import { Box, DatePicker, Flex, InputGroup, InputLabel, Text } from '@endpoint/blockparty';
import { OcrConfidenceScoreText } from 'components/OcrConfidenceScoreText';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';

export const Dates: FC = () => {
  const { values, setFieldValue, initialValues } = useFormikContext<OpenOrderDrawerForm>();

  return (
    <Box mt="space70">
      <Text as="h2" fontWeight="semi" size="fontSize30">
        Dates
      </Text>
      <Flex flexDirection="column" width="40%">
        <InputGroup groupId="closingDate" pb="space30" pt="space50" width="100%">
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel>Closing Date</InputLabel>
            {values.dates.closingDate && values.ocrConfidenceScores?.closingDate && (
              <OcrConfidenceScoreText
                currentInputText={values.dates?.closingDate.toString()}
                originalInputText={initialValues.dates?.closingDate?.toString()}
                value={values.ocrConfidenceScores?.closingDate}
              />
            )}
          </Flex>
          <DatePicker
            dataTestId="open-order-drawer-closing-date-picker"
            selected={values.dates.closingDate}
            onChange={(date) => {
              setFieldValue('dates.closingDate', date);
            }}
          />
        </InputGroup>

        <InputGroup groupId="emdDue" pb="space30" pt="space50">
          <InputLabel>EMD Due Date</InputLabel>
          <DatePicker selected={values.dates.emdDueDate} onChange={(date) => setFieldValue('dates.emdDueDate', date)} />
        </InputGroup>

        <InputGroup groupId="contractExecutedDate" pb="space30" pt="space50">
          <InputLabel htmlFor="contractExecutedDate">Contract Executed Date</InputLabel>
          <DatePicker
            selected={values.dates.contractExecutedDate}
            onChange={(date) => setFieldValue('dates.contractExecutedDate', date)}
          />
        </InputGroup>
      </Flex>
    </Box>
  );
};
