import React, { FC, useState } from 'react';
import { Box, Flex, Icon, ListChoice, Loading, Text, useDisclosure, useToast } from '@endpoint/blockparty';
import { TodoAssignment, TodoStatus, RecallReason } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { useMutation } from '@apollo/client';
import { todoStatusUpdateSuccessMap, updateTodoTextMap, updateTodoIconMap, updateTodoOptions } from 'consts/toDoConsts';
import { TodoStatusModified } from 'consts/segmentProtocols';
import { mapStatusText } from 'helpers/statusHelper';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';

import { SET_TODO_STATUS, UpdateTodoAssignment } from './queries';
import { ConfirmRecallReasonModal } from './ConfirmRecallReasonModal';

interface UpdateTodoProps {
  assignmentStatus: TodoAssignment;
  updateLocation: 'Contact Details' | 'Edit Task' | 'To-Do assignment' | 'Customer Status Card';
}

export const UpdateTodoStatus: FC<UpdateTodoProps> = ({ assignmentStatus, updateLocation }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialTrackData: Omit<TodoStatusModified, 'newValue'> = {
    todoId: assignmentStatus.id,
    location: updateLocation,
    modification: 'Status',
    originalValue: assignmentStatus.status,
  };
  const [trackData] = useState(initialTrackData);

  const toast = useToast();
  const mutationHandler = {
    onError(): void {
      toast({
        description: 'Action was not successful',
        duration: 5000,
        iconColor: 'watermelon500',
        icon: 'ErrorCircle',
      });
    },
    onCompleted(data: UpdateTodoAssignment): void {
      const status = data.updateTodoAssignment.status;
      const recallReason = data.updateTodoAssignment.recallReason;

      toast({
        description: mapStatusText(status, todoStatusUpdateSuccessMap),
        duration: 5000,
        icon: 'CheckCircle',
      });

      trackAnalytics('Todo Modified', {
        ...trackData,
        newValue: status,
        reason: recallReason,
      });
    },
  };

  const [updateTodoStatus, { loading }] = useMutation<UpdateTodoAssignment>(SET_TODO_STATUS, mutationHandler);

  const handleSubmitStatus = (status: TodoStatus, recallReason?: RecallReason) => {
    void updateTodoStatus({
      variables: {
        data: {
          status,
          recallReason,
        },
        where: {
          id: assignmentStatus.id,
        },
      },
    });
  };

  const handleUpdateClick = (status: TodoStatus) => {
    if (status === TodoStatus.RECALLED) {
      onOpen();
    } else {
      handleSubmitStatus(status);
    }
  };

  const possibleOptions = assignmentStatus.statusOptions || [];

  return (
    <>
      <Box width={208}>
        {loading && (
          <Flex
            alignItems="center"
            data-test-id="update-todo-status-loader"
            flexDirection="column"
            height={120}
            justifyContent="center"
          >
            <Loading size="medium" />
            <Text as="p" pt="space40" size="fontSize20" textAlign="center">
              Loading
            </Text>
          </Flex>
        )}

        {!loading &&
          updateTodoOptions.map((status) => {
            const isValidOption = possibleOptions.includes(status);

            return (
              <ListChoice
                key={`update-todo-status-option-${status}`}
                data-test-id={`update-todo-status-option-${status}`}
                px="space50"
                py="space30"
                onClick={(): void => {
                  if (isValidOption) {
                    handleUpdateClick(status);
                  }
                }}
              >
                <Flex alignItems="center">
                  <Icon
                    color={isValidOption ? 'carbon500' : 'carbon300'}
                    name={mapStatusText(status, updateTodoIconMap) as IconNames}
                    size="large"
                  />
                  <Text color={isValidOption ? 'carbon900' : 'carbon300'} ml="space30" size="fontSize20">
                    {mapStatusText(status, updateTodoTextMap)}
                  </Text>
                </Flex>
              </ListChoice>
            );
          })}
      </Box>
      <ConfirmRecallReasonModal
        assigneeId={assignmentStatus.id}
        isModalOpen={isOpen}
        updateLocation={updateLocation}
        onClose={onClose}
        onSubmit={handleSubmitStatus}
      />
    </>
  );
};

ListChoice.displayName = 'ListChoice';
