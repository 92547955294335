import { datadogRum } from '@datadog/browser-rum';
import React, { FC, createContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Redirect } from '@reach/router';
import { getConfig } from 'helpers/utils/config';
import { Transaction } from 'routes/Transaction';
import { TransactionBasicInfo } from 'routes/Transaction/TransactionBasicInfo';
import { TransactionContacts } from 'routes/Transaction/TransactionContacts';
import { TaskList } from 'routes/Transaction/TaskList';
import { ContactDetails } from 'routes/Transaction/ContactDetails';
import { OpenOrderList } from 'routes/Dashboard/OpenOrderList';
import { Auth } from 'components/Auth';
import { NotFound } from 'components/NotFound';
import { Documents } from 'routes/Transaction/Documents';
import { DocumentRightPanel } from 'routes/Transaction/Documents/DocumentsContent/DocumentsRightPanel';
import { Calendar } from 'routes/Calendar';
import { DocumentReview } from 'routes/DocumentReview';
import { DocumentReviewProvider } from 'routes/DocumentReview/contexts/DocumentReviewContext';
import { ContactDetails as AddressBookContactDetails } from 'routes/AddressBook/ContactDetails';
import { ContactsList as AddressBookList } from 'routes/AddressBook/ContactsList';
import { TransactionRightPanel } from 'routes/Transaction/TransactionRightPanel';
import { TransactionRightPanelV2 } from 'routes/Transaction/TransactionRightPanel/TransactionRightPanelV2';
import { Dogs404 } from 'routes/404';
import { TransactionsList } from 'routes/TransactionsList';
import { Signings } from 'routes/Signings';
import { AddressBook } from 'routes/AddressBook';
import Admin from 'routes/Admin';
import Markets from 'routes/Admin/Markets';
import Queue from 'routes/Queue';
import { Contacts } from 'routes/Transaction/Contacts';
import { Analytics } from 'routes/Analytics';

import { name, version } from '../package.json';

const appConfig: AppConfig = getConfig();

export const ConfigContext = createContext(appConfig);

const App: FC = () => {
  const { isSigningsPageEnabled, isOpenOrderQueueEnabled, isSellerOnboardingPocEnabled, isAnalyticsPageEnabled } =
    useFlags();

  if (appConfig.DATADOG_RUM_APP_ID && appConfig.DATADOG_RUM_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: appConfig.DATADOG_RUM_APP_ID,
      clientToken: appConfig.DATADOG_RUM_CLIENT_TOKEN,
      env: appConfig.DEPLOY_ENV,
      service: name,
      version,
      sampleRate: 100,
      site: 'datadoghq.com',
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }

  return (
    <DocumentReviewProvider>
      <Auth>
        <Redirect from="/" noThrow to="transactions" />
        <Redirect from="dashboard" noThrow to="open-order" />
        <Transaction path="transaction/:transactionIdentifier">
          <TransactionBasicInfo path="/" />
          <TaskList path="tasks/" />
          <TaskList path="tasks/*taskId">
            {isSellerOnboardingPocEnabled ? (
              <TransactionRightPanelV2 path="/:taskId" />
            ) : (
              <TransactionRightPanel path="/:taskId" />
            )}
          </TaskList>
          <Contacts path="contacts">
            <TransactionContacts path="/" />
            <TransactionContacts path="/*participantId" />
            <ContactDetails path="/:participantId" />
          </Contacts>
          <Documents path="documents/" />
          <Documents path="documents/*documentId">
            <DocumentRightPanel path="/:documentId" />
          </Documents>
          {isSigningsPageEnabled && <Signings path="signings" />}
          <NotFound default />
        </Transaction>
        <TransactionsList path="transactions">
          <NotFound default />
        </TransactionsList>
        <DocumentReview path="document-review" />
        <Calendar path="calendar" />
        <AddressBook path="address-book">
          <NotFound path="contacts/" />
          <AddressBookContactDetails path="contacts/:contactId" />
          <AddressBookList path="/" />
        </AddressBook>
        {isOpenOrderQueueEnabled && <OpenOrderList path="dashboard/open-order" />}
        <Admin path="admin">
          <Redirect from="/" noThrow to="markets" />
          <Markets path="/markets" />
        </Admin>
        {isAnalyticsPageEnabled && <Analytics path="analytics" />}
        <Queue path="queue" />
        <Dogs404 default />
      </Auth>
    </DocumentReviewProvider>
  );
};

export default App;
