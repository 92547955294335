import React, { FC } from 'react';
import { getIn, useFormikContext } from 'formik';
import { FormikItems, FormikItemValue, TaskStepItemDefinition } from 'helpers/dynamicForm/types';
import { componentMapping, TaskStepItem } from 'helpers/dynamicForm/mapping';
import { Box } from '@endpoint/blockparty';

interface TaskStepItemViewProps {
  item: TaskStepItemDefinition;
  ctaId: string;
}

export const TaskStepItemView: FC<TaskStepItemViewProps> = ({ item, ctaId }) => {
  const { values } = useFormikContext<FormikItems>();
  const formikItem: FormikItemValue | undefined = getIn(values, item.name);

  const Item: TaskStepItem = componentMapping[item.type];

  const getFollowUpItems = (value: string) => {
    let FollowUpItemsList: JSX.Element[] = [];

    if (item.followUp && value in item.followUp) {
      FollowUpItemsList = item.followUp[value].map((followUpItem) => {
        const FollowUpItem = componentMapping[followUpItem.type];

        return (
          <Box key={ctaId} data-test-id={followUpItem.key} marginBottom="space30">
            <FollowUpItem {...followUpItem} />
          </Box>
        );
      });
    }

    return FollowUpItemsList;
  };

  const FollowUpItems =
    formikItem &&
    (Array.isArray(formikItem.value)
      ? formikItem.value.map((value: string) => {
          return getFollowUpItems(value);
        })
      : getFollowUpItems(formikItem.value));

  return (
    <>
      <Box marginBottom="space30">
        <Item {...item} />
      </Box>
      {FollowUpItems}
    </>
  );
};
