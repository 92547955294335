import { gql } from '@apollo/client';
import { Milestone } from '@endpoint/opsware-bff-graphql-schema';

export const UPDATE_MILESTONE = gql`
  mutation UpdateMilestone($data: MilestoneUpdateInput!, $where: MilestoneWhereUniqueInput!) {
    setMilestoneStatus(data: $data, where: $where) {
      id
      status
      statusOptions
    }
  }
`;
export interface SetMilestoneStatusData {
  setMilestoneStatus: Milestone;
}
