import { gql } from '@apollo/client';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_CONTACTS = gql`
  query getContacts($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      id
      fileNum
      type
      requireListingAgent
      requireSellingAgent
      property {
        address {
          street1
        }
      }
      participants {
        id
        roles
        isDirectingContact
        contact {
          id
          email
          contactType
          phone
          ... on AgentContact {
            licenseNumber
            stateOfOperation
            firstName
            lastName
            middleName
          }
          ... on LenderContact {
            nmlsId
            firstName
            lastName
            middleName
          }
          ... on IndividualContact {
            firstName
            lastName
            middleName
          }
          ... on EntityContact {
            entityName
          }
        }
      }
    }
  }
`;

export interface GetContactsType {
  transaction: Transaction;
}
