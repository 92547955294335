import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box, Flex, useDisclosure } from '@endpoint/blockparty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import 'styled-components/macro';
import { Layout } from 'components/Layout';
import { transactionLinks, transactionLinksPrelim } from 'consts/transactionConsts';
import { useQuery } from '@apollo/client';
import { ErrorMessage } from 'components/ErrorMessage';
import {
  TransactionParticipant,
  TransactionStatus,
  TransactionType,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { LayoutLoader } from 'components/LayoutLoader';
import { DocumentUploadDrawer } from 'components/DocumentUploadDrawer';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { buildQueryInput } from 'helpers/buildQueryInput';
import { OpswareUser } from 'components/Auth/helpers';
import { Dogs403 } from 'routes/403';
import { isUserAuthorized } from 'helpers/authorizationHelper';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';
import { Dogs404 } from 'routes/404';

import { GET_BASIC_TRANSACTION_DATA, GetBasicTransactionData } from './queries';
import { SubHeader } from './SubHeader';

interface TransactionProps extends RouteComponentProps {
  transactionIdentifier?: string;
  taskId?: string;
}
export interface TransactionContextType {
  fileNumber: string;
  id: string;
  participants: TransactionParticipant[];
  organizationId?: number;
  type?: TransactionType;
}
export const TransactionContext = createContext<TransactionContextType>({
  fileNumber: '',
  participants: [],
  id: '',
  organizationId: undefined,
  type: undefined,
});

export const Transaction: FC<TransactionProps> = ({ children, transactionIdentifier = '' }) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>();
  const [authError, setAuthError] = useState<boolean>(false);
  const { data: uploads } = useUploadDocument();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isSigningsPageEnabled } = useFlags();
  const isUploadDrawerOpen = uploads?.uploadDrawerOpen;
  const queryInput = buildQueryInput(transactionIdentifier);
  const { data: currentUserData, loading: opswareUserLoading } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);
  const hasSigningsLink = transactionLinks.find((link) => link.label === 'Signings');
  const hasCurrentUserResolved = !opswareUserLoading && currentUserData && !!currentUserData.me.firstName;
  const { data, loading, error } = useQuery<GetBasicTransactionData>(GET_BASIC_TRANSACTION_DATA, {
    variables: { where: queryInput },
  });

  if (isSigningsPageEnabled && !hasSigningsLink) {
    transactionLinks.push({ label: 'Signings', route: './signings' });
  }

  useEffect(() => {
    if (isUploadDrawerOpen) {
      onOpen();
    }
  }, [isUploadDrawerOpen, onClose, onOpen]);

  useEffect(() => {
    if (error?.graphQLErrors?.[0]?.extensions?.code === 'FORBIDDEN') {
      setAuthError(true);
    }
  }, [error]);

  useEffect(() => {
    if (data?.transaction && currentUserData && hasCurrentUserResolved) {
      setIsAuthorized(isUserAuthorized(currentUserData?.me, data.transaction));
    }
  }, [data, currentUserData, hasCurrentUserResolved]);

  const transactionProviderValue = useMemo(
    () => ({
      fileNumber: data?.transaction?.fileNum || '',
      participants: data?.transaction?.participants || [],
      id: data?.transaction?.id || '',
      organizationId: data?.transaction?.organizationId,
      type: data?.transaction?.type,
    }),
    [data],
  );

  if (loading) {
    return <LayoutLoader />;
  }

  // NOTE: need to explicitly check isAuthorized for false
  if (!hasCurrentUserResolved || isAuthorized === false || authError) {
    return <Dogs403 />;
  }

  if (!data?.transaction || error) {
    return (
      <Box height="100%" width="100%">
        {error && <ErrorMessage />}
        {!data?.transaction && <Dogs404 />}
      </Box>
    );
  }

  return (
    <TransactionContext.Provider value={transactionProviderValue}>
      <Layout
        hasLeftPanel
        sidenavLinks={
          data.transaction.status === TransactionStatus.PRELISTING ? transactionLinksPrelim : transactionLinks
        }
      >
        <SubHeader transactionIdentifier={data.transaction.fileNum || transactionIdentifier} />
        <Flex
          // @ts-ignore - to be fixed in BP
          css={`
            & > div {
              width: 100%;
            }
          `}
          height="100%"
          overflow="auto"
        >
          {children}
        </Flex>
      </Layout>
      <DocumentUploadDrawer isOpen={isOpen} transactionId={data.transaction.id} onClose={onClose} />
    </TransactionContext.Provider>
  );
};
