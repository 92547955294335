import {
  TransactionParticipant,
  TransactionRole,
  Transaction,
  MilestoneType,
  TransactionStage,
  TransactionType,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { AllContactTypes } from 'consts/contactDetails';

import { formatRolesLikeTitle, giveFirstWord } from '../formatText';

export type UserFirstLast = {
  id?: string;
  firstName?: string;
  lastName?: string;
  entityName?: string;
};

export type ContactBasicData = {
  avatar: string;
  firstWordNames: string;
  firstName: string;
  lastName: string;
  fullName: string;
};

export const getContactData = (contact: UserFirstLast): ContactBasicData => {
  /* This function fixes the problem for getting different fields when we request a
   * AgentContact | LenderContact | IndividualContact | EntityContact;
   * It returns a ContactBasicData type, does not matter if AllContactTypes have different fields;
   */

  if (contact?.entityName) {
    // EntityContact just return entityName as a field but we can group it in a ContactBasicData type;
    return {
      avatar: contact?.entityName?.charAt(0) || '',
      firstWordNames: giveFirstWord(contact?.entityName).trim() || '',
      firstName: contact?.entityName || '',
      lastName: '',
      fullName: contact?.entityName || '',
    };
  }

  return {
    avatar: `${contact?.firstName?.charAt(0)}${contact?.lastName?.charAt(0)}`.trim() || '',
    firstWordNames: `${giveFirstWord(contact?.firstName) || ''} ${giveFirstWord(contact?.lastName || '')}`.trim(),
    firstName: contact?.firstName || '',
    lastName: contact?.lastName || '',
    fullName: `${contact?.firstName} ${contact?.lastName}`.trim() || '',
  };
};

export const getParticipantsNamesByRole = (transaction: Transaction, roleType: TransactionRole): string[] => {
  /* 
    NOTE: https://apex-venture.atlassian.net/browse/OM-605
    transactionSearch no longer resolving participants (for now)
  */

  if (!transaction.participants) {
    return [];
  }

  return transaction.participants
    .filter((participant: TransactionParticipant) => participant.roles.includes(roleType))
    .map((participant: TransactionParticipant) => {
      const contact = participant?.contact as AllContactTypes;
      const firstLastObject: UserFirstLast = {
        id: contact?.id || participant?.user?.id,
        firstName: contact?.firstName || participant?.user?.firstName,
        lastName: contact?.lastName || participant?.user?.lastName,
        entityName: contact?.entityName || participant?.user?.lastName,
      };

      return getContactData(firstLastObject).fullName;
    });
};

export const getParticipantsNamesObjectByRole = (
  transaction: Transaction,
  roleType: TransactionRole,
): UserFirstLast[] => {
  if (!transaction.participants) {
    return [];
  }

  return transaction.participants
    .filter((participant: TransactionParticipant) => participant.roles.includes(roleType))
    .map((participant: TransactionParticipant) => {
      const contact = participant.contact as AllContactTypes;

      if (contact?.entityName) {
        return {
          id: contact?.id || '',
          entityName: contact?.entityName,
        };
      }

      return {
        id: contact?.id || '',
        firstName: contact?.firstName || '',
        lastName: contact?.lastName || '',
      };
    });
};

export const hasValidListingAgent = (transaction: Transaction): boolean => {
  if (transaction.requireListingAgent === false) {
    return true;
  }

  const hasListingAgent = getParticipantsNamesObjectByRole(transaction, TransactionRole.LISTING_AGENT).length !== 0;
  const hasListingAgentCoordinator =
    getParticipantsNamesObjectByRole(transaction, TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR).length !== 0;

  return hasListingAgent || hasListingAgentCoordinator;
};

export const hasValidSellingAgent = (transaction: Transaction): boolean => {
  if (transaction.requireSellingAgent === false) {
    return true;
  }

  const hasSellingAgent = getParticipantsNamesObjectByRole(transaction, TransactionRole.SELLING_AGENT).length !== 0;
  const hasSellingAgentCoordinator =
    getParticipantsNamesObjectByRole(transaction, TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR).length !== 0;

  return hasSellingAgent || hasSellingAgentCoordinator;
};

export const getBuyerSellerBorrowerRoleFromEventType = (
  eventType: MilestoneType | TransactionStage,
  transaction: Transaction,
): TransactionRole => {
  if (
    (transaction.type === TransactionType.REFINANCE && eventType === MilestoneType.SELLER_SIGNING_SCHEDULED) ||
    (transaction.type === TransactionType.REFINANCE && eventType === TransactionStage.SELLER_SIGNING_SCHEDULED)
  )
    return TransactionRole.BORROWER;

  if (
    (transaction.type !== TransactionType.REFINANCE && eventType === TransactionStage.BUYER_SIGNING_SCHEDULED) ||
    eventType === MilestoneType.BUYER_SIGNING_SCHEDULED
  )
    return TransactionRole.BUYER;

  return TransactionRole.SELLER;
};

export interface UserOption {
  id: string;
  roles?: TransactionRole[];
  value: string;
  label: string;
  src?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  formattedRoles?: string;
}

export const getParticipantSelectOptions = (
  transactionParticipants: Partial<TransactionParticipant>[],
): UserOption[] => {
  return (
    transactionParticipants &&
    transactionParticipants.map((transactionParticipant: Partial<TransactionParticipant>) => {
      const contact = getContactData(transactionParticipant.contact as AllContactTypes);
      const nameValueForAvatar: string = contact.firstWordNames;
      const formattedRoles: string = formatRolesLikeTitle(transactionParticipant.roles?.join(', ') || '', true);

      return {
        id: transactionParticipant.id || '',
        roles: transactionParticipant.roles,
        value: nameValueForAvatar,
        src: '',
        label: `${contact.fullName} (${formattedRoles})`,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        fullName: contact?.fullName,
        formattedRoles,
      };
    })
  );
};

export interface ContactOption {
  id: string;
  roles?: TransactionRole[];
  value: string;
  label: string;
  src?: string;
  firstName?: string;
  lastName?: string;
  entityName?: string;
  fullName?: string;
  formattedRoles?: string;
}

export const getContactSelectOptions = (
  transactionParticipants: Partial<TransactionParticipant>[],
): ContactOption[] => {
  return (
    transactionParticipants &&
    transactionParticipants.map((transactionParticipant: Partial<TransactionParticipant>) => {
      const { id, roles } = transactionParticipant;
      const { firstName, lastName, entityName } = transactionParticipant.contact as AllContactTypes;
      const nameValueForAvatar: string = entityName
        ? giveFirstWord(entityName)
        : `${giveFirstWord(firstName || '')} ${giveFirstWord(lastName || '')}`;

      const fullName: string = entityName || `${firstName} ${lastName}`;
      const formattedRoles: string = formatRolesLikeTitle(roles?.join(', ') || '', true);

      return {
        id: id || '',
        roles,
        value: nameValueForAvatar,
        src: '',
        label: `${fullName} (${formattedRoles})`,
        firstName,
        lastName,
        fullName,
        entityName,
        formattedRoles,
      };
    })
  );
};
