import {
  Box,
  Flex,
  Heading,
  Layout as BaseLayout,
  LayoutContainer,
  LayoutContent,
  LayoutSubContainer,
  LayoutSubContent,
  SkeletonBlock,
} from '@endpoint/blockparty';
import React from 'react';

import { Header } from '../../components/Header';
import { SkeletonTable } from '../../components/SkeletonTable';

export function SkeletonQueue() {
  return (
    <BaseLayout>
      <Header />
      <LayoutContainer>
        <LayoutSubContainer>
          <LayoutContent>
            <LayoutSubContent p="space60">
              <Heading as="h4" color="carbon900" fontWeight="normal" pb="space60" size="fontSize50">
                <Flex alignItems="center" justifyContent="space-between">
                  <Box>
                    <SkeletonBlock height={40} width={184} />
                  </Box>
                  <Flex alignItems="center">
                    <Box mr="space50">
                      <SkeletonBlock height={12} width={400} />
                    </Box>
                    <Box>
                      <SkeletonBlock height={40} maxWidth="auto" width={184} />
                    </Box>
                  </Flex>
                </Flex>
              </Heading>
              <Flex alignItems="center" justifyContent="space-between" pb="space60">
                <Flex alignItems="center" minWidth="77%">
                  <SkeletonBlock height={40} maxWidth="auto" mr="space50" width={329} />
                  <SkeletonBlock height={40} maxWidth="auto" mr="space50" width={184} />
                  <SkeletonBlock height={40} maxWidth="auto" mr="space50" width={184} />
                </Flex>
              </Flex>
              <SkeletonTable columns={5} rows={16} />
            </LayoutSubContent>
          </LayoutContent>
        </LayoutSubContainer>
      </LayoutContainer>
    </BaseLayout>
  );
}
