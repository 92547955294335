import React, { FC } from 'react';
import { Box, Grid, Heading, Text } from '@endpoint/blockparty';
import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema';
import { dateFormatter } from 'helpers/datetimeHelper';
import { formatLikeTitle, formatNotificationType } from 'helpers/formatText';
import { useEditContactDetailsProvider } from 'hooks/useEditContactDetailsProvider';

interface SystemInformationProps {
  participant: TransactionParticipant;
}

export const SystemInformationFields: FC<SystemInformationProps> = ({ participant }) => {
  const {
    contact: { createdAt, isOnboarded, notificationPreferences },
  } = participant;
  const preference = (notificationPreferences || []).map((pref) => pref.toLowerCase()).join(', ');
  const notificationTypeContent = formatLikeTitle(preference);

  return (
    <Grid
      columnGap="space60"
      px="space40"
      py="space30"
      rowGap="space30"
      templateColumns="repeat(2, minmax(150px, 1fr))"
    >
      <Box>
        <Text as="p" color="passive" mb="space20" size="fontSize10">
          Joined EP App
        </Text>
        <Box mb="space30">
          <Text as="p" data-test-id="system-information-joined">
            {isOnboarded ? dateFormatter(createdAt, 'MMM dd, yyyy') : '-'}
          </Text>
        </Box>
      </Box>
      <Box>
        <Text as="p" color="passive" mb="space20" size="fontSize10">
          Notification Type
        </Text>
        <Text as="p" data-test-id="system-information-notification" mb="space30">
          {formatNotificationType(notificationTypeContent) || '-'}
        </Text>
      </Box>
    </Grid>
  );
};

export const SystemInformation: FC<SystemInformationProps> = ({ participant }) => {
  const { state } = useEditContactDetailsProvider();

  return (
    <Box data-test-id="system-information-section" pb={state.isEditing ? 'space60' : 'space0'} width="100%">
      <Heading backgroundColor="slate0" p="space40" size="fontSize30">
        System Information
      </Heading>
      <SystemInformationFields participant={participant} />
    </Box>
  );
};
