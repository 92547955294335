import React, { FC } from 'react';
import {
  TodoAssignment,
  TransactionParticipant,
  TransactionStatus,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { Box, Tab, TabList, TabPanel, Tabs as TabsContainer, Flex } from '@endpoint/blockparty';
import { EditContactDetailsProvider } from 'hooks/useEditContactDetailsProvider';

import { DetailsTab } from './DetailsTab';
import { TodosTab } from './TodosTab';

interface TabsProps {
  participant: TransactionParticipant;
  todoAssignments?: TodoAssignment[];
  transactionStatus?: TransactionStatus;
  defaultTabSelected?: string;
}

export const Tabs: FC<TabsProps> = ({ participant, todoAssignments, transactionStatus }): React.ReactElement => {
  const numberOfTodos = todoAssignments && todoAssignments.length;

  return (
    <Box
      backgroundColor="white"
      borderRadius="radiusDefault"
      boxShadow="medium"
      data-test-id="contact-details-tabs"
      mb="space60"
      pb="space60"
      pt="space40"
      px="space60"
      width="100%"
    >
      <TabsContainer>
        <TabList aria-label="contact-tabs-list" mb="space50">
          <Flex>
            <Tab id="details">Details</Tab>
            {transactionStatus !== TransactionStatus.PRELISTING && (
              <Tab whiteSpace="nowrap">{`To-Do's (${numberOfTodos})`}</Tab>
            )}
          </Flex>
        </TabList>
        <TabPanel>
          <EditContactDetailsProvider>
            <DetailsTab participant={participant} />
          </EditContactDetailsProvider>
        </TabPanel>
        <TabPanel>
          <TodosTab todoAssignments={todoAssignments} />
        </TabPanel>
      </TabsContainer>
    </Box>
  );
};
