import { Box, MultiSelect } from '@endpoint/blockparty';
import { optionsDisplayValueMap, supportedOptions } from 'consts/supportedTaskOptions';
import React, { FC } from 'react';

const selectTaskStatusOptions = supportedOptions.map((status) => ({
  value: status,
  label: optionsDisplayValueMap[status],
}));

interface TaskStatusSelectProps {
  onChange(statuses: string[]): void;
  defaultValue?: string[];
}

export const TaskStatusSelect: FC<TaskStatusSelectProps> = ({ onChange, defaultValue = [] }) => {
  const defaultValueOptions = selectTaskStatusOptions.filter((option) => {
    return defaultValue.includes(option.value);
  });

  return (
    <Box data-test-id="task-status-select" minWidth={190}>
      <MultiSelect
        controlText="Task Status"
        data-test-id="task-status-select-filter"
        defaultValue={defaultValueOptions}
        options={selectTaskStatusOptions}
        onChange={(values: { value: string }[]) => onChange(values.map((value) => String(value.value)))}
      />
    </Box>
  );
};
