import React, { FC } from 'react';
import { Alert, AlertContent, AlertDescription, AlertTitle, List, ListItem } from '@endpoint/blockparty';
import { v4 } from 'uuid';
import { QualityControlFailureOption } from '@endpoint/common-model';

interface WorkflowPromptProps {
  dataTestId: string;
  status: 'error' | 'warning' | 'info' | 'success';
  title: string;
  description: string;
  listValues?: string[];
}

export const WorkflowPrompt: FC<WorkflowPromptProps> = ({
  children,
  dataTestId,
  description,
  listValues,
  status,
  title,
}) => {
  return (
    <Alert data-test-id={dataTestId} mb="space60" status={status}>
      <AlertContent>
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>
          {description}
          {listValues && (
            <List
              spacing="space0"
              style={{
                textIndent: '12px',
                listStylePosition: 'inside',
              }}
            >
              {listValues?.map((listValue) => {
                const registeredOption =
                  QualityControlFailureOption[listValue as keyof typeof QualityControlFailureOption];
                const optionText = registeredOption || listValue;

                return <ListItem key={v4()}>{optionText}</ListItem>;
              })}
            </List>
          )}
        </AlertDescription>
      </AlertContent>
      {children}
    </Alert>
  );
};
