import React, { FC, ReactElement } from 'react';
import { cloneDeep } from 'lodash';
import { Formik } from 'formik';
import { Divider, DrawerSection, DrawerFooter, Button, Flex, Text, Box } from '@endpoint/blockparty';
import { Todo, Transaction, TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { DatePickerField } from 'components/DatePicker';
import { TextArea } from 'components/TextArea';
import { RemoveAssigneeFieldArray } from 'components/RemoveAssigneeFieldArray';
import { AddAssigneeField } from 'components/AddAssigneeField';
import { mapTransactionToContacts } from 'helpers/contacts';
import { flattenTodoAssignments, canAddTodoAssignments } from 'helpers/todo';
import { FetchResult } from 'apollo-link';
import { todoWithDueDate } from 'helpers/datetimeHelper';
import { ErrorMessage } from 'components/ErrorMessage';

import { FormValues } from '../index';

interface ToDoFormProps {
  todo: Todo;
  transaction: Transaction | null;
  toDoUpdateAction(initialValues: Todo, updatedValues: FormValues): Promise<FetchResult[]>;
  cancelAction(): void;
}

export const EditToDoForm: FC<ToDoFormProps> = ({ todo, transaction, toDoUpdateAction, cancelAction }) => {
  const initialToDo = cloneDeep(todo);

  const initialAssignments = initialToDo.assignments || [];

  const transactionParticipants: Pick<TransactionParticipant, 'id' | 'roles' | 'contact'>[] = mapTransactionToContacts(
    transaction || null,
  );
  const flatTodoAssignments = flattenTodoAssignments(initialToDo.assignments || []);

  const canAddParticipants = canAddTodoAssignments(initialToDo.assignments || []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: initialToDo.id,
        escrowNote: initialToDo.escrowNote || '',
        due: todoWithDueDate(initialToDo.due),
        todoAssignments: flatTodoAssignments || [],
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await toDoUpdateAction(todo, values);
        setSubmitting(false);
      }}
    >
      {({ handleReset, handleSubmit, setFieldValue, isSubmitting, dirty, ...props }): ReactElement => (
        // @ts-ignore - onSubmit not recognized on Flex
        <Flex as="form" data-test-id="todo-update-form" flex={1} flexDirection="column" onSubmit={handleSubmit}>
          <Flex flex={1} flexDirection="column">
            <DrawerSection pt="space0">
              <DatePickerField
                data-tets-id="todo-update-form-date"
                label="Due Date"
                name="due"
                placeholder="Select a due date"
                startDate={props.values.due}
                onDataChange={setFieldValue}
              />
              <Box pt="space60">
                <TextArea
                  label="Note to Customer(optional)"
                  name="escrowNote"
                  placeholder="Attach an optional note"
                  {...props}
                />
              </Box>
            </DrawerSection>
            <Divider />
            <DrawerSection px="space0">
              <Text
                as="h3"
                data-test-id="todo-update-assign-customers"
                fontWeight="semi"
                mx="space60"
                pb="space60"
                size="fontSize30"
              >
                Assign Customers
              </Text>
              {canAddParticipants && (
                <Box data-test-id="add-assigness-box" mx="space60" pb="space40">
                  {transactionParticipants.length ? (
                    <AddAssigneeField
                      label="Add Assignees"
                      name="todoAssignments"
                      transactionParticipants={transactionParticipants}
                      values={props.values.todoAssignments}
                    />
                  ) : (
                    <ErrorMessage
                      description="Other fields are still editable."
                      title="Cannot add Assignees at this time"
                    />
                  )}
                </Box>
              )}
              <RemoveAssigneeFieldArray
                initialValues={initialAssignments}
                label="Remove Assignee"
                name="todoAssignments"
                values={props.values.todoAssignments}
              />
            </DrawerSection>
          </Flex>
          <Flex flexDirection="column">
            <DrawerFooter>
              <Button
                data-test-id="edit-todo-cancel"
                isDisabled={isSubmitting}
                mr="space30"
                type="reset"
                variant="outline"
                onClick={(): void => {
                  handleReset();
                  cancelAction();
                }}
              >
                Cancel
              </Button>
              <Button
                data-testid="edit-todo-save"
                isDisabled={isSubmitting || !dirty}
                isLoading={isSubmitting}
                type="submit"
              >
                <Text color="white" fontWeight="semi">
                  Save Changes
                </Text>
              </Button>
            </DrawerFooter>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

EditToDoForm.displayName = 'EditToDoForm';
