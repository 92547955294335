import { Text } from '@endpoint/blockparty';
import { formatOcrConfidenceScore } from 'helpers/openOrder/formatOcrConfidenceScore';
import React, { FC } from 'react';
import { currencyInputRegex } from 'consts/currencyInputRegex';

import { formatCurrencyStringToNumber } from '../../helpers/formatText';

interface OcrConfidenceScoreProps {
  value: number;
  currentInputText?: string | number;
  originalInputText?: string;
}

// FYI:
// while running tests:  currentInputText = #,### (string), originalInputText = #### (string)
// but in the browser: currentInputText = #### (number), originalInputText = #,### (string)

export const OcrConfidenceScoreText: FC<OcrConfidenceScoreProps> = ({ value, currentInputText, originalInputText }) => {
  let originalValue: string | number = String(originalInputText);
  let currentValue: string | number = String(currentInputText);

  if (currencyInputRegex.test(originalValue)) {
    originalValue = formatCurrencyStringToNumber(originalValue);
  }

  if (currencyInputRegex.test(currentValue)) {
    currentValue = formatCurrencyStringToNumber(currentValue);
  }

  return originalValue === currentValue ? (
    <Text color="watermelon500" size="fontSize10">
      {formatOcrConfidenceScore(value)}
    </Text>
  ) : null;
};
