import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { doesRoleHaveWriteAccess, OpswareUser } from 'components/Auth/helpers';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';

export const useHasWriteAccess = (): boolean => {
  const [hasWriteAccess, setHasWriteAccess] = useState<boolean>(false);
  const { data } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);

  useEffect(() => {
    if (data?.me.role) {
      const canWrite = doesRoleHaveWriteAccess(data.me.role);

      setHasWriteAccess(canWrite);
    }
  }, [data]);

  return hasWriteAccess;
};
