import React, { FC, ReactNode } from 'react';
import { Link, Match } from '@reach/router';
import { LayoutLeftPanel, ListChoice, Text } from '@endpoint/blockparty';

interface LeftPanelProps {
  sidenavLinks: LinkProps[];
}

export const LeftPanel: FC<LeftPanelProps> = ({ sidenavLinks }) => (
  <LayoutLeftPanel
    borderColor="carbon300"
    borderRight="1px solid"
    data-test-id="left-menu-panel"
    iconToggleCollapsed="ArrowChevronRight"
    pt="space50"
  >
    {sidenavLinks.map(({ label, route }) => (
      <Link key={route} to={`${route}`}>
        <Match path={route}>
          {(props): ReactNode => {
            // Look for full or partial matches
            const isActive: boolean =
              !!props.match ||
              (props.location.pathname.indexOf(route.substr(1)) > -1 &&
                // Don't partial match for root
                route !== './');

            return (
              <ListChoice pl="space60" py="space30" selected={isActive}>
                <Text fontWeight={isActive ? 'semi' : 'normal'}>{label}</Text>
              </ListChoice>
            );
          }}
        </Match>
      </Link>
    ))}
  </LayoutLeftPanel>
);

ListChoice.displayName = 'ListChoice';
LayoutLeftPanel.displayName = 'LayoutLeftPanel';
