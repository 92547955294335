import { TransactionType } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export function convertOcrProductType(type: string | null): TransactionType | string {
  if (!type) return '';

  return ocrToOpswareProductMap[type];
}

export const ocrToOpswareProductMap: { [value: string]: TransactionType } = {
  SALE_WITH_MORTGAGE: TransactionType.SALE_MORTGAGE,
  SALE_WITH_CASH: TransactionType.SALE_CASH,
};
