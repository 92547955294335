import React, { FC } from 'react';
import { useField } from 'formik';
import { InputGroupProps } from '@endpoint/blockparty/dist/components/InputGroup';
import { Input, InputGroup, InputLabel, InputErrorMessage } from '@endpoint/blockparty';
import Cleave from 'cleave.js/react';
import { removeNonNumeric } from 'helpers/formatPhone';

export interface FormikCleaveInputGroupProps extends InputGroupProps {
  isRequired?: boolean;
  label?: string;
  placeholder?: string;
  mask?: GenericObject;
  component?: React.ReactNode;
  name: string;
  value?: string;
  isSensitiveInformation?: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
}

export const FormikCleaveInputGroup: FC<FormikCleaveInputGroupProps> = ({
  component,
  isRequired,
  name,
  label,
  isSensitiveInformation,
  placeholder,
  mask,
  value,
  autoFocus,
  dataTestId,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  const isInvalid = Boolean(meta.touched && meta.error);

  const options = {
    as: Cleave,
    options: mask,
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value || '';

    if (mask?.numericOnly) {
      inputValue = removeNonNumeric(event.target.value || '');
    }

    setValue(inputValue);
  };

  return (
    <InputGroup groupId={name} isInvalid={isInvalid} isRequired={isRequired} {...props}>
      <InputLabel>{label || name}</InputLabel>
      <Input
        {...field}
        className={isSensitiveInformation ? 'fs-exclude' : ''}
        placeholder={placeholder}
        {...(mask && options)}
        autoComplete="none"
        autoFocus={autoFocus}
        data-test-id={dataTestId}
        value={value}
        onChange={handleOnChange}
      />
      {isInvalid && <InputErrorMessage>{meta.error}</InputErrorMessage>}
    </InputGroup>
  );
};
