import React from 'react';
import { Box, SkeletonBlock, Flex, Heading, Divider, Icon } from '@endpoint/blockparty';
import { InternalNoteLoader } from 'components/InternalNote/InternalNoteLoader';

export function CalendarEventDrawerLoader() {
  return (
    <Box data-test-id="calendar-event-loader">
      <Box>
        <Flex alignItems="center" pb="space30">
          <SkeletonBlock height={12} width="70px" />
          <Icon color="carbon500" name="Bullet" size="medium" />
          <SkeletonBlock height={12} width="80px" />
        </Flex>
        <SkeletonBlock height={12} maxWidth="unset" width="300px" />
        <Flex alignItems="center" py="space60">
          <SkeletonBlock borderRadius="50%" height={24} mr="space40" width={24} />
          <SkeletonBlock height={12} width="91px" />
        </Flex>
      </Box>
      <Divider />
      <Box py="space60">
        <Heading as="h5" pb="space60" size="fontSize40">
          Internal Notes
        </Heading>
        <InternalNoteLoader />
        <InternalNoteLoader />
        <InternalNoteLoader />
      </Box>
    </Box>
  );
}
