import React from 'react';
import { Button, Card, CardHeader, CardSection, Divider, Text, useDisclosure } from '@endpoint/blockparty';
import { EditTodoDrawer } from 'routes/Transaction/TransactionRightPanel/EditTodoDrawer';
import { Todo } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';

import { ToDoCardBody } from './ToDoCardBody';

export interface ToDoCardProp {
  taskId: string;
  transactionIdentifier: string;
  todo: Todo;
}

export const ToDoCard: React.FC<ToDoCardProp> = ({ taskId, transactionIdentifier, todo }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Card data-test-id="todo-card">
      <CardHeader data-test-id="todo-card-header" pt="space60" px="space60">
        <Text data-test-id="todo-card-header-text" fontWeight="semi" size="fontSize30">
          {todo.name}
        </Text>
      </CardHeader>
      <CardSection flexDirection="column" pb="space60" px="space60">
        <ToDoCardBody data={todo} data-test-id="todo-card-body" />
      </CardSection>
      <Divider data-test-id="divider" />
      <CardSection data-test-id="todo-card-footer" px="space60" py="space50">
        <ButtonWriteRequired
          data-test-id="todo-edit-button"
          iconLeft="Edit"
          variant="outline"
          variantColor="blue"
          width="100%"
          onClick={(): void => {
            onOpen();
          }}
        >
          Edit
        </ButtonWriteRequired>

        <EditTodoDrawer
          isOpen={isOpen}
          taskId={taskId}
          todo={todo}
          transactionIdentifier={transactionIdentifier}
          onClose={onClose}
        />
      </CardSection>
    </Card>
  );
};
