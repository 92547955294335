import { v4 } from 'uuid';
import { DocumentUpload, DocumentUploadStatus } from 'consts/uploadDocumentsConsts';
import { FileUpload } from 'apollo/resolvers/UploadDocument/FileUpload';

export function createDocumentUpload(
  fileName: string,
  documentType: string,
  fileRejection: string,
  fileUpload: FileUpload,
): DocumentUpload {
  const status: DocumentUploadStatus = fileRejection ? DocumentUploadStatus.ERROR : DocumentUploadStatus.INPROGRESS;

  return {
    id: v4(),
    status,
    progress: 0,
    name: fileName,
    documentType,
    fileRejection,
    fileUpload,
  };
}
