import { OcrPsaRegionResult } from '@endpoint/opsware-bff-graphql-schema';
import { sortBy } from 'lodash';

export const buildMarketOptions = (
  ocrPsaRegions: NonNullable<OcrPsaRegionResult>[] | undefined,
  orgStateOptions: NonNullable<{ value: string; label: string }>[] | undefined,
) => {
  let stateOptions: OcrPsaRegionResult[] = [];

  if (ocrPsaRegions && ocrPsaRegions.length) {
    stateOptions.push(...ocrPsaRegions);
  }

  if (orgStateOptions && orgStateOptions.length) {
    stateOptions = stateOptions.filter((stateOption) =>
      orgStateOptions.map((orgStateOption) => orgStateOption.value).includes(stateOption.value),
    );
  }

  stateOptions = sortBy(stateOptions, ['label']);

  stateOptions.push({ value: 'ZZ', label: 'Other' } as OcrPsaRegionResult);

  return stateOptions;
};
