import React, { FC } from 'react';
import { Box, LayoutContainer, Text, Heading } from '@endpoint/blockparty';

export const TabletViewMessage: FC = () => {
  return (
    <LayoutContainer
      bg="carbon100"
      bottom={0}
      display={{ base: 'block', md: 'none' }}
      left={0} // height of <Header />
      position="absolute"
      right={0}
      top="56px"
      zIndex="warning"
    >
      <Box margin="0 auto" pt="120px" textAlign="center">
        <Heading as="h3" mb="space70" size="fontSize60">
          Oof! It’s getting a bit crowded in here.
        </Heading>
        <Text as="p">
          Increase the size of your browser to continue. Tablet and mobile are not currently supported.
        </Text>
      </Box>
    </LayoutContainer>
  );
};
