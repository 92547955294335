export function getColumnSortIcon(isSorted: boolean, isSortedDesc?: boolean) {
  if (isSorted) {
    if (isSortedDesc) {
      return 'ArrowCarrotDown';
    }

    return 'ArrowCarrotUp';
  }

  return 'ArrowCarrots';
}
