import React, { FC } from 'react';
import { Box, MultiSelect } from '@endpoint/blockparty';
import { SelectStateProps } from 'components/TaskStatusButton/styles';

const MultiSelectOptions: SelectOptionsObject<string>[] = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

interface OfficeSelectProps {
  onChange(options: string[]): void;
  defaultValue?: string | string[];
}

export const NeedsReviewSelect: FC<OfficeSelectProps> = ({ onChange, defaultValue }) => {
  const defaultValueOptions = MultiSelectOptions.filter((option) => {
    return defaultValue && defaultValue.includes(option.value);
  });

  return (
    <Box minWidth={240}>
      <MultiSelect
        controlText="Needs Review"
        customStyles={{
          menu: (base: SelectStateProps) => ({
            ...base,
            minWidth: 180,
            right: 0,
          }),
        }}
        data-test-id="open-order-needs-review-select"
        defaultValue={defaultValueOptions}
        options={MultiSelectOptions}
        onChange={(values: { value: string }[]) => onChange(values.map((value) => value.value))}
      />
    </Box>
  );
};
