import React, { FC, ReactElement } from 'react';
import { Text, Flex, Divider, Box } from '@endpoint/blockparty';
import { PinnedItem } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { MultiLineText } from 'components/MultiLineText';
import { formatLikeTitle } from 'helpers/formatText';
import { mapStatusText } from 'helpers/statusHelper';
import { pinnedItemToText, PinnedItemRole } from 'consts/pinnedItemConsts';

import { getPinnedItemRole, getPinnedItemData } from './pinnedItemHelper';

interface PinnedItemPopverInterface {
  pinnedItem: PinnedItem;
  milestonePosition: number;
}

export const PinnedItemPopover: FC<PinnedItemPopverInterface> = ({ pinnedItem, milestonePosition }) => {
  const pinnedItemData = getPinnedItemData(pinnedItem, milestonePosition);
  const pinnedItemRole: PinnedItemRole = getPinnedItemRole(pinnedItem);
  const statusText = mapStatusText(pinnedItemRole, pinnedItemToText).toUpperCase();

  return (
    <Flex data-test-id="pinned-item-popover" flexDirection="column" outline="none" tabIndex={0} width={320}>
      <Text color="carbon500" data-test-id="pinned-item-popover-roles" fontWeight="semi" size="fontSize10">
        {statusText}
      </Text>
      <Text data-test-id="pinned-item-popover-title" fontWeight="semi" mt="space20" size="fontSize40">
        {pinnedItemData.title}
      </Text>
      <Box mt="space20">
        {pinnedItemData &&
          Object.entries(pinnedItemData).map(([key, value], index): ReactElement | null => {
            if (key !== 'title') {
              return (
                <Flex key={key} data-test-id={`pinned-item-popover-${key}`} flexDirection="column" pt="space30">
                  <Text
                    color="carbon500"
                    data-test-id={`pinned-item-popover-${milestonePosition}-${pinnedItemRole}-${key}`}
                    size="fontSize10"
                  >
                    {formatLikeTitle(key)}
                  </Text>
                  <Flex
                    data-test-id={`pinned-item-popover-${milestonePosition}-${pinnedItemRole}-${key}-value`}
                    flexDirection="column"
                    mt="space30"
                  >
                    <MultiLineText text={value} />
                  </Flex>
                  {index < Object.keys(pinnedItemData).length - 1 && <Divider mt="space30" />}
                </Flex>
              );
            }

            return null;
          })}
      </Box>
    </Flex>
  );
};
