import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GetMe($includeSquad: Boolean = false) {
    me @client {
      id
      firstName
      lastName
      email
      organization
      market
      hasMultiOrganizationAccess
      hasMultiSquadAccess @include(if: $includeSquad)
      role
      squad @include(if: $includeSquad)
    }
  }
`;
