import React, { FC } from 'react';
import { Flex, Layout, LayoutContent, SkeletonBlock, Loading } from '@endpoint/blockparty';

export const SkeletonPage: FC = (): React.ReactElement => (
  <Layout width="100%">
    <SkeletonBlock backgroundColor="slate500" height="56px" maxWidth="100%" minHeight="56px" width="100%" />
    <LayoutContent minHeight="calc(100vh - 56px)">
      <Flex alignItems="center" flex="1" justifyContent="center">
        <Loading size="medium" />
      </Flex>
    </LayoutContent>
  </Layout>
);
