import React, { FC } from 'react';
import { Box, Heading } from '@endpoint/blockparty';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { useEditTransactionBasicInfoContext } from 'hooks/useEditTransactionBasicInfoContext';

import { BasicInfoDatesEditingForm } from './BasicInfoDatesEditingForm';
import { Dates } from './Dates';

export interface BasicInfoDatesProps {
  transaction: Transaction;
}

export const BasicInfoDates: FC<BasicInfoDatesProps> = ({ transaction }) => {
  const { isBeingEdited } = useEditTransactionBasicInfoContext();

  return (
    <Box data-test-id="basic-info-dates">
      <Heading backgroundColor="slate0" p="space50" size="fontSize40">
        Dates
      </Heading>
      {isBeingEdited ? <BasicInfoDatesEditingForm transaction={transaction} /> : <Dates transaction={transaction} />}
    </Box>
  );
};

BasicInfoDates.displayName = 'BasicInfoDates';
