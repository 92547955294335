import { TransactionRole, ContactType, StateOfOperation } from '@endpoint/opsware-bff-graphql-schema';
import { v4 } from 'uuid';

export enum NewContactStep {
  SEARCH_CONTACT = 'SEARCH_CONTACT',
  ADD_CONTACT = 'ADD_CONTACT',
  SELECT_DUPLICATE = 'SELECT_DUPLICATE',
  SEARCH_UPDATE_CONTACT = 'SEARCH_UPDATE_CONTACT',
}

export enum ContactGQLErrorType {
  FIND_ERROR = 'FIND_ERROR',
  CREATE_ERROR = 'CREATE_ERROR',
  SEARCH_ERROR = 'SEARCH_ERROR',
  CREATE_TRANSACTION_PARTICIPANT_ERROR = 'CREATE_TRANSACTION_PARTICIPANT_ERROR',
}

export enum RelatedContactsGQLErrorType {
  AUTO_ADD_RELATED_CONTACTS_ERROR = 'AUTO_ADD_RELATED_CONTACTS_ERROR',
}
export interface NewContactSchema {
  contactType: ContactType | string;
  roles: TransactionRole[];
  firstName: string;
  middleName?: string;
  lastName?: string;
  entityName?: string;
  licenseNumber?: string;
  stateOfOperation?: StateOfOperation | string;
  nmlsId?: string;
  email?: string;
  phone?: string;
  id: string;
}

export interface CreateUserInput {
  contactType: ContactType | string;
  roles: TransactionRole[];
  firstName?: string;
  middleName?: string;
  lastName?: string;
  entityName?: string;
  licenseNumber?: string;
  stateOfOperation?: StateOfOperation | string;
  nmlsId?: string;
  email?: string;
  phone?: string;
}

export interface SearchContactResult {
  contactType?: ContactType;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  entityName?: string;
  licenseNumber?: string;
  nmlsId?: string;
  email?: string;
  phone?: string;
  id?: string;
  stateOfOperation?: StateOfOperation | string;
  organizationId: number;
}

// give the Contact from the form a uuid for tracking in the radio card
// the id will never go to the API
export const newContactDefault: NewContactSchema = {
  contactType: '',
  roles: [],
  firstName: '',
  middleName: '',
  lastName: '',
  entityName: '',
  licenseNumber: '',
  stateOfOperation: '',
  nmlsId: '',
  email: '',
  phone: '',
  id: v4(),
};

export const initialContactFormErrors = {
  firstName: '',
  lastName: '',
  entityName: '',
  licenseNumber: '',
  nmlsId: '',
  email: '',
  phone: '',
  contactType: '',
  roles: '',
};

export interface ContactErrorMessaging {
  label: string;
  description: string;
}

export type ContactGQLErrorTextMap = Record<ContactGQLErrorType, ContactErrorMessaging>;

export const contactGQLErrorTextMap: ContactGQLErrorTextMap = {
  [ContactGQLErrorType.CREATE_ERROR]: {
    label: 'Unable to save contact',
    description: 'There was a problem saving the contact information',
  },
  [ContactGQLErrorType.FIND_ERROR]: {
    label: 'Unable to search contact',
    description: 'There was a problem searching for the contact information',
  },
  [ContactGQLErrorType.SEARCH_ERROR]: {
    label: 'Search has encountered a problem',
    description: 'Results could not be returned.',
  },
  [ContactGQLErrorType.CREATE_TRANSACTION_PARTICIPANT_ERROR]: {
    label: 'Unable to add to file',
    description: 'There was a problem adding the contact to the file',
  },
};

export const contactIsParticipantError: ContactErrorMessaging = {
  label: 'Contact already added',
  description: 'This contact has already been added to the transaction',
};

export const relatedContactsError: ContactErrorMessaging = {
  label: 'Unable to auto-add related contacts',
  description: 'Please add related contacts manually.',
};

export const contactTypeOptions: SelectOptionsObject<ContactType>[] = [
  { label: 'Agent', value: ContactType.AGENT },
  { label: 'Loan Officer', value: ContactType.LOAN_OFFICER },
  { label: 'Loan Processor', value: ContactType.LOAN_PROCESSOR },
  { label: 'Mortgage Broker', value: ContactType.MORTGAGE_BROKER },
  { label: 'Corporation', value: ContactType.TRANSACTEE_CORPORATION },
  { label: 'Individual', value: ContactType.TRANSACTEE_INDIVIDUAL },
  { label: 'Partnership', value: ContactType.TRANSACTEE_PARTNERSHIP },
  { label: 'Trust', value: ContactType.TRANSACTEE_TRUST },
  { label: 'Transaction Coordinator', value: ContactType.TRANSACTION_COORDINATOR },
  { label: 'Proptech Partner', value: ContactType.PROPTECH_PARTNER },
  { label: 'Internal Employee', value: ContactType.INTERNAL_EMPLOYEE },
  { label: 'Settlement Company', value: ContactType.SETTLEMENT_COMPANY },
];

export const newContactDrawerTitleMap: GenericMapObject<NewContactStep> = {
  [NewContactStep.ADD_CONTACT]: 'Create new contact',
  [NewContactStep.SEARCH_CONTACT]: 'Add Contact',
  [NewContactStep.SELECT_DUPLICATE]: '',
  [NewContactStep.SEARCH_UPDATE_CONTACT]: 'Add Contact',
};

export type RoleOptionMap = Record<ContactType, SelectOptionsObject<TransactionRole>[] | []>;

export const roleOptions: RoleOptionMap = {
  [ContactType.AGENT]: [
    {
      label: 'Selling Agent',
      value: TransactionRole.SELLING_AGENT,
    },
    {
      label: 'Listing Agent',
      value: TransactionRole.LISTING_AGENT,
    },
  ],
  [ContactType.TRANSACTION_COORDINATOR]: [
    {
      label: 'Selling TC',
      value: TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR,
    },
    {
      label: 'Listing TC',
      value: TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR,
    },
  ],
  [ContactType.TRANSACTEE_CORPORATION]: [
    {
      label: 'Buyer',
      value: TransactionRole.BUYER,
    },
    {
      label: 'Seller',
      value: TransactionRole.SELLER,
    },
  ],
  [ContactType.TRANSACTEE_TRUST]: [
    {
      label: 'Buyer',
      value: TransactionRole.BUYER,
    },
    {
      label: 'Seller',
      value: TransactionRole.SELLER,
    },
  ],
  [ContactType.TRANSACTEE_PARTNERSHIP]: [
    {
      label: 'Buyer',
      value: TransactionRole.BUYER,
    },
    {
      label: 'Seller',
      value: TransactionRole.SELLER,
    },
  ],
  [ContactType.TRANSACTEE_INDIVIDUAL]: [
    {
      label: 'Buyer',
      value: TransactionRole.BUYER,
    },
    {
      label: 'Seller',
      value: TransactionRole.SELLER,
    },
  ],
  [ContactType.LOAN_OFFICER]: [
    {
      label: 'Loan Officer',
      value: TransactionRole.LOAN_OFFICER,
    },
  ],
  [ContactType.LOAN_PROCESSOR]: [
    {
      label: 'Loan Processor',
      value: TransactionRole.LOAN_PROCESSOR,
    },
  ],
  [ContactType.MORTGAGE_BROKER]: [
    {
      label: 'Mortgage Broker',
      value: TransactionRole.MORTGAGE_BROKER,
    },
  ],
  [ContactType.PROPTECH_PARTNER]: [
    {
      label: 'Buyer',
      value: TransactionRole.PROPTECH_BUYER,
    },
    {
      label: 'Seller',
      value: TransactionRole.PROPTECH_SELLER,
    },
  ],
  [ContactType.INTERNAL_EMPLOYEE]: [
    {
      label: 'Closing Specialist',
      value: TransactionRole.CLOSING_SPECIALIST,
    },
  ],
  [ContactType.SETTLEMENT_COMPANY]: [
    {
      label: 'Settlement Company',
      value: TransactionRole.SETTLEMENT_COMPANY,
    },
  ],
};

export interface ContactSearchTerms {
  firstName?: string;
  lastName?: string;
  licenseNumber?: string;
  NMLSId?: string;
  email?: string;
}
