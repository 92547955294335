import { selectComp } from '@endpoint/blockparty';
import { SimpleAddressInput } from '@endpoint/opsware-bff-graphql-schema';
import React, { FC } from 'react';
import { OptionProps } from 'react-select';

import { SearchAddressOption } from './OptionContent';

export const Option: FC<OptionProps<SimpleAddressInput, false>> = ({ ...props }) => {
  const { data } = props;

  return (
    <selectComp.Option {...props}>
      <SearchAddressOption optionData={data} {...props} />
    </selectComp.Option>
  );
};
