import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Flex, Modal, ModalContent, ModalFooter, ModalSection, Text, useToast } from '@endpoint/blockparty';
import { UPDATE_TRANSACTION_BASIC_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';

interface MarkAsTestModalProps {
  transactionId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const MarkAsTestModal: FC<MarkAsTestModalProps> = ({ transactionId, isOpen, onClose }) => {
  const toast = useToast();

  const [updateIsTest, { loading: isMarkTestUpdating }] = useMutation(UPDATE_TRANSACTION_BASIC_INFO, {
    onCompleted(): void {
      onClose();
      toast({
        description: 'Mark As Test Successful',
        duration: 5000,
        icon: 'CheckCircle',
      });
    },
    onError(): void {
      toast({
        description: 'Action was not successful',
        duration: 5000,
        iconColor: 'watermelon500',
        icon: 'ErrorCircle',
      });
    },
  });

  const markAsIsTest = (): void => {
    void updateIsTest({
      variables: {
        updateTransaction: { isTest: true },
        where: { id: transactionId },
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalSection>
          <Flex flexDirection="column">
            <Text as="h3" fontWeight="semi" mb="space0" size="fontSize30">
              Mark transaction as Test?
            </Text>
          </Flex>
        </ModalSection>
        <ModalFooter>
          <Button mr="space30" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button isLoading={isMarkTestUpdating} minWidth={105} onClick={markAsIsTest}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
