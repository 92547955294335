import { TodoAssignmentToUpdate } from 'consts/toDoConsts';
import {
  TodoAssignment,
  TodoStatus,
  Json,
  TodoAssignmentStepAnswer,
  TodoAssignmentStepAnswerField,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { getContactData } from 'helpers/transactionParticipants';

export function flattenTodoAssignments(assignments: TodoAssignment[]): TodoAssignmentToUpdate[] {
  return assignments.map((assignment: TodoAssignment) => ({
    id: assignment.id,
    status: assignment.status,
    todoAssigneeId: assignment.assignee.id,
    assigneeRoles: assignment.assignee.roles,
    assigneeFirstName: getContactData(assignment.assignee.contact).firstName,
    assigneeLastName: getContactData(assignment.assignee.contact).lastName,
  }));
}

export const showTodoDueDate = (todoStatus: TodoStatus): boolean =>
  ![TodoStatus.SUBMITTED, TodoStatus.SUBMITTED_BY_OPS, TodoStatus.SUBMITTED_BY_PEER, TodoStatus.RECALLED].includes(
    todoStatus,
  );

export const canAddTodoAssignments = (todoAssignments: TodoAssignment[]): boolean => {
  const taskMetadata: Json[] = todoAssignments
    ? todoAssignments.map((assignment: TodoAssignment) => assignment.metadata).flat()
    : [];

  if (
    taskMetadata &&
    taskMetadata.length &&
    taskMetadata.some(
      (metadata: Json) => metadata.task_data_type === 'signature_id' || metadata.task_data_name === 'template',
    )
  )
    return false;

  return true;
};

export const aggregateStepAnswerFields = (stepAnswers?: TodoAssignmentStepAnswer[]) => {
  if (!stepAnswers) {
    return [];
  }

  return stepAnswers.reduce((allFields: TodoAssignmentStepAnswerField[], currentStepAnswer) => {
    const filteredFields = currentStepAnswer.fields.filter((field) => field);

    return allFields.concat(filteredFields);
  }, []);
};

interface QuestionAnswerMultipleData {
  fullname?: string;
  emailAddress?: string;
  phoneNumber?: string;
}

export const convertObjectToKeySortedList = (
  answer: QuestionAnswerMultipleData,
  sortOrder: string[],
): [string, string][] => {
  const entries = Object.entries(answer);

  entries.sort((sortA, sortB) => {
    const sortOrderA = sortOrder.indexOf(sortA[0]);
    const sortOrderB = sortOrder.indexOf(sortB[0]);

    if (sortOrderA === -1 && sortOrderB === -1) {
      return sortA[0].localeCompare(sortB[0]);
    }

    if (sortOrderA === -1) {
      return 1;
    }

    if (sortOrderB === -1) {
      return -1;
    }

    return sortOrderA - sortOrderB;
  });

  return entries;
};
