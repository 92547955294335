import React, { FC } from 'react';
import { Box, Heading } from '@endpoint/blockparty';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { useEditTransactionBasicInfoContext } from 'hooks/useEditTransactionBasicInfoContext';

import { BasicInfoTermsEditForm } from './BasicInfoTermsEditForm';
import { Terms } from './Terms';

export interface BasicInfoTermsProps {
  transaction: Transaction;
}

export const BasicInfoTerms: FC<BasicInfoTermsProps> = ({ transaction }) => {
  const { isBeingEdited } = useEditTransactionBasicInfoContext();

  return (
    <Box data-test-id="basic-info-terms">
      <Heading backgroundColor="slate0" p="space50" size="fontSize40">
        Terms
      </Heading>
      {isBeingEdited ? <BasicInfoTermsEditForm transaction={transaction} /> : <Terms transaction={transaction} />}
    </Box>
  );
};

BasicInfoTerms.displayName = 'BasicInfoTerms';
