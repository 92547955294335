import React, { FC, useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { mimeTypes } from 'consts/uploadDocumentsConsts';
import { Box, Flex, Icon, Select, Text, InputLabel, InputGroup } from '@endpoint/blockparty';
import { BasicSelectOption, documentTypeOptions } from 'consts/documentTypeOptions';
import '../../Styles/DocumentUploadWidget/document-upload-widget.scss';
import { ErrorMessage } from 'components/ErrorMessage';

interface DocumentUploadStatusProps {
  transactionId: string;
}

export const DocumentUploadWidget: FC<DocumentUploadStatusProps> = ({ transactionId }) => {
  const { uploadDocument } = useUploadDocument();
  const [documentType, setDocumentType] = useState<BasicSelectOption>({ value: '', label: '' });
  const [errorMessage, setErrorMessage] = useState('');

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      uploadDocument(acceptedFiles[0], transactionId, documentType.label);
      setDocumentType({ value: '', label: '' });
      setErrorMessage('');
    },
    [documentType, transactionId, uploadDocument],
  );

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const fileRejectionErrorCode = fileRejections[0].errors[0].code;
    const isFileTypeError = fileRejectionErrorCode.includes('file-invalid-type');
    const isTooManyFiles = fileRejectionErrorCode.includes('too-many-files');

    if (isFileTypeError) {
      setErrorMessage('Document must be of type PDF, JPG, or PNG.');
    }

    if (isTooManyFiles) {
      setErrorMessage('Too many files.');
    }
  };

  const handleSelectChange = (value: BasicSelectOption) => {
    setDocumentType({ value: value.value, label: value.label });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: mimeTypes,
    disabled: !documentType.value,
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <Flex flexDirection="column">
      <InputGroup pb="space50">
        <InputLabel color="carbon500" htmlFor="document-type">
          Document Type *
        </InputLabel>
        <Select
          data-test-id="document-type-select"
          inputId="document-type"
          isSearchable
          name="document-type"
          options={documentTypeOptions}
          placeholder="Select..."
          value={documentType.value ? documentType : null}
          onChange={(option: BasicSelectOption): void => {
            handleSelectChange(option);
          }}
        />
      </InputGroup>
      <Box className="opsware-upload-dropzone">
        {/*
        // @ts-ignore - dropzone returns color not part of color tokens */}
        <Box
          data-test-id="upload-target"
          {...getRootProps({ className: 'dropzone disabled' })}
          cursor={documentType.value ? 'pointer' : 'not-allowed'}
          mb="space40"
        >
          {/*
        // @ts-ignore - dropzone returns color not part of color tokens */}
          <Box as="input" {...getInputProps()} />
          {errorMessage ? (
            <ErrorMessage
              closeAction={() => setErrorMessage('')}
              description={errorMessage}
              title="Unable to upload document."
            />
          ) : null}
          <Flex
            alignItems="center"
            border="1px dashed"
            className="opsware-upload-dropdown-wrapper"
            justifyContent="center"
            mt="space50"
            py="20px"
          >
            <Icon color={!documentType.value ? 'blue200' : 'blue500'} mr="space30" name="Upload" size="large" />
            <Text color={!documentType.value ? 'carbon500' : 'carbon900'} size="fontSize20">
              Choose a file or drag it here
            </Text>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};
