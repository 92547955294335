import { ToastProps } from '@endpoint/blockparty/dist/components/Toast';

export const networkFailureInfo: ToastProps = {
  description: 'Network failure - please try again or contact support',
  icon: 'ErrorCircle',
};

export const m1ProcessingInfo: ToastProps = {
  description: 'M1 Processing',
  icon: 'CheckCircle',
};

export const qcReadyInfo: ToastProps = {
  description: 'Marked as Ready for QC',
  icon: 'CheckCircle',
};

export const qcFailedInfo: ToastProps = {
  description: 'Marked as QC Failed',
  icon: 'CheckCircle',
};
