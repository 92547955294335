import {
  Contact,
  AgentContact,
  EntityContact,
  LenderContact,
  IndividualContact,
  TransactionRole,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';

import { UpdateContactField } from './updateContact';

export type AllContactTypes = Contact & IndividualContact & AgentContact & EntityContact & LenderContact;

export enum ContactFragmentTypes {
  CONTACT = 'CONTACT',
  INDIVIDUAL_CONTACT = 'INDIVIDUAL_CONTACT',
  AGENT_CONTACT = 'AGENT_CONTACT',
  ENTITY_CONTACT = 'ENTITY_CONTACT',
  LENDER_CONTACT = 'LENDER_CONTACT',
}

export enum ContactDetailsFieldsLabels {
  NAME = 'Name',
  ROLE = 'Role',
  LICENSE_NUMBER = 'License Number',
  STATE_OF_OPERATION = 'State of Operation',
  NMLS_ID = 'NMLS ID',
  MLS_ID = 'MLS ID',
}

export const ContactDetailsFieldsOrder = [
  ContactDetailsFieldsLabels.NAME,
  ContactDetailsFieldsLabels.ROLE,
  ContactDetailsFieldsLabels.LICENSE_NUMBER,
  ContactDetailsFieldsLabels.STATE_OF_OPERATION,
  ContactDetailsFieldsLabels.MLS_ID,
  ContactDetailsFieldsLabels.NMLS_ID,
];

export interface ContactFieldData {
  label: string;
  value: string | undefined;
  isEditable: boolean;
  fieldName: UpdateContactField;
}

export type RoleToFragmentRecord = Record<TransactionRole, ContactFragmentTypes | ContactFragmentTypes[]>;

export const rolesToFragmentMap: RoleToFragmentRecord = {
  [TransactionRole.BUYER]: [ContactFragmentTypes.ENTITY_CONTACT, ContactFragmentTypes.INDIVIDUAL_CONTACT],
  [TransactionRole.SELLER]: [ContactFragmentTypes.ENTITY_CONTACT, ContactFragmentTypes.INDIVIDUAL_CONTACT],
  [TransactionRole.BORROWER]: [ContactFragmentTypes.ENTITY_CONTACT, ContactFragmentTypes.INDIVIDUAL_CONTACT],
  [TransactionRole.CLOSING_SPECIALIST]: ContactFragmentTypes.INDIVIDUAL_CONTACT,
  [TransactionRole.SELLING_AGENT]: ContactFragmentTypes.AGENT_CONTACT,
  [TransactionRole.LISTING_AGENT]: ContactFragmentTypes.AGENT_CONTACT,
  [TransactionRole.ESCROW_AGENT]: ContactFragmentTypes.AGENT_CONTACT,
  [TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR]: ContactFragmentTypes.AGENT_CONTACT,
  [TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR]: ContactFragmentTypes.AGENT_CONTACT,
  [TransactionRole.LOAN_OFFICER]: ContactFragmentTypes.LENDER_CONTACT,
  [TransactionRole.LOAN_PROCESSOR]: ContactFragmentTypes.LENDER_CONTACT,
  [TransactionRole.MORTGAGE_BROKER]: ContactFragmentTypes.LENDER_CONTACT,
  [TransactionRole.PROPTECH_BUYER]: ContactFragmentTypes.ENTITY_CONTACT,
  [TransactionRole.PROPTECH_SELLER]: ContactFragmentTypes.ENTITY_CONTACT,
  [TransactionRole.PROPTECH_PARTNER]: ContactFragmentTypes.ENTITY_CONTACT,
  [TransactionRole.SETTLEMENT_COMPANY]: ContactFragmentTypes.ENTITY_CONTACT,
  [TransactionRole.BUYER_API]: ContactFragmentTypes.ENTITY_CONTACT,
  [TransactionRole.SELLER_API]: ContactFragmentTypes.ENTITY_CONTACT,
};
