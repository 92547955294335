import React, { FC } from 'react';
import { Flex, Text, Avatar, Box } from '@endpoint/blockparty';
import {
  Contact,
  IndividualContact,
  EntityContact,
  ContactType,
  AgentContact,
  LenderContact,
} from '@endpoint/opsware-bff-graphql-schema';
import { giveFirstWord } from 'helpers/formatText';

interface SingleValueContentProps {
  selectedData: Contact | IndividualContact | EntityContact | AgentContact | LenderContact;
}

export const SingleValueContent: FC<SingleValueContentProps> = ({ selectedData }) => {
  const isEntity =
    selectedData.contactType === ContactType.TRANSACTEE_CORPORATION ||
    selectedData.contactType === ContactType.TRANSACTEE_PARTNERSHIP ||
    selectedData.contactType === ContactType.TRANSACTEE_TRUST ||
    (selectedData as EntityContact).entityName;

  const firstName = (selectedData as IndividualContact).firstName || '';
  const lastName = (selectedData as IndividualContact).lastName || '';
  const name = `${firstName} ${lastName}` || '';

  const avatarName = `${giveFirstWord(firstName)} ${giveFirstWord(lastName)}` || '';
  const entityName = (selectedData as EntityContact).entityName || '';
  const displayName = isEntity ? entityName : name;

  return (
    <Flex alignItems="center" data-test-id="single-value-content">
      <Box>
        <Avatar mr="space30" name={isEntity ? entityName : avatarName} size={24} />
      </Box>
      <Text>{displayName}</Text>
    </Flex>
  );
};
