import React, { FC } from 'react';
import { Layout as BaseLayout, LayoutContainer, LayoutSubContainer } from '@endpoint/blockparty';
import { Header } from 'components/Header';
import { TabletViewMessage } from 'components/TabletViewMessage';
import { LeftPanel } from 'components/LeftPanel';

interface LayoutProps {
  hasLeftPanel?: boolean;
  sidenavLinks?: LinkProps[];
}

export const Layout: FC<LayoutProps> = ({ children, hasLeftPanel, sidenavLinks = [{ label: '', route: '' }] }) => {
  return (
    <BaseLayout>
      <Header />
      <TabletViewMessage />
      <LayoutContainer>
        {hasLeftPanel && <LeftPanel sidenavLinks={sidenavLinks} />}
        <LayoutSubContainer position="relative">{children}</LayoutSubContainer>
      </LayoutContainer>
    </BaseLayout>
  );
};
