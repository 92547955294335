import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { isLoggedInUserOpsOrSystemAdmin, OpswareUser } from 'components/Auth/helpers';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';

interface IsSystemOrOpsAdminValue {
  isAdmin: boolean;
  loading: boolean;
}

export const useIsSystemOrOpsAdmin = (): IsSystemOrOpsAdminValue => {
  const [isSystemOrOpsAdmin, setIsSystemOrOpsAdmin] = useState<boolean>(false);
  const { data, loading } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);

  useEffect(() => {
    if (data?.me.role) {
      const isOpsAdminOrSysAdmin = isLoggedInUserOpsOrSystemAdmin(data.me.role);

      setIsSystemOrOpsAdmin(isOpsAdminOrSysAdmin);
    }
  }, [data, isSystemOrOpsAdmin]);

  return {
    loading,
    isAdmin: isSystemOrOpsAdmin,
  };
};
