import { gql } from '@apollo/client';
import { Note } from '@endpoint/opsware-bff-graphql-schema';

export const GET_CURRENT_USER = gql`
  {
    me @client {
      firstName
      lastName
    }
  }
`;

export const CREATE_TRANSACTION_NOTE = gql`
  mutation CreateTransactionNote($data: CreateTransactionNoteInput!) {
    createTransactionNote(data: $data) {
      id
      text
      createdAt
      isPinned
      author {
        id
        firstName
        lastName
        imageUrl
      }
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CreateTransactionNote($data: CreateNoteInput!) {
    createNote(data: $data) {
      id
      text
      createdAt
      isPinned
      authorId
      authorName
    }
  }
`;

export const TRANSACTION_FRAGMENT = gql`
  fragment transaction on Transaction {
    id
    notes {
      id
      text
      createdAt
      isPinned
      author {
        id
        firstName
        lastName
        imageUrl
      }
    }
  }
`;

export interface CreateNote {
  createNote: Note;
}
