import { Flex, Text } from '@endpoint/blockparty';
import * as React from 'react';

import { PlatformIcon } from './PlatformIcon';
import { Platform } from './types';

export interface PlatformMenuItemProps {
  platform: Platform;
}

const PlatformMenuItem: React.FC<PlatformMenuItemProps> = ({ platform }) => {
  return (
    <Flex alignItems="center" paddingX="space30" paddingY="space40">
      <PlatformIcon />
      <Flex flexDirection="column" textAlign="left">
        <Text color="carbon900" fontWeight="semi" size="fontSize20">
          {platform.title}
        </Text>
        <Text color="carbon500" size="fontSize10">
          {platform.subtitle}
        </Text>
      </Flex>
    </Flex>
  );
};

export default PlatformMenuItem;
