import React, { FC } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { navigate, useLocation, useMatch } from '@reach/router';
import {
  Avatar,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  Icon,
  LayoutHeader,
  NavBar,
  NavBarItem,
  useDropdownMenuState,
} from '@endpoint/blockparty';
import { giveFirstWord } from 'helpers/formatText';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';
import { useIsSystemOrOpsAdmin } from 'hooks/useIsSystemOrOpsAdmin';

import { OpswareUser, signOut } from '../Auth/helpers';
import PlatformLogoFactory from './Platform/PlatformLogoFactory';

interface NavBarItemProps {
  label: string;
  route: string;
  show: Boolean;
}

export function useActiveNavBarItems(): NavBarItemProps[] {
  const { isAdmin } = useIsSystemOrOpsAdmin();
  const adminMatch = useMatch('/admin/*');
  const {
    isOpenOrderQueueEnabled,
    canViewOpswareNavigationPopover,
    isQueuePageEnabled,
    isAnalyticsPageEnabled,
    isCalendarEnabled = true,
  } = useFlags();

  if (isAdmin && canViewOpswareNavigationPopover && adminMatch) {
    return [
      {
        label: 'Markets',
        route: '/admin/markets',
        show: true,
      },
    ];
  }

  return [
    {
      label: 'Dashboard',
      route: '/dashboard/',
      show: isOpenOrderQueueEnabled,
    },
    {
      label: 'Calendar',
      route: '/calendar',
      show: isCalendarEnabled,
    },
    {
      label: 'Transactions',
      route: '/transactions/',
      show: true,
    },
    {
      label: 'Address Book',
      route: '/address-book',
      show: true,
    },
    {
      label: 'Queue',
      route: '/queue',
      show: isQueuePageEnabled,
    },
    {
      label: 'Analytics',
      route: '/analytics',
      show: isAnalyticsPageEnabled,
    },
  ].filter((item) => item.show);
}

export const Header: FC = () => {
  const avatarMenu = useDropdownMenuState();
  const location = useLocation();
  const ldClient = useLDClient();
  const NavBarItems = useActiveNavBarItems();
  const client = useApolloClient();

  const { data } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);
  const fullName = data ? `${giveFirstWord(data.me.firstName)} ${giveFirstWord(data.me.lastName)}` : '';

  const handleNavigationClick = (to: string): Promise<void> => {
    return navigate(to);
  };

  const handleSignout = async () => {
    const resetUser = {
      key: '',
      firstName: '',
      lastName: '',
      email: '',
    };

    await signOut();
    await ldClient?.identify(resetUser);
    await client.resetStore();
  };

  return (
    <LayoutHeader>
      <Flex flexGrow={1} height="100%">
        <PlatformLogoFactory />
        <Flex
          alignItems="center"
          borderColor="carbon900"
          borderRight="1px solid"
          boxShadow="1px -1px 2px 0px #4F586F;"
          flex="1"
          justifyContent="space-between"
        >
          <NavBar pl="space60">
            {NavBarItems.map((navItem) => {
              let isRouteCurrentlyActive: boolean = false;

              if (location.pathname.includes('transaction') && navItem.route.includes('transaction'))
                isRouteCurrentlyActive = true;
              if (!location.pathname.includes('transactions') && location.pathname.includes(navItem.route))
                isRouteCurrentlyActive = true;

              return (
                <NavBarItem
                  key={`nav-bar-item-${navItem.label}`}
                  data-test-id={`nav-bar-item-${navItem.label}`}
                  selected={isRouteCurrentlyActive}
                  onClick={(): Promise<void> => handleNavigationClick(navItem.route)}
                >
                  {navItem.label}
                </NavBarItem>
              );
            })}
          </NavBar>
        </Flex>
        <Flex alignItems="center" pl="space50" pr="space60" py="space30">
          <DropdownMenuTrigger state={avatarMenu}>
            <Flex alignItems="center">
              <Avatar data-test-id="header-avatar" name={fullName} size={42} />
              <Icon color="carbon0" name="ArrowChevronDown" size="large" />
            </Flex>
          </DropdownMenuTrigger>
          <DropdownMenu aria-label="Main menu" state={avatarMenu}>
            <DropdownMenuItem onClick={() => handleSignout()}>Log out</DropdownMenuItem>
          </DropdownMenu>
        </Flex>
      </Flex>
    </LayoutHeader>
  );
};

Avatar.displayName = 'Avatar';
NavBarItem.displayName = 'NavBarItem';
