import { gql } from '@apollo/client';
import { OpsTask } from '@endpoint/opsware-bff-graphql-schema';

export const RECORD_TASK_STEP_RESPONSE = gql`
  mutation recordTaskStepResponse($where: BasicIdWhereInput!, $data: Json!) {
    recordTaskStepResponse(where: $where, data: $data) {
      id
      contacts {
        id
        phone
        email
        contactType
        ... on AgentContact {
          firstName
          middleName
          lastName
          licenseNumber
          mlsId
          stateOfOperation
        }
        ... on LenderContact {
          firstName
          middleName
          lastName
          nmlsId
        }
        ... on IndividualContact {
          firstName
          middleName
          lastName
        }
        ... on EntityContact {
          entityName
        }
      }
      taskDefinitionSchema
      steps {
        stepId
        taskId
        isCompleted
        response
        key
      }
    }
  }
`;

export interface RecordTaskStepResponseData {
  task: OpsTask;
}
