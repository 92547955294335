import { xor } from 'lodash';

export function reverseLookupByArray<T>(values: Array<T>, mapping: { [key: string]: Array<T> }): string | undefined {
  const entries = Object.entries<Array<T>>(mapping);
  const searchResult = entries.filter((keyValuePair) => xor(values, keyValuePair[1]).length === 0);

  if (searchResult.length) {
    return searchResult[0][0];
  }

  return undefined;
}
