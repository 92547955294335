import React, { FC } from 'react';
import { MilestoneType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { ButtonProps } from '@endpoint/blockparty/dist/components/Button';
import { useMilestoneValidationErrors } from 'hooks/useMilestoneValidationErrors';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';

interface ButtonMilestoneValidatedProps extends ButtonProps {
  transactionId: string;
}

export const ButtonMilestoneValidated: FC<ButtonMilestoneValidatedProps> = ({
  children,
  transactionId,
  style,
  isDisabled,
  ...componentProps
}) => {
  const { data, loading, error } = useMilestoneValidationErrors(transactionId, MilestoneType.ESCROW_OPENED);
  const disabled = !!data?.milestoneValidationErrors.length || !!error || isDisabled;
  const carbon700 = '#474747';
  const carbon500 = '#757575';
  const carbon0 = '#f7f7f7';

  if (isDisabled) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    componentProps['_before'] = { borderColor: 'carbon500' };
  }

  return (
    <ButtonWriteRequired
      {...componentProps}
      isDisabled={disabled}
      isLoading={loading}
      style={{
        color: isDisabled ? carbon0 : carbon700,
        outlineColor: isDisabled ? carbon500 : carbon700,
        backgroundColor: isDisabled ? carbon500 : '',
        ...style,
      }}
    >
      {children}
    </ButtonWriteRequired>
  );
};
