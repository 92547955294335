import { GroupBase, StylesConfig } from 'react-select';
import { TaskStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { theme } from '@endpoint/blockparty';

export interface SelectStateProps {
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  selectProps: {
    value: SelectOption;
  };
}

const fontStyles: { [key: string]: string } = {
  fontSize: '14px',
  lineHeight: '22px',
};

// this solution's typings and how they're derived come from this reference
// https://stackoverflow.com/questions/63696310/how-to-use-typescript-with-the-definition-of-custom-styles-for-a-select-using-re
export const statusSelectorStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
  // @ts-ignore
  control: (provided, { isFocused, selectProps }: Partial<unknown>) => ({
    ...provided,
    ...fontStyles,
    ...(selectProps.value && {
      borderColor: theme.colors.carbon300,
      '&:hover': {
        borderColor: isFocused ? theme.colors.inputActive : theme.colors.inputHover,
      },
    }),
    boxShadow: isFocused && theme.shadows.focus,
  }),
  menu: (base) => ({
    ...base,
    width: 200,
  }),
  option: (base, { isSelected, isFocused, isDisabled }) => ({
    ...base,
    ...fontStyles,
    backgroundColor: getBackgroundColor(isSelected, isFocused),
    color: isDisabled ? theme.colors.carbon300 : theme.colors.carbon900,
  }),
  // @ts-ignore
  dropdownIndicator: (base, { selectProps }: Partial<unknown>) => ({
    ...base,
    ...(selectProps.value && {
      color: theme.colors.carbon300,
      '&:hover': {
        color: theme.colors.carbon500,
      },
    }),
  }),
  // @ts-ignore
  indicatorSeparator: (styles, { selectProps }: Partial<unknown>) => ({
    ...styles,
    ...(selectProps.value && {
      backgroundColor: theme.colors.carbon300,
    }),
  }),
  // @ts-ignore
  singleValue: (styles, { selectProps }: Partial<unknown>) => ({
    ...styles,
    ...(selectProps.value && {
      color: theme.colors.carbon900,
    }),
  }),
};

export interface SelectOption {
  value: TaskStatus;
  label: string;
  isDisabled?: boolean;
}

function getBackgroundColor(isSelected: boolean, isFocused: boolean) {
  if (isSelected) {
    return 'blue0';
  }

  if (isFocused) {
    return 'carbon0';
  }

  return undefined;
}
