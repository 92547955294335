import * as yup from 'yup';
import { FormikState } from 'formik';
import { navigate } from '@reach/router';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { createTransaction } from 'components/OpenOrderDrawer/createTransactionMutation';
import { updateTransactionMutation } from 'helpers/prelimConversion/updateTransactionMutation';
import { scrollElementIntoView } from 'helpers/scrollElementIntoView';
import { CREATE_TRANSACTION_MUTATION_FAILED } from 'components/OpenOrderDrawer';
import { ApolloClient } from '@apollo/client';
import { DocumentReviewType } from 'routes/DocumentReview/contexts/DocumentReviewContext';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';

export const orderReviewValidationSchema = yup.object().shape({
  ocrComplete: yup.boolean().required('Required').oneOf([true], 'Waiting for OCR'),
  property: yup.object().shape({
    address: yup.object().shape({
      street1: yup.string().required('Required').trim(),
      street2: yup.string().trim(),
      city: yup.string().required('Required').trim(),
      state: yup.string().required('Required').trim(),
      zip: yup.string().required('Required').trim(),
      county: yup.string().required('Required').trim(),
    }),
    parcelNumber: yup.string().trim(),
  }),
  terms: yup.object().shape({
    productType: yup.string().required('Required').trim(),
    salePrice: yup.number().required('Required').min(0, 'Sale Price must be greater than or equal to 0'),
    loanNumber: yup.string().trim(),
  }),
  organization: yup.number().required('Required'),
});

export const handleSubmit = async (
  values: OpenOrderDrawerForm,
  {
    resetForm,
    setSubmitting,
    setStatus,
    client,
    clearUploads,
    documentReviewType,
  }: {
    resetForm: (nextState?: Partial<FormikState<OpenOrderDrawerForm>> | undefined) => void;
    setSubmitting: (isSubmitting: boolean) => void;
    setStatus: (status?: unknown) => void;
    client: ApolloClient<Object>;
    clearUploads: () => void;
    documentReviewType?: DocumentReviewType;
  },
) => {
  let transactionId: string;
  const castValues = orderReviewValidationSchema.cast(values) as unknown as OpenOrderDrawerForm;

  if (documentReviewType === DocumentReviewType.PRELIM_CONVERSION) {
    const response = await updateTransactionMutation(castValues, client);

    transactionId = response?.data?.updateTransaction.id;

    if (!transactionId) {
      setStatus({ error: CREATE_TRANSACTION_MUTATION_FAILED });
      setSubmitting(false);
      scrollElementIntoView('order-review-drawer-section');

      return;
    }

    trackAnalytics('Prelim Converted', {
      data: { fileNumber: response?.data?.updateTransaction.fileNum },
    });
  } else if (documentReviewType === DocumentReviewType.ORDER_REVIEW) {
    const response = await updateTransactionMutation({ ...castValues, reviewType: documentReviewType }, client);

    transactionId = response?.data?.updateTransaction.id;

    if (!transactionId) {
      setStatus({ error: CREATE_TRANSACTION_MUTATION_FAILED });
      setSubmitting(false);
      scrollElementIntoView('order-review-drawer-section');

      return;
    }

    trackAnalytics('EP Order Reviewed', {
      data: { ...castValues },
    });
  } else {
    const response = await createTransaction(castValues, client);

    transactionId = response?.data?.createTransaction.id;

    if (!transactionId) {
      setStatus({ error: CREATE_TRANSACTION_MUTATION_FAILED });
      setSubmitting(false);
      scrollElementIntoView('order-review-drawer-section');

      return;
    }

    trackAnalytics('New Order Submitted', {
      data: { ...castValues },
    });
  }

  setSubmitting(false);
  clearUploads();
  resetForm();

  await navigate(`/transaction/id-${transactionId}`);
};
