import { RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { Page } from 'consts/segmentProtocols';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box } from '@endpoint/blockparty';
import { SkeletonPage } from 'components/SkeletonPage';
import { trackPage } from 'helpers/utils/segment/segmentAnalytics';
import { Dogs404 } from 'routes/404';
import { Dogs403 } from 'routes/403';
import { useIsSystemOrOpsAdmin } from 'hooks/useIsSystemOrOpsAdmin';

interface AdminProps extends RouteComponentProps {}

const Admin: React.FC<AdminProps> = ({ children }) => {
  const { canViewOpswareNavigationPopover } = useFlags();
  const { isAdmin, loading: isAdminLoading } = useIsSystemOrOpsAdmin();

  useEffect(() => {
    trackPage<Page>('Open Admin');
  }, []);

  if (!canViewOpswareNavigationPopover) {
    return <Dogs404 />;
  }

  if (isAdminLoading) {
    return <SkeletonPage />;
  }

  if (!isAdmin) {
    return <Dogs403 />;
  }

  return <Box>{children}</Box>;
};

export default Admin;
