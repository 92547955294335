import { MilestoneStatus, MilestoneType } from '@endpoint/opsware-bff-graphql-schema/dist/types';

type MilestoneStatusMap = { [index in MilestoneStatus]: string };

type MilestoneStatusAndTitlesMap = {
  [milestoneTypeIndex in MilestoneType]: { [index in MilestoneStatus]: string };
};

export const milestoneStatusMap: MilestoneStatusMap = {
  [MilestoneStatus.COMPLETE]: 'Complete',
  [MilestoneStatus.INTERMEDIATE]: 'Incomplete',
  [MilestoneStatus.UPCOMING]: 'Upcoming',
};

export const editMilestoneStatusOrder: MilestoneStatus[] = [
  MilestoneStatus.UPCOMING,
  MilestoneStatus.INTERMEDIATE,
  MilestoneStatus.COMPLETE,
];

export const editMilestoneStateTextMap: MilestoneStatusMap = {
  [MilestoneStatus.UPCOMING]: 'Incomplete',
  [MilestoneStatus.INTERMEDIATE]: 'Intermediate',
  [MilestoneStatus.COMPLETE]: 'Completed',
};

export const milestoneStatusAndTitlesMap: MilestoneStatusAndTitlesMap = {
  [MilestoneType.ESCROW_OPENED]: {
    [MilestoneStatus.UPCOMING]: 'Escrow Opened',
    [MilestoneStatus.INTERMEDIATE]: 'Escrow Opened',
    [MilestoneStatus.COMPLETE]: 'Escrow Opened',
  },
  [MilestoneType.EARNEST_MONEY_DEPOSIT]: {
    [MilestoneStatus.UPCOMING]: 'Make Earnest Money Deposit',
    [MilestoneStatus.INTERMEDIATE]: 'Make Earnest Money Deposit',
    [MilestoneStatus.COMPLETE]: 'Earnest Money Received',
  },
  [MilestoneType.TITLE_COMMITMENT]: {
    [MilestoneStatus.UPCOMING]: 'Title Commitment in Progress',
    [MilestoneStatus.INTERMEDIATE]: 'Title Commitment in Progress',
    [MilestoneStatus.COMPLETE]: 'Title Commitment Delivered',
  },
  [MilestoneType.READY_TO_CLOSE]: {
    [MilestoneStatus.UPCOMING]: 'Title Work in Progress',
    [MilestoneStatus.INTERMEDIATE]: 'Title Work in Progress',
    [MilestoneStatus.COMPLETE]: 'Endpoint Ready to Close',
  },
  [MilestoneType.BUYER_SIGNING_SCHEDULED]: {
    [MilestoneStatus.UPCOMING]: 'Buyer Schedule Signing',
    [MilestoneStatus.INTERMEDIATE]: 'Buyer Schedule Signing',
    [MilestoneStatus.COMPLETE]: 'Buyer Signing Scheduled',
  },
  [MilestoneType.SELLER_SIGNING_SCHEDULED]: {
    [MilestoneStatus.UPCOMING]: 'Seller Schedule Signing',
    [MilestoneStatus.INTERMEDIATE]: 'Seller Schedule Signing',
    [MilestoneStatus.COMPLETE]: 'Seller Signing Scheduled',
  },
  [MilestoneType.BUYER_CLOSING_DOCUMENTS_SIGNED]: {
    [MilestoneStatus.UPCOMING]: 'Buyer Sign Closing Documents',
    [MilestoneStatus.INTERMEDIATE]: 'Buyer Sign Closing Documents',
    [MilestoneStatus.COMPLETE]: 'Buyer Closing Documents Signed',
  },
  [MilestoneType.SELLER_CLOSING_DOCUMENTS_SIGNED]: {
    [MilestoneStatus.UPCOMING]: 'Seller Sign Closing Documents',
    [MilestoneStatus.INTERMEDIATE]: 'Seller Sign Closing Documents',
    [MilestoneStatus.COMPLETE]: 'Seller Closing Documents Signed',
  },
  [MilestoneType.RECORDING_COMPLETE]: {
    [MilestoneStatus.UPCOMING]: 'Record Documents',
    [MilestoneStatus.INTERMEDIATE]: 'Released to Record',
    [MilestoneStatus.COMPLETE]: 'Recording Complete',
  },
  [MilestoneType.TRANSACTION_DISBURSED]: {
    [MilestoneStatus.UPCOMING]: 'Transaction to be disbursed',
    [MilestoneStatus.INTERMEDIATE]: 'Transaction to be disbursed',
    [MilestoneStatus.COMPLETE]: 'Transaction Disbursed',
  },
};
