import { TaskStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TaskStatusColorMap } from 'consts/taskConsts';
import { TagVariantColor } from '@endpoint/blockparty/dist/components/Tag';
import { ReactElement } from 'react';

export function mapStatusText(status: string, mapping: { [index: string]: string }): string {
  return mapping[status] || '';
}

// TODO refactor to use blockparty colors types keyof ThemeShape['colors'] stead of index: string
export function mapStatusToColor(status: string, mapping: { [index: string]: string }): string {
  return mapping[status];
}

export function mapStatusToColorVariant(
  status: string,
  mapping: { [index: string]: TagVariantColor },
): TagVariantColor {
  return mapping[status];
}

export const mapStatusToTextColor = (status: TaskStatus, mapping: TaskStatusColorMap): string => mapping[status];

export function mapStatusToReactElement<T extends keyof never = string>(status: T, mapping: Record<T, ReactElement>) {
  return mapping[status];
}
