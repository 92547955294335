import React, { FC, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import {
  Box,
  Popover,
  Flex,
  Button,
  Avatar,
  Text,
  Icon,
  Link,
  usePopoverState,
  PopoverTrigger,
  AvatarIcon,
} from '@endpoint/blockparty';
import { formatPhoneNumber } from 'helpers/formatPhone';
import { giveFirstWord, formatRolesLikeTitle } from 'helpers/formatText';
import { SignUpStatusInterface } from 'routes/Transaction/ContactDetails/Info';

import { ContactWithRoles } from '..';

interface AvatarPopoverInterface {
  contact: ContactWithRoles;
  fileNumber: string;
}

interface AvatarPopoverContentsInterface {
  contact: ContactWithRoles;
  fileNumber: string;
}

export const AvatarPopover: FC<AvatarPopoverInterface> = ({ contact, fileNumber, ...rest }) => {
  const popover = usePopoverState({ placement: 'bottom-end' });
  const { firstName, lastName, entityName, isOnboarded, id, isDeactivated } = contact;
  const avatarName = entityName
    ? giveFirstWord(entityName)
    : `${giveFirstWord(firstName || '')} ${giveFirstWord(lastName || '')}`;

  const handleMouseEnter = useCallback(() => popover.show(), [popover]);

  const handleMouseLeave = useCallback(() => popover.hide(), [popover]);

  return (
    <Box data-test-id="avatar-popover" onMouseLeave={handleMouseLeave}>
      <Avatar
        {...rest}
        as={PopoverTrigger}
        {...popover}
        key={id}
        name={avatarName}
        size={32}
        src=""
        onMouseEnter={handleMouseEnter}
      >
        {isDeactivated && <AvatarIcon bg="watermelon500" name="Minus" />}
        {!isOnboarded && <AvatarIcon bg="marigold400" name="Minus" />}
      </Avatar>
      <Popover {...popover} aria-label="popover-contact">
        <AvatarPopoverContents contact={contact} fileNumber={fileNumber} />
      </Popover>
    </Box>
  );
};

export const SignUpStatusPopoverContents: FC<SignUpStatusInterface> = ({ isDeactivated, isOnboarded }) => {
  if (isDeactivated) {
    return (
      <Text data-test-id="contact-not-activated" mt="space20">
        <Icon color="watermelon500" mr="space30" name="Warning" />
        Deactivated
      </Text>
    );
  }

  if (!isOnboarded) {
    return (
      <Text data-test-id="contact-not-onboarded" mt="space20">
        <Icon color="marigold400" mr="space30" name="NotOnboarded" />
        Not Onboarded
      </Text>
    );
  }

  return null;
};

export const AvatarPopoverContents: FC<AvatarPopoverContentsInterface> = ({ contact, fileNumber }) => {
  const navigate = useNavigate();
  const { firstName, lastName, entityName, phone, email, isOnboarded, participantId, roles, isDeactivated } = contact;
  const avatarName = entityName
    ? `${giveFirstWord(entityName)}`
    : `${giveFirstWord(firstName || '')} ${giveFirstWord(lastName || '')}`;
  const fullName = entityName || `${firstName} ${lastName}`;

  return (
    <Box maxWidth={520} minWidth={320} p="space50">
      <Flex mb="space50">
        <Box>
          <Avatar name={avatarName} size={40} />
        </Box>
        <Flex flexDirection="column" ml="space50">
          <Text fontWeight="semi">{fullName}</Text>
          <Text data-test-id="contact-avatar-roles">{formatRolesLikeTitle(roles.join(', '), true)}</Text>

          <Flex flexDirection="column" mt="space40">
            <Link data-test-id="contact-avatar-phone" href={`tel:${phone}`}>
              <Icon color="carbon500" mr="3" name="Phone" />
              {formatPhoneNumber(phone || '') || '-'}
            </Link>
            <Link data-test-id="contact-avatar-email" href={`mailto:${email}`} mt="space20">
              <Icon color="carbon500" mr="3" name="Email" />
              {email}
            </Link>
            <SignUpStatusPopoverContents isDeactivated={!!isDeactivated} isOnboarded={!!isOnboarded} />
          </Flex>
        </Flex>
      </Flex>
      <Button
        data-test-id="contact-avatar-link"
        size="medium"
        variant="outline"
        width="100%"
        onClick={() => navigate(`/transaction/${fileNumber}/contacts/${participantId}`)}
      >
        View Profile
      </Button>
    </Box>
  );
};

Avatar.displayName = 'Avatar';
AvatarIcon.displayName = 'AvatarIcon';
