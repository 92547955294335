import * as yup from 'yup';

export interface PsaDocumentData {
  id: string;
  storageUrl: string;
}

export const editTransactionBasicInfoValidationSchema = yup.object().shape({
  address: yup.string().required('Address is a required field').trim(),
  address2: yup.string().trim(),
  city: yup.string().required('City is a required field').trim(),
  state: yup.string().required('Please select a state').trim(),
  zip: yup
    .string()
    .required('Please enter a valid zip code')
    .matches(/^[0-9]+$/, 'Please enter a valid zip code')
    .min(5, 'Please enter a valid zip code')
    .max(5, 'Please enter a valid zip code')
    .trim(),
  county: yup.string().required('County is a required field').trim(),
  parcelNumber: yup.string().trim(),
  salePrice: yup
    .number()
    .required('Sale price is a required field')
    .min(0, 'Sale Price must be greater than or equal to 0'),
  loanNumber: yup.string().trim(),
});
