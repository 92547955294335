import React, { FC } from 'react';
import { SingleValueProps } from 'react-select';
import { SimpleAddress } from '@endpoint/opsware-bff-graphql-schema';
import { selectComp } from '@endpoint/blockparty';

import { SingleAddressValue } from './SingleValueContent';

export const SingleValue: FC<SingleValueProps<SimpleAddress>> = ({ children, data, ...props }) => {
  return (
    // @ts-ignore
    <selectComp.SingleValue {...props}>
      <SingleAddressValue selectedData={data} {...props} />
      {children}
    </selectComp.SingleValue>
  );
};
