import { Box, Divider, DrawerHeader, DrawerSection, Flex, Icon, Text } from '@endpoint/blockparty';
import { FileUploadProgress } from 'components/DocumentUploadDrawer/FileUploadProgress';
import { PSA_DOCUMENT_TYPE } from 'consts/psaDocumentType';
import { DocumentUploadStatus, mimeTypes, DocumentUpload } from 'consts/uploadDocumentsConsts';
import { useFormikContext } from 'formik';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDocumentReviewContext, DocumentReviewType } from 'routes/DocumentReview/contexts/DocumentReviewContext';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';

interface EscrowConversionPsaUploadProps {
  title?: string;
}

export const EscrowConversionPsaUpload: FC<EscrowConversionPsaUploadProps> = ({ title = 'Open Escrow' }) => {
  const { values } = useFormikContext<OpenOrderDrawerForm>();
  const { data, uploadUnassignedDocument } = useUploadDocument();
  const uploads = useMemo(() => data?.uploads ?? [], [data?.uploads]);
  const { setDocumentProperties } = useDocumentReviewContext();

  useEffect(() => {
    if (uploads?.length) {
      if (uploads[0]?.status === DocumentUploadStatus.COMPLETED && values.property.address.state) {
        setDocumentProperties({
          documentId: uploads[0]!.fileUpload!.documentId as string,
          documentUrl: uploads[0]?.fileUpload!.downloadUrl as string,
          documentReviewType: DocumentReviewType.PRELIM_CONVERSION,
          documentRegion: values.property.address.state,
        });
      }
    }
  }, [uploads, setDocumentProperties, values.property.address.state]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        uploadUnassignedDocument({
          file: acceptedFiles[0],
          type: PSA_DOCUMENT_TYPE,
          name: acceptedFiles[0].name,
          region: values.property.address.state as string,
        });
      }
    },
    [uploadUnassignedDocument, values],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: mimeTypes,
    disabled: !values.property.address.state || !!uploads[0],
    multiple: false,
    onDrop,
  });

  return (
    <>
      <DrawerHeader border="none">
        <Text as="h2" fontWeight="semi" size="fontSize40">
          {title}
        </Text>
        <Text as="p" fontWeight="normal" mt="space50" size="fontSize20">
          Maximum upload file size 50MB. PDF only.
        </Text>
      </DrawerHeader>
      <Divider mx="6" />
      <DrawerSection mt="space60">
        <Text data-test-id="upload-psa-text" fontWeight="semi" size="fontSize30">
          Upload a purchase agreement
        </Text>
        <br />
        <Flex flexDirection="column" mt="space60">
          {(!uploads || !uploads.length) && (
            <Box className="opsware-upload-dropzone" data-test-id="upload-box">
              {/*
            // @ts-ignore - react-table issue */}
              <Box
                data-test-id="upload-target"
                {...getRootProps({ className: 'dropzone disabled' })}
                cursor={values.property.address.state ? 'pointer' : 'not-allowed'}
                mb="space40"
              >
                {/*
            // @ts-ignore - react-table issue */}
                <Box as="input" {...getInputProps()} />
                <Flex
                  alignItems="center"
                  border="1px dashed"
                  className="opsware-upload-dropdown-wrapper"
                  justifyContent="center"
                  py="20px"
                >
                  <Icon
                    color={!values.property.address.state ? 'blue200' : 'blue500'}
                    mr="space30"
                    name="Upload"
                    size="large"
                  />
                  <Text color={!values.property.address.state ? 'carbon500' : 'carbon900'} size="fontSize20">
                    Choose a file or drag it here
                  </Text>
                </Flex>
              </Box>
            </Box>
          )}
          <Box data-test-id="progress-container" mt="space50">
            {uploads && uploads.length > 0
              ? uploads.map((upload: DocumentUpload) => (
                  <Box key={upload.id} pb="space70">
                    <FileUploadProgress document={upload} isRemovable />
                  </Box>
                ))
              : null}
          </Box>
        </Flex>
      </DrawerSection>
    </>
  );
};
