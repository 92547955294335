import React, { FC } from 'react';
import {
  Todo,
  TodoAssignment,
  TodoStatus,
  TransactionParticipant,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { dateFormatter } from 'helpers/datetimeHelper';
import { formatLikeTitle } from 'helpers/formatText';
import { CardBodyRow, AssigneesRow } from 'components/Card/CardBodyRows';

interface CardBodyProp {
  data: Todo;
}

export const ToDoCardBody: FC<CardBodyProp> = ({ data }) => {
  const todoAssignmentsNotRecalled = (data.assignments || []).filter(
    (assignment: TodoAssignment) => assignment.status !== TodoStatus.RECALLED,
  );

  const participants: TransactionParticipant[] = todoAssignmentsNotRecalled.map(
    (assignment: TodoAssignment) => assignment.assignee,
  );

  const dueDate = data.due ? dateFormatter(new Date(data.due), 'eee, MMM dd') : '-';
  const syncType = formatLikeTitle(data.syncType);
  const hasAssignees = todoAssignmentsNotRecalled.length > 0;

  return (
    <>
      <CardBodyRow data-test-id="due-date" label="Due Date" value={dueDate} />
      <CardBodyRow data-test-id="note" label="Note" value={data.escrowNote || '-'} />
      <CardBodyRow data-test-id="sync-type" label="Sync Type" value={syncType} />
      {hasAssignees ? <AssigneesRow label="Assignees" values={participants} /> : null}
    </>
  );
};
