import React, { FC } from 'react';
import { SingleValueProps } from 'react-select';
import { Contact } from '@endpoint/opsware-bff-graphql-schema';
import { selectComp } from '@endpoint/blockparty';

import { SingleValueContent } from './SingleValueContent';

export const SingleValue: FC<SingleValueProps<Contact>> = ({ children, ...props }) => {
  const { data } = props;

  return (
    // @ts-ignore
    <selectComp.SingleValue {...props}>
      <SingleValueContent selectedData={data} {...props} />
      {children}
    </selectComp.SingleValue>
  );
};
