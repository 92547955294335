import { gql } from '@apollo/client';

export const RERUN_1099_REPORT = gql`
  mutation Report1099($where: Report1099Input!) {
    report1099(where: $where)
  }
`;

export const VOID_1099_REPORT = gql`
  mutation Void1099($where: Void1099Input!) {
    void1099(where: $where)
  }
`;

export const UPDATE_TRANSACTION_PARTICIPANT = gql`
  mutation UpdateTransactionParticipant(
    $where: TransactionParticipantWhereUniqueInput!
    $input: TransactionParticipantUpdateInput!
  ) {
    updateTransactionParticipant(where: $where, input: $input) {
      id
      contact {
        id
        ... on AgentContact {
          firstName
          lastName
          middleName
        }
        ... on LenderContact {
          firstName
          lastName
          middleName
        }
        ... on IndividualContact {
          firstName
          lastName
          middleName
        }
        ... on EntityContact {
          entityName
        }
      }
      isDirectingContact
    }
  }
`;
