import React, { FC } from 'react';
import { Button, Flex, Modal, ModalContent, ModalFooter, ModalSection, Text } from '@endpoint/blockparty';

interface ConfirmMarketStatusModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmMarketStatusModal: FC<ConfirmMarketStatusModalProps> = ({ title, isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalSection>
          <Flex flexDirection="column">
            <Text as="h3" fontWeight="semi" mb="space0" size="fontSize30">
              {title}
            </Text>
          </Flex>
        </ModalSection>
        <ModalFooter>
          <Button mr="space30" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button dataTestId="confirm-modal-button" minWidth={105} onClick={onConfirm}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
