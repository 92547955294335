import React, { FC } from 'react';
import {
  InputGroup,
  Flex,
  InputLabel,
  InputElemGroup,
  DatePicker,
  InputRightElement,
  Icon,
} from '@endpoint/blockparty';
import { EditTransactionBasicInfoDateProps } from 'consts/EditTransactionBasicInfoDateProps';
import { toLocalIsoDate } from 'helpers/datetimeHelper';

import { NonEditableDateField } from './NonEditableDateField';

export const EditTransactionBasicInfoSaleDateFields: FC<EditTransactionBasicInfoDateProps> = ({
  setFieldValue,
  focusField,
  values,
}) => {
  return (
    <>
      <InputGroup groupId="closingDate">
        <Flex flexDirection="row" justifyContent="space-between">
          <InputLabel>Estimated Closing Date</InputLabel>
        </Flex>
        <Flex>
          <InputElemGroup>
            <DatePicker
              autoFocus={focusField === 'Estimated Closing Date'}
              dataTestId="closing-date-picker"
              selected={values.estimatedClosing ? new Date(values.estimatedClosing) : null}
              onChange={(date) => {
                setFieldValue('estimatedClosing', date);
              }}
            />
            <InputRightElement>
              <Icon color="carbon400" name="CalendarDateRange" size="20px" />
            </InputRightElement>
          </InputElemGroup>
        </Flex>
      </InputGroup>
      <NonEditableDateField date={values.escrowOpenedDate} label="Contract Received Date" />

      <InputGroup groupId="emdDue">
        <InputLabel>EMD Due Date</InputLabel>
        <Flex>
          <InputElemGroup iconRightSpacing>
            <DatePicker
              autoFocus={focusField === 'EMD Due Date'}
              dataTestId="emd-due-date-picker"
              selected={values.emdDueDate ? new Date(values.emdDueDate) : null}
              onChange={(date) => {
                setFieldValue('emdDueDate', date ? toLocalIsoDate(date) : date);
              }}
            />
            <InputRightElement>
              <Icon color="carbon400" name="CalendarDateRange" size="20px" />
            </InputRightElement>
          </InputElemGroup>
        </Flex>
      </InputGroup>

      <InputGroup groupId="contractExecutedDate">
        <InputLabel>Contract Executed Date</InputLabel>
        <Flex>
          <InputElemGroup iconRightSpacing>
            <DatePicker
              autoFocus={focusField === 'Contract Executed Date'}
              dataTestId="contract-date-picker"
              selected={values.contractExecutionDate ? new Date(values.contractExecutionDate) : null}
              onChange={(date) => {
                setFieldValue('contractExecutionDate', date);
              }}
            />
            <InputRightElement>
              <Icon color="carbon400" name="CalendarDateRange" size="20px" />
            </InputRightElement>
          </InputElemGroup>
        </Flex>
      </InputGroup>
    </>
  );
};
