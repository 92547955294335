import { MultiSelect } from '@endpoint/blockparty';
import * as React from 'react';

const statusSelectOptions = [
  {
    label: 'Activated',
    value: true,
  },
  {
    label: 'Inactive',
    value: false,
  },
];

export interface StatusSelectProps {
  onChange: (selectedValues: boolean[]) => void;
}

const StatusSelect: React.FC<StatusSelectProps> = ({ onChange }) => {
  return (
    <MultiSelect
      controlText="Status"
      options={statusSelectOptions}
      placeholder="Status"
      onChange={(selectedOptions: { value: boolean }[]) => {
        onChange(selectedOptions.map((option) => option.value));
      }}
    />
  );
};

export default StatusSelect;
