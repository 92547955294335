import React from 'react';
import { Select, Box, Flex } from '@endpoint/blockparty';
import { uniq } from 'lodash';
import { Task, TaskStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { SelectProps } from '@endpoint/blockparty/dist/components/Select';
import { mapStatusToColor, mapStatusText } from 'helpers/statusHelper';
import {
  taskStatusBgColorMap,
  taskStatusMap,
  taskStatusBorderColorMap,
  taskStatusDisabledBorderColorMap,
  taskStatusDisabledBgColorMap,
} from 'consts/taskConsts';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';

import { statusSelectorStyles, SelectOption } from './styles';

interface TaskStatusButtonProps extends Partial<SelectProps> {
  task: Task;
  onChange(task: TaskStatus): void;
}

interface Option {
  readonly value: string;
  readonly label: string;
  readonly isDisabled?: boolean;
}

export const FormatOptionLabel = (
  data: unknown,
  formatOptionLabelMeta: FormatOptionLabelMeta<unknown>,
): React.ReactNode => {
  const { value, label, isDisabled } = data as Option;
  const valueTask = convertStringToTaskEnum(value);
  const { context, selectValue } = formatOptionLabelMeta as FormatOptionLabelMeta<Option>;

  const currentOption = selectValue.map((option: Option) => option.value).includes(value) && context === 'value';

  let backgroundColor;

  if (currentOption) {
    backgroundColor = mapStatusToColor(valueTask, taskStatusBgColorMap);
  } else if (isDisabled) {
    backgroundColor = mapStatusToColor(valueTask, taskStatusDisabledBgColorMap);
  } else {
    backgroundColor = mapStatusToColor(valueTask, taskStatusBgColorMap);
  }

  return (
    <Flex alignItems="center">
      <Box
        bg={backgroundColor}
        border="1px solid"
        // @ts-ignore - revisit types
        borderColor={mapStatusToColor(
          valueTask,
          isDisabled ? taskStatusDisabledBorderColorMap : taskStatusBorderColorMap,
        )}
        borderRadius="50%"
        data-test-id="format-option-label-container"
        mr="space50"
        size="14px"
      />
      <Box data-test-id="format-option-label">{label}</Box>
    </Flex>
  );
};

export const TaskStatusButton: React.FC<TaskStatusButtonProps> = ({ task, onChange, ...rest }) => {
  // construct an obj of current status and possible statuses
  const selectOptions = constructSelectOptions(task.status, task.statusOptions);

  const currentOption = selectOptions[0];

  return (
    <Select
      data-test-id="task-status-button"
      formatOptionLabel={FormatOptionLabel}
      options={selectOptions}
      styles={statusSelectorStyles}
      value={currentOption}
      {...rest}
      onChange={(option: SelectOption): void => {
        onChange(option.value);
      }}
    />
  );
};

const constructSelectOptions = (status: TaskStatus, statusOptions: TaskStatus[]): SelectOption[] => {
  const enabledOptions = [...statusOptions];

  const options = uniq([status, ...Object.values(TaskStatus)]);
  const selectOptions = options.map(mapStatusToSelectOption);

  return selectOptions.map((selectOption) => ({
    ...selectOption,
    isDisabled: !enabledOptions.includes(selectOption.value),
  }));
};

export interface FormatLabelOptionProps<T> extends SelectOptionsObject<T> {
  isDisabled?: boolean;
}

export interface FormatLabelOptionValues<T> {
  selectValue: FormatLabelOptionProps<T>[];
  context: string;
}

export const mapStatusToSelectOption = (status: TaskStatus): SelectOption => ({
  label: mapStatusText(status, taskStatusMap),
  value: status,
  isDisabled: true,
});

export const convertStringToTaskEnum = (status: string): TaskStatus => {
  for (const value of Object.values(TaskStatus)) {
    if (value === status) {
      return value;
    }
  }

  return TaskStatus.NOT_STARTED;
};

TaskStatusButton.displayName = 'TaskStatusButton';
