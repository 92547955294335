import React, { FC } from 'react';
import { Box, Divider, DrawerSection, Text } from '@endpoint/blockparty';
import './styles.scss';
import { ErrorMessage } from 'components/ErrorMessage';
import { useFormikContext } from 'formik';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';
import { TransactionType } from '@endpoint/opsware-bff-graphql-schema/dist/types';

import { Terms } from './Terms';
import { Dates } from './Dates';
import { SettlementAndFees } from './SettlementAndFees';
import { CREATE_TRANSACTION_MUTATION_FAILED } from '..';

export const Step2: FC = () => {
  const { status, setStatus, values } = useFormikContext<OpenOrderDrawerForm>();

  const isRefinance = values.terms?.productType === TransactionType.REFINANCE;
  const isEquity = values.terms?.productType === TransactionType.EQUITY;

  const showDivider = !isRefinance && !isEquity && <Divider mt="space60" />;
  const showDates = !isRefinance && !isEquity && <Dates />;

  return (
    <DrawerSection>
      <Text as="h1" fontWeight="semi" size="fontSize50">
        Enter order details
      </Text>
      <Box data-test-id="open-order-drawer-step2-box" mt="40px" pb={status.error ? 'space50' : 'space0'}>
        {status.error === CREATE_TRANSACTION_MUTATION_FAILED && (
          <ErrorMessage
            closeAction={() => setStatus({ error: '' })}
            description="There was a problem saving your order"
            title="Try again or contact the Opsware product team for assistance."
          />
        )}
        <Text as="h2" fontWeight="semi" size="fontSize30">
          Terms
        </Text>
        <Terms />
      </Box>

      {showDivider}
      {showDates}
    </DrawerSection>
  );
};
