import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Grid, Text, DatePicker } from '@endpoint/blockparty';
import { OpsTask, OpsTaskStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TaskStatusButton } from 'components/OpsTaskStatusButton';
import { ErrorMessage } from 'components/ErrorMessage';
import { updateOpsTaskPayload } from 'helpers/opsTask';

import { UPDATE_OPS_TASK } from '../queries';

interface TaskFiltersProps {
  task: OpsTask;
}

// TODO: Add tracking
export const TaskFilters: FC<TaskFiltersProps> = ({ task }) => {
  const [dueDate, setDueDate] = useState(new Date(task?.dueDateTime));
  const [updateOpsTask, { error }] = useMutation(UPDATE_OPS_TASK);

  if (!task) {
    return (
      <Box pt="space60">
        <ErrorMessage />
      </Box>
    );
  }

  const updateTaskStatus = (status: OpsTaskStatus): void => {
    void updateOpsTask(updateOpsTaskPayload(task.id, { status }));
  };

  const updateTaskDueDate = (dueOn: Date): void => {
    setDueDate(dueOn || dueDate);
    void updateOpsTask(updateOpsTaskPayload(task.id, { dueOn }));
  };

  return (
    <Box data-test-id="task-details">
      <Box p="space60">
        {error && (
          <Box pt="space60">
            <ErrorMessage />
          </Box>
        )}
        <Grid gap="space50" templateColumns="repeat(2, 1fr)">
          <TaskStatusButton task={task} onChange={updateTaskStatus} />
          <DatePicker iconType="calendar" selected={dueDate} onChange={updateTaskDueDate} />
          <Text>Global</Text>
          <Text>{task.assigneeName || 'N/A'}</Text>
        </Grid>
      </Box>
    </Box>
  );
};
