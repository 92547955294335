import React, { FC, useContext, useState } from 'react';
import { Box, Text, Divider, Icon, Button } from '@endpoint/blockparty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { dateFormatter } from 'helpers/datetimeHelper';
import { TransactionContext } from 'routes/Transaction';
import { formatBytes } from 'helpers/documents';
import { ErrorMessage } from 'components/ErrorMessage';

import { DocumentDetailsProps } from '../index';
import { useDownloadDocument } from './useDownloadDocument';
import { deriveMimeTypeText, getDocumentDisplayName } from '../../DocumentsTable/helpers';
import { DocumentName } from './DocumentName';

export const DocumentDetails: FC<DocumentDetailsProps> = ({ document }) => {
  const { isOpsWareBffEnabled } = useFlags();

  const mimeType = deriveMimeTypeText(document?.storage?.mimeType);

  const addedBy = document?.uploadedBy ? `by ${document.uploadedBy}` : '';

  const { id: transactionId } = useContext(TransactionContext);
  const cleanDocumentName = document?.name ? getDocumentDisplayName(document.name, mimeType) : '';
  const downloadDocument = useDownloadDocument(transactionId, document?.id || '', cleanDocumentName);
  const [downloadErrorOccurred, setDownloadErrorOccurred] = useState(false);
  const [downloadingDocument, setDownloadingDocument] = useState(false);

  const handleDownloadDocumentClicked = async () => {
    try {
      setDownloadingDocument(true);
      await downloadDocument();
    } catch {
      setDownloadErrorOccurred(true);
    } finally {
      setDownloadingDocument(false);
    }
  };

  return (
    <Box data-test-id="document-details">
      <Box p="space60">
        {isOpsWareBffEnabled ? (
          <DocumentName documentId={document?.id} documentName={cleanDocumentName} transactionId={transactionId} />
        ) : (
          <Text
            as="p"
            data-test-id="document-details-title"
            fontWeight="semi"
            pb="space30"
            size="fontSize50"
            style={{ wordWrap: 'break-word' }}
          >
            {cleanDocumentName}
          </Text>
        )}
        <Box>
          <Text color="carbon500" data-test-id="document-date-added" fontWeight="normal">
            Added {addedBy} {dateFormatter(document?.createdAt, 'MMM dd, yyyy h:mm a')}
          </Text>
        </Box>
        <Box pb="space30">
          {document?.storage?.size ? (
            <Text color="carbon500" data-test-id="document-storage-size" fontWeight="normal">
              {formatBytes(document.storage.size)}
            </Text>
          ) : null}
          {document?.storage?.size && mimeType && (
            <Icon color="carbon200" data-test-id="bullet" name="Bullet" size="medium" />
          )}
          <Text color="carbon500" data-test-id="document-mimeType" fontWeight="normal">
            {mimeType.substring(1).toUpperCase()}
          </Text>
        </Box>

        <Box mt="space50">
          <Button
            data-test-id="document-download-button"
            iconLeft="Download"
            isLoading={downloadingDocument}
            loadingText="Downloading..."
            variant="outline"
            onClick={handleDownloadDocumentClicked}
          >
            Download
          </Button>
        </Box>
        {downloadErrorOccurred && (
          <Box mt="space60">
            <ErrorMessage
              closeAction={() => setDownloadErrorOccurred(false)}
              description="Unable to download document. Please try again."
              title="Download Error"
            />
          </Box>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

DocumentDetails.displayName = 'DocumentDetails';
