import { gql, useApolloClient } from '@apollo/client';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export function useDownloadDocument(transactionId: string, documentId: string, documentName: string) {
  const apolloClient = useApolloClient();

  return async function downloadDocument() {
    const { data } = await apolloClient.query<RefreshDocumentResponse>({
      query: QUERY,
      fetchPolicy: 'network-only',
      variables: {
        transactionInput: {
          id: transactionId,
        },
        documentInput: {
          id: documentId,
        },
      },
    });

    const documentDownloadUrl = data.transaction.document?.storage?.url;

    if (documentDownloadUrl) {
      const response = await fetch(documentDownloadUrl);
      const dataBlob = await response.blob();
      const url = window.URL.createObjectURL(dataBlob);
      const link = document.createElement('a');

      link.href = url;
      link.id = 'document-download-widget';
      link.download = documentName;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
}

export const QUERY = gql`
  query refreshDocumentUrl($transactionInput: TransactionWhereUniqueInput!, $documentInput: DocumentWhereUniqueInput!) {
    transaction(where: $transactionInput) {
      id
      document(where: $documentInput) {
        id
        storage {
          url
        }
      }
    }
  }
`;

interface RefreshDocumentResponse {
  transaction: Transaction;
}
