import { Box, MultiSelect } from '@endpoint/blockparty';
import { squadDisplayValueMap, supportedSquads } from 'consts/supportedTaskSquad';
import React, { FC } from 'react';

const selectTaskSquadOptions = supportedSquads.map((squad) => ({
  value: squad,
  label: squadDisplayValueMap[squad],
}));

interface TaskSquadSelectProps {
  onChange(squads: string[]): void;
  defaultValue?: string[];
}

export const TaskSquadSelect: FC<TaskSquadSelectProps> = ({ onChange, defaultValue = [] }) => {
  const defaultValueOptions = selectTaskSquadOptions.filter((option) => {
    return defaultValue.includes(option.value);
  });

  return (
    <Box data-test-id="task-squad-select" minWidth={190}>
      <MultiSelect
        controlText="By Squad"
        data-test-id="task-squad-select-filter"
        defaultValue={defaultValueOptions}
        options={selectTaskSquadOptions}
        onChange={(values: { value: string }[]) => onChange(values.map((value) => String(value.value)))}
      />
    </Box>
  );
};
