import {
  OpenOrderDrawerForm,
  OpenOrderDrawerFormStep,
  OpenOrderDrawerFormType,
} from 'helpers/openOrder/openOrderFormTypes';
import { TransactionType } from '@endpoint/opsware-bff-graphql-schema';

export const emptyOpenOrderDrawerForm = (properties: Partial<OpenOrderDrawerForm> = {}): OpenOrderDrawerForm => ({
  currentStep: OpenOrderDrawerFormStep.STEP1,
  formType: OpenOrderDrawerFormType.MANUAL,
  market: '',
  transactionId: '',
  transactionStatus: '',
  isTest: false,
  documentId: '',
  property: {
    address: {
      city: '',
      county: '',
      state: '',
      street1: '',
      street2: '',
      zip: '',
    },
    parcelNumber: '',
  },
  organization: undefined,
  documentUploaded: false,
  terms: {
    productType: '' as TransactionType,
    salePrice: '',
    loanAmount: '',
    loanNumber: '',
    emdAmount: '',
    emdPercent: undefined,
  },
  fees: {
    sellerCreditsBuyer: '',
  },
  dates: {
    closingDate: undefined,
    emdDueDate: undefined,
    contractExecutedDate: undefined,
  },
  ocrConfidenceScores: {
    street1: undefined,
    street2: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    county: undefined,
    parcelNumber: undefined,
    loanAmount: undefined,
    salePrice: undefined,
    emdAmount: undefined,
    productType: undefined,
    sellerCreditsBuyer: undefined,
    closingDate: undefined,
  },
  ...properties,
});
