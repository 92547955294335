import {
  Flex,
  Icon,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  useDropdownMenuState,
} from '@endpoint/blockparty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC } from 'react';
import { Link, useMatch } from '@reach/router';
import { useIsSystemOrOpsAdmin } from 'hooks/useIsSystemOrOpsAdmin';

import PlatformLogo from './PlatformLogo';
import { PlatformLogoSeparatorDecoration } from './PlatformLogoSeparatorDecoration';
import PlatformMenuItem from './PlatformMenuItem';
import { platforms } from './platforms';
import { ValidPlatform } from './types';

const PlatformLogoFactory: FC = () => {
  const platformMenu = useDropdownMenuState({ gutter: 15, placement: 'bottom-end' });
  const { isAdmin } = useIsSystemOrOpsAdmin();
  const { canViewOpswareNavigationPopover, canUseSelfHostedAdmin } = useFlags();

  const adminMatch = useMatch('/admin/*');

  if (!isAdmin || !canViewOpswareNavigationPopover) {
    return (
      <Flex data-test-id="platform">
        <PlatformLogoSeparatorDecoration>
          <PlatformLogo platformName={ValidPlatform.Workspace} />
        </PlatformLogoSeparatorDecoration>
      </Flex>
    );
  }

  return (
    <>
      <DropdownMenuTrigger state={platformMenu}>
        <Flex data-test-id="platform-popover-menu" overflow="visible">
          <PlatformLogoSeparatorDecoration>
            <PlatformLogo platformName={adminMatch ? ValidPlatform.Admin : ValidPlatform.Workspace} />
            <Icon color="carbon0" marginRight="15px" name="ArrowChevronDown" size="large" />
          </PlatformLogoSeparatorDecoration>
        </Flex>
      </DropdownMenuTrigger>
      <DropdownMenu aria-label="Platform menu" state={platformMenu}>
        {platforms.map((platform) =>
          platform.path === '/admin' && platform.url && canUseSelfHostedAdmin ? (
            <a key={platform.title} href={platform.url} style={{ textDecoration: 'none' }}>
              <DropdownMenuItem>
                <PlatformMenuItem platform={platform} />
              </DropdownMenuItem>
            </a>
          ) : (
            <Link key={platform.title} style={{ textDecoration: 'none' }} to={platform.path!}>
              <DropdownMenuItem>
                <PlatformMenuItem platform={platform} />
              </DropdownMenuItem>
            </Link>
          ),
        )}
      </DropdownMenu>
    </>
  );
};

export default PlatformLogoFactory;
