import { gql } from '@apollo/client';
import { Document, Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_DOCUMENTS = gql`
  query getDocuments($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      id
      fileNum
      milestones {
        id
        tasks {
          id
          name
          todos {
            id
          }
        }
      }
      documents {
        id
        todo {
          id
        }
        createdAt
        name
        type
        escrowNote
        uploadedBy
        customerNote
        participants {
          id
          roles
          contact {
            id
            contactType
            isOnboarded
            ... on AgentContact {
              firstName
              lastName
            }
            ... on LenderContact {
              firstName
              lastName
            }
            ... on IndividualContact {
              firstName
              lastName
            }
            ... on EntityContact {
              entityName
            }
          }
        }
        storage {
          url
          size
          mimeType
        }
      }
    }
  }
`;

export const DOCUMENT_FRAGMENT = gql`
  fragment Document on Document {
    id
    todo {
      id
    }
    createdAt
    name
    type
    escrowNote
    customerNote
    participants {
      id
      roles
      contact {
        id
        contactType
        isOnboarded
        ... on AgentContact {
          firstName
          lastName
        }
        ... on LenderContact {
          firstName
          lastName
        }
        ... on IndividualContact {
          firstName
          lastName
        }
        ... on EntityContact {
          entityName
        }
      }
    }
    storage {
      provider
      url
      size
      mimeType
    }
  }
`;

export const REMOVE_DOCUMENT_PARTICIPANT = gql`
  mutation RemoveParticipantsFromDocument(
    $where: DocumentWhereUniqueInput!
    $forParticipants: [TransactionParticipantWhereUniqueInput!]!
  ) {
    removeParticipantsFromDocument(where: $where, forParticipants: $forParticipants) {
      id
      participants {
        id
      }
    }
  }
`;

export const ADD_DOCUMENT_PARTICIPANT = gql`
  mutation AddParticipantsToDocument(
    $where: DocumentWhereUniqueInput!
    $forParticipants: [TransactionParticipantWhereUniqueInput!]!
  ) {
    addParticipantsToDocument(where: $where, forParticipants: $forParticipants) {
      id
      participants {
        id
      }
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($where: DocumentWhereUniqueInput!, $input: EditDocumentInput!) {
    updateDocument(where: $where, input: $input) {
      id
    }
  }
`;

export interface GetDocumentsType {
  transaction: Transaction;
}

export interface UpdatedDocument {
  document: Document;
}
