import React, { FC } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerActionBar,
  DrawerSection,
  Heading,
  Text,
} from '@endpoint/blockparty';

interface AdditionalInfoDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  transactionId: string;
}

export const AdditionalInfoDrawer: FC<AdditionalInfoDrawerProps> = ({ isOpen, onClose, transactionId }) => (
  <Drawer
    data-test-id="additional-info-drawer"
    id="additional-info-drawer"
    isOpen={isOpen}
    placement="right"
    onClose={onClose}
  >
    <DrawerContent>
      <DrawerActionBar>
        <DrawerCloseButton />
      </DrawerActionBar>
      <DrawerSection>
        <Heading as="h3" pb="space40" size="fontSize50">
          Additional Info
        </Heading>
        <Text as="p" color="carbon500" size="fontSize10">
          Transaction ID#
        </Text>
        <Text as="p" size="fontSize20">
          {transactionId}
        </Text>
      </DrawerSection>
    </DrawerContent>
  </Drawer>
);
