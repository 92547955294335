import {
  Box,
  Divider,
  DrawerSection,
  InputErrorMessage,
  InputGroup,
  InputLabel,
  Select,
  Text,
} from '@endpoint/blockparty';
import { transactionTypeMap } from 'consts/transactionConsts';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { OrganizationForm } from 'components/OpenOrderDrawer/OrganizationForm';
import { useQuery } from '@apollo/client';
import { OpswareUser } from 'components/Auth/helpers';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';
import { useIsAccessPrelistingEnabled } from 'hooks/useIsAccessPrelistingEnabled';
import { TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { OpenOrderDrawerForm as FormInterface } from 'helpers/openOrder/openOrderFormTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetActiveTransactionTypes } from 'hooks/useGetActiveTransactionTypes';

import { PropertyAddressForm } from './PropertyAddressForm';

export const ManualEntry: FC = () => {
  const { errors, setFieldValue, values, touched } = useFormikContext<FormInterface>();
  const productTypeOptions = useGetActiveTransactionTypes();
  const { data } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);
  const hasMultiOrgAccess = data?.me.hasMultiOrganizationAccess;

  const { isAddressServiceEnabled } = useFlags();

  const isPrelistingAccessMarket = useIsAccessPrelistingEnabled(values.property.address.state);

  const selectProductTypeOptions = productTypeOptions.map((productType) => ({
    value: productType,
    label: transactionTypeMap[productType],
  }));

  const defaultValue = selectProductTypeOptions.find((option) => option.value === values.terms.productType);

  const nonPrelistingMarketProductTypeOptions = selectProductTypeOptions.filter((productType) => {
    return productType.value !== TransactionType.PRELISTING;
  });

  const isOrgSelectedAndFlagEnabled = !!values.organization && !!isAddressServiceEnabled;

  const showPropertyForm = !!isOrgSelectedAndFlagEnabled || !isAddressServiceEnabled || !hasMultiOrgAccess;

  const productSelectOptions = isPrelistingAccessMarket
    ? selectProductTypeOptions
    : nonPrelistingMarketProductTypeOptions;

  const handleOnChange = (value: string) => {
    setFieldValue('terms.productType', value);
  };

  return (
    <DrawerSection>
      <Box>
        {hasMultiOrgAccess && <OrganizationForm />}

        {showPropertyForm ? (
          <>
            <Divider mb="space60" />
            <Text fontWeight="semi" size="fontSize30">
              Enter property address
            </Text>

            <PropertyAddressForm />

            <Divider mb="space60" />

            <Text fontWeight="semi" size="fontSize30">
              Select product type
            </Text>

            <InputGroup
              data-test-id="open-order-drawer-product-type-select"
              flex={1}
              groupId="productType"
              isInvalid={touched.terms?.productType && !!errors.terms?.productType}
              pb="space30"
              pt="space50"
            >
              <InputLabel color="carbon500" htmlFor="productType">
                Product Type *
              </InputLabel>
              <Select
                defaultValue={defaultValue}
                inputId="productType"
                name="productType"
                options={productSelectOptions}
                onChange={(value) => handleOnChange(value.value)}
              />
              {touched.terms?.productType && !!errors.terms?.productType && (
                <InputErrorMessage>{errors.terms?.productType}</InputErrorMessage>
              )}
            </InputGroup>
          </>
        ) : null}
      </Box>
    </DrawerSection>
  );
};
