import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, Heading, Text } from '@endpoint/blockparty';
import { dateFormatter } from 'helpers/datetimeHelper';
import { formatLikeTitle, formatNotificationType } from 'helpers/formatText';
import { AllContactTypes } from 'consts/contactDetails';
import { Organization } from '@endpoint/opsware-bff-graphql-schema';
import { GET_MARKETS_AND_ORGANIZATIONS, MarketsAndOrganizations } from 'consts/getMarketsAndOrganizationsQuery';

interface SystemInformationProps {
  contact: AllContactTypes;
}

export const SystemInformationFields: FC<SystemInformationProps> = ({ contact }) => {
  const { data: marketsAndOrganizations } = useQuery<MarketsAndOrganizations>(GET_MARKETS_AND_ORGANIZATIONS, {
    fetchPolicy: 'cache-only',
  });
  const { createdAt, isOnboarded, notificationPreferences, organizationId } = contact;
  const preference = (notificationPreferences || []).map((pref) => pref.toLowerCase()).join(', ');
  const organization = marketsAndOrganizations?.organizations.find((org: Organization) => org.id === organizationId);
  const orgName = organization ? organization.name : '';
  const notificationTypeContent = formatLikeTitle(preference);

  return (
    <Grid
      columnGap="space60"
      px="space40"
      py="space30"
      rowGap="space30"
      templateColumns="repeat(2, minmax(150px, 1fr))"
    >
      <Box>
        <Text as="p" color="passive" mb="space20" size="fontSize10">
          Joined EP App
        </Text>
        <Box mb="space30">
          <Text as="p" data-test-id="system-information-joined">
            {isOnboarded ? dateFormatter(createdAt, 'MMM dd, yyyy') : '-'}
          </Text>
        </Box>
        <Divider data-test-id="divider" />
      </Box>
      <Box>
        <Text as="p" color="passive" mb="space20" size="fontSize10">
          Notification Type
        </Text>
        <Text as="p" data-test-id="system-information-notification" mb="space30">
          {formatNotificationType(notificationTypeContent) || '-'}
        </Text>
        <Divider data-test-id="divider" />
      </Box>
      <Box>
        <Text as="p" color="passive" mb="space20" size="fontSize10">
          Organization
        </Text>
        <Text as="p" data-test-id="system-information-organization" mb="space30">
          {formatLikeTitle(orgName) || '-'}
        </Text>
      </Box>
    </Grid>
  );
};

export const SystemInformation: FC<SystemInformationProps> = ({ contact }) => {
  return (
    <Box data-test-id="system-information-section" width="100%">
      <Heading backgroundColor="slate0" p="space40" size="fontSize30">
        System Information
      </Heading>
      <SystemInformationFields contact={contact} />
    </Box>
  );
};
