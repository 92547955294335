import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { LayoutContent, LayoutSubContent, Flex, Box } from '@endpoint/blockparty';
import { useQuery } from '@apollo/client';
import { ErrorMessage } from 'components/ErrorMessage';
import { trackPage } from 'helpers/utils/segment/segmentAnalytics';
import { Page } from 'consts/segmentProtocols';
import { updateBreadcrumbs } from 'helpers/updateBreadcrumbs';
import { buildQueryInput } from 'helpers/buildQueryInput';
import { AllContactTypes } from 'consts/contactDetails';
import { ActionKind, useRelatedContactsProvider } from 'hooks/useRelatedContactsProvider';
import { relatedContactsError, RelatedContactsGQLErrorType } from 'consts/createNewContact';
import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema';

import { Tabs } from './Tabs';
import { ContactInfo } from './Info';
import { SkeletonContactDetails } from './SkeletonContactDetails';
import { GET_PARTICIPANT, GetParticipant } from './queries';

type ContactDetailTypes = RouteComponentProps;
export interface ContactDetailsProps extends ContactDetailTypes {
  participantId?: string;
  transactionIdentifier?: string;
}

export const ContactDetails: FC<ContactDetailsProps> = ({ transactionIdentifier = '', participantId, ...props }) => {
  useEffect(() => {
    trackPage<Page>('Contact Details');
  }, []);

  const queryInput = buildQueryInput(transactionIdentifier);
  const { id, fileNum } = queryInput;
  const { data, loading, error } = useQuery<GetParticipant>(GET_PARTICIPANT, {
    variables: {
      where: { id: participantId },
    },
  });

  const participant = data?.transactionParticipant;
  const transactionStatus = participant?.transaction.status;
  const contact = (participant?.contact || { firstName: '', lastName: '' }) as AllContactTypes;
  const todoAssignments = participant?.todoAssignments;
  let firstNameLastInitial = '';

  if ('entityName' in contact) {
    firstNameLastInitial = contact.entityName || '';
  } else if ('firstName' in contact) {
    firstNameLastInitial = `${contact.firstName} ${contact.lastName?.substr(0, 1)}`;
  }

  const path = fileNum ? `/transaction/${fileNum}/contacts` : `/transaction/id-${id}/contacts`;

  const { state, dispatch } = useRelatedContactsProvider();
  const { relatedContactsGQLError } = state;

  const isRelatedContactsErrorDisplayed =
    relatedContactsGQLError === RelatedContactsGQLErrorType.AUTO_ADD_RELATED_CONTACTS_ERROR;

  useEffect(() => {
    updateBreadcrumbs([
      {
        label: 'Contacts',
        path,
      },
      { label: firstNameLastInitial, path: '' },
    ]);
  }, [fileNum, firstNameLastInitial, id, path]);

  const closeErrorMessage = () => {
    dispatch({ type: ActionKind.SET_RELATED_CONTACTS_GQL_ERROR, payload: { ...state, relatedContactsGQLError: null } });
  };

  const participantSection = (
    <Flex flexDirection="column" width="100%">
      <ContactInfo participant={participant} {...props} />
      {isRelatedContactsErrorDisplayed && (
        <Box mb="space50">
          <ErrorMessage
            closeAction={closeErrorMessage}
            description={relatedContactsError.description}
            title={relatedContactsError.label}
          />
        </Box>
      )}
      <Tabs
        defaultTabSelected={isRelatedContactsErrorDisplayed ? 'relationships' : 'details'}
        participant={participant as TransactionParticipant}
        todoAssignments={todoAssignments || []}
        transactionStatus={transactionStatus}
      />
    </Flex>
  );

  return (
    <LayoutContent>
      <LayoutSubContent p="space60">
        <Flex flexDirection="row">
          {loading && <SkeletonContactDetails />}
          {error && <ErrorMessage />}
          {participant && participantSection}
        </Flex>
      </LayoutSubContent>
    </LayoutContent>
  );
};
