import { gql } from '@apollo/client';
import {
  Contact,
  AgentContact,
  EntityContact,
  IndividualContact,
  LenderContact,
} from '@endpoint/opsware-bff-graphql-schema';

export const UPDATE_CONTACT_DETAILS = gql`
  mutation updateContact($where: ContactWhereUniqueInput!, $input: UpdateContactInput!) {
    updateContact(where: $where, input: $input) {
      id
      email
      contactType
      phone
      ... on AgentContact {
        licenseNumber
        stateOfOperation
        firstName
        lastName
        middleName
        mlsId
      }
      ... on LenderContact {
        nmlsId
        firstName
        lastName
        middleName
      }
      ... on IndividualContact {
        firstName
        lastName
        middleName
      }
      ... on EntityContact {
        entityName
      }
    }
  }
`;

export interface UpdateContactDetailsData {
  updateContact: Contact | AgentContact | EntityContact | IndividualContact | LenderContact;
}
