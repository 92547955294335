import { gql } from '@apollo/client';
import { IMutation, Task, BasicIdWhereInput, OpsTask } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_TODO_DETAILS = gql`
  query getTask($where: TaskWhereUniqueInput!) {
    task(where: $where) {
      id
      name
      status
      statusOptions
      todos {
        id
        name
        due
        createdAt
        escrowNote
        syncType
        metadata
        assignments {
          id
          status
          statusOptions
          createdAt
          updatedAt
          deletedAt
          completedAt
          metadata
          todo {
            id
          }
          assignee {
            id
            roles
            contact {
              id
              ... on AgentContact {
                firstName
                lastName
              }
              ... on LenderContact {
                firstName
                lastName
              }
              ... on IndividualContact {
                firstName
                lastName
              }
              ... on EntityContact {
                entityName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TODO_DETAILS_WITH_STEPANSWERS = gql`
  query getTask($where: TaskWhereUniqueInput!) {
    task(where: $where) {
      id
      name
      status
      statusOptions
      todos {
        id
        name
        due
        createdAt
        escrowNote
        syncType
        metadata
        assignments {
          id
          status
          statusOptions
          createdAt
          updatedAt
          deletedAt
          completedAt
          metadata
          todo {
            id
          }
          stepAnswers {
            stepId
            answer
            todoAssignmentId
            createdAt
            updatedAt
            fields {
              fieldId
              fieldType
              label
              answer
              document {
                id
                type
                name
                storage {
                  url
                  size
                  mimeType
                }
              }
              isSensitiveInformation
            }
          }
          assignee {
            id
            roles
            contact {
              id
              ... on AgentContact {
                firstName
                lastName
              }
              ... on LenderContact {
                firstName
                lastName
              }
              ... on IndividualContact {
                firstName
                lastName
              }
              ... on EntityContact {
                entityName
              }
            }
          }
        }
      }
    }
  }
`;

export const SET_TASK_STATUS = gql`
  mutation SetTaskStatus($data: TaskUpdateInput!, $where: TaskWhereUniqueInput!) {
    setTaskStatus(data: $data, where: $where) {
      id
      status
    }
  }
`;

export const GET_OPS_TASK = gql`
  query opsTask($where: BasicIdWhereInput!) {
    opsTask(where: $where) {
      id
      assigneeId
      assigneeName
      taskTitle
      assigneeName
      dueDateTime
      squad
      status
      transactionId
      autoAssignTask
      isOverdue
      steps {
        stepId
        taskId
        isCompleted
        key
        title
        response
      }
      taskDefinitionKey
      taskDefinitionVersion
      taskDefinitionSchema
      contacts {
        isOnboarded
        ... on IndividualContact {
          firstName
          middleName
          lastName
        }
      }
    }
  }
`;

export const GET_OPS_TASK_NOTES = gql`
  query opsTask($where: BasicIdWhereInput!) {
    opsTask(where: $where) {
      id
      assigneeId
      assigneeName
      taskTitle
      assigneeName
      dueDateTime
      squad
      status
      transactionId
      autoAssignTask
      isOverdue
      steps {
        stepId
        taskId
        isCompleted
        key
        title
        response
      }
      taskDefinitionKey
      taskDefinitionVersion
      taskDefinitionSchema
      contacts {
        isOnboarded
        ... on IndividualContact {
          firstName
          middleName
          lastName
        }
      }
      notes {
        id
        text
        createdAt
        isPinned
        authorId
        authorName
      }
    }
  }
`;

export const UPDATE_OPS_TASK = gql`
  mutation updateOpsTask($where: BasicIdWhereInput!, $data: OpsTaskUpdateInput!) {
    updateOpsTask(where: $where, data: $data) {
      id
      assigneeId
      assigneeName
      squad
      status
      dueDateTime
    }
  }
`;

export interface GetToDoDetails {
  task: Task;
}

export interface SetTaskStatus {
  setTaskStatus: IMutation['setTaskStatus'];
}
export interface TaskStatusResult {
  setTaskStatus: Task;
}

export interface OpsTaskResponse {
  opsTask: OpsTask;
}

export interface UpdateOpsTaskResponse {
  opsTask: OpsTask;
}
