import React, { FC, useMemo } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import {
  Box,
  Table,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from '@endpoint/blockparty';
import { useSortBy, useTable, Row, SortByFn, useFlexLayout } from 'react-table';
import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import 'styled-components/macro';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { TableItemClicked, TrackingEvent } from 'consts/segmentProtocols';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  contactsRowData,
  columnHeaders,
  ColumnSortedHeader,
  ColumnHeader,
  ContactsRowDataTypes,
} from '../TableComponents';

const customNameSort = (rowA: Row<ContactsRowDataTypes>, rowB: Row<ContactsRowDataTypes>): number => {
  return rowA.values.name.label.localeCompare(rowB.values.name.label);
};

const customRolesSort = (rowA: Row<ContactsRowDataTypes>, rowB: Row<ContactsRowDataTypes>): number => {
  return rowA.values.roles.formattedRoles.localeCompare(rowB.values.roles.formattedRoles);
};

const sortTypes: Record<string, SortByFn<ContactsRowDataTypes>> = {
  customNameSort,
  customRolesSort,
};

interface ContactsTableProps extends RouteComponentProps {
  participants: TransactionParticipant[];
  fileNumber?: string;
}

export const ContactsTable: FC<ContactsTableProps> = (props) => {
  const { canViewClosingSpecialist } = useFlags();
  const { participants: contactsData } = props;
  const columns = useMemo(columnHeaders, []);
  const data = useMemo(
    () => contactsRowData(contactsData, canViewClosingSpecialist),
    [canViewClosingSpecialist, contactsData],
  );
  const trackData: TableItemClicked = {
    table: 'Contacts List Table',
  };
  const trackingEvent: TrackingEvent = 'Table Item Clicked';
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      sortTypes,
    },
    useSortBy,
    useFlexLayout,
  );

  return (
    <Box>
      <Table data-test-id="contacts-table-wrapper" label="Contacts List Table" {...getTableProps()}>
        <TableHeader zIndex={0}>
          {headerGroups.map((headerGroup) => {
            const { key: tableHeaderRowKey, ...tableHeaderRowProps } = headerGroup.getHeaderGroupProps();

            return (
              <TableHeaderRow key={tableHeaderRowKey} {...tableHeaderRowProps}>
                {headerGroup.headers.map((column) => {
                  const { key: headerCellKey, ...headerCellProps } = column.getHeaderProps(
                    column.getSortByToggleProps(),
                  );

                  return (
                    <TableHeaderCell key={headerCellKey} {...headerCellProps}>
                      {column.canSort ? <ColumnSortedHeader column={column} /> : <ColumnHeader column={column} />}
                    </TableHeaderCell>
                  );
                })}
              </TableHeaderRow>
            );
          })}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            const { key: rowKey, ...rowProps } = row.getRowProps();

            return (
              <TableRow
                key={rowKey}
                {...rowProps}
                cursor="pointer"
                onClick={(): RouteComponentProps => {
                  trackAnalytics(trackingEvent, trackData);

                  return navigate(`contacts/${row.original.participantId}`);
                }}
              >
                {row.cells.map((cell) => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps();

                  return (
                    <TableCell key={cellKey} py="space30" wordBreak="break-word" {...cellProps}>
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

ContactsTable.displayName = 'ContactsTable';
