import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Flex, LayoutRightPanel } from '@endpoint/blockparty';

import { RightPanelHeader } from './RightPanelHeader';
import { TaskPanelV2 } from './TaskPanel/TaskPanelV2';

type TransactionRightPanelV2Props = RouteComponentProps<{
  transactionIdentifier: string;
  taskId: string;
}>;

export const TransactionRightPanelV2: FC<TransactionRightPanelV2Props> = ({
  transactionIdentifier = '0',
  taskId = '0',
}) => {
  return (
    <LayoutRightPanel display={['none', 'none', 'block']} width="504px">
      <Flex flexDirection="column" height="100%">
        <RightPanelHeader taskId={taskId} transactionIdentifier={transactionIdentifier} />
        <TaskPanelV2 taskId={taskId} transactionIdentifier={transactionIdentifier} />
      </Flex>
    </LayoutRightPanel>
  );
};
