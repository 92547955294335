import { Flex, Text } from '@endpoint/blockparty';
import { SimpleAddress } from '@endpoint/opsware-bff-graphql-schema';
import React, { FC } from 'react';

export interface SingleAddressValueProps {
  selectedData: SimpleAddress;
}

export const SingleAddressValue: FC<SingleAddressValueProps> = ({ selectedData }) => {
  return (
    <Flex alignItems="center" data-test-id="single-value-content">
      <Text>{selectedData.street}</Text>
    </Flex>
  );
};
