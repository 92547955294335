import React, { FC, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { AsyncSelect, InputGroup, InputLabel, Text } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';
import { useApolloClient } from '@apollo/client';
import debounce from 'debounce-promise';
import { SearchContactResult, NewContactSchema, NewContactStep, ContactGQLErrorType } from 'consts/createNewContact';
import { useNewContactProvider, ActionKind } from 'hooks/useContactProvider';
import { TransactionContext } from 'routes/Transaction';
import { delay } from 'lodash';
import { TransactionType, TransactionRole } from '@endpoint/opsware-bff-graphql-schema';
import { setRolesByContactAndTransactionType } from 'helpers/contacts';
import { removeNonNumeric } from 'helpers/formatPhone';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Menu } from './Menu';
import { Option } from './Option';
import { SingleValue } from './SingleValue';
import { getSearchContactsResults } from './queries';

interface SearchContactParams {
  transactionType: TransactionType;
}

export const SearchContact: FC<SearchContactParams> = ({ transactionType }) => {
  const client = useApolloClient();
  const { canAddLenderContactTypes } = useFlags();
  const { dispatch, state } = useNewContactProvider();
  const { setFieldValue, setFieldTouched, touched } = useFormikContext<NewContactSchema>();
  const [searchTerm, setSearchTerm] = useState('');
  const [optionValues, setOptionValues] = useState<SearchContactResult[] | []>([]);
  const [selectedContactOption, setSelectedContactOption] = useState<SearchContactResult | null>(null);
  const { organizationId } = useContext(TransactionContext);

  useEffect(() => {
    return () => {
      setSelectedContactOption(null);
    };
  }, [state]);

  // source https://stackoverflow.com/questions/52695414/react-select-with-react-apollo-does-not-work
  const handleLoadOptions = useCallback(
    async (inputValue: string) => {
      dispatch({
        type: ActionKind.SET_CONTACT_GQL_ERROR,
        payload: { ...state, contactGQLError: null },
      });

      if (inputValue.length >= 2) {
        try {
          const results = await getSearchContactsResults(inputValue, client, organizationId);

          // filtering out LenderContact types while they're unsupported
          const filteredResults = results.data.searchContactsWithInput.filter((contact) => {
            const keys = Object.keys(contact);

            return canAddLenderContactTypes ? true : !keys.includes('nmlsId');
          });

          setOptionValues(filteredResults);

          return filteredResults;
        } catch {
          dispatch({
            type: ActionKind.SET_CONTACT_GQL_ERROR,
            payload: { ...state, contactGQLError: ContactGQLErrorType.SEARCH_ERROR },
          });
        }
      }

      return [];
    },
    [client, dispatch, organizationId, canAddLenderContactTypes, state],
  );

  // source: https://github.com/JedWatson/react-select/issues/3075
  const debouncedHandleLoadOptions = useMemo(() => debounce(handleLoadOptions, 300), [handleLoadOptions]);

  const handleInputChange = (input: string): void => {
    setSearchTerm(input);
  };

  const handleSelectChange = (selected: SearchContactResult | null): void => {
    if (!selected) {
      setSelectedContactOption(null);
      setFieldValue('firstName', '');
      setFieldValue('lastName', '');
      setFieldValue('middleName', '');
      setFieldValue('contactType', '');
      setFieldValue('licenseNumber', '');
      setFieldValue('nmlsId', '');
      setFieldValue('phone', '');
      setFieldValue('email', '');
      setFieldValue('id', '');
      setFieldValue('roles', []);
      setFieldValue('stateOfOperation', '');
      setFieldValue('entityName', '');

      dispatch({
        type: ActionKind.SET_CONTACT_STEP,
        payload: { ...state, newContactStep: NewContactStep.SEARCH_CONTACT },
      });
    } else {
      setSelectedContactOption(selected);
      const roles: TransactionRole[] = setRolesByContactAndTransactionType(selected.contactType, transactionType);

      // fill name fields with default values to prevent Formik validation
      // errors from allowing form submit - createTransactionParticipant
      // only requires id and roles

      setFieldValue('firstName', selected.firstName);
      setFieldValue('lastName', selected.lastName);
      setFieldValue('entityName', selected.entityName);
      setFieldValue('contactType', selected.contactType);
      setFieldValue('licenseNumber', selected.licenseNumber);
      setFieldValue('nmlsId', selected.nmlsId);
      setFieldValue('phone', removeNonNumeric(selected.phone || ''));
      setFieldValue('email', selected.email);
      setFieldValue('id', selected.id);
      setFieldValue('stateOfOperation', selected.stateOfOperation);
      setFieldValue('middleName', selected.middleName || '');
      setFieldValue('roles', roles);
      /** adding a delay to the call of setFieldTouched ensures that validation is run onChange
       * fields that use ReactSelect or other Selects - see https://github.com/formium/formik/issues/2059
       */
      delay(setFieldTouched, 100, 'contactType', true);
      dispatch({
        type: ActionKind.SET_CONTACT_STEP,
        payload: { ...state, newContactStep: NewContactStep.SEARCH_UPDATE_CONTACT },
      });

      dispatch({
        type: ActionKind.SET_CONTACT,
        payload: {
          ...state,
          selectedContactData: {
            ...selected,
            phone: removeNonNumeric(selected.phone || ''),
            id: selected.id || '',
            firstName: selected.firstName || '',
            middleName: selected.middleName || '',
            lastName: selected.lastName || '',
            entityName: selected.entityName || '',
            roles,
            contactType: selected.contactType || '',
          },
        },
      });
    }
  };

  const noOptions = () =>
    touched && searchTerm && !optionValues.length ? (
      <Text as="p" color="carbon600" padding="space40" textAlign="center" width="100%">
        No Options
      </Text>
    ) : null;

  if (state.newContactStep !== NewContactStep.SEARCH_CONTACT) {
    return null;
  }

  return (
    <InputGroup groupId="id" mb="space50">
      <InputLabel htmlFor="id">Contact *</InputLabel>
      <AsyncSelect
        components={{
          Menu,
          Option,
          SingleValue,
          NoOptionsMessage: noOptions,
        }}
        inputId="id"
        inputValue={searchTerm}
        isClearable
        isSearchable
        loadOptions={debouncedHandleLoadOptions}
        placeholder="Search by name, license or email"
        value={selectedContactOption}
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
      />
    </InputGroup>
  );
};
