import React from 'react';
import { Text } from '@endpoint/blockparty';
import { formatLikeTitle, getContactName } from 'helpers/formatText';
import { formatPhoneNumber } from 'helpers/formatPhone';
import { UseTableCellProps, CellProps, Column, ColumnInstance } from 'react-table';
import { AllContactTypes } from 'consts/contactDetails';
import { useAddressBookFilters } from 'helpers/addressBook';
import { ContactSortProperties, SortDirection } from '@endpoint/opsware-bff-graphql-schema';
import {
  AddressBookSortTableAccessor,
  addressBookSortPropToTableAccessorMap,
  addressBookTableAccessorToSortPropMap,
} from 'consts/addressBook';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import GenericColumnHeader from 'components/GenericColumnHeader';

import { formatContactTypeLikeTitle } from '../helpers/formatContactTypeLikeTitle';

// //////////////////////////////////////////////////////////////////////

export interface ContactsRowDataTypes {
  name: string;
  contactType: string;
  phoneNumber?: string;
  email: string;
  contactId: string;
}

export type TableColumnCellProps<T extends object> = Pick<UseTableCellProps<T>, 'column'>;

export type TableColumnHeaderCellProps = Pick<CellProps<GenericObject>, 'cell'>;

// //////////////////////////////////////////////////////////////////////

/** **** Table helpers ***** */

export const columnHeaders = (): Column<ContactsRowDataTypes>[] => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ cell }: TableColumnHeaderCellProps): React.ReactElement => <Text>{cell.value}</Text>,
  },
  {
    Header: 'Contact Type',
    accessor: 'contactType',
    Cell: ({ cell }: TableColumnHeaderCellProps): React.ReactElement => <Text>{formatLikeTitle(cell.value)}</Text>,
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: 'Phone',
    accessor: 'phoneNumber',
    disableSortBy: true,
    Cell: ({ cell }: TableColumnHeaderCellProps): React.ReactElement => (
      <Text>{cell.value ? formatPhoneNumber(cell.value) : '-'}</Text>
    ),
  },
];

export const contactsRowData = (contacts: AllContactTypes[]): ContactsRowDataTypes[] =>
  contacts.map((contact): ContactsRowDataTypes => {
    const { id, contactType, phone, email } = contact;

    const contactTypeLikeTitle: string = formatContactTypeLikeTitle(contactType);
    const name = getContactName({ contact });

    return {
      name,
      contactType: contactTypeLikeTitle,
      phoneNumber: phone || '',
      email: email || '',
      contactId: id!,
    };
  });

const getSortQuery = (column: ColumnInstance<ContactsRowDataTypes>) => {
  const sortProp = addressBookTableAccessorToSortPropMap[column.id as AddressBookSortTableAccessor];
  let query: [ContactSortProperties, SortDirection] | [] = [sortProp, SortDirection.ASC];

  if (column.isSorted) {
    query = column.isSortedDesc ? [] : [sortProp, SortDirection.DESC];
  }

  return query;
};

export const useGetInitialSortState = () => {
  const location = useLocation();
  const searchParams = parse(location.search, { arrayFormat: 'comma' });
  const sortQuery = searchParams.sort;

  if (sortQuery) {
    const id = addressBookSortPropToTableAccessorMap[sortQuery[0] as ContactSortProperties];

    if (sortQuery[1] === 'DESC') {
      return [
        {
          id,
          desc: true,
        },
      ];
    }

    return [
      {
        id,
        desc: false,
      },
    ];
  }

  return [];
};

/** **** react-table--specific components ***** */

export function ColumnHeader({ column }: { column: ColumnInstance<ContactsRowDataTypes> }): React.ReactElement {
  const { navigateWithParams } = useAddressBookFilters();

  return (
    <GenericColumnHeader<ContactsRowDataTypes>
      column={column}
      onClick={() => {
        navigateWithParams({ sort: getSortQuery(column) });
      }}
    />
  );
}
