import {
  TransactionStatus,
  TransactionType,
  TransactionUpdateInput,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { ApolloClient, gql } from '@apollo/client';
import { buildOpenEscrowPayload } from 'helpers/openOrder/buildOpenEscrowPayload';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';
import { GET_TRANSACTION_HEADER_DATA } from 'routes/Transaction/SubHeader/queries';
import { GET_BASIC_TRANSACTION_DATA } from 'routes/Transaction/queries';
import { GET_TRANSACTION_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import { toLocalIsoDate } from 'helpers/datetimeHelper';

export interface InputFees {
  sellerCreditsBuyer?: number;
  titleFees?: number;
}
export interface TransactionUpdateInputDto extends TransactionUpdateInput {
  isOpsReviewComplete: boolean;
  isPending: boolean;
  address: {
    street1: string;
    street2?: string;
    city: string;
    state: string;
    zip: string;
    county: string;
  };
  parcelNumber?: string;
  market?: string;
  terms: {
    productType: TransactionType;
    salePrice?: number;
    loanAmount?: number;
    loanNumber?: string;
    emdAmount?: number;
  };
  fees: InputFees;
  dates: DateTypes;
  purchaseAgreementDocumentId?: string;
  status: TransactionStatus;
}

interface DateTypes {
  estimatedClosing?: Date;
  emdContractDate?: Date;
  contractExecutedDate?: Date;
}

export async function updateTransactionMutation(data: OpenOrderDrawerForm, client: ApolloClient<Object>) {
  const openEscrowValues = buildOpenEscrowPayload(data);

  const isTest = data.isTest;

  const updateTransaction: TransactionUpdateInputDto = {
    ...openEscrowValues,
    isPending: true,
    isOpsReviewComplete: true,
    status: TransactionStatus.IN_ESCROW,
  };

  Object.keys(updateTransaction.dates).forEach((key) => {
    const dateKey = key as keyof DateTypes;

    if (updateTransaction.dates[dateKey]) {
      updateTransaction.dates[dateKey] = toLocalIsoDate(updateTransaction.dates[dateKey] as Date);
    }
  });

  if (isTest) {
    updateTransaction.isTest = true;
  }

  return client
    .mutate({
      mutation: query,
      variables: {
        where: { id: data.transactionId },
        updateTransaction,
      },
      refetchQueries: [
        {
          query: GET_TRANSACTION_HEADER_DATA,
          variables: {
            where: {
              id: data.transactionId,
            },
          },
        },
        {
          query: GET_BASIC_TRANSACTION_DATA,
          variables: {
            where: {
              id: data.transactionId,
            },
          },
        },
        {
          query: GET_TRANSACTION_INFO,
          variables: {
            where: {
              id: data.transactionId,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    })
    .then((result) => result)
    .catch((error) => error);
}

const query = gql`
  mutation updateTransaction($where: UpdateTransactionWhereUniqueInput!, $updateTransaction: TransactionUpdateInput!) {
    updateTransaction(where: $where, data: $updateTransaction) {
      id
      fileNum
    }
  }
`;
