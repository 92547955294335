import React, { FC, useEffect, createContext, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import { LayoutContent, LayoutSubContent, Card, Box, Flex, Heading } from '@endpoint/blockparty';
import { Document, Milestone } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { trackPage } from 'helpers/utils/segment/segmentAnalytics';
import { Page } from 'consts/segmentProtocols';
import { updateBreadcrumbs } from 'helpers/updateBreadcrumbs';
import { useQuery } from '@apollo/client';
import { SkeletonTable } from 'components/SkeletonTable';
import { ErrorMessage } from 'components/ErrorMessage';
import { sortDocuments, setUploadRefreshPollInterval } from 'helpers/documents';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { buildQueryInput } from 'helpers/buildQueryInput';
import { cloneDeep } from 'lodash';

import { DocumentsTable } from './DocumentsContent/DocumentsTable';
import { GetDocumentsType, GET_DOCUMENTS } from './queries';
import { DocumentsHeader } from './DocumentsHeader';

type DocumentsTypes = RouteComponentProps;

interface DocumentsProps extends DocumentsTypes {
  transactionIdentifier?: string;
}

interface DocumentsContextType {
  transactionId?: string;
  fileNumber?: string;
  documents?: Document[];
  milestones?: Milestone[];
}

export const DocumentsContext = createContext<DocumentsContextType>({});

export const Documents: FC<DocumentsProps> = ({ transactionIdentifier, children }) => {
  useEffect(() => {
    trackPage<Page>('Documents');
  }, []);

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Documents', path: '' }]);
  }, []);

  const { data: uploadsData } = useUploadDocument();
  const uploadPollInterval = setUploadRefreshPollInterval(uploadsData);

  const queryInput = buildQueryInput(transactionIdentifier);

  const { data, loading, error, startPolling, stopPolling } = useQuery<GetDocumentsType>(GET_DOCUMENTS, {
    variables: { where: queryInput },
    pollInterval: uploadPollInterval,
  });

  const documentsQueryRespnose = cloneDeep(data?.transaction.documents);
  const sortedDocuments = sortDocuments(loading, documentsQueryRespnose);

  const hasNoDocuments = !data?.transaction.documents.length;

  const docsCountBeforeUpload = useRef(sortedDocuments.length || 0);

  if (!uploadsData?.uploads.length) {
    docsCountBeforeUpload.current = sortedDocuments.length;
  }

  useEffect(() => {
    const numberOfUploads = uploadsData?.uploads?.length || 0;
    const waitingForUploads = sortedDocuments.length !== docsCountBeforeUpload.current + numberOfUploads;

    if (numberOfUploads && waitingForUploads) {
      startPolling(500);
    }

    if (!waitingForUploads) {
      stopPolling();
    }
  }, [uploadsData, sortedDocuments, startPolling, stopPolling]);

  const contextValue = React.useMemo(
    () => ({
      transactionId: queryInput.id || '',
      fileNumber: data?.transaction.fileNum || '',
      documents: sortedDocuments,
      milestones: data?.transaction.milestones,
    }),
    [queryInput.id, data?.transaction.fileNum, sortedDocuments, data?.transaction.milestones],
  );

  return (
    <DocumentsContext.Provider value={contextValue}>
      <LayoutContent width="100%">
        <LayoutSubContent p="space60">
          <DocumentsHeader disableButton={loading || !!error} />
          <Box>
            <Card variant="shadow">
              {loading && <SkeletonTable />}
              {error && <ErrorMessage />}
              {data?.transaction?.documents && <DocumentsTable documents={sortedDocuments} />}
              {data?.transaction && hasNoDocuments && (
                <Flex alignItems="center" height={224} justifyContent="center">
                  <Heading as="h5" data-test-id="heading-no-documents" size="fontSize40">
                    No documents have been added yet.
                  </Heading>
                </Flex>
              )}
            </Card>
          </Box>
        </LayoutSubContent>
        {children}
      </LayoutContent>
    </DocumentsContext.Provider>
  );
};

Documents.displayName = 'Documents';
