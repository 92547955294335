import React, { ChangeEvent, FC } from 'react';
import { useFormikContext, Field } from 'formik';
import { ContactType, TransactionRole, TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import {
  DrawerSection,
  Flex,
  Input,
  InputErrorMessage,
  InputGroup,
  InputLabel,
  Select,
  Stack,
} from '@endpoint/blockparty';
import { NewContactSchema, NewContactStep } from 'consts/createNewContact';
import { StateOption, stateOptions } from 'consts/states';
import { FormikCleaveInputGroup } from 'components/FormikCleaveInputGroup';
import { useNewContactProvider } from 'hooks/useContactProvider';
import { isEntity, isAgentOrTC, isSettlementCompanyByValues } from 'helpers/contacts';
import { formatPhoneNumber, removeNonNumeric } from 'helpers/formatPhone';

import { SelectContactTypeAndRoles } from './SelectContactTypeAndRole';
import { SearchContact } from './SearchContact';

interface ContactDetailsFormProps {
  transactionType: TransactionType;
}

export const ContactDetailsForm: FC<ContactDetailsFormProps> = ({ transactionType }) => {
  const { state } = useNewContactProvider();
  const { errors, setFieldValue, touched, values } = useFormikContext<NewContactSchema>();

  const isNonIndividualTransactee: boolean = isEntity(values.contactType);
  const showNMLSIDField: boolean =
    values.contactType === ContactType.LOAN_PROCESSOR || values.contactType === ContactType.LOAN_OFFICER;
  const isAgentOrTCContact: boolean = isAgentOrTC(values.contactType);
  const isPropTechPartner =
    values.contactType === ContactType.PROPTECH_PARTNER ||
    state.selectedContactData.contactType === ContactType.PROPTECH_PARTNER;

  const isSettlementCompany = isSettlementCompanyByValues(values, state);

  const isInternalEmployee =
    values.contactType === ContactType.INTERNAL_EMPLOYEE ||
    state.selectedContactData.contactType === ContactType.INTERNAL_EMPLOYEE;

  const showExtraNameField: boolean =
    values.contactType === ContactType.TRANSACTEE_INDIVIDUAL ||
    values.roles.includes(TransactionRole.BUYER) ||
    values.roles.includes(TransactionRole.SELLER);

  const showFormFields: boolean =
    (state.newContactStep === NewContactStep.ADD_CONTACT && !!values.contactType) ||
    state.newContactStep === NewContactStep.SEARCH_UPDATE_CONTACT;

  const disableFieldForUpdate: boolean = state.newContactStep === NewContactStep.SEARCH_UPDATE_CONTACT;
  const maskPhone: {
    blocks: number[];
    delimiters: string[];
    numericOnly: boolean;
  } = {
    numericOnly: true,
    blocks: [0, 3, 0, 3, 4],
    delimiters: ['(', ')', ' ', '-'],
  };

  const showEntityNameField = isPropTechPartner || isNonIndividualTransactee || isSettlementCompany;

  return (
    <DrawerSection pb="space0">
      <Flex flexDirection="column">
        {(state.newContactStep === NewContactStep.SEARCH_CONTACT ||
          state.newContactStep === NewContactStep.SEARCH_UPDATE_CONTACT) && (
          <SearchContact transactionType={transactionType} />
        )}
        {(state.newContactStep === NewContactStep.ADD_CONTACT ||
          state.newContactStep === NewContactStep.SEARCH_UPDATE_CONTACT) && (
          <SelectContactTypeAndRoles transactionType={transactionType} />
        )}
        {showFormFields && (
          <Stack spacing="space60">
            {showEntityNameField ? (
              <InputGroup
                groupId="entityName"
                isDisabled={isPropTechPartner || isSettlementCompany}
                isInvalid={touched.entityName && !!errors.entityName}
              >
                <InputLabel>Name *</InputLabel>
                <Field
                  as={Input}
                  autoComplete="none"
                  data-test-id="name-input"
                  inputId="entityName"
                  name="entityName"
                  placeholder="Name"
                  type="text"
                  value={values.entityName}
                />
                <InputErrorMessage>{errors.entityName}</InputErrorMessage>
              </InputGroup>
            ) : (
              <>
                <InputGroup
                  groupId="firstName"
                  isDisabled={isInternalEmployee}
                  isInvalid={touched.firstName && !!errors.firstName}
                >
                  <InputLabel>First Name *</InputLabel>
                  <Field
                    as={Input}
                    autoComplete="none"
                    data-test-id="first-name-input"
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    value={values.firstName}
                  />
                  <InputErrorMessage>{errors.firstName}</InputErrorMessage>
                </InputGroup>
                {showExtraNameField && (
                  <InputGroup groupId="middleName">
                    <InputLabel>Middle Name </InputLabel>
                    <Field
                      as={Input}
                      autoComplete="none"
                      data-test-id="middle-name-input"
                      name="middleName"
                      placeholder="Middle Name"
                      type="text"
                      value={values.middleName}
                    />
                  </InputGroup>
                )}
                <InputGroup
                  groupId="lastName"
                  isDisabled={isInternalEmployee}
                  isInvalid={touched.lastName && !!errors.lastName}
                >
                  <InputLabel>Last Name *</InputLabel>
                  <Field
                    as={Input}
                    autoComplete="none"
                    data-test-id="last-name-input"
                    name="lastName"
                    placeholder="Last Name"
                    type="text"
                    value={values.lastName}
                  />
                  <InputErrorMessage>{errors.lastName}</InputErrorMessage>
                </InputGroup>
              </>
            )}
            {isAgentOrTCContact && (
              <>
                <InputGroup groupId="licenseNumber" isInvalid={touched.licenseNumber && !!errors.licenseNumber}>
                  <InputLabel>License Number</InputLabel>
                  <Field
                    as={Input}
                    autoComplete="none"
                    data-test-id="license-number-input"
                    name="licenseNumber"
                    type="text"
                    value={values.licenseNumber}
                  />
                  <InputErrorMessage>{errors.licenseNumber}</InputErrorMessage>
                </InputGroup>
                <InputGroup groupId="stateOfOperation">
                  <InputLabel htmlFor="stateOfOperation">State of Operation</InputLabel>
                  <Field
                    as={Select}
                    defaultValue={stateOptions.find((option) => option.value === values.stateOfOperation)}
                    inputId="stateOfOperation"
                    isClearable
                    isSearchable
                    name="stateOfOperation"
                    options={stateOptions}
                    value={stateOptions.find((option) => option.value === values.stateOfOperation) || ''}
                    onChange={(option: StateOption | null) => {
                      if (!option) {
                        setFieldValue('stateOfOperation', '');
                      } else {
                        setFieldValue('stateOfOperation', option.value || '');
                      }
                    }}
                  />
                </InputGroup>
              </>
            )}
            {showNMLSIDField && (
              <InputGroup
                groupId="nmlsId"
                isDisabled={disableFieldForUpdate}
                isInvalid={touched.nmlsId && !!errors.nmlsId}
              >
                <InputLabel>NMLS ID</InputLabel>
                <Field
                  as={Input}
                  autoComplete="none"
                  data-test-id="nmlsId-input"
                  name="nmlsId"
                  type="text"
                  value={values.nmlsId}
                />
                <InputErrorMessage>{errors.nmlsId}</InputErrorMessage>
              </InputGroup>
            )}
            <InputGroup
              groupId="email"
              isDisabled={disableFieldForUpdate || isPropTechPartner || isSettlementCompany}
              isInvalid={touched.email && !!errors.email}
            >
              <InputLabel>Email</InputLabel>
              <Field
                as={Input}
                autoComplete="none"
                data-test-id="email-input"
                name="email"
                type="text"
                value={values.email}
              />
              <InputErrorMessage>{errors.email}</InputErrorMessage>
            </InputGroup>
            <FormikCleaveInputGroup
              groupId="phone"
              isDisabled={disableFieldForUpdate || isPropTechPartner || isSettlementCompany}
              label="Phone Number"
              mask={maskPhone}
              mb="space50"
              name="phone"
              type="text"
              value={formatPhoneNumber(values.phone || '') || undefined}
            />
          </Stack>
        )}
      </Flex>
    </DrawerSection>
  );
};
