import React, { FC } from 'react';
import { Drawer, DrawerActionBar, DrawerCloseButton, DrawerContent, DrawerSection } from '@endpoint/blockparty';
import { TaskPanelV2 } from 'routes/Transaction/TransactionRightPanel/TaskPanel/TaskPanelV2';

interface QueueDrawerProps {
  taskId: string;
  transactionIdentifier: string;
  isOpen: boolean;
  onClose(): void;
}

export const QueueDrawer: FC<QueueDrawerProps> = ({ taskId, transactionIdentifier, isOpen, onClose }) => {
  return (
    <Drawer id="queue-drawer" isOpen={isOpen} onClose={onClose}>
      <DrawerContent overflow="auto">
        <DrawerActionBar>
          <DrawerCloseButton />
        </DrawerActionBar>
        <DrawerSection paddingX="space0" pt="space20">
          <TaskPanelV2 taskId={taskId} transactionIdentifier={transactionIdentifier} />
        </DrawerSection>
      </DrawerContent>
    </Drawer>
  );
};
