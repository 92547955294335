import React, { FC } from 'react';
import { Box } from '@endpoint/blockparty';
import { AllContactTypes } from 'consts/contactDetails';
import { useEditContactDetailsProvider } from 'hooks/useEditContactDetailsProvider';
import { EditContactDetails } from 'routes/AddressBook/EditContactDetails/EditContactDetailsForm';

import { Details } from './Details';
import { ContactInformation } from './ContactInformation';
import { SystemInformation } from './SystemInformation';

interface DetailsTabProps {
  contact: AllContactTypes;
}

export const DetailsTab: FC<DetailsTabProps> = ({ contact }): React.ReactElement => {
  const { state } = useEditContactDetailsProvider();

  return (
    <Box data-test-id="contact-details-tab">
      {state.isEditing ? (
        <EditContactDetails contact={contact} />
      ) : (
        <>
          <Details contact={contact} />
          <ContactInformation contact={contact} />
          <SystemInformation contact={contact} />
        </>
      )}
    </Box>
  );
};
