import { Router } from '@reach/router';
import React, { FC, useEffect, useState } from 'react';
import { Dogs403 } from 'routes/403';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import useMarketsAndOrganizations from 'hooks/useMarketsAndOrganizations';

import { getCurrentUser, OpswareUser } from '../helpers';

const fallBack = () => {
  return (
    <Router>
      <Dogs403 default />
    </Router>
  );
};

const reportSentryError = (user: OpswareUser) => {
  Sentry.captureMessage(`Unauthorized user: ${user.id}.`, Severity.Info);
};

export const UserAuthWrapper: FC = ({ children }) => {
  const [isAuthorizedState, setisAuthorizedState] = useState(true);
  const { error: marketOrgError } = useMarketsAndOrganizations();

  useEffect(() => {
    if (marketOrgError) setisAuthorizedState(false);
  }, [marketOrgError]);

  useEffect(() => {
    let currentUser;

    try {
      currentUser = getCurrentUser();
    } catch (error) {
      console.log(`The following error occurred: ${error}`);
    }

    if (currentUser) {
      void currentUser.then((user: OpswareUser) => {
        if (!user.organization || !user.market) {
          setisAuthorizedState(false);
          reportSentryError(user);
        }
      });
    }
  });

  if (isAuthorizedState) {
    return <Router style={{ height: '100%' }}>{children}</Router>;
  }

  return (
    <Sentry.ErrorBoundary fallback={fallBack}>
      <Router style={{ height: '100%' }}>
        <Dogs403 default />
      </Router>
    </Sentry.ErrorBoundary>
  );
};
