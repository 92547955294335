import React, { FC } from 'react';
import { Box, MultiSelect } from '@endpoint/blockparty';
import { Organization } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { SelectStyleProps } from '@endpoint/blockparty/dist/components/Select/styles';
import useMarketsAndOrganizations from 'hooks/useMarketsAndOrganizations';

interface OrganizationSelectProps {
  onChange(organizationIds: number[]): void;
  defaultValue?: string[];
}

export const OrganizationSelect: FC<OrganizationSelectProps> = ({ onChange, defaultValue = [] }) => {
  const { organizations, loading } = useMarketsAndOrganizations();

  const MultiSelectOptions: SelectOptionsObject<string>[] =
    organizations?.map((organization: Organization) => ({
      value: organization.id.toString(),
      label: organization.name,
    })) || [];

  const defaultValueOptions = MultiSelectOptions.filter((option) => {
    return defaultValue.includes(option.value);
  });

  return (
    <Box data-test-id="filter-organization-select" minWidth={190}>
      <MultiSelect
        key={organizations?.length}
        controlText="Organization"
        customStyles={{
          menu: (base: SelectStyleProps) => ({
            ...base,
            minWidth: 220,
            right: 0,
          }),
        }}
        defaultValue={defaultValueOptions}
        isLoading={loading}
        options={MultiSelectOptions}
        onChange={(values: { value: string }[]) => onChange(values.map((value) => Number(value.value)))}
      />
    </Box>
  );
};
