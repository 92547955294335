import { TransactionRole } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export enum PinnedItemRole {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  ALL_BUYERS = 'ALL_BUYERS',
  ALL_SELLERS = 'ALL_SELLERS',
  ALL = 'ALL',
}

type PinnedItemRoleLookup = {
  [index in PinnedItemRole]: TransactionRole[];
};

type PinnedItemLookup = {
  [key in PinnedItemRole]: string;
};

export const pinnedItemRoleLookupMap: PinnedItemRoleLookup = {
  [PinnedItemRole.BUYER]: [TransactionRole.BUYER],
  [PinnedItemRole.SELLER]: [TransactionRole.SELLER],
  [PinnedItemRole.ALL_BUYERS]: [
    TransactionRole.BUYER,
    TransactionRole.SELLING_AGENT,
    TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR,
  ],
  [PinnedItemRole.ALL_SELLERS]: [
    TransactionRole.SELLER,
    TransactionRole.LISTING_AGENT,
    TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR,
  ],
  [PinnedItemRole.ALL]: Object.values(TransactionRole).filter(
    (role) => role !== TransactionRole.PROPTECH_BUYER && role !== TransactionRole.PROPTECH_SELLER,
  ),
};

export const pinnedItemToIcon: PinnedItemLookup = {
  [PinnedItemRole.ALL]: 'FolderOutline',
  [PinnedItemRole.BUYER]: 'PinPerson',
  [PinnedItemRole.SELLER]: 'PinPerson',
  [PinnedItemRole.ALL_BUYERS]: 'DollarSign',
  [PinnedItemRole.ALL_SELLERS]: 'DollarSign',
};

export const pinnedItemToText: PinnedItemLookup = {
  [PinnedItemRole.ALL]: 'All',
  [PinnedItemRole.BUYER]: "Buyer's Side",
  [PinnedItemRole.SELLER]: "Seller's Side",
  [PinnedItemRole.ALL_BUYERS]: 'All Buyers',
  [PinnedItemRole.ALL_SELLERS]: 'All Sellers',
};

// interfaces for pinneditems

export interface PinnedItemData {
  title: string;
}

export interface PinnedItemM2 extends PinnedItemData {
  title: 'How to Pay with a Check';
  paymentDetails: string;
}

export interface PinnedItemM6Notary extends PinnedItemData {
  title: 'Signing Appointment';
  time: string;
  location: string;
  notary: string;
}
