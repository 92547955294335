import React, { FC, useState } from 'react';
import { Box, MultiSelect, Text } from '@endpoint/blockparty';
import { Market } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import useMarketsAndOrganizations from 'hooks/useMarketsAndOrganizations';
import { SelectStyleProps } from '@endpoint/blockparty/dist/components/Select/styles';
import { components, GroupHeadingProps } from 'react-select';

interface MarketSelectProps {
  onChange?(marketIds: number[]): void;
  onMenuClose?(marketIds: number[]): void;
  defaultValue?: string[];
  organizationValue?: number[];
  variant?: 'counter' | 'tag';
  minWidth?: number;
  maxWidth?: number;
}

interface MultiSelectOptionsProps {
  [key: string]: {
    label: string;
    options: SelectOptionsObject<string>[];
  };
}

const GroupHeading = (props: GroupHeadingProps) => {
  const { children } = props;

  return (
    <components.GroupHeading {...props}>
      <Text color="carbon500" textTransform="capitalize">
        {children}
      </Text>
    </components.GroupHeading>
  );
};

type OrganizationShortNames = { [key: number]: string };

export const MarketSelect: FC<MarketSelectProps> = ({
  onChange,
  onMenuClose,
  defaultValue = [],
  organizationValue,
  variant,
  minWidth = 190,
  maxWidth,
}) => {
  const [marketIds, setMarketIds] = useState<number[]>((defaultValue || []).map(Number));
  const { markets, organizations, loading } = useMarketsAndOrganizations();

  const organizationShortNames: OrganizationShortNames | undefined = organizations?.reduce(
    (shortNames, { id, shortName }) => {
      shortNames[id] = shortName;

      return shortNames;
    },
    {} as OrganizationShortNames,
  );

  const formatMultiSelectOptions: MultiSelectOptionsProps =
    markets
      ?.filter((market: Market) =>
        organizationValue?.length ? organizationValue?.includes(market.organizationId) : market,
      )
      .reduce((prev, current) => {
        const getOrg: string = organizationShortNames?.[current.organizationId];
        const option: SelectOptionsObject<string> = {
          value: current.id.toString(),
          label: `${current.name}`,
        };

        const newOption = prev[getOrg]?.options?.length ? [...prev[getOrg].options, option] : [option];

        const multiSelectOption: MultiSelectOptionsProps = {
          ...prev,
          [getOrg]: {
            label: getOrg,
            options: newOption,
          },
        };

        return multiSelectOption;
      }, [] as unknown as MultiSelectOptionsProps) || [];

  const multiSelectOptions = Object.values(formatMultiSelectOptions);

  const defaultValueOptions = multiSelectOptions
    .map((value) => value.options)
    .flat()
    .filter((option) => {
      return (defaultValue || []).includes(option.value);
    });

  multiSelectOptions.forEach((_element, index) => {
    multiSelectOptions[index]?.options.sort((optionA, optionB) => (optionA.label > optionB.label ? 1 : -1));
  });

  const handleOnChange = (values: { value: string }[], { action }: { action: string }) => {
    const modifiedValues = values.map((value) => Number(value.value));

    setMarketIds(modifiedValues);

    if (onChange) {
      onChange(modifiedValues);
    }

    if ((action === 'clear' || action === 'remove-value') && onMenuClose) {
      onMenuClose(modifiedValues);
    }
  };

  const handleOnMenuClose = () => {
    if (onMenuClose) {
      onMenuClose(marketIds);
    }
  };

  return (
    <Box maxWidth={maxWidth} minWidth={minWidth}>
      <MultiSelect
        key={markets?.length}
        components={{ GroupHeading }}
        controlText="Market"
        customStyles={{
          menu: (base: SelectStyleProps) => ({
            ...base,
            right: 0,
          }),
          multiValue: (base: SelectStyleProps) => ({
            ...base,
            borderRadius: 50,
          }),
          multiValueRemove: (base: SelectStyleProps) => ({
            ...base,
            ':hover': {
              backgroundColor: '#e4e6f0',
            },
          }),
        }}
        data-test-id="filter-office-select"
        defaultValue={defaultValueOptions}
        isLoading={loading}
        options={multiSelectOptions}
        variant={variant}
        // @ts-ignore
        onChange={handleOnChange}
        onMenuClose={handleOnMenuClose}
      />
    </Box>
  );
};
