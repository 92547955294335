import React, { FC } from 'react';
import { Card, CardSection, Text, Icon, Flex, Popover, PopoverTrigger, usePopoverState } from '@endpoint/blockparty';
import { TodoAssignment, TodoStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { StatusTag } from 'components/StatusTag';
import { formatRolesLikeTitle } from 'helpers/formatText';
import { prettyDate } from 'helpers/datetimeHelper';
import { UpdateTodoStatus } from 'components/UpdateTodoStatus';
import { AllContactTypes } from 'consts/contactDetails';
import { getContactData } from 'helpers/transactionParticipants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { v4 } from 'uuid';
import { CustomerStatusCardQuestionAnswer } from 'routes/Transaction/TransactionRightPanel/CustomerStatus/CustomerStatusCard/CustomerStatusCardQuestionAnswer';
import { aggregateStepAnswerFields } from 'helpers/todo';
import { useHasWriteAccess } from 'hooks/useHasWriteAccess';

import { CustomerStatusCardBody, CustomerStatusDates } from './CustomerStatusCardBody';

interface CustomerStatusCardProps {
  data: TodoAssignment;
}

export const CustomerStatusCard: FC<CustomerStatusCardProps> = ({ data }) => {
  const popover = usePopoverState({ placement: 'bottom-end' });
  const customerStatusDates: CustomerStatusDates = {
    toDoDelivered: prettyDate(data.createdAt),
    toDoStarted: data.updatedAt ? prettyDate(data.updatedAt) : undefined,
    submitted: data.completedAt ? prettyDate(data.completedAt) : undefined,
    reset: data.deletedAt ? prettyDate(data.deletedAt) : undefined,
  };
  const contact = getContactData(data.assignee.contact as AllContactTypes);
  const assigneeName = contact.fullName;
  const stepAnswerFields = aggregateStepAnswerFields(data.stepAnswers);
  const { isTodoQuestionsAnswersEnabled } = useFlags();

  const hasWriteAccess = useHasWriteAccess();

  return (
    <Card data-test-id="customer-status-card" mb="space50">
      <CardSection
        alignItems="center"
        data-test-id="customer-status-card-header"
        justifyContent="space-between"
        pb="space50"
        pt="space60"
        px="space60"
      >
        <Flex alignItems="center">
          <StatusTag status={data.status} variantType="outline" />
          {data.status === TodoStatus.SUBMITTED_BY_PEER && (
            <Text
              as="p"
              color="carbon600"
              data-test-id="customer-status-by-peer"
              fontWeight="normal"
              pl="space50"
              size="fontSize10"
            >
              by Peer
            </Text>
          )}
        </Flex>
        {data.status !== TodoStatus.RECALLED && (
          <>
            <PopoverTrigger {...popover}>
              <Icon color="carbon500" data-test-id="todo-status-trigger" name="MoreHorizontal" size="large" />
            </PopoverTrigger>
            {hasWriteAccess && (
              <Popover {...popover} aria-label="Update To-Do Status" px="space0" py="space30">
                <UpdateTodoStatus assignmentStatus={data} updateLocation="Customer Status Card" />
              </Popover>
            )}
          </>
        )}
      </CardSection>
      <CardSection flexDirection="column" pb="space60" px="space60">
        <Text as="p" data-test-id="assignee" fontWeight="semi" pb="space40" size="fontSize30">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          {assigneeName} ({formatRolesLikeTitle(data.assignee.roles.join(', ') || '', true)})
        </Text>
        <CustomerStatusCardBody data={customerStatusDates} data-test-id="customer-status-card-body" />
        {isTodoQuestionsAnswersEnabled &&
          stepAnswerFields.map((field) => (
            <CustomerStatusCardQuestionAnswer
              key={v4()}
              answer={field.answer}
              document={field.document}
              fieldType={field.fieldType}
              label={field.label}
            />
          ))}
      </CardSection>
    </Card>
  );
};
