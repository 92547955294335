import { Flex, Text } from '@endpoint/blockparty';
import { TransactionParticipant, TransactionRole } from '@endpoint/opsware-bff-graphql-schema';
import { getContactData } from 'helpers/transactionParticipants';
import React, { FC } from 'react';

interface TransactionOverviewAgentsProps {
  transactionParticipants: TransactionParticipant[];
}

interface TransactionParticipantsTextDisplayProps {
  participants: string[];
}

export const TransactionParticipantsTextDisplay: FC<TransactionParticipantsTextDisplayProps> = ({ participants }) => {
  return (
    <>
      {participants.map((name, i) => {
        return (
          <Flex key={name}>
            {i !== participants.length - 1 ? (
              <Text color="blue300">{`${name}, ${'\xa0'}`}</Text>
            ) : (
              <Text color="blue300">{`${name} ${'\xa0'}`}</Text>
            )}
          </Flex>
        );
      })}
    </>
  );
};

export const TransactionOverviewAgents: FC<TransactionOverviewAgentsProps> = ({ transactionParticipants }) => {
  const contacts = createContactsArray(transactionParticipants);

  const { sellers, buyers, borrowers } = contacts;

  const text = 'to';

  return (
    <Flex data-test-id="agents-text-display" mt="space70">
      <TransactionParticipantsTextDisplay participants={sellers} />
      {sellers.length && buyers.length ? <Text>{`${text} \xa0`}</Text> : null}
      <TransactionParticipantsTextDisplay participants={buyers} />
      {borrowers.length ? <TransactionParticipantsTextDisplay participants={borrowers} /> : null}
    </Flex>
  );
};

export const createContactsArray = (transactionParticipants: TransactionParticipant[]) => {
  const sellers: string[] = [];
  const buyers: string[] = [];
  const borrowers: string[] = [];

  transactionParticipants.forEach((participant) => {
    const contactData = getContactData(participant.contact);

    if (participant.roles[0] === 'SELLING_AGENT' || participant.roles[0] === 'SELLER') {
      sellers.push(contactData.fullName);
    } else if (participant.roles[0] === 'BUYER') {
      buyers.push(contactData.fullName);
    } else if (participant.roles.includes(TransactionRole.BORROWER)) {
      borrowers.push(contactData.fullName);
    }
  });

  return { sellers, buyers, borrowers };
};
