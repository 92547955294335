import React, { FC } from 'react';
import { Box, MultiSelect } from '@endpoint/blockparty';
import { qualityControlStatusMap } from 'consts/transactionConsts';
import { SelectStyleProps } from '@endpoint/blockparty/dist/components/Select/styles';
import { QualityControlStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const currentValidOrderStatuses = [
  QualityControlStatus.NEW_ORDER,
  QualityControlStatus.PREPARING_ORDER,
  QualityControlStatus.QC_READY,
  QualityControlStatus.QC_FAILED,
];

export const MultiSelectOptions = Object.values(currentValidOrderStatuses).map((qualityControlStatus) => ({
  value: qualityControlStatus,
  label: qualityControlStatusMap[qualityControlStatus],
}));

interface OrderStatusSelectProps {
  onChange(options: QualityControlStatus[]): void;
  defaultValue?: QualityControlStatus[];
}

export const OrderStatusSelect: FC<OrderStatusSelectProps> = ({ onChange, defaultValue }) => {
  const defaultValueOptions = MultiSelectOptions.filter((option) => {
    return defaultValue && defaultValue.includes(option.value);
  });

  return (
    <Box data-testid="open-order-status-select" minWidth={240}>
      <MultiSelect
        controlText="Order Status"
        customStyles={{
          menu: (base: SelectStyleProps) => ({
            ...base,
            minWidth: 180,
            right: 0,
          }),
        }}
        defaultValue={defaultValueOptions}
        options={MultiSelectOptions}
        onChange={(values: { value: QualityControlStatus }[]) => onChange(values.map((value) => value.value))}
      />
    </Box>
  );
};
