import React, { FC } from 'react';
import { Button } from '@endpoint/blockparty';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';

interface UploadDocumentsButtonProps {
  disabled: boolean;
  buttonText?: string;
  iconLeft?: IconNames;
}

export const UploadDocumentsButton: FC<UploadDocumentsButtonProps> = ({ disabled, buttonText, iconLeft }) => {
  const { toggleUploadDrawer } = useUploadDocument();

  const handleClick = () => {
    toggleUploadDrawer();
  };

  return (
    <ButtonWriteRequired
      data-test-id="upload-button"
      iconLeft={iconLeft}
      isDisabled={disabled}
      variant="outline"
      onClick={handleClick}
    >
      {buttonText || 'Upload'}
    </ButtonWriteRequired>
  );
};
