import React, { FC } from 'react';
import { Box, Select } from '@endpoint/blockparty';
import { ContactType } from '@endpoint/opsware-bff-graphql-schema';
import { contactTypeOptions as options } from 'consts/createNewContact';

interface ContactTypeOption {
  label: string;
  value: ContactType | '';
}

export const contactTypeOptions: ContactTypeOption[] = [{ label: 'All Contact Types', value: '' }, ...options];

interface ContactTypeSelectProps {
  onChange(options: ContactType | ''): void;
  defaultValue: ContactType | '';
}

export const ContactTypeSelect: FC<ContactTypeSelectProps> = ({ onChange, defaultValue }) => {
  return (
    <Box data-test-id="contact-type-select" marginLeft="space50" minWidth={240}>
      <Select
        defaultValue={contactTypeOptions.find((option) => option.value === defaultValue)}
        isSearchable
        options={contactTypeOptions}
        onChange={(option: ContactTypeOption) => {
          onChange(option.value);
        }}
      />
    </Box>
  );
};
