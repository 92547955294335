import React, { FC, useState, useCallback, useEffect } from 'react';
import { Flex } from '@endpoint/blockparty';
import { Formik } from 'formik';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import useOCRSupport from 'hooks/useOCRSupport';
import { initialError } from 'components/OpenOrderDrawer';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';
import { ReviewForm } from 'routes/DocumentReview/OpenOrderReview/ReviewForm';
import { useApolloClient } from '@apollo/client';
import { emptyOpenOrderDrawerForm } from 'helpers/openOrder/emptyOpenOrderDrawerForm';
import { orderReviewValidationSchema, handleSubmit } from 'routes/DocumentReview/OpenOrderReview/handleSubmit';
import { set } from 'lodash';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema';
import { DocumentReviewType } from 'routes/DocumentReview/contexts/DocumentReviewContext';

import { useDocumentReviewContext } from '../contexts/DocumentReviewContext';
import { DiscardOrderModal } from './DiscardOrderModal';
import { OpenOrderFooter } from './OpenOrderFooter';
import { OcrWaitingScreen } from './OcrWaitingScreen';

interface OpenOrderReviewProps {
  transaction?: Transaction;
}

export const OpenOrderReview: FC<OpenOrderReviewProps> = ({ transaction }) => {
  const { documentId, documentReviewType, documentRegion } = useDocumentReviewContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<OpenOrderDrawerForm>({
    ...emptyOpenOrderDrawerForm(),
    documentId,
  });
  const { clearUploads } = useUploadDocument();
  const client = useApolloClient();
  const { isValidMarket, loading: regionsLoading } = useOCRSupport(documentRegion);
  const isOCRComplete = showForm || !isValidMarket || documentReviewType === DocumentReviewType.ORDER_REVIEW;
  const isPrelim = transaction?.status === 'PRELISTING';
  const setDefaultValue = useCallback((path, value) => {
    setInitialValues((currentValues) => set(currentValues, path, value));
  }, []);

  useEffect(() => {
    if (transaction) {
      setDefaultValue('property.address.street1', transaction?.property.address.street1 ?? '');
      setDefaultValue('property.address.street2', transaction?.property.address.street2 ?? '');
      setDefaultValue('property.address.city', transaction?.property.address.city ?? '');
      setDefaultValue('property.address.state', transaction?.property.address.state ?? '');
      setDefaultValue('property.address.zip', transaction?.property.address.zip ?? '');
      setDefaultValue('property.parcelNumber', transaction?.property.parcelNumber ?? '');
      setDefaultValue('property.address.county', transaction?.property.address.county ?? '');
      setDefaultValue('transactionStatus', transaction?.status);
      setDefaultValue('transactionId', transaction?.id);
      setDefaultValue('organization', transaction?.organizationId ?? '');
      setDefaultValue('isTest', transaction?.isTest);

      if (documentReviewType === DocumentReviewType.ORDER_REVIEW) {
        setDefaultValue('terms.productType', transaction?.type);
        setDefaultValue('terms.salePrice', transaction?.price);
        setDefaultValue('terms.loanAmount', transaction?.loanAmount);
        setDefaultValue('terms.loanNumber', transaction?.loanNumber ?? '');
        setDefaultValue('terms.emdAmount', transaction?.emd?.amount ?? '');

        if (transaction?.dates.estimatedClosing) {
          setDefaultValue('dates.closingDate', new Date(transaction?.dates.estimatedClosing));
        }

        if (transaction?.dates.contractExecutedDate) {
          setDefaultValue('dates.contractExecutedDate', new Date(transaction?.dates.contractExecutedDate));
        }

        if (transaction?.dates.emdContractDate) {
          setDefaultValue('dates.emdDueDate', new Date(transaction?.dates.emdContractDate));
        }
      }
    }
  }, [transaction, setDefaultValue, documentReviewType]);

  return (
    <Formik
      enableReinitialize
      initialErrors={initialError}
      initialStatus={{ error: '' }}
      initialValues={initialValues}
      validateOnChange
      validationSchema={orderReviewValidationSchema}
      onSubmit={async (values, { resetForm, setSubmitting, setStatus }) => {
        await handleSubmit(values, {
          resetForm,
          setSubmitting,
          setStatus,
          client,
          clearUploads,
          documentReviewType,
        });
      }}
    >
      {!regionsLoading && (
        <>
          <Flex flex={1} flexDirection="column" overflow="auto">
            {isOCRComplete ? (
              <ReviewForm />
            ) : (
              <OcrWaitingScreen
                next={() => setShowForm(true)}
                prelimConversion={isPrelim}
                setDefaultValue={setDefaultValue}
              />
            )}

            <DiscardOrderModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
          </Flex>

          <OpenOrderFooter discard={() => setIsModalOpen(true)} transaction={transaction} />
        </>
      )}
    </Formik>
  );
};
