import React, { FC, useState } from 'react';
import { add, parseISO } from 'date-fns';
import { Box, Flex, Icon, ListChoice, Text } from '@endpoint/blockparty';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import { MilestoneType, Transaction, TransactionStage } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { calculateDateDifference, dateFormatter } from 'helpers/datetimeHelper';
import { formatLikeTitle } from 'helpers/formatText';
import { getCalendarEventRowStage } from 'helpers/calendar';
import { BoxProps } from '@endpoint/blockparty/dist/components/Box';
import { useLazyQuery } from '@apollo/client';
import { GetTransactionType } from 'routes/Transaction/TransactionBasicInfo/queries';

import { CalendarEventDrawer } from './CalendarEventDrawer';
import { GET_TRANSACTION_CALENDAR_INFO } from './queries';

export interface CalendarEventRowProps {
  description?: string;
  eventType: MilestoneType | TransactionStage;
  iconColor?: BoxProps['color'];
  iconName: string;
  iconSize?: string;
  isTransactionEvent: boolean;
  time: string;
  transaction: Transaction;
}

export const CalendarEventRow: FC<CalendarEventRowProps> = ({
  description,
  eventType,
  iconColor,
  iconName,
  iconSize,
  isTransactionEvent,
  time,
  transaction,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const eventTimeISO = parseISO(time);
  const eventTimePlusThirty: Date | null = eventTimeISO ? add(eventTimeISO, { minutes: 30 }) : null;
  const eventTime: string = !isTransactionEvent
    ? `${dateFormatter(time, 'h:mm')}-${dateFormatter(eventTimePlusThirty, 'h:mma')}`
    : calculateDateDifference(new Date(), eventTimeISO);

  const eventText = getCalendarEventRowStage(isTransactionEvent, eventType, transaction);
  const [getCalendarTransactionInfo, { data, loading, error }] =
    useLazyQuery<GetTransactionType>(GET_TRANSACTION_CALENDAR_INFO);

  const handleToggleDrawer = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getCalendarTransactionInfo({ variables: { where: { id: transaction.id } } });
    setIsDrawerOpen(!isDrawerOpen);
  };

  // the fixed widths set on the first two boxes were a request from Kim
  return (
    <Flex>
      <ListChoice
        data-test-id={
          isTransactionEvent
            ? `calendar-event-row-${transaction.fileNum}`
            : `calendar-event-row-${transaction.fileNum}-${eventText}`
        }
        onClick={handleToggleDrawer}
      >
        <Flex px="space60" py="space30">
          <Box minWidth={132}>
            <Text color="carbon500" data-test-id={`event-time-${transaction.fileNum}`}>
              {eventTime}
            </Text>
          </Box>
          <Flex alignItems="center" minWidth={264}>
            <Box>
              <Icon
                color={iconColor || 'blue500'}
                mr="space30"
                name={iconName as IconNames}
                size={iconSize || 'medium'}
              />
            </Box>
            <Text data-test-id={`event-type-${transaction.fileNum}`}>{formatLikeTitle(eventText)}</Text>
          </Flex>
          <Box maxWidth={965} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            <Text data-test-id={`file-number-${transaction.fileNum}`} fontWeight="semi">
              {transaction.fileNum}{' '}
            </Text>
            <Text data-test-id={`event-description-${transaction.fileNum}`}>{`${description || ''}`}</Text>
          </Box>
        </Flex>
      </ListChoice>
      <CalendarEventDrawer
        error={error}
        eventType={eventType}
        headerText={`${transaction.fileNum} ${description}`}
        isOpen={isDrawerOpen}
        isTransactionEvent={isTransactionEvent}
        loading={loading}
        signingDate={eventTimeISO}
        signingTimePlusThirty={eventTimePlusThirty}
        transaction={data?.transaction}
        onClose={handleToggleDrawer}
      />
    </Flex>
  );
};

CalendarEventRow.displayName = 'CalendarEventRow';
Icon.displayName = 'Icon';
