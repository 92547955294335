import { TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const activeTransactionTypes = [
  TransactionType.SALE_MORTGAGE,
  TransactionType.SALE_CASH,
  TransactionType.PRELISTING,
  TransactionType.REFINANCE,
];

export function useGetActiveTransactionTypes(): TransactionType[] {
  const { canOpenEquityTransaction } = useFlags();

  let transactionTypes = activeTransactionTypes;

  if (canOpenEquityTransaction) {
    transactionTypes = [...transactionTypes, TransactionType.EQUITY];
  }

  return transactionTypes;
}
