import { useFlags } from 'launchdarkly-react-client-sdk';
import { OrganizationType } from 'consts/organizationConsts';
import { useQuery } from '@apollo/client';
import { GET_MARKETS_AND_ORGANIZATIONS, MarketsAndOrganizations } from 'consts/getMarketsAndOrganizationsQuery';
import { useEffect, useMemo, useState } from 'react';
import { OpswareUser } from 'components/Auth/helpers';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';

export const COFLTN_EXPANSION_PACK = ['CO', 'FL', 'TN'];
export const MOOHIN_EXPANSION_PACK = ['MO', 'OH', 'IN'];

export default function useMarketsAndOrganizations() {
  const { isCoFlTnExpansionEnabled, isMoOhInExpansionEnabled } = useFlags();
  const { data, error, loading } = useQuery<MarketsAndOrganizations>(GET_MARKETS_AND_ORGANIZATIONS);
  const { data: currentUser } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);
  const [isError, setIsError] = useState(false);
  const organizations = data?.organizations || [];
  const hasMultiOrgAccess = currentUser?.me?.hasMultiOrganizationAccess ?? false;

  useEffect(() => {
    if (!loading) {
      if (error || !data?.markets || !data?.organizations) setIsError(true);
    }
  }, [data, setIsError, error, loading]);

  const markets = useMemo(
    () =>
      (data?.markets ?? []).filter((market) => {
        const isCOFLTN = COFLTN_EXPANSION_PACK.includes(market.state);
        const isMOOHIN = MOOHIN_EXPANSION_PACK.includes(market.state);

        if (!market.active) return false;
        if (market.organizationId !== OrganizationType.ENDPOINT_CLOSING) return true;
        if (isCoFlTnExpansionEnabled && isCOFLTN) return true;
        if (isMoOhInExpansionEnabled && isMOOHIN) return true;

        return !isCOFLTN && !isMOOHIN;
      }),
    [isCoFlTnExpansionEnabled, isMoOhInExpansionEnabled, data?.markets],
  );

  return {
    markets,
    organizations,
    hasMultiOrgAccess,
    showOrganizationDropdown: hasMultiOrgAccess,
    showMarketDropdown: hasMultiOrgAccess || markets.length > 1,
    error: isError,
    loading,
  };
}
