import {
  Market,
  Milestone,
  MilestoneStatus,
  MilestoneType,
  Transaction,
  TransactionStage,
  TransactionStatus,
  TransactionType,
} from '@endpoint/opsware-bff-graphql-schema';
import { OrganizationType } from 'consts/organizationConsts';
import { startOfDay } from 'date-fns';
import { useQueryParams } from 'helpers/utils/queryParams';

export const isTableFundingMarket = (markets: Market[], marketId: number | undefined): boolean => {
  const marketID = marketId ?? 0;

  return markets.some((market) => market.id === marketID);
};

export const getTransactionStage = (
  transaction: Transaction,
  markets: Market[],
  defValue = TransactionStage.NO_SIGNINGS_SCHEDULED,
): TransactionStage => {
  let eventType = defValue;
  const isTransactionMarketTableFunding = isTableFundingMarket(markets, transaction?.marketId);

  if (isTransactionMarketTableFunding) {
    const isRecordingMilestoneCompleted = transaction.milestones.some(
      (milestone: Milestone) =>
        milestone.type === MilestoneType.RECORDING_COMPLETE && milestone.status === MilestoneStatus.COMPLETE,
    );

    const isTransactionDisbursedMilestoneCompleted = transaction.milestones.some(
      (milestone: Milestone) =>
        milestone.type === MilestoneType.TRANSACTION_DISBURSED && milestone.status === MilestoneStatus.COMPLETE,
    );

    if (
      transaction.status === TransactionStatus.CLOSED ||
      (isRecordingMilestoneCompleted && isTransactionDisbursedMilestoneCompleted)
    ) {
      eventType = TransactionStage.RECORDING_COMPLETE;
    }
  }

  return eventType;
};

export interface CalendarFilters {
  date: Date;
  showOpenTransactions: boolean | string;
  showClosedTransactions: boolean | string;
  showSignings: boolean | string;
  marketIds: number[];
  organizationIds: OrganizationType[];
}

export function useCalendarFilters() {
  return useQueryParams((params: Partial<CalendarFilters>) => ({
    date: startOfDay(params.date ? new Date(params.date) : new Date()),
    showOpenTransactions: params.showOpenTransactions !== 'false',
    showClosedTransactions: params.showClosedTransactions !== 'false',
    showSignings: params.showSignings !== 'false',
    marketIds: [params.marketIds ?? []].flat(1).map(Number),
    organizationIds: [params.organizationIds ?? []].flat(1).map(Number),
  }));
}

export function getCalendarEventRowStage(
  isTransactionEvent: boolean,
  eventType: TransactionStage | MilestoneType,
  transaction: Transaction,
): string {
  if (
    isTransactionEvent &&
    transaction.type &&
    transaction.type === TransactionType.REFINANCE &&
    (eventType === TransactionStage.ALL_SIGNINGS_SCHEDULED ||
      eventType === TransactionStage.SELLER_SIGNING_SCHEDULED ||
      eventType === MilestoneType.SELLER_SIGNING_SCHEDULED)
  )
    return 'Borrower Signing Scheduled';

  if (
    !isTransactionEvent &&
    transaction.type &&
    transaction.type === TransactionType.REFINANCE &&
    (eventType === TransactionStage.ALL_SIGNINGS_SCHEDULED ||
      eventType === TransactionStage.SELLER_SIGNING_SCHEDULED ||
      eventType === MilestoneType.SELLER_SIGNING_SCHEDULED)
  )
    return 'Borrower Signing';

  if (
    !isTransactionEvent &&
    transaction.type &&
    transaction.type !== TransactionType.REFINANCE &&
    eventType === MilestoneType.SELLER_SIGNING_SCHEDULED
  )
    return 'Seller Signing';
  if (
    !isTransactionEvent &&
    transaction.type &&
    transaction.type !== TransactionType.REFINANCE &&
    eventType === MilestoneType.BUYER_SIGNING_SCHEDULED
  )
    return 'Buyer Signing';

  return eventType;
}
