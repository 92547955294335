import React, { FC, FocusEvent } from 'react';
import { Textarea, Text } from '@endpoint/blockparty';
import { useField } from 'formik';
import { FormikItemValue } from 'helpers/dynamicForm/types';

interface TaskStepItemTextAreaProps {
  name: string;
  title?: string;
  description?: string;
}

export const TaskStepItemTextArea: FC<TaskStepItemTextAreaProps> = ({ name, title, description }) => {
  const [field, meta, helpers] = useField<FormikItemValue>({ name });
  const value: string | undefined = field.value?.value as string | undefined;

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    helpers.setValue({ value: event.target.value });
  };

  return (
    <>
      <Text size="fontSize20">{title}</Text>
      <Textarea
        defaultValue={value}
        marginTop="space40"
        name={field.name}
        placeholder={description}
        onBlur={handleOnBlur}
      />
    </>
  );
};
