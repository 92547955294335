import React, { FC } from 'react';
import { Box, Flex, Icon, Text } from '@endpoint/blockparty';
import { dateFormatter } from 'helpers/datetimeHelper';

interface SigningApppointmentInfoProps {
  participants: string[] | [];
  side: 'Borrower' | 'Seller' | 'Buyer';
  completed: Boolean;
  date?: Date;
}

export const SigningApppointmentInfo: FC<SigningApppointmentInfoProps> = ({ participants, side, completed, date }) => {
  return (
    <Box>
      <Flex alignItems="flex-start">
        <Box aria-label={completed ? 'PinPerson' : 'PinOutline'}>
          <Icon
            color="blue500"
            data-test-id="signing-appointment-icon"
            mr="space40"
            name={completed ? 'PinPerson' : 'PinOutline'}
            size="large"
          />
        </Box>
        <Flex flexDirection="column">
          <Text data-test-id="signing-appointment-side">{side} Signing</Text>
          {participants.length > 0 && (
            <Text data-test-id="signing-appointment-participants">{`${participants.join(', ')}`}</Text>
          )}
          {completed && (
            <Text data-test-id="signing-appointment-date">
              {date ? dateFormatter(date, 'MMM d, h:mma') : 'Completed'}
            </Text>
          )}
          {!completed && <Text data-test-id="signing-appointment-not-scheduled">Not scheduled</Text>}
        </Flex>
      </Flex>
    </Box>
  );
};
