import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import { sortBy } from 'lodash';
import { Accordion, Box, LayoutContent, LayoutSubContent } from '@endpoint/blockparty';
import { useQuery } from '@apollo/client';
import { Milestone, Transaction, TransactionStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { filterMilestonesByTaskStatus, filterMilestonesByTodoStatus } from 'helpers/tasksHelper';
import { generateLoadingContentSkeltons } from 'components/LoadingContentSkeleton';
import { ErrorMessage } from 'components/ErrorMessage';
import { trackPage } from 'helpers/utils/segment/segmentAnalytics';
import { Page } from 'consts/segmentProtocols';
import { updateBreadcrumbs } from 'helpers/updateBreadcrumbs';
import { buildQueryInput } from 'helpers/buildQueryInput';

import { MilestoneAccordion } from './MilestoneAccordion';
import { TaskListHeader, TaskFilterOptions } from './TaskListHeader';
import { GET_TASKS, GetTasksType } from './queries';
import { NotAuthorized } from '../../../components/NotAuthorized';

type TaskListRouteParams = RouteComponentProps<{
  transactionIdentifier: string;
  taskId: string;
}>;

export const TaskList: FC<TaskListRouteParams> = ({ children, transactionIdentifier, taskId }) => {
  useEffect(() => {
    updateBreadcrumbs([
      {
        label: 'Tasks',
        path: '',
      },
    ]);
  }, []);

  useEffect(() => {
    trackPage<Page>('Tasks');
  }, []);

  const queryInput = buildQueryInput(transactionIdentifier);

  const { loading, error, data } = useQuery<GetTasksType>(GET_TASKS, {
    variables: {
      where: queryInput,
    },
  });

  const sortedMilestones = sortBy(data?.transaction.milestones || [], (milestone) => milestone.position);

  const [filterOptions, setFilterOptions] = useState<TaskFilterOptions>();
  const taskFilteredMilestones = filterMilestonesByTaskStatus(sortedMilestones, filterOptions?.tasks || []);

  const todoFilteredMilestones = filterMilestonesByTodoStatus(taskFilteredMilestones, filterOptions?.todos || []);

  const [openMilestones, setOpenMilestones] = useState(false);
  const taskListProviderValue = useMemo(
    () => ({
      fileNumber: data?.transaction.fileNum,
      transaction: data?.transaction,
      taskId,
    }),
    [data?.transaction, taskId],
  );

  if (data?.transaction.status === TransactionStatus.PRELISTING) {
    return <NotAuthorized />;
  }

  return (
    <TaskListContext.Provider value={taskListProviderValue}>
      <LayoutContent width="100%">
        <LayoutSubContent>
          <Box px="space60">
            <TaskListHeader
              onAccordionToggle={(): void => setOpenMilestones(!openMilestones)}
              onFilterChange={(options: TaskFilterOptions): void => setFilterOptions(options)}
            />
            {loading && generateLoadingContentSkeltons(6, 2, 'task-list')}
            {error && <ErrorMessage title="Workflow loading error" />}
            {data?.transaction && (
              <Accordion backgroundColor="transparent" openAll={openMilestones}>
                <MilestoneAccordionList
                  fileNumber={data?.transaction.fileNum}
                  milestones={todoFilteredMilestones}
                  taskId={taskId}
                />
              </Accordion>
            )}
          </Box>
        </LayoutSubContent>
        {children}
      </LayoutContent>
    </TaskListContext.Provider>
  );
};

interface MilestoneAccordionListProps {
  fileNumber?: string;
  milestones: Array<Milestone>;
  taskId?: string;
}

export const MilestoneAccordionList: FC<MilestoneAccordionListProps> = ({ milestones }) => (
  <>
    {milestones.map((milestone) => (
      <MilestoneAccordion key={`milestone-${milestone.id}`} milestone={milestone} />
    ))}
  </>
);

interface TaskListContextType {
  fileNumber?: string;
  transaction?: Transaction;
  taskId?: string;
}

export const TaskListContext = React.createContext<TaskListContextType>({});
