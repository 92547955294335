import React, { FC } from 'react';
import { Box, Flex } from '@endpoint/blockparty';

import { UploadDocumentsButton } from './UploadDocumentsButton';

interface DocumentsHeaderProps {
  disableButton: boolean;
}

export const DocumentsHeader: FC<DocumentsHeaderProps> = ({ disableButton }) => (
  <Flex justifyContent="space-between" pb="space60">
    <Box />
    <UploadDocumentsButton disabled={disableButton} />
  </Flex>
);

DocumentsHeader.displayName = 'DocumentsHeader';
