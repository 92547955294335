import { TodoModified, TrackingEvent } from 'consts/segmentProtocols';

// credit to Louisa Reese for developing the original version
// documentation: https://segment.com/docs/connections/sources/catalog/libraries/server/node/
import { getConfig } from '../config';

const config: AppConfig = getConfig();
const segmentKey = config && config.SEGMENT_WRITE_KEY ? config.SEGMENT_WRITE_KEY : 'write key';

export function trackAnalytics(name: 'Todo Modified', params: TodoModified): void;
export function trackAnalytics(name: TrackingEvent, params: GenericObject): void;

export function trackAnalytics(name: TrackingEvent, params: GenericObject) {
  if (segmentKey === 'write key') {
    console.log(name, params);
  } else {
    window?.analytics?.track?.(name, params);
  }
}

export function trackPage<P>(params: P) {
  if (segmentKey === 'write key') {
    console.log('params', params);
  } else {
    window?.analytics?.page?.(params);
  }
}
