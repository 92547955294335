import { StateOfOperation } from '@endpoint/opsware-bff-graphql-schema';

export interface StateOption {
  label: string;
  value: StateOfOperation | undefined;
}

export const stateOptions: StateOption[] = [
  { label: 'Alabama', value: StateOfOperation.AL },
  { label: 'Alaska', value: StateOfOperation.AK },
  { label: 'Arizona', value: StateOfOperation.AZ },
  { label: 'Arkansas', value: StateOfOperation.AR },
  { label: 'California', value: StateOfOperation.CA },
  { label: 'Colorado', value: StateOfOperation.CO },
  { label: 'Connecticut', value: StateOfOperation.CT },
  { label: 'Delaware', value: StateOfOperation.DE },
  { label: 'District of Columbia', value: StateOfOperation.DC },
  { label: 'Florida', value: StateOfOperation.FL },
  { label: 'Georgia', value: StateOfOperation.GA },
  { label: 'Hawaii', value: StateOfOperation.HI },
  { label: 'Idaho', value: StateOfOperation.ID },
  { label: 'Illinois', value: StateOfOperation.IL },
  { label: 'Indiana', value: StateOfOperation.IN },
  { label: 'Iowa', value: StateOfOperation.IA },
  { label: 'Kansas', value: StateOfOperation.KS },
  { label: 'Kentucky', value: StateOfOperation.KY },
  { label: 'Louisiana', value: StateOfOperation.LA },
  { label: 'Maine', value: StateOfOperation.ME },
  { label: 'Maryland', value: StateOfOperation.MD },
  { label: 'Massachusetts', value: StateOfOperation.MA },
  { label: 'Michigan', value: StateOfOperation.MI },
  { label: 'Minnesota', value: StateOfOperation.MN },
  { label: 'Mississippi', value: StateOfOperation.MS },
  { label: 'Missouri', value: StateOfOperation.MO },
  { label: 'Montana', value: StateOfOperation.MT },
  { label: 'Nebraska', value: StateOfOperation.NE },
  { label: 'Nevada', value: StateOfOperation.NV },
  { label: 'New Hampshire', value: StateOfOperation.NH },
  { label: 'New Jersey', value: StateOfOperation.NJ },
  { label: 'New Mexico', value: StateOfOperation.NM },
  { label: 'New York', value: StateOfOperation.NY },
  { label: 'North Carolina', value: StateOfOperation.NC },
  { label: 'North Dakota', value: StateOfOperation.ND },
  { label: 'Ohio', value: StateOfOperation.OH },
  { label: 'Oklahoma', value: StateOfOperation.OK },
  { label: 'Oregon', value: StateOfOperation.OR },
  { label: 'Pennsylvania', value: StateOfOperation.PA },
  { label: 'Rhode Island', value: StateOfOperation.RI },
  { label: 'South Carolina', value: StateOfOperation.SC },
  { label: 'South Dakota', value: StateOfOperation.SD },
  { label: 'Tennessee', value: StateOfOperation.TN },
  { label: 'Texas', value: StateOfOperation.TX },
  { label: 'Utah', value: StateOfOperation.UT },
  { label: 'Vermont', value: StateOfOperation.VT },
  { label: 'Virginia', value: StateOfOperation.VA },
  { label: 'Washington', value: StateOfOperation.WA },
  { label: 'West Virginia', value: StateOfOperation.WV },
  { label: 'Wisconsin', value: StateOfOperation.WI },
  { label: 'Wyoming', value: StateOfOperation.WY },
];

export const StateMap: { [state: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};
