import { gql } from '@apollo/client';
import { CalendarDate } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_ALL_CALENDAR_DATES = gql`
  query calendarDatesByDateWithSignings($filter: CalendarEventsFilter!) {
    calendarDatesByDate(filter: $filter) {
      date
      signingCalendarEvents {
        id
        description
        milestoneType
        text
        time
        transaction {
          id
          fileNum
          type
        }
      }
      transactionCalendarEvents {
        id
        description
        stage
        text
        time
        transaction {
          id
          fileNum
          type
          status
          marketId
          milestones {
            type
            status
          }
        }
      }
    }
  }
`;

export interface GetCalendarDates {
  calendarDatesByDate: CalendarDate[];
}
