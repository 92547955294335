import { Document } from '@endpoint/opsware-bff-graphql-schema';
import { PSA_DOCUMENT_TYPE } from 'consts/psaDocumentType';
import { PsaDocumentData } from 'consts/transacionBasicInfo';

export function getPsaDocData(documents: Document[]): PsaDocumentData {
  if (!documents.length) {
    return {
      id: '',
      storageUrl: '',
    };
  }

  const targetDoc = documents
    .filter((doc) => doc.type?.toLowerCase() === PSA_DOCUMENT_TYPE.toLowerCase() || doc.type?.toLowerCase() === 'psa')
    .sort((first: Document, second: Document) => second.createdAt - first.createdAt)[0];

  return {
    id: targetDoc?.id || '',
    storageUrl: targetDoc?.storage?.url || '',
  };
}
