import React, { FC, Fragment } from 'react';
import { add, formatISO, startOfDay } from 'date-fns';
import { Box, Flex, Icon, Text, Divider } from '@endpoint/blockparty';
import {
  CalendarDate,
  SigningCalendarEvent,
  TransactionCalendarEvent,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { mapStatusText } from 'helpers/statusHelper';
import { transactionStageToIcon } from 'consts/calendar';
import { dateFormatter } from 'helpers/datetimeHelper';
import { useCalendarContext } from 'hooks/useCalendarContext';
import { getCalendarEventRowStage, getTransactionStage } from 'helpers/calendar';

import { CalendarEventRow } from './CalendarEventRow';

export interface DayCardProps {
  calendarDate: CalendarDate;
}

export function TimeMarker() {
  return (
    <Flex alignItems="center" mt="-8px" style={{ position: 'sticky' }}>
      <Icon color="watermelon500" name="CircleSmall" size="medium" />
      <Divider bg="watermelon500" ml="-4px" width="100%" />
    </Flex>
  );
}

export const getDateMarkerIndex = (signingEvents: SigningCalendarEvent[]): number => {
  const endingTimes = signingEvents.map((event: SigningCalendarEvent) => {
    return add(new Date(event.time), { minutes: 30 });
  });

  const currentTime = new Date();
  let indexOfTimeMarker: number = 0;
  let i: number;

  for (i = 0; i < endingTimes.length; i++) {
    indexOfTimeMarker = i;

    if (currentTime < endingTimes[i]) {
      break;
    }
  }

  return indexOfTimeMarker;
};

export const DayCard: FC<DayCardProps> = ({ calendarDate }) => {
  // for this card, provide the index of the time marker
  const indexOfTimeMarker: number = calendarDate.signingCalendarEvents
    ? getDateMarkerIndex(calendarDate.signingCalendarEvents)
    : 0;
  const eventDateRaw: Date = new Date(calendarDate.date);
  const dateISO = formatISO(eventDateRaw);

  const today: Date = startOfDay(new Date());
  const todayISO: string = formatISO(today);

  const isToday: boolean = dateISO === todayISO;

  const signingsTotal = calendarDate.signingCalendarEvents ? calendarDate.signingCalendarEvents.length : 0;

  const hasClosings = calendarDate.transactionCalendarEvents && calendarDate.transactionCalendarEvents.length > 0;

  const hasSigningEvents = calendarDate.signingCalendarEvents && calendarDate.signingCalendarEvents.length > 0;

  const { tableFundingMarkets } = useCalendarContext();

  if (!hasClosings && !hasSigningEvents) {
    return null;
  }

  return (
    <Flex data-test-id={`day-list-item-${calendarDate.date}`} flexDirection="column">
      <Flex
        align-self="flex-start"
        backgroundColor="slate0"
        justifyContent="space-between"
        position="sticky"
        px="space60"
        py="space30"
        top={0}
      >
        <Flex>
          <Text data-test-id={`date-header-${calendarDate.date}`} fontWeight="semi">
            {`${dateFormatter(dateISO, 'E, MMM d')}`}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Text color="carbon500" data-test-id={`date-header-signings-count-${calendarDate.date}`}>
            {`${signingsTotal} signings`}{' '}
          </Text>
          <Icon color="carbon500" name="Bullet" size="small" />
          <Text color="carbon500" data-test-id={`date-header-closings-count-${calendarDate.date}`}>
            {`${calendarDate.transactionCalendarEvents.length} closings`}{' '}
          </Text>
        </Flex>
      </Flex>
      <Box>
        {calendarDate.transactionCalendarEvents && calendarDate.transactionCalendarEvents.length > 0 && (
          <Box data-test-id="closing-events-list" py="space30">
            {calendarDate.transactionCalendarEvents.map((transactionEvent: TransactionCalendarEvent) => {
              const { transaction } = transactionEvent;
              const eventStage = getCalendarEventRowStage(true, transactionEvent.stage, transactionEvent.transaction);
              const eventType = getTransactionStage(transaction, tableFundingMarkets, transactionEvent.stage);

              const iconName: string = eventStage.includes('Borrower Signing Scheduled')
                ? 'PinPerson'
                : mapStatusText(eventType, transactionStageToIcon);
              const iconColor = iconName === transactionStageToIcon.TRANSACTION_DISBURSED ? 'mist500' : 'blue500';

              return (
                <CalendarEventRow
                  key={transactionEvent.id}
                  description={transactionEvent.description}
                  eventType={eventType}
                  iconColor={iconColor}
                  iconName={iconName}
                  isTransactionEvent
                  time={transactionEvent.time}
                  transaction={transactionEvent.transaction}
                />
              );
            })}
          </Box>
        )}
        {calendarDate.signingCalendarEvents && calendarDate.signingCalendarEvents.length > 0 && <Divider />}
        {calendarDate.signingCalendarEvents && calendarDate.signingCalendarEvents.length > 0 && (
          <Box data-test-id="signing-events-list" py="space30">
            {calendarDate.signingCalendarEvents.map((signingEvent: SigningCalendarEvent, index: number) => {
              const isLastCompleted = index === indexOfTimeMarker;

              return (
                <Fragment key={signingEvent.id}>
                  <CalendarEventRow
                    description={signingEvent.description}
                    eventType={signingEvent.milestoneType}
                    iconColor="marigold500"
                    iconName="CircleOutline"
                    iconSize="small"
                    isTransactionEvent={false}
                    time={signingEvent.time}
                    transaction={signingEvent.transaction}
                  />
                  {isToday && isLastCompleted && <TimeMarker />}
                </Fragment>
              );
            })}
          </Box>
        )}
      </Box>
    </Flex>
  );
};

DayCard.displayName = 'DayCard';
Divider.displayName = 'Divider';
Icon.displayName = 'Icon';
TimeMarker.displayName = 'TimeMarker';
