import React, { FC } from 'react';
import { Box, Tab, TabList, TabPanel, Tabs as TabsContainer, Flex } from '@endpoint/blockparty';
import { AllContactTypes } from 'consts/contactDetails';
import { EditContactDetailsProvider } from 'hooks/useEditContactDetailsProvider';

import { DetailsTab } from './DetailsTab';

interface TabsProps {
  contact: AllContactTypes;
}

export const Tabs: FC<TabsProps> = ({ contact }): React.ReactElement => {
  return (
    <Box
      backgroundColor="white"
      borderRadius="radiusDefault"
      boxShadow="medium"
      data-test-id="contact-details-tabs"
      mb="space60"
      pb="space60"
      pt="space40"
      px="space60"
      width="100%"
    >
      <TabsContainer>
        <TabList aria-label="contact-tabs-list" mb="space50">
          <Flex>
            <Tab>Details</Tab>
          </Flex>
        </TabList>
        <TabPanel>
          <EditContactDetailsProvider>
            <DetailsTab contact={contact} />
          </EditContactDetailsProvider>
        </TabPanel>
      </TabsContainer>
    </Box>
  );
};
