import React, { FC, useState, useContext } from 'react';
import { Link as ReachLink, RouteComponentProps } from '@reach/router';
import { Document, Task } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { Preview, Flex, Modal, ModalContent, Box, Text, Divider, Link, Icon } from '@endpoint/blockparty';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import { AccordionSection } from 'components/AccordionSection';
import { dateFormatter } from 'helpers/datetimeHelper';
import { formatLikeTitle } from 'helpers/formatText';
import { imageIcon, getTask } from 'helpers/documents';
import { DocumentsContext } from 'routes/Transaction/Documents';

import { deriveMimeTypeText } from '../../DocumentsTable/helpers';

interface DocumentDetailsProps extends RouteComponentProps {
  document: Document;
}

export const DocumentDetailsSection: FC<DocumentDetailsProps> = ({ document }) => {
  const [previewPdf, setPreviewPdf] = useState(false);
  const { fileNumber, milestones } = useContext(DocumentsContext);
  const task: Task | undefined = getTask(milestones, document?.todo?.id);
  const addedBy = document.uploadedBy ? `${document.uploadedBy} on` : '';
  const mimeType = deriveMimeTypeText(document.storage?.mimeType || '');
  const iconInfo = imageIcon(mimeType);

  return (
    <>
      <AccordionSection dataTestId="document-details-section" hasDivider isOpen title="Document Details">
        <Box>
          <Flex
            alignItems="center"
            bg="slate0"
            cursor="pointer"
            data-test-id="document-trigger"
            height="264px"
            justifyContent="center"
            onClick={() => {
              setPreviewPdf(true);
            }}
          >
            <Icon color={iconInfo.color} name={iconInfo.name as IconNames} size="large" />
          </Flex>

          <Box pt="space60">
            <Text as="p" color="passive" size="fontSize10">
              Added by
            </Text>
            <Text as="p" data-test-id="details-added-by" pb="space40" pt="space20">
              {addedBy} {dateFormatter(document.createdAt, 'MMM dd, yyyy h:mm a')}
            </Text>
            <Divider />
            <Text as="p" color="passive" pt="space40" size="fontSize10">
              Document Type
            </Text>
            <Text as="p" pt="space20">
              {document.type ? formatLikeTitle(document.type) : '-'}
            </Text>
            {document.todo && task && (
              <>
                <Divider />

                <Text as="p" color="passive" pt="space40" size="fontSize10">
                  Associated To-Do
                </Text>
                <Box pt="space20">
                  <Link
                    as={ReachLink}
                    color="blue500"
                    cursor="pointer"
                    data-test-id="details-associated-to-do"
                    to={`/transaction/${fileNumber}/tasks/${task.id}`}
                  >
                    {task.name}
                  </Link>
                </Box>
              </>
            )}
            {document.customerNote && (
              <Box>
                <Text as="p" color="passive" pb="space20" size="fontSize10">
                  Note Added by Customer
                </Text>
                <Text>{document?.customerNote}</Text>
              </Box>
            )}
          </Box>
        </Box>
      </AccordionSection>
      <Modal
        data-test-id={`document-id-${document.name}`}
        id={`document-id-${document.name}`}
        isOpen={previewPdf}
        onClose={() => setPreviewPdf(false)}
      >
        <ModalContent height="100vh" maxWidth="100vh">
          {/*
            // @ts-ignore - to be investigated in BP */}
          <Preview overflow="auto" src={document!.storage?.url} type={mimeType} />
        </ModalContent>
      </Modal>
    </>
  );
};

DocumentDetailsSection.displayName = 'DocumentDetailsSection';
Preview.displayName = 'Preview';
