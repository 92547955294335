import React, { FC } from 'react';
import { Avatar, Box, Card, Divider, Icon, Link, Text, Center } from '@endpoint/blockparty';
import { TransactionParticipant, TransactionRole } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { RouteComponentProps } from '@reach/router';
import { removeNonNumeric, formatPhoneNumber } from 'helpers/formatPhone';
import { giveFirstWord, formatRolesLikeTitle } from 'helpers/formatText';
import { createFullstoryUrl } from 'helpers/utils/fullstory';
import { AllContactTypes } from 'consts/contactDetails';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContactActionMenu } from '../../ContactActionMenu';

export interface ContactInfoProps extends RouteComponentProps {
  participant?: TransactionParticipant;
}

export interface SignUpStatusInterface {
  isDeactivated: boolean;
  isOnboarded: boolean;
}

export const SignUpStatus: FC<SignUpStatusInterface> = ({ isDeactivated, isOnboarded }) => {
  if (isDeactivated) {
    return (
      <Box alignItems="center" display="flex">
        <Icon backgroundColor="watermelon500" borderRadius="radiusFull" color="white" name="Minus" />
        <Text ml="space30">Deactivated</Text>
      </Box>
    );
  }

  if (isOnboarded) {
    return (
      <Box alignItems="center" display="flex">
        <Icon backgroundColor="aloe500" borderRadius="radiusFull" color="white" name="CheckBasic" />
        <Text ml="space30">Joined</Text>
      </Box>
    );
  }

  return (
    <Box alignItems="center" display="flex">
      <Icon backgroundColor="marigold500" borderRadius="radiusFull" color="white" name="Minus" />
      <Text ml="space30">Not Onboarded</Text>
    </Box>
  );
};

export const ContactInfo: FC<ContactInfoProps> = ({ participant }) => {
  const { canViewClosingSpecialist } = useFlags();

  if (!participant) {
    return (
      <Box data-test-id="contact-info-unmatched" mr="space60" width="100%">
        <Card display="flex" mb="space60" p="space60" variant="shadow">
          <Text as="p">No matched participant</Text>
        </Card>
      </Box>
    );
  }

  let { roles } = participant;
  const { isDirectingContact } = participant;

  if (!canViewClosingSpecialist) {
    roles = roles.filter((role) => role !== TransactionRole.CLOSING_SPECIALIST);
  }

  const contact = participant.contact as AllContactTypes;
  let firstName;
  let middleName;
  let lastName;
  let entityName;
  let isEntity = false;

  const { email, phone, id, isOnboarded, isDeactivated } = contact;

  if ('entityName' in contact) {
    ({ entityName } = contact);
    isEntity = true;
  } else if ('firstName' in contact) {
    ({ firstName, middleName, lastName } = contact);
  }

  const fullName = isEntity ? entityName : `${firstName} ${middleName || ''} ${lastName}`.replace(/  +/g, ' ');
  const avatarName = isEntity
    ? giveFirstWord(entityName)
    : `${giveFirstWord(firstName || '')} ${giveFirstWord(lastName || '')}`;

  return (
    <Box data-test-id="contact-info-card" mr="space60" width="100%">
      <Card display="flex" flexDirection="column" mb="space50" p="space60" variant="shadow">
        <Box display="flex">
          <Avatar mr="space50" name={avatarName} size={40} />
          <Box width="100%">
            <Box display="flex" justifyContent="space-between">
              <Text data-test-id="contact-info-heading" fontWeight="heading" size="fontSize40">
                {fullName}
              </Text>
              <Center>
                <Text as="p" pr="space20">
                  ID: {participant.id}
                </Text>
                <ContactActionMenu transactionParticipant={participant} />
              </Center>
            </Box>
            <Box display="flex">
              {isDirectingContact && (
                <Box
                  alignItems="center"
                  backgroundColor="marigold100"
                  border="1px solid transparent"
                  borderRadius="10px"
                  data-test-id="contact-director-tag"
                  display="flex"
                  height="20px"
                  justifyContent="center"
                  mr="space30"
                  px="space40"
                >
                  <Text color="clay900" size="fontSize10">
                    Directing
                  </Text>
                </Box>
              )}
              <Text as="p" data-test-id="contact-info-role">
                {formatRolesLikeTitle(roles.join(', '))}
              </Text>
            </Box>
          </Box>
        </Box>
        <Divider my="space40" />
        <Box display="flex">
          <Box data-test-id="contact-info-signup-status" display="flex" flexDirection="column" mr="space60">
            <Text color="carbon500" size="fontSize10">
              Sign Up Status
            </Text>
            <SignUpStatus isDeactivated={!!isDeactivated} isOnboarded={!!isOnboarded} />
          </Box>
          <Box data-test-id="contact-info-fullstory" display="flex" flexDirection="column" mr="space60">
            <Text color="carbon500" size="fontSize10">
              Recordings
            </Text>
            {email ? (
              <Link color="blue500" href={createFullstoryUrl(email)} isExternal>
                Fullstory
              </Link>
            ) : (
              '-'
            )}
          </Box>
          <Box data-test-id="contact-info-phone" display="flex" flexDirection="column" mr="space60">
            <Text color="carbon500" size="fontSize10">
              Phone
            </Text>
            {phone ? (
              <Link color="blue500" href={`tel:+1${removeNonNumeric(phone)}`}>
                {formatPhoneNumber(phone)}
              </Link>
            ) : (
              '-'
            )}
          </Box>
          <Box data-test-id="contact-info-email" display="flex" flexDirection="column">
            <Text color="carbon500" size="fontSize10">
              Email
            </Text>
            <Link color="blue500" href={`mailto:${email}`}>
              {email || '-'}
            </Link>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
