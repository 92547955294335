import React, { FC } from 'react';
import { Flex, Text, Icon, Box, IconButton, Divider } from '@endpoint/blockparty';
import { navigate } from '@reach/router';
import { useQuery } from '@apollo/client';
import { sortBy, flatten } from 'lodash';
import { ErrorMessage } from 'components/ErrorMessage';
import { buildQueryInput } from 'helpers/buildQueryInput';

import { GET_SORTED_TASKS, GetSortedTasksResult } from './queries';

interface RightPanelProps {
  transactionIdentifier: string;
  taskId: string;
}

export const RightPanelHeader: FC<RightPanelProps> = ({ transactionIdentifier, taskId }) => {
  const queryInput = buildQueryInput(transactionIdentifier);
  const { data, error } = useQuery<GetSortedTasksResult>(GET_SORTED_TASKS, {
    variables: {
      where: queryInput,
    },
  });

  const sortedTasksByMilestones = sortBy(data?.transaction.milestones, (milestone) => milestone.position).map(
    (milestone) => milestone.tasks,
  );
  const sortedTasks = flatten(sortedTasksByMilestones).map((task) => task.id);

  const sortedTaskPosition = sortedTasks?.indexOf(taskId) || 0;

  const navigateToTask = (taskIndex: number): void => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(`/transaction/${transactionIdentifier}/tasks/${sortedTasks[taskIndex % sortedTasks.length]}`);
  };

  const disableBackButton = sortedTaskPosition === 0;
  const disableNextButton = sortedTaskPosition === sortedTasks.length - 1;

  return (
    <Box data-test-id="right-panel-header">
      <Flex
        alignItems="center"
        justifyContent={data?.transaction && sortedTasks.length === 1 ? 'flex-end' : null}
        px="space60"
        py="space50"
      >
        {error && <ErrorMessage />}
        {data?.transaction && sortedTasks.length > 1 && (
          <Flex alignItems="center" flexGrow={1}>
            <IconButton
              color="carbon500"
              data-test-id="navigation-left"
              isDisabled={disableBackButton}
              label="back"
              name="ArrowChevronLeft"
              variant="unstyled"
              variantColor="carbon"
              onClick={(): void => {
                navigateToTask(sortedTaskPosition - 1);
              }}
            />
            <IconButton
              color="carbon500"
              data-test-id="navigation-right"
              isDisabled={disableNextButton}
              label="forward"
              ml="space50"
              name="ArrowChevronRight"
              variant="unstyled"
              variantColor="carbon"
              onClick={(): void => {
                navigateToTask(sortedTaskPosition + 1);
              }}
            />
            <Text color="carbon500" data-test-id="navigation-location" ml="space50">
              {sortedTasks && `${sortedTaskPosition + 1} of ${sortedTasks.length}`}
            </Text>
          </Flex>
        )}
        <IconButton
          color="carbon500"
          data-test-id="navigation-close"
          label="close"
          name="Close"
          variant="unstyled"
          variantColor="carbon"
          onClick={(): void => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate(`/transaction/${transactionIdentifier}/tasks`);
          }}
        />
      </Flex>
      <Divider />
    </Box>
  );
};

RightPanelHeader.displayName = 'RightPanelHeader';
Icon.displayName = 'Icon';
