import React, { FC } from 'react';
import { Button, Modal, ModalContent, ModalSection, ModalFooter, Text, useDisclosure } from '@endpoint/blockparty';

interface TogglePinnedProps {
  isPinned: boolean;
  loading: boolean;
  noteId: string;
  onTogglePinned: (isPinned: boolean) => void;
}

export const TogglePinnedButton: FC<TogglePinnedProps> = ({ isPinned, loading, noteId, onTogglePinned }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleToggleClick = () => {
    if (isPinned) {
      onOpen();
    } else onTogglePinned(true);
  };

  return (
    <>
      <Button
        data-test-id={`pin-note-button-${noteId}`}
        isDisabled={loading}
        size="none"
        variant="link"
        onClick={handleToggleClick}
      >
        <Text color="carbon500" size="fontSize10">
          {isPinned ? 'Unpin' : 'Pin Note'}
        </Text>
      </Button>
      <Modal data-test-id="pinned-note-confirmation" id="toggle-pinned-note-modal" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalSection>
            <Text as="h2" fontWeight="semi" size="fontSize30">
              Are you sure you want to unpin this note?
            </Text>
          </ModalSection>
          <ModalFooter>
            <Button data-test-id="cancel-unpin-button" mr="space30" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              data-test-id="modal-unpin-button"
              onClick={(): void => {
                onTogglePinned(false);
                onClose();
              }}
            >
              Yes, unpin
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
