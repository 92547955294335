import { TransactionDates, TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { formatCurrencyStringToNumber } from 'helpers/formatText';
import { InputFees, TransactionUpdateInputDto } from 'helpers/prelimConversion/updateTransactionMutation';
import { DocumentReviewType } from 'routes/DocumentReview/contexts/DocumentReviewContext';
import { isValidDate } from 'helpers/datetimeHelper';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';

export function buildOpenEscrowPayload(data: OpenOrderDrawerForm) {
  const sanitizedTerms: TransactionUpdateInputDto['terms'] = {
    productType: data.terms.productType,
  };

  const isRefinance = sanitizedTerms.productType === TransactionType.REFINANCE;
  const isSaleMortage = sanitizedTerms.productType === TransactionType.SALE_MORTGAGE;
  const isEquity = sanitizedTerms.productType === TransactionType.EQUITY;

  const saveLoanAmount = isRefinance || isSaleMortage || isEquity;

  if (data.terms.salePrice) {
    sanitizedTerms.salePrice = formatCurrencyStringToNumber(data.terms.salePrice);
  }

  if (data.terms.emdAmount) {
    sanitizedTerms.emdAmount = formatCurrencyStringToNumber(data.terms.emdAmount);
  }

  if (data.terms.loanNumber) {
    sanitizedTerms.loanNumber = data.terms.loanNumber;
  }

  if (saveLoanAmount) {
    if (data.terms.loanAmount) {
      sanitizedTerms.loanAmount = formatCurrencyStringToNumber(data.terms.loanAmount);
    }

    sanitizedTerms.productType = data.terms.productType;
  }

  const fees: InputFees = {};

  if (data.fees.sellerCreditsBuyer) {
    fees.sellerCreditsBuyer = formatCurrencyStringToNumber(data.fees.sellerCreditsBuyer);
  }

  if (data.fees.titleFees) {
    fees.titleFees = formatCurrencyStringToNumber(data.fees.titleFees);
  }

  const dates: TransactionDates = {};

  if (data.dates.closingDate) {
    dates.estimatedClosing = isValidDate(data.dates.closingDate) ? data.dates.closingDate : '';
  }

  if (data.dates.emdDueDate) {
    dates.emdContractDate = isValidDate(data.dates.emdDueDate) ? data.dates.emdDueDate : '';
  }

  if (data.dates.contractExecutedDate) {
    dates.contractExecutedDate = isValidDate(data.dates.contractExecutedDate) ? data.dates.contractExecutedDate : '';
  }

  const payload = {
    address: data.property.address,
    parcelNumber: data.property.parcelNumber,
    fees,
    terms: sanitizedTerms,
    dates,
  } as TransactionUpdateInputDto;

  if (!data.reviewType || data.reviewType !== DocumentReviewType.ORDER_REVIEW) {
    payload.purchaseAgreementDocumentId = data.documentId;
  }

  return payload;
}
