import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  Alert,
  AlertContent,
  AlertIcon,
  AlertTitle,
  Card,
  Flex,
  Heading,
  LayoutContent,
  LayoutSubContent,
} from '@endpoint/blockparty';
import { useQuery } from '@apollo/client';
import { SkeletonTable } from 'components/SkeletonTable';
import { ErrorMessage } from 'components/ErrorMessage';
import { trackPage, trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { Page } from 'consts/segmentProtocols';
import { updateBreadcrumbs } from 'helpers/updateBreadcrumbs';
import { buildQueryInput } from 'helpers/buildQueryInput';
import { NewContactProvider } from 'hooks/useContactProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ContactType, TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';

import { ContactsTable } from './ContactsTable';
import { GetContactsType, GET_CONTACTS } from './queries';
import { AddNewContact } from './AddNewContact';

type ContactsTypes = RouteComponentProps;

interface ContactsProps extends ContactsTypes {
  transactionIdentifier?: string;
}

export const TransactionContacts: FC<ContactsProps> = ({ transactionIdentifier }) => {
  const { canViewSettlementCompany, isQcOrderFlowEnabled } = useFlags();

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Contacts', path: '' }]);
  }, []);
  const queryInput = buildQueryInput(transactionIdentifier);
  const { data, loading, error } = useQuery<GetContactsType>(GET_CONTACTS, {
    variables: {
      where: queryInput,
    },
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const participants = React.useMemo(() => {
    const unfilteredParticipants = data?.transaction.participants || [];

    if (canViewSettlementCompany) {
      return unfilteredParticipants;
    }

    return unfilteredParticipants.filter(
      (participant) => participant?.contact?.contactType !== ContactType.SETTLEMENT_COMPANY,
    );
  }, [data?.transaction.participants, canViewSettlementCompany]);

  const hasNoParticipants = !participants.length;
  const address = data?.transaction.property.address.street1 || '';
  const isRefi = data?.transaction.type === TransactionType.REFINANCE;
  const listingAgentNotRequired = data?.transaction.requireListingAgent === false;
  const sellingAgentNotRequired = data?.transaction.requireSellingAgent === false;
  const bothAgentsNotRequired = listingAgentNotRequired && sellingAgentNotRequired;
  const agentNotRequired = listingAgentNotRequired || sellingAgentNotRequired;

  const agentMessage = `There is no ${listingAgentNotRequired ? 'Listing' : ''}${bothAgentsNotRequired ? '/' : ''}${
    sellingAgentNotRequired ? 'Selling' : ''
  } Agent on this transaction`;

  useEffect(() => {
    trackPage<Page>('Contact List');
  }, []);

  const handleAddContactClick = () => {
    setIsDrawerOpen(true);
    trackAnalytics('Add Contact Clicked', {});
  };

  return (
    <LayoutContent>
      <LayoutSubContent p="space60">
        {data?.transaction.type && (
          <>
            <Flex justifyContent="flex-end" pb="space60">
              <ButtonWriteRequired isDisabled={loading} variant="outline" onClick={handleAddContactClick}>
                Add Contact
              </ButtonWriteRequired>
              <NewContactProvider>
                <AddNewContact
                  isDrawerOpen={isDrawerOpen}
                  propertyAddress={address}
                  toggleDrawer={setIsDrawerOpen}
                  transactionId={data?.transaction.id}
                  transactionIdentifier={queryInput}
                  transactionParticipants={participants}
                  transactionType={data.transaction.type}
                />
              </NewContactProvider>
            </Flex>
            <Flex>
              {isQcOrderFlowEnabled && !isRefi && agentNotRequired && (
                <Alert
                  data-test-id="agent-not-required-alert-banner"
                  marginBottom="space60"
                  marginTop="spac20"
                  status="info"
                  width="100%"
                >
                  <AlertIcon />
                  <AlertContent>
                    <AlertTitle as="p">{agentMessage}</AlertTitle>
                  </AlertContent>
                </Alert>
              )}
            </Flex>
          </>
        )}
        {loading && (
          <Card variant="shadow">
            <SkeletonTable />
          </Card>
        )}
        {error && (
          <Card variant="shadow">
            <ErrorMessage />
          </Card>
        )}
        {data?.transaction && !hasNoParticipants && (
          <Card variant="shadow">
            <ContactsTable fileNumber={data.transaction.fileNum} participants={participants} />
          </Card>
        )}
        {data?.transaction && hasNoParticipants && (
          <Flex justifyContent="center">
            <Heading as="h5" data-test-id="heading-no-contacts" mt="224px" size="fontSize40">
              No contacts have been added yet.
            </Heading>
          </Flex>
        )}
      </LayoutSubContent>
    </LayoutContent>
  );
};

TransactionContacts.displayName = 'TransactionContacts';
