import React, { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  Contact,
  AgentContact,
  EntityContact,
  IndividualContact,
  LenderContact,
} from '@endpoint/opsware-bff-graphql-schema';
import { Box, DrawerSection, Heading, RadioGroup, Text } from '@endpoint/blockparty';
import { NewContactSchema } from 'consts/createNewContact';
import { ActionKind, useNewContactProvider } from 'hooks/useContactProvider';
import { mapFoundContactsToNewContactSchema, getContactErrorMessageText } from 'helpers/contacts';
import { ErrorMessage } from 'components/ErrorMessage';

import { ContactDetailRadioCard } from './ContactDetailRadioCard';

interface SelectDuplicateOrNewContactProps {
  isDuplicateAndNewCheckStep: boolean;
  options: Contact[] | AgentContact[] | EntityContact[] | IndividualContact[] | LenderContact[];
}

export const SelectDuplicateOrNewContact: FC<SelectDuplicateOrNewContactProps> = ({
  isDuplicateAndNewCheckStep,
  options,
}) => {
  const { dispatch, state } = useNewContactProvider();
  const { values } = useFormikContext<NewContactSchema>();
  const [selected, setSelected] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState(Boolean(state.contactGQLError));
  const errorMessageText = state.contactGQLError ? getContactErrorMessageText(state.contactGQLError) : null;

  const handleRadioChange = (option: NewContactSchema, isNewContact: boolean) => {
    setSelected(option.id);
    dispatch({ type: ActionKind.SET_CONTACT, payload: { ...state, selectedContactData: option } });
    dispatch({ type: ActionKind.SET_IS_NEW_CONTACT, payload: { ...state, isNewContact } });
    dispatch({
      type: ActionKind.SET_IS_DUPLICATE_OR_NEW_CONTACT_SELECTED,
      payload: { ...state, isDuplicateOrNewContactSelected: true },
    });
  };

  const mappedOptions: NewContactSchema[] = mapFoundContactsToNewContactSchema(options, values.roles);
  const isEmailInReturn: boolean = options.some((option) => option.email === values.email);

  return (
    <>
      <DrawerSection pb="space40">
        <Heading as="h3" pb="space40" size="fontSize50">
          {isDuplicateAndNewCheckStep ? `${options.length} potential duplicates found` : 'View duplicates'}
        </Heading>
        <Text as="p" size="fontSize20">
          Looks like this contact might be a duplicate. Use an existing record instead?
        </Text>
      </DrawerSection>
      {showErrorMessage ? (
        <DrawerSection>
          <ErrorMessage
            closeAction={() => setShowErrorMessage(false)}
            description={errorMessageText?.description}
            title={errorMessageText?.label}
          />
        </DrawerSection>
      ) : null}
      <DrawerSection>
        <RadioGroup arial-label="contact-choices" defaultValue={selected} name="contact-choices">
          {isDuplicateAndNewCheckStep && !isEmailInReturn && (
            <Box pb="space50">
              <Text as="p" fontWeight="semi" pb="space40">
                Create New Entry
              </Text>
              <ContactDetailRadioCard
                data={values}
                handleChange={() => handleRadioChange(values, true)}
                isSelected={selected === values.id}
              />
            </Box>
          )}
          <Box>
            <Text as="p" fontWeight="semi" pb="space40">
              Use Existing Profile
            </Text>
            {mappedOptions.length > 0 &&
              mappedOptions.map((option: NewContactSchema) => {
                return (
                  <ContactDetailRadioCard
                    key={option.id}
                    data={option}
                    handleChange={() => {
                      handleRadioChange(option, false);
                    }}
                    isSelected={selected === option.id}
                  />
                );
              })}
          </Box>
        </RadioGroup>
      </DrawerSection>
    </>
  );
};
