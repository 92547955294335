import React, { FC } from 'react';
import { Alert, Button, AlertContent, AlertDescription, AlertTitle } from '@endpoint/blockparty';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';

interface ConvertToEscrowPromptProps {
  setDrawerIsOpen: (value: boolean) => void;
}

export const ConvertToEscrowPrompt: FC<ConvertToEscrowPromptProps> = ({ setDrawerIsOpen }) => {
  return (
    <Alert mb="space60" status="info">
      <AlertContent>
        <AlertTitle>Open Escrow</AlertTitle>
        <AlertDescription>Upgrade this file by uploading a purchase agreement</AlertDescription>
      </AlertContent>
      <ButtonWriteRequired
        data-test-id="convert-to-escrow-open-escrow-button"
        variant="outline"
        variantColor="carbon"
        onClick={() => setDrawerIsOpen(true)}
      >
        Open Escrow
      </ButtonWriteRequired>
    </Alert>
  );
};
