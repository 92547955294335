import { gql } from '@apollo/client';
import { TransactionNote } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const TOGGLE_INTERNAL_PINNED_NOTE = gql`
  mutation ModifyTransactionNote($data: ModifyTransactionNoteInput!) {
    modifyTransactionNote(data: $data) {
      id
      isPinned
    }
  }
`;

export const TOGGLE_PINNED_NOTE = gql`
  mutation ModifyTransactionNote($where: BasicIdWhereInput!, $data: UpdateNoteInput!) {
    updateNote(where: $where, data: $data) {
      id
      isPinned
    }
  }
`;

export const REMOVE_INTERNAL_NOTE = gql`
  mutation DeleteTransactionNote($id: ID!) {
    deleteTransactionNote(id: $id) {
      id
    }
  }
`;

export const REMOVE_NOTE = gql`
  mutation DeleteTransactionNote($where: BasicIdWhereInput!) {
    deleteNote(where: $where) {
      id
    }
  }
`;

export interface ToggleInternalPinnedNote {
  modifyTransactionNote: TransactionNote;
}

export interface RemoveInternalNote {
  deleteTransactionNote: TransactionNote;
}
