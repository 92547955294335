import { QualityControlStatus } from '@endpoint/opsware-bff-graphql-schema';

export const updateQcStatusTransactionPayload = (id: string, qualityControlStatus: QualityControlStatus) => {
  return {
    variables: {
      where: {
        id,
      },
      updateTransaction: {
        qualityControlStatus,
      },
    },
  };
};
