import { cloneDeep } from 'lodash';
import React, { FC, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { Box, Card, Checkbox, Flex, Heading, Text } from '@endpoint/blockparty';
import { Transaction, TransactionSearchResult, QualityControlStatus } from '@endpoint/opsware-bff-graphql-schema';
import useMarketsAndOrganizations from 'hooks/useMarketsAndOrganizations';
import { ErrorMessage } from 'components/ErrorMessage';
import { OpenTransactionDrawer } from 'components/OpenOrderDrawer';
import { SearchInputForm } from 'components/SearchBar';
import { SkeletonTable } from 'components/SkeletonTable';
import { MarketSelect } from 'components/FilterMarketSelect';
import { OrganizationSelect } from 'components/FilterOrganizationSelect';
import { TableItemSearched, TrackingEvent } from 'consts/segmentProtocols';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { GET_TRANSACTIONS, TransactionsData } from 'routes/TransactionsList/queries';
import { TransactionsListTable } from 'routes/TransactionsList/TransactionsListContent/TransactionsListTable';
import { StatusSelect } from 'components/FilterStatusSelect';
import { OrganizationType } from 'consts/organizationConsts';
import { useQueryParams } from 'helpers/utils/queryParams/';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';

export interface TransactionListFilters {
  marketIds: number[];
  search: string[] | string;
  organizationIds: OrganizationType[];
  orderStatuses?: QualityControlStatus[] | [];
  includeTest?: boolean | string;
}

export const TransactionsListContent: FC = () => {
  const { showOrganizationDropdown, showMarketDropdown, hasMultiOrgAccess, organizations } =
    useMarketsAndOrganizations();
  const { currentParams, navigateWithParams } = useQueryParams((params: Partial<TransactionListFilters>) => ({
    search: Array.isArray(params.search)
      ? params.search.map((str: string) => str.trim()).join(', ')
      : params.search ?? '',
    marketIds: [params.marketIds ?? []].flat(1).map(Number),
    organizationIds: [params.organizationIds ?? []].flat(1).map(Number),
    orderStatuses: [params.orderStatuses ?? []].flat(1),
    includeTest: params.includeTest === 'true',
  }));

  const { search, marketIds, organizationIds, orderStatuses, includeTest } = currentParams;
  const { isMarkTransactionAsTestEnabled } = useFlags();
  const client = useApolloClient();
  const [pendingSearchTerm, updatePendingSearchTerm] = useState(search);
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState<number[]>(organizationIds);
  let orgIds = organizations.map((org) => org.id);

  if (hasMultiOrgAccess) {
    orgIds = organizationIds;
  }

  const { data, loading, error } = useQuery<TransactionsData>(GET_TRANSACTIONS, {
    variables: {
      search: {
        query: search.replace(/,\s*/g, ' '),
        filter: {
          displayStatuses: orderStatuses.length ? orderStatuses : null,
          marketIds: marketIds.length ? marketIds : null,
          organizationIds: orgIds?.length ? orgIds : null,
          includeAllOrderStatuses: !orderStatuses.length,
          includeTest,
        },
        itemsPerPage: 50,
        pageIndex: 0,
      },
    },
    errorPolicy: 'all',
    onCompleted: () => {
      trackingData = {
        search: search.replace(/,\s*/g, ' '),
        table: 'Transactions Table',
      };

      trackAnalytics(trackingEvent, trackingData);
    },
  });

  const queriedTransactions: TransactionSearchResult[] = cloneDeep(
    data?.searchTransactions || ([] as TransactionSearchResult[]),
  );

  // most recent transactions first
  const sortedTransactions: TransactionSearchResult[] | null = loading
    ? ([] as TransactionSearchResult[])
    : queriedTransactions.sort((resultA: TransactionSearchResult, resultB: TransactionSearchResult) => {
        const transactionA = new Date(resultA.createdAt).getTime();
        const transactionB = new Date(resultB.createdAt).getTime();

        return transactionB - transactionA;
      }) || ([] as Transaction[]);
  const trackingEvent: TrackingEvent = 'Table Searched';
  let trackingData: TableItemSearched;

  return (
    <>
      <Box m="space60">
        <Heading as="h4" color="carbon900" fontWeight="normal" pb="space60" size="fontSize50">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontWeight="semi" size="fontSize50">
              Transactions
            </Text>
            <Flex alignItems="center">
              {showOrganizationDropdown && (
                <Box mr="space50">
                  <OrganizationSelect
                    defaultValue={organizationIds.map(String)}
                    onChange={(newOrganizationIds) => {
                      navigateWithParams({ organizationIds: newOrganizationIds });
                      setOrganizationId(newOrganizationIds);
                    }}
                  />
                </Box>
              )}
              {showMarketDropdown && (
                <MarketSelect
                  defaultValue={marketIds.map(String)}
                  organizationValue={organizationId}
                  onChange={(newMarketIds) => {
                    navigateWithParams({ marketIds: newMarketIds });
                  }}
                />
              )}
            </Flex>
          </Flex>
        </Heading>
        <Flex alignItems="center" justifyContent="space-between" pb="space60">
          <Flex alignItems="center" minWidth="77%">
            <SearchInputForm
              disabled={loading}
              handleSubmit={(): void => {
                trackAnalytics(trackingEvent, {
                  search: pendingSearchTerm,
                  table: 'Transactions Table',
                });

                navigateWithParams({ search: pendingSearchTerm });
              }}
              placeholder="Search by Property Address or File Number"
              value={pendingSearchTerm}
              onSearchTermChange={(term: string) => {
                updatePendingSearchTerm(term);
              }}
            />
            <Box ml="space50">
              <StatusSelect
                defaultValue={orderStatuses}
                onChange={(newOrderStatuses: QualityControlStatus[]) => {
                  navigateWithParams({ orderStatuses: newOrderStatuses });
                }}
              />
            </Box>
            {isMarkTransactionAsTestEnabled && (
              <Box ml="space50">
                <Checkbox
                  dataTestId="show-tests-checkbox"
                  isChecked={includeTest}
                  value="Test Files"
                  onChange={(event) => {
                    navigateWithParams({ includeTest: event.target.checked });
                  }}
                >
                  Test Files
                </Checkbox>
              </Box>
            )}
          </Flex>
          <Box mr="0" whiteSpace="nowrap">
            <ButtonWriteRequired
              data-test-id="open-new-transaction-button"
              isDisabled={loading}
              size="large"
              variant="outline"
              onClick={() => {
                trackAnalytics('New Order Clicked', {});
                setDrawerIsOpen(true);
              }}
            >
              New Order
            </ButtonWriteRequired>
          </Box>
        </Flex>
        <Card variant="shadow">
          {loading && <SkeletonTable />}
          {error && <ErrorMessage />}
          {data?.searchTransactions && <TransactionsListTable transactions={sortedTransactions} />}
        </Card>
      </Box>

      <OpenTransactionDrawer
        client={client}
        data-test-id="open-new-transaction-drawer"
        isOpen={drawerIsOpen}
        setDrawerIsOpen={() => setDrawerIsOpen(false)}
      />
    </>
  );
};

TransactionsListContent.displayName = 'TransactionsListContent';
