import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { Box, Flex, Icon, InputErrorMessage, InputGroup, InputLabel, Select, Text } from '@endpoint/blockparty';
import 'components/Styles/DocumentUploadWidget/document-upload-widget.scss';
import { Field, useFormikContext } from 'formik';
import { DocumentUpload, DocumentUploadStatus } from 'consts/uploadDocumentsConsts';
import { FileUploadProgress } from 'components/DocumentUploadDrawer/FileUploadProgress';
import { DocumentReviewType, useDocumentReviewContext } from 'routes/DocumentReview/contexts/DocumentReviewContext';
import { PSA_DOCUMENT_TYPE } from 'consts/psaDocumentType';
import { ErrorMessage } from 'components/ErrorMessage';
import { buildMarketOptions } from 'helpers/openOrder/buildMarketOptions';
import { useStateOptions } from 'hooks/useStateOptions';
import useOCRSupport from 'hooks/useOCRSupport';
import { OrganizationType } from 'consts/organizationConsts';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';

export const OpenOrderPsaUploadWidget: FC = () => {
  const { regions } = useOCRSupport();
  const { errors, setFieldValue, values, touched } = useFormikContext<OpenOrderDrawerForm>();
  const { data, uploadUnassignedDocument } = useUploadDocument();
  const uploads = useMemo(() => data?.uploads ?? [], [data?.uploads]);
  const { setDocumentProperties } = useDocumentReviewContext();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (uploads?.length) {
      if (uploads[0]?.status === DocumentUploadStatus.COMPLETED && values.market) {
        setDocumentProperties({
          documentId: uploads[0]!.fileUpload!.documentId as string,
          documentUrl: uploads[0]?.fileUpload!.downloadUrl as string,
          documentReviewType: DocumentReviewType.OPEN_ORDER,
          documentRegion: values.market,
        });
      }
    }
  }, [uploads, setDocumentProperties, values.market]);

  const orgStateOptions = useStateOptions(values.organization as OrganizationType);
  const STATE_OPTIONS = buildMarketOptions(regions, orgStateOptions);

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      uploadUnassignedDocument({
        file: acceptedFiles[0],
        type: PSA_DOCUMENT_TYPE,
        name: acceptedFiles[0].name,
        region: values.market,
      });

      setFieldValue('documentUploaded', true);
    },
    [uploadUnassignedDocument, setFieldValue, values.market],
  );

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const fileRejectionErrorCode = fileRejections[0].errors[0].code;
    const isPDFError = fileRejectionErrorCode.includes('file-invalid-type');
    const tooManyFilesError = fileRejectionErrorCode.includes('too-many-files');

    if (isPDFError) {
      setErrorMessage('The document type must be PDF.');
    }

    if (tooManyFilesError) {
      setErrorMessage('Too many files.');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    disabled: !values.market || !!uploads[0],
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <Flex data-test-id="psa-upload-widget" flexDirection="column" mt="space60">
      <InputGroup
        data-test-id="open-order-drawer-market-selection"
        flex={1}
        groupId="market"
        isInvalid={touched.market && !!errors.market}
      >
        <InputLabel htmlFor="propertyMarket">Market *</InputLabel>
        <Field
          as={Select}
          inputId="propertyMarket"
          isDisabled={uploads.length > 0 && !values.organization}
          isInvalid={touched.market && errors?.market}
          name="market"
          options={[...STATE_OPTIONS]}
          value={STATE_OPTIONS.find((option) => option.value === values.market)}
          onChange={(market: { value: string }) => {
            setFieldValue('market', market.value);
          }}
        />
        {errors?.market && <InputErrorMessage>{touched?.market && errors?.market}</InputErrorMessage>}
      </InputGroup>
      {(!uploads || !uploads.length) && (
        <Box className="opsware-upload-dropzone" data-test-id="upload-box">
          {/*
            // @ts-ignore - react-table issue */}
          <Box
            data-test-id="upload-target"
            {...getRootProps({ className: 'dropzone disabled' })}
            cursor={values.market ? 'pointer' : 'not-allowed'}
            mb="space40"
          >
            {/*
            // @ts-ignore - react-table issue */}
            <Box as="input" {...getInputProps()} />
            {errorMessage ? (
              <ErrorMessage
                closeAction={() => setErrorMessage('')}
                description={errorMessage}
                title="Unable to upload document."
              />
            ) : null}
            <Flex
              alignItems="center"
              border="1px dashed"
              className="opsware-upload-dropdown-wrapper"
              justifyContent="center"
              mt="space50"
              py="20px"
            >
              <Icon color={!values.market ? 'blue200' : 'blue500'} mr="space30" name="Upload" size="large" />
              <Text color={!values.market ? 'carbon500' : 'carbon900'} size="fontSize20">
                Choose a file or drag it here
              </Text>
            </Flex>
          </Box>
        </Box>
      )}
      <Box data-test-id="progress-container">
        {uploads && uploads.length > 0
          ? uploads.map((upload: DocumentUpload) => (
              <Box key={upload.id} pb="space70">
                <FileUploadProgress document={upload} isRemovable />
              </Box>
            ))
          : null}
      </Box>
    </Flex>
  );
};
