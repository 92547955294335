import { ContactType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { contactTypeOptions } from 'consts/createNewContact';

export function getActiveContactTypes(): typeof contactTypeOptions[number][] {
  return [
    { label: 'Agent', value: ContactType.AGENT },
    { label: 'Corporation', value: ContactType.TRANSACTEE_CORPORATION },
    { label: 'Individual', value: ContactType.TRANSACTEE_INDIVIDUAL },
    { label: 'Partnership', value: ContactType.TRANSACTEE_PARTNERSHIP },
    { label: 'Trust', value: ContactType.TRANSACTEE_TRUST },
    { label: 'Transaction Coordinator', value: ContactType.TRANSACTION_COORDINATOR },
  ];
}

export const proptechPartnerOption = {
  label: 'Proptech Partner',
  value: ContactType.PROPTECH_PARTNER,
};

export const settlementCompanyOption = {
  label: 'Settlement Company',
  value: ContactType.SETTLEMENT_COMPANY,
};

export const internalEmployeeOption = {
  label: 'Internal Employee',
  value: ContactType.INTERNAL_EMPLOYEE,
};
