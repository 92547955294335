import React, { FC } from 'react';
import { Flex, Text, Avatar, Icon } from '@endpoint/blockparty';
import {
  Contact,
  IndividualContact,
  EntityContact,
  ContactType,
  AgentContact,
  LenderContact,
} from '@endpoint/opsware-bff-graphql-schema';
import { giveFirstWord } from 'helpers/formatText';
import { contactTypeOptions } from 'consts/createNewContact';
import { formatPhoneNumber } from 'helpers/formatPhone';

import { formatContactTypeLikeTitle } from '../../../../../../../../AddressBook/helpers/formatContactTypeLikeTitle';

interface SearchContactOptionProps {
  optionData: Contact | IndividualContact | EntityContact | AgentContact | LenderContact;
}

export const SearchContactOption: FC<SearchContactOptionProps> = ({ optionData }) => {
  const isEntity =
    optionData.contactType === ContactType.TRANSACTEE_CORPORATION ||
    optionData.contactType === ContactType.TRANSACTEE_PARTNERSHIP ||
    optionData.contactType === ContactType.TRANSACTEE_TRUST ||
    (optionData as EntityContact).entityName;
  const isTransactee =
    optionData.contactType === ContactType.TRANSACTEE_CORPORATION ||
    optionData.contactType === ContactType.TRANSACTEE_PARTNERSHIP ||
    optionData.contactType === ContactType.TRANSACTEE_INDIVIDUAL ||
    optionData.contactType === ContactType.TRANSACTEE_TRUST;
  const name = `${(optionData as IndividualContact).firstName} ${(optionData as IndividualContact).lastName}`;

  const avatarName = `${giveFirstWord((optionData as IndividualContact).firstName)} ${giveFirstWord(
    (optionData as IndividualContact).lastName,
  )}`;

  const entityName = (optionData as EntityContact).entityName;

  const type = contactTypeOptions.find(
    (option: SelectOptionsObject<ContactType>) => option.value === optionData.contactType,
  );

  const displayName = isEntity ? entityName : name;
  const displayType = isTransactee ? formatContactTypeLikeTitle(optionData.contactType) : type?.label;

  return (
    <Flex data-test-id={`contact-${optionData.id}`} flexDirection="column">
      <Flex alignItems="center">
        <Avatar mr="space40" name={isEntity ? giveFirstWord(entityName) || '' : avatarName || ''} size={24} />
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" width="100%">
          {displayName}
        </Text>
      </Flex>
      <Flex pl="36px">
        {optionData.contactType && (
          <Flex alignItems="center">
            <Text color="carbon500" data-test-id="contact-type" size="fontSize10" whiteSpace="nowrap">
              {displayType}
            </Text>
            <Icon color="carbon500" name="Bullet" size="medium" />
          </Flex>
        )}
        {(optionData as LenderContact).nmlsId && (
          <Flex alignItems="center">
            <Text color="carbon500" data-test-id="contact-nmls" size="fontSize10">
              {(optionData as LenderContact).nmlsId}
            </Text>
            <Icon color="carbon500" name="Bullet" size="medium" />
          </Flex>
        )}
        {(optionData as AgentContact).licenseNumber && (
          <Flex alignItems="center">
            <Text color="carbon500" data-test-id="contact-license-number" size="fontSize10">
              {(optionData as AgentContact).licenseNumber}
            </Text>
            <Icon color="carbon500" name="Bullet" size="medium" />
          </Flex>
        )}
        {(optionData as Contact).phone && (
          <Flex alignItems="center">
            <Text color="carbon500" data-test-id="contact-phone" size="fontSize10" whiteSpace="nowrap">
              {formatPhoneNumber((optionData as Contact).phone || '')}
            </Text>
            {optionData.email && <Icon color="carbon500" data-test-id="phone-bullet" name="Bullet" size="medium" />}
          </Flex>
        )}
        {(optionData as Contact).email && (
          <Flex alignItems="center" overflow="hidden">
            <Text
              color="carbon500"
              data-test-id="contact-email"
              overflow="hidden"
              size="fontSize10"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              width="100%"
            >
              {(optionData as Contact).email}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
