import React, { FC } from 'react';
import { Box, Flex, Text, Icon } from '@endpoint/blockparty';
import { DocumentUpload } from 'consts/uploadDocumentsConsts';

interface UploadDocumentErrorProps {
  document: DocumentUpload;
  onRemoveDocument: () => void;
}

export const UploadDocumentError: FC<UploadDocumentErrorProps> = ({ document, onRemoveDocument }) => (
  <Box data-test-id="upload-error" mt="space50">
    <Flex
      alignItems="center"
      bg="watermelon0"
      border="1px solid"
      borderColor="watermelon500"
      borderRadius="radiusDefault"
      px="space50"
      py="space60"
    >
      <Flex alignItems="center" flexGrow={1} width="80%">
        <Box>
          <Icon color="watermelon500" name="Information" size="large" />
        </Box>
        <Text data-test-id="filename" ml="space50" textStyle="truncate">
          {document.name}
        </Text>
      </Flex>
      <Flex>
        <Icon
          color="carbon500"
          data-test-id="upload-error-cancel-icon"
          focusable
          ml="space60"
          name="Close"
          size="large"
          onClick={() => {
            onRemoveDocument();
          }}
        />
      </Flex>
    </Flex>
    <Box data-test-id="upload-error-text" pt="space10">
      <Text as="p" color="watermelon500" size="fontSize10">
        {document.fileRejection}
      </Text>
    </Box>
  </Box>
);
