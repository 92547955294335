import React, { ChangeEvent, FC } from 'react';
import { useFormikContext } from 'formik';
import {
  InputGroup,
  InputLabel,
  Select,
  Flex,
  Input,
  Icon,
  InputLeftElement,
  InputElemGroup,
  InputRightElement,
  Text,
  Grid,
  Divider,
  InputErrorMessage,
} from '@endpoint/blockparty';
import Cleave from 'cleave.js/react';
import { formatCurrencyStringToNumber, formatField } from 'helpers/formatText';
import { TransactionStatus, TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { createTermsFieldsOfInterest } from 'helpers/transaction';
import { delay } from 'lodash';
import { SaleProductOption, saleProductTypes } from 'consts/saleProductTypes';
import { transactionTypeMap } from 'consts/transactionConsts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { preventMinus, preventNegativeNumberCopyPaste } from 'helpers/openOrder/preventMinusInput';
import { useEditTransactionBasicInfoContext } from 'hooks/useEditTransactionBasicInfoContext';

import { EditTransactionBasicInfoDrawer } from '../types/EditTransactionBasicInfoDrawer';
import { BasicInfoTermsProps } from '.';

export const saleProductTypeOptions = saleProductTypes.map((productType) => ({
  value: productType,
  label: transactionTypeMap[productType],
}));

export const BasicInfoTermsEditForm: FC<BasicInfoTermsProps> = ({ transaction }) => {
  const { focusField } = useEditTransactionBasicInfoContext();
  const { setFieldValue, values, handleChange, errors, setFieldTouched } =
    useFormikContext<EditTransactionBasicInfoDrawer>();

  const { isChangeSaleProductTypeEnabled } = useFlags();

  const emdStatus = createTermsFieldsOfInterest(transaction).find((field) => field.label === 'EMD Status');

  const isSaleTransaction =
    values?.productType === TransactionType.SALE_MORTGAGE || values?.productType === TransactionType.SALE_CASH;
  const isClosedOrCancelledTransaction =
    transaction.status === TransactionStatus.CLOSED || transaction.status === TransactionStatus.CANCELLED;
  const isMissingProductType = !values?.productType;
  const canEditProductType = isSaleTransaction && !isClosedOrCancelledTransaction && isChangeSaleProductTypeEnabled;
  const isRefiOrEquityTransaction = [TransactionType.REFINANCE, TransactionType.EQUITY].includes(values.productType);
  const isRefiEquityOrMortageTransaction =
    isRefiOrEquityTransaction || values.productType === TransactionType.SALE_MORTGAGE;

  return (
    <Grid
      columnGap="space60"
      data-test-id="basic-info-terms-edit-form"
      pb="space50"
      px="space50"
      rowGap="space40"
      templateColumns="repeat(2, minmax(150px, 1fr))"
    >
      <InputGroup data-test-id="open-order-drawer-product-type-select" flex={1} groupId="productType" pt="space50">
        <InputLabel color={errors.productType ? 'watermelon500' : 'carbon600'} htmlFor="product-type">
          Product Type *
        </InputLabel>
        {isMissingProductType || canEditProductType ? (
          <Select
            autoFocus={focusField === 'Product Type'}
            defaultValue={saleProductTypeOptions.find((option) => option.value === values.productType)}
            inputId="product-type"
            name="product-type"
            options={saleProductTypeOptions}
            onChange={(selection: SaleProductOption) => setFieldValue('productType', selection.value)}
          />
        ) : (
          <Text as="p" mt="space30">
            {formatField(values.productType)}
          </Text>
        )}
        <Divider mt="space30" />
      </InputGroup>
      {transaction.type !== TransactionType.PRELISTING && (
        <>
          {!isRefiOrEquityTransaction && (
            <InputGroup groupId="salePrice" isInvalid={!!errors?.salePrice} pt="space50">
              <Flex flexDirection="row" justifyContent="space-between">
                <InputLabel color={errors.salePrice ? 'watermelon500' : 'carbon600'} htmlFor="salePrice">
                  Sale Price *
                </InputLabel>
              </Flex>
              <InputElemGroup>
                <InputLeftElement>
                  <Icon name="DollarSign" />
                </InputLeftElement>

                <Input
                  as={Cleave}
                  autoComplete="off"
                  autoFocus={focusField === 'Sale Price'}
                  name="salePrice"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                    rawValueTrimPrefix: true,
                  }}
                  value={values.salePrice ?? 0}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value === '') {
                      setFieldValue('salePrice', '');

                      return;
                    }

                    const salesPrice = formatCurrencyStringToNumber(event.target.value);

                    setFieldValue('salePrice', salesPrice);

                    if (salesPrice >= 0 && values.emdAmount) {
                      const emdAmount: number = parseInt(values.emdAmount, 10) || 0;

                      setFieldValue('emdPercent', ((100 * emdAmount) / salesPrice).toFixed(2));
                      /** adding a delay to the call of setFieldTouched ensures that any validation is run onChange */
                      delay(setFieldTouched, 100, 'emdPercent', true);
                    }
                  }}
                  onKeyPress={preventMinus}
                />
              </InputElemGroup>
              <Divider mt="space30" />
              {errors.salePrice && <InputErrorMessage>{errors.salePrice}</InputErrorMessage>}
            </InputGroup>
          )}

          {isRefiEquityOrMortageTransaction ? (
            <>
              <InputGroup groupId="loanAmount">
                <Flex flexDirection="column" width="100%">
                  <Flex flexDirection="row" justifyContent="space-between">
                    <InputLabel htmlFor="loanAmount">Loan Amount</InputLabel>
                  </Flex>
                  <InputElemGroup>
                    <InputLeftElement>
                      <Icon name="DollarSign" />
                    </InputLeftElement>

                    <Input
                      as={Cleave}
                      autoComplete="off"
                      autoFocus={focusField === 'Loan Amount'}
                      name="loanAmount"
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                        rawValueTrimPrefix: true,
                      }}
                      value={values.loanAmount ?? ''}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const loanValue = preventNegativeNumberCopyPaste(event.target.value);

                        const loanAmount = formatCurrencyStringToNumber(loanValue);

                        setFieldValue('loanAmount', loanAmount);
                      }}
                      onKeyPress={preventMinus}
                    />
                  </InputElemGroup>
                  <Divider mt="space30" />
                </Flex>
              </InputGroup>
              <InputGroup groupId="loanNumber">
                <InputLabel htmlFor="loanNumber">Loan Number</InputLabel>
                <InputElemGroup>
                  <Input
                    autoComplete="off"
                    autoFocus={focusField === 'Loan Number'}
                    name="loanNumber"
                    value={values.loanNumber}
                    onChange={handleChange}
                  />
                </InputElemGroup>
                <Divider mt="space30" />
              </InputGroup>
            </>
          ) : null}

          {!isRefiOrEquityTransaction && (
            <InputGroup groupId="emdAmount">
              <Flex flexDirection="row" width="100%">
                <Flex flexDirection="row" justifyContent="space-between" width="45%">
                  <InputLabel htmlFor="emdAmount">EMD Amount</InputLabel>
                </Flex>
              </Flex>
              <Flex alignItems="center">
                <InputElemGroup data-test-id="open-order-emd-dollarSign" flex={1}>
                  <InputLeftElement>
                    <Icon name="DollarSign" />
                  </InputLeftElement>
                  <Input
                    as={Cleave}
                    autoComplete="off"
                    autoFocus={focusField === 'EMD Amount'}
                    flex={1}
                    name="emdAmount"
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand',
                      rawValueTrimPrefix: true,
                    }}
                    value={values.emdAmount}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (!event.target.value) {
                        setFieldValue('emdAmount', 0);
                        setFieldValue('emdPercent', 0);

                        return;
                      }

                      const emdValue = preventNegativeNumberCopyPaste(event.target.value);

                      const currencyValueInteger = formatCurrencyStringToNumber(emdValue);

                      if (values.salePrice && currencyValueInteger) {
                        setFieldValue('emdAmount', currencyValueInteger);
                        setFieldValue('emdPercent', ((100 * currencyValueInteger) / values.salePrice).toFixed(2));
                      } else {
                        setFieldValue('emdAmount', undefined);
                        setFieldValue('emdPercent', undefined);
                      }
                    }}
                    onKeyPress={preventMinus}
                  />
                </InputElemGroup>
                <Text px="space50">=</Text>
                <InputElemGroup data-test-id="open-order-emd-percentage" flex={1} iconRightSpacing>
                  <Input
                    aria-label="emdPercent"
                    autoComplete="off"
                    name="emdPercent"
                    type="number"
                    value={values.emdPercent}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const value = parseInt(event.target.value, 10);

                      if (values.salePrice && value) {
                        const percentage = event.target.value || 0;

                        const emdAmount = (values.salePrice * value) / 100;

                        setFieldValue('emdAmount', emdAmount);
                        setFieldValue('emdPercent', percentage);
                      } else {
                        setFieldValue('emdAmount', undefined);
                        setFieldValue('emdPercent', undefined);
                      }
                    }}
                  />
                  <InputRightElement>%</InputRightElement>
                </InputElemGroup>
              </Flex>
            </InputGroup>
          )}

          {emdStatus && (
            <InputGroup flex={1} groupId="escrowOpenedDate" marginRight="5px" pt="space50">
              <Flex flexDirection="row" justifyContent="space-between">
                <InputLabel>EMD Status</InputLabel>
              </Flex>
              <Text>{formatField(emdStatus.value)}</Text>
            </InputGroup>
          )}
        </>
      )}
    </Grid>
  );
};
