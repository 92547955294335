import {
  DateTime,
  Todo,
  OpsSquad,
  OpsTaskStatus,
  Contact,
  OpsTaskStep as BffOpsTaskStep,
} from '@endpoint/opsware-bff-graphql-schema';
import { JSONSchema7 } from 'json-schema';

export enum TaskStepItemType {
  TaskStepItemRadioGroup = 'TaskStepItemRadioGroup',
  TaskStepItemChecklist = 'TaskStepItemCheckboxGroup',
  TaskStepItemTextArea = 'TaskStepItemTextArea',
}

export enum TaskStepItemFormat {
  Radio = 'radio',
  TextArea = 'textarea',
  Checkbox = 'checkbox',
}

// As we add additional types of form elements, we can extend TaskStepItem to include those
export type TaskStepItemDefinition = QATaskStepItemDefinition;
export interface TaskStepOption {
  label: string;
  value: string;
}

export interface QATaskStepItemDefinition {
  key: string;
  name: string;
  type: TaskStepItemType;
  title?: string;
  description?: string;
  readOnly?: boolean;
  required?: boolean;
  options?: TaskStepOption[];
  followUp?: Record<string, QATaskStepItemDefinition[]>;
}

export interface TaskStepDefinition {
  key: string;
  items: TaskStepItemDefinition[];
  completedView: CompletedView;
  pendingView: PendingView;
}

export interface CompletedView {
  message: string;
  showResponse: boolean;
}

export interface PendingView {
  message: string;
}

export interface TaskSchema {
  title?: string;
  description?: string;
  stepDefinitions: Record<string, TaskStepDefinition>;
}

export enum OpenApiSchemaTypes {
  String = 'string',
  Array = 'array',
  Boolean = 'boolean',
  Object = 'object',
}

export type OpenApiObject = JSONSchema7;

interface TaskDefinition {
  key: string;
  version: string;
  schema: OpenApiObject;
}

export interface OpsTaskStep extends Omit<BffOpsTaskStep, 'response'> {
  response?: FormikItems;
}

// TODO: Import this from the BFF whenever they update it to include steps, taskDefinition, etc
export interface OpsTask {
  id: string;
  taskTitle: string;
  assigneeName?: string;
  createdOn: DateTime;
  dueDateTime: DateTime;
  squad: OpsSquad;
  status: OpsTaskStatus;
  transactionId: string;
  transactionFileNumber: string;
  transactionClosingDate: DateTime;
  isOverdue: boolean;
  documents: Document[];
  contacts: Contact[];
  todos: Todo[];
  taskDefinition: TaskDefinition;
  steps: OpsTaskStep[];
}

export interface FormikItems {
  [key: string]: FormikItemValue;
}

export interface FormikItemValue {
  value: string | string[];
  followUp?: FormikItems;
}
