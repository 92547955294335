import React, { FC, useState } from 'react';
import { useMutation, ApolloError } from '@apollo/client';
import { Box, Text, Divider } from '@endpoint/blockparty';
import { Task, TaskStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TaskStatusButton } from 'components/TaskStatusButton';
import { ErrorMessage } from 'components/ErrorMessage';
import { TaskModified, TrackingEvent } from 'consts/segmentProtocols';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { ExecutionResult } from 'graphql';
import { useHasWriteAccess } from 'hooks/useHasWriteAccess';

import { SET_TASK_STATUS } from '../queries';

interface TransactionRightPanelDetails {
  task: Task;
}

export const Details: FC<TransactionRightPanelDetails> = ({ task }) => {
  const trackData: TaskModified = {
    taskId: task.id,
    originalValue: task.status,
    newValue: null,
  };
  const trackingEvent: TrackingEvent = 'Task Modified';
  const [hasError, setError] = useState(false);

  const [setTaskStatus] = useMutation(SET_TASK_STATUS, {
    onCompleted: (response: ExecutionResult) => {
      trackAnalytics(trackingEvent, {
        ...trackData,
        newValue: response?.data?.setTaskStatus.status,
      });
    },
    onError: () => {
      setError(true);
    },
  });

  const handleChange = (value: TaskStatus): void => {
    void setTaskStatus({
      variables: {
        data: { status: value },
        where: {
          id: task.id,
        },
      },
    });
  };

  const hasWriteAccess = useHasWriteAccess();

  return (
    <Box data-test-id="task-details">
      <Box p="space60">
        <Text data-test-id="task-details-title" fontWeight="semi" size="fontSize50">
          {task.name}
        </Text>
        <Box mt="space60" width="168px">
          <TaskStatusButton
            isDisabled={!hasWriteAccess}
            task={task}
            onChange={(value): void => {
              handleChange(value);
            }}
          />
        </Box>
        {hasError && (
          <Box pt="space60">
            <ErrorMessage />
          </Box>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

Details.displayName = 'Details';
