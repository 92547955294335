import { gql } from '@apollo/client';
import { Transaction, TodoAssignment, Todo } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_TRANSACTION_PARTICIPANTS = gql`
  query GetTransactionHeaderData($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      id
      participants {
        id
        roles
        contact {
          id
          ... on AgentContact {
            firstName
            lastName
          }
          ... on LenderContact {
            firstName
            lastName
          }
          ... on IndividualContact {
            firstName
            lastName
          }
          ... on EntityContact {
            entityName
          }
        }
      }
    }
  }
`;

export const UPDATE_TODO = gql`
  mutation UpdateTodo($data: TodoUpdateInput!, $where: TodoWhereUniqueInput!) {
    updateTodo(data: $data, where: $where) {
      id
      escrowNote
      due
    }
  }
`;

export const UPDATE_TODO_ASSIGNMENT = gql`
  mutation UpdateTodoAssignment($data: TodoAssignmentUpdateInput!, $where: TodoAssignmentWhereUniqueInput!) {
    updateTodoAssignment(data: $data, where: $where) {
      id
      status
      recallReason
    }
  }
`;

const TodoFragment = {
  todoAssignment: gql`
    fragment TodoAssignment on TodoAssignment {
      id
      createdAt
      updatedAt
      deletedAt
      statusOptions
      completedAt
      status
      assignee {
        id
        roles
        contact {
          id
          ... on AgentContact {
            firstName
            lastName
          }
          ... on LenderContact {
            firstName
            lastName
          }
          ... on IndividualContact {
            firstName
            lastName
          }
          ... on EntityContact {
            entityName
          }
        }
      }
      todo {
        id
      }
    }
  `,
};

export const CREATE_TODO_ASSIGNMENT = gql`
  mutation CreateTodoAssignment($data: TodoAssignmentCreateInput!) {
    createTodoAssignment(data: $data) {
      ...TodoAssignment
    }
  }
  ${TodoFragment.todoAssignment}
`;

export const TODO_FRAGMENT = gql`
  fragment Todo on Todo {
    id
    assignments {
      ...TodoAssignment
    }
  }
  ${TodoFragment.todoAssignment}
`;

export interface CreateTodoAssignment {
  createTodoAssignment: TodoAssignment;
}

export interface GetTransactionParticipants {
  transaction: Transaction;
}

export interface UpdateTodoData {
  updateTodo: Todo;
}
