import { Icon } from '@endpoint/blockparty';
import { IconButtonProps } from '@endpoint/blockparty/dist/components/IconButton';
import { useHasWriteAccess } from 'hooks/useHasWriteAccess';
import React, { FC } from 'react';

/**
 * This component can be used as an icon where WRITE ACCESS is required.
 * If the user is READ ONLY, it will Hide the icon edit button.
 * If the user has WRITE ACCESS it will render the normal icon as expected.
 */

interface IconButtonWriteRequiredProps extends Omit<IconButtonProps, 'label'> {}

export const IconButtonWriteRequired: FC<IconButtonWriteRequiredProps> = (props) => {
  const hasWriteAccess = useHasWriteAccess();

  const { children } = props;

  return hasWriteAccess ? <Icon {...props}>{children}</Icon> : null;
};
