import React, { FC } from 'react';
import { Flex, InputLabel, Text } from '@endpoint/blockparty';
import { dateFormatter } from 'helpers/datetimeHelper';

export const NonEditableDateField: FC<{ label: string; date: Date }> = ({ label, date }) => {
  return (
    <Flex flexDirection="column" marginRight="5px">
      <Flex flexDirection="row" justifyContent="space-between">
        <InputLabel>{label}</InputLabel>
      </Flex>
      <Text>{date ? dateFormatter(date, 'MM/dd/yyyy') : '-'}</Text>
    </Flex>
  );
};
