import { BoxProps } from '@endpoint/blockparty/dist/components/Box';
import { Document, Milestone, Task, Todo } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { UploadDocument, DocumentUploadStatus, FileRejections } from 'consts/uploadDocumentsConsts';

export type IconMap = {
  name: string;
  color: BoxProps['color'];
};

export const imageIcon = (mimeType: string): IconMap => {
  if ((mimeType && mimeType.includes('pdf')) || mimeType.includes('.pdf'))
    return { name: 'DocumentPDF', color: 'carbon900' };
  if ((mimeType && mimeType.includes('image')) || mimeType.includes('jpg') || mimeType.includes('jpeg'))
    return { name: 'DocumentJPG', color: 'turquoise600' };

  return { name: 'PaperOutline', color: 'carbon900' };
};

export function sortDocuments(loading: boolean, documents: Document[] | undefined): Document[] {
  if (loading || !documents || !documents.length) return [];

  return documents.sort((aDoc: Document, bDoc: Document) => {
    const documentA = new Date(aDoc.createdAt).getTime();
    const documentB = new Date(bDoc.createdAt).getTime();

    return documentB - documentA;
  });
}

// formatBytes function reference: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes: number, decimals: number = 1): string => {
  if (bytes === 0) return '0 Bytes';
  // eslint-disable-next-line id-length
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const setUploadRefreshPollInterval = (uploadsData: UploadDocument | undefined): number => {
  if (
    uploadsData?.uploads.length &&
    uploadsData.uploads.some((upload) => upload.status === DocumentUploadStatus.COMPLETED) &&
    uploadsData.uploadDrawerOpen
  ) {
    return 500;
  }

  return 0;
};

export const getUploadErrorMessage = (fileRejection: string): string => {
  switch (fileRejection) {
    case FileRejections.FILE_INVALID_TYPE:
      return 'The document type must be PDF, JPEG, or JPG';
    case FileRejections.FILE_TOO_LARGE:
      return 'The document must be smaller than 50MB';
    case FileRejections.CANCELED:
      return 'Upload cancelled';
    default:
      return 'There was an error uploading your file';
  }
};

export const getTask = (milestones?: Milestone[], todoId?: string): Task | undefined => {
  if (!(milestones && todoId)) {
    return undefined;
  }

  for (const milestone of milestones) {
    const targetTask: Task | undefined = milestone.tasks.find((task: Task) => {
      return task.todos.map((todo: Todo) => todo.id).includes(todoId);
    });

    if (targetTask) {
      return targetTask;
    }
  }

  return undefined;
};
