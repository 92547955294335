import { gql } from '@apollo/client';

export const GET_TRANSACTION_CALENDAR_INFO = gql`
  query getTransactionCalendarInfo($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      id
      dates {
        estimatedClosing
        sellerSigningDate
        buyerSigningDate
      }
      fileNum
      property {
        address {
          standardFormat
        }
      }
      buyerSigning {
        date
        location
        notaryName
        notaryPhone
      }
      sellerSigning {
        date
        location
        notaryName
        notaryPhone
      }
      participants {
        id
        roles
        contact {
          id
          ... on AgentContact {
            firstName
            lastName
          }
          ... on LenderContact {
            firstName
            lastName
          }
          ... on IndividualContact {
            firstName
            lastName
          }
          ... on EntityContact {
            entityName
          }
        }
      }
      milestones {
        id
        status
        type
      }
      type
      notes {
        id
        text
        author {
          id
          firstName
          lastName
          imageUrl
        }
        createdAt
        isPinned
      }
    }
  }
`;
