import { ContactSortProperties, ContactType } from '@endpoint/opsware-bff-graphql-schema';

import { ContactFragmentTypes } from './contactDetails';

export enum AddressBookDetailsFieldsLabels {
  NAME = 'Name',
  CONTACT_TYPE = 'Default Contact Type',
  LICENSE_NUMBER = 'License Number',
  STATE_OF_OPERATION = 'State of Operation',
  NMLS_ID = 'NMLS ID',
  MLS_ID = 'MLS ID',
}

export const AddressBookFieldsOrder = [
  AddressBookDetailsFieldsLabels.NAME,
  AddressBookDetailsFieldsLabels.CONTACT_TYPE,
  AddressBookDetailsFieldsLabels.LICENSE_NUMBER,
  AddressBookDetailsFieldsLabels.STATE_OF_OPERATION,
  AddressBookDetailsFieldsLabels.MLS_ID,
  AddressBookDetailsFieldsLabels.NMLS_ID,
];

export type ContactTypeToFragmentRecord = Record<ContactType, ContactFragmentTypes>;

export const contactTypeToFragmentMap: ContactTypeToFragmentRecord = {
  [ContactType.AGENT]: ContactFragmentTypes.AGENT_CONTACT,
  [ContactType.LOAN_OFFICER]: ContactFragmentTypes.LENDER_CONTACT,
  [ContactType.LOAN_PROCESSOR]: ContactFragmentTypes.LENDER_CONTACT,
  [ContactType.MORTGAGE_BROKER]: ContactFragmentTypes.LENDER_CONTACT,
  [ContactType.PROPTECH_PARTNER]: ContactFragmentTypes.ENTITY_CONTACT,
  [ContactType.SETTLEMENT_COMPANY]: ContactFragmentTypes.ENTITY_CONTACT,
  [ContactType.TRANSACTEE_CORPORATION]: ContactFragmentTypes.ENTITY_CONTACT,
  [ContactType.TRANSACTEE_INDIVIDUAL]: ContactFragmentTypes.INDIVIDUAL_CONTACT,
  [ContactType.INTERNAL_EMPLOYEE]: ContactFragmentTypes.INDIVIDUAL_CONTACT,
  [ContactType.TRANSACTEE_PARTNERSHIP]: ContactFragmentTypes.ENTITY_CONTACT,
  [ContactType.TRANSACTEE_TRUST]: ContactFragmentTypes.ENTITY_CONTACT,
  [ContactType.TRANSACTION_COORDINATOR]: ContactFragmentTypes.AGENT_CONTACT,
};

export enum AddressBookSortTableAccessor {
  name = 'name',
  contactType = 'contactType',
}

export const addressBookTableAccessorToSortPropMap: Record<AddressBookSortTableAccessor, ContactSortProperties> = {
  name: ContactSortProperties.NAME,
  contactType: ContactSortProperties.CONTACT_TYPE,
};

export const addressBookSortPropToTableAccessorMap: Record<ContactSortProperties, AddressBookSortTableAccessor> = {
  [ContactSortProperties.NAME]: AddressBookSortTableAccessor.name,
  [ContactSortProperties.CONTACT_TYPE]: AddressBookSortTableAccessor.contactType,
};

export enum GlobalContactUpdateOptions {
  APPLY_TO_ALL = 'allOpenOrders',
  DO_NOT_APPLY = 'anyOpenOrders',
}
