import React, { FC, ReactNode } from 'react';
import { SkeletonBlock, SkeletonText, Card } from '@endpoint/blockparty';

interface LoadingContentSkeletonProps {
  lines: number;
}

const LoadingContentSkeleton: FC<LoadingContentSkeletonProps> = ({ lines }): React.ReactElement => (
  <Card dataTestId="loading-content-skeleton" mb="space60" p="space30">
    <SkeletonBlock />
    <SkeletonText lines={lines} />
  </Card>
);

export function generateLoadingContentSkeltons(count: number, linesForText: number, section: string): ReactNode[] {
  const arr: ReactNode[] = [];

  for (let i = 0; i < count; i++) {
    arr.push(<LoadingContentSkeleton key={`${section}-${i}`} lines={linesForText} />);
  }

  return arr;
}

LoadingContentSkeleton.displayName = 'LoadingContentSkeleton';
