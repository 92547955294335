import { Box, InputErrorMessage, InputGroup, InputLabel, Select, Text } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';
import { Organization } from '@endpoint/opsware-bff-graphql-schema';
import useMarketsAndOrganizations from 'hooks/useMarketsAndOrganizations';

export const OrganizationForm: FC = () => {
  const { errors, values, setFieldValue, touched } = useFormikContext<OpenOrderDrawerForm>();
  const { organizations } = useMarketsAndOrganizations();

  const organizationOptions: SelectOptionsObject<number>[] =
    organizations?.map((organization: Organization) => ({
      value: organization.id,
      label: organization.name,
    })) || [];

  return (
    <Box>
      <Text fontWeight="semi" size="fontSize30">
        Open with
      </Text>
      <InputGroup
        data-test-id="open-order-drawer-organization-type"
        flex={1}
        groupId="organizationType"
        isInvalid={!!(touched.organization && errors.organization)}
        pb="space70"
        pt="space50"
      >
        <InputLabel color="carbon500">Organization *</InputLabel>
        <Select
          defaultValue={organizationOptions.find((option) => option.value === values.organization)}
          inputId="organizationType"
          name="organizationType"
          options={organizationOptions}
          onChange={(value: { value: string }) => setFieldValue('organization', value.value)}
        />
        <InputErrorMessage>{errors.organization}</InputErrorMessage>
      </InputGroup>
    </Box>
  );
};
