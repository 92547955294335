import { TaskStepItemCheckboxGroup } from '../../routes/Transaction/TransactionRightPanel/DynamicForm/TaskStepItemComponents/TaskStepItemCheckboxGroup';
import { TaskStepItemRadioGroup } from '../../routes/Transaction/TransactionRightPanel/DynamicForm/TaskStepItemComponents/TaskStepItemRadioGroup';
import { TaskStepItemTextArea } from '../../routes/Transaction/TransactionRightPanel/DynamicForm/TaskStepItemComponents/TaskStepItemTextArea';

export type TaskStepItem = typeof componentMapping[keyof typeof componentMapping];

export const componentMapping = {
  TaskStepItemCheckboxGroup,
  TaskStepItemRadioGroup,
  TaskStepItemTextArea,
};
