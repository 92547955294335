import { isEmpty } from 'lodash';

import {
  OpenApiObject,
  TaskSchema,
  TaskStepDefinition,
  TaskStepItemType,
  TaskStepItemDefinition,
  TaskStepItemFormat,
  TaskStepOption,
} from './types';

export const generateSchema = (oaTask: OpenApiObject): TaskSchema => {
  const schema: TaskSchema = {
    title: oaTask.title,
    description: oaTask.description,
    stepDefinitions: {},
  };

  const oaTaskSteps: OpenApiObject[] | undefined =
    (((oaTask.properties?.stepDefinitions as OpenApiObject)?.items as OpenApiObject)?.anyOf as OpenApiObject[]) || [];

  for (const openApiStep of oaTaskSteps) {
    if (!openApiStep.title) {
      throw new Error('Object does not contain expected property description title');
    }

    const oaTaskStepDefinitions = (openApiStep.properties?.definition as OpenApiObject).properties as OpenApiObject;

    const oaTaskStepItemPendingView = (
      (openApiStep.properties?.visualization as OpenApiObject).properties?.pendingView as OpenApiObject
    ).properties;
    const oaTaskStepItemCompletedView = (
      (openApiStep.properties?.visualization as OpenApiObject).properties?.completedView as OpenApiObject
    ).properties;

    const pendingMessage: string = ((oaTaskStepItemPendingView?.message as OpenApiObject).default as string) || '';
    const completedMessage: string = ((oaTaskStepItemCompletedView?.message as OpenApiObject).default as string) || '';
    const showResponse: boolean = (oaTaskStepItemCompletedView?.showResponse as OpenApiObject).default as boolean;

    const taskStepDefinition: TaskStepDefinition = {
      key: openApiStep.title,
      items: getRootTaskStepItems(oaTaskStepDefinitions),
      pendingView: { message: pendingMessage },
      completedView: { message: completedMessage, showResponse },
    };

    schema.stepDefinitions[openApiStep.title] = taskStepDefinition;
  }

  return schema;
};

const getRootTaskStepItems = (oaTaskStepItems: OpenApiObject): TaskStepItemDefinition[] => {
  if (!oaTaskStepItems) {
    return [];
  }

  const taskStepItems = [];

  for (const [oaTaskStepItemKey, oaTaskStepItemValue] of Object.entries(oaTaskStepItems)) {
    const type: TaskStepItemType = getTaskStepItemType(oaTaskStepItemValue);
    const name = oaTaskStepItemKey;
    const taskStepItem: TaskStepItemDefinition = {
      key: oaTaskStepItemKey,
      name,
      type,
      title: oaTaskStepItemValue.title,
      description: oaTaskStepItemValue.description,
      options: getOptions(oaTaskStepItemValue),
      followUp: getFollowUpTaskStepItems(oaTaskStepItemValue, name),
    };

    taskStepItems.push(taskStepItem);
  }

  return taskStepItems;
};

const getFollowUpTaskStepItems = (
  oaTaskStepItem: OpenApiObject,
  parentName: string,
): Record<string, TaskStepItemDefinition[]> | undefined => {
  const taskStepItems: Record<string, TaskStepItemDefinition[]> = {};
  const oaOptions = getOaOptions(oaTaskStepItem);

  if (!oaOptions) return undefined;

  for (const oaOption of oaOptions) {
    if (!oaOption.description || !oaOption.title) {
      throw new Error('Object does not contain expected property');
    }

    const oaFollowUpItems: OpenApiObject = oaOption.properties as OpenApiObject;

    if (oaFollowUpItems) {
      taskStepItems[oaOption.title] = [];

      for (const [oaFollowUpItemItemKey, oaFollowUpItemValue] of Object.entries(oaFollowUpItems).sort()) {
        const type: TaskStepItemType = getTaskStepItemType(oaFollowUpItemValue);
        const name = `${parentName}.followUp.${oaFollowUpItemItemKey}`;
        const taskStepItem: TaskStepItemDefinition = {
          key: oaFollowUpItemItemKey,
          name,
          type,
          title: oaFollowUpItemValue.title,
          description: oaFollowUpItemValue.description,
          options: getOptions(oaFollowUpItemValue),
          followUp: getFollowUpTaskStepItems(oaFollowUpItemValue, name),
        };

        taskStepItems[oaOption.title].push(taskStepItem);
      }
    }
  }

  if (isEmpty(taskStepItems)) {
    return undefined;
  }

  return taskStepItems;
};

const getOaOptions = (oaTaskStepItem: OpenApiObject): OpenApiObject[] | null => {
  const oaOptions = oaTaskStepItem.properties?.options as OpenApiObject | undefined;

  switch (oaTaskStepItem.format) {
    case TaskStepItemFormat.Radio:
      if (!oaOptions) {
        throw new Error('Invalid options structure');
      }

      return oaOptions.oneOf as OpenApiObject[];
    case TaskStepItemFormat.Checkbox:
      if (!oaOptions) {
        throw new Error('Invalid options structure');
      }

      return oaOptions.anyOf as OpenApiObject[];
    case TaskStepItemFormat.TextArea:
      return null;
    default:
      throw new Error('Format did not match any expected values');
  }
};

const getOptions = (oaTaskStepItem: OpenApiObject): TaskStepOption[] | undefined => {
  const oaOptions = getOaOptions(oaTaskStepItem);

  if (!oaOptions) return undefined;

  const taskStepOptions: TaskStepOption[] = [];

  for (const option of oaOptions) {
    if (option.description && option.title) {
      taskStepOptions.push({
        label: option.description,
        value: option.title,
      } as TaskStepOption);
    }
  }

  return taskStepOptions;
};

export const getTaskStepItemType = (openApiTaskStepItem: OpenApiObject): TaskStepItemType => {
  if (openApiTaskStepItem.format === TaskStepItemFormat.Checkbox) {
    return TaskStepItemType.TaskStepItemChecklist;
  }

  if (openApiTaskStepItem.format === TaskStepItemFormat.Radio) {
    return TaskStepItemType.TaskStepItemRadioGroup;
  }

  if (openApiTaskStepItem.format === TaskStepItemFormat.TextArea) {
    return TaskStepItemType.TaskStepItemTextArea;
  }

  throw new Error('Task Step Item did not contain a valid format value');
};
