import {
  TransactionRole,
  Contact,
  AgentContact,
  EntityContact,
  IndividualContact,
  LenderContact,
  ContactType,
  TransactionParticipant,
  Transaction,
  TransactionType,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { flatten } from 'lodash';
import { State } from 'hooks/useContactProvider';
import { contactTypeToFragmentMap } from 'consts/addressBook';

import { StateMap } from '../../consts/states';
import {
  NewContactSchema,
  roleOptions,
  NewContactStep,
  newContactDrawerTitleMap,
  ContactGQLErrorType,
  ContactErrorMessaging,
  contactGQLErrorTextMap,
} from '../../consts/createNewContact';
import { UpdateContactField } from '../../consts/updateContact';
import {
  AllContactTypes,
  ContactFragmentTypes,
  ContactDetailsFieldsOrder,
  ContactFieldData,
  rolesToFragmentMap,
} from '../../consts/contactDetails';
import { formatRolesLikeTitle, getContactName, getParticipantName } from '../formatText';

export const getContactRoleOptionsByTransactionType = (
  contactType: ContactType | string,
  transactionType: TransactionType,
): SelectOptionsObject<TransactionRole>[] => {
  // all transactees on Refinance transactions become Borrowers

  const isRefinance = transactionType === TransactionType.REFINANCE;
  const isEquity = transactionType === TransactionType.EQUITY;

  const shouldRolesOptionsBeEmpty =
    !contactType ||
    (isRefinance && contactType.includes('TRANSACTEE')) ||
    (isEquity && contactType.includes('TRANSACTEE')) ||
    isSettlementCompanyByContactType(contactType);

  if (shouldRolesOptionsBeEmpty) {
    return [];
  }

  return roleOptions[contactType as ContactType];
};

export const getContactErrorMessageText = (errorType: ContactGQLErrorType): ContactErrorMessaging => {
  return contactGQLErrorTextMap[errorType as ContactGQLErrorType];
};

export const setNewContactDrawerTitle = (step: NewContactStep): string => newContactDrawerTitleMap[step];

export const handleRolesCheckboxUpdate = (
  role: TransactionRole,
  checkedRoles: TransactionRole[],
): TransactionRole[] => {
  const checkedRolesCopy = [...checkedRoles];

  if (checkedRolesCopy.includes(role)) {
    const existingRoleIndex = checkedRolesCopy.indexOf(role);

    checkedRolesCopy.splice(existingRoleIndex, 1);
  } else {
    checkedRolesCopy.push(role);
  }

  return checkedRolesCopy;
};

export const setTransactionRoleByContactType = (contactType: ContactType): TransactionRole => {
  switch (contactType) {
    case ContactType.LOAN_OFFICER:
      return TransactionRole.LOAN_OFFICER;
    case ContactType.LOAN_PROCESSOR:
      return TransactionRole.LOAN_PROCESSOR;

    default:
      return TransactionRole.MORTGAGE_BROKER;
  }
};

export const setRolesByContactAndTransactionType = (
  contactType: ContactType | undefined,
  transactionType: TransactionType,
): TransactionRole[] => {
  const isRefinance = transactionType === TransactionType.REFINANCE;
  const isEquity = transactionType === TransactionType.EQUITY;

  const shouldSetRoleToBorrower =
    (contactType && isRefinance && contactType.includes('TRANSACTEE')) ||
    (contactType && isEquity && contactType.includes('TRANSACTEE'));
  const shouldSetRoleToSettlementCompany = isSettlementCompanyByContactType(contactType);
  const isLender =
    contactType &&
    (contactType === ContactType.LOAN_OFFICER ||
      contactType === ContactType.LOAN_PROCESSOR ||
      contactType === ContactType.MORTGAGE_BROKER);

  if (shouldSetRoleToBorrower) return [TransactionRole.BORROWER];

  if (shouldSetRoleToSettlementCompany) return [TransactionRole.SETTLEMENT_COMPANY];

  if (isLender) {
    const role = setTransactionRoleByContactType(contactType as ContactType);

    return [role];
  }

  if (contactType === ContactType.INTERNAL_EMPLOYEE) {
    return [TransactionRole.CLOSING_SPECIALIST];
  }

  return [] as TransactionRole[];
};

export const mapFoundContactsToNewContactSchema = (
  responseData: Contact[] | AgentContact[] | EntityContact[] | IndividualContact[] | LenderContact[],
  rolesSelectedInForm: TransactionRole[],
): NewContactSchema[] => {
  const mappedData = (
    responseData as Array<Contact | AgentContact | EntityContact | IndividualContact | LenderContact>
  ).map((contact) => {
    const base = {
      contactType: contact.contactType || '',
      phone: contact.phone || '',
      email: contact.email || '',
      id: contact.id || '',
    };
    const individual: IndividualContact = {
      firstName: (contact as IndividualContact).firstName || '',
      lastName: (contact as IndividualContact).lastName || '',
      middleName: (contact as IndividualContact).middleName || '',
      organizationId: contact.organizationId,
    };
    const agent = {
      licenseNumber: (contact as AgentContact).licenseNumber || '',
      stateOfOperation: (contact as AgentContact).stateOfOperation || '',
      organizationId: (contact as AgentContact).organizationId,
    };
    const lender = {
      nmlsId: (contact as LenderContact).nmlsId || '',
      organizationId: (contact as AgentContact).organizationId,
    };
    const entity = {
      entityName: (contact as EntityContact).entityName || '',
      organizationId: (contact as AgentContact).organizationId,
    };

    return {
      ...base,
      ...individual,
      ...agent,
      ...lender,
      ...entity,
      roles: [...rolesSelectedInForm],
    };
  });

  return mappedData;
};

export const isEntity = (contactType: ContactType | string): boolean => {
  return (
    contactType === ContactType.PROPTECH_PARTNER ||
    contactType === ContactType.TRANSACTEE_CORPORATION ||
    contactType === ContactType.TRANSACTEE_PARTNERSHIP ||
    contactType === ContactType.TRANSACTEE_TRUST ||
    contactType === ContactType.SETTLEMENT_COMPANY
  );
};

export const isAgentOrTC = (contactType: ContactType | string): boolean => {
  return contactType === ContactType.AGENT || contactType === ContactType.TRANSACTION_COORDINATOR;
};

export const isLoan = (contactType: ContactType | string): boolean => {
  return (
    contactType === ContactType.LOAN_OFFICER ||
    contactType === ContactType.LOAN_PROCESSOR ||
    contactType === ContactType.MORTGAGE_BROKER
  );
};

export const isIndividual = (contactType: ContactType | string): boolean => {
  return contactType === ContactType.INTERNAL_EMPLOYEE || contactType === ContactType.TRANSACTEE_INDIVIDUAL;
};

export const isKeyInContactObject = (contact: AllContactTypes, keyName: string): boolean => {
  return Object.hasOwnProperty.call(contact, keyName);
};

export const isParticipantEntity = (participant: TransactionParticipant): boolean => {
  const contact = participant.contact as AllContactTypes;

  if (contact.entityName) {
    return true;
  }

  return false;
};

export const isParticipantTxIndividual = (participant: TransactionParticipant): boolean => {
  const { roles } = participant;

  const txIndividualRoles = [TransactionRole.BUYER, TransactionRole.SELLER, TransactionRole.BORROWER];

  return roles.some((role) => txIndividualRoles.includes(role));
};

export const isParticipantAgentOrTC = (participant: TransactionParticipant): boolean => {
  const { roles } = participant;
  let hasAgentOrTCRole = false;
  const agentOrTCRoles = [
    TransactionRole.LISTING_AGENT,
    TransactionRole.SELLING_AGENT,
    TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR,
    TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR,
  ];

  roles.forEach((role) => {
    if (agentOrTCRoles.includes(role)) {
      hasAgentOrTCRole = true;
    }
  });

  return hasAgentOrTCRole;
};

export const isParticipantLoanContact = (participant: TransactionParticipant): boolean => {
  const { roles } = participant;
  let isLoanContact = false;
  const loanContactRoles = [
    TransactionRole.MORTGAGE_BROKER,
    TransactionRole.LOAN_OFFICER,
    TransactionRole.LOAN_PROCESSOR,
  ];

  roles.forEach((role) => {
    if (loanContactRoles.includes(role)) {
      isLoanContact = true;
    }
  });

  return isLoanContact;
};

export const getContactFragmentType = (
  participant: TransactionParticipant,
  canDisplayParticipantProfileData: boolean,
): ContactFragmentTypes[] => {
  const { roles, contact, contactType } = participant;
  const fragments: ContactFragmentTypes[] = [];

  if (canDisplayParticipantProfileData) {
    const fragment = contactType ? contactTypeToFragmentMap[contactType] : ContactFragmentTypes.CONTACT;

    fragments.push(fragment);
  } else {
    roles.forEach((role) => {
      fragments.push(roleToFragment(role, contact as AllContactTypes));
    });
  }

  return Array.from(new Set(fragments));
};

export const roleToFragment = (role: TransactionRole, contact: AllContactTypes) => {
  const fragment = rolesToFragmentMap[role] || ContactFragmentTypes.CONTACT;

  if (typeof fragment === 'object' && 'entityName' in contact) {
    return ContactFragmentTypes.ENTITY_CONTACT;
  }

  if (typeof fragment === 'object') {
    return ContactFragmentTypes.INDIVIDUAL_CONTACT;
  }

  return fragment;
};

export const createContactDetailsFields = (
  participant: TransactionParticipant,
  canDisplayParticipantProfileData: boolean,
): ContactFieldData[] => {
  const contact = participant.contact as AllContactTypes;
  const contactFragmentTypes = getContactFragmentType(participant, canDisplayParticipantProfileData);
  let fieldsWithDuplicates: ContactFieldData[] = [...getDataFieldRole(participant)];
  const fields: ContactFieldData[] = [];

  contactFragmentTypes.forEach((type) => {
    switch (type) {
      case ContactFragmentTypes.AGENT_CONTACT: {
        fieldsWithDuplicates = [
          ...fieldsWithDuplicates,
          ...getDataFieldsAgentContact(participant, canDisplayParticipantProfileData),
        ];
        break;
      }

      case ContactFragmentTypes.INDIVIDUAL_CONTACT: {
        fieldsWithDuplicates = [
          ...fieldsWithDuplicates,
          ...getDataFieldsIndividualContact(participant, canDisplayParticipantProfileData),
        ];
        break;
      }

      case ContactFragmentTypes.LENDER_CONTACT: {
        fieldsWithDuplicates = [
          ...fieldsWithDuplicates,
          ...getDataFieldsLenderContact(participant, canDisplayParticipantProfileData),
        ];
        break;
      }

      case ContactFragmentTypes.ENTITY_CONTACT: {
        fieldsWithDuplicates = [
          ...fieldsWithDuplicates,
          ...getDataFieldsEntityContact(participant, canDisplayParticipantProfileData),
        ];
        break;
      }

      default: {
        break;
      }
    }
  });

  ContactDetailsFieldsOrder.forEach((label) => {
    const field = fieldsWithDuplicates.find((basicField) => basicField.label === label);

    if (field) {
      fields.push(field);
    }
  });

  return fields;
};

function getDataFieldRole(participant: TransactionParticipant): ContactFieldData[] {
  const { roles } = participant;

  return [
    {
      label: 'Role',
      value: formatRolesLikeTitle(roles.join(', ')),
      isEditable: false,
      fieldName: UpdateContactField.ROLE,
    },
  ];
}

export function getDataFieldsAgentContact(
  participant: TransactionParticipant,
  canDisplayParticipantProfileData: boolean,
): ContactFieldData[] {
  const contact = participant.contact as AllContactTypes;
  let licenseNumber;
  let mlsId;
  let stateOfOperation;

  if (canDisplayParticipantProfileData) {
    ({ licenseNumber, mlsId, stateOfOperation } = participant);
  } else {
    ({ licenseNumber, mlsId, stateOfOperation } = contact);
  }

  const state = stateOfOperation ? StateMap[stateOfOperation.toUpperCase()] : stateOfOperation;
  const name = canDisplayParticipantProfileData ? getParticipantName({ participant }) : getContactName({ contact });

  return [
    {
      label: 'Name',
      value: name,
      isEditable: true,
      fieldName: UpdateContactField.FIRST_NAME,
    },
    {
      label: 'License Number',
      value: licenseNumber,
      isEditable: true,
      fieldName: UpdateContactField.LICENSE_NUMBER,
    },
    {
      label: 'State of Operation',
      value: state || '',
      isEditable: true,
      fieldName: UpdateContactField.STATE_OF_OPERATION,
    },
    {
      label: 'MLS ID',
      value: mlsId,
      isEditable: true,
      fieldName: UpdateContactField.MLS_ID,
    },
  ];
}

export function getDataFieldsLenderContact(
  participant: TransactionParticipant,
  canDisplayParticipantProfileData: boolean,
): ContactFieldData[] {
  const contact = participant.contact as AllContactTypes;
  const { nmlsId } = contact;
  const name = canDisplayParticipantProfileData ? getParticipantName({ participant }) : getContactName({ contact });

  return [
    {
      label: 'Name',
      value: name,
      isEditable: true,
      fieldName: UpdateContactField.FIRST_NAME,
    },
    {
      label: 'NMLS ID',
      value: nmlsId,
      isEditable: true,
      fieldName: UpdateContactField.NMLS_ID,
    },
  ];
}

export function getDataFieldsIndividualContact(
  participant: TransactionParticipant,
  canDisplayParticipantProfileData: boolean,
): ContactFieldData[] {
  const name = canDisplayParticipantProfileData
    ? getParticipantName({ participant, partial: false })
    : getContactName({ contact: participant.contact as AllContactTypes, partial: false });

  return [
    {
      label: 'Name',
      value: name,
      isEditable: true,
      fieldName: UpdateContactField.FIRST_NAME,
    },
  ];
}

export function getDataFieldsEntityContact(
  participant: TransactionParticipant,
  canDisplayParticipantProfileData: boolean,
): ContactFieldData[] {
  let contactType;

  if (canDisplayParticipantProfileData) {
    contactType = participant.contactType;
  } else {
    contactType = participant.contact.contactType;
  }

  const name = canDisplayParticipantProfileData
    ? getParticipantName({ participant })
    : getContactName({ contact: participant.contact as AllContactTypes });

  return [
    {
      label: 'Name',
      value: name,
      isEditable: contactType !== ContactType.SETTLEMENT_COMPANY,
      fieldName: UpdateContactField.ENTITY_NAME,
    },
  ];
}

export const mapTransactionToContacts = (
  transaction: Transaction | null,
): Pick<TransactionParticipant, 'id' | 'roles' | 'contact'>[] | [] => {
  const transactionParticipants = transaction ? transaction.participants : [];

  return flatten(
    transactionParticipants.map((participant) => ({
      id: participant.id,
      roles: participant.roles,
      contact: participant.contact as AllContactTypes,
    })),
  );
};

export const isSettlementCompanyByValues = (values: NewContactSchema, state: State): boolean => {
  return (
    values.contactType === ContactType.SETTLEMENT_COMPANY ||
    state.selectedContactData.contactType === ContactType.SETTLEMENT_COMPANY
  );
};

export const isSettlementCompanyByContactType = (contactType: ContactType | string | undefined): boolean => {
  return contactType === ContactType.SETTLEMENT_COMPANY;
};
