import { parse, stringify } from 'query-string';
import { navigate, useLocation } from '@reach/router';

export type ParamsType = Record<string, unknown>;

export function useQueryParams(formatParams: Function = (values: unknown) => values) {
  const location = useLocation();

  const params = parse(location.search, { arrayFormat: 'comma' });

  return {
    currentParams: formatParams(params),
    navigateWithParams: (newParams: ParamsType) => navigateWithParams(params, newParams),
  };
}

export function filterOutEmptyParams(filters: ParamsType) {
  const filtersCopy = { ...filters };

  Object.keys(filtersCopy).forEach((key) => {
    const isEmptyFilterArray = Array.isArray(filtersCopy[key]) && (filtersCopy[key] as []).length === 0;
    const isEmptyValue = filtersCopy[key] === undefined || filtersCopy[key] === '';

    if (isEmptyFilterArray || isEmptyValue) {
      delete filtersCopy[key];
    }
  });

  return filtersCopy;
}

export const navigateWithParams = (currentParams: ParamsType, newParams: ParamsType) => {
  const filters = filterOutEmptyParams({
    ...currentParams,
    ...newParams,
  });

  const queryString = stringify(filters, { arrayFormat: 'comma' });

  void navigate(`?${queryString}`);
};
