import React, { FC } from 'react';
import { Box, Divider, Accordion, AccordionItem, AccordionHeader, AccordionPanel, Heading } from '@endpoint/blockparty';

interface AccordionSectionProps {
  dataTestId: string;
  isOpen: boolean;
  hasDivider: boolean;
  title: string;
  pb?: string;
  pt?: string;
  px?: string;
}

export const AccordionSection: FC<AccordionSectionProps> = ({
  dataTestId,
  isOpen = true,
  hasDivider = true,
  title,
  children,
  pb,
  pt,
  px,
}) => (
  <Box>
    <Box key={dataTestId} data-test-id={dataTestId}>
      <Accordion key={`${dataTestId}-accordion`} data-test-id={`${dataTestId}-accordion`}>
        <AccordionItem defaultIsOpen={isOpen} variant="ghost">
          <AccordionHeader hideDivider iconLeft p="space50">
            <Heading as="h6" fontWeight="semi" size="fontSize30">
              {title}
            </Heading>
          </AccordionHeader>
          <AccordionPanel pb={pb || 'space70'} pt={pt || 'space30'} px={px || 'space60'}>
            {children}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
    {hasDivider && <Divider data-test-id={`${dataTestId}-divider`} />}
  </Box>
);
