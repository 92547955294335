import React, { FC, useEffect, useState } from 'react';
import { Box, Flex, LayoutContent, LayoutSubContainer } from '@endpoint/blockparty';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';
import { SkeletonBasicInfo } from 'routes/Transaction/TransactionBasicInfo/SkeletonBasicInfo';
import { ErrorMessage } from 'components/ErrorMessage';
import { OpswareUser } from 'components/Auth/helpers';
import { NotAuthorized } from 'components/NotAuthorized';
import { NotFound } from 'components/NotFound';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';
import { AllContactTypes } from 'consts/contactDetails';

import { ContactData, GET_CONTACT } from './queries';
import { Tabs } from '../Tabs';
import { ContactInfo } from '../ContactInfo';

export interface ContactDetailsProps extends RouteComponentProps {
  contactId?: string;
}

export const ContactDetails: FC<ContactDetailsProps> = ({ contactId }) => {
  const [authError, setAuthError] = useState<boolean>(false);
  const { data: currentUserData, loading: opswareUserLoading } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER, {
    fetchPolicy: 'cache-only',
  });
  const {
    data: contactData,
    loading: contactLoading,
    error,
  } = useQuery<ContactData>(GET_CONTACT, {
    variables: {
      input: {
        id: contactId,
      },
    },
  });

  const hasCurrentUserResolved = !!currentUserData && currentUserData.me.firstName;

  const isValidAuthUser = !!currentUserData?.me.organization && currentUserData?.me.market;

  const contact = contactData?.contact as AllContactTypes;

  const showLoading = contactLoading || opswareUserLoading;

  const showNotAuthorizedPage = !isValidAuthUser || !hasCurrentUserResolved || authError;

  const showNotFoundPage = !contact && !error;

  useEffect(() => {
    if (error && error.graphQLErrors.length > 0) {
      if (error?.graphQLErrors[0].extensions?.code === 'FORBIDDEN') {
        setAuthError(true);
      }
    }
  }, [error]);

  if (showLoading) {
    return (
      <LayoutContent>
        <LayoutSubContainer p="space60">
          <SkeletonBasicInfo isRightPanelShown={false} />
        </LayoutSubContainer>
      </LayoutContent>
    );
  }

  if (showNotAuthorizedPage) {
    return (
      <Flex alignItems="center" height="calc(100vh - 56px)">
        <NotAuthorized />
      </Flex>
    );
  }

  if (showNotFoundPage) {
    return (
      <Flex alignItems="center" height="calc(100vh - 56px)">
        <NotFound />
      </Flex>
    );
  }

  return (
    <LayoutContent>
      <LayoutSubContainer p="space60">
        <Flex direction="row">
          {error && (
            <Box width="100%">
              <ErrorMessage />
            </Box>
          )}
          {contact && (
            <Flex direction="column" width="100%">
              <ContactInfo contact={contact} />
              <Tabs contact={contact} />
            </Flex>
          )}
        </Flex>
      </LayoutSubContainer>
    </LayoutContent>
  );
};
