import { gql } from '@apollo/client';
import {
  AgentContact,
  Contact,
  EntityContact,
  IndividualContact,
  LenderContact,
} from '@endpoint/opsware-bff-graphql-schema';

export const SEARCH_CONTACTS_BY_PARAMS = gql`
  query searchContactsByParams($input: SearchContactByParamsInput!) {
    searchContactsResult: searchContactsByParams(input: $input) {
      contacts {
        id
        phone
        email
        contactType
        ... on AgentContact {
          firstName
          middleName
          lastName
          licenseNumber
          mlsId
          stateOfOperation
        }
        ... on LenderContact {
          firstName
          middleName
          lastName
          nmlsId
        }
        ... on IndividualContact {
          firstName
          middleName
          lastName
        }
        ... on EntityContact {
          entityName
        }
      }
      pageIndex
      totalContacts
    }
  }
`;

export interface SearchContactsResultType {
  searchContactsResult: {
    contacts: (Contact | IndividualContact | LenderContact | AgentContact | EntityContact)[];
    pageIndex: number;
    totalContacts: number;
  };
}
