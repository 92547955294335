import { add, endOfDay, formatISO } from 'date-fns';
import { sum } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Card, LayoutContent, LayoutSubContent } from '@endpoint/blockparty';
import { CalendarDate } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { RouteComponentProps, useLocation } from '@reach/router';
import { OpswareUser } from 'components/Auth/helpers';
import { CalendarLoading } from 'components/CalendarLoading';
import { ErrorMessage } from 'components/ErrorMessage';
import { Layout } from 'components/Layout';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';
import { Page } from 'consts/segmentProtocols';
import { datePlusDays } from 'helpers/datetimeHelper';
import { trackPage } from 'helpers/utils/segment/segmentAnalytics';
import { CalendarContextProvider } from 'hooks/useCalendarContext';
import { useCalendarFilters } from 'helpers/calendar';

import { CalendarSubHeader } from './CalendarSubHeader';
import { CalendarLeftPanel } from './LeftPanel';
import { GET_ALL_CALENDAR_DATES, GetCalendarDates } from './queries';
import { WeekList } from './WeekList';

interface CalendarProps extends RouteComponentProps {}

export const Calendar: FC<CalendarProps> = () => {
  const { currentParams, navigateWithParams } = useCalendarFilters();
  const { search } = useLocation();
  const { data: currentUser } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);
  const hasMultiOrgAccess = currentUser?.me?.hasMultiOrganizationAccess;

  if (!search.length) {
    navigateWithParams({ showClosedTransactions: true, showOpenTransactions: true, showSignings: true });
  }

  useEffect(() => {
    trackPage<Page>('Calendar');
  }, []);

  const { date: startDate } = currentParams;
  const endDate = endOfDay(add(startDate, { days: 6 }));
  const subheaderDisplayDate = datePlusDays(startDate, 6);
  let organizationIds = Array.isArray(currentUser?.me?.organization)
    ? currentUser?.me?.organization
    : currentParams.organizationIds;

  if (!organizationIds || hasMultiOrgAccess) {
    organizationIds = currentParams.organizationIds;
  }

  const {
    data,
    loading: isLoading,
    error,
  } = useQuery<GetCalendarDates>(GET_ALL_CALENDAR_DATES, {
    variables: {
      filter: {
        startTime: formatISO(startDate),
        endTime: formatISO(endDate),
        showOpenTransactions: currentParams.showOpenTransactions,
        showClosedTransactions: currentParams.showClosedTransactions,
        showSignings: currentParams.showSignings,
        marketIds: currentParams.marketIds,
        organizationIds,
      },
    },
  });

  const closings: number =
    data && data.calendarDatesByDate
      ? sum(data.calendarDatesByDate.map((week: CalendarDate): number => week.transactionCalendarEvents.length))
      : 0;

  return (
    <CalendarContextProvider>
      <Layout>
        <LayoutContent>
          <CalendarLeftPanel initialDate={startDate} />
          <LayoutSubContent>
            <CalendarSubHeader endDate={subheaderDisplayDate} startDate={startDate} totalClosings={closings} />
            {isLoading && <CalendarLoading />}
            {error && (
              <Card m="space60">
                <ErrorMessage
                  description="Please contact Opsware product team for assistance."
                  title="Unable to load calendar"
                />
              </Card>
            )}
            {!isLoading && !error && data && data.calendarDatesByDate && (
              <WeekList calendarDates={data.calendarDatesByDate} />
            )}
          </LayoutSubContent>
        </LayoutContent>
      </Layout>
    </CalendarContextProvider>
  );
};
