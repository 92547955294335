import { gql } from '@apollo/client';

export const GET_MILESTONE_VALIDATION_ERRORS = gql`
  query milestoneValidationErrors($where: MilestoneValidationInput!) {
    milestoneValidationErrors(where: $where)
  }
`;

export interface GetMilestoneValidationErrors {
  milestoneValidationErrors: string[];
}
