import React, { FC } from 'react';
import { ContactType } from '@endpoint/opsware-bff-graphql-schema';
import { Card, Radio, Text, Divider, Flex } from '@endpoint/blockparty';
import { NewContactSchema, contactTypeOptions } from 'consts/createNewContact';
import { isAgentOrTC } from 'helpers/contacts';
import { formatPhoneNumber } from 'helpers/formatPhone';

interface ContactDetailRadioCardProps {
  data: NewContactSchema;
  handleChange: () => void;
  isSelected: boolean;
}

export const ContactDetailRadioCard: FC<ContactDetailRadioCardProps> = ({ data, handleChange, isSelected }) => {
  const hasLicencseNumber = isAgentOrTC(data.contactType);
  const hasNMLSId = data.contactType === ContactType.LOAN_OFFICER || data.contactType === ContactType.LOAN_PROCESSOR;
  const contactTypeOption = contactTypeOptions.find((option) => option.value === data.contactType);

  const contactName =
    data.entityName && data.entityName !== 'filler name' ? data.entityName : `${data.firstName} ${data.lastName}`;
  const formattedPhone = formatPhoneNumber(data.phone || '');

  return (
    <Card
      border="1px solid"
      borderColor={isSelected ? 'blue500' : 'carbon100'}
      data-test-id="contact-detail-radio-card"
      mb="space50"
      p="space50"
      variant="ghost"
    >
      <Flex flexDirection="column" pb="space40">
        <Radio dataTestId={`contact-radio-${data.id}`} label={contactName} value={data.id} onChange={handleChange}>
          <Text as="p" data-test-id="contact-name" fontWeight="semi" ml="space10">
            {contactName}
          </Text>
        </Radio>
        <Text ml="space70">{contactTypeOption?.label || '-'}</Text>
      </Flex>
      <Divider />
      {hasLicencseNumber && (
        <Flex data-test-id="license-box" pb="space20" pt="space40">
          <Text as="p" color="carbon500" width={92}>
            License:
          </Text>
          <Text as="p">{data.licenseNumber || '-'}</Text>
        </Flex>
      )}
      {hasNMLSId && (
        <Flex data-test-id="nmls-box" pb="space20" pt="space40">
          <Text as="p" color="carbon500" width={92}>
            NMLS ID:
          </Text>
          <Text as="p">{data.nmlsId || '-'}</Text>
        </Flex>
      )}
      <Flex data-test-id="email-box" pb="space20" pt={!hasLicencseNumber && !hasNMLSId ? 'space40' : 'space0'}>
        <Text color="carbon500" width={92}>
          Email:
        </Text>
        <Text as="p">{data.email || '-'}</Text>
      </Flex>
      <Flex data-test-id="phone-box">
        <Text as="p" color="carbon500" width={92}>
          Phone:
        </Text>
        <Text as="p">{formattedPhone || '-'}</Text>
      </Flex>
    </Card>
  );
};
