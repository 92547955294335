import { Market } from '@endpoint/opsware-bff-graphql-schema';

export interface FilterOptions {
  searchTerm?: string;
  filterOrganization: number[];
  selectedStatusOption: boolean[];
}

export function filterMarkets(options: FilterOptions, markets?: Market[]): Market[] | null {
  if (!markets) {
    return null;
  }

  let filteredMarkets = markets;

  if (options.searchTerm) {
    filteredMarkets = filteredMarkets.filter((market: Market) =>
      market.name.toLocaleLowerCase().includes(options.searchTerm!.toLocaleLowerCase()),
    );
  }

  if (options.selectedStatusOption.length > 0) {
    filteredMarkets = filteredMarkets.filter((market: Market) => options.selectedStatusOption.includes(market.active));
  }

  if (options.filterOrganization?.length) {
    filteredMarkets = filteredMarkets.filter((market: Market) =>
      options.filterOrganization?.includes(market.organizationId),
    );
  }

  return filteredMarkets;
}
