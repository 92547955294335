import { TransactionDisplayStatus } from '@endpoint/opsware-bff-graphql-schema';

type TransactionDisplayStatusMap = { [index in TransactionDisplayStatus]: string };

export const supportedStatuses: Array<TransactionDisplayStatus> = [
  TransactionDisplayStatus.OPEN,
  TransactionDisplayStatus.ORDER_PROCESSING,
  TransactionDisplayStatus.CLOSED,
  TransactionDisplayStatus.CANCELLED,
];

export const statusDisplayValueMap: TransactionDisplayStatusMap = {
  [TransactionDisplayStatus.OPEN]: 'Open',
  [TransactionDisplayStatus.ORDER_PROCESSING]: 'Order Processing',
  [TransactionDisplayStatus.CLOSED]: 'Closed',
  [TransactionDisplayStatus.CANCELLED]: 'Cancelled',
};
