import { gql } from '@apollo/client';
import {
  AgentContact,
  Contact,
  EntityContact,
  IndividualContact,
  LenderContact,
} from '@endpoint/opsware-bff-graphql-schema';
import { AllContactTypes } from 'consts/contactDetails';

export const GET_CONTACT = gql`
  query getContact($input: ContactWhereUniqueInput) {
    contact: getContact(input: $input) {
      id
      contactType
      email
      phone
      isOnboarded
      isDeactivated
      organizationId
      notificationPreferences
      createdAt
      ... on AgentContact {
        firstName
        middleName
        lastName
        suffix
        licenseNumber
        stateOfOperation
        mlsId
      }
      ... on LenderContact {
        firstName
        middleName
        lastName
        suffix
        nmlsId
      }
      ... on IndividualContact {
        firstName
        middleName
        lastName
        suffix
      }
      ... on EntityContact {
        entityName
      }
    }
  }
`;

export interface ContactData {
  contact: Contact | LenderContact | AgentContact | IndividualContact | EntityContact;
}
