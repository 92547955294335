import React, { FC, useContext } from 'react';
import { Flex, Heading } from '@endpoint/blockparty';
import { NoteEntityType, TransactionNote } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { InternalNote } from 'components/InternalNote';
import { TransactionContext } from 'routes/Transaction';
import { AddNote } from 'components/AddNote';

interface BasicInfoRightPanelProps {
  notes?: TransactionNote[];
}

export const BasicInfoRightPanel: FC<BasicInfoRightPanelProps> = ({ notes }) => {
  const { id: transactionId } = useContext(TransactionContext);

  return (
    <Flex flexDirection="column" height="100%" maxWidth="376px" minWidth="376px">
      <Heading as="h5" mb="space60" size="fontSize40">
        Internal Notes
      </Heading>
      <AddNote entityType={NoteEntityType.TRANSACTION} isCalendarPage={false} transactionId={transactionId} />
      <Flex data-test-id="internal-notes" flexDirection="column" height="100%">
        {notes && notes.length > 0
          ? notes.map((note: TransactionNote) => (
              <InternalNote
                key={note.id}
                entityType={NoteEntityType.TRANSACTION}
                isBasicInfo
                note={note}
                transactionId={transactionId}
              />
            ))
          : null}
      </Flex>
    </Flex>
  );
};
