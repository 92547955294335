import { OpsSquad } from '@endpoint/opsware-bff-graphql-schema';

type TaskDisplaySquadMap = { [index in OpsSquad]: string };

export const supportedSquads: Array<OpsSquad> = [OpsSquad.GLOBAL];

export const squadDisplayValueMap: TaskDisplaySquadMap = {
  [OpsSquad.GLOBAL]: 'Global',
};

export const defaultSquads: OpsSquad[] = [OpsSquad.GLOBAL];
