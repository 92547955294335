import React, { FC, useCallback } from 'react';
import { Box, Text, Flex, Icon, Popover, PopoverTrigger, usePopoverState } from '@endpoint/blockparty';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import { Milestone, MilestoneStatus, TaskStatus, PinnedItem } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { mapStatusText } from 'helpers/statusHelper';
import { milestoneStatusMap } from 'consts/milestoneConsts';
import { pinnedItemToIcon, pinnedItemToText } from 'consts/pinnedItemConsts';
import { v4 } from 'uuid';

import { MilestoneAccordionPopover } from '../MilestoneAccordionPopover';
import { PinnedItemPopover } from './PinnedItemPopover';
import { getPinnedItemRole } from './PinnedItemPopover/pinnedItemHelper';

interface MilestoneAccordionHeaderProps {
  milestone: Milestone;
}

export const MilestoneAccordionHeader: FC<MilestoneAccordionHeaderProps> = ({ milestone }) => {
  const popover = usePopoverState({
    placement: 'bottom-start',
  });
  const notWaivedTasks = milestone.tasks.filter((task) => task.status !== TaskStatus.WAIVED);
  const taskRemaining = notWaivedTasks.filter((task) => task.status !== TaskStatus.COMPLETED).length;

  const handleMouseEnter = useCallback(() => popover.show(), [popover]);

  const handleMouseLeave = useCallback(() => popover.hide(), [popover]);

  return (
    <Box>
      <Box>
        <Text fontWeight="semi" size="fontSize20">
          {milestone.name}
        </Text>
      </Box>
      <Flex alignItems="center" mt="space30">
        <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <PopoverTrigger {...popover}>
            <Flex alignItems="center">
              {milestone.status === MilestoneStatus.COMPLETE && (
                <Icon color="carbon500" data-test-id="milestone-icon-complete" name="CheckCircle" size="medium" />
              )}
              {milestone.status !== MilestoneStatus.COMPLETE && (
                <Icon
                  color="carbon500"
                  data-test-id="milestone-icon-incomplete"
                  name="CheckCircleOutline"
                  size="medium"
                />
              )}
              <Text color="carbon500" ml="space30">
                {`M${milestone.position} - ${mapStatusText(milestone.status, milestoneStatusMap)}`}
              </Text>
            </Flex>
          </PopoverTrigger>
          <Popover {...popover} aria-label="Edit Milestone">
            <MilestoneAccordionPopover milestone={milestone} />
          </Popover>
        </Box>

        <HeaderPinnedItems milestonePosition={milestone.position} pinnedItems={milestone.pinnedItems} />
        <Icon color="carbon200" name="Bullet" size="medium" />
        <Text color="carbon500">{`${taskRemaining} tasks remaining`}</Text>
      </Flex>
    </Box>
  );
};

interface HeaderPinnedItemsProps {
  pinnedItems: PinnedItem[];
  milestonePosition: number;
}

export const HeaderPinnedItems: FC<HeaderPinnedItemsProps> = ({ pinnedItems, milestonePosition }) => {
  const popover = usePopoverState({
    placement: 'bottom-start',
  });
  const handleMouseEnter = useCallback(() => popover.show(), [popover]);

  const handleMouseLeave = useCallback(() => popover.hide(), [popover]);

  return (
    <>
      {pinnedItems.map((pinnedItem) => {
        const pinnedItemRole = getPinnedItemRole(pinnedItem);

        return (
          // @ts-ignore - to be fixed in BP
          <Box key={v4()} onMouseEnter={handleMouseEnter} onMouseLeave={() => handleMouseLeave()}>
            <PopoverTrigger {...popover}>
              <Flex alignItems="center">
                <Icon color="carbon300" name="Bullet" size="medium" />
                <Icon
                  color="carbon500"
                  name={mapStatusText(pinnedItemRole, pinnedItemToIcon) as IconNames}
                  size="medium"
                />
                <Text color="carbon500" ml="space30">
                  {mapStatusText(pinnedItemRole, pinnedItemToText)}
                </Text>
              </Flex>
            </PopoverTrigger>
            <Popover {...popover} aria-label="Pinned Item Info">
              <PinnedItemPopover milestonePosition={milestonePosition} pinnedItem={pinnedItem} />
            </Popover>
          </Box>
        );
      })}
    </>
  );
};
