import React, { FC } from 'react';
import { Checkbox, CheckboxGroup, Text } from '@endpoint/blockparty';
import { useField } from 'formik';
import { v4 } from 'uuid';
import { FormikItemValue, TaskStepOption } from 'helpers/dynamicForm/types';

interface TaskStepItemCheckboxGroupProps {
  name: string;
  title?: string;
  options?: TaskStepOption[];
}

export const TaskStepItemCheckboxGroup: FC<TaskStepItemCheckboxGroupProps> = ({ name, title, options }) => {
  const [field, meta, helpers] = useField<FormikItemValue>({ name });
  const values: string[] | undefined = field.value?.value as string[] | undefined;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = values || [];

    if (value && value.includes(event.target.value)) {
      value.splice(value.indexOf(event.target.value), 1);
    } else {
      value.push(event.target.value);
    }

    helpers.setValue({ value });
  };

  return (
    <>
      <Text size="fontSize20">{title}</Text>
      <CheckboxGroup marginLeft="space20" marginTop="space40">
        {options?.map((option) => {
          return (
            <Checkbox
              key={v4()}
              isChecked={values?.includes(option.value)}
              name={field.name}
              value={option.value}
              onChange={handleOnChange}
            >
              {option.label}
            </Checkbox>
          );
        })}
      </CheckboxGroup>
    </>
  );
};
