import React, { FC } from 'react';
import { formatField } from 'helpers/formatText';
import { createDateFieldsOfInterest, BasicInfoFieldsData } from 'helpers/transaction';
import { Grid, Text, Box, Divider, Flex } from '@endpoint/blockparty';
import { EditTransactionBasicInfoEditButton } from 'components/EditTransactionBasicInfo/EditTransactionBasicInfoEditButton';
import { useBasicInfoShowEditButton } from 'hooks/useBasicInfoShowEditButton';
import { useEditTransactionBasicInfoContext } from 'hooks/useEditTransactionBasicInfoContext';

import { BasicInfoDatesProps } from '.';

export const Dates: FC<BasicInfoDatesProps> = ({ transaction }) => {
  const dateFieldsOfInterest = createDateFieldsOfInterest(transaction);
  const { toggleIsBeingEdited, setFocusField } = useEditTransactionBasicInfoContext();
  const showEditButton = useBasicInfoShowEditButton(transaction);

  return (
    <Grid
      columnGap="space60"
      data-test-id="basic-info-dates-display"
      pb="space50"
      pt="space30"
      px="space50"
      rowGap="space40"
      templateColumns="repeat(2, minmax(150px, 1fr))"
    >
      {dateFieldsOfInterest.map(({ label, value }: BasicInfoFieldsData, index) => {
        const fieldValue = formatField(value);
        const isOddNumFields = dateFieldsOfInterest.length % 2;
        const showDivider = index < dateFieldsOfInterest.length - (isOddNumFields ? 1 : 2);

        return (
          <Box key={label}>
            <Flex direction="row">
              <Text as="p" color="passive" data-test-id={`${label}-label`} mb="space30" size="fontSize10" width="100%">
                {label}
              </Text>

              {label !== 'Contract Received Date' && label !== 'Borrower Signing Date' && showEditButton && (
                <EditTransactionBasicInfoEditButton
                  fieldName={label}
                  setFocusField={setFocusField}
                  toggleEditing={toggleIsBeingEdited}
                />
              )}
            </Flex>
            <Text as="p" data-test-id={`${label}-value`} mb="space40">
              {fieldValue}
            </Text>

            {showDivider && <Divider />}
          </Box>
        );
      })}
    </Grid>
  );
};

Dates.displayName = 'Dates';
