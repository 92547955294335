import React, { FC } from 'react';
import { AllContactTypes } from 'consts/contactDetails';
import { isEmailOffline, maskPhone } from 'helpers/updateContact';
import { useEditContactDetailsProvider } from 'hooks/useEditContactDetailsProvider';
import { Box, Grid, Heading, Input, InputErrorMessage, InputGroup, InputLabel } from '@endpoint/blockparty';
import { FormikCleaveInputGroup } from 'components/FormikCleaveInputGroup';
import { UpdateContactField } from 'consts/updateContact';
import { Field, useFormikContext } from 'formik';
import { UpdateContactInput } from '@endpoint/opsware-bff-graphql-schema';

interface ContactInformationProps {
  canEditOnboardedContactProps: boolean;
  contact: AllContactTypes;
}

export const ContactInformationFields: FC<ContactInformationProps> = ({ canEditOnboardedContactProps, contact }) => {
  const { state } = useEditContactDetailsProvider();
  const { errors, touched, values } = useFormikContext<UpdateContactInput>();
  const { email, isOnboarded } = contact;
  const canEditEmail = isEmailOffline(email!) || !isOnboarded || canEditOnboardedContactProps;
  const canEditPhone = !isOnboarded || canEditOnboardedContactProps;

  return (
    <Box pb="space50" width="100%">
      <Heading backgroundColor="slate0" p="space40" size="fontSize30">
        Contact Information
      </Heading>

      <Grid
        columnGap="space60"
        px="space40"
        py="space30"
        rowGap="space30"
        templateColumns="repeat(2, minmax(150px, 1fr))"
      >
        <FormikCleaveInputGroup
          autoFocus={state.selectedField === UpdateContactField.PHONE}
          groupId={UpdateContactField.PHONE}
          isDisabled={!canEditPhone}
          label="Phone Number"
          mask={maskPhone}
          mb="space50"
          name={UpdateContactField.PHONE}
          type="text"
          value={values.phoneNumber}
        />
        <InputGroup
          groupId={UpdateContactField.EMAIL}
          isDisabled={!canEditEmail}
          isInvalid={touched.email && !!errors.email}
        >
          <InputLabel>Email *</InputLabel>
          <Field
            as={Input}
            autoComplete="none"
            autoFocus={state.selectedField === UpdateContactField.EMAIL}
            data-test-id="email-input"
            inputId={UpdateContactField.EMAIL}
            name={UpdateContactField.EMAIL}
            placeholder="Email"
            type="text"
            value={values.email}
          />
          <InputErrorMessage>{errors.email}</InputErrorMessage>
        </InputGroup>
      </Grid>
    </Box>
  );
};
