import React, { ChangeEvent, FC, FormEvent } from 'react';
import { Icon, Input, InputElemGroup, InputLeftElement, Box } from '@endpoint/blockparty';

interface SearchInputFormProps {
  disabled: boolean;
  handleSubmit: () => void;
  onSearchTermChange: (searchTerm: string) => void;
  value: string | undefined;
  placeholder: string;
  width?: string;
}

export const SearchInputForm: FC<SearchInputFormProps> = ({
  disabled,
  handleSubmit,
  onSearchTermChange,
  placeholder,
  value = undefined,
  width = '33%',
}) => (
  <Box width={width}>
    <form
      onSubmit={(event: FormEvent<HTMLElement>) => {
        handleSubmit();
        event.preventDefault();
      }}
    >
      <InputElemGroup>
        <InputLeftElement>
          <Icon color="carbon600" name="Search" size="large" />
        </InputLeftElement>
        <Input
          isDisabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            onSearchTermChange(event.target.value);
          }}
        />
      </InputElemGroup>
    </form>
  </Box>
);

Input.displayName = 'Input';
SearchInputForm.displayName = 'SearchInput';
