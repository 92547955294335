import { gql } from '@apollo/client';

export const GET_OPS_TASKS = gql`
  query opsTasks($filter: OpsTasksFilter!) {
    opsTasks(filter: $filter) {
      pageIndex
      totalItems
      items {
        id
        assigneeId
        assigneeName
        autoAssignTask
        taskTitle
        status
        squad
        transaction {
          id
          fileNum
        }
        isOverdue
        dueDateTime
        createdOn
      }
    }
  }
`;
