import React, { FC } from 'react';
import { Contact } from '@endpoint/opsware-bff-graphql-schema';
import { useNewContactProvider, ActionKind } from 'hooks/useContactProvider';
import { selectComp, Box, Divider, Icon, Text } from '@endpoint/blockparty';
import { NewContactStep } from 'consts/createNewContact';
import { OptionProps, MenuProps } from 'react-select';
import { useFormikContext } from 'formik';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';

export const Menu: FC<MenuProps<OptionProps<Contact, false>, false>> = ({ children, ...props }) => {
  const { dispatch, state } = useNewContactProvider();
  const { handleReset } = useFormikContext();

  const { options } = props;

  const hasOptions = options.length > 1;

  const handleNewContactClick = () => {
    dispatch({
      type: ActionKind.SET_CONTACT_STEP,
      payload: { ...state, newContactStep: NewContactStep.ADD_CONTACT },
    });

    handleReset();
    trackAnalytics('New Contact Clicked', {});
  };

  return (
    // @ts-ignore
    <selectComp.Menu {...props}>
      <>
        {children}
        <Box mt={hasOptions ? 'space0' : '-8px'}>
          {hasOptions ? <Divider /> : null}
          <Box
            aria-label="new-contact-button"
            cursor="pointer"
            p="space50"
            role="button"
            onClick={handleNewContactClick}
          >
            <Icon color="carbon500" marginRight="space40" name="Add" size="24px" />
            <Text>New Contact</Text>
          </Box>
        </Box>
      </>
    </selectComp.Menu>
  );
};
