import React, { createContext, FC, useMemo, useContext } from 'react';
import { Market } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { useQuery } from '@apollo/client';
import { GET_MARKETS_AND_ORGANIZATIONS, MarketsAndOrganizations } from 'consts/getMarketsAndOrganizationsQuery';

export type CalendarContextType = {
  tableFundingMarkets: Market[];
};

export const CalendarContext = createContext<CalendarContextType>({
  tableFundingMarkets: [],
});

export const CalendarContextProvider: FC = ({ children }) => {
  const { data: marketsData } = useQuery<MarketsAndOrganizations>(GET_MARKETS_AND_ORGANIZATIONS, {
    fetchPolicy: 'cache-only',
  });

  const calendarProviderValue = useMemo(
    () => ({
      tableFundingMarkets: marketsData?.markets.filter((market) => market.fundingType === 'TABLE') || [],
    }),
    [marketsData],
  );

  return <CalendarContext.Provider value={calendarProviderValue}>{children}</CalendarContext.Provider>;
};

export function useCalendarContext() {
  const context = useContext(CalendarContext);

  if (context === undefined) {
    throw new Error('useCalendarContext must be used within a Provider');
  }

  return context;
}
