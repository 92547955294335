import React from 'react';
import { Box, Card, Flex, SkeletonBlock, SkeletonText } from '@endpoint/blockparty';

export function SkeletonContactDetails(): React.ReactElement {
  return (
    <>
      <Box mr="space60" width="100%">
        <Card display="flex" mb="space60" p="space60" variant="shadow">
          <Box mr="space60">
            <SkeletonBlock size={80} />
          </Box>
          <Box>
            <SkeletonBlock mb="space60" />
            <Flex flexDirection="column" mb="space60" width="200px">
              <SkeletonText lines={1} />
            </Flex>
            <Flex flexDirection="column" mb="space60" width="200px">
              <SkeletonText />
            </Flex>
            <Flex>
              <SkeletonBlock mr="space50" />
              <SkeletonBlock />
            </Flex>
          </Box>
        </Card>
      </Box>
      {/* <Box minWidth="376px">
        <Flex>
          <SkeletonBlock mr="space50" />
          <SkeletonBlock />
        </Flex>
        <SkeletonText />
      </Box> */}
    </>
  );
}
