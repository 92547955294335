import React, { FC } from 'react';
import { Button } from '@endpoint/blockparty';
import { IconButtonWriteRequired } from 'components/IconButtonWriteRequired';

interface EditFieldButtonProps {
  handleClick: () => void;
  label: string;
}

export const EditFieldButton: FC<EditFieldButtonProps> = ({ handleClick, label }) => {
  return (
    <Button aria-label={`Edit ${label}`} size="none" variant="unstyled" variantColor="carbon" onClick={handleClick}>
      <IconButtonWriteRequired color="carbon200" dataTestId={`edit-${label}`} name="Edit" size="medium" />
    </Button>
  );
};
