import React, { FC } from 'react';
import {
  DrawerActionBar,
  DrawerCloseButton,
  DrawerContent,
  Drawer,
  DrawerSection,
  Flex,
  Icon,
  Stack,
  Text,
} from '@endpoint/blockparty';

interface IconHelpDrawerProps {
  isOpen: boolean;
  onClose(): void;
}

export const IconHelpDrawer: FC<IconHelpDrawerProps> = ({ isOpen, onClose }) => (
  <Drawer data-test-id="icon-help-drawer" id="icon-help-drawer" isOpen={isOpen} onClose={onClose}>
    <DrawerContent>
      <DrawerActionBar>
        <DrawerCloseButton />
      </DrawerActionBar>
      <Flex flex={1} flexDirection="column">
        <DrawerSection pb="space40">
          <Text as="h3" data-test-id="icon-code-help-drawer-title" fontWeight="semi" size="fontSize60">
            Icon Code
          </Text>
        </DrawerSection>
        <DrawerSection data-test-id="icon-code-status-drawer">
          <Stack spacing="space50">
            <Text as="h4" fontWeight="semi">
              Status
            </Text>
            <Flex alignItems="center">
              <Icon color="blue500" mr="space40" name="PinOutline" size="20px" />
              <Text>No Signings Scheduled (Refi)</Text>
            </Flex>
            <Flex alignItems="center">
              <Icon color="blue500" mr="space40" name="PinPerson" size="20px" />
              <Text>All Signings Scheduled (Refi)</Text>
            </Flex>
            <Flex alignItems="center">
              <Icon color="blue500" mr="space40" name="PinPairOutline" size="20px" />
              <Text>No Signings Scheduled (Sale)</Text>
            </Flex>
            <Flex alignItems="center">
              <Icon color="blue500" mr="space40" name="PinPairFirst" size="20px" />
              <Text>Seller Signing Scheduled (Sale)</Text>
            </Flex>
            <Flex alignItems="center">
              <Icon color="blue500" mr="space40" name="PinPairSecond" size="20px" />
              <Text>Buyer Signing Scheduled (Sale)</Text>
            </Flex>
            <Flex alignItems="center">
              <Icon color="blue500" mr="space40" name="PinPairBoth" size="20px" />
              <Text>All Signings Scheduled (Sale)</Text>
            </Flex>
            <Flex alignItems="center">
              <Icon color="blue500" mr="space40" name="PaperOutline" size="20px" />
              <Text>Released To Record</Text>
            </Flex>
            <Flex alignItems="center">
              <Icon color="blue500" mr="space40" name="PaperCheck" size="20px" />
              <Text>Recording Complete</Text>
            </Flex>
            <Flex alignItems="center">
              <Icon color="mist500" mr="space40" name="DollarSignCircle" size="20px" />
              <Text>Transaction Disbursed</Text>
            </Flex>
            <Flex alignItems="center">
              <Icon color="marigold500" mr="space40" name="CircleSmall" size="20px" />
              <Text>Signing Event</Text>
            </Flex>
          </Stack>
        </DrawerSection>
      </Flex>
    </DrawerContent>
  </Drawer>
);

Icon.displayName = 'Icon';
IconHelpDrawer.displayName = 'IconHelpDrawer';
