import { Flex } from '@endpoint/blockparty';
import React, { FC } from 'react';

export const PlatformLogoSeparatorDecoration: FC = ({ children }) => {
  return (
    <Flex
      alignItems="center"
      borderColor="carbon900"
      borderRight="1px solid"
      boxShadow="1px -1px 2px #4F586F;"
      height="100%"
      textAlign="left"
    >
      {children}
    </Flex>
  );
};
