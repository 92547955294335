import { Milestone, TaskStatus, TodoStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { some } from 'lodash';

export const filterMilestonesByTaskStatus = (milestones: Milestone[], statuses: TaskStatus[]): Milestone[] => {
  if (statuses.length === 0) {
    return milestones;
  }

  return milestones.map((milestone) => ({
    ...milestone,
    tasks: milestone.tasks.filter((task) => statuses.includes(task.status)),
  }));
};

export const filterMilestonesByTodoStatus = (milestones: Milestone[], statuses: TodoStatus[]): Milestone[] => {
  if (statuses.length === 0) {
    return milestones;
  }

  return milestones.map((milestone) => ({
    ...milestone,
    tasks: milestone.tasks.filter((task) =>
      some(
        task.todos.map((todo) =>
          some((todo.assignments || []).map((assignment) => statuses.includes(assignment.status))),
        ),
      ),
    ),
  }));
};
