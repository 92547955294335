import { Box, Flex, Input, InputErrorMessage, InputGroup, InputLabel, Select } from '@endpoint/blockparty';
import { OcrConfidenceScoreText } from 'components/OcrConfidenceScoreText';
import { Field, useFormikContext } from 'formik';
import React, { FC, useMemo } from 'react';
import { useStateOptions } from 'hooks/useStateOptions';
import { OrganizationType } from 'consts/organizationConsts';
import { TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { OpenOrderDrawerForm, OpenOrderDrawerFormType } from 'helpers/openOrder/openOrderFormTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { SearchAddress } from './SearchAddress';

export const PropertyAddressForm: FC = () => {
  const { errors, setFieldValue, values, touched, initialValues } = useFormikContext<OpenOrderDrawerForm>();
  const propertyErrors = errors.property?.address;
  const STATE_OPTIONS = useStateOptions(values.organization as OrganizationType);

  const { isAddressServiceEnabled } = useFlags();

  const isManualAddressEntrySelected = values.manualAddress;

  const isManualFormType = values.formType === OpenOrderDrawerFormType.MANUAL;

  const showSearchAddressInput = !!isAddressServiceEnabled && !!isManualFormType && !isManualAddressEntrySelected;

  const isStateSelectDisabled = !values.organization || values?.transactionStatus === TransactionType.PRELISTING;

  const value = useMemo(() => {
    return STATE_OPTIONS.find((option) => option.value === values.property.address.state);
  }, [STATE_OPTIONS, values.property.address.state]);

  const enterAddressManually = () => {
    setFieldValue('manualAddress', true);
  };

  return (
    <>
      <InputGroup
        data-test-id="open-order-drawer-street1"
        groupId="street1"
        isInvalid={touched.property?.address?.street1 && !!propertyErrors?.street1}
        pb="space30"
        pt="space50"
      >
        {showSearchAddressInput ? (
          <SearchAddress enterAddressManually={enterAddressManually} />
        ) : (
          <>
            <Flex flexDirection="row" justifyContent="space-between">
              <InputLabel>Address *</InputLabel>
              {values.property?.address?.street1 && values.ocrConfidenceScores?.street1 && (
                <OcrConfidenceScoreText
                  currentInputText={values.property.address.street1}
                  originalInputText={initialValues.property?.address?.street1}
                  value={values.ocrConfidenceScores?.street1}
                />
              )}
            </Flex>
            <Field as={Input} autoComplete="none" name="property.address.street1" />
            {touched.property?.address?.street1 && propertyErrors?.street1 && (
              <InputErrorMessage>{propertyErrors?.street1}</InputErrorMessage>
            )}
          </>
        )}
      </InputGroup>

      <InputGroup data-test-id="open-order-drawer-street2" groupId="street2 " pb="space30" pt="space50">
        <Flex flexDirection="row" justifyContent="space-between">
          <InputLabel>Apt, unit, etc.</InputLabel>
          {values.property?.address?.street2 && values.ocrConfidenceScores?.street2 && (
            <OcrConfidenceScoreText
              currentInputText={values.property.address.street2}
              originalInputText={initialValues.property?.address?.street2}
              value={values.ocrConfidenceScores?.street2}
            />
          )}
        </Flex>
        <Field as={Input} autoComplete="none" name="property.address.street2" />
      </InputGroup>

      <InputGroup
        data-test-id="open-order-drawer-city"
        groupId="city"
        isInvalid={touched.property?.address?.city && !!propertyErrors?.city}
        pb="space30"
        pt="space50"
      >
        <Flex flexDirection="row" justifyContent="space-between">
          <InputLabel>City *</InputLabel>
          {values.property?.address?.city && values.ocrConfidenceScores?.city && (
            <OcrConfidenceScoreText
              currentInputText={values.property.address.city}
              originalInputText={initialValues.property?.address?.city}
              value={values.ocrConfidenceScores?.city}
            />
          )}
        </Flex>
        <Field as={Input} autoComplete="none" name="property.address.city" />
        {propertyErrors?.city && <InputErrorMessage>{propertyErrors?.city}</InputErrorMessage>}
      </InputGroup>

      <Flex width="100%">
        <InputGroup
          data-test-id="open-order-drawer-state-selection"
          flex={1}
          groupId="property.address.state"
          isInvalid={touched.property?.address?.state && !!propertyErrors?.state}
          pb="space30"
          pt="space50"
        >
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel htmlFor="property.address.state">State *</InputLabel>
            {values.property?.address?.state && values.ocrConfidenceScores?.state && (
              <OcrConfidenceScoreText
                currentInputText={values.property.address.state}
                originalInputText={initialValues.property?.address?.state}
                value={values.ocrConfidenceScores?.state}
              />
            )}
          </Flex>
          <Field
            as={Select}
            inputId="property.address.state"
            isDisabled={isStateSelectDisabled}
            isSearchable
            name="property.state"
            options={STATE_OPTIONS}
            value={value || ''}
            onChange={(state: { value: '' }) => {
              setFieldValue('property.address.state', state.value);
            }}
          />
          {touched?.property?.address?.state && !!propertyErrors?.state && (
            <InputErrorMessage>{propertyErrors?.state}</InputErrorMessage>
          )}
        </InputGroup>
        <Box width={24} />
        <InputGroup
          data-test-id="open-order-drawer-zip"
          flex={1}
          groupId="zip"
          isInvalid={touched.property?.address?.zip && !!propertyErrors?.zip}
          marginRight="5px"
          pb="space30"
          pt="space50"
        >
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel>Zip Code *</InputLabel>
            {values.property?.address?.zip && values.ocrConfidenceScores?.zip && (
              <OcrConfidenceScoreText
                currentInputText={values.property.address.zip}
                originalInputText={initialValues.property?.address?.zip}
                value={values.ocrConfidenceScores?.zip}
              />
            )}
          </Flex>
          <Field as={Input} autoComplete="none" name="property.address.zip" />
          {propertyErrors?.zip && <InputErrorMessage>{propertyErrors?.zip}</InputErrorMessage>}
        </InputGroup>
      </Flex>
      <InputGroup
        data-test-id="open-order-drawer-county"
        groupId="county"
        isInvalid={touched.property?.address?.county && !!propertyErrors?.county}
        pb="space30"
        pt="space50"
      >
        <Flex flexDirection="row" justifyContent="space-between">
          <InputLabel>County *</InputLabel>
          {values.property?.address?.county && values.ocrConfidenceScores?.county && (
            <OcrConfidenceScoreText
              currentInputText={values.property.address.county}
              originalInputText={initialValues.property?.address?.county}
              value={values.ocrConfidenceScores?.county}
            />
          )}
        </Flex>
        <Field as={Input} autoComplete="none" name="property.address.county" />
        {propertyErrors?.county && <InputErrorMessage>{propertyErrors?.county}</InputErrorMessage>}
      </InputGroup>
      <InputGroup data-test-id="open-order-drawer-parcel" groupId="parcel" pb="space60" pt="space50">
        <Flex flexDirection="row" justifyContent="space-between">
          <InputLabel>Parcel Number</InputLabel>
          {values.property?.parcelNumber && values.ocrConfidenceScores?.parcelNumber && (
            <OcrConfidenceScoreText
              currentInputText={values.property.parcelNumber}
              originalInputText={initialValues.property?.parcelNumber}
              value={values.ocrConfidenceScores?.parcelNumber}
            />
          )}
        </Flex>
        <Field as={Input} autoComplete="none" name="property.parcelNumber" />
      </InputGroup>
    </>
  );
};
