import { CancellationReason, TransactionType } from '@endpoint/opsware-bff-graphql-schema';

type CancellationReasonMap = { [index in CancellationReason]: string };

export const cancellationReasonMap: CancellationReasonMap = {
  [CancellationReason.DEAL_LOST]: 'Deal Lost',
  [CancellationReason.EARNEST_MONEY_NOT_DELIVERED]: 'Earnest Money Not Delivered',
  [CancellationReason.FAILED_FINANCING]: 'Failed Financing',
  [CancellationReason.FAILED_INSPECTION]: 'Failed Inspection',
  [CancellationReason.FAILED_WALKTHROUGH]: 'Failed Walkthrough',
  [CancellationReason.OPENED_IN_ERROR]: 'Opened in Error',
  [CancellationReason.OTHER]: 'Other',
};

export const saleCancellationReasons: Array<CancellationReason> = [
  CancellationReason.OPENED_IN_ERROR,
  CancellationReason.EARNEST_MONEY_NOT_DELIVERED,
  CancellationReason.FAILED_FINANCING,
  CancellationReason.FAILED_INSPECTION,
  CancellationReason.FAILED_WALKTHROUGH,
  CancellationReason.OTHER,
];

export const prelistingCancellationReasons: Array<CancellationReason> = [
  CancellationReason.DEAL_LOST,
  CancellationReason.OPENED_IN_ERROR,
  CancellationReason.OTHER,
];

export const refiCancellationReasons: Array<CancellationReason> = [
  CancellationReason.FAILED_FINANCING,
  CancellationReason.OPENED_IN_ERROR,
  CancellationReason.OTHER,
];

const mapToLabelValue = (cancellationReason: CancellationReason) => {
  return { label: cancellationReasonMap[cancellationReason], value: cancellationReason };
};

const saleTransactionCancellationReasons = saleCancellationReasons.map((cancellationReason) =>
  mapToLabelValue(cancellationReason),
);

const prelistingTransactionCancellationReasons = prelistingCancellationReasons.map((cancellationReason) =>
  mapToLabelValue(cancellationReason),
);

const refinanceTransactionCancellationReasons = refiCancellationReasons.map((cancellationReason) =>
  mapToLabelValue(cancellationReason),
);

export const getSelectOptionsByTransactionType = (type: TransactionType) => {
  if (type === TransactionType.REFINANCE) return refinanceTransactionCancellationReasons;
  if (type === TransactionType.PRELISTING) return prelistingTransactionCancellationReasons;

  return saleTransactionCancellationReasons;
};
