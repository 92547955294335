import React, { Dispatch, FC, SetStateAction } from 'react';
import { Modal, ModalContent, Text, Button, ModalSection, ModalFooter } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';
import { EditTransactionBasicInfoDrawer } from 'routes/Transaction/TransactionBasicInfo/types/EditTransactionBasicInfoDrawer';

interface EditTransactionBasicInfoDiscardChangesModalProps {
  isModalOpen: boolean;
  setFormEditing: Dispatch<SetStateAction<boolean>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditTransactionBasicInfoDiscardChangesModal: FC<EditTransactionBasicInfoDiscardChangesModalProps> = ({
  isModalOpen,
  setFormEditing,
  setIsModalOpen,
}) => {
  const { handleReset } = useFormikContext<EditTransactionBasicInfoDrawer>();

  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalContent zIndex={2}>
        <ModalSection>
          <Text as="h1" fontWeight="semi" size="fontSize30">
            Discard unsaved changes?
          </Text>
          <Text as="p" pt="space50" size="fontSize20">
            Progress will not be saved
          </Text>
        </ModalSection>
        <ModalFooter>
          <Button
            data-test-id="document-review-modal-keep-editing"
            mr="space50"
            variant="outline"
            onClick={() => setIsModalOpen(false)}
          >
            Keep editing
          </Button>
          <Button
            data-test-id="document-review-modal-cancel"
            onClick={() => {
              handleReset();
              setIsModalOpen(false);
              setFormEditing(false);
            }}
          >
            Discard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
