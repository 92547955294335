import { InMemoryCache, makeVar } from '@apollo/client';
import { OpswareUser, emptyOpswareUser } from 'components/Auth/helpers';
import { UploadDocument, DocumentUpload } from 'consts/uploadDocumentsConsts';

import { BreadCrumbLink, UploadUnassignedDocument } from './typeDefs';

export const meVar = makeVar<OpswareUser>(emptyOpswareUser());
export const breadCrumbLinksVar = makeVar<BreadCrumbLink[]>([{ label: '', path: '' }]);
const initialUploadDocument: UploadDocument = { uploadDrawerOpen: false, uploads: [] as DocumentUpload[] };

export const uploadDocumentVar = makeVar<UploadDocument>(initialUploadDocument);
export const uploadUnassignedDocumentVar = makeVar<UploadUnassignedDocument>({ uploads: [] });
export const cache = new InMemoryCache({
  possibleTypes: {
    CalendarEvent: ['TransactionCalendarEvent', 'SigningCalendarEvent'],
    Contact: ['IndividualContact', 'AgentContact', 'LenderContact', 'EntityContact'],
  },
  addTypename: true,
  typePolicies: {
    Address: {
      merge: true,
    },
    Property: {
      merge: true,
    },
    TransactionDates: {
      merge: true,
    },
    StorageInformation: {
      keyFields: ['url'],
      merge: false,
    },
    TransactionSigning: {
      merge: true,
    },
    Me: {
      fields: {
        me: {
          read() {
            return meVar();
          },
        },
      },
    },
    BreadcrumbLinks: {
      fields: {
        breadCrumbLinks: {
          read() {
            return breadCrumbLinksVar();
          },
        },
      },
    },
    UploadDocument: {
      fields: {
        uploadDocument: {
          read() {
            return uploadDocumentVar();
          },
        },
      },
    },
    UploadUnassignedDocument: {
      fields: {
        uploadUnassignedDocument: {
          read() {
            return uploadUnassignedDocumentVar();
          },
        },
      },
    },
    Query: {
      fields: {
        me: {
          read() {
            return meVar();
          },
        },
        breadCrumbLinks: {
          read() {
            return breadCrumbLinksVar();
          },
        },
        uploadDocument: {
          read() {
            return uploadDocumentVar();
          },
        },
        uploadUnassignedDocument: {
          read() {
            return uploadUnassignedDocumentVar();
          },
        },
      },
    },
  },
});
