import { OpsTaskStatus } from '@endpoint/opsware-bff-graphql-schema';

export enum OpsTaskQueryableState {
  OVERDUE = 'OVERDUE',
}

type SupportedStatuses =
  | OpsTaskStatus.WAITING
  | OpsTaskStatus.IN_PROGRESS
  | OpsTaskStatus.PARTIAL
  | OpsTaskStatus.NOT_STARTED;

type SupportedFilters = OpsTaskQueryableState.OVERDUE;

export type SupportedOptions = SupportedStatuses | SupportedFilters;

type TaskDisplayOptionMap = {
  [index in SupportedOptions]: string;
};

export const supportedOptions: SupportedOptions[] = [
  OpsTaskStatus.WAITING,
  OpsTaskStatus.IN_PROGRESS,
  OpsTaskStatus.PARTIAL,
  OpsTaskStatus.NOT_STARTED,
  OpsTaskQueryableState.OVERDUE,
];

export const optionsDisplayValueMap: TaskDisplayOptionMap = {
  [OpsTaskStatus.WAITING]: 'Waiting',
  [OpsTaskStatus.IN_PROGRESS]: 'In Progress',
  [OpsTaskStatus.PARTIAL]: 'Partial',
  [OpsTaskStatus.NOT_STARTED]: 'Not Started',
  [OpsTaskQueryableState.OVERDUE]: 'Overdue',
};

export const defaultStatuses: OpsTaskStatus[] = [OpsTaskStatus.IN_PROGRESS, OpsTaskStatus.PARTIAL];

export const defaultOptions: (OpsTaskStatus | OpsTaskQueryableState)[] = [
  ...defaultStatuses,
  OpsTaskQueryableState.OVERDUE,
];
