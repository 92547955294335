import React, { FC, useRef } from 'react';
import { DrawerFooter, Button, Checkbox, Box } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';
import { CREATE_TRANSACTION_MUTATION_FAILED } from 'components/OpenOrderDrawer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema';
import { useHasWriteAccess } from 'hooks/useHasWriteAccess';

interface OpenOrderFooterType {
  discard(): void;
  transaction?: Transaction;
}

export const OpenOrderFooter: FC<OpenOrderFooterType> = ({ discard, transaction }) => {
  const { status, errors, setFieldValue, handleSubmit, isSubmitting, setSubmitting, values } =
    useFormikContext<OpenOrderDrawerForm>();

  const { isMarkTransactionAsTestEnabled } = useFlags();

  const { isTest = false } = values;

  const hasWriteAccess = useHasWriteAccess();

  const hasMarkAsTestPermissions = hasWriteAccess && isMarkTransactionAsTestEnabled;

  return (
    <DrawerFooter alignItems="center" justifyContent="space-between">
      {hasMarkAsTestPermissions ? (
        <Checkbox
          data-test-id="mark-as-test-checkbox"
          isChecked={isTest}
          isDisabled={transaction?.isTest}
          value="Mark as Test"
          onChange={(event) => {
            setFieldValue('isTest', event.target.checked);
          }}
        >
          Mark as Test
        </Checkbox>
      ) : (
        <Box />
      )}
      <Box justifyContent="flex-end">
        <Button
          data-test-id="open-new-transaction-drawer-close-button"
          mr="space30"
          variant="outline"
          onClick={discard}
        >
          Cancel
        </Button>
        <Button
          data-test-id="open-order-drawer-next-button"
          isDisabled={
            isSubmitting || !!Object.keys(errors).length || status.error === CREATE_TRANSACTION_MUTATION_FAILED
          }
          isLoading={isSubmitting}
          ml="space5"
          onClick={() => {
            setSubmitting(true);
            handleSubmit();
          }}
        >
          Complete Review
        </Button>
      </Box>
    </DrawerFooter>
  );
};
