import { OpsTaskUpdateInput } from '@endpoint/opsware-bff-graphql-schema';

export const updateOpsTaskPayload = (taskId: string, data: Partial<OpsTaskUpdateInput>) => ({
  variables: {
    data,
    where: {
      id: taskId,
    },
  },
});
