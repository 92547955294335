import React, { FC, useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Formik, FormikProps } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RouteComponentProps } from '@reach/router';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex, LayoutContent, LayoutSubContent } from '@endpoint/blockparty';
import { TransactionStatus, TransactionType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { Page } from 'consts/segmentProtocols';
import { editTransactionBasicInfoValidationSchema } from 'consts/transacionBasicInfo';
import { ErrorMessage } from 'components/ErrorMessage';
import { EditTransactionInfoActionBanner } from 'components/EditTransactionBasicInfo/EditTransactionBasicInfoActionBanner';
import { EditTransactionBasicInfoDiscardChangesModal } from 'components/EditTransactionBasicInfo/EditTransactionBasicInfoDiscardChangesModal';
import { trackPage } from 'helpers/utils/segment/segmentAnalytics';
import { updateBreadcrumbs } from 'helpers/updateBreadcrumbs';
import { sortInternalNotesByPinned } from 'helpers/internalNotes';
import { buildEditTransactionBasicInfoFormInitialValues } from 'helpers/editTransactionBasicInfo/buildEditTransactionBasicInfoFormInitialValues';
import {
  buildBasicInfoTransactionUpdate,
  UpdateTransactionBasicInfoMutationInput,
} from 'helpers/editTransactionBasicInfo/buildBasicInfoTransactionUpdate';
import { scrollElementIntoView } from 'helpers/scrollElementIntoView';
import { TransactionUpdateResult } from 'routes/Dashboard/OpenOrderList/queries';
import { BasicInfoTerms } from 'routes/Transaction/TransactionBasicInfo/BasicInfoTerms';
import { BasicInfoDates } from 'routes/Transaction/TransactionBasicInfo/BasicInfoDates';
import { TransactionOverview } from 'routes/Transaction/TransactionBasicInfo/TransactionOverview';
import {
  GetTransactionType,
  GET_TRANSACTION_INFO,
  UPDATE_TRANSACTION_BASIC_INFO,
} from 'routes/Transaction/TransactionBasicInfo/queries';
import { BasicInfoRightPanel } from 'routes/Transaction/TransactionBasicInfo/RightPanel';
import { TransactionContext } from 'routes/Transaction';
import { BasicInfoProperty } from 'routes/Transaction/TransactionBasicInfo/BasicInfoProperty';
import { ConvertToEscrowPrompt } from 'routes/Transaction/TransactionBasicInfo/EscrowConversionDrawer/ConvertToEscrowPrompt';
import { EscrowConversionDrawer } from 'routes/Transaction/TransactionBasicInfo/EscrowConversionDrawer/EscrowConversionDrawer';
import { OrderReviewPrompt } from 'routes/Transaction/TransactionBasicInfo/OrderReviewDrawer/OrderReviewPrompt';
import { SkeletonBasicInfo } from 'routes/Transaction/TransactionBasicInfo/SkeletonBasicInfo';
import { BasicInfoOwnership } from 'routes/Transaction/TransactionBasicInfo/BasicInfoOwnership';
import { WorkflowPromptQCNewOrder } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/WorkflowPromptQCNewOrder';
import { WorkflowPromptQCPreparingOrder } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/WorkflowPromptQCPreparingOrder';
import { WorkflowPromptQCFailed } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/WorkflowPromptQCFailed';
import { WorkflowPromptQCReady } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/WorkflowPromptQCReady';
import { EditTransactionBasicInfoContext } from 'hooks/useEditTransactionBasicInfoContext';
import { getPsaDocData } from 'helpers/orderReview/getPsaDocData';

export const TransactionBasicInfo: FC<RouteComponentProps> = () => {
  const formikRef = useRef<FormikProps<{}>>(null);
  const { id: transactionId } = useContext(TransactionContext);
  const [showUnableToSaveChangesAlert, setShowUnableToSaveChangesAlert] = useState<boolean>(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const [discardChangesModalIsOpen, setDiscardChangesModalIsOpen] = useState<boolean>(false);
  const [focusField, setFocusField] = useState<string>('');
  const [isBeingEdited, setFormEditing] = useState<boolean>(false);
  const { isQcOrderFlowEnabled, isRefiQualityControlEnabled } = useFlags();

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Basic Info', path: '' }]);
  }, []);
  const { data, loading, error } = useQuery<GetTransactionType>(GET_TRANSACTION_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: { id: transactionId },
    },
  });

  const mutationHandler = {
    onError(): void {
      setShowUnableToSaveChangesAlert(true);
      scrollElementIntoView('failed-to-update-transaction-info-alert');
    },
    onCompleted(): void {
      setFormEditing(false);
    },
  };

  const [updateTransaction] = useMutation<TransactionUpdateResult>(UPDATE_TRANSACTION_BASIC_INFO, {
    awaitRefetchQueries: true,
  });

  const hideErrorAlert = () => {
    setShowUnableToSaveChangesAlert(false);
  };

  const toggleIsBeingEdited = useCallback(async () => {
    if (isBeingEdited) {
      setShowUnableToSaveChangesAlert(false);
      setFormEditing(false);
      setFocusField('');
    } else {
      await formikRef?.current?.validateForm();
      setFormEditing(true);
    }
  }, [isBeingEdited]);

  useEffect(() => {
    trackPage<Page>('Basic Info');
  }, []);

  const editBasicInfoContextValues = useMemo(
    () => ({ isBeingEdited, toggleIsBeingEdited, setFocusField, focusField }),
    [isBeingEdited, toggleIsBeingEdited, setFocusField, focusField],
  );

  if (loading) {
    return <SkeletonBasicInfo />;
  }

  if (error || !data?.transaction) {
    return (
      <LayoutContent>
        <LayoutSubContent>
          <Flex p="space60">
            <Box mr="space60" width="100%">
              <ErrorMessage />
            </Box>
            <BasicInfoRightPanel />
          </Flex>
        </LayoutSubContent>
      </LayoutContent>
    );
  }

  const isRefiTransaction: boolean = !!data.transaction.type && data.transaction.type === TransactionType.REFINANCE;
  const { id, storageUrl } = getPsaDocData(data?.transaction.documents);
  const isPsaUploaded = !!(id && storageUrl);
  const sortedNotes = sortInternalNotesByPinned(data?.transaction.notes || []);
  const isPrelisting = data?.transaction.status === TransactionStatus.PRELISTING;
  const isCancelled = data?.transaction.status === TransactionStatus.CANCELLED;
  const showPromptOrderReview =
    !data?.transaction?.isOpsReviewComplete &&
    (data?.transaction?.type === TransactionType.SALE_CASH ||
      data?.transaction?.type === TransactionType.SALE_MORTGAGE ||
      isPsaUploaded);
  const showQcPrompt =
    ((isQcOrderFlowEnabled && !isRefiTransaction) || (isRefiQualityControlEnabled && isRefiTransaction)) &&
    !isCancelled;

  return (
    <LayoutContent>
      <LayoutSubContent>
        <Flex p="space60">
          <Formik
            enableReinitialize
            initialErrors={{}}
            initialValues={buildEditTransactionBasicInfoFormInitialValues(data.transaction)}
            innerRef={formikRef}
            validateOnChange
            validationSchema={editTransactionBasicInfoValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const castValues = editTransactionBasicInfoValidationSchema.cast(values);
              const input = buildBasicInfoTransactionUpdate(castValues as UpdateTransactionBasicInfoMutationInput);

              void updateTransaction({
                ...mutationHandler,
                variables: {
                  where: { id: data.transaction.id },
                  updateTransaction: { ...input },
                },
              });

              setSubmitting(false);
            }}
          >
            <>
              <Box mr="space60" width="100%">
                <TransactionOverview transaction={data.transaction} />
                <EditTransactionBasicInfoContext.Provider value={editBasicInfoContextValues}>
                  <Box
                    backgroundColor="white"
                    borderRadius="radiusDefault"
                    boxShadow="medium"
                    pt="space60"
                    px="space60"
                  >
                    {showUnableToSaveChangesAlert && isBeingEdited && (
                      <Box id="failed-to-update-transaction-info-alert" mb="space50">
                        <ErrorMessage
                          closeAction={hideErrorAlert}
                          description="Contact the Opsware product team for assistance."
                          title="Unable to save changes"
                        />
                      </Box>
                    )}

                    {isPrelisting && (
                      <>
                        <ConvertToEscrowPrompt setDrawerIsOpen={setDrawerIsOpen} />
                        {drawerIsOpen && (
                          <EscrowConversionDrawer
                            isOpen={drawerIsOpen}
                            setDrawerIsOpen={setDrawerIsOpen}
                            transaction={data.transaction}
                          />
                        )}
                      </>
                    )}

                    {showPromptOrderReview && <OrderReviewPrompt />}

                    {showQcPrompt && (
                      <>
                        <WorkflowPromptQCNewOrder
                          drawerIsOpen={drawerIsOpen}
                          isPsaUploaded={isPsaUploaded}
                          setDrawerIsOpen={setDrawerIsOpen}
                          transaction={data.transaction}
                        />
                        <WorkflowPromptQCPreparingOrder transaction={data.transaction} />
                        <WorkflowPromptQCFailed transaction={data.transaction} />
                        <WorkflowPromptQCReady transaction={data.transaction} />
                      </>
                    )}

                    <BasicInfoProperty transaction={data.transaction} />
                    <BasicInfoTerms transaction={data.transaction} />
                    <BasicInfoDates transaction={data.transaction} />
                    <BasicInfoOwnership transaction={data.transaction} />
                  </Box>
                  {isBeingEdited && (
                    <Box
                      fill="mist0"
                      opacity="90%"
                      position="sticky"
                      style={{ borderTop: '1px solid #C9C9C9', bottom: -10 }}
                    >
                      <EditTransactionInfoActionBanner
                        setDiscardChangesModalIsOpen={setDiscardChangesModalIsOpen}
                        setFormEditing={setFormEditing}
                      />
                    </Box>
                  )}
                  {discardChangesModalIsOpen && (
                    <EditTransactionBasicInfoDiscardChangesModal
                      isModalOpen={discardChangesModalIsOpen}
                      setFormEditing={setFormEditing}
                      setIsModalOpen={setDiscardChangesModalIsOpen}
                    />
                  )}
                </EditTransactionBasicInfoContext.Provider>
              </Box>
              <BasicInfoRightPanel notes={sortedNotes} />
            </>
          </Formik>
        </Flex>
      </LayoutSubContent>
    </LayoutContent>
  );
};
