import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Box, useToast } from '@endpoint/blockparty';
import { UpdateContactInput, TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema';
import {
  generateInitialEditContactValues,
  generateUpdateContactValidation,
  generateEditContactSubmitValues,
  getDisplayableError,
} from 'helpers/updateContact';
import { AllContactTypes } from 'consts/contactDetails';
import { useEditContactDetailsProvider, EditContactDetailsAction } from 'hooks/useEditContactDetailsProvider';
import { ErrorMessage } from 'components/ErrorMessage';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSystemOrOpsAdmin } from 'hooks/useIsSystemOrOpsAdmin';

import { DetailsFields } from './DetailsFields';
import { ContactInformationFields } from './ContactInformationFields';
import { SystemInformation } from '../../Tabs/DetailsTab/SystemInformation';
import { EditContactDetailsActionBar } from '../EditContactDetailsActionBar';
import { UpdateContactDetailsData, UPDATE_CONTACT_DETAILS } from './queries';

interface EditContactDetailsProps {
  participant: TransactionParticipant;
}

export const EditContactDetails: FC<EditContactDetailsProps> = ({ participant }) => {
  const { isAdmin } = useIsSystemOrOpsAdmin();
  const { canEditContactEmailAndPhone } = useFlags();
  const userCanUpdatePhoneOrEmail = isAdmin && canEditContactEmailAndPhone;
  const toast = useToast();
  const { state, dispatch } = useEditContactDetailsProvider();
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const [errDescription, setErrDescription] = useState<string>('');
  const [editContactDetailsSegmentKeys, setEditContactDetailsSegmentKeys] = useState<string[]>([]);
  const contact: AllContactTypes = participant.contact as AllContactTypes;
  const initialValues: UpdateContactInput = generateInitialEditContactValues(contact);
  const validationSchema = generateUpdateContactValidation(contact);
  const handleHasError = () => setHasSubmitError(!hasSubmitError);

  const [updateContactDetails] = useMutation<UpdateContactDetailsData>(UPDATE_CONTACT_DETAILS, {
    onCompleted(): void {
      dispatch({
        type: EditContactDetailsAction.SET_IS_EDITING,
        payload: { ...state, isEditing: false },
      });

      toast({
        description: 'Edits saved',
        duration: 5000,
        icon: 'CheckCircle',
      });

      trackAnalytics('Edit Contact Saved', { fields: editContactDetailsSegmentKeys });
    },
    onError(error): void {
      const errMessage = getDisplayableError(error);

      if (errMessage) {
        setErrDescription(errMessage);
      } else {
        setErrDescription('');
      }

      handleHasError();
    },
  });

  const handleSubmit = React.useCallback(
    async (values: UpdateContactInput) => {
      const submitValues: UpdateContactInput = generateEditContactSubmitValues(initialValues, values);

      setEditContactDetailsSegmentKeys(Object.keys(submitValues));
      await updateContactDetails({
        variables: {
          where: { id: contact.id },
          input: { ...submitValues },
        },
      });
    },
    [initialValues, contact.id, updateContactDetails],
  );

  return (
    <Formik
      initialValues={{ ...initialValues }}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Box>
        {hasSubmitError ? (
          <Box pb="space50">
            <ErrorMessage closeAction={handleHasError} description={errDescription} title="Unable to save changes" />
          </Box>
        ) : null}
        <DetailsFields participant={participant} />
        <ContactInformationFields canEditOnboardedContactProps={userCanUpdatePhoneOrEmail} contact={contact} />
        <SystemInformation participant={participant} />
        <EditContactDetailsActionBar />
      </Box>
    </Formik>
  );
};
