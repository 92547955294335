import React, { FC } from 'react';
import { Button } from '@endpoint/blockparty';
import { WorkflowPrompt } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/WorkflowPrompt';
import {
  Transaction,
  QualityControlStatus,
  TransactionStatus,
  TransactionType,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { EscrowConversionDrawer } from 'routes/Transaction/TransactionBasicInfo/EscrowConversionDrawer/EscrowConversionDrawer';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';

interface WorkflowPromptQCNewOrderProps {
  transaction: Transaction;
  isPsaUploaded: boolean;
  drawerIsOpen: boolean;
  setDrawerIsOpen: (value: boolean) => void;
}

export const WorkflowPromptQCNewOrder: FC<WorkflowPromptQCNewOrderProps> = ({
  transaction,
  isPsaUploaded,
  drawerIsOpen,
  setDrawerIsOpen,
}) => {
  const isPrelisting = transaction.status === TransactionStatus.PRELISTING;
  const isRefiOrEquity = [TransactionType.REFINANCE, TransactionType.EQUITY].includes(
    transaction.type as unknown as TransactionType,
  );
  const showPrompt =
    transaction.qualityControlStatus === QualityControlStatus.NEW_ORDER &&
    !isPsaUploaded &&
    !isPrelisting &&
    !isRefiOrEquity;

  if (!showPrompt) {
    return null;
  }

  return (
    <>
      <WorkflowPrompt
        dataTestId="qc-new-order-prompt"
        description="A new order was created through the app. Start by uploading the PSA"
        status="warning"
        title="New Order"
      >
        <ButtonWriteRequired
          data-test-id="qc-document-upload-button"
          style={{
            color: '#474747',
            outlineColor: '#474747',
          }}
          variant="outline"
          variantColor="carbon"
          onClick={() => setDrawerIsOpen(true)}
        >
          Upload PSA
        </ButtonWriteRequired>
      </WorkflowPrompt>
      {drawerIsOpen && (
        <EscrowConversionDrawer
          isOpen={drawerIsOpen}
          setDrawerIsOpen={setDrawerIsOpen}
          title="Upload PSA"
          transaction={transaction}
        />
      )}
    </>
  );
};
