import React, { FC } from 'react';
import { Box, Divider, Grid, Heading, Text, Flex } from '@endpoint/blockparty';
import { createContactDetailsFields } from 'helpers/contacts';
import { ContactType, TransactionParticipant, TransactionRole } from '@endpoint/opsware-bff-graphql-schema';
import { ContactFieldData } from 'consts/contactDetails';
import { EditFieldButton } from 'components/EditFieldButton';
import { useEditContactDetailsProvider, EditContactDetailsAction } from 'hooks/useEditContactDetailsProvider';
import { UpdateContactField } from 'consts/updateContact';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { useIsClosingSpecialistRole } from 'hooks/useIsClosingSpecialistRole';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ContactDetailsProps {
  participant: TransactionParticipant;
}

export const ContactDetailsFields: FC<ContactDetailsProps> = ({ participant }) => {
  const { canDisplayTransactionParticipantProfileData } = useFlags();

  const contactDetailsFields = createContactDetailsFields(participant, canDisplayTransactionParticipantProfileData);
  const { state, dispatch } = useEditContactDetailsProvider();
  const isEditProptechContactEnabled = participant?.contact?.contactType !== ContactType.PROPTECH_PARTNER;
  const isClosingSpecialist = useIsClosingSpecialistRole(participant);

  const handleEditClick = (fieldToFocus: UpdateContactField) => {
    dispatch({
      type: EditContactDetailsAction.SET_SELECTED_FIELD,
      payload: { ...state, selectedField: fieldToFocus },
    });

    trackAnalytics('Edit Contact Clicked', { field: fieldToFocus });
  };

  return (
    <Grid
      columnGap="space60"
      px="space40"
      py="space30"
      rowGap="space30"
      templateColumns="repeat(2, minmax(150px, 1fr))"
    >
      {contactDetailsFields.map(({ label, isEditable, fieldName, value }: ContactFieldData, index) => {
        const isOddNumFields = contactDetailsFields.length % 2;
        const showDivider = index < contactDetailsFields.length - (isOddNumFields ? 1 : 2);
        const formattedValue = value || '-';

        return (
          <Box key={label}>
            <Flex justifyContent="space-between">
              <Text as="p" color="passive" data-test-id={`${label}-text`} mb="space20" size="fontSize10">
                {label}
              </Text>
              {isEditable && isEditProptechContactEnabled && !isClosingSpecialist && (
                <EditFieldButton handleClick={() => handleEditClick(fieldName)} label={label} />
              )}
            </Flex>
            <Text as="p" data-test-id={`${label}-value`} mb="space30">
              {formattedValue}
            </Text>
            {showDivider && <Divider data-test-id="divider" />}
          </Box>
        );
      })}
    </Grid>
  );
};

export const Details: FC<ContactDetailsProps> = ({ participant }) => {
  return (
    <Box data-test-id="contact-details-section" width="100%">
      <Heading backgroundColor="slate0" p="space40" size="fontSize30">
        Details
      </Heading>
      <ContactDetailsFields participant={participant} />
    </Box>
  );
};
