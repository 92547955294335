import {
  Transaction,
  Milestone,
  Json,
  DateTime,
  TransactionType,
  Market,
  Organization,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { transactionTypeMap } from 'consts/transactionConsts';
import { getCurrentUser } from 'components/Auth/helpers';

import { formatCurrency } from '../formatText';
import { dateFormatter } from '../datetimeHelper';

export interface BasicInfoFieldsData {
  label: string;
  value: string | number | boolean | Json | undefined;
}

// //////////////////////////////////////////////////////

// Milestone 2
export function getEmdMilestoneData(transaction: Transaction): Milestone | undefined {
  return transaction?.milestones?.find((milestone) => milestone.position === 2);
}
// //////////////////////////////////////////////////////

export function createDateFieldsOfInterest(transaction: Transaction) {
  switch (transaction.type) {
    case TransactionType.PRELISTING:
      return getDatesFieldsPrelisting(transaction);
    case TransactionType.EQUITY:
    case TransactionType.REFINANCE:
      return getDatesFieldsRefinance(transaction);
    // case TransactionStatus.IN_ESCROW_REFI: - not handled but ready for when it is
    //   return ['estimatedClosing', 'signingDate', 'escrowOpen'];
    case TransactionType.TITLE_ONLY:
      return getDatesFieldsTitleOnly(transaction);
    case TransactionType.PIGGYBACK_LOAN:
      return getDatesFieldsPiggyback(transaction);
    default:
      return getDatesFieldsDefault(transaction);
  }
}

function getDatesFieldsPrelisting(transaction: Transaction): BasicInfoFieldsData[] {
  const dateFormatUS: string = 'MM/dd/yyyy';
  const {
    prelistingOpened,
  }: {
    prelistingOpened?: DateTime;
  } = transaction.dates;

  const prelistingOpenedDate: string | undefined = prelistingOpened && dateFormatter(prelistingOpened, dateFormatUS);

  return [
    {
      label: 'Opened Date',
      value: prelistingOpenedDate,
    },
  ];
}

function getDatesFieldsRefinance(transaction: Transaction): BasicInfoFieldsData[] {
  const { escrowOpenDate, sellerSigningDate, estimatedClosingDate } = getAllDatesFields(transaction);

  return [
    {
      label: 'Contract Received Date',
      value: escrowOpenDate,
    },
    {
      label: 'Borrower Signing Date',
      value: sellerSigningDate,
    },
    {
      label: 'Estimated Closing Date',
      value: estimatedClosingDate,
    },
  ];
}

function getDatesFieldsTitleOnly(transaction: Transaction): BasicInfoFieldsData[] {
  const { estimatedClosingDate, escrowOpenDate, titleProductDeliveredDate, contractExecutedDateFormatted } =
    getAllDatesFields(transaction);

  return [
    {
      label: 'Closing Date',
      value: estimatedClosingDate,
    },
    {
      label: 'Opened Date',
      value: escrowOpenDate,
    },
    {
      label: 'Title Product Delivered Date',
      value: titleProductDeliveredDate,
    },
    {
      label: 'Contract Executed Date',
      value: contractExecutedDateFormatted,
    },
  ];
}

function getDatesFieldsPiggyback(transaction: Transaction): BasicInfoFieldsData[] {
  const { escrowOpenDate } = getAllDatesFields(transaction);

  return [
    {
      label: 'Escrow Opened Date',
      value: escrowOpenDate,
    },
  ];
}

function getDatesFieldsDefault(transaction: Transaction): BasicInfoFieldsData[] {
  const { estimatedClosingDate, escrowOpenDate, emdContract, contractExecutedDateFormatted } =
    getAllDatesFields(transaction);

  return [
    {
      label: 'Estimated Closing Date',
      value: estimatedClosingDate,
    },
    {
      label: 'Contract Received Date',
      value: escrowOpenDate,
    },
    {
      label: 'EMD Due Date',
      value: emdContract,
    },
    {
      label: 'Contract Executed Date',
      value: contractExecutedDateFormatted,
    },
  ];
}

function getAllDatesFields(transaction: Transaction) {
  const dateFormatUS: string = 'MM/dd/yyyy';

  const {
    estimatedClosing,
    escrowOpen,
    emdContractDate,
    contractExecutedDate,
    prelistingOpened,
    titleProductDelivered,
  }: {
    estimatedClosing?: DateTime;
    escrowOpen?: DateTime;
    emdContractDate?: DateTime;
    contractExecutedDate?: DateTime;
    prelistingOpened?: DateTime;
    titleProductDelivered?: DateTime;
  } = transaction.dates;

  const estimatedClosingDate: string | undefined = estimatedClosing && dateFormatter(estimatedClosing, dateFormatUS);
  const escrowOpenDate: string | undefined = escrowOpen && dateFormatter(escrowOpen, dateFormatUS);
  const emdContract: BasicInfoFieldsData['value'] = emdContractDate
    ? dateFormatter(emdContractDate, dateFormatUS)
    : undefined;
  const contractExecutedDateFormatted: BasicInfoFieldsData['value'] = contractExecutedDate
    ? dateFormatter(contractExecutedDate, dateFormatUS)
    : undefined;
  const titleProductDeliveredDate: string | undefined =
    titleProductDelivered && dateFormatter(titleProductDelivered, dateFormatUS);
  const prelistingOpenedDate: string | undefined = prelistingOpened && dateFormatter(prelistingOpened, dateFormatUS);
  const sellerSigningDate: string | undefined =
    transaction.sellerSigning?.date && dateFormatter(transaction.sellerSigning.date, dateFormatUS);

  return {
    estimatedClosingDate,
    escrowOpenDate,
    emdContract,
    titleProductDeliveredDate,
    contractExecutedDateFormatted,
    prelistingOpenedDate,
    sellerSigningDate,
  };
}

// //////////////////////////////////////////////////////

export function createTermsFieldsOfInterest(transaction: Transaction) {
  switch (transaction.type) {
    case TransactionType.PRELISTING:
      return getTermsFieldsPrelisting(transaction);
    // case TransactionStatus.IN_ESCROW_REFI: - not handled but ready for when it is
    //   return['loanAmount', 'type'];
    case TransactionType.SALE_MORTGAGE:
      return getTermsFieldsSaleMortgage(transaction);
    case TransactionType.SALE_CASH:
      return getTermsFieldsSaleCash(transaction);
    case TransactionType.REFINANCE:
      return getTermsFieldsRefinance(transaction);
    case TransactionType.TITLE_ONLY:
      return getTermsFieldsTitleOnly(transaction);
    case TransactionType.PIGGYBACK_LOAN:
      return getTermsFieldsPiggyback(transaction);
    case TransactionType.ESCROW_ONLY:
      return getTermsFieldsEscrowOnly(transaction);
    case TransactionType.EQUITY:
      return getTermsFieldsEquity(transaction);
    default:
      return getTermsFieldsPrelisting(transaction);
  }
}

function getTermsFieldsPrelisting(transaction: Transaction): BasicInfoFieldsData[] {
  const { transactionType } = getAllTermsFields(transaction);

  return [
    {
      label: 'Product Type',
      value: transactionType,
    },
  ];
}

function getTermsFieldsEquity(transaction: Transaction): BasicInfoFieldsData[] {
  const { transactionType, loanAmount, loanNumber } = getAllTermsFields(transaction);

  return [
    {
      label: 'Product Type',
      value: transactionType,
    },
    {
      label: 'Loan Amount',
      value: loanAmount,
    },
    {
      label: 'Loan Number',
      value: loanNumber,
    },
  ];
}

function getTermsFieldsSaleMortgage(transaction: Transaction): BasicInfoFieldsData[] {
  const { transactionType, salePrice, loanAmount, loanNumber, emdAmount, emdStatus } = getAllTermsFields(transaction);

  return [
    {
      label: 'Product Type',
      value: transactionType,
    },
    {
      label: 'Sale Price',
      value: salePrice,
    },
    {
      label: 'Loan Amount',
      value: loanAmount,
    },
    {
      label: 'Loan Number',
      value: loanNumber,
    },
    {
      label: 'EMD Amount',
      value: emdAmount,
    },
    {
      label: 'EMD Status',
      value: emdStatus,
    },
  ];
}

function getTermsFieldsSaleCash(transaction: Transaction): BasicInfoFieldsData[] {
  const { transactionType, salePrice, emdAmount, emdStatus } = getAllTermsFields(transaction);

  return [
    {
      label: 'Product Type',
      value: transactionType,
    },
    {
      label: 'Sale Price',
      value: salePrice,
    },
    {
      label: 'EMD Amount',
      value: emdAmount,
    },
    {
      label: 'EMD Status',
      value: emdStatus,
    },
  ];
}

function getTermsFieldsRefinance(transaction: Transaction): BasicInfoFieldsData[] {
  const { transactionType, loanAmount, loanNumber } = getAllTermsFields(transaction);

  return [
    {
      label: 'Product Type',
      value: transactionType,
    },
    {
      label: 'Loan Amount',
      value: loanAmount,
    },
    {
      label: 'Loan Number',
      value: loanNumber,
    },
  ];
}

function getTermsFieldsTitleOnly(transaction: Transaction): BasicInfoFieldsData[] {
  const { transactionType, salePrice, loanAmount, loanNumber } = getAllTermsFields(transaction);

  return [
    {
      label: 'Product Type',
      value: transactionType,
    },
    {
      label: 'Sale Price',
      value: salePrice,
    },
    {
      label: 'Loan Amount',
      value: loanAmount,
    },
    {
      label: 'Loan Number',
      value: loanNumber,
    },
  ];
}

function getTermsFieldsPiggyback(transaction: Transaction): BasicInfoFieldsData[] {
  const { transactionType, loanAmount, loanNumber } = getAllTermsFields(transaction);

  return [
    {
      label: 'Product Type',
      value: transactionType,
    },
    {
      label: 'Loan Amount',
      value: loanAmount,
    },
    {
      label: 'Loan Number',
      value: loanNumber,
    },
  ];
}

function getTermsFieldsEscrowOnly(transaction: Transaction): BasicInfoFieldsData[] {
  const { transactionType, salePrice, loanAmount, loanNumber, emdAmount, emdStatus } = getAllTermsFields(transaction);

  return [
    {
      label: 'Product Type',
      value: transactionType,
    },
    {
      label: 'Sale Price',
      value: salePrice,
    },
    {
      label: 'Loan Amount',
      value: loanAmount,
    },
    {
      label: 'Loan Number',
      value: loanNumber,
    },
    {
      label: 'EMD Amount',
      value: emdAmount,
    },
    {
      label: 'EMD Status',
      value: emdStatus,
    },
  ];
}

function getAllTermsFields(transaction: Transaction) {
  const transactionType: TransactionType | undefined = transaction?.type;
  const salePrice: number | undefined = transaction?.price;
  const loanAmount: number | undefined = transaction?.loanAmount;
  const loanNumber: string | undefined = transaction?.loanNumber;

  const emdStatus = transaction.milestones.find((milestone) => milestone.position === 2)?.status;

  let emdAmount: BasicInfoFieldsData['value'] = transaction?.emd?.amount || 0;

  if (salePrice && emdAmount) {
    const percentOfPrice = (100 * (emdAmount / salePrice)).toFixed(2);

    emdAmount = `${formatCurrency(emdAmount)} (${percentOfPrice}%)`;
  }

  return {
    transactionType: transactionType ? transactionTypeMap[transactionType] : '-',
    salePrice,
    loanAmount,
    loanNumber,
    emdAmount,
    emdStatus,
  };
}

export function getCacheOrganizationName(
  organizations: Organization[] | undefined,
  organizationId: number | undefined,
): string {
  const organization = organizations?.find((item) => item.id === organizationId);

  return organization?.name || '';
}

export function getCacheMarketName(markets: Market[] | undefined, marketId: number | undefined): string {
  const market = markets?.find((item) => item.id === marketId);

  return market?.name || '';
}

export const getAdminStatus = (): Promise<boolean> => {
  return getCurrentUser()
    .then((user) => {
      return user.role === 'OPS_ADMIN' || user.role === 'SYSTEM_ADMIN';
    })
    .catch((error) => {
      return false;
    });
};
