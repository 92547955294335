import React, { FC } from 'react';
import { Milestone, MilestoneType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { Text, Flex, useDisclosure, Heading, Box, Icon } from '@endpoint/blockparty';
import { mapStatusText } from 'helpers/statusHelper';
import { milestoneStatusMap } from 'consts/milestoneConsts';
import { useHasWriteAccess } from 'hooks/useHasWriteAccess';

import { EditMilestoneDrawer } from '../EditMilestoneDrawer';

interface MilestoneAccordionPopoverProps {
  milestone: Milestone;
}

export const MilestoneAccordionPopover: FC<MilestoneAccordionPopoverProps> = ({ milestone }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasWriteAccess = useHasWriteAccess();

  return (
    <Flex
      data-test-id="milestone-accordion-popover"
      flexDirection="column"
      outline="none"
      tabIndex={milestone.type === MilestoneType.ESCROW_OPENED ? 0 : undefined}
      width="320px"
    >
      <Text color="carbon500" data-test-id="milestone-accordion-title" fontWeight="semi" size="fontSize10">
        {`M${milestone.position} - ${mapStatusText(milestone.status, milestoneStatusMap).toUpperCase()}`}
      </Text>
      <Heading as="h6" data-test-id="milestone-accordion-name" mt="space20" size="fontSize30">
        {milestone.name}
      </Heading>
      <Text data-test-id="milestone-accordion-description" mt="space30" size="fontSize20">
        {milestone.description}
      </Text>
      {milestone.type !== MilestoneType.ESCROW_OPENED && hasWriteAccess && (
        <>
          <Box
            _focus={{
              boxShadow: '0 0 0 3px #83d5f2',
            }}
            _hover={{
              color: 'blue400',
            }}
            border="1px solid"
            borderRadius="radiusDefault"
            color="blue500"
            data-test-id="milestone-accordion-edit"
            height="32px"
            mt="space60"
            p="space20"
            tabIndex={0}
            textAlign="center"
            width="100%"
            onClick={onOpen}
          >
            <Text as="p" color="currentColor" fontFamily="normal" fontWeight="heading" size="fontSize20">
              <Icon marginRight="space30" name="Edit" size="large" />
              Edit
            </Text>
          </Box>
          <EditMilestoneDrawer isOpen={isOpen} milestone={milestone} onClose={onClose} />
        </>
      )}
    </Flex>
  );
};
