import React, { FC } from 'react';
import { formatField } from 'helpers/formatText';
import { createTermsFieldsOfInterest, BasicInfoFieldsData } from 'helpers/transaction';
import { Box, Grid, Text, Flex, Divider } from '@endpoint/blockparty';
import { EditTransactionBasicInfoEditButton } from 'components/EditTransactionBasicInfo/EditTransactionBasicInfoEditButton';
import { TransactionStatus, TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { useBasicInfoShowEditButton } from 'hooks/useBasicInfoShowEditButton';
import { useEditTransactionBasicInfoContext } from 'hooks/useEditTransactionBasicInfoContext';

import { BasicInfoTermsProps } from '.';

export const Terms: FC<BasicInfoTermsProps> = ({ transaction }) => {
  const termsFieldsofInterest = createTermsFieldsOfInterest(transaction);
  const { toggleIsBeingEdited, setFocusField } = useEditTransactionBasicInfoContext();
  const showEditButton = useBasicInfoShowEditButton(transaction);

  return (
    <Grid
      columnGap="space60"
      data-test-id="basic-info-terms-grid"
      pb="space50"
      pt="space30"
      px="space50"
      rowGap="space40"
      templateColumns="repeat(2, minmax(150px, 1fr))"
    >
      {termsFieldsofInterest.map(({ label, value }: BasicInfoFieldsData, index) => {
        let fieldValue;
        const isOddNumFields = termsFieldsofInterest.length % 2;
        const showDivider = index < termsFieldsofInterest.length - (isOddNumFields ? 1 : 2);

        const isClosedOrCancelledTransaction =
          (transaction.status === TransactionStatus.CLOSED && label === 'Product Type') ||
          (transaction.status === TransactionStatus.CANCELLED && label === 'Product Type');
        const isProductTypeFieldRefinance = transaction.type === TransactionType.REFINANCE && label === 'Product Type';

        if (label !== 'Product Type') {
          fieldValue = formatField(value);
        } else {
          fieldValue = value;
        }

        const isMissingProductType = label === 'Product Type' && (!value || value === '-');

        const showEditTransactionInfoButton =
          isMissingProductType ||
          (label !== 'EMD Status' &&
            transaction.type !== TransactionType.PRELISTING &&
            !isProductTypeFieldRefinance &&
            !isClosedOrCancelledTransaction &&
            showEditButton);

        return (
          <Box key={label} data-test-id="transaction-basic-info-terms">
            <Flex width="space90">
              <Text as="p" color="passive" data-test-id={`${label}-text`} mb="space30" size="fontSize10" width="100%">
                {label}
              </Text>
              {showEditTransactionInfoButton && (
                <EditTransactionBasicInfoEditButton
                  fieldName={label}
                  setFocusField={setFocusField}
                  toggleEditing={toggleIsBeingEdited}
                />
              )}
            </Flex>
            <Text as="p" data-test-id={`${label}-value`} mb="space40">
              {fieldValue}
            </Text>
            {showDivider && <Divider />}
          </Box>
        );
      })}
    </Grid>
  );
};

Terms.displayName = 'Terms';
