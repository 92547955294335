import React, { FC, useState, useContext } from 'react';
import { Box, NavBarItem, NavBar, Divider, Text, Flex } from '@endpoint/blockparty';
import ReactMarkdown from 'react-markdown';
import { TaskEntityContact } from 'routes/Transaction/TransactionRightPanel/DynamicForm/TaskEntityComponents/TaskEntityContact';
import { sampleOpsTaskQueryResponse } from 'helpers/dynamicForm/sampleOpsTask';
import { AddNote } from 'components/AddNote';
import { Note, NoteEntityType } from '@endpoint/opsware-bff-graphql-schema';
import { InternalNote } from 'components/InternalNote';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { TaskDrawerContext } from '../TaskPanel/TaskPanelV2';

enum TabOptions {
  Task = 'Task',
  Notes = 'Notes',
}

interface TabItemProps {
  tabName: string;
  selected: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<TabOptions>>;
}

const TabItem: FC<TabItemProps> = ({ tabName, selected, setActiveTab }) => {
  const { canViewTaskNotes } = useFlags();

  if (tabName === TabOptions.Notes && !canViewTaskNotes) {
    return null;
  }

  return (
    <NavBarItem
      key={tabName}
      color={selected ? 'blue500' : 'carbon900'}
      data-test-id={`nav-bar-item-${tabName}`}
      fontWeight={selected ? 'heading' : 'normal'}
      mx="space50"
      selected={selected}
      onClick={() => setActiveTab(tabName as TabOptions)}
    >
      {tabName}
    </NavBarItem>
  );
};

interface TabbedContentProps {}

export const TabbedContent: FC<TabbedContentProps> = () => {
  const [activeTab, setActiveTab] = useState('Task' as TabOptions);

  return (
    <Box>
      <Divider />
      <NavBar mx="space50">
        {Object.keys(TabOptions).map((tabName) => (
          <TabItem key={tabName} selected={activeTab === tabName} setActiveTab={setActiveTab} tabName={tabName} />
        ))}
      </NavBar>
      <Divider m="space0" />
      {tabComponentMap[activeTab]}
    </Box>
  );
};

const TabTask: FC = () => {
  const context = useContext(TaskDrawerContext);
  const task = sampleOpsTaskQueryResponse;
  const description = context?.opsTask?.taskDefinitionSchema.description || 'N/A';

  return (
    <Box data-test-id="tab-task">
      <Box p="space60">
        <Box fontSize="fontSize30" fontWeight="heading">
          Description
        </Box>
        <Text as="p" mt="space40">
          <ReactMarkdown>{description || 'N/A'}</ReactMarkdown>
        </Text>
      </Box>
      <Divider m="space0" />
      <Box fontSize="fontSize30" fontWeight="heading" p="space60">
        Task
      </Box>
      <Box px="space60">
        {task?.contacts?.map((contact) => (
          <Box
            key={contact.id}
            backgroundColor="slate0"
            borderRadius="radiusDefault"
            marginBottom="space20"
            p="space60"
          >
            <TaskEntityContact contact={contact} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const TabNotes: FC = () => {
  const context = useContext(TaskDrawerContext);
  const opsTaskId = context!.opsTask?.id || '';
  const notes = context!.opsTask?.notes || [];

  return (
    <Box data-test-id="tab-notes">
      <Box fontSize="fontSize30" fontWeight="heading" p="space60">
        Notes
      </Box>
      <Box px="space60">
        <Flex data-test-id="internal-notes" flexDirection="column" height="100%">
          {notes.length > 0 &&
            notes.map((note: Note) => (
              <InternalNote key={note.id} entityType={NoteEntityType.TASK} note={note} transactionId={opsTaskId} />
            ))}
        </Flex>
        <AddNote entityType={NoteEntityType.TASK} transactionId={opsTaskId} />
      </Box>
    </Box>
  );
};

const tabComponentMap: { [key in TabOptions]: JSX.Element } = {
  Task: <TabTask />,
  Notes: <TabNotes />,
};
