import { gql } from '@apollo/client';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { BreadCrumbLink } from 'apollo/typeDefs';

export const GET_TRANSACTION_HEADER_DATA = gql`
  query GetTransactionHeaderData($where: TransactionWhereUniqueInput!) {
    breadCrumbLinks @client {
      path
      label
    }
    transaction(where: $where) {
      id
      status
      fileNum
      type
      isTest
      escrowPending
      dates {
        estimatedClosing
      }
      participants {
        id
        roles
        contact {
          id
          email
          contactType
          phone
          isDeactivated
          isOnboarded
          ... on AgentContact {
            licenseNumber
            stateOfOperation
            firstName
            lastName
            middleName
          }
          ... on LenderContact {
            nmlsId
            firstName
            lastName
            middleName
          }
          ... on IndividualContact {
            firstName
            lastName
            middleName
          }
          ... on EntityContact {
            entityName
          }
        }
      }
      property {
        address {
          standardFormat
        }
      }
    }
  }
`;

export interface GetTransactionHeader {
  transaction: Transaction;
  breadCrumbLinks: BreadCrumbLink[];
}
