import { ApolloClient, FetchResult } from '@apollo/client';
import { DocumentCreateInput } from '@endpoint/opsware-bff-graphql-schema';
import { FileUpload } from 'apollo/resolvers/UploadDocument/FileUpload';
import { maxUploadSize, FileRejections, DocumentUpload } from 'consts/uploadDocumentsConsts';
import { getUploadErrorMessage } from 'helpers/documents';
import { cache } from 'apollo/ApolloCache';
import { trimFileExtension } from 'helpers/formatText';

import { createDocumentUpload } from './createDocumentUpload';
import {
  startUpload,
  uploadDocumentError,
  createDocument,
  updateUploadFileUpload,
  uploadProgressDone,
  updateUploadProgress,
  CreateDocument,
} from './apolloClientHelpers';

export interface UploadDocumentToTransactionVariables {
  file: File;
  transactionId: string;
  documentType: string;
}

const handleCreateDocument = async (
  client: ApolloClient<object>,
  createDocumentVariables: DocumentCreateInput,
  cacheDocumentId: string,
): Promise<FetchResult<CreateDocument | null | undefined>> => {
  let result: FetchResult<CreateDocument | null | undefined>;

  try {
    result = await createDocument(client, createDocumentVariables);

    return result;
  } catch {
    uploadDocumentError(cache, cacheDocumentId);
  }

  return {};
};

export const uploadDocumentToTransaction = async (
  uploadVariables: UploadDocumentToTransactionVariables,
  client: ApolloClient<object>,
): Promise<FileUpload> => {
  const file = uploadVariables.file as File;
  const documentType = uploadVariables.documentType;
  const validationError = file.size > maxUploadSize ? getUploadErrorMessage(FileRejections.FILE_TOO_LARGE) : '';
  let fileUpload = new FileUpload(uploadVariables.file, '');
  const documentUpload: DocumentUpload = createDocumentUpload(file.name, documentType, validationError, fileUpload);

  startUpload(cache, documentUpload);

  if (documentUpload.fileRejection) {
    uploadDocumentError(cache, documentUpload.id);

    return fileUpload;
  }

  const createDocumentVariables: DocumentCreateInput = {
    transactionId: uploadVariables.transactionId,
    name: trimFileExtension(file.name),
    type: documentType,
  };
  const document = await handleCreateDocument(client, createDocumentVariables, documentUpload.id);

  if (!document.data?.createDocument.storage?.uploadUrl) {
    uploadDocumentError(cache, documentUpload.id);

    return fileUpload;
  }

  const url = document.data?.createDocument.storage?.uploadUrl;

  fileUpload = new FileUpload(uploadVariables.file, url);

  fileUpload.onComplete = () => {
    uploadProgressDone(cache, documentUpload.id);
  };

  fileUpload.onProgress = (progress) => {
    updateUploadProgress(cache, documentUpload.id, progress);
  };

  fileUpload.onError = () => {
    uploadDocumentError(cache, documentUpload.id);
  };

  updateUploadFileUpload(cache, documentUpload.id, fileUpload);

  fileUpload.start();

  return fileUpload;
};
