import { gql } from '@apollo/client';
import { Market } from '@endpoint/opsware-bff-graphql-schema';

export const GET_MARKETS = gql`
  query GetMarkets {
    markets {
      id
      name
      state
      active
      acceptsPrelistings
      organizationLegalName
      fundingType
      organizationId
      __typename
    }
  }
`;

export const UPDATE_MARKET = gql`
  mutation updateMarket($where: MarketWhereUniqueInput!, $input: UpdateMarketInput!) {
    updateMarket(where: $where, input: $input) {
      id
      name
      state
      active
      acceptsPrelistings
      organizationLegalName
      fundingType
      __typename
    }
  }
`;

export interface GetMarketsQueryResult {
  markets: Market[];
}

export interface UpdateMarketsResult {
  market: Market;
}
