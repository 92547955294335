import React, { FC } from 'react';
import { Button, Modal, ModalContent, ModalFooter, ModalSection, Text } from '@endpoint/blockparty';

interface DiscardUnsavedChangesModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  resetUpdateForm: () => void;
  handleIsEditing: () => void;
}

export const DiscardUnsavedChangesModal: FC<DiscardUnsavedChangesModalProps> = ({
  closeModal,
  handleIsEditing,
  isModalOpen,
  resetUpdateForm,
}) => (
  <Modal isOpen={isModalOpen} onClose={closeModal}>
    <ModalContent zIndex={2}>
      <ModalSection>
        <Text as="h1" fontWeight="semi" size="fontSize30">
          Discard unsaved changes?
        </Text>
      </ModalSection>
      <ModalFooter>
        <Button mr="space50" variant="outline" onClick={closeModal}>
          Keep editing
        </Button>
        <Button
          onClick={() => {
            closeModal();
            handleIsEditing();
            resetUpdateForm();
          }}
        >
          Discard
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
