import { Transaction } from '@endpoint/opsware-bff-graphql-schema';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { QualityControlStatus, TransactionStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export function useBasicInfoShowEditButton(transaction: Transaction): boolean {
  const { isQcOrderFlowEnabled } = useFlags();

  const isPendingToReview = isQcOrderFlowEnabled
    ? transaction?.status !== TransactionStatus.PRELISTING &&
      transaction?.qualityControlStatus === QualityControlStatus.NEW_ORDER
    : transaction?.status !== TransactionStatus.PRELISTING && !transaction?.isOpsReviewComplete;

  return !isPendingToReview;
}
