import { Auth } from 'aws-amplify';
import { extractAccessListFromCognitoAttribute } from '@endpoint/common-util';
import { Severity } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { RBACRole } from '@endpoint/common-model';

type CommonIds = string | undefined;
type IntIds = CommonIds | number[];
type StringIds = CommonIds | string[];

export const getAuthToken = async (): Promise<string> => {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken();

  return idToken.getJwtToken();
};

export const signOut = async (): Promise<void> => {
  await Auth.signOut();
};

export interface OpswareUser {
  firstName: string;
  lastName: string;
  id: string;
  email: string;
  hasMultiOrganizationAccess: boolean;
  hasMultiMarketAccess: boolean;
  hasMultiSquadAccess: boolean;
  role: string;
  market: IntIds;
  organization: IntIds;
  squad: StringIds;
}

export const emptyOpswareUser = (properties: Partial<OpswareUser> = {}): OpswareUser => ({
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  hasMultiOrganizationAccess: false,
  hasMultiMarketAccess: false,
  hasMultiSquadAccess: false,
  role: '',
  market: undefined,
  organization: undefined,
  squad: undefined,
  ...properties,
});

const hasMultiAccess = (accessList: IntIds | StringIds): boolean => {
  return Array.isArray(accessList) ? accessList.length > 1 : accessList === '*';
};

export const getCurrentUser = async (): Promise<OpswareUser> => {
  let currentUser;
  let currentUserAttributes;

  try {
    currentUser = await Auth.currentAuthenticatedUser();

    if (currentUser.attributes) {
      currentUserAttributes = currentUser.attributes;
    } else {
      throw new Error('The current user has no attributes.');
    }
  } catch (error) {
    console.log(`The following error occurred: ${error}`);

    return emptyOpswareUser();
  }

  const {
    'custom:rbac_role': role,
    'custom:rbac_market': marketString,
    'custom:rbac_organization': organizationString,
    'custom:rbac_squad': squadString,
  } = currentUserAttributes;
  const market = extractAccessListFromCognitoAttribute(marketString) as IntIds;
  const organization = extractAccessListFromCognitoAttribute(organizationString) as IntIds;
  const squad = extractAccessListFromCognitoAttribute(squadString) as StringIds;

  return {
    firstName: currentUserAttributes.given_name,
    lastName: currentUserAttributes.family_name,
    id: currentUserAttributes.sub,
    email: currentUserAttributes.email,
    hasMultiOrganizationAccess: hasMultiAccess(organization),
    hasMultiMarketAccess: hasMultiAccess(market),
    hasMultiSquadAccess: hasMultiAccess(squad),
    market,
    role,
    organization,
    squad,
  };
};

export function isLoggedInUserOpsOrSystemAdmin(role: string): boolean {
  return role.includes('OPS_ADMIN') || role.includes('SYSTEM_ADMIN');
}

export function doesRoleHaveWriteAccess(role: string): boolean {
  try {
    return RBACRole.getRoleByName(role).write;
  } catch (error) {
    Sentry.captureMessage(`Invalid role: ${role}.`, Severity.Info);
  }

  return false;
}
