import React, { createContext, ReactNode, useContext, useReducer, useMemo } from 'react';
import { RelatedContactsGQLErrorType } from 'consts/createNewContact';

enum ActionKind {
  SET_RELATED_CONTACTS_GQL_ERROR = 'SET_RELATED_CONTACTS_GQL_ERROR',
}

type Payload = {
  relatedContactsGQLError: RelatedContactsGQLErrorType | null;
};

type Action = { type: ActionKind; payload: Payload };
type Dispatch = (action: Action) => void;

export type State = {
  relatedContactsGQLError: RelatedContactsGQLErrorType | null;
};

type RelatedContactsProviderProps = { children: ReactNode };

const RelatedContactsContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function relatedContactsReducer(state: State, action: Action) {
  if (action.type === ActionKind.SET_RELATED_CONTACTS_GQL_ERROR) {
    if (state.relatedContactsGQLError === action.payload.relatedContactsGQLError) return { ...state };

    return { relatedContactsGQLError: action.payload.relatedContactsGQLError };
  }

  throw new Error(`Unhandled action type: ${action.type}`);
}

function RelatedContactsProvider({ children }: RelatedContactsProviderProps) {
  const [state, dispatch] = useReducer(relatedContactsReducer, { relatedContactsGQLError: null });

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <RelatedContactsContext.Provider value={value}>{children}</RelatedContactsContext.Provider>;
}

function useRelatedContactsProvider() {
  const context = useContext(RelatedContactsContext);

  if (context === undefined) {
    throw new Error('useRelatedContactsProvider must be used within a RelatedContactsProvider');
  }

  return context;
}

export { ActionKind, RelatedContactsProvider, useRelatedContactsProvider };
