import React, { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { Image, Flex, Heading, Button } from '@endpoint/blockparty';
import DogsImg from 'static/img/404Dogs.svg';

export const NotFound: FC<RouteComponentProps> = () => (
  <Flex
    alignItems="center"
    background="slate0"
    flexDirection="column"
    height="100%"
    justifyContent="center"
    width="100%"
  >
    {/*
    // @ts-ignore - fix svg reference */}
    <Image alt="dogsImg-404-image" src={DogsImg} width="600" />
    <Heading marginTop="space70">Sorry, we couldn&apos;t find that page</Heading>
    <Button marginTop="space60" onClick={() => navigate('/')}>
      Back to Home
    </Button>
  </Flex>
);
