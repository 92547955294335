import React from 'react';
import { Box, Flex, Heading, Text } from '@endpoint/blockparty';
import { dateFormatter } from 'helpers/datetimeHelper';
import { MarketSelect } from 'components/FilterMarketSelect';
import { useQueueFilters } from 'helpers/queue';

const Header: React.FC = () => {
  const { currentParams, navigateWithParams } = useQueueFilters();

  return (
    <Heading as="h4" color="carbon900" fontWeight="normal" pb="space60" size="fontSize50">
      <Flex alignItems="center" justifyContent="space-between">
        <Box>
          <Text fontWeight="semi" size="fontSize50">
            Queue
          </Text>
        </Box>
        <Flex alignItems="center">
          <Text mr="space50">{dateFormatter(new Date(), 'eeee, MMMM d, yyyy')}</Text>
          <Box data-test-id="task-market-select">
            <MarketSelect
              defaultValue={currentParams.marketIds.map(String)}
              onChange={(marketIds) => {
                navigateWithParams({
                  marketIds,
                });
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </Heading>
  );
};

export default Header;
