import React, { FC, ReactElement, useState } from 'react';
import * as yup from 'yup';
import { Button, Flex, Input, InputErrorMessage, Text, InputGroup, Box, Icon } from '@endpoint/blockparty';
import { useMutation } from '@apollo/client';
import { UpdatedDocument, UPDATE_DOCUMENT, GET_DOCUMENTS } from 'routes/Transaction/Documents/queries';
import { ErrorMessage } from 'components/ErrorMessage';
import { Field, Formik, Form } from 'formik';
import { IconButtonWriteRequired } from 'components/IconButtonWriteRequired';

interface DocumentNameProps {
  documentId: string | undefined;
  documentName: string;
  transactionId: string;
}

interface UpdateDocumentValues {
  name: string;
}

export const DocumentName: FC<DocumentNameProps> = ({ documentId, documentName, transactionId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [hasError, setHasError] = useState(false);

  const mutationHandler = {
    onError(): void {
      setHasError(true);
    },
    onCompleted(): void {
      setIsEditing(false);
    },
  };

  const [updateDocumentName] = useMutation<UpdatedDocument>(UPDATE_DOCUMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_DOCUMENTS,
        variables: {
          where: { id: transactionId },
        },
      },
    ],
    ...mutationHandler,
  });

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('Please enter a new document name')
      .max(255, 'Please enter a name less than 255 characters')
      .matches(/^[-_0-9a-zA-Z. ]+$/, 'This is not a valid name'),
  });

  const handleUpdateDocument = (values: UpdateDocumentValues) => {
    const name = values.name.trim();

    void updateDocumentName({
      variables: {
        where: {
          id: documentId,
        },
        input: {
          name,
        },
      },
    });
  };

  return isEditing ? (
    <Formik
      initialErrors={{
        name: '',
      }}
      initialValues={{
        name: documentName,
      }}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={(values: UpdateDocumentValues, { setSubmitting }) => {
        handleUpdateDocument(values);
        setSubmitting(false);
      }}
    >
      {({ handleReset, handleSubmit, isSubmitting, errors, dirty, values }): ReactElement => {
        return (
          <Form>
            <InputGroup isInvalid={!!errors.name} pb={hasError ? 'space50' : 'space0'}>
              {/* Formik Field fontSize prop used here instead of size */}
              <Field
                aria-label="Document Name"
                as={Input}
                autoComplete="none"
                data-test-id="rename-document-input"
                fontSize="fontSize50"
                fontWeight="semi"
                name="name"
                type="text"
                value={values.name}
              />
              {errors.name && <InputErrorMessage>{errors.name}</InputErrorMessage>}
            </InputGroup>
            {hasError && (
              <ErrorMessage
                closeAction={() => {
                  setHasError(false);
                  handleReset();
                }}
                title="Unable to update document name"
              />
            )}
            <Flex justifyContent="flex-end" pb="space60" pt="space50">
              <Button
                isDisabled={isSubmitting}
                mr="space50"
                variant="outline"
                onClick={() => {
                  setIsEditing(false);
                  setHasError(false);
                  handleReset();
                }}
              >
                Cancel
              </Button>
              <Button
                data-test-id="rename-document-submit"
                isDisabled={!dirty || isSubmitting || !!errors.name}
                isLoading={isSubmitting}
                onClick={() => handleSubmit()}
              >
                <Text color="white" fontWeight="semi">
                  Save
                </Text>
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <Flex justifyContent="space-between" pb="space30">
      <Text
        as="p"
        data-test-id="document-details-title"
        fontWeight="semi"
        minWidth={240}
        pr="12px"
        size="fontSize50"
        style={{ wordWrap: 'break-word' }}
      >
        {documentName}
      </Text>
      <Box>
        <IconButtonWriteRequired
          color="carbon200"
          cursor="pointer"
          data-test-id="rename-document-button"
          name="Edit"
          size="medium"
          onClick={(): void => setIsEditing(true)}
        />
      </Box>
    </Flex>
  );
};
