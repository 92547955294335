import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Layout } from 'components/Layout';
import { init, AuthType } from '@thoughtspot/visual-embed-sdk';
import { getConfig } from 'helpers/utils/config';

import { ThoughtspotSearch, ThoughtspotLiveboard, ThoughtspotVisualization } from './Thoughtspot';
import { SubNav, TsOptions } from './SubNav';

interface AnalyticsProps extends RouteComponentProps {}

export const Analytics: FC<AnalyticsProps> = () => {
  const config: AppConfig = getConfig();
  const [activeTab, setActiveTab] = useState('Search' as TsOptions);

  init({
    thoughtSpotHost: config.THOUGHTSPOT_URL,
    authType: AuthType.None,
    callPrefetch: true,
  });

  return (
    <Layout>
      <SubNav activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === TsOptions.Search && <ThoughtspotSearch />}
      {activeTab === TsOptions.Liveboard && <ThoughtspotLiveboard />}
      {activeTab === TsOptions.Visualization && <ThoughtspotVisualization />}
    </Layout>
  );
};
