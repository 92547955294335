import React, { FC, useState } from 'react';
import { useEditContactDetailsProvider, EditContactDetailsAction } from 'hooks/useEditContactDetailsProvider';
import { useFormikContext } from 'formik';
import { UpdateContactInput } from '@endpoint/opsware-bff-graphql-schema';
import { Box, Button, Flex, Text } from '@endpoint/blockparty';

import { DiscardUnsavedChangesModal } from '../ConfirmEditModal';

export const EditContactDetailsActionBar: FC = () => {
  const { state, dispatch } = useEditContactDetailsProvider();
  const { dirty, handleReset, handleSubmit, isSubmitting, isValid } = useFormikContext<UpdateContactInput>();
  const [discardChangesModalIsOpen, setDiscardChangesModalIsOpen] = useState<boolean>(false);

  const toggleIsEditing = () => {
    dispatch({
      type: EditContactDetailsAction.SET_IS_EDITING,
      payload: { ...state, isEditing: false },
    });
  };

  const toggleModal = () => {
    setDiscardChangesModalIsOpen(!discardChangesModalIsOpen);
  };

  return (
    <Box
      backgroundColor="mist0"
      opacity="90%"
      position="sticky"
      py="space50"
      style={{ borderTop: '1px solid #C9C9C9', bottom: -24, margin: -24 }}
    >
      <Flex justifyContent="flex-end">
        <Button
          isDisabled={isSubmitting}
          mr="space50"
          variant="outline"
          onClick={() => {
            if (!dirty) {
              toggleIsEditing();

              return;
            }

            setDiscardChangesModalIsOpen(true);
          }}
        >
          Cancel
        </Button>
        <Button
          data-test-id="edit-contact-save-button"
          isDisabled={!dirty || !isValid || isSubmitting}
          mr="space60"
          onClick={() => handleSubmit()}
        >
          <Text color="white" fontWeight="semi">
            Save
          </Text>
        </Button>
      </Flex>
      <DiscardUnsavedChangesModal
        closeModal={toggleModal}
        handleIsEditing={toggleIsEditing}
        isModalOpen={discardChangesModalIsOpen}
        resetUpdateForm={handleReset}
      />
    </Box>
  );
};
