import React, { FC, useState } from 'react';
import { Box, Flex, Heading, LayoutContent, LayoutSubContainer } from '@endpoint/blockparty';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';
import { SkeletonTable } from 'components/SkeletonTable';
import { ErrorMessage } from 'components/ErrorMessage';
import { SearchInputForm } from 'components/SearchBar';
import { createSearchContactsByParamsInput, useAddressBookFilters } from 'helpers/addressBook';
import { OpswareUser } from 'components/Auth/helpers';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';
import { OrganizationSelect } from 'components/FilterOrganizationSelect';

import { SEARCH_CONTACTS_BY_PARAMS, SearchContactsResultType } from './queries';
import { ContactsTable } from '../ContactsTable';
import { ContactTypeSelect } from './ContactTypeSelect';

export const ContactsList: FC<RouteComponentProps> = () => {
  const { currentParams, navigateWithParams } = useAddressBookFilters();
  const { search, contactType, organizationIds } = currentParams;
  const [searchTerm, setSearchTerm] = useState<string>(search || '');
  const searchInput = createSearchContactsByParamsInput(currentParams);

  const { data: currentUserData } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER, {
    fetchPolicy: 'cache-only',
  });

  const displayOrgSelect = currentUserData?.me?.hasMultiOrganizationAccess;

  const { data, loading, error } = useQuery<SearchContactsResultType>(SEARCH_CONTACTS_BY_PARAMS, {
    variables: {
      input: searchInput,
    },
  });

  const contacts = data?.searchContactsResult?.contacts || [];

  const handleNavigateOnSubmit = () => {
    void navigateWithParams({ search: searchTerm, pageIndex: '1' });
  };

  return (
    <LayoutContent>
      <LayoutSubContainer height="calc(100vh - 56px)" p="space60">
        <Flex alignItems="center" justifyContent="space-between">
          <Heading as="h4" color="carbon900" pb="space60" size="fontSize50">
            Address Book
          </Heading>
          {displayOrgSelect && (
            <Box ml="space50">
              <OrganizationSelect
                defaultValue={organizationIds.map(String)}
                onChange={(newOrganizationIds) => {
                  navigateWithParams({ organizationIds: newOrganizationIds, pageIndex: '1' });
                }}
              />
            </Box>
          )}
        </Flex>
        <Flex alignItems="center" pb="space50">
          <SearchInputForm
            disabled={false}
            handleSubmit={handleNavigateOnSubmit}
            placeholder="Search by name, email, or license #"
            value={searchTerm}
            width="350px"
            onSearchTermChange={(val) => setSearchTerm(val)}
          />
          <ContactTypeSelect
            defaultValue={contactType || ''}
            onChange={(type) => {
              navigateWithParams({ contactType: type, pageIndex: '1' });
            }}
          />
        </Flex>
        {loading && <SkeletonTable />}
        {error && (
          <ErrorMessage
            description="Please refresh the page. Contact Opsware product team for assistance if this issue persists."
            title="Unable to load address book"
          />
        )}
        {data && (
          <ContactsTable
            contacts={contacts}
            currentParams={currentParams}
            loading={loading}
            totalItems={data?.searchContactsResult.totalContacts}
          />
        )}
      </LayoutSubContainer>
    </LayoutContent>
  );
};
