import React, { FC } from 'react';
import { useToast } from '@endpoint/blockparty';
import { WorkflowPrompt } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/WorkflowPrompt';
import { ButtonMilestoneValidated } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/ButtonMilestoneValidated';
import { useMutation } from '@apollo/client';
import {
  QualityControlStatus,
  Transaction,
  TransactionStatus,
  MilestoneStatus,
  MilestoneType,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TransactionUpdateResult } from 'routes/Dashboard/OpenOrderList/queries';
import { UPDATE_TRANSACTION_BASIC_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import {
  SetMilestoneStatusData,
  UPDATE_MILESTONE,
} from 'routes/Transaction/TaskList/MilestoneAccordion/EditMilestoneDrawer/queries';
import { updateQcStatusTransactionPayload } from 'helpers/updateTransaction';
import { m1ProcessingInfo, networkFailureInfo } from 'consts/toastProps';
import { useMilestoneValidationErrors } from 'hooks/useMilestoneValidationErrors';

interface WorkflowPromptQCFailedProps {
  transaction: Transaction;
}

export const WorkflowPromptQCFailed: FC<WorkflowPromptQCFailedProps> = ({ transaction }) => {
  const toast = useToast();
  const { data: milestoneValidationErrors } = useMilestoneValidationErrors(transaction.id, MilestoneType.ESCROW_OPENED);
  const [updateMilestoneStatus, { loading }] = useMutation<SetMilestoneStatusData>(UPDATE_MILESTONE, {
    onCompleted(): void {
      void updateToQCComplete();
    },
    onError(): void {
      toast(networkFailureInfo);
    },
  });
  const [updateToQCComplete] = useMutation<TransactionUpdateResult>(UPDATE_TRANSACTION_BASIC_INFO, {
    ...updateQcStatusTransactionPayload(transaction.id, QualityControlStatus.QC_COMPLETE),
    onCompleted(): void {
      toast(m1ProcessingInfo);
    },
    onError(): void {
      toast(networkFailureInfo);
    },
  });

  const updateToQCPass = (): void => {
    const milestone1 = transaction.milestones.find((milestone) => milestone.position === 1);

    void updateMilestoneStatus({
      variables: {
        data: { status: MilestoneStatus.COMPLETE },
        where: { id: milestone1?.id },
      },
    });
  };

  const shouldShow =
    transaction.status === TransactionStatus.IN_ESCROW &&
    transaction.qualityControlStatus === QualityControlStatus.QC_FAILED;

  if (!shouldShow) {
    return null;
  }

  const dataTestId = 'qc-failed-prompt';

  const issuesList = (transaction.qualityControlFailureReasons || []).concat(
    milestoneValidationErrors?.milestoneValidationErrors || [],
  );

  return (
    <WorkflowPrompt
      dataTestId={dataTestId}
      description="Review the following error(s) to pass QC."
      listValues={issuesList}
      status="error"
      title="QC Failed"
    >
      <ButtonMilestoneValidated
        data-test-id={`${dataTestId}-cta`}
        isDisabled={loading}
        transactionId={transaction.id}
        variantColor="carbon"
        onClick={updateToQCPass}
      >
        Pass QC
      </ButtonMilestoneValidated>
    </WorkflowPrompt>
  );
};
