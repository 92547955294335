import { gql } from '@apollo/client';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_BASIC_TRANSACTION_DATA = gql`
  query getTransaction($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      id
      marketId
      organizationId
      fileNum
      status
      type
      participants {
        id
        roles
        contact {
          id
          ... on AgentContact {
            firstName
            lastName
          }
          ... on LenderContact {
            firstName
            lastName
          }
          ... on IndividualContact {
            firstName
            lastName
          }
          ... on EntityContact {
            entityName
          }
        }
      }
    }
  }
`;

export interface GetBasicTransactionData {
  transaction: Transaction;
}
