import { Box, Flex } from '@endpoint/blockparty';
import React from 'react';
import { OpsSquad, OpsTaskStatus } from '@endpoint/opsware-bff-graphql-schema';

import { SearchInputForm } from '../../../components/SearchBar';
import { TaskStatusSelect } from '../../../components/FilterTaskStatusSelect';
import { TaskSquadSelect } from '../../../components/FilterTaskSquadSelect';
import { OpsTaskQueryableState, SupportedOptions } from '../../../consts/supportedTaskOptions';

interface QueueTableFiltersProps {
  defaultSquads: OpsSquad[];
  defaultStatuses: (OpsTaskStatus | OpsTaskQueryableState)[];
  searchBarFilter: string | undefined;
  fetchingOpsTasks: boolean;
  handleSearchBarChange: (term: string) => void;
  handleSearchBarSubmit: () => void;
  handleSquadsChange: (selectedValues: OpsSquad[]) => void;
  handleStatusesChange: (selectedValues: SupportedOptions[]) => void;
  showSquadsSelect?: boolean;
}

const Filters: React.FC<QueueTableFiltersProps> = ({
  handleSearchBarChange,
  handleSearchBarSubmit,
  handleSquadsChange,
  showSquadsSelect,
  handleStatusesChange,
  searchBarFilter,
  defaultSquads,
  defaultStatuses,
  fetchingOpsTasks,
}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" pb="space60">
      <Flex alignItems="center" minWidth="77%">
        <SearchInputForm
          data-test-id="queue-search-bar"
          disabled={fetchingOpsTasks}
          handleSubmit={handleSearchBarSubmit}
          placeholder="Search by Property Address or File Number"
          value={searchBarFilter}
          onSearchTermChange={(term: string) => {
            handleSearchBarChange(term);
          }}
        />
        <Box ml="space50">
          <TaskStatusSelect
            defaultValue={defaultStatuses}
            onChange={(selectedStatuses: SupportedOptions[]) => {
              handleStatusesChange(selectedStatuses);
            }}
          />
        </Box>
        <Box ml="space50">
          {showSquadsSelect && (
            <TaskSquadSelect
              defaultValue={defaultSquads}
              onChange={(selectedSquads: OpsSquad[]) => {
                handleSquadsChange(selectedSquads);
              }}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Filters;
