import { PinnedItem, TransactionRole } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import {
  PinnedItemRole,
  pinnedItemRoleLookupMap,
  PinnedItemData,
  PinnedItemM2,
  PinnedItemM6Notary,
} from 'consts/pinnedItemConsts';
import { stripIndents } from 'common-tags';
import { reverseLookupByArray } from 'helpers/lookupHelper';

export const getPinnedItemRole = (pinnedItem: PinnedItem): PinnedItemRole => {
  const supportedRoles = pinnedItem.roles.filter(
    (role) => role !== TransactionRole.PROPTECH_BUYER && role !== TransactionRole.PROPTECH_SELLER,
  );

  const stringResult = reverseLookupByArray<TransactionRole>(supportedRoles, pinnedItemRoleLookupMap);

  return stringResult as PinnedItemRole;
};

export const formatEmdMoney = (emdMoney: string) => {
  const parsedEmdMoney = parseFloat(emdMoney);

  return (parsedEmdMoney && parsedEmdMoney.toFixed(2)) || '0.00';
};

export const getPinnedItemData = (pinnedItem: PinnedItem, milestonePosition: number): PinnedItemData => {
  const pinnedItemData = pinnedItem.data || {};
  const pinnedItemRole = getPinnedItemRole(pinnedItem);

  if (milestonePosition === 2) {
    const data: PinnedItemM2 = {
      title: 'How to Pay with a Check',
      paymentDetails: stripIndents`
        $${formatEmdMoney(pinnedItemData.emd_money || pinnedItemData.emdAmount)}
        Endpoint Closing, Inc.
      `,
    };

    return data;
  }

  if (milestonePosition === 6) {
    if (pinnedItemRole === PinnedItemRole.BUYER || pinnedItemRole === PinnedItemRole.SELLER) {
      const data: PinnedItemM6Notary = {
        title: 'Signing Appointment',
        time: `${pinnedItemData.date} ${pinnedItemData.time}`,
        location: (pinnedItemData.locationOfSigning || '-').toString(),
        notary: stripIndents`
          ${(pinnedItemData.notaryName || '-').toString()}
          ${(pinnedItemData.notaryPhone || '-').toString()}
        `,
      };

      return data;
    }

    return {
      title: 'How to Pay Funds for Closing',
    };
  }

  return {
    title: 'Recording Complete',
  };
};
