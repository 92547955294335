import React, { FC } from 'react';
import { MultiSelect } from '@endpoint/blockparty';
import { TaskStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { mapStatusText } from 'helpers/statusHelper';
import { taskStatusMap } from 'consts/taskConsts';

interface TaskStatusFilterOptions {
  value: TaskStatus;
  label: string;
}

const filterOptions: TaskStatusFilterOptions[] = Object.values(TaskStatus).map((status) => ({
  value: status,
  label: mapStatusText(status, taskStatusMap),
}));

export interface TaskStatusFilterProps {
  onChange(status: TaskStatus[]): void;
}

export const TaskStatusFilter: FC<TaskStatusFilterProps> = ({ onChange }) => (
  <MultiSelect
    controlText="Task Status"
    options={filterOptions}
    onChange={(value): void => {
      const valueTyped = value as TaskStatusFilterOptions[];

      onChange(valueTyped.map((values) => values.value));
    }}
  />
);
