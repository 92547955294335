import React, { Children, FC, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Card, Heading, Button, Flex, Text } from '@endpoint/blockparty';
import { SkeletonTable } from 'components/SkeletonTable';
import { TransactionSearchResult } from '@endpoint/opsware-bff-graphql-schema';
import useMarketsAndOrganizations from 'hooks/useMarketsAndOrganizations';
import { SearchInputForm } from 'components/SearchBar';
import { ErrorMessage } from 'components/ErrorMessage';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { TrackingEvent } from 'consts/segmentProtocols';
import { OpenTransactionDrawer } from 'components/OpenOrderDrawer';
import { cloneDeep } from 'lodash';
import { MarketSelect } from 'components/FilterMarketSelect';
import { dateFormatter } from 'helpers/datetimeHelper';
import { OpenOrderListTable } from 'routes/Dashboard/OpenOrderList/OpenOrderListContent/OpenOrderListTable';
import { OrganizationSelect } from 'components/FilterOrganizationSelect';
import { useOpenOrderFilters } from 'helpers/openOrderList/';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';

import { GET_OPEN_TRANSACTIONS, TransactionSearchResults } from '../queries';
import { ProductTypeSelect } from '../ProductTypeSelect';
import { OrderStatusSelect } from '../OrderStatusSelect';
import { OrderSourceSelect } from '../OrderSourceSelect';
import { NeedsReviewSelect } from '../NeedsReviewSelect';

export const OpenOrderListContent: FC = () => {
  const { currentParams, navigateWithParams } = useOpenOrderFilters();
  const { showOrganizationDropdown, showMarketDropdown, hasMultiOrgAccess, organizations } =
    useMarketsAndOrganizations();
  const { sort, search, needsReview, marketIds, organizationIds, escrowProductTypes, orderStatuses, orderSources } =
    currentParams;

  const { isQcStatusEnabled, isQcSourceEnabled } = useFlags();
  const client = useApolloClient();
  const [pendingSearchTerm, updatePendingSearchTerm] = useState(search);
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  let orgIds = organizations.map((org) => org.id);
  const [organizationId, setOrganizationId] = useState<number[]>(organizationIds);

  if (hasMultiOrgAccess) {
    orgIds = organizationIds;
  }

  let isOpsReviewComplete;

  if (needsReview.length === 1) {
    isOpsReviewComplete = needsReview[0] !== 'yes';
  }

  const { data, loading, error } = useQuery<TransactionSearchResults>(GET_OPEN_TRANSACTIONS, {
    variables: {
      search: {
        query: search.replace(/,\s*/g, ' '),
        filter: {
          escrowProductTypes: escrowProductTypes.length ? escrowProductTypes : null,
          marketIds: marketIds.length ? marketIds : null,
          isPending: true,
          organizationIds: orgIds?.length ? orgIds : null,
          qualityControlStatuses: orderStatuses.length ? orderStatuses : null,
          isOpsReviewComplete,
          sources: orderSources?.length ? orderSources : null,
        },
        sort: {
          propertyName: sort[0] || 'CREATED_ON',
          direction: sort[1] || 'DESC',
        },
        itemsPerPage: 50,
        pageIndex: 0,
      },
    },
  });

  const queriedTransactions: TransactionSearchResult[] = cloneDeep(
    data?.searchTransactions || ([] as TransactionSearchResult[]),
  );

  // most recent transactions first
  const sortedTransactions: TransactionSearchResult[] | null = loading
    ? ([] as TransactionSearchResult[])
    : queriedTransactions.sort(
        (firstTransaction: TransactionSearchResult, secondTransaction: TransactionSearchResult) => {
          const transactionA = new Date(firstTransaction.createdAt).getTime();
          const transactionB = new Date(secondTransaction.createdAt).getTime();

          return transactionB - transactionA;
        },
      ) || ([] as TransactionSearchResult[]);

  const trackingEvent: TrackingEvent = 'Open Order Table Searched';

  return (
    <>
      <Box m="space60">
        <Heading as="h4" color="carbon900" fontWeight="normal" pb="space60" size="fontSize50">
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              Dashboard /{' '}
              <Text fontWeight="semi" size="fontSize50">
                Open Order
              </Text>
            </Box>
            <Flex alignItems="center">
              <Text>{dateFormatter(new Date(), 'eeee, MMMM d, yyyy')}</Text>
              {showOrganizationDropdown && (
                <Box ml="space60">
                  <OrganizationSelect
                    defaultValue={organizationIds.map(String)}
                    onChange={(newOrganizationIds) => {
                      navigateWithParams({ organizationIds: newOrganizationIds });
                      setOrganizationId(newOrganizationIds);
                    }}
                  />
                </Box>
              )}
              {showMarketDropdown && (
                <Box ml="space50">
                  <MarketSelect
                    defaultValue={marketIds.map(String)}
                    organizationValue={organizationId}
                    onChange={(newMarketIds) => {
                      navigateWithParams({ marketIds: newMarketIds });
                    }}
                  />
                </Box>
              )}
            </Flex>
          </Flex>
        </Heading>
        <Flex alignItems="center" pb="space60">
          <SearchInputForm
            disabled={loading}
            handleSubmit={(): void => {
              trackAnalytics(trackingEvent, {
                search: pendingSearchTerm,
                table: 'Transactions Table',
              });

              navigateWithParams({ search: pendingSearchTerm });
            }}
            placeholder="Search by Property Address or File Number"
            value={pendingSearchTerm}
            width="375px"
            onSearchTermChange={(term: string) => {
              updatePendingSearchTerm(term);
            }}
          />
          <Box ml="space60">
            <ProductTypeSelect
              defaultValue={escrowProductTypes}
              onChange={(newEscrowProductTypes) => {
                navigateWithParams({ escrowProductTypes: newEscrowProductTypes });
              }}
            />
          </Box>
          <Box ml="space60">
            {!isQcStatusEnabled ? (
              <NeedsReviewSelect
                defaultValue={needsReview}
                onChange={(newNeedsReview) => {
                  navigateWithParams({ needsReview: newNeedsReview });
                }}
              />
            ) : (
              <OrderStatusSelect
                defaultValue={orderStatuses}
                onChange={(newOrderStatuses) => {
                  navigateWithParams({ orderStatuses: newOrderStatuses });
                }}
              />
            )}
          </Box>
          {isQcSourceEnabled && (
            <Box ml="space60">
              <OrderSourceSelect
                defaultValue={orderSources}
                onChange={(newOrderSources) => {
                  navigateWithParams({ orderSources: newOrderSources });
                }}
              />
            </Box>
          )}
          <Box ml="auto" whiteSpace="nowrap">
            <ButtonWriteRequired
              data-test-id="open-new-transaction-button"
              isDisabled={loading}
              ml="auto"
              size="large"
              variant="outline"
              onClick={() => {
                trackAnalytics('New Order Clicked', {});

                setDrawerIsOpen(true);
              }}
            >
              New Order
            </ButtonWriteRequired>
          </Box>
        </Flex>
        <Card variant="shadow">
          {loading && <SkeletonTable />}
          {error && <ErrorMessage />}
          {data?.searchTransactions && <OpenOrderListTable transactions={sortedTransactions} />}
        </Card>
      </Box>

      <OpenTransactionDrawer
        client={client}
        data-test-id="open-new-transaction-drawer"
        isOpen={drawerIsOpen}
        setDrawerIsOpen={() => setDrawerIsOpen(false)}
      />
    </>
  );
};
