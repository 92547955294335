import { TransactionType, TransactionCreateInput } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { ApolloClient, gql } from '@apollo/client';
import { buildOpenEscrowPayload } from 'helpers/openOrder/buildOpenEscrowPayload';
import { OrganizationType } from 'consts/organizationConsts';
import { toLocalIsoDate } from 'helpers/datetimeHelper';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';

interface OpenOrderInputFees {
  sellerCreditsBuyer?: number;
  titleFees?: number;
}
interface OpenOrderInput extends TransactionCreateInput {
  address: {
    street1: string;
    street2?: string;
    city: string;
    state: string;
    zip: string;
    county: string;
  };
  isOpsReviewComplete: boolean;
  parcelNumber?: string;
  market?: string;
  terms: {
    productType: TransactionType;
    salePrice?: number;
    loanAmount?: number;
    loanNumber?: string;
    emdAmount?: number;
  };
  fees: OpenOrderInputFees;
  dates: DateTypes;
  purchaseAgreementDocumentId?: string;
}

interface DateTypes {
  estimatedClosing?: Date;
  emdContractDate?: Date;
  contractExecutedDate?: Date;
}

export async function createTransaction(data: OpenOrderDrawerForm, client: ApolloClient<Object>) {
  const openEscrowValues = buildOpenEscrowPayload(data);

  // If no data.organization exists, default to 1 to match transaction service default.
  const organization = data.organization ? Number(data.organization) : OrganizationType.ENDPOINT_CLOSING;

  const createInput: OpenOrderInput = {
    ...openEscrowValues,
    isOpsReviewComplete: true,
    organizationId: organization,
    isTest: data?.isTest,
  };

  Object.keys(createInput.dates).forEach((key) => {
    const dateKey = key as keyof DateTypes;

    if (createInput.dates[dateKey]) {
      createInput.dates[dateKey] = toLocalIsoDate(createInput.dates[dateKey] as Date);
    }
  });

  return client
    .mutate({
      mutation: query,
      variables: { createInput },
    })
    .then((results) => results)
    .catch((error) => error);
}

const query = gql`
  mutation createTransaction($createInput: TransactionCreateInput!) {
    createTransaction(data: $createInput) {
      id
      createdAt
    }
  }
`;
