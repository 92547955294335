import React, { ReactElement } from 'react';
import { Icon, Loading } from '@endpoint/blockparty';
import { DocumentUpload, DocumentUploadStatus } from 'consts/uploadDocumentsConsts';
import { mapStatusText } from 'helpers/statusHelper';
import { ThemeShape } from '@endpoint/blockparty/dist/theme';

type DocumentUploadProgress = DocumentUpload['status'];
type UploadStatusToString = Record<DocumentUploadProgress, keyof ThemeShape['colors']>;
type UploadStatusToComponent = Record<DocumentUploadProgress, ReactElement>;

export const ProgressColorMap: UploadStatusToString = {
  [DocumentUploadStatus.COMPLETED]: 'aloe500',
  [DocumentUploadStatus.ERROR]: 'watermelon500',
  [DocumentUploadStatus.INPROGRESS]: 'turquoise500',
};

export const ProgressBorderColorMap: UploadStatusToString = {
  [DocumentUploadStatus.COMPLETED]: 'carbon300',
  [DocumentUploadStatus.ERROR]: 'watermelon500',
  [DocumentUploadStatus.INPROGRESS]: 'carbon300',
};

export const ProgressBackgroundColorMap: UploadStatusToString = {
  [DocumentUploadStatus.ERROR]: 'watermelon0',
  [DocumentUploadStatus.COMPLETED]: 'white',
  [DocumentUploadStatus.INPROGRESS]: 'white',
};

export const ProgressIconMap: UploadStatusToComponent = {
  [DocumentUploadStatus.COMPLETED]: (
    // @ts-ignore - revisit
    <Icon color={mapStatusText(DocumentUploadStatus.COMPLETED, ProgressColorMap)} name="CheckCircle" size="large" />
  ),
  [DocumentUploadStatus.ERROR]: (
    // @ts-ignore - revisit
    <Icon color={mapStatusText(DocumentUploadStatus.ERROR, ProgressColorMap)} name="Information" size="large" />
  ),
  [DocumentUploadStatus.INPROGRESS]: <Loading />,
};
