import {
  UpdateContactInput,
  StateOfOperation,
  TransactionParticipantUpdateInput,
} from '@endpoint/opsware-bff-graphql-schema';
import * as yup from 'yup';
import { AllContactTypes } from 'consts/contactDetails';
import { isKeyInContactObject } from 'helpers/contacts';
import { editContactDisplayableErrors } from 'consts/updateContact';
import { updatedDiff } from 'deep-object-diff';
import { NewContactSchema } from 'consts/createNewContact';
import { ApolloError } from '@apollo/client';

type UpdateContactInputProps = Array<keyof UpdateContactInput>;
interface UpdateContactValidationItems {
  firstName?: string;
  middleName?: string | null;
  lastName?: string;
  entityName?: string;
  nmlsId?: string | null;
  licenseNumber?: string | null;
  email?: string;
  phoneNumber?: string | null;
  stateOfOperation?: string | null;
}

export const generateInitialEditContactValues = (contact: AllContactTypes): UpdateContactInput => {
  const stateOfOperation: StateOfOperation | undefined =
    StateOfOperation?.[contact?.stateOfOperation as StateOfOperation];

  return {
    firstName: contact.firstName,
    middleName: contact.middleName,
    lastName: contact.lastName,
    email: contact.email,
    phoneNumber: contact.phone,
    entityName: contact.entityName,
    licenseNumber: contact.licenseNumber,
    mlsId: contact.mlsId,
    nmlsId: contact.nmlsId,
    stateOfOperation,
  };
};

export const generateUpdateContactValidation = (
  contact: AllContactTypes,
): yup.ObjectSchema<yup.Shape<object | undefined, UpdateContactValidationItems>, object> => {
  const isEntity = isKeyInContactObject(contact, 'entityName');

  if (isEntity) {
    return yup.object().shape({
      entityName: yup.string().required('Name is a required field'),
      email: yup.string().email('Please enter a valid email address').required('Email is a required field'),
      phoneNumber: yup.string().nullable(),
    });
  }

  return yup.object().shape(
    {
      firstName: yup.string().required('First Name is a required field'),
      middleName: yup.string().nullable(),
      lastName: yup.string().required('Last Name is a required field'),
      nmlsId: yup
        .string()
        .matches(/^[0-9]*$/, 'NMLS ID can only contain numbers')
        .nullable(),
      email: yup.string().email('Please enter a valid email address').required('Email is a required field'),
      phoneNumber: yup.string().nullable(),
      licenseNumber: yup.string().when('stateOfOperation', {
        is: (stateOfOperation) => !!stateOfOperation,
        then: yup
          .string()
          .matches(/^[a-zA-Z0-9]*$/, 'License Number can only be alphanumeric')
          .required('Missing License Number')
          .nullable(),
        otherwise: yup
          .string()
          .max(0, 'License # must be paired with State of Operation. Either complete or clear both fields to save.')
          .nullable(),
      }),
      stateOfOperation: yup.string().when('licenseNumber', {
        is: (licenseNumber) => !!licenseNumber,
        then: yup.string().required('Missing State of Operation').nullable(),
        otherwise: yup
          .string()
          .max(0, 'State of Operation must be paired with License #. Either complete or clear both fields to save.')
          .nullable(),
      }),
    },
    [['licenseNumber', 'stateOfOperation']],
  );
};

export const generateEditContactSubmitValues = (
  initialValues: UpdateContactInput,
  newValues: UpdateContactInput,
): UpdateContactInput => {
  const formUpdates: UpdateContactInput = updatedDiff(initialValues, newValues) as UpdateContactInput;

  // set empty string changes from Formik to null to unset optional properties
  for (const [key, value] of Object.entries(formUpdates)) {
    // @ts-ignore null is a valid value, it must be sent when "unsetting" the original value
    if (value === '') formUpdates[key] = null;
  }

  return formUpdates;
};

export const generateEditTransactionParticipantsValues = (updatedContactFields: UpdateContactInput) => {
  const updatedFields: TransactionParticipantUpdateInput = {};
  const excludedProperties = ['email', 'phoneNumber'];

  for (const [key, value] of Object.entries(updatedContactFields)) {
    if (!excludedProperties.includes(key)) {
      // @ts-ignore
      updatedFields[key] = value;
    }
  }

  return updatedFields;
};

// remove filler name form values set to fields firstName, lastName, entityName from diff
// this is needed to validate the form when switching between contactTypes
// the contact is not always returned from the searchContact or findContact query, so it cannot be ommitted from initial values
export const cleanseUpdateContactInput = (
  stateData: NewContactSchema,
  formValues: NewContactSchema,
): NewContactSchema => {
  const formUpdateDiff: NewContactSchema = updatedDiff(stateData, formValues) as NewContactSchema;
  const cleansedUpdateInput = {} as NewContactSchema;

  Object.entries(formUpdateDiff).forEach(([key, value]) => {
    // @ts-ignore - Object.entries does not allow typing on key or value - key -> Type string, value -> type any per the spec
    // https://effectivetypescript.com/2020/05/26/iterate-objects/ section on Object.entries
    if (value !== 'filler name')
      // @ts-ignore - Object.entries does not allow typing on key or value - key -> Type string, value -> type any per the spec
      cleansedUpdateInput[key] = value;
    // @ts-ignore null is a valid value, it must be sent when "unsetting" the original value
    if (value === '') cleansedUpdateInput[key] = null;
  });

  return cleansedUpdateInput;
};

export const isEmailOffline = (email: string): boolean => {
  return /^offline\+.*@endpointclosing.com$/.test(email);
};

export const getDisplayableError = (error: ApolloError): undefined | string => {
  let message;
  const errors = error.graphQLErrors;
  const displayableErrors = Object.values(editContactDisplayableErrors);

  errors.forEach((err) => {
    const errMessage = err.extensions?.response?.message;

    if (errMessage && displayableErrors.includes(errMessage)) {
      message = errMessage;
    }
  });

  return message;
};

export const maskPhone: {
  numericOnly: boolean;
  blocks: number[];
  delimiters: string[];
} = { numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ['(', ')', ' ', '-'] };
