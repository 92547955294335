import { QueryInput } from 'consts/transactionConsts';

export function buildQueryInput(transactionIdentifier?: string): QueryInput {
  if (!transactionIdentifier) {
    return {
      fileNum: '',
    };
  }

  if (transactionIdentifier.startsWith('id-')) {
    return { id: transactionIdentifier.split('id-')[1] };
  }

  return {
    fileNum: transactionIdentifier,
  };
}
