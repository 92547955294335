export enum ValidPlatform {
  Workspace = 'Workspace',
  Admin = 'Admin',
}
export interface Platform {
  title: ValidPlatform;
  subtitle: string;
  path?: string;
  url?: string;
}
