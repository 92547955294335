import React, { FC } from 'react';
import { useField } from 'formik';
import { InputGroup, InputLabel, Textarea } from '@endpoint/blockparty';

export interface InputProps {
  value?: string;
  name: string;
  label?: string;
  placeholder?: string;
  onChange?: (e: unknown) => void; // instead of Date | React.ChangeEvent<HTMLFormElement> or some kind of event typing
}

export const TextArea: FC<InputProps> = ({ label, name, placeholder, onChange, value }) => {
  const [field] = useField({
    name,
    placeholder,
    onChange,
    value,
  });

  return (
    <InputGroup>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Textarea id={name} placeholder={placeholder} {...field} />
    </InputGroup>
  );
};
