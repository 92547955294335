import { gql } from '@apollo/client';
import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';

// will need notification types
export const GET_PARTICIPANT = gql`
  query getParticipant($where: TransactionParticipantWhereUniqueInput!) {
    transactionParticipant(where: $where) {
      id
      roles
      contactType
      firstName
      lastName
      middleName
      suffix
      entityName
      mlsId
      licenseNumber
      stateOfOperation
      isDirectingContact
      transaction {
        id
        fileNum
        status
      }
      todoAssignments {
        id
        status
        statusOptions
        todo {
          id
          name
          due
        }
      }
      contact {
        id
        email
        contactType
        phone
        createdAt
        isOnboarded
        isDeactivated
        notificationPreferences
        ... on AgentContact {
          licenseNumber
          stateOfOperation
          firstName
          lastName
          middleName
          mlsId
        }
        ... on LenderContact {
          nmlsId
          firstName
          lastName
          middleName
        }
        ... on IndividualContact {
          firstName
          lastName
          middleName
        }
        ... on EntityContact {
          entityName
        }
      }
    }
  }
`;

export const REMOVE_PARTICIPANT_ROLES = gql`
  mutation removeTransactionParticipantRoles(
    $where: TransactionParticipantWhereUniqueInput!
    $roles: [TransactionRole!]!
  ) {
    removeRoles(where: $where, roles: $roles) {
      id
      roles
    }
  }
`;
export interface GetParticipant {
  transactionParticipant: TransactionParticipant;
}
