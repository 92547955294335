import React, { FC, ReactElement } from 'react';
import { Avatar, AvatarIcon } from '@endpoint/blockparty';
import { formatToCamelCase } from 'helpers/formatText';

export interface AvatarProps {
  name: string | '';
  src?: string;
  alertTypes?: string[];
  size?: number;
}

export const OpswareAvatar: FC<AvatarProps> = ({ name, src, alertTypes, size = 40 }) => (
  <Avatar data-test-id={`avatar-${formatToCamelCase(name)}`} name={name} size={size} src={src}>
    {setAlertIcon(alertTypes || [])}
  </Avatar>
);

export const setAlertIcon = (alertTypes: string[]): ReactElement | null => {
  if (alertTypes.includes('NON_ENGLISH') || (alertTypes.includes('OFFLINE') && !alertTypes.includes('NOT_ONBOARDED'))) {
    return <AvatarIcon bg="watermelon500" color="white" data-test-id="avatar-icon-watermelon" name="Information" />;
  }

  if (alertTypes.includes('NOT_ONBOARDED')) {
    return <AvatarIcon bg="marigold400" color="carbon0" data-test-id="avatar-icon-marigold" name="Minus" />;
  }

  return null;
};
