import React, { FC } from 'react';
import { Button, useToast } from '@endpoint/blockparty';
import { WorkflowPrompt } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/WorkflowPrompt';
import { ButtonMilestoneValidated } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/ButtonMilestoneValidated';
import { useMutation } from '@apollo/client';
import {
  MilestoneStatus,
  MilestoneType,
  QualityControlStatus,
  Transaction,
  TransactionStatus,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TransactionUpdateResult } from 'routes/Dashboard/OpenOrderList/queries';
import { UPDATE_TRANSACTION_BASIC_INFO, GET_TRANSACTION_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import {
  SetMilestoneStatusData,
  UPDATE_MILESTONE,
} from 'routes/Transaction/TaskList/MilestoneAccordion/EditMilestoneDrawer/queries';
import { updateQcStatusTransactionPayload } from 'helpers/updateTransaction';
import { m1ProcessingInfo, networkFailureInfo } from 'consts/toastProps';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';
import { useMilestoneValidationErrors } from 'hooks/useMilestoneValidationErrors';

import { ModalQCFailureReasons } from './ModalQCFailureReasons';

interface WorkflowPromptQCReadyProps {
  transaction: Transaction;
}

export const WorkflowPromptQCReady: FC<WorkflowPromptQCReadyProps> = ({ transaction }) => {
  const toast = useToast();
  const { data: milestoneValidationErrors } = useMilestoneValidationErrors(transaction.id, MilestoneType.ESCROW_OPENED);
  const [updateMilestoneStatus, { loading }] = useMutation<SetMilestoneStatusData>(UPDATE_MILESTONE, {
    onCompleted(): void {
      void updateToQCComplete();
    },
    onError(): void {
      toast(networkFailureInfo);
    },
  });
  const [updateToQCComplete] = useMutation<TransactionUpdateResult>(UPDATE_TRANSACTION_BASIC_INFO, {
    ...updateQcStatusTransactionPayload(transaction.id, QualityControlStatus.QC_COMPLETE),
    onCompleted(): void {
      toast(m1ProcessingInfo);
    },
    onError(): void {
      toast(networkFailureInfo);
    },
    refetchQueries: [
      {
        query: GET_TRANSACTION_INFO,
        variables: {
          where: {
            id: transaction.id,
          },
        },
      },
    ],
  });
  const shouldShow =
    transaction.status === TransactionStatus.IN_ESCROW &&
    transaction.qualityControlStatus === QualityControlStatus.QC_READY;

  const updateToQCPass = (): void => {
    const milestone1 = transaction.milestones.find((milestone) => milestone.position === 1);

    void updateMilestoneStatus({
      variables: {
        data: { status: MilestoneStatus.COMPLETE },
        where: { id: milestone1?.id },
      },
    });
  };

  if (!shouldShow) {
    return null;
  }

  const dataTestId = 'qc-ready-prompt';

  return (
    <WorkflowPrompt
      dataTestId={dataTestId}
      description="Pass or fail this order to complete QC."
      listValues={milestoneValidationErrors?.milestoneValidationErrors}
      status="warning"
      title="Ready for QC"
    >
      <ModalQCFailureReasons transaction={transaction}>
        <ButtonWriteRequired
          data-test-id={`${dataTestId}-fail-cta`}
          style={{
            color: '#474747', // carbon700
            outlineColor: '#474747', // carbon700
            marginRight: '16px',
          }}
          variant="outline"
          variantColor="carbon"
        >
          Fail QC
        </ButtonWriteRequired>
      </ModalQCFailureReasons>
      <ButtonMilestoneValidated
        data-test-id={`${dataTestId}-pass-cta`}
        isDisabled={loading}
        transactionId={transaction.id}
        variant="outline"
        variantColor="carbon"
        onClick={updateToQCPass}
      >
        Pass QC
      </ButtonMilestoneValidated>
    </WorkflowPrompt>
  );
};
