import React from 'react';
import {
  Layout as BaseLayout,
  LayoutContainer,
  LayoutSubContainer,
  LayoutLeftPanel,
  SkeletonText,
  LayoutSubHeader,
  LayoutContent,
  LayoutSubContent,
} from '@endpoint/blockparty';
import { Header } from 'components/Header';
import { generateLoadingContentSkeltons } from 'components/LoadingContentSkeleton';

export function LayoutLoader() {
  return (
    <BaseLayout>
      <Header />
      <LayoutContainer>
        <LayoutLeftPanel borderColor="carbon300" borderRight="1px solid" pt="space50">
          <SkeletonText lines={1} px="space60" py="space30" />
          <SkeletonText lines={1} px="space60" py="space30" />
          <SkeletonText lines={1} px="space60" py="space30" />
        </LayoutLeftPanel>
        <LayoutSubContainer>
          <LayoutSubHeader p="space60" />
          <LayoutContent>
            <LayoutSubContent p="space60">{generateLoadingContentSkeltons(3, 3, 'basicInfo')}</LayoutSubContent>
          </LayoutContent>
        </LayoutSubContainer>
      </LayoutContainer>
    </BaseLayout>
  );
}
