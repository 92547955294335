import React, { FC } from 'react';
import {
  InputGroup,
  Flex,
  InputLabel,
  InputElemGroup,
  DatePicker,
  InputRightElement,
  Icon,
  Divider,
} from '@endpoint/blockparty';
import { EditTransactionBasicInfoDateProps } from 'consts/EditTransactionBasicInfoDateProps';

import { NonEditableDateField } from './NonEditableDateField';

export const EditTransactionBasicInfoRefiDateFields: FC<EditTransactionBasicInfoDateProps> = ({
  setFieldValue,
  focusField,
  values,
  transaction,
}) => {
  return (
    <>
      <InputGroup>
        <InputElemGroup mb="space60">
          <NonEditableDateField date={values.escrowOpenedDate} label="Contract Received Date" />
        </InputElemGroup>
        <Divider mt="space30" />
      </InputGroup>
      <InputGroup>
        <InputElemGroup mb={transaction.sellerSigning?.date ? null : 'space60'}>
          <NonEditableDateField date={transaction.sellerSigning?.date} label="Borrower Signing Date" />
        </InputElemGroup>
        <Divider mt="space30" />
      </InputGroup>
      <InputGroup>
        <Flex flexDirection="row" justifyContent="space-between">
          <InputLabel>Estimated Closing Date</InputLabel>
        </Flex>
        <InputElemGroup>
          <DatePicker
            autoFocus={focusField === 'Estimated Closing Date'}
            dataTestId="estimated-closing-date-picker"
            selected={values.estimatedClosing ? new Date(values.estimatedClosing) : null}
            onChange={(date) => {
              setFieldValue('estimatedClosing', date);
            }}
          />
          <InputRightElement>
            <Icon color="carbon400" name="CalendarDateRange" size="20px" />
          </InputRightElement>
        </InputElemGroup>
      </InputGroup>
    </>
  );
};
