import { Box, MultiSelect } from '@endpoint/blockparty';
import { statusDisplayValueMap, supportedStatuses } from 'consts/supportedStatus';
import React, { FC } from 'react';

const selectStatusOptions = supportedStatuses.map((status) => ({
  value: status,
  label: statusDisplayValueMap[status],
}));

interface StatusSelectProps {
  onChange(statuses: string[]): void;
  defaultValue?: string[];
}

export const StatusSelect: FC<StatusSelectProps> = ({ onChange, defaultValue = [] }) => {
  const defaultValueOptions = selectStatusOptions.filter((option) => {
    return defaultValue.includes(option.value);
  });

  return (
    <Box data-test-id="status-select" minWidth={190}>
      <MultiSelect
        controlText="Status"
        data-test-id="status-select-filter"
        defaultValue={defaultValueOptions}
        options={selectStatusOptions}
        onChange={(values: { value: string }[]) => onChange(values.map((value) => String(value.value)))}
      />
    </Box>
  );
};
