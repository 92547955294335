import { getConfig } from 'helpers/utils/config';

import { Platform, ValidPlatform } from './types';

const config: AppConfig = getConfig();

export const platforms: Platform[] = [
  {
    title: ValidPlatform.Workspace,
    subtitle: 'Manage Endpoint transactions',
    path: '/',
  },
  {
    title: ValidPlatform.Admin,
    subtitle: 'Edit Opsware functionality',
    path: '/admin',
    url: config.ADMIN_CLIENT_URL,
  },
];
