import React, { FC } from 'react';
import { LayoutContent, LayoutSubContent, Flex, Box, Card, SkeletonBlock, SkeletonText } from '@endpoint/blockparty';

import { BasicInfoRightPanel } from './RightPanel';

interface SkeletonBasicInfoProps {
  isRightPanelShown?: boolean;
}

export const SkeletonBasicInfo: FC<SkeletonBasicInfoProps> = ({ isRightPanelShown = true }) => {
  return (
    <LayoutContent>
      <LayoutSubContent>
        <Flex p="space60">
          <Box mr="space60" width="100%">
            <Card mb="space40" p="space60">
              <Flex>
                <SkeletonBlock height={120} mr="space50" width={150} />
                <SkeletonText lines={1} width={400} />
              </Flex>
            </Card>
            <Card height="450px" p="space60">
              <Flex pb="space60">
                <SkeletonText lines={3} width="100%" />
              </Flex>
              <Flex pb="space60">
                <SkeletonText lines={3} width="100%" />
              </Flex>
              <Flex pb="space60">
                <SkeletonText lines={3} width="100%" />
              </Flex>
              <Flex pb="space60">
                <SkeletonText lines={3} width="100%" />
              </Flex>
              <Flex pb="space60">
                <SkeletonText lines={3} width="100%" />
              </Flex>
              <Flex pb="space60">
                <SkeletonText lines={3} width="100%" />
              </Flex>
            </Card>
          </Box>
          {isRightPanelShown && <BasicInfoRightPanel />}
        </Flex>
      </LayoutSubContent>
    </LayoutContent>
  );
};
