import React, { FC } from 'react';
import { Grid } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';
import { TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { EditTransactionBasicInfoSaleDateFields } from 'components/EditTransactionBasicInfo/EditTransactionBasicInfoSaleDateFields';
import { NonEditableDateField } from 'components/EditTransactionBasicInfo/NonEditableDateField';
import { EditTransactionBasicInfoRefiDateFields } from 'components/EditTransactionBasicInfo/EditTransactionBasicInfoRefiDateFields';
import { useEditTransactionBasicInfoContext } from 'hooks/useEditTransactionBasicInfoContext';

import { EditTransactionBasicInfoDrawer } from '../types/EditTransactionBasicInfoDrawer';
import { BasicInfoDatesProps } from '.';

export const BasicInfoDatesEditingForm: FC<BasicInfoDatesProps> = ({ transaction }) => {
  const { focusField } = useEditTransactionBasicInfoContext();
  const { setFieldValue, values } = useFormikContext<EditTransactionBasicInfoDrawer>();

  const renderDateFields = () => {
    switch (values.productType) {
      case TransactionType.SALE_CASH:
      case TransactionType.SALE_MORTGAGE:
        return (
          <EditTransactionBasicInfoSaleDateFields
            focusField={focusField}
            setFieldValue={setFieldValue}
            transaction={transaction}
            values={values}
          />
        );
      case TransactionType.PRELISTING:
        return <NonEditableDateField date={values.createdDate} label="Opened Date" />;
      case TransactionType.EQUITY:
      case TransactionType.REFINANCE:
        return (
          <EditTransactionBasicInfoRefiDateFields
            focusField={focusField}
            setFieldValue={setFieldValue}
            transaction={transaction}
            values={values}
          />
        );
      default:
        return (
          <EditTransactionBasicInfoSaleDateFields
            focusField={focusField}
            setFieldValue={setFieldValue}
            transaction={transaction}
            values={values}
          />
        );
    }
  };

  return (
    <Grid
      columnGap="space60"
      data-test-id="basic-info-dates-editing-form"
      pb="space50"
      pt="space30"
      px="space50"
      rowGap="space40"
      templateColumns="repeat(2, minmax(150px, 1fr))"
    >
      {renderDateFields()}
    </Grid>
  );
};
