import React, { FC } from 'react';
import { Box, MultiSelect } from '@endpoint/blockparty';
import { SelectStyleProps } from '@endpoint/blockparty/dist/components/Select/styles';
import { OriginationSource } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const validSourceOptions = [OriginationSource.Email, OriginationSource.Endpoint, OriginationSource.PartnerAPI];

export const MultiSelectOptions = validSourceOptions.map((option) => ({
  value: option,
  label: option,
}));

interface OrderSourceSelectProps {
  onChange(options: OriginationSource[]): void;
  defaultValue?: OriginationSource[];
}

export const OrderSourceSelect: FC<OrderSourceSelectProps> = ({ onChange, defaultValue }) => {
  const defaultValueOptions = MultiSelectOptions.filter((option) => {
    return defaultValue && defaultValue.includes(option.value);
  });

  return (
    <Box data-testid="open-order-source-select" minWidth={140}>
      <MultiSelect
        controlText="Source"
        customStyles={{
          menu: (base: SelectStyleProps) => ({
            ...base,
            minWidth: 120,
            right: 0,
          }),
        }}
        defaultValue={defaultValueOptions}
        options={MultiSelectOptions}
        onChange={(values: { value: OriginationSource }[]) => onChange(values.map((value) => value.value))}
      />
    </Box>
  );
};
