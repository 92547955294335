import React, { ReactElement } from 'react';
import { Flex, Icon, Box, Text, AvatarGroup, Avatar, AvatarIcon } from '@endpoint/blockparty';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import {
  TableColumnCellProps,
  TableColumnHeaderCellProps,
} from 'routes/Transaction/TransactionContacts/TableComponents';
import { Column } from 'react-table';
import { giveFirstWord } from 'helpers/formatText';
import { dateFormatter } from 'helpers/datetimeHelper';
import { getColumnSortIcon } from 'helpers/table';
import { imageIcon } from 'helpers/documents';
import { AllContactTypes } from 'consts/contactDetails';

import { DocumentRowDataTypes } from '..';

export function ColumnHeader({ column }: TableColumnCellProps<DocumentRowDataTypes>): ReactElement {
  return <>{column.render('Header')}</>;
}

export function ColumnSortedHeader({ column }: TableColumnCellProps<DocumentRowDataTypes>): ReactElement {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      {column.render('Header')}
      {/* Sort direction indicator */}
      {column.canSort && (
        <Icon color="carbon200" name={getColumnSortIcon(column.isSorted, column.isSortedDesc)} size="large" />
      )}
    </Flex>
  );
}

export const docColumnHeaders = (): Column<DocumentRowDataTypes>[] => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ cell }: TableColumnHeaderCellProps): ReactElement => {
      const iconInfo = imageIcon(cell.row.original.mimeType);

      return (
        <Flex alignItems="center">
          <Box>
            <Icon color={iconInfo.color} mr="space40" name={iconInfo.name as IconNames} size="medium" />
          </Box>
          <Text maxWidth={354} overflow="hidden" textOverflow="ellipsis">
            {cell.row.original.displayName}
          </Text>
        </Flex>
      );
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableSortBy: true,
    Cell: ({ cell }: TableColumnHeaderCellProps): ReactElement => (
      <Box>
        <Text>{cell.value}</Text>
      </Box>
    ),
  },
  {
    Header: 'Customers',
    accessor: 'deliveredTo',
    disableSortBy: true,
    Cell: ({ cell }: TableColumnHeaderCellProps): ReactElement => (
      <Box>
        <AvatarGroup max={4} size={24}>
          {cell.value.map((value: TransactionParticipant): ReactElement => {
            const { firstName, lastName, entityName, isOnboarded } = value.contact as AllContactTypes;
            const avatarName: string = entityName
              ? giveFirstWord(entityName)
              : `${giveFirstWord(firstName || '')} ${giveFirstWord(lastName || '')}`;

            return (
              <Avatar key={value.id} name={avatarName} src="">
                {!isOnboarded && <AvatarIcon bg="marigold400" name="Minus" />}
              </Avatar>
            );
          })}
        </AvatarGroup>
      </Box>
    ),
  },
  {
    Header: 'Added',
    accessor: 'added',
    Cell: ({ cell }: TableColumnHeaderCellProps): ReactElement => (
      <Box>
        <Text>{dateFormatter(cell.value, 'MM/dd/yyyy')}</Text>
      </Box>
    ),
  },
];
