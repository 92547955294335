export const preventMinus = (val: { code: string; preventDefault: () => void }) => {
  if (val.code === 'Minus') {
    val.preventDefault();
  }
};

export const preventNegativeNumberCopyPaste = (val: string | number) => {
  let emdValue = String(val);

  if (emdValue[0] === '-') {
    emdValue = emdValue.substring(1);
  }

  return emdValue;
};
