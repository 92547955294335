import React, { FC } from 'react';
import { Box, Flex, Icon, Text, ProgressBar } from '@endpoint/blockparty';
import { mapStatusText, mapStatusToReactElement } from 'helpers/statusHelper';
import { DocumentUpload, DocumentUploadStatus } from 'consts/uploadDocumentsConsts';

import { ProgressColorMap, ProgressIconMap } from '../../consts/uploadDocumentToastConsts';

interface UploadDocumentProgressProps {
  document: DocumentUpload;
  onCancelUpload: () => void;
  onRemoveDocument: () => void;
  isRemovable?: boolean;
}

export const UploadDocumentProgress: FC<UploadDocumentProgressProps> = ({
  document,
  onCancelUpload,
  onRemoveDocument,
  isRemovable,
}) => {
  const handleCancelButton = () => {
    if (document.status === DocumentUploadStatus.COMPLETED && isRemovable) {
      onRemoveDocument();
    } else {
      onCancelUpload();
    }
  };

  return (
    <Box data-test-id="upload-progress" mt="space50">
      <Flex
        alignItems="center"
        border="1px solid"
        borderBottom="none"
        borderColor="carbon300"
        borderTopLeftRadius="radiusDefault"
        borderTopRightRadius="radiusDefault"
        p="space50"
      >
        <Flex alignItems="center" flexGrow={1} width="80%">
          <Box>{mapStatusToReactElement<DocumentUploadStatus>(document.status, ProgressIconMap)}</Box>
          <Text ml="space50" textStyle="truncate">
            {document.name}
          </Text>
        </Flex>
        <Flex>
          <Icon
            color="carbon500"
            data-test-id="upload-progress-cancel-icon"
            focusable
            ml="space60"
            name="Close"
            size="large"
            onClick={handleCancelButton}
          />
        </Flex>
      </Flex>
      <Box borderBottomLeftRadius="0" borderBottomRightRadius="0">
        <ProgressBar
          // @ts-ignore - revisit types, switch to use mapStatusToColor - see note in src/consts/uploadDocumentToastConsts.tsx
          barColor={mapStatusText(document.status, ProgressColorMap)}
          borderRadius="radiusDefault"
          borderTopLeftRadius="0px"
          borderTopRightRadius="0px"
          // @ts-ignore - revisit types
          completed={document.progress}
          height={6}
          steps={100}
        />
      </Box>
    </Box>
  );
};
