import React, { FC } from 'react';
import { Flex, Text } from '@endpoint/blockparty';
import { SimpleAddressInput } from '@endpoint/opsware-bff-graphql-schema';

export interface SearchAddressOptionProps {
  optionData: SimpleAddressInput;
}

export const SearchAddressOption: FC<SearchAddressOptionProps> = ({ optionData }) => {
  return (
    <Flex flexDirection="column">
      <Text>
        {optionData.street} {optionData.secondary} {optionData.city} {optionData.state} {optionData.zipCode}
      </Text>
    </Flex>
  );
};
