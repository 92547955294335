import React, { FC, useEffect } from 'react';
import { render } from 'react-dom';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui/dist/style.css';
import * as FullStory from '@fullstory/browser';
import { ApolloProvider } from '@apollo/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { BlockPartyProvider } from '@endpoint/blockparty';
import { getConfig } from 'helpers/utils/config';
import { initSentry } from 'helpers/utils/sentry';
import { trackPage } from 'helpers/utils/segment/segmentAnalytics';
import { Page } from 'consts/segmentProtocols';
import './App.css';
import { getCurrentUser, OpswareUser, emptyOpswareUser } from 'components/Auth/helpers';

import { createClient } from './Apollo';
import App from './App';

const config: AppConfig = getConfig();
const rootElement: HTMLElement | null = document.getElementById('root');

const MissingConfig: FC = () => <h1>Missing Configuration. Please contact support.</h1>;

initSentry();

const OpsWare: FC = () => {
  const apiUrl = `${config.API_URL}/opsware-graphql`;
  const client = createClient(apiUrl, window.OpsWare.buildVersion);

  useEffect(() => {
    if (config.SEGMENT_WRITE_KEY) {
      trackPage<Page>(undefined);
    }
  }, []);

  return (
    <BlockPartyProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BlockPartyProvider>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-floating-promises
(async () => {
  if (!config) {
    console.error('!!! MISSING ENV CONFIG !!!');
    render(<MissingConfig />, rootElement);
  } else {
    const isDev = process.env.NODE_ENV === 'development';

    Amplify.configure({
      Auth: {
        region: config.AWS_REGION,
        userPoolId: config.AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: config.AWS_COGNITO_WEB_CLIENT_ID,
        authenticationFlowType: 'USER_SRP_AUTH',
        cookieStorage: {
          domain: isDev ? 'localhost' : config.AUTH_COOKIE_DOMAIN,
          expires: 365, // days
          secure: !isDev,
        },
      },
    });

    let currentUser: OpswareUser;

    try {
      const currentUserResoponse = await getCurrentUser();

      currentUser = currentUserResoponse;
    } catch {
      currentUser = emptyOpswareUser();
    }

    if (config.FULLSTORY_ORG) {
      FullStory.init({ orgId: config.FULLSTORY_ORG });
    }

    if (config.SEGMENT_WRITE_KEY) {
      window.analytics.load(config.SEGMENT_WRITE_KEY);
    }

    const LDProvider = await asyncWithLDProvider({
      clientSideID: config.LAUNCH_DARKLY_CLIENT_ID,
      user: {
        key: currentUser.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
      },
    });

    render(
      <LDProvider>
        <OpsWare />
      </LDProvider>,
      rootElement,
    );
  }
})();
