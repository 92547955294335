import React, { FC } from 'react';
import { AccordionItem, Icon, Stack, Flex, useToast } from '@endpoint/blockparty';
import { Form, Formik } from 'formik';
import { FormikItems, TaskStepDefinition } from 'helpers/dynamicForm/types';
import { useMutation } from '@apollo/client';
import { OpsTaskStep } from '@endpoint/opsware-bff-graphql-schema';

import { TaskStepView } from '../TaskStepView';
import { TaskStepCompletedView } from '../TaskStepCompletedView/TaskStepCompletedView';
import { TaskStepPendingView } from '../TaskStepPendingView/TaskStepPendingView';
import { RECORD_TASK_STEP_RESPONSE, RecordTaskStepResponseData } from './queries';

interface TaskStepViewWrapperProps {
  taskStepDefinition: TaskStepDefinition;
  taskStep: OpsTaskStep;
  expanded: boolean;
}

export const TaskStepViewWrapper: FC<TaskStepViewWrapperProps> = ({ taskStepDefinition, taskStep, expanded }) => {
  const toast = useToast();
  const { items, completedView, pendingView } = taskStepDefinition;
  const { title, isCompleted, response, stepId } = taskStep;
  const initialValues: FormikItems = response || {};
  const iconName = isCompleted ? 'CheckCircle' : 'CheckCircleOutline';
  const iconColor = isCompleted ? { color: 'carbon500' } : {};

  const [recordTaskStepResponse, { loading }] = useMutation<RecordTaskStepResponseData>(RECORD_TASK_STEP_RESPONSE, {
    onCompleted(): void {
      toast({
        description: 'Step answer saved',
        duration: 5000,
        icon: 'CheckCircle',
      });
    },
    onError(): void {
      toast({
        description: 'Unable to save step answer',
        duration: 5000,
        icon: 'ErrorCircle',
        iconColor: 'watermelon500',
      });
    },
  });

  const onSubmit = React.useCallback(
    async (values) => {
      await recordTaskStepResponse({
        variables: {
          where: { id: stepId },
          data: values,
        },
      });
    },
    [recordTaskStepResponse, stepId],
  );

  return (
    <Formik initialValues={{ ...initialValues }} onSubmit={onSubmit}>
      <Form>
        <AccordionItem my="space50" outline="ActiveBorder">
          <Stack margin="space50">
            {title && (
              <Flex align="center" fontWeight="semi" size="fontSize20">
                <Icon {...iconColor} marginRight="space30" name={iconName} size="large" />
                {title}
              </Flex>
            )}
            {(isCompleted && <TaskStepCompletedView completedView={completedView} items={items} />) ||
              (expanded && <TaskStepView isLoading={loading} items={items} />) ||
              (!expanded && <TaskStepPendingView pendingView={pendingView} />)}
          </Stack>
        </AccordionItem>
      </Form>
    </Formik>
  );
};
