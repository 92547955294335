import { TransactionSearchResult } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { dateFormatter } from 'helpers/datetimeHelper';
import { deriveStatus } from 'helpers/openOrderList';
import { mapStatusText } from 'helpers/statusHelper';
import { transactionTypeMap } from 'consts/transactionConsts';

export interface TransactionsListRowData {
  fileNumber: string;
  address: string;
  status: string;
  productType: string;
  closingDate: string;
  transactionId: string;
}

const sortTransactions = (transactions: TransactionSearchResult[]): TransactionSearchResult[] =>
  transactions.sort((resultA: TransactionSearchResult, resultB: TransactionSearchResult) => {
    const transactionA = new Date(resultA.createdAt).getTime();
    const transactionB = new Date(resultB.createdAt).getTime();

    return transactionB - transactionA;
  }) || [];

export const transactionRowData = (transactions: TransactionSearchResult[]): TransactionsListRowData[] => {
  const sorted = sortTransactions(transactions);

  return sorted.map((transaction) => {
    const { isPending } = transaction;

    // address: don't want the county parens to appear if there's no county
    const address = transaction.property.address.county
      ? `${transaction.property.address.standardFormat} (${transaction.property.address.county})`
      : `${transaction.property.address.standardFormat}`;
    const isPrelisting = transaction.status === 'PRELISTING';

    const transactionStatus = deriveStatus(transaction.status, isPending);

    const fileNumber = (): string => {
      if (transaction.fileNum && transaction.fileNum !== '') {
        return transaction.fileNum;
      }

      return '-';
    };

    const productType = () => {
      if (transaction.type) {
        return mapStatusText(transaction.type, transactionTypeMap);
      }

      if (isPrelisting) {
        return 'Pre-Listing';
      }

      return '-';
    };

    // all fields without '-' at the end are non-nullable
    return {
      transactionId: transaction.id,
      fileNumber: fileNumber(),
      address,
      status: transactionStatus,
      productType: productType(),
      closingDate: transaction.dates.estimatedClosing
        ? dateFormatter(transaction.dates.estimatedClosing, 'MM/dd/yyyy')
        : '-',
    };
  });
};
