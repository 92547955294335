import {
  SortDirection,
  TransactionSearchResult,
  TransactionsSortProperties,
  TransactionStatus,
  TransactionType,
  QualityControlStatus,
  OriginationSource,
} from '@endpoint/opsware-bff-graphql-schema';
import { qualityControlStatusMap, transactionTypeMap } from 'consts/transactionConsts';
import { OrganizationType } from 'consts/organizationConsts';
import { formatLikeTitle } from 'helpers/formatText';
import { useQueryParams } from 'helpers/utils/queryParams/';

export interface TransactionsListRowData {
  transactionId: string;
  [TransactionsSortProperties.FILE_NUMBER]: string;
  [TransactionsSortProperties.ADDRESS]: string;
  [TransactionsSortProperties.ESCROW_PRODUCT_TYPE]: string;
  [TransactionsSortProperties.CREATED_ON]: Date;
  [TransactionsSortProperties.NEEDS_REVIEW]?: string;
  [TransactionsSortProperties.QUALITY_CONTROL_STATUS]?: string;
  [TransactionsSortProperties.SOURCE]?: string;
}

export interface OpenOrderFilters {
  sort: [TransactionsSortProperties, SortDirection] | [];
  marketIds: number[];
  search: string[] | string;
  escrowProductTypes: TransactionType[] | [];
  organizationIds: OrganizationType[];
  orderStatuses?: QualityControlStatus[] | [];
  orderSources?: OriginationSource[] | [];
  needsReview?: string[] | string;
}

export function useOpenOrderFilters() {
  return useQueryParams((params: Partial<OpenOrderFilters>) => ({
    sort: params.sort ?? [],
    search: Array.isArray(params.search)
      ? params.search.map((str: string) => str.trim()).join(', ')
      : params.search ?? '',
    needsReview: [params.needsReview ?? []].flat(1),
    marketIds: [params.marketIds ?? []].flat(1).map(Number),
    organizationIds: [params.organizationIds ?? []].flat(1).map(Number),
    escrowProductTypes: [params.escrowProductTypes ?? []].flat(1),
    orderStatuses: [params.orderStatuses ?? []].flat(1),
    orderSources: [params.orderSources ?? []].flat(1),
  }));
}

export const getAddress = (transaction: TransactionSearchResult): string => {
  // address: don't want the county parens to appear if there's no county
  return transaction.property.address.county
    ? `${transaction.property.address.standardFormat}(${transaction.property.address.county})`
    : `${transaction.property.address.standardFormat}`;
};

export const deriveStatus = (transactionStatus: TransactionStatus, isPending?: boolean) => {
  const isPrelisting = transactionStatus === 'PRELISTING';
  const isOpenEscrow = transactionStatus === 'IN_ESCROW' && !isPending;
  const isOrderProcessingEscrow = transactionStatus === 'IN_ESCROW' && isPending;

  if (isPrelisting || isOrderProcessingEscrow) {
    return 'Order Processing';
  }

  if (isOpenEscrow) {
    return 'Open';
  }

  return formatLikeTitle(transactionStatus);
};

export const transactionRowData = (
  transactions: TransactionSearchResult[],
  isQcStatusEnabled?: boolean,
  isQcSourceEnabled?: boolean,
): TransactionsListRowData[] => {
  return transactions.map((transaction) => {
    const fileNumber = (): string => {
      if (transaction.fileNum && transaction.fileNum !== '') {
        return transaction.fileNum;
      }

      return '-';
    };

    // all fields without '-' at the end are non-nullable
    return {
      transactionId: transaction.id,
      [TransactionsSortProperties.FILE_NUMBER]: fileNumber(),
      [TransactionsSortProperties.ADDRESS]: getAddress(transaction),
      [TransactionsSortProperties.ESCROW_PRODUCT_TYPE]: transaction.type ? transactionTypeMap[transaction.type] : '',
      [TransactionsSortProperties.CREATED_ON]: new Date(transaction.createdAt),
      ...(isQcStatusEnabled && {
        [TransactionsSortProperties.QUALITY_CONTROL_STATUS]: transaction.qualityControlStatus
          ? qualityControlStatusMap[transaction.qualityControlStatus]
          : '',
      }),
      ...(!isQcStatusEnabled && {
        [TransactionsSortProperties.NEEDS_REVIEW]: transaction.isOpsReviewComplete ? 'No' : 'Yes',
      }),
      ...(isQcSourceEnabled && {
        [TransactionsSortProperties.SOURCE]: transaction.source || '',
      }),
      status: formatLikeTitle(transaction.status),
    };
  });
};
