import { IndividualContact, OpsSquad, OpsTask, OpsTaskStatus, Transaction } from '@endpoint/opsware-bff-graphql-schema';

export const sampleOpsTaskQueryResponse: OpsTask = {
  id: 'task-id-123',
  createdOn: '2022-08-08T20:59:39Z',
  taskTitle: 'Seller Onboarding Task',
  assigneeName: 'Jules Winnfield',
  dueDateTime: '2022-08-12T20:59:39Z',
  squad: OpsSquad.GLOBAL,
  status: OpsTaskStatus.IN_PROGRESS,
  transactionId: 'transaction-id-123',
  isOverdue: false,
  contacts: [
    {
      id: '123',
      firstName: 'Jules',
      lastName: 'Winnfield',
      email: 'jules.winnfield@gmail.com',
      phone: '888-888-8888',
      isOnboarded: false,
    } as IndividualContact,
  ],
  taskDefinitionKey: 'seller_onboarding',
  taskDefinitionSchema: {
    type: 'object',
    title: 'Seller Onboarding (v2)',
    description:
      "This task is complete when the Seller has received onboarding calls and have either joined the Endpoint app or been marked as 'offline'.",
    required: ['steps'],
    properties: {
      taskDefaults: {
        description: 'Default values to be used when creating a Task from this schema',
        type: 'object',
        properties: {
          squad: {
            description: 'The default squad assigned to the new Task',
            type: 'string',
            enum: ['global', 'cx'],
            default: 'global',
          },
          dueOnOffset: {
            description: 'The offset from time of creation to assign the default due date for the new Task',
            type: 'string',
            format: 'duration',
            default: '1d0h',
          },
          name: {
            description: 'The default name for the new Task',
            type: 'string',
            default: 'Seller Onboarding for {firstName} {lastName}',
          },
          milestoneType: {
            type: 'string',
            enum: ['ESCROW_OPENED'],
          },
        },
      },
      stepDefinitions: {
        type: 'array',
        items: {
          anyOf: [
            {
              type: 'object',
              title: 'onboarding_call',
              description: 'Onboarding call attempt #{stepNumber}',
              properties: {
                definition: {
                  properties: {
                    call_complete: {
                      title: 'Onboarding call complete?',
                      format: 'radio',
                      type: 'object',
                      properties: {
                        value: {
                          type: 'string',
                        },
                        options: {
                          type: 'object',
                          oneOf: [
                            {
                              title: 'not_yet',
                              description: 'Not yet',
                              type: 'object',
                            },
                            {
                              title: 'called_left_message',
                              description: 'Called and Left a Message',
                              type: 'object',
                              properties: {
                                notes: {
                                  title: 'Anything else to note?',
                                  format: 'textarea',
                                  description: '(Optional)',
                                  maxLength: 255,
                                  type: 'object',
                                  properties: {
                                    value: {
                                      type: 'string',
                                    },
                                  },
                                },
                              },
                            },
                            {
                              title: 'called_spoke_to_seller',
                              description: 'Called and Spoke to Seller',
                              type: 'object',
                              properties: {
                                '01_question': {
                                  type: 'object',
                                  title: 'Do any of the following apply?',
                                  format: 'checkbox',
                                  properties: {
                                    value: {
                                      type: 'array',
                                      items: {
                                        type: 'string',
                                      },
                                    },
                                    options: {
                                      type: 'object',
                                      anyOf: [
                                        {
                                          title: 'offline',
                                          description: 'Offline Customer',
                                        },
                                        {
                                          title: 'exchange_1031',
                                          description: '1031 Exchange',
                                        },
                                        {
                                          title: 'power_attorney',
                                          description: 'Power of Attorney',
                                        },
                                        {
                                          title: 'out_of_country',
                                          description: 'Out of country during closing',
                                        },
                                      ],
                                    },
                                  },
                                },
                                notes: {
                                  title: 'Anything else to note?',
                                  format: 'textarea',
                                  description: '(Optional)',
                                  maxLength: 255,
                                  type: 'object',
                                  properties: {
                                    value: {
                                      type: 'string',
                                    },
                                  },
                                },
                              },
                            },
                            {
                              title: 'called_not_needed',
                              description: 'Called not Needed',
                              type: 'object',
                              properties: {
                                notes: {
                                  title: 'Anything else to note?',
                                  format: 'textarea',
                                  description: '(Optional)',
                                  maxLength: 255,
                                  type: 'object',
                                  properties: {
                                    value: {
                                      type: 'string',
                                    },
                                  },
                                },
                              },
                            },
                            {
                              title: 'out_of_country',
                              description: 'Out of Country',
                              type: 'object',
                              properties: {
                                notes: {
                                  title: 'Anything else to note?',
                                  format: 'textarea',
                                  description: '(Optional)',
                                  maxLength: 255,
                                  type: 'object',
                                  properties: {
                                    value: {
                                      type: 'string',
                                    },
                                  },
                                },
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                },
                visualization: {
                  type: 'object',
                  properties: {
                    ctaLabel: {
                      description: 'The displayed text for the CTA button on the step',
                      type: 'string',
                      default: 'Save',
                    },
                    ctaAction: {
                      description: 'How the UX should respond to the CTA being triggered',
                      type: 'string',
                      enum: ['next_step_or_close', 'next_step_or_wait'],
                    },
                    pendingView: {
                      type: 'object',
                      properties: {
                        message: {
                          description: 'static copy to be displayed when the step is NOT completed',
                          type: 'string',
                          default:
                            'Pending message aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
                        },
                      },
                    },
                    completedView: {
                      type: 'object',
                      properties: {
                        message: {
                          description: 'static copy to be displayed when the step is completed',
                          type: 'string',
                          default:
                            'Completed message ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                        },
                        showResponse: {
                          type: 'boolean',
                          default: true,
                        },
                      },
                    },
                  },
                },
                onComplete: {
                  description: 'Changes to be made on the Task upon this step successfully completing.',
                  type: 'object',
                  properties: {
                    status: {
                      description: 'Optional. The status to be assigned to the Task upon completing the step',
                      type: 'string',
                      enum: ['in_progress', 'waiting', 'completed'],
                    },
                    team: {
                      description: 'Optional. The team to which the Task should be assigned upon completing the step',
                      type: 'string',
                      enum: ['cx', 'global', 'signings'],
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
  },
  steps: [
    {
      title: 'Onboarding call attempt 1',
      taskId: '123',
      stepId: 'task-step-id',
      key: 'onboarding_call',
      isCompleted: false,
    },
  ],
  transaction: {} as Transaction,
  autoAssignTask: false,
};
