import React, { FC, ReactElement, Fragment, useState } from 'react';
import { FieldArray, ArrayHelpers } from 'formik';
import { Box, InputGroup, Text, IconButton, Flex, Avatar, useDisclosure } from '@endpoint/blockparty';
import { TodoStatus, RecallReason, TodoAssignment } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { InputProps } from 'components/TextArea';
import { TodoAssignmentToUpdate } from 'consts/toDoConsts';
import { giveFirstWord, formatRolesLikeTitle } from 'helpers/formatText';
import { ConfirmRecallReasonModal } from 'components/UpdateTodoStatus/ConfirmRecallReasonModal';

interface RemoveAssigneeFieldArrayProps extends InputProps {
  values: TodoAssignmentToUpdate[];
  initialValues: TodoAssignment[];
}

export const RemoveAssigneeFieldArray: FC<RemoveAssigneeFieldArrayProps> = ({ name, values, initialValues }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedAssignee, setSelectedAssignee] = useState<TodoAssignmentToUpdate>();

  const handleModalChange = (status: TodoStatus, recallReason: RecallReason) => {
    const assignee = selectedAssignee;

    if (assignee?.todoAssigneeId) setSelectedAssignee({ ...assignee, status, recallReason });
  };

  const isUnsavedAssignee = (assignee: TodoAssignmentToUpdate) => {
    const savedAssigneeIds = initialValues
      .filter((assignment) => assignment.status !== TodoStatus.RECALLED)
      .map((innitialAssignment) => innitialAssignment.assignee.id);

    return assignee ? !savedAssigneeIds.includes(assignee.todoAssigneeId) : false;
  };

  return (
    <>
      <InputGroup>
        <FieldArray
          name={name}
          render={(arrayHelpers: ArrayHelpers): ReactElement => (
            <Box maxHeight={224} overflow="auto">
              {values && values.length ? (
                values.map((value: TodoAssignmentToUpdate, index: number): ReactElement | null => {
                  if (value.status !== TodoStatus.RECALLED) {
                    const nameValueForAvatar: string = `${giveFirstWord(value.assigneeFirstName || '')} ${giveFirstWord(
                      value.assigneeLastName || '',
                    )}`;

                    if (
                      selectedAssignee &&
                      selectedAssignee.todoAssigneeId === value.todoAssigneeId &&
                      selectedAssignee.status === TodoStatus.RECALLED &&
                      selectedAssignee.recallReason
                    ) {
                      const updatedValue = {
                        ...value,
                        status: selectedAssignee.status,
                        recallReason: selectedAssignee.recallReason,
                      };

                      arrayHelpers.replace(index, updatedValue);
                    }

                    return (
                      <Fragment key={`assignee-${value.todoAssigneeId}`}>
                        <Box
                          _hover={{ backgroundColor: 'carbon0' }}
                          data-test-id="remove-assignee-box"
                          display="flex"
                          px="space60"
                          py="space30"
                          style={{ justifyContent: 'space-between' }}
                        >
                          <Flex alignItems="center">
                            <Avatar
                              alignSelf="flex-start"
                              mr="space40"
                              name={nameValueForAvatar || ''}
                              size={24}
                              src=""
                            />
                            <Flex flexDirection="column">
                              <Text
                                maxWidth="350px"
                                overflow="hidden"
                                size="fontSize20"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                              >
                                {`${value.assigneeFirstName} ${value.assigneeLastName}`}
                              </Text>
                              <Text
                                color="carbon500"
                                maxWidth="350px"
                                overflow="hidden"
                                size="fontSize10"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                              >
                                {formatRolesLikeTitle(value?.assigneeRoles?.join(', ') || '', true)}
                              </Text>
                            </Flex>
                          </Flex>
                          <IconButton
                            label="remove user"
                            name="Close"
                            variant="unstyled"
                            variantColor="carbon"
                            onClick={(): void => {
                              setSelectedAssignee(value);

                              if (isUnsavedAssignee(value)) {
                                arrayHelpers.remove(index);
                              } else {
                                onOpen();
                              }
                            }}
                          />
                        </Box>
                      </Fragment>
                    );
                  }

                  return null;
                })
              ) : (
                <Text>Sorry, no Assignees exist on this Todo</Text>
              )}
            </Box>
          )}
        />
      </InputGroup>
      <ConfirmRecallReasonModal
        assigneeId={selectedAssignee?.todoAssigneeId || ''}
        isModalOpen={isOpen}
        updateLocation="edit-task"
        onClose={onClose}
        onSubmit={handleModalChange}
      />
    </>
  );
};

IconButton.displayName = 'IconButton';
RemoveAssigneeFieldArray.displayName = 'RemoveAssigneeFieldArray';
