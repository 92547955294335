import React from 'react';
import { TableRow, TableCell } from '@endpoint/blockparty';
import { Cell, Row } from 'react-table';
import { ExtraConfigProps } from '@endpoint/blockparty/dist/components/Box/types';

type GenericRow = {};

export interface GenericTableRowProps<T extends GenericRow> extends ExtraConfigProps {
  row: Row<T>;
  dataTestId: string;
  onClick?(row?: T): void;
  tableCell?: (cell: Cell<T>) => JSX.Element;
}

const GenericTableRow = <T extends GenericRow>({
  row,
  dataTestId,
  onClick,
  tableCell,
  ...extraConfigProps
}: GenericTableRowProps<T>) => {
  const { key, ...rowProps } = row.getRowProps();

  return (
    <TableRow
      key={key}
      data-test-id={dataTestId}
      onClick={() => onClick?.(row.original)}
      {...rowProps}
      {...extraConfigProps}
    >
      {row.cells.map((cell) => {
        const { key: tableCellKey, ...tableCellProps } = cell.getCellProps();

        return (
          <TableCell key={tableCellKey} py="space30" wordBreak="break-word" {...tableCellProps}>
            {(tableCell && tableCell(cell)) || <>{cell.render('Cell')}</>}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default GenericTableRow;
