import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalSection,
  RadioAlpha,
  RadioGroupAlpha,
  Text,
} from '@endpoint/blockparty';
import { GlobalContactUpdateOptions } from 'consts/addressBook';

interface UpdateAllOpenOrdersModalProps {
  isModalOpen: boolean;
  isLoading: boolean;
  closeModal: () => void;
  onSave: (updateOption: string) => void;
}

export const UpdateAllOpenOrdersModal: FC<UpdateAllOpenOrdersModalProps> = ({
  closeModal,
  isLoading,
  isModalOpen,
  onSave,
}) => {
  const [selectedOption, setSelectedOption] = useState('');

  const onValueChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleOnSave = () => {
    onSave(selectedOption);
  };

  const canSave = !!selectedOption && !isLoading;

  useEffect(() => {
    if (isModalOpen) {
      setSelectedOption('');
    }
  }, [isModalOpen]);

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <ModalContent zIndex={2}>
        <ModalSection>
          <Text as="h1" fontWeight="semi" mb="space60" size="fontSize30">
            Global Contact Change
          </Text>
          <RadioGroupAlpha label="GlobalContactChangeOptions" mb="space60" onValueChange={onValueChange}>
            <RadioAlpha value={GlobalContactUpdateOptions.APPLY_TO_ALL}>Apply to all open orders</RadioAlpha>
            <RadioAlpha value={GlobalContactUpdateOptions.DO_NOT_APPLY}>Do not apply to any open orders</RadioAlpha>
          </RadioGroupAlpha>
          <Text>This change will not affect transactions where this contact is a buyer or seller.</Text>
        </ModalSection>
        <ModalFooter>
          <Button mr="space50" variant="outline" onClick={closeModal}>
            Cancel
          </Button>
          <Button isDisabled={!canSave} isLoading={isLoading} onClick={handleOnSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
