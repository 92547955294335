import { DrawerHeader, Text, Radio, RadioGroup, Divider, Flex } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import useOCRSupport from 'hooks/useOCRSupport';
import { OpenOrderDrawerForm, OpenOrderDrawerFormType } from 'helpers/openOrder/openOrderFormTypes';

import { ManualEntry } from './ManualEntry';
import { UploadEntry } from './UploadEntry';

export const Step1: FC = () => {
  const { values, setFieldValue } = useFormikContext<OpenOrderDrawerForm>();
  const { regions } = useOCRSupport();

  return (
    <Flex alignContent="center" flex={1} flexDirection="column">
      <DrawerHeader border="none">
        <Text as="h2" fontWeight="semi" size="fontSize40">
          Open a New Order
        </Text>
        <RadioGroup
          aria-label="orderOpenOption"
          data-test-id="open-order-type"
          defaultValue={values.formType}
          marginTop="20px"
          name="orderOpenOption"
        >
          <Radio
            data-test-id="open-order-upload-psa-radio"
            isDisabled={regions.length === 0}
            value="upload"
            onChange={() => setFieldValue('formType', OpenOrderDrawerFormType.UPLOAD)}
          >
            Upload a purchase agreement
          </Radio>
          <Radio
            data-test-id="open-order-address-or-parcel-radio"
            value="manual"
            onChange={() => setFieldValue('formType', OpenOrderDrawerFormType.MANUAL)}
          >
            Enter an address
          </Radio>
        </RadioGroup>
      </DrawerHeader>
      <Divider mx="6" />

      {values.formType === OpenOrderDrawerFormType.MANUAL && <ManualEntry />}
      {values.formType === OpenOrderDrawerFormType.UPLOAD && <UploadEntry />}
    </Flex>
  );
};
