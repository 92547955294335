import { RBACRoleName } from '@endpoint/common-model';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema';
import { OpswareUser } from 'components/Auth/helpers';
import { ALL } from 'consts/authorization';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';

export const isUserAuthorized = (user: OpswareUser, transaction: Transaction): boolean => {
  if (!user.organization || !user.market) {
    return false;
  }

  if (user.market === ALL && user.organization === ALL) {
    return true;
  }

  return organizationAuthCheck(user, transaction) && marketAuthCheck(user, transaction);
};

export const organizationAuthCheck = (user: OpswareUser, transaction: Transaction): boolean => {
  if (user.organization === ALL) {
    return true;
  }

  if (Array.isArray(user.organization) && user.organization.includes(transaction.organizationId as number)) {
    return true;
  }

  return false;
};

export const marketAuthCheck = (user: OpswareUser, transaction: Transaction): boolean => {
  if (user.market === ALL) {
    return true;
  }

  if (Array.isArray(user.market) && user.market.includes(transaction.marketId as number)) {
    return true;
  }

  return false;
};

export const getMockOpswareUserByRole = (role: RBACRoleName) => {
  const mockOpswareUser: OpswareUser = {
    firstName: 'Test',
    lastName: 'Last',
    id: '123456',
    email: 'someEmail@email.com',
    hasMultiOrganizationAccess: true,
    hasMultiMarketAccess: false,
    hasMultiSquadAccess: false,
    role,
    market: '*',
    organization: '*',
    squad: '*',
  };

  return mockOpswareUser;
};

export const getMockUserByRole = (role: RBACRoleName) => {
  const mockUser = {
    id: '123',
    firstName: 'Test',
    lastName: 'User',
    email: 'first-initial-last-name@endpointclosing.com',
    hasMultiOrganizationAccess: true,
    hasMultiMarketAccess: true,
    role,
  };

  return mockUser;
};

export const getCurrentUserMocksWithOpswareUser = (user: OpswareUser) => {
  const mocksWithUser = [
    {
      request: {
        query: GET_CURRENT_USER,
      },
      result: {
        data: {
          me: user,
        },
      },
    },
  ];

  return mocksWithUser;
};
