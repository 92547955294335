import React, { createContext, ReactNode, useContext, useReducer, useMemo } from 'react';
import { isEqual } from 'lodash';
import {
  NewContactSchema,
  newContactDefault,
  NewContactStep,
  ContactGQLErrorType,
  RelatedContactsGQLErrorType,
} from 'consts/createNewContact';

enum ActionKind {
  SET_CONTACT = 'SET_CONTACT',
  SET_CONTACT_STEP = 'SET_CONTACT_STEP',
  SET_CONTACT_GQL_ERROR = 'SET_CONTACT_GQL_ERROR',
  SET_IS_NEW_CONTACT = 'SET_IS_NEW_CONTACT',
  SET_IS_DUPLICATE_OR_NEW_CONTACT_SELECTED = 'SET_IS_DUPLICATE_OR_NEW_CONTACT_SELECTED',
}

type Payload = {
  selectedContactData: NewContactSchema;
  newContactStep: NewContactStep;
  contactGQLError: ContactGQLErrorType | null;
  isNewContact: boolean;
  isDuplicateOrNewContactSelected: boolean;
};

type Action = { type: ActionKind; payload: Payload };
type Dispatch = (action: Action) => void;
export type State = {
  selectedContactData: NewContactSchema;
  newContactStep: NewContactStep;
  contactGQLError: ContactGQLErrorType | null;
  isNewContact: boolean;
  isDuplicateOrNewContactSelected: boolean;
};
type NewContactProviderProps = { children: ReactNode };

const NewContactContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function contactReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionKind.SET_CONTACT: {
      if (isEqual(state.selectedContactData, action.payload.selectedContactData)) return { ...state };

      return { ...state, selectedContactData: action.payload.selectedContactData };
    }

    case ActionKind.SET_CONTACT_STEP: {
      if (state.newContactStep === action.payload.newContactStep) return { ...state };

      return { ...state, newContactStep: action.payload.newContactStep };
    }

    case ActionKind.SET_CONTACT_GQL_ERROR: {
      if (state.contactGQLError === action.payload.contactGQLError) return { ...state };

      return { ...state, contactGQLError: action.payload.contactGQLError };
    }

    case ActionKind.SET_IS_NEW_CONTACT: {
      if (state.isNewContact === action.payload.isNewContact) return { ...state };

      return { ...state, isNewContact: action.payload.isNewContact };
    }

    case ActionKind.SET_IS_DUPLICATE_OR_NEW_CONTACT_SELECTED: {
      if (state.isDuplicateOrNewContactSelected === action.payload.isDuplicateOrNewContactSelected) return { ...state };

      return { ...state, isDuplicateOrNewContactSelected: action.payload.isDuplicateOrNewContactSelected };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function NewContactProvider({ children }: NewContactProviderProps) {
  const initialStep = NewContactStep.SEARCH_CONTACT;

  const [state, dispatch] = useReducer(contactReducer, {
    selectedContactData: newContactDefault,
    newContactStep: initialStep,
    contactGQLError: null,
    isNewContact: true,
    isDuplicateOrNewContactSelected: false,
  });

  const stateStr = JSON.stringify(state);

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateStr, dispatch],
  );

  return <NewContactContext.Provider value={value}>{children}</NewContactContext.Provider>;
}

function useNewContactProvider() {
  const context = useContext(NewContactContext);

  if (context === undefined) {
    throw new Error('useNewContactProvider must be used within a ContactProvider');
  }

  return context;
}

export { ActionKind, NewContactProvider, useNewContactProvider };
