import React, { FC, useState } from 'react';
import { TransactionRole } from '@endpoint/opsware-bff-graphql-schema';
import {
  Box,
  Button,
  CheckboxGroup,
  Checkbox,
  Modal,
  ModalContent,
  ModalSection,
  ModalFooter,
  Text,
  useToast,
} from '@endpoint/blockparty';
import { roleOptions } from 'consts/transactionParticipant';
import { handleRolesCheckboxUpdate } from 'helpers/contacts';
import { useMutation } from '@apollo/client';
import { REMOVE_PARTICIPANT_ROLES } from 'routes/Transaction/ContactDetails/queries';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { navigate } from '@reach/router';
import { GET_CONTACTS } from 'routes/Transaction/TransactionContacts/queries';
import { difference } from 'lodash';
import { buildQueryInput } from 'helpers/buildQueryInput';

interface RemoveRolesModalProps {
  isRemoveRolesModalOpen: boolean;
  participantId: string;
  roles: TransactionRole[];
  toggleModal: () => void;
  transactionIdInput: string;
}

export const RemoveRolesModal: FC<RemoveRolesModalProps> = ({
  isRemoveRolesModalOpen,
  participantId,
  roles,
  toggleModal,
  transactionIdInput,
}) => {
  const toast = useToast();
  const [rolesToRemove, setRolesToRemove] = useState<TransactionRole[]>([]);
  const shouldRerouteToContactsPage = difference(roles, rolesToRemove).length === 0;
  const contactPagePath = `/transaction/${transactionIdInput}/contacts`;
  const queryInput = buildQueryInput(transactionIdInput);
  const [removeTransactionParticipantRoles, { loading }] = useMutation(REMOVE_PARTICIPANT_ROLES, {
    onCompleted(): void {
      toast({
        description: 'Role removed',
        duration: 5000,
        icon: 'CheckCircle',
      });

      trackAnalytics('Remove Role Saved', {});
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      if (shouldRerouteToContactsPage) navigate(contactPagePath);
    },
    onError(): void {
      toast({
        description: 'Unable to remove role',
        duration: 5000,
        icon: 'ErrorCircle',
        iconColor: 'watermelon500',
      });
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CONTACTS,
        variables: {
          where: {
            ...queryInput,
          },
        },
      },
    ],
  });

  const handleCheckboxChange = (roleFromChange: TransactionRole) => {
    const updatedRoles = handleRolesCheckboxUpdate(roleFromChange, rolesToRemove);

    setRolesToRemove(updatedRoles);
  };

  const handleCancel = () => {
    setRolesToRemove([]);
    toggleModal();
  };

  const handleConfirm = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    removeTransactionParticipantRoles({ variables: { where: { id: participantId }, roles: rolesToRemove } });
    toggleModal();
  };

  const disableSaveButton = rolesToRemove.length === 0;

  return (
    <Modal isOpen={isRemoveRolesModalOpen} onClose={toggleModal}>
      <ModalContent zIndex={2}>
        <ModalSection>
          <Text as="h1" fontWeight="semi" pb="space40" size="fontSize30">
            Which role should be removed?
          </Text>
          <Text as="p" pb="space60">
            Removing all roles will also remove this contact from the transaction.
          </Text>
          <Box>
            <CheckboxGroup aria-label="Remove roles">
              <>
                {roles.map((role: TransactionRole) => {
                  const roleLabelAndValue = roleOptions[role];

                  return (
                    <Checkbox
                      key={`${roleLabelAndValue.value}-checkbox`}
                      value={roleLabelAndValue.value}
                      onChange={() => handleCheckboxChange(roleLabelAndValue.value)}
                    >
                      {roleLabelAndValue.label}
                    </Checkbox>
                  );
                })}
              </>
            </CheckboxGroup>
          </Box>
        </ModalSection>
        <ModalFooter>
          <Button mr="space50" variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            data-test-id="confirm-remove-roles"
            isDisabled={disableSaveButton}
            isLoading={loading}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
