import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AsyncSelect, Box, InputErrorMessage, InputGroup, InputLabel, Text } from '@endpoint/blockparty';
import debounce from 'debounce-promise';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { SimpleAddress } from '@endpoint/opsware-bff-graphql-schema';
import { useFormikContext } from 'formik';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';
import { ErrorMessage } from 'components/ErrorMessage';
import { useStateOptions } from 'hooks/useStateOptions';
import { OrganizationType } from 'consts/organizationConsts';
import { InputActionMeta, Options } from 'react-select';

import { Option } from './Option';
import { SingleValue } from './SingleValue';
import {
  getSearchAddressResults,
  SearchAddressWithInputData,
  verifyAddressResults,
  VerifyAddressWithInputData,
} from './queries';
import { emptyAddress, manualAddressSelection, PropertyAddressTypes } from './addressSearch';
import { getAsyncSelectStyles } from './styles';

export interface SearcAddressProps {
  enterAddressManually: () => void;
}

export const SearchAddress: FC<SearcAddressProps> = ({ enterAddressManually }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [queryError, setQueryError] = useState(false);
  const [selectionMade, setSelectionMade] = useState(false);
  const [selectAddressOption, setSelectAddressOption] = useState<SimpleAddress>(emptyAddress);
  const { setFieldValue, setFieldTouched, values, errors, touched } = useFormikContext<OpenOrderDrawerForm>();
  const client = useApolloClient();

  const street1Error = !!(touched.property?.address?.street1 && !!errors.property?.address?.street1);

  const STATE_OPTIONS = useStateOptions(values.organization as OrganizationType);

  const formattedStateOptions = useMemo(() => {
    return STATE_OPTIONS.map((item) => {
      return item.value;
    });
  }, [STATE_OPTIONS]);

  useEffect(() => {
    const address: SimpleAddress = {
      street: values.property?.address?.street1,
    };

    setSelectAddressOption(address);

    // Only set selectionMade to true if it is a click 'Back' to Step1
    if (values.property?.address?.city && !selectionMade) {
      setSelectionMade(true);
    }
  }, [values.property?.address?.street1, values.property?.address?.city, selectionMade]);

  const handleInputChange = (input: string, actionMeta: InputActionMeta): void => {
    if (queryError) setQueryError(false);

    handleActionType(actionMeta, input);
  };

  const handleActionType = (actionMeta: InputActionMeta, input: string) => {
    // the default behavior for a react-select onBlur event is to clear the input field
    // reference to this issue and solution here https://github.com/JedWatson/react-select/issues/588
    if (actionMeta.action === 'input-change') {
      setSearchTerm(input);
      setFieldValue(PropertyAddressTypes.STREET1, input);
      setSelectionMade(false);
    }

    if (actionMeta.action === 'set-value') {
      setSearchTerm(input);
      setSelectionMade(true);
    }
  };

  const handleSelectChange = (selection: SimpleAddress | null): void => {
    if (!selection) {
      clearFormValues();
    } else {
      if (selection.street === manualAddressSelection.street) {
        enterAddressManually();

        return;
      }

      setFormValues(selection);
    }
  };

  const clearFormValues = () => {
    setFieldValue(PropertyAddressTypes.STREET1, '');
    setFieldValue(PropertyAddressTypes.STREET2, '');
    setFieldValue(PropertyAddressTypes.CITY, '');
    setFieldValue(PropertyAddressTypes.STATE, '');
    setFieldValue(PropertyAddressTypes.ZIP, '');
    setFieldValue(PropertyAddressTypes.COUNTY, '');

    setSelectAddressOption(emptyAddress);
    setSelectionMade(false);
  };

  const setFormValues = (selection: SimpleAddress) => {
    setSelectAddressOption(selection);
    setSelectionMade(true);

    // we use this function to get/set countyName as it is not returned from Smarty api
    // we then verify the address results and setFieldValue's from that data
    void verifyAddress(selection);
  };

  const verifyAddress = async (selection: SimpleAddress) => {
    const addressVerificationValues: SimpleAddress = {
      // for now we are concatenating the secondary onto street, to match the verify() api specs,
      street: `${selection.street} ${selection.secondary}`,
      secondary: selection.secondary,
      city: selection.city,
      state: selection.state,
      zipCode: selection.zipCode,
    };

    try {
      const results: ApolloQueryResult<VerifyAddressWithInputData> = await verifyAddressResults(
        addressVerificationValues,
        client,
      );

      // the fields returned from results.data are required
      if (results.data) {
        setFieldValue(PropertyAddressTypes.STREET1, results.data.verifyAddress.address.fullStreet ?? '');
        setFieldValue(PropertyAddressTypes.STREET2, results.data.verifyAddress.address.secondary ?? '');
        setFieldValue(PropertyAddressTypes.CITY, results.data.verifyAddress.address.city ?? '');
        setFieldValue(PropertyAddressTypes.STATE, results.data.verifyAddress.address.state ?? '');
        setFieldValue(PropertyAddressTypes.ZIP, results.data.verifyAddress.address.zipCode ?? '');
        setFieldValue(PropertyAddressTypes.COUNTY, results.data.verifyAddress.address.countyName ?? '');
      } else {
        setQueryError(true);
        clearFormValues();
      }
    } catch (err) {
      setQueryError(true);
    }
  };

  const getAddressSuggestions = useCallback(
    async (input: string) => {
      let addressSuggestions: SimpleAddress[] = [];

      if (input.length >= 4) {
        try {
          const results: ApolloQueryResult<SearchAddressWithInputData> = await getSearchAddressResults(
            input,
            client,
            formattedStateOptions,
          );

          // the fields returned from results.data are required
          const suggestions = results.data.searchAddress.suggestions;

          addressSuggestions = [...suggestions, manualAddressSelection];

          return addressSuggestions;
        } catch (err) {
          setQueryError(true);
        }
      }

      return [];
    },
    [client, formattedStateOptions],
  );

  const debounceAddressLoadOptions = useMemo(() => debounce(getAddressSuggestions, 300), [getAddressSuggestions]);

  const noOptions = () => (
    <Text as="p" color="carbon600" padding="space40" textAlign="center" width="100%">
      No Options
    </Text>
  );

  const loadingIndicator = () => {
    return (
      <Text as="p" color="carbon500" padding="space40" size="fontSize10" textAlign="center" width="100%">
        Loading....
      </Text>
    );
  };

  const setFieldToTouchedIfBlurred = () => {
    setFieldTouched(PropertyAddressTypes.STREET1);
  };

  const isOptionSelected = (option: SimpleAddress, allOptions: Options<SimpleAddress>) => {
    return allOptions[0] === option;
  };

  return (
    <InputGroup
      data-testid="address-search-select"
      groupId="street1"
      isInvalid={street1Error}
      mb="space50"
      onBlur={setFieldToTouchedIfBlurred}
    >
      {queryError && (
        <Box mb="space40">
          <ErrorMessage
            description="There was a problem searching for the address information"
            title="Unable to search Address"
          />
        </Box>
      )}
      <InputLabel>Address *</InputLabel>
      <AsyncSelect
        components={{
          DropdownIndicator: () => null,
          Option,
          SingleValue,
          NoOptionsMessage: noOptions,
          LoadingMessage: loadingIndicator,
        }}
        customStyles={getAsyncSelectStyles(street1Error)}
        inputId="street1"
        inputValue={searchTerm}
        isClearable={!!selectionMade}
        isOptionSelected={isOptionSelected}
        isSearchable
        loadOptions={debounceAddressLoadOptions}
        name="property.address.street1"
        placeholder=""
        value={selectAddressOption}
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
      />
      {street1Error && <InputErrorMessage>{errors.property?.address?.street1}</InputErrorMessage>}
    </InputGroup>
  );
};
