import React, { useState, useEffect } from 'react';
// import { Flex, Button, IconButton, Box } from '@endpoint/blockparty';
import { Flex, IconButton, Box } from '@endpoint/blockparty';
import { TaskStatus, TodoStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';

import { TaskStatusFilter } from './TaskStatusFilter';
import { TodoStatusFilter } from './TodoStatusFilter';

interface TaskListHeaderProps {
  onAccordionToggle(): void;
  onFilterChange(taskFilterOptions: TaskFilterOptions): void;
}

export interface TaskFilterOptions {
  tasks?: TaskStatus[];
  todos?: TodoStatus[];
}

export const TaskListHeader: React.FC<TaskListHeaderProps> = ({ onAccordionToggle, onFilterChange }) => {
  const [filterOptions, setFilterOptions] = useState<TaskFilterOptions>();

  useEffect(() => {
    if (filterOptions) {
      onFilterChange(filterOptions);
    }
  }, [filterOptions, onFilterChange]);

  return (
    <Flex py="space50">
      <Flex flexGrow={1}>
        <IconButton
          label="expand / collapse"
          name="UnfoldLess"
          size="large"
          variant="outline"
          variantColor="carbon"
          onClick={(): void => onAccordionToggle()}
        />
        <Box ml="space50" width="224px">
          <TaskStatusFilter onChange={(value): void => setFilterOptions({ tasks: value })} />
        </Box>
        <Box ml="space50" width="244px">
          <TodoStatusFilter onChange={(value): void => setFilterOptions({ todos: value })} />
        </Box>
      </Flex>
      <Flex>
        {/* <Button variant="outline" iconLeft="Add" size="large">
          Add Task
        </Button> */}
      </Flex>
    </Flex>
  );
};

IconButton.displayName = 'IconButton';
