import * as React from 'react';
import { useContext, FC, createContext, useMemo, useState, useCallback } from 'react';

export type EditTransactionBasicInfoContextType = {
  isBeingEdited: boolean;
  toggleIsBeingEdited: () => void;
  setFocusField: (value: string) => void;
  focusField: string;
};

export const EditTransactionBasicInfoContext = createContext<EditTransactionBasicInfoContextType | undefined>(
  undefined,
);

export const initialEditTransactionBasicInfoValues = {};

export const EditTransactionBasicInfoContextProvider: FC = ({ children }) => {
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [focusField, setFocusFieldValue] = useState('');

  const toggleIsBeingEdited = useCallback(() => {
    setIsBeingEdited(!isBeingEdited);
  }, [isBeingEdited]);

  const setFocusField = useCallback((value: string) => {
    setFocusFieldValue(value);
  }, []);

  const values: EditTransactionBasicInfoContextType = useMemo(
    () => ({
      isBeingEdited,
      toggleIsBeingEdited,
      setFocusField,
      focusField,
    }),
    [focusField, isBeingEdited, setFocusField, toggleIsBeingEdited],
  );

  return <EditTransactionBasicInfoContext.Provider value={values}>{children}</EditTransactionBasicInfoContext.Provider>;
};

export function useEditTransactionBasicInfoContext() {
  const context = useContext(EditTransactionBasicInfoContext);

  if (context === undefined) {
    throw new Error('useEditTransactionBasicInfoContext must be used within a Provider');
  }

  return context;
}
