import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  Box,
  CheckboxGroup,
  Checkbox,
  useToast,
  useDisclosure,
  Input,
  InputLabel,
  Text,
  Flex,
  Button,
  Modal,
  ModalContent,
  ModalSection,
  ModalFooter,
} from '@endpoint/blockparty';
import { Transaction, QualityControlStatus } from '@endpoint/opsware-bff-graphql-schema';
import { QualityControlFailureOption as QualityControlFailureOptionEnum } from '@endpoint/common-model';
import { networkFailureInfo } from 'consts/toastProps';
import { TransactionUpdateResult } from 'routes/Dashboard/OpenOrderList/queries';
import { UPDATE_TRANSACTION_BASIC_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import { QualityControlFailureOption } from '@endpoint/opsware-bff-graphql-schema/dist/types';

import { useQCFailureOptions } from '../../../../hooks/useQCFailureOptions';

interface ModalQCFailureReasonsProps {
  transaction: Transaction;
}

export const ModalQCFailureReasons: FC<ModalQCFailureReasonsProps> = ({ transaction, children }) => {
  const { data, loading } = useQCFailureOptions(transaction.type);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [showReasonOther, setShowReasonOther] = useState(false);
  const [textReasonOther, setTextReasonOther] = useState('');
  const [selectedFailureOptions, setSelectedFailureOptions] = useState(
    {} as Partial<Record<keyof typeof QualityControlFailureOptionEnum, string>>,
  );

  const qcFailureOptions = (data?.qualityControlFailureOptions as Array<QualityControlFailureOption>)?.map(
    (failureOption) => failureOption.value,
  ) as Array<keyof typeof QualityControlFailureOptionEnum>;

  const [updateToQCFailed] = useMutation<TransactionUpdateResult>(UPDATE_TRANSACTION_BASIC_INFO, {
    onCompleted(): void {
      onClose();
      toast({
        description: 'Marked as QC Failed',
        icon: 'CheckCircle',
      });
    },
    onError(): void {
      toast(networkFailureInfo);
    },
  });

  useEffect(() => {
    const selectedOptions = Object.values(selectedFailureOptions).filter((reason) => reason);

    setShowReasonOther(selectedFailureOptions.OTHER != null);

    if (showReasonOther) {
      setIsSaveButtonDisabled(!textReasonOther);
    } else {
      setIsSaveButtonDisabled(!selectedOptions.length);
    }
  }, [selectedFailureOptions, showReasonOther, textReasonOther]);

  const onSubmitFailureReasons = () => {
    if (textReasonOther && showReasonOther) {
      selectedFailureOptions.OTHER = textReasonOther;
    } else {
      setTextReasonOther('');
      setShowReasonOther(false);
      delete selectedFailureOptions.OTHER;
    }

    const qualityControlFailureReasons = Object.values(selectedFailureOptions).filter((option) => option);

    void updateToQCFailed({
      variables: {
        where: { id: transaction.id },
        updateTransaction: {
          qualityControlStatus: QualityControlStatus.QC_FAILED,
          qualityControlFailureReasons,
        },
      },
    });
  };

  return (
    <>
      <Box data-test-id="mark-qc-failed-button-wrapper" onClick={onOpen}>
        {children}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalSection width="max-content">
            <Flex flexDirection="column">
              <Text as="h3" fontWeight="semi" mb="space50" size="fontSize30">
                QC Fail Reason
              </Text>
              <Text color="carbon900" mb="space50">
                Please select all reasons that apply.
              </Text>
              <CheckboxGroup color="carbon900" mb="space50">
                {qcFailureOptions?.map((failureOption) => {
                  return (
                    <Checkbox
                      key={failureOption}
                      name={QualityControlFailureOptionEnum[failureOption]}
                      value={failureOption}
                      onChange={() => {
                        setSelectedFailureOptions({
                          ...selectedFailureOptions,
                          [failureOption]: selectedFailureOptions[failureOption] ? null : failureOption,
                        });
                      }}
                    >
                      {QualityControlFailureOptionEnum[failureOption]}
                    </Checkbox>
                  );
                })}
              </CheckboxGroup>
              {showReasonOther && (
                <Box color="carbon900">
                  <InputLabel color="carbon500" htmlFor="failure-reason-other" size="fontSize10">
                    Please leave a note describing the reason *
                  </InputLabel>
                  <Input
                    id="failure-reason-other"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setTextReasonOther(event.target.value);
                    }}
                  />
                </Box>
              )}
            </Flex>
          </ModalSection>
          <ModalFooter>
            <Button isLoading={loading} mr="space40" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button isDisabled={isSaveButtonDisabled} isLoading={loading} onClick={onSubmitFailureReasons}>
              <Text color="white" fontWeight="semi">
                Save
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
