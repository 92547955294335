import { TransactionType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { useQuery } from '@apollo/client';
import { GET_QC_FAILURE_OPTIONS, GetQCFailureOptions } from 'hooks/useQCFailureOptions/queries';

export const useQCFailureOptions = (transactionType: TransactionType | undefined) => {
  return useQuery<GetQCFailureOptions>(GET_QC_FAILURE_OPTIONS, {
    variables: {
      input: {
        transactionType,
      },
    },
  });
};
