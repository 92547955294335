import React, { FC } from 'react';
import {
  Box,
  Flex,
  InputGroup,
  InputLabel,
  Input,
  Divider,
  Heading,
  Grid,
  Select,
  InputErrorMessage,
} from '@endpoint/blockparty';
import { Field, useFormikContext } from 'formik';
import { TransactionParticipant, UpdateContactInput } from '@endpoint/opsware-bff-graphql-schema';
import { stateOptions, StateOption } from 'consts/states';
import { UpdateContactField } from 'consts/updateContact';
import {
  isParticipantAgentOrTC,
  isParticipantTxIndividual,
  isParticipantEntity,
  isParticipantLoanContact,
} from 'helpers/contacts';
import { useEditContactDetailsProvider } from 'hooks/useEditContactDetailsProvider';

interface DetailsFieldsProps {
  participant: TransactionParticipant;
}

export const DetailsFields: FC<DetailsFieldsProps> = ({ participant }) => {
  const isAgent = isParticipantAgentOrTC(participant);
  const isTxIndividual = isParticipantTxIndividual(participant);
  const isLoanContact = isParticipantLoanContact(participant);
  const isEntity = isParticipantEntity(participant);

  const { errors, setFieldValue, touched, values } = useFormikContext<UpdateContactInput>();
  const { state } = useEditContactDetailsProvider();

  return (
    <Box pb="space50" width="100%">
      <Heading backgroundColor="slate0" p="space40" size="fontSize30">
        Details
      </Heading>

      <Flex flexDirection="column" pt="space30" px="space40" width="50%">
        {isEntity ? (
          <InputGroup
            groupId={UpdateContactField.ENTITY_NAME}
            isInvalid={touched.entityName && !!errors.entityName}
            mb={isAgent || isLoanContact ? 'space30' : 'space0'}
          >
            <InputLabel>Name *</InputLabel>
            <Field
              as={Input}
              autoComplete="none"
              autoFocus={state.selectedField === UpdateContactField.ENTITY_NAME}
              inputId={UpdateContactField.ENTITY_NAME}
              name={UpdateContactField.ENTITY_NAME}
              placeholder="Name"
              type="text"
              value={values.entityName}
            />
            <InputErrorMessage>{errors.entityName}</InputErrorMessage>
          </InputGroup>
        ) : (
          <>
            <InputGroup
              groupId={UpdateContactField.FIRST_NAME}
              isInvalid={touched.firstName && !!errors.firstName}
              mb="space30"
            >
              <InputLabel>First Name *</InputLabel>
              <Field
                as={Input}
                autoComplete="none"
                autoFocus={state.selectedField === UpdateContactField.FIRST_NAME}
                inputId={UpdateContactField.FIRST_NAME}
                name={UpdateContactField.FIRST_NAME}
                placeholder="First Name"
                type="text"
                value={values.firstName}
              />
              <InputErrorMessage>{errors.firstName}</InputErrorMessage>
            </InputGroup>
            {isTxIndividual && (
              <InputGroup
                groupId={UpdateContactField.MIDDLE_NAME}
                isInvalid={touched.middleName && !!errors.middleName}
                mb="space30"
              >
                <InputLabel>Middle Name</InputLabel>
                <Field
                  as={Input}
                  autoComplete="none"
                  autoFocus={state.selectedField === UpdateContactField.MIDDLE_NAME}
                  inputId={UpdateContactField.MIDDLE_NAME}
                  name={UpdateContactField.MIDDLE_NAME}
                  placeholder="Middle Name"
                  type="text"
                  value={values.middleName}
                />
                <InputErrorMessage>{errors.middleName}</InputErrorMessage>
              </InputGroup>
            )}
            <InputGroup
              groupId={UpdateContactField.LAST_NAME}
              isInvalid={touched.lastName && !!errors.lastName}
              mb={isAgent || isLoanContact ? 'space30' : 'space0'}
            >
              <InputLabel>Last Name *</InputLabel>
              <Field
                as={Input}
                autoComplete="none"
                autoFocus={state.selectedField === UpdateContactField.LAST_NAME}
                inputId={UpdateContactField.LAST_NAME}
                name={UpdateContactField.LAST_NAME}
                placeholder="Last Name"
                type="text"
                value={values.lastName}
              />
              <InputErrorMessage>{errors.lastName}</InputErrorMessage>
            </InputGroup>
          </>
        )}
        {(isAgent || isLoanContact) && <Divider data-test-id="divider" />}
      </Flex>
      <Grid
        columnGap="space60"
        px="space40"
        py="space30"
        rowGap="space30"
        templateColumns="repeat(2, minmax(150px, 1fr))"
      >
        {isAgent && (
          <>
            <Flex flexDirection="column">
              <InputGroup groupId={UpdateContactField.LICENSE_NUMBER} isInvalid={!!errors.licenseNumber} mb="space30">
                <InputLabel>License Number</InputLabel>
                <Field
                  as={Input}
                  autoComplete="none"
                  autoFocus={state.selectedField === UpdateContactField.LICENSE_NUMBER}
                  inputId={UpdateContactField.LICENSE_NUMBER}
                  name={UpdateContactField.LICENSE_NUMBER}
                  placeholder="License Number"
                  type="text"
                  value={values.licenseNumber}
                />
                <InputErrorMessage>{errors.licenseNumber}</InputErrorMessage>
              </InputGroup>
              <Divider data-test-id="divider" marginTop="auto" />
            </Flex>
            <Flex flexDirection="column">
              <InputGroup
                groupId={UpdateContactField.STATE_OF_OPERATION}
                isInvalid={!!errors.stateOfOperation}
                mb="space30"
              >
                <InputLabel htmlFor="stateOfOperation">State of Operation</InputLabel>
                <Field
                  as={Select}
                  autoFocus={state.selectedField === UpdateContactField.STATE_OF_OPERATION}
                  defaultValue={stateOptions.find((option) => option.value === values.stateOfOperation)}
                  inputId="stateOfOperation"
                  isClearable
                  isSearchable
                  name="stateOfOperation"
                  options={stateOptions}
                  value={stateOptions.find((option) => option.value === values.stateOfOperation) || ''}
                  onChange={(option: StateOption | null) => {
                    if (!option) {
                      setFieldValue('stateOfOperation', null);
                    } else {
                      setFieldValue('stateOfOperation', option.value);
                    }
                  }}
                />
                <InputErrorMessage>{errors.stateOfOperation}</InputErrorMessage>
              </InputGroup>
              <Divider data-test-id="divider" marginTop="auto" />
            </Flex>
            <Box>
              <InputGroup groupId={UpdateContactField.MLS_ID}>
                <InputLabel>MLS ID</InputLabel>
                <Field
                  as={Input}
                  autoComplete="none"
                  autoFocus={state.selectedField === UpdateContactField.MLS_ID}
                  inputId={UpdateContactField.MLS_ID}
                  name={UpdateContactField.MLS_ID}
                  placeholder="MLS ID"
                  type="text"
                  value={values.mlsId}
                />
              </InputGroup>
            </Box>
          </>
        )}
        {isLoanContact && (
          <InputGroup groupId={UpdateContactField.NMLS_ID}>
            <InputLabel>NMLS ID</InputLabel>
            <Field
              as={Input}
              autoComplete="none"
              autoFocus={state.selectedField === UpdateContactField.NMLS_ID}
              inputId={UpdateContactField.NMLS_ID}
              name={UpdateContactField.NMLS_ID}
              placeholder="NMLS ID"
              type="text"
              value={values.nmlsId}
            />
            <InputErrorMessage>{errors.nmlsId}</InputErrorMessage>
          </InputGroup>
        )}
      </Grid>
    </Box>
  );
};
