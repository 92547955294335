import { BreadCrumbLink } from 'apollo/typeDefs';
import { breadCrumbLinksVar } from 'apollo/ApolloCache';

export function updateBreadcrumbs(breadcrumbs: BreadCrumbLink[]) {
  const newBreadcrumbs = breadcrumbs.map((breadcrumb: BreadCrumbLink) => ({
    ...breadcrumb,
  }));

  return breadCrumbLinksVar(newBreadcrumbs);
}
