import { OpsTasksFilter, OpsTaskStatus } from '@endpoint/opsware-bff-graphql-schema';
import { useQueryParams } from 'helpers/utils/queryParams/';
import { OpsTask, OpsTasksSortProperties, SortDirection } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { ColumnInstance } from 'react-table';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import _ from 'lodash';
import { QueueSortTableAccessor, toOpsTasksSortPropertiesMap, toOpsTasksTableAccessorMap } from 'consts/queue';
import { defaultSquads } from 'consts/supportedTaskSquad';
import { OpsTaskQueryableState, SupportedOptions } from 'consts/supportedTaskOptions';

export interface QueueFilters {
  squads: string;
  statuses: string;
  search: string;
  itemsPerPage: string;
  pageIndex: string;
  marketIds: string | string[];
  sort: [OpsTasksSortProperties, SortDirection] | [];
}

export const getSortQuery = (column: ColumnInstance<OpsTask>) => {
  const propertyName = toOpsTasksSortPropertiesMap[column.id as QueueSortTableAccessor];
  let sort: [OpsTasksSortProperties, SortDirection] | [] = [propertyName, SortDirection.ASC];

  if (column.isSorted) {
    sort = column.isSortedDesc ? [] : [propertyName, SortDirection.DESC];
  }

  return sort;
};

export const useGetInitialSortState = () => {
  const location = useLocation();
  const searchParams = parse(location.search, { arrayFormat: 'comma' });
  const sortQuery = searchParams.sort;

  if (sortQuery) {
    const id = toOpsTasksTableAccessorMap[sortQuery[0] as OpsTasksSortProperties];

    if (sortQuery[1] === 'DESC') {
      return [
        {
          id,
          desc: true,
        },
      ];
    }

    return [
      {
        id,
        desc: false,
      },
    ];
  }

  return [];
};

export function useQueueFilters() {
  return useQueryParams((params: Partial<QueueFilters>) => ({
    search: Array.isArray(params.search)
      ? params.search.map((str: string) => str.trim()).join(', ')
      : params.search ?? '',
    itemsPerPage: params.itemsPerPage ?? '50',
    pageIndex: params.pageIndex ?? '1',
    squads: params.squads ?? undefined,
    statuses: params.statuses ?? [],
    marketIds: [params.marketIds ?? []].flat(1).map(Number),
    sort: params.sort,
  }));
}

export function buildStatuses(options: string | SupportedOptions[]): OpsTaskStatus[] {
  if (!options?.length) return [];

  const extractStatuses = (option: string) => Object.keys(OpsTaskStatus).includes(option);

  if (Array.isArray(options)) return options.filter(extractStatuses) as OpsTaskStatus[];

  return options.split(',').filter(extractStatuses) as OpsTaskStatus[];
}

export function buildIsOverdue(options: string | SupportedOptions[]): boolean | undefined {
  if (!options?.length) return undefined;

  if (Array.isArray(options)) return _.includes(options, OpsTaskQueryableState.OVERDUE);

  return options.split(',').includes(OpsTaskQueryableState.OVERDUE);
}

export function generateQueueQueryFilters(currentParams: QueueFilters) {
  const { itemsPerPage, pageIndex, search, statuses, marketIds, sort } = currentParams;

  const filters: OpsTasksFilter = {
    squads: defaultSquads,
    statuses: [],
    pageSize: 50,
    pageNumber: 1,
  };

  if (search) {
    filters.query = search.replace(/,\s*/g, ' ');
  }

  if (statuses) {
    filters.statuses = buildStatuses(statuses);
    filters.isOverdue = buildIsOverdue(statuses);
  }

  if (itemsPerPage) {
    filters.pageSize = parseInt(itemsPerPage, 10);
  }

  if (pageIndex) {
    filters.pageNumber = parseInt(pageIndex, 10);
  }

  if (marketIds) {
    filters.marketIds = Array.isArray(marketIds) ? marketIds.map(Number) : [parseInt(marketIds, 10)];
  }

  if (sort) {
    filters.sort = {
      propertyName: sort[0] as unknown as OpsTasksSortProperties,
      direction: sort[1] as unknown as SortDirection,
    };
  }

  return filters;
}
