import React, { FC } from 'react';
import { Avatar, Box, Card, Divider, Icon, Link, Text, Center, Flex } from '@endpoint/blockparty';
import { RouteComponentProps } from '@reach/router';
import { removeNonNumeric, formatPhoneNumber } from 'helpers/formatPhone';
import { giveFirstWord, getContactName } from 'helpers/formatText';
import { createFullstoryUrl } from 'helpers/utils/fullstory';
import { AllContactTypes } from 'consts/contactDetails';

import { formatContactTypeLikeTitle } from '../helpers/formatContactTypeLikeTitle';

export interface ContactInfoProps extends RouteComponentProps {
  contact: AllContactTypes;
}

export interface SignUpStatusInterface {
  isDeactivated: boolean;
  isOnboarded: boolean;
}

export const SignUpStatus: FC<SignUpStatusInterface> = ({ isDeactivated, isOnboarded }) => {
  if (isDeactivated) {
    return (
      <Box alignItems="center" display="flex">
        <Icon backgroundColor="watermelon500" borderRadius="radiusFull" color="white" name="Minus" />
        <Text ml="space30">Deactivated</Text>
      </Box>
    );
  }

  if (isOnboarded) {
    return (
      <Box alignItems="center" display="flex">
        <Icon backgroundColor="aloe500" borderRadius="radiusFull" color="white" name="CheckBasic" />
        <Text ml="space30">Joined</Text>
      </Box>
    );
  }

  return (
    <Box alignItems="center" display="flex">
      <Icon backgroundColor="marigold500" borderRadius="radiusFull" color="white" name="Minus" />
      <Text ml="space30">Not Onboarded</Text>
    </Box>
  );
};

export const ContactInfo: FC<ContactInfoProps> = ({ contact }) => {
  if (!contact) {
    return (
      <Box data-test-id="contact-info-unmatched" mr="space60" width="100%">
        <Card display="flex" mb="space60" p="space60" variant="shadow">
          <Text as="p">No matched participant</Text>
        </Card>
      </Box>
    );
  }

  const { firstName, lastName, entityName, email, phone, id, isOnboarded, isDeactivated, contactType, organizationId } =
    contact;

  const isEntity = !!('entityName' in contact);
  const isEPContact = organizationId === 1;
  const fullName = getContactName({ contact, partial: false });
  const avatarName = isEntity
    ? giveFirstWord(entityName)
    : `${giveFirstWord(firstName || '')} ${giveFirstWord(lastName || '')}`;

  return (
    <Box data-test-id="contact-info-card" mr="space60" width="100%">
      <Card display="flex" flexDirection="column" mb="space50" p="space60" variant="shadow">
        <Box display="flex">
          <Avatar mr="space50" name={avatarName} size={40} />
          <Box width="100%">
            <Box display="flex" justifyContent="space-between">
              <Text data-test-id="contact-info-heading" fontWeight="heading" size="fontSize40">
                {fullName}
              </Text>
              <Center>
                <Text as="p" pr="space20">
                  ID: {id}
                </Text>
              </Center>
            </Box>
            <Box display="flex">
              <Text as="p" data-test-id="contact-info-role">
                {formatContactTypeLikeTitle(contactType)}
              </Text>
            </Box>
          </Box>
        </Box>
        <Divider my="space40" />
        <Box display="flex">
          {isEPContact && (
            <Flex>
              <Box data-test-id="contact-info-signup-status" display="flex" flexDirection="column" mr="space60">
                <Text color="carbon500" size="fontSize10">
                  Sign Up Status
                </Text>
                <SignUpStatus isDeactivated={!!isDeactivated} isOnboarded={!!isOnboarded} />
              </Box>
              <Box data-test-id="contact-info-fullstory" display="flex" flexDirection="column" mr="space60">
                <Text color="carbon500" size="fontSize10">
                  Recordings
                </Text>
                {email ? (
                  <Link color="blue500" href={createFullstoryUrl(email)} isExternal>
                    Fullstory
                  </Link>
                ) : (
                  '-'
                )}
              </Box>
            </Flex>
          )}
          <Box data-test-id="contact-info-phone" display="flex" flexDirection="column" mr="space60">
            <Text color="carbon500" size="fontSize10">
              Phone
            </Text>
            {phone ? (
              <Link color="blue500" href={`tel:+1${removeNonNumeric(phone)}`}>
                {formatPhoneNumber(phone)}
              </Link>
            ) : (
              '-'
            )}
          </Box>
          <Box data-test-id="contact-info-email" display="flex" flexDirection="column">
            <Text color="carbon500" size="fontSize10">
              Email
            </Text>
            <Link color="blue500" href={`mailto:${email}`}>
              {email || '-'}
            </Link>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
