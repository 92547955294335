import React, { FC } from 'react';
import { Contact, TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { groupBy, map } from 'lodash';
import { AvatarGroup } from '@endpoint/blockparty';

import { AvatarPopover } from './AvatarPopover';

interface TransactionHeaderUsersProps {
  transactionParticipants: TransactionParticipant[];
  fileNumber: string;
}

export interface ContactWithRoles extends Contact {
  roles: string[];
  firstName?: string;
  lastName?: string;
  entityName?: string;
  participantId?: string;
}

export const TransactionHeaderUsers: FC<TransactionHeaderUsersProps> = ({ transactionParticipants, fileNumber }) => {
  const contactsWithRoles = transactionParticipantsToContactsWithRoles(transactionParticipants);

  return (
    <AvatarGroup data-test-id="avatar-popover-group">
      {contactsWithRoles.map((contact) => (
        <AvatarPopover key={contact.id} contact={contact} fileNumber={fileNumber} />
      ))}
    </AvatarGroup>
  );
};

export const transactionParticipantsToContactsWithRoles = (
  participants: TransactionParticipant[],
): ContactWithRoles[] => {
  const participantsByContact = groupBy(participants, (participant) => participant.contact.id);

  const contactsWithRoles: ContactWithRoles[] = map(participantsByContact, (participantsArray) => {
    const { contact, id: participantId } = participantsArray[0];
    const roles = participantsArray.map((participant) => participant.roles.join(', '));

    return {
      ...contact,
      participantId,
      roles,
    };
  });

  return contactsWithRoles;
};
