import React, { FC } from 'react';
import { OptionProps } from 'react-select';
import { Contact } from '@endpoint/opsware-bff-graphql-schema';
import { selectComp } from '@endpoint/blockparty';

import { SearchContactOption } from './OptionContent';

// note: the test for src/routes/Transaction/TransactionContacts/AddNewContact/AddNewContactDrawer/ContactDetailsForm/SearchContact/Option/OptionContent
// is the only thing that needs to be tested, otherwise we're testing react-select

export const Option: FC<OptionProps<Contact, false>> = ({ ...props }) => {
  const { data } = props;

  return (
    // @ts-ignore
    <selectComp.Option {...props}>
      <SearchContactOption optionData={data} {...props} />
    </selectComp.Option>
  );
};
