import React, { FC } from 'react';
import { TodoAssignment } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { AccordionSection } from 'components/AccordionSection';
import { CustomerStatusCard } from 'routes/Transaction/TransactionRightPanel/CustomerStatus/CustomerStatusCard';

interface CustomerStatusProps {
  assignments: TodoAssignment[];
}

export const CustomerStatusCards: FC<CustomerStatusProps> = ({ assignments }) => (
  <>
    {assignments.map((assignment) => (
      <CustomerStatusCard key={`customer-status-card-${assignment.id}`} data={assignment} />
    ))}
  </>
);

export const CustomerStatus: FC<CustomerStatusProps> = ({ assignments }) => (
  <AccordionSection
    data-test-id="customer-status-accordion-section"
    dataTestId="customer-status"
    hasDivider
    isOpen
    title="Customer Status"
  >
    <CustomerStatusCards assignments={assignments || []} data-test-id="customer-status-cards" />
  </AccordionSection>
);

CustomerStatus.displayName = 'CustomerStatus';
