import React, { FC, Fragment } from 'react';
import { TodoAssignment, TodoStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Button,
  Text,
  Heading,
  Box,
  Flex,
  Icon,
  Divider,
  Popover,
  PopoverTrigger,
  usePopoverState,
} from '@endpoint/blockparty';
import { StatusTag } from 'components/StatusTag';
import { formatDateDifference, isDateAlert } from 'helpers/datetimeHelper';
import { showTodoDueDate } from 'helpers/todo';
import { UpdateTodoStatus } from 'components/UpdateTodoStatus';
import { formatDate, YEAR_MONTH_DAY } from 'helpers/formatDate';

export interface TodosTabProps {
  todoAssignments?: TodoAssignment[];
}

export interface TodoAssignmentRowInterface {
  todoAssignment: TodoAssignment;
}

export const TodosTab: FC<TodosTabProps> = ({ todoAssignments }): React.ReactElement => {
  return (
    <Box data-test-id="contact-todos-tab">
      <Accordion>
        <AccordionItem defaultIsOpen>
          <AccordionHeader alignItems="center" backgroundColor="slate0" display="flex" height="48px" iconLeft>
            <Heading as="h5" size="fontSize30">
              To-Do&apos;s
            </Heading>
          </AccordionHeader>
          <AccordionPanel pb="space30" pt="space0" px="space60">
            {todoAssignments && todoAssignments.length ? (
              todoAssignments.map((todoAssignment, index) => (
                <Fragment key={todoAssignment.id}>
                  <TodoAssignmentRow todoAssignment={todoAssignment} />
                  {index + 1 < todoAssignments.length && <Divider data-test-id="divider" />}
                </Fragment>
              ))
            ) : (
              <Text as="p" color="carbon500" mb="space20" mt="space40" textAlign="center">
                No to-do&apos;s yet
              </Text>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export const TodoAssignmentRow: FC<TodoAssignmentRowInterface> = ({ todoAssignment }) => {
  const popover = usePopoverState({ placement: 'bottom-end' });

  const preparedDate: Date = new Date(todoAssignment.todo?.due);

  preparedDate.setUTCHours(23, 0, 0, 0);

  const dueDate: string = formatDate(preparedDate, YEAR_MONTH_DAY, true);
  const currentDate: string = formatDate(new Date(), YEAR_MONTH_DAY, true);

  return (
    <Box data-test-id="todo-assignment-row" py="space30">
      <Flex justifyContent="space-between" width="100%">
        <Box key={todoAssignment.id}>
          <Text>{todoAssignment.todo?.name}</Text>
        </Box>
        <Flex alignItems="center" flexDirection="row">
          {showTodoDueDate(todoAssignment.status) && todoAssignment.todo?.due && (
            <Text
              color={isDateAlert(new Date(dueDate)) ? 'watermelon500' : 'carbon600'}
              data-test-id={`contact-todo-${todoAssignment.id}-todo-due`}
              size="fontSize10"
            >
              {formatDateDifference(new Date(dueDate), new Date(currentDate))}
            </Text>
          )}

          <Box ml="space30" mr="space60" pr={todoAssignment.status === TodoStatus.RECALLED ? 'space50' : 'space0'}>
            <StatusTag
              data-test-id={`contact-todo-${todoAssignment.id}-status`}
              status={todoAssignment.status}
              variantType="outline"
            />
          </Box>
          {todoAssignment.status !== TodoStatus.RECALLED && (
            <>
              <PopoverTrigger ml="space0" {...popover}>
                <Button size="none" variant="unstyled">
                  <Icon color="carbon500" name="MoreHorizontal" size="large" />
                </Button>
              </PopoverTrigger>
              <Popover {...popover} aria-label="Update To-Do Status" px="space0" py="space30">
                <UpdateTodoStatus
                  assignmentStatus={todoAssignment}
                  data-test-id={`contact-todo-${todoAssignment.id}-update-todo-status`}
                  updateLocation="Contact Details"
                />
              </Popover>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
