import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Drawer,
  DrawerContent,
  DrawerActionBar,
  DrawerCloseButton,
  Flex,
  DrawerHeader,
  Text,
  DrawerSection,
  Box,
  RadioGroup,
  Radio,
  Card,
  DrawerFooter,
  Button,
} from '@endpoint/blockparty';
import { Milestone, MilestoneStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { editMilestoneStatusOrder, milestoneStatusMap } from 'consts/milestoneConsts';
import { mapStatusText } from 'helpers/statusHelper';
import { ErrorMessage } from 'components/ErrorMessage';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { MilestoneModified, TrackingEvent } from 'consts/segmentProtocols';
import { getMilestoneName } from 'helpers/milestone';

import { UPDATE_MILESTONE } from './queries';

interface EditMilestoneDrawerProps {
  milestone: Milestone;
  isOpen: boolean;
  onClose(): void;
}

export const EditMilestoneDrawer: FC<EditMilestoneDrawerProps> = ({ isOpen, onClose, milestone }) => {
  const [selectedOption, setSelectedOption] = useState<MilestoneStatus>(milestone.status);

  const [hasError, setError] = useState(false);

  const [updateMilestoneStatus] = useMutation(UPDATE_MILESTONE, {
    onCompleted: () => {
      setError(false);
      trackAnalytics(trackingEvent, trackingData);
      onClose();
    },
    onError: (error) => {
      setError(true);
    },
  });

  const statusOptions = [...(milestone.statusOptions || []), milestone.status];

  const trackingData: MilestoneModified = {
    milestone: milestone.position,
    newValue: selectedOption,
    originalValue: milestone.status,
  };

  const trackingEvent: TrackingEvent = 'Milestone Modified';

  return (
    <Drawer
      data-test-id={`edit-milestone-${milestone.id}`}
      id={`edit-milestone-${milestone.id}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DrawerContent>
        <DrawerActionBar>
          <DrawerCloseButton />
        </DrawerActionBar>
        <Flex flex={1} flexDirection="column">
          <DrawerHeader>
            <Text as="h4" data-test-id="edit-milestone-title" fontWeight="semi" size="fontSize50">
              Edit Milestone
            </Text>
            <Text as="p" data-test-id="edit-milestone-name" pt="space30">
              {milestone.name}
            </Text>
          </DrawerHeader>
          {hasError && (
            <DrawerSection>
              <ErrorMessage />
            </DrawerSection>
          )}
          <DrawerSection>
            <Box>
              <Text as="h6" fontWeight="semi" pb="space20" size="fontSize30">
                Select State
              </Text>
            </Box>
            <Box>
              <Text as="p">Select the state to display on the customers’ progress tracker.</Text>
              <RadioGroup
                aria-label="Edit Milestone State"
                defaultValue={selectedOption}
                name="edit-milestone-state"
                pt="space60"
              >
                {editMilestoneStatusOrder.map((value) => {
                  const isValueSelected = selectedOption === value;
                  const isValueDisabled = !statusOptions.includes(value);

                  return (
                    <Card
                      key={value}
                      border="1px solid"
                      borderColor={isValueSelected ? 'blue500' : 'carbon200'}
                      p="space50"
                      variant="ghost"
                    >
                      <Flex>
                        <Radio
                          data-test-id={`edit-milestone-status-${value}`}
                          isDisabled={isValueDisabled}
                          value={value}
                          onClick={(event: { preventDefault: () => void }): void => {
                            if (!isValueDisabled) {
                              setSelectedOption(value);
                            } else {
                              event.preventDefault();
                            }
                          }}
                        />
                        <Flex flexDirection="column" ml="space30">
                          <Text color={isValueDisabled ? 'carbon300' : 'carbon900'} fontWeight="semi">
                            {mapStatusText(value, milestoneStatusMap)}
                          </Text>
                          <Text color={isValueDisabled ? 'carbon300' : 'carbon900'} mt="space20">
                            {getMilestoneName(milestone.type, value)}
                          </Text>
                        </Flex>
                      </Flex>
                    </Card>
                  );
                })}
              </RadioGroup>
            </Box>
          </DrawerSection>
        </Flex>
        <DrawerFooter>
          <Button
            data-test-id="edit-milestone-status-cancel"
            mr="space30"
            variant="outline"
            onClick={(): void => {
              setError(false);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-test-id="edit-milestone-status-save"
            onClick={(): void => {
              void updateMilestoneStatus({
                variables: {
                  data: {
                    status: selectedOption,
                  },
                  where: {
                    id: milestone.id,
                  },
                },
              });
            }}
          >
            <Text color="white" fontWeight="semi">
              Save Changes
            </Text>
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
