import React, { useContext } from 'react';
import { Task, TaskStatus, TodoStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { navigate } from '@reach/router';
import { ListChoice, Flex, Text, Box, Divider } from '@endpoint/blockparty';
import { flatten, sortBy } from 'lodash';
import { toDoStatusRanking } from 'consts/toDoConsts';
import { StatusTag } from 'components/StatusTag';
import { formatDateDifference, isDateAlert } from 'helpers/datetimeHelper';
import { mapStatusToColor, mapStatusText } from 'helpers/statusHelper';
import { taskStatusBgColorMap, taskStatusMap, taskStatusBorderColorMap } from 'consts/taskConsts';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { TrackingEvent, TaskClicked } from 'consts/segmentProtocols';
import { formatDate, YEAR_MONTH_DAY } from 'helpers/formatDate';

import { TaskListContext } from '../..';

interface MilestoneAccordionItemProps {
  task: Task;
}

export const MilestoneAccordionItem: React.FC<MilestoneAccordionItemProps> = ({ task }) => {
  const { fileNumber, taskId, transaction } = useContext(TaskListContext);

  const todoStatuses = flatten(
    task.todos.map((todo) => (todo.assignments || []).map((assignment) => assignment.status)),
  );

  const rankedTodoStatuses = rankTodoStatuses(todoStatuses);

  const trackingEvent: TrackingEvent = 'Task Clicked';
  const trackingData: TaskClicked = { taskName: task.name };
  const isDone: boolean = task.status === TaskStatus.COMPLETED || task.status === TaskStatus.WAIVED;

  const preparedDate: Date = task.todos.length > 0 ? new Date(task.todos[0].due) : new Date();

  preparedDate.setUTCHours(23, 0, 0, 0);

  const dueDate: string = formatDate(preparedDate, YEAR_MONTH_DAY, true);
  const currentDate: string = formatDate(new Date(), YEAR_MONTH_DAY, true);

  return (
    <ListChoice
      data-test-id={`milestone-accordion-item-${task.id}`}
      px="space70"
      py="space40"
      selected={taskId === task.id}
      onClick={(): void => {
        trackAnalytics(trackingEvent, trackingData);
        const transactionTarget = fileNumber || `id-${transaction?.id}`;

        void navigate(`/transaction/${transactionTarget}/tasks/${task.id}`);
      }}
    >
      <Flex width="100%">
        <Flex alignItems="center" px="space30" width={120}>
          <Box
            bg={mapStatusToColor(task.status, taskStatusBgColorMap)}
            border="1px solid"
            // @ts-ignore - revisit types
            borderColor={mapStatusToColor(task.status, taskStatusBorderColorMap)}
            borderRadius="50%"
            height={13}
            mr="space30"
            width={13}
          />
          <Text>{mapStatusText(task.status, taskStatusMap)}</Text>
        </Flex>
        <Divider mr="space40" orientation="vertical" />
        <Flex alignItems="center" flexGrow={1}>
          <Text
            color={isDone ? 'carbon500' : 'carbon900'}
            data-test-id={`task-${task.id}-name`}
            ml="space30"
            textDecoration={isDone ? 'line-through' : undefined}
          >
            {task.name}
          </Text>
        </Flex>
        {task.status !== TaskStatus.COMPLETED && (
          <Flex>
            {task.todos.length > 0 &&
              rankedTodoStatuses[0] !== TodoStatus.RECALLED &&
              rankedTodoStatuses[0] !== TodoStatus.SUBMITTED &&
              rankedTodoStatuses[0] !== TodoStatus.SUBMITTED_BY_OPS && (
                <Text
                  color={isDateAlert(new Date(dueDate || '')) ? 'watermelon500' : 'carbon500'}
                  data-test-id="task-due-date"
                  mr="space30"
                >
                  {formatDateDifference(new Date(dueDate || ''), new Date(currentDate))}
                </Text>
              )}
            {rankedTodoStatuses.length > 0 && (
              <StatusTag minWidth={84} status={rankedTodoStatuses[0]} variantType="outline" />
            )}
          </Flex>
        )}
      </Flex>
    </ListChoice>
  );
};

MilestoneAccordionItem.displayName = 'MilestoneAccordionItem';

export const rankTodoStatuses = (todoStatuses: TodoStatus[]): TodoStatus[] =>
  sortBy(todoStatuses, (todoStatus) => toDoStatusRanking[todoStatus]);
