import React, { FC } from 'react';
import { Box, Text } from '@endpoint/blockparty';
import { PendingView } from 'helpers/dynamicForm/types';

interface TaskStepPendingViewProps {
  pendingView: PendingView;
}

export const TaskStepPendingView: FC<TaskStepPendingViewProps> = ({ pendingView }) => {
  return (
    <Box paddingTop="space10">
      <Text>{pendingView.message}</Text>
    </Box>
  );
};
