import { TransactionType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { DocumentReviewType } from 'routes/DocumentReview/contexts/DocumentReviewContext';

export enum OpenOrderDrawerFormType {
  UPLOAD = 'upload',
  MANUAL = 'manual',
}

export enum OpenOrderDrawerFormStep {
  STEP1 = 'step1',
  STEP2 = 'step2',
}

export interface OpenOrderDrawerForm {
  ocrComplete?: boolean;
  formType: OpenOrderDrawerFormType;
  currentStep: OpenOrderDrawerFormStep;
  reviewType?: DocumentReviewType;
  transactionId?: string;
  transactionStatus?: string;
  isTest?: boolean;
  property: {
    address: {
      street1: string;
      street2?: string;
      city: string;
      state: string;
      zip: string;
      county: string;
    };
    parcelNumber?: string;
  };
  documentId: string;
  documentUploaded: boolean;
  market: string;
  terms: {
    productType: TransactionType;
    salePrice?: string;
    loanAmount?: string;
    loanNumber?: string;
    emdAmount?: string;
    emdPercent?: number;
  };
  organization?: string | number;
  fees: {
    sellerCreditsBuyer?: string;
    titleFees?: string;
  };
  dates: {
    closingDate?: Date;
    emdDueDate?: Date;
    contractExecutedDate?: Date;
  };
  ocrConfidenceScores?: {
    street1?: number;
    street2?: number;
    city?: number;
    state?: number;
    zip?: number;
    county?: number;
    parcelNumber?: number;
    loanAmount?: number;
    salePrice?: number;
    emdAmount?: number;
    productType?: number;
    sellerCreditsBuyer?: number;
    closingDate?: number;
    titleFees?: number;
  };
  manualAddress?: boolean;
}
