import React, { FC } from 'react';
import {
  Alert,
  AlertIcon,
  AlertContent,
  AlertDescription,
  AlertTitle,
  Text,
  AlertCloseButton,
} from '@endpoint/blockparty';

interface ErrorMessageProps {
  description?: string;
  title?: string;
  closeAction?: () => void;
  isCloseButtonShown?: boolean;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({
  description,
  title,
  closeAction,
  isCloseButtonShown = true,
  children,
}) => (
  <Alert status="error">
    <AlertIcon />
    <AlertContent>
      <AlertTitle as="p">{title || 'An error occurred'}</AlertTitle>
      <AlertDescription>
        {children}
        <Text>{description || 'Contact the Opsware product team for assistance.'}</Text>
      </AlertDescription>
    </AlertContent>
    {isCloseButtonShown && <AlertCloseButton data-test-id="alert-close-btn" onClick={closeAction} />}
  </Alert>
);

ErrorMessage.displayName = 'ErrorMessage';
