import React, { FC } from 'react';
import { SearchEmbed, LiveboardEmbed } from '@thoughtspot/visual-embed-sdk/lib/src/react';

export const ThoughtspotSearch: FC = () => {
  return <SearchEmbed dataSources={['fc692d95-846a-4f49-9223-ae8891414c25']} frameParams={{ height: '70vh' }} />;
};

export const ThoughtspotLiveboard: FC = () => {
  return <LiveboardEmbed frameParams={{ height: '70vh' }} liveboardId="fc692d95-846a-4f49-9223-ae8891414c25" />;
};

export const ThoughtspotVisualization: FC = () => {
  return (
    <LiveboardEmbed
      frameParams={{ height: '70vh' }}
      liveboardId="fc692d95-846a-4f49-9223-ae8891414c25"
      vizId="1bed42f6-85e0-4da5-80f7-f30ac1435ead"
    />
  );
};
