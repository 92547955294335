import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalSection,
  Text,
  Radio,
  RadioGroup,
  Box,
} from '@endpoint/blockparty';
import { AddressDetails } from '@endpoint/opsware-bff-graphql-schema';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';
import { ADDRESS_SELECTION_TYPES } from 'components/OpenOrderDrawer/Step1/SearchAddress/addressSearch';
import { ErrorMessage } from 'components/ErrorMessage';

export interface VerifyAddressModalProps {
  completeValidation: (suggestedAddress?: AddressDetails | undefined) => void;
  enteredAddress: OpenOrderDrawerForm['property']['address'];
  suggestedAddress?: AddressDetails | undefined;
  isOpen: boolean;
  isValidAutoCorrectedAddress: boolean;
  onClose: () => void;
}

export const VerifyAddressModal: FC<VerifyAddressModalProps> = ({
  completeValidation,
  enteredAddress,
  isOpen,
  isValidAutoCorrectedAddress,
  onClose,
  suggestedAddress,
}) => {
  const [selectedOption, setSelectedOption] = useState(ADDRESS_SELECTION_TYPES.SUGGESTED_ADDRESS);

  useEffect(() => {
    setSelectedOption(
      isValidAutoCorrectedAddress ? ADDRESS_SELECTION_TYPES.SUGGESTED_ADDRESS : ADDRESS_SELECTION_TYPES.ENTERED_ADDRESS,
    );
  }, [isValidAutoCorrectedAddress]);

  const handleChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleAddressSelection = () => {
    const suggestedAddressSelected = selectedOption === ADDRESS_SELECTION_TYPES.SUGGESTED_ADDRESS;

    completeValidation(suggestedAddressSelected ? suggestedAddress : undefined);
  };

  return (
    <Modal data-test-id="address-verification-modal" isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalSection>
          <Flex flexDirection="column">
            <Text as="h3" fontWeight="semi" size="fontSize30">
              Address Verification
            </Text>
            <Text>We think the address you entered may be incorrect or incomplete.</Text>
            {!isValidAutoCorrectedAddress && (
              <Box mb="space20">
                <ErrorMessage
                  description="There was a problem verifying your address."
                  title="Are you sure you want to use this Address?"
                />
              </Box>
            )}
            <RadioGroup
              aria-label="verifyAddressOption"
              defaultValue={selectedOption}
              mt="space40"
              name="verifyAddressOption"
            >
              {isValidAutoCorrectedAddress && (
                <Radio
                  dataTestId="suggested-address-button"
                  value={ADDRESS_SELECTION_TYPES.SUGGESTED_ADDRESS}
                  onChange={(value: ChangeEvent<HTMLInputElement>) => handleChange(value.target.value)}
                >
                  <Text as="p" fontWeight="semi" mb="space20" ml="space10">
                    Suggested Address:
                    <br />
                    <Text fontWeight="normal" size="fontSize20">
                      {suggestedAddress?.fullStreet} {suggestedAddress?.secondary}
                      <br />
                      {suggestedAddress?.city}, {suggestedAddress?.state} {suggestedAddress?.zipCode}
                      <br />
                      {suggestedAddress?.countyName}
                    </Text>
                  </Text>
                </Radio>
              )}
              <Radio
                dataTestId="entered-address-button"
                value={ADDRESS_SELECTION_TYPES.ENTERED_ADDRESS}
                onChange={(value: ChangeEvent<HTMLInputElement>) => handleChange(value.target.value)}
              >
                <Text as="p" fontWeight="semi" mb="space20" ml="space10">
                  You entered:
                </Text>
                <Text fontWeight="normal" size="fontSize20">
                  {enteredAddress?.street1} {enteredAddress?.street2}
                  <br />
                  {enteredAddress?.city}, {enteredAddress?.state} {enteredAddress?.zip}
                  <br />
                  {enteredAddress?.county}
                </Text>
              </Radio>
            </RadioGroup>
          </Flex>
        </ModalSection>
        <ModalFooter>
          <Button
            mr="space30"
            variant="outline"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            dataTestId="address-save-button"
            minWidth={105}
            onClick={() => {
              handleAddressSelection();
              onClose();
            }}
          >
            Use this address
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
