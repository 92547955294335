import React, { FC } from 'react';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import { navigate } from '@reach/router';
import { Formik } from 'formik';
import {
  NewContactSchema,
  newContactDefault,
  ContactGQLErrorType,
  initialContactFormErrors,
  RelatedContactsGQLErrorType,
} from 'consts/createNewContact';
import { phoneNumberRegex } from 'helpers/formatPhone';
import { QueryInput } from 'consts/transactionConsts';
import { useNewContactProvider, ActionKind } from 'hooks/useContactProvider';
import { ActionKind as RelatedContactsActionKind, useRelatedContactsProvider } from 'hooks/useRelatedContactsProvider';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { ContactType, TransactionParticipant, TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { AddContactValidationItems, generateAddContactValidation } from 'helpers/contacts/addContactValidation';

import { AddNewContactDrawer } from './AddNewContactDrawer';
import { CREATE_TRANSACTION_PARTICIPANT, CreateTransactionParticipantData } from './queries';
import { GET_CONTACTS } from '../queries';

interface AddNewContactProps {
  isDrawerOpen: boolean;
  propertyAddress: string;
  transactionId?: string;
  transactionIdentifier: QueryInput;
  transactionParticipants: TransactionParticipant[];
  transactionType: TransactionType;
  toggleDrawer: (isOpen: boolean) => void;
}

export const AddNewContact: FC<AddNewContactProps> = ({
  isDrawerOpen,
  propertyAddress,
  transactionId,
  transactionIdentifier,
  transactionParticipants,
  transactionType,
  toggleDrawer,
}) => {
  const { state, dispatch } = useNewContactProvider();
  const { state: relatedContactsState, dispatch: relatedContactsDispatch } = useRelatedContactsProvider();
  const transactionUrl = `/transaction/${transactionIdentifier.fileNum || `id-${transactionIdentifier.id}`}`;

  const [createTransactionParticipant] = useMutation<CreateTransactionParticipantData>(CREATE_TRANSACTION_PARTICIPANT, {
    awaitRefetchQueries: true,

    onCompleted: (data) => {
      if (data.createTransactionParticipant?.autoAddErrors?.length) {
        relatedContactsDispatch({
          type: RelatedContactsActionKind.SET_RELATED_CONTACTS_GQL_ERROR,
          payload: {
            ...relatedContactsState,
            relatedContactsGQLError: RelatedContactsGQLErrorType.AUTO_ADD_RELATED_CONTACTS_ERROR,
          },
        });
      } else {
        relatedContactsDispatch({
          type: RelatedContactsActionKind.SET_RELATED_CONTACTS_GQL_ERROR,
          payload: { ...relatedContactsState, relatedContactsGQLError: null },
        });
      }

      dispatch({
        type: ActionKind.SET_CONTACT_GQL_ERROR,
        payload: { ...state, contactGQLError: null },
      });

      if (state.isNewContact) {
        trackAnalytics('New Contact Added to Transaction', {});
      } else {
        trackAnalytics('Duplicate Contact Added to Transaction', {});
      }

      toggleDrawer(false);
      void navigate(`${transactionUrl}/contacts/${data.createTransactionParticipant.id}`);
    },
    onError: (err) => {
      dispatch({
        type: ActionKind.SET_CONTACT_GQL_ERROR,
        payload: { ...state, contactGQLError: ContactGQLErrorType.CREATE_TRANSACTION_PARTICIPANT_ERROR },
      });
    },
    refetchQueries: [
      {
        query: GET_CONTACTS,
        variables: {
          where: {
            id: transactionId,
          },
        },
      },
    ],
  });

  const cancelAddContact = () => {
    toggleDrawer(false);
    dispatch({
      type: ActionKind.SET_CONTACT,
      payload: { ...state, selectedContactData: newContactDefault },
    });
  };

  return (
    <Formik
      initialErrors={{ ...initialContactFormErrors }}
      initialValues={{
        ...newContactDefault,
      }}
      validateOnChange
      validationSchema={() => generateAddContactValidation(state.selectedContactData.contactType as ContactType)}
      onSubmit={(values: NewContactSchema, { setSubmitting }) => {
        void createTransactionParticipant({
          variables: {
            input: {
              transactionId,
              contactId: state.selectedContactData.id,
              roles: state.selectedContactData.roles,
              contactType: values.contactType,
            },
          },
        });

        setSubmitting(false);
      }}
    >
      <AddNewContactDrawer
        handleCancel={cancelAddContact}
        isDrawerOpen={isDrawerOpen}
        propertyAddress={propertyAddress}
        toggleDrawer={toggleDrawer}
        transactionParticipants={transactionParticipants}
        transactionType={transactionType}
      />
    </Formik>
  );
};
