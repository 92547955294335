import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerActionBar,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  Flex,
  Text,
} from '@endpoint/blockparty';
import { navigate } from '@reach/router';
import { useFormikContext } from 'formik';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import React, { FC, useEffect, useState } from 'react';
import { AddressDetails, SimpleAddress, TransactionType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDocumentReviewContext } from 'routes/DocumentReview/contexts/DocumentReviewContext';
import {
  OpenOrderDrawerForm,
  OpenOrderDrawerFormStep,
  OpenOrderDrawerFormType,
} from 'helpers/openOrder/openOrderFormTypes';
import { useHasWriteAccess } from 'hooks/useHasWriteAccess';
import { VerifyAddressModal } from 'components/VerifyAddressModal';
import { useLazyQuery } from '@apollo/client';

import { CREATE_TRANSACTION_MUTATION_FAILED } from '.';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { VerifyAddressWithInputData, VERIFY_ADDRESS_QUERY } from './Step1/SearchAddress/queries';
import { PropertyAddressTypes } from './Step1/SearchAddress/addressSearch';

interface OpenOrderDrawerFormsProps {
  isOpen: boolean;
  setDrawerIsOpen: (value: boolean) => void;
}

export const OpenOrderDrawerForms: FC<OpenOrderDrawerFormsProps> = ({ isOpen, setDrawerIsOpen }) => {
  const {
    values,
    handleReset,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setStatus,
    errors,
    isSubmitting,
    status,
    isValid,
    touched,
  } = useFormikContext<OpenOrderDrawerForm>();
  const { documentId } = useDocumentReviewContext();
  const { clearUploads } = useUploadDocument();

  const [isVerifyAddressModalOpen, setIsVerifyAddressModalOpen] = useState(false);
  const [isValidAutoCorrectedAddress, setIsValidAutoCorrectedAddress] = useState(false);
  const [isValidationComplete, setIsValidationComplete] = useState(false);
  const openVerifyAddressModal = () => setIsVerifyAddressModalOpen(true);
  const closeVerifyAddressModal = () => setIsVerifyAddressModalOpen(false);

  const { isMarkTransactionAsTestEnabled, isAddressServiceEnabled } = useFlags();

  const hasWriteAccess = useHasWriteAccess();

  const hasMarkAsTestPermissions = hasWriteAccess && isMarkTransactionAsTestEnabled;

  const addressVerificationValues: SimpleAddress = {
    street: `${values.property?.address?.street1} ${values.property?.address?.street2}` || '',
    secondary: values.property?.address?.street2 || '',
    city: values.property?.address?.city || '',
    state: values.property?.address?.state || '',
    zipCode: values.property?.address?.zip || '',
  };

  const shouldShowValidationModal =
    isAddressServiceEnabled &&
    values.currentStep !== OpenOrderDrawerFormStep.STEP2 &&
    values.formType !== OpenOrderDrawerFormType.UPLOAD;

  const hasFormBeenTouched =
    !!touched.property?.address?.street1 ||
    !!touched.property?.address?.city ||
    !!touched.property?.address?.county ||
    !!touched.property?.address?.state ||
    !!touched.property?.address?.street2 ||
    !!touched.property?.address?.zip ||
    !!touched.terms?.productType;

  const [validateAddress, { data }] = useLazyQuery<VerifyAddressWithInputData>(VERIFY_ADDRESS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (results) => {
      if (results) {
        handleResultsData(results);
      } else {
        handleSubmitLogic();
      }
    },
    onError: (error) => {
      if (error) {
        // if an api error occurs, do not display verification modal
        handleSubmitLogic();
      }
    },
  });

  const handleAddressValidation = async () => {
    // if validation is complete and form is not touched, skip validation
    if (isValidationComplete && !hasFormBeenTouched) {
      handleSubmitLogic();

      return;
    }

    await runValidation();
  };

  const runValidation = async () => {
    await validateAddress({
      variables: {
        input: {
          address: addressVerificationValues,
        },
      },
    });
  };

  const handleResultsData = (results: VerifyAddressWithInputData) => {
    const isValidAddress = results.verifyAddress.status.valid;
    const isModifiedAddress = results.verifyAddress.status.modified;
    const isAutoCorrectedAddress = isValidAddress && isModifiedAddress;
    const isValidUnmodifiedAddress = isValidAddress && !isModifiedAddress;

    if (isAutoCorrectedAddress) {
      setIsValidAutoCorrectedAddress(true);
    }

    if (isValidUnmodifiedAddress) {
      setIsValidationComplete(true);

      handleSubmitLogic();
    } else {
      openVerifyAddressModal();
    }
  };

  const handleSubmitLogic = () => {
    if (values.formType === OpenOrderDrawerFormType.MANUAL) {
      if (
        values.currentStep === OpenOrderDrawerFormStep.STEP2 ||
        values.terms?.productType === TransactionType.PRELISTING
      ) {
        handleSubmit();
      } else {
        setFieldValue('currentStep', OpenOrderDrawerFormStep.STEP2);
      }
    } else if (values.currentStep === OpenOrderDrawerFormStep.STEP1) {
      handleReset();
      clearUploads();

      void navigate('/document-review');
    }
  };

  useEffect(() => {
    if (isValidationComplete && !values.property.address.street1) {
      setIsValidationComplete(false);
      setIsValidAutoCorrectedAddress(false);
    }
  }, [isValidationComplete, values.property?.address?.street1]);

  const completeValidation = (suggestedAddress?: AddressDetails | undefined) => {
    if (suggestedAddress) {
      setFieldValue(PropertyAddressTypes.STREET1, suggestedAddress.fullStreet ?? '');
      setFieldValue(PropertyAddressTypes.STREET2, suggestedAddress.secondary ?? '');
      setFieldValue(PropertyAddressTypes.CITY, suggestedAddress.city ?? '');
      setFieldValue(PropertyAddressTypes.STATE, suggestedAddress.state ?? '');
      setFieldValue(PropertyAddressTypes.ZIP, suggestedAddress.zipCode ?? '');
      setFieldValue(PropertyAddressTypes.COUNTY, suggestedAddress.countyName ?? '');
    }

    setIsValidationComplete(true);

    handleSubmitLogic();
  };

  const setFieldsToUnTouched = () => {
    setFieldTouched('property.address.street1', false);
    setFieldTouched('property.address.street2', false);
    setFieldTouched('property.address.city', false);
    setFieldTouched('property.address.state', false);
    setFieldTouched('property.address.county', false);
    setFieldTouched('property.address.zip', false);
    setFieldTouched('terms.productType', false);
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={() => {
        clearUploads();
        handleReset();
      }}
    >
      <DrawerContent>
        <DrawerActionBar>
          <Flex flexDirection="row-reverse" justifyContent="space-between" width="100%">
            <DrawerCloseButton
              color="carbon"
              data-test-id="open-new-transaction-drawer-x-button"
              label="Close open new transaction drawer"
              onClick={() => {
                handleReset();
                setDrawerIsOpen(false);
              }}
            />
            {values.currentStep !== OpenOrderDrawerFormStep.STEP1 && (
              <Button
                data-test-id="drawer-back-button"
                iconLeft="ArrowChevronLeft"
                size="none"
                variant="link"
                variantColor="blue"
                onClick={() => {
                  setStatus({ error: '' });

                  if (values.formType === OpenOrderDrawerFormType.MANUAL) {
                    if (values.currentStep === OpenOrderDrawerFormStep.STEP2) {
                      setFieldValue('formType', OpenOrderDrawerFormType.MANUAL);
                      setFieldValue('currentStep', OpenOrderDrawerFormStep.STEP1);
                    }
                  } else {
                    setFieldValue('currentStep', OpenOrderDrawerFormStep.STEP1);
                  }

                  setFieldsToUnTouched();
                }}
              >
                Back
              </Button>
            )}
          </Flex>
        </DrawerActionBar>
        <Flex flex={1} flexDirection="column" overflow="auto">
          {values.currentStep === OpenOrderDrawerFormStep.STEP1 && <Step1 />}
          {values.currentStep === OpenOrderDrawerFormStep.STEP2 && <Step2 />}
        </Flex>
        <DrawerFooter alignItems="center" justifyContent={hasMarkAsTestPermissions ? 'space-between' : 'flex-end'}>
          {hasMarkAsTestPermissions && values.formType === OpenOrderDrawerFormType.MANUAL ? (
            <Checkbox
              checked={values.isTest}
              dataTestId="mark-as-test-checkbox"
              value="Mark as Test"
              onChange={(event) => {
                setFieldValue('isTest', event.target.checked);
              }}
            >
              Mark as Test
            </Checkbox>
          ) : (
            <Box />
          )}
          <Box>
            <Button
              data-test-id="open-new-transaction-drawer-close-button"
              mr="space30"
              variant="outline"
              onClick={() => {
                clearUploads();
                handleReset();
                setDrawerIsOpen(false);
              }}
            >
              Close
            </Button>
            <Button
              data-test-id="open-order-drawer-next-button"
              iconRight={values.currentStep === OpenOrderDrawerFormStep.STEP1 ? 'ArrowChevronRight' : undefined}
              isDisabled={
                isSubmitting ||
                !isValid ||
                !!Object.keys(errors).length ||
                (values.formType === OpenOrderDrawerFormType.UPLOAD && documentId === '') ||
                status?.error === CREATE_TRANSACTION_MUTATION_FAILED
              }
              isLoading={isSubmitting}
              onClick={async () => {
                if (shouldShowValidationModal) {
                  await handleAddressValidation();
                } else {
                  handleSubmitLogic();
                }
              }}
            >
              <Text color="white" fontWeight="semi">
                {values.currentStep === OpenOrderDrawerFormStep.STEP1 &&
                values.terms?.productType !== TransactionType.PRELISTING
                  ? 'Next'
                  : 'Save'}
              </Text>
            </Button>
          </Box>
        </DrawerFooter>
        <VerifyAddressModal
          completeValidation={completeValidation}
          enteredAddress={values.property?.address}
          isOpen={isVerifyAddressModalOpen}
          isValidAutoCorrectedAddress={isValidAutoCorrectedAddress}
          suggestedAddress={isValidAutoCorrectedAddress ? data?.verifyAddress?.address : undefined}
          onClose={closeVerifyAddressModal}
        />
      </DrawerContent>
    </Drawer>
  );
};
