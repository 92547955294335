import React, { createContext, ReactNode, useContext, useReducer, useMemo } from 'react';
import { UpdateContactField } from 'consts/updateContact';

enum EditContactDetailsAction {
  SET_IS_EDITING = 'SET_IS_EDITING',
  SET_SELECTED_FIELD = 'SET_SELECTED_FIELD',
}

type Payload = {
  isEditing: boolean;
  selectedField: UpdateContactField | string;
};

type Action = { type: EditContactDetailsAction; payload: Payload };
type Dispatch = (action: Action) => void;
type State = {
  isEditing: boolean;
  selectedField: UpdateContactField | string;
};
type EditContactDetailsProviderProps = { children: ReactNode };

const EditContactDetailsContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function editContactDetailsReducer(state: State, action: Action): State {
  switch (action.type) {
    // used to close the form on discard changes, isEditing value should be false, selectedField is empty string
    case EditContactDetailsAction.SET_IS_EDITING: {
      if (state.isEditing === action.payload.isEditing) return { ...state };

      return { selectedField: '', isEditing: action.payload.isEditing };
    }

    // used to open the form and set the selectedField
    case EditContactDetailsAction.SET_SELECTED_FIELD: {
      if (state.selectedField === action.payload.selectedField && !state.isEditing)
        return { ...state, isEditing: true };

      return { selectedField: action.payload.selectedField, isEditing: true };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function EditContactDetailsProvider({ children }: EditContactDetailsProviderProps) {
  const [state, dispatch] = useReducer(editContactDetailsReducer, {
    isEditing: false,
    selectedField: '',
  });

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <EditContactDetailsContext.Provider value={value}>{children}</EditContactDetailsContext.Provider>;
}

function useEditContactDetailsProvider() {
  const context = useContext(EditContactDetailsContext);

  if (context === undefined) {
    throw new Error('useNewContactProvider must be used within a ContactProvider');
  }

  return context;
}

export { EditContactDetailsAction, EditContactDetailsProvider, useEditContactDetailsProvider };
