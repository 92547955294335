import {
  TransactionStatus,
  TransactionType,
  QualityControlStatus,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TagVariantColor } from '@endpoint/blockparty/dist/components/Tag';

export type TransactionStatusColorMap = {
  [index in TransactionStatus]: TagVariantColor;
};

type TransactionStatusMap = { [index in TransactionStatus]: string };
type TransactionTypesMap = { [index in TransactionType]: string };
type QualityControlStatusMap = { [index in QualityControlStatus]: string };

export const transactionStatusMap: TransactionStatusMap = {
  [TransactionStatus.PRELISTING]: 'Pre-Listing',
  [TransactionStatus.IN_ESCROW]: 'Open',
  [TransactionStatus.CLOSED]: 'Closed',
  [TransactionStatus.CANCELLED]: 'Cancelled',
  [TransactionStatus.DELETED_BY_OPS]: 'Open in Error',
};

export const transactionStatusBgColorMap: TransactionStatusColorMap = {
  [TransactionStatus.CANCELLED]: 'cancelled',
  [TransactionStatus.CLOSED]: 'closed',
  [TransactionStatus.IN_ESCROW]: 'open',
  [TransactionStatus.DELETED_BY_OPS]: 'openInError',
  [TransactionStatus.PRELISTING]: 'prelisting',
};

export const transactionTypeMap: TransactionTypesMap = {
  [TransactionType.REFINANCE]: 'Refinance',
  [TransactionType.PIGGYBACK_LOAN]: 'Piggyback Loan',
  [TransactionType.SALE_MORTGAGE]: 'Sale w/ Mortgage',
  [TransactionType.SALE_CASH]: 'Sale w/ Cash',
  [TransactionType.TITLE_ONLY]: 'Title Only',
  [TransactionType.PRELISTING]: 'Pre-Listing',
  [TransactionType.ESCROW_ONLY]: 'Escrow Only',
  [TransactionType.EQUITY]: 'Equity',
};

export const qualityControlStatusMap: QualityControlStatusMap = {
  [QualityControlStatus.PREPARING_ORDER]: 'Preparing Order',
  [QualityControlStatus.NEW_ORDER]: 'New Order',
  [QualityControlStatus.QC_COMPLETE]: 'QC Complete',
  [QualityControlStatus.QC_FAILED]: 'QC Failed',
  [QualityControlStatus.QC_READY]: 'Ready for QC',
};

export const transactionLinks: LinkProps[] = [
  {
    label: 'Basic Info',
    route: './',
  },
  { label: 'Tasks', route: './tasks' },
  {
    label: 'Contacts',
    route: './contacts',
  },
  // { label: 'Customers', route: './customers' },
  { label: 'Documents', route: './documents' },
  // { label: 'Recent Activity', route: './activity' },
];

export const transactionLinksPrelim: LinkProps[] = [
  {
    label: 'Basic Info',
    route: './',
  },
  {
    label: 'Contacts',
    route: './contacts',
  },
  // { label: 'Customers', route: './customers' },
  { label: 'Documents', route: './documents' },
  // { label: 'Recent Activity', route: './activity' },
];

export interface QueryInput {
  id?: string;
  fileNum?: string;
}
