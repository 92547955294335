import React, { FC } from 'react';
import {
  getBuyerSellerBorrowerRoleFromEventType,
  getParticipantsNamesObjectByRole,
  UserFirstLast,
} from 'helpers/transactionParticipants';
import {
  MilestoneType,
  Transaction,
  TransactionRole,
  TransactionStage,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { isKeyInContactObject } from 'helpers/contacts';
import { Avatar, Box, Flex, Icon, Text } from '@endpoint/blockparty';
import { formatLikeTitle, giveFirstWord } from 'helpers/formatText';
import { formatPhoneNumber } from 'helpers/formatPhone';
import { AllContactTypes } from 'consts/contactDetails';

interface SigningEventBodyProps {
  eventType: TransactionStage | MilestoneType;
  transaction: Transaction;
}

export const SigningEventBody: FC<SigningEventBodyProps> = ({ eventType, transaction }) => {
  const side = getBuyerSellerBorrowerRoleFromEventType(eventType, transaction);
  const sideIsBuyer = side === TransactionRole.BUYER;
  const participants = getParticipantsNamesObjectByRole(transaction, side);

  return (
    <Flex flexDirection="column" py="space60">
      {participants.length > 0 && (
        <Box data-test-id="participants" pb="space50">
          {participants.map((participant: UserFirstLast) => {
            const name = isKeyInContactObject(participant as AllContactTypes, 'entityName')
              ? giveFirstWord(participant?.entityName)
              : `${giveFirstWord(participant.firstName)} ${giveFirstWord(participant.lastName)}`;

            const textName = isKeyInContactObject(participant as AllContactTypes, 'entityName')
              ? participant?.entityName
              : `${participant.firstName} ${participant.lastName}`;

            return (
              <Flex key={participant.id} alignItems="center" data-test-id="participant-avatar-and-name" pb="space50">
                <Box>
                  <Avatar mr="space40" name={name} size={24} />
                </Box>
                <Text data-test-id="participant">
                  {textName} {`(${formatLikeTitle(side)})`}
                </Text>
              </Flex>
            );
          })}
        </Box>
      )}
      <Box>
        <Flex alignItems="center" pb="space50">
          <Icon color="carbon500" mr="space40" name="Pin" size="large" />
          <Text data-test-id="signing-location">
            {sideIsBuyer ? transaction.buyerSigning?.location || '' : transaction.sellerSigning?.location || ''}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <Icon color="carbon500" mr="space40" name="Person" size="large" />
          <Flex flexDirection="column">
            <Text data-test-id="notary-name">
              {sideIsBuyer ? transaction.buyerSigning?.notaryName || '' : transaction.sellerSigning?.notaryName || ''}
            </Text>
            <Text data-test-id="notary-phone">
              {sideIsBuyer
                ? formatPhoneNumber(transaction.buyerSigning?.notaryPhone || '')
                : formatPhoneNumber(transaction.sellerSigning?.notaryPhone || '')}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
