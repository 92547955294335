import {
  TodoStatus,
  TodoAssignment,
  TransactionRole,
  RecallReason,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TagVariantColor } from '@endpoint/blockparty/dist/components/Tag';

export type ToDoStatusMap = { [index in TodoStatus]: string };
export type TodoStatusRanking = { [index in TodoStatus]: number };
export type ToDoStatusColorMap = { [index in TodoStatus]: TagVariantColor };

export const toDoStatusMapping: ToDoStatusMap = {
  [TodoStatus.DELIVERED]: 'Delivered',
  [TodoStatus.HOLD]: 'Hold',
  [TodoStatus.STARTED]: 'Started',
  [TodoStatus.SUBMITTED]: 'Submitted',
  [TodoStatus.SUBMITTED_BY_OPS]: 'Submitted',
  [TodoStatus.SUBMITTED_BY_PEER]: 'Submitted',
  [TodoStatus.NEVER_COMPLETED]: 'Never Completed',
  [TodoStatus.RECALLED]: 'Recalled',
};

export const todoStatusUpdateSuccessMap = {
  [TodoStatus.RECALLED]: 'To-do recalled',
};

export const toDoStatusColorMap: ToDoStatusColorMap = {
  [TodoStatus.DELIVERED]: 'delivered',
  [TodoStatus.HOLD]: 'hold',
  [TodoStatus.STARTED]: 'started',
  [TodoStatus.SUBMITTED]: 'submitted',
  [TodoStatus.SUBMITTED_BY_OPS]: 'submitted',
  [TodoStatus.SUBMITTED_BY_PEER]: 'submitted',
  [TodoStatus.NEVER_COMPLETED]: 'recalled', // temporary - waiting for design
  [TodoStatus.RECALLED]: 'recalled', // temporary - waiting for design
};

export const toDoStatusRanking: TodoStatusRanking = {
  [TodoStatus.HOLD]: 1,
  [TodoStatus.STARTED]: 2,
  [TodoStatus.DELIVERED]: 3,
  [TodoStatus.SUBMITTED_BY_OPS]: 4,
  [TodoStatus.SUBMITTED]: 5,
  [TodoStatus.SUBMITTED_BY_PEER]: 5,
  [TodoStatus.RECALLED]: 6,
  [TodoStatus.NEVER_COMPLETED]: 7,
};

// OM-287: Remove ability to Reset To-dos
export const updateTodoOptions: TodoStatus[] = [TodoStatus.RECALLED];

export const updateTodoTextMap = {
  [TodoStatus.RECALLED]: 'Recall from customer',
};

export const updateTodoIconMap = {
  [TodoStatus.RECALLED]: 'Delete',
};

export const todoRecallReasonMap = {
  [RecallReason.ADD_TO_SIGNING]: 'Add to signing package',
  [RecallReason.COMPLETED_OUTSIDE_APP]: 'Successfully completed outside of the app',
  [RecallReason.SENT_IN_ERROR]: 'To-do sent in error',
};

type TodoAssignmentOfInterest = Pick<TodoAssignment, 'id' | 'status' | 'recallReason'>;
export interface TodoAssignmentToUpdate extends TodoAssignmentOfInterest {
  todoAssigneeId: string;
  assigneeFirstName?: string;
  assigneeLastName?: string;
  assigneeRoles: TransactionRole[];
}

type TodoStatusFilterType = { [key: string]: TodoStatus[] };

export const todoStatusFilterMap: TodoStatusFilterType = {
  Hold: [TodoStatus.HOLD],
  Delivered: [TodoStatus.DELIVERED],
  Started: [TodoStatus.STARTED],
  Submitted: [TodoStatus.SUBMITTED, TodoStatus.SUBMITTED_BY_PEER, TodoStatus.SUBMITTED_BY_OPS],
  Recalled: [TodoStatus.RECALLED],
};
