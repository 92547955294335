import { AddressBookFieldsOrder, contactTypeToFragmentMap } from 'consts/addressBook';
import { AllContactTypes, ContactFieldData, ContactFragmentTypes } from 'consts/contactDetails';
import { UpdateContactField } from 'consts/updateContact';
import {
  ContactFilterInput,
  ContactSortInput,
  ContactSortProperties,
  ContactType,
  SearchContactByParamsInput,
  SortDirection,
} from '@endpoint/opsware-bff-graphql-schema';
import { useQueryParams } from 'helpers/utils/queryParams';
import { OrganizationType } from 'consts/organizationConsts';
import { StateMap } from 'consts/states';
import { getContactName } from 'helpers/formatText';

import { formatContactTypeLikeTitle } from '../../routes/AddressBook/helpers/formatContactTypeLikeTitle';

// Address Book Contact Details Helpers

export const createAddressBookDetailsFields = (contact: AllContactTypes): ContactFieldData[] => {
  const { contactType } = contact;
  const contactFragmentType = contactType ? contactTypeToFragmentMap[contactType] : ContactFragmentTypes.CONTACT;
  let fields: ContactFieldData[] = [...getDataFieldContactType(contact)];
  const fieldsInOrder: ContactFieldData[] = [];

  switch (contactFragmentType) {
    case ContactFragmentTypes.AGENT_CONTACT: {
      fields = [...fields, ...getDataFieldsAgentContact(contact)];
      break;
    }

    case ContactFragmentTypes.INDIVIDUAL_CONTACT: {
      fields = [...fields, ...getDataFieldsIndividualContact(contact)];
      break;
    }

    case ContactFragmentTypes.LENDER_CONTACT: {
      fields = [...fields, ...getDataFieldsLenderContact(contact)];
      break;
    }

    case ContactFragmentTypes.ENTITY_CONTACT: {
      fields = [...fields, ...getDataFieldsEntityContact(contact)];
      break;
    }

    default: {
      break;
    }
  }

  AddressBookFieldsOrder.forEach((label) => {
    const field = fields.find((basicField) => basicField.label === label);

    if (field) {
      fieldsInOrder.push(field);
    }
  });

  return fieldsInOrder;
};

function getDataFieldContactType(contact: AllContactTypes): ContactFieldData[] {
  const { contactType } = contact;

  return [
    {
      label: 'Default Contact Type',
      value: formatContactTypeLikeTitle(contactType),
      isEditable: false,
      fieldName: UpdateContactField.CONTACT_TYPE,
    },
  ];
}

export function getDataFieldsAgentContact(contact: AllContactTypes): ContactFieldData[] {
  const { licenseNumber, stateOfOperation, mlsId } = contact;
  const state = stateOfOperation ? StateMap[stateOfOperation.toUpperCase()] : stateOfOperation;
  const name = getContactName({ contact });

  return [
    {
      label: 'Name',
      value: name,
      isEditable: true,
      fieldName: UpdateContactField.FIRST_NAME,
    },
    {
      label: 'License Number',
      value: licenseNumber,
      isEditable: true,
      fieldName: UpdateContactField.LICENSE_NUMBER,
    },
    {
      label: 'State of Operation',
      value: state || '',
      isEditable: true,
      fieldName: UpdateContactField.STATE_OF_OPERATION,
    },
    {
      label: 'MLS ID',
      value: mlsId,
      isEditable: true,
      fieldName: UpdateContactField.MLS_ID,
    },
  ];
}

export function getDataFieldsLenderContact(contact: AllContactTypes): ContactFieldData[] {
  const { nmlsId } = contact;
  const name = getContactName({ contact });

  return [
    {
      label: 'Name',
      value: name,
      isEditable: true,
      fieldName: UpdateContactField.FIRST_NAME,
    },
    {
      label: 'NMLS ID',
      value: nmlsId,
      isEditable: true,
      fieldName: UpdateContactField.NMLS_ID,
    },
  ];
}

export function getDataFieldsIndividualContact(contact: AllContactTypes): ContactFieldData[] {
  const name = getContactName({ contact, partial: false });

  return [
    {
      label: 'Name',
      value: name,
      isEditable: true,
      fieldName: UpdateContactField.FIRST_NAME,
    },
  ];
}

export function getDataFieldsEntityContact(contact: AllContactTypes): ContactFieldData[] {
  const name = getContactName({ contact });

  return [
    {
      label: 'Name',
      value: name,
      isEditable: contact.contactType !== ContactType.SETTLEMENT_COMPANY,
      fieldName: UpdateContactField.ENTITY_NAME,
    },
  ];
}

// Address Book Table Sort and Filter Helpers
export interface AddressBookFilters {
  sort: [ContactSortProperties, SortDirection] | [];
  search: string[] | string;
  contactType: ContactType;
  organizationIds: OrganizationType[];
  itemsPerPage: string;
  pageIndex: string;
}

export function useAddressBookFilters() {
  return useQueryParams((params: Partial<AddressBookFilters>) => ({
    sort: params.sort ?? [],
    search: Array.isArray(params.search)
      ? params.search.map((str: string) => str.trim()).join(', ')
      : params.search ?? '',
    contactType: params.contactType,
    organizationIds: [params.organizationIds ?? []].flat(1).map(Number),
    itemsPerPage: params.itemsPerPage ?? '50',
    pageIndex: params.pageIndex ?? '1',
  }));
}

export function createSearchContactsByParamsInput(filters: AddressBookFilters): SearchContactByParamsInput {
  const { search, sort, contactType, organizationIds, itemsPerPage, pageIndex } = filters;
  const input = {} as SearchContactByParamsInput;

  if (search) {
    const searchTerm = (Array.isArray(search) ? search[0] : search) as string;

    input.term = searchTerm.replace(/,\s*/g, ' ');
  }

  if (itemsPerPage) {
    input.itemsPerPage = parseInt(itemsPerPage, 10);
  }

  if (pageIndex) {
    input.pageIndex = parseInt(pageIndex, 10) - 1;
  }

  if (sort && sort.length) {
    const sortInput = {} as ContactSortInput;

    sortInput.propertyName = sort[0];
    sortInput.direction = sort[1];
    input.sort = sortInput;
  }

  if (contactType || organizationIds.length) {
    const filter: ContactFilterInput = {};

    if (contactType) filter.contactType = contactType;
    if (organizationIds.length) filter.organizationIds = organizationIds.sort();

    input.filter = filter;
  }

  return input;
}
