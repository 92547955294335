import React, { FC, ReactNode, useContext } from 'react';
import { AccordionSection } from 'components/AccordionSection';
import { Box, Divider, Text } from '@endpoint/blockparty';
import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TransactionContext, TransactionContextType } from 'routes/Transaction';

import { DeliveredToItem } from './DeliveredToItem';
import { AddCustomerPopover } from './AddCustomer';
import { DocumentDetailsProps } from '..';

const ParticipantListItems = (
  participants: TransactionParticipant[],
  documentId: string,
  documentName: string,
  transactionIdentifier: string | undefined,
  documentType?: string,
) => {
  return participants.map(
    (participant: TransactionParticipant): ReactNode => (
      <DeliveredToItem
        key={`user-delivered-to-${participant.id}`}
        documentId={documentId}
        documentName={documentName}
        documentType={documentType}
        participant={participant}
        transactionIdentifier={transactionIdentifier || ''}
      />
    ),
  );
};

export const DocumentDeliveredToSection: FC<DocumentDetailsProps> = ({ document, transactionIdentifier }) => {
  const value: TransactionContextType = useContext(TransactionContext);

  return (
    <AccordionSection dataTestId="document-delivered-to" hasDivider isOpen pt="space0" px="space0" title="Customers">
      <Box>
        {document?.participants && document?.participants.length > 0 && (
          <Box data-test-id="documents-participants-row" mb="space40">
            {ParticipantListItems(
              document.participants,
              document.id,
              document.name,
              transactionIdentifier,
              document.type,
            )}
          </Box>
        )}
        <Box pl="space60">
          <AddCustomerPopover
            documentId={document?.id || ''}
            documentName={document?.name || ''}
            documentUsers={document?.participants || []}
            transactionIdentifier={transactionIdentifier || ''}
            transactionParticipants={value.participants}
          />
        </Box>
        <Box marginX="space60" pt="space60">
          <Divider />
          <Text as="p" color="passive" pt="space40" size="fontSize10">
            Attached Note
          </Text>
          <Text as="p" data-testid="document-eo-note" pt="space20">
            {document?.escrowNote || '-'}
          </Text>
        </Box>
      </Box>
    </AccordionSection>
  );
};

DocumentDeliveredToSection.displayName = 'DocumentDeliveredToSection';
