import React, { FC, useState } from 'react';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Text,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  useDropdownMenuState,
  useToast,
} from '@endpoint/blockparty';
import {
  ContactType,
  Report1099Input,
  TransactionParticipant,
  TransactionRole,
  TransactionStatus,
  Void1099Input,
} from '@endpoint/opsware-bff-graphql-schema';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMutation, useQuery } from '@apollo/client';
import { GET_TRANSACTION_INFO, GetTransactionType } from 'routes/Transaction/TransactionBasicInfo/queries';
import { useHasWriteAccess } from 'hooks/useHasWriteAccess';

import { RemoveRolesModal } from './RemoveRolesModal';
import { RemoveTransactionParticipantModal } from './RemoveTransactionParticipantModal';
import { RERUN_1099_REPORT, UPDATE_TRANSACTION_PARTICIPANT, VOID_1099_REPORT } from './queries';
import { UpdateDirectingContactModal } from './UpdateDirectingContactModal';

interface ContactActionMenuProps {
  transactionParticipant: TransactionParticipant;
}

export const valid1099ContactTypes = [
  ContactType.TRANSACTION_COORDINATOR,
  ContactType.TRANSACTEE_INDIVIDUAL,
  ContactType.AGENT,
  ContactType.TRANSACTEE_TRUST,
];

export const ContactActionMenu: FC<ContactActionMenuProps> = ({ transactionParticipant }) => {
  const { canSetTransactionParticipantAsDirectingAgent } = useFlags();
  const [isRemoveRoleModalOpen, setRemoveRoleModalOpen] = useState<boolean>(false);
  const [isRemoveFromTransactionModalOpen, setRemoveFromTransactionModalOpen] = useState<boolean>(false);
  const [isUpdateDirectingContactModalOpen, setIsUpdateDirectingContactModalOpen] = useState<boolean>(false);
  const menu = useDropdownMenuState({ placement: 'bottom-end' });
  const toast = useToast();

  const hasWriteAccess = useHasWriteAccess();

  const { data } = useQuery<GetTransactionType>(GET_TRANSACTION_INFO, {
    variables: {
      where: {
        fileNum: transactionParticipant.transaction.fileNum,
      },
    },
  });

  const [runReport] = useMutation<Promise<boolean>>(RERUN_1099_REPORT, {
    onCompleted: () => {
      toast({
        description: '1099 report successfully reran',
        duration: 5000,
        icon: 'CheckCircle',
      });
    },
    onError: () => {
      toast({
        description: 'Action was not successful',
        duration: 5000,
        iconColor: 'watermelon500',
        icon: 'ErrorCircle',
      });
    },
  });
  const [voidReport] = useMutation<Promise<boolean>>(VOID_1099_REPORT, {
    onCompleted: () => {
      toast({
        description: '1099 report successfully voided',
        duration: 5000,
        icon: 'CheckCircle',
      });
    },
    onError: () => {
      toast({
        description: 'Action was not successful',
        duration: 5000,
        iconColor: 'watermelon500',
        icon: 'ErrorCircle',
      });
    },
  });
  const [updateDirectingContact, { loading: isUpdatingDirectingContact }] = useMutation(
    UPDATE_TRANSACTION_PARTICIPANT,
    {
      variables: {
        where: {
          id: transactionParticipant.id,
        },
        input: {
          isDirectingContact: true,
        },
      },
      onCompleted: () => {
        toast({
          description: 'Contact marked as directing',
          duration: 5000,
          icon: 'CheckCircle',
        });

        setIsUpdateDirectingContactModalOpen(false);
      },
      onError: () => {
        toast({
          description: 'Unable to update directing contact',
          duration: 5000,
          iconColor: 'watermelon500',
          icon: 'ErrorCircle',
        });
      },
      refetchQueries: [
        {
          query: GET_TRANSACTION_INFO,
          variables: {
            where: {
              fileNum: transactionParticipant.transaction.fileNum,
            },
          },
        },
      ],
    },
  );

  const handleToggleRemoveFromTransactionModal = () => {
    setRemoveFromTransactionModalOpen(!isRemoveFromTransactionModalOpen);
  };

  const handleToggleRemoveRolesModal = () => {
    setRemoveRoleModalOpen(!isRemoveRoleModalOpen);
  };

  const handleIsRemoveFromTransactionModalOpen = () => {
    handleToggleRemoveFromTransactionModal();
    trackAnalytics('Remove Contact Clicked', {});
  };

  const handleIsRemoveRolesModalOpen = () => {
    handleToggleRemoveRolesModal();
    trackAnalytics('Remove Role Clicked', {});
  };

  const toggleDirectingContactModal = () => {
    setIsUpdateDirectingContactModalOpen(!isUpdateDirectingContactModalOpen);
  };

  const handleRerun1099Report = () => {
    const report1099Input: Report1099Input = {
      fileNumber: transactionParticipant.transaction.fileNum,
      organizationId: transactionParticipant.transaction.organizationId?.toString() || '',
      participants: [
        {
          participantId: transactionParticipant.id || '',
          organizationId: transactionParticipant.transaction.organizationId?.toString() || '',
          contactId: transactionParticipant.contact.id || '',
          fileNumber: transactionParticipant.transaction.fileNum,
          email: transactionParticipant.contact.email,
        },
      ],
    };

    void runReport({
      variables: {
        where: report1099Input,
      },
    });

    trackAnalytics('Rerun 1099 Clicked', {});
  };

  const handleVoid1099Report = () => {
    const void1099Input: Void1099Input = {
      participant: {
        participantId: transactionParticipant.id || '',
        organizationId: transactionParticipant.transaction.organizationId?.toString() || '',
        contactId: transactionParticipant.contact.id || '',
        fileNumber: transactionParticipant.transaction.fileNum,
      },
    };

    void voidReport({
      variables: {
        where: void1099Input,
      },
    });

    trackAnalytics('Void 1099 Clicked', {});
  };

  const handleUpdateDirectingContact = async () => {
    const participants = data?.transaction.participants;
    const alreadyExistingDirectingContact = !!participants?.find((participant) => participant.isDirectingContact);

    if (alreadyExistingDirectingContact) {
      setIsUpdateDirectingContactModalOpen(true);
    } else {
      await updateDirectingContact();
    }

    trackAnalytics('Directing Contact Clicked', {});
  };

  const { transaction, contact, roles, isDirectingContact } = transactionParticipant;
  const transactionIdentifier = transaction.fileNum ? transaction.fileNum : `id-${transaction.id}`;

  const closingDateYear = new Date(data?.transaction?.dates?.estimatedClosing).getFullYear();

  const { contactType } = contact;

  const isSellerType = roles.includes(TransactionRole.SELLER);
  const isValidContactType = valid1099ContactTypes.includes(contactType as ContactType);
  const closedLast3Years = new Date().getFullYear() - closingDateYear <= 3;
  const isTransactionClosed = transaction.status === TransactionStatus.CLOSED;
  const isTransactionReal = !data?.transaction?.isTest;
  const show1099Options =
    isTransactionClosed && closedLast3Years && isSellerType && isValidContactType && isTransactionReal;
  const canUpdateDirectingContact = canSetTransactionParticipantAsDirectingAgent && !isDirectingContact;

  return (
    <>
      <DropdownMenuTrigger state={menu}>
        <IconButton
          aria-label="Open Contact Action Menu"
          color="carbon500"
          data-test-id="contactActionMenuBtn"
          label="Open Contact Action Menu"
          name="MoreHorizontal"
          size="small"
          variant="none"
          variantColor="carbon"
        />
      </DropdownMenuTrigger>
      <DropdownMenu
        aria-label="Contact Action Menu"
        data-test-id="contactActionMenu"
        state={menu}
        style={{ offset: 5, paddingBottom: 8, paddingTop: 8, width: 292 }}
      >
        <DropdownMenuItem
          data-test-id="remove-from-transaction-icon"
          disabled={!hasWriteAccess}
          style={{ borderRadius: 0, paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 8 }}
          onClick={handleIsRemoveFromTransactionModalOpen}
        >
          <Flex alignItems="center">
            <Box>
              <Icon color="carbon500" mr="space30" name="Delete" size="large" />
            </Box>
            <Text as="p" color="carbon900" size="fontSize20">
              Remove from transaction
            </Text>
          </Flex>
        </DropdownMenuItem>
        <DropdownMenuItem
          borderRadius="radiusNone"
          data-test-id="remove-role-icon"
          disabled={!hasWriteAccess}
          px="space50"
          py="space30"
          onClick={handleIsRemoveRolesModalOpen}
        >
          <Flex alignItems="center">
            <Box>
              <Icon color="carbon500" mr="space30" name="AccountCircle" size="large" />
            </Box>
            <Text as="p" color="carbon900" size="fontSize20">
              Remove a role
            </Text>
          </Flex>
        </DropdownMenuItem>

        {canUpdateDirectingContact && (
          <DropdownMenuItem
            borderRadius="radiusNone"
            data-test-id="directing-contact-icon"
            px="space50"
            py="space30"
            onClick={handleUpdateDirectingContact}
          >
            <Flex alignItems="center">
              <Box>
                <Icon color="carbon500" mr="space30" name="Star" size="large" />
              </Box>
              <Text as="p" color="carbon900" size="fontSize20">
                Make directing contact
              </Text>
            </Flex>
          </DropdownMenuItem>
        )}

        {show1099Options && (
          <>
            <DropdownMenuItem borderRadius="radiusNone" px="space50" py="space30" onClick={handleRerun1099Report}>
              <Flex alignItems="center">
                <Box>
                  <Icon color="carbon500" mr="space30" name="Paper" size="large" />
                </Box>
                <Text as="p" color="carbon900" size="fontSize20">
                  Rerun 1099 report
                </Text>
              </Flex>
            </DropdownMenuItem>
            <DropdownMenuItem borderRadius="radiusNone" px="space50" py="space30" onClick={handleVoid1099Report}>
              <Flex alignItems="center">
                <Box>
                  <Icon color="carbon500" mr="space30" name="Void" size="large" />
                </Box>
                <Text as="p" color="carbon900" size="fontSize20">
                  Void 1099 report
                </Text>
              </Flex>
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenu>

      <RemoveTransactionParticipantModal
        isRemoveTransactionParticipantModalOpen={isRemoveFromTransactionModalOpen}
        participantId={transactionParticipant.id}
        roles={roles}
        toggleModal={handleToggleRemoveFromTransactionModal}
        transactionIdInput={transactionIdentifier}
      />
      <RemoveRolesModal
        isRemoveRolesModalOpen={isRemoveRoleModalOpen}
        participantId={transactionParticipant.id}
        roles={roles}
        toggleModal={handleToggleRemoveRolesModal}
        transactionIdInput={transactionIdentifier}
      />
      <UpdateDirectingContactModal
        isLoading={isUpdatingDirectingContact}
        isOpen={isUpdateDirectingContactModalOpen}
        onClose={toggleDirectingContactModal}
        onConfirm={updateDirectingContact}
      />
    </>
  );
};
