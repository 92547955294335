import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { LayoutContent, LayoutSubContent } from '@endpoint/blockparty';
import { Layout } from 'components/Layout';
import { trackPage } from 'helpers/utils/segment/segmentAnalytics';
import { Page } from 'consts/segmentProtocols';

import { OpenOrderListContent } from './OpenOrderListContent';

interface OpenOrderListProps extends RouteComponentProps {}

export const OpenOrderList: FC<OpenOrderListProps> = () => {
  useEffect(() => {
    trackPage<Page>('Open Order List');
  }, []);

  return (
    <Layout>
      <LayoutContent>
        <LayoutSubContent>
          <OpenOrderListContent />
        </LayoutSubContent>
      </LayoutContent>
    </Layout>
  );
};
