import { SelectStyleProps } from '@endpoint/blockparty/dist/components/Select/styles';
import { theme, selectorStyles } from '@endpoint/blockparty';

export const getAsyncSelectStyles = (hasError: boolean) => ({
  control: (base: SelectStyleProps['styles'], state: SelectStyleProps['state']) => {
    const defaultFocusBorder = state.isFocused ? theme.colors.inputActive : theme.colors.inputHover;
    const errorColor = theme.colors.inputAlert;

    return {
      ...base,
      ...selectorStyles.control(base, state),
      borderColor: hasError ? errorColor : theme.colors.carbon300,
      '&:focus-within': {
        boxShadow: hasError ? `0 0 0 3px ${theme.colors.watermelon200}` : '',
        borderColor: hasError ? errorColor : theme.colors.inputActiveFocus,
      },
      '&:hover': {
        borderColor: `${hasError ? errorColor : defaultFocusBorder}`,
      },
    };
  },
});
