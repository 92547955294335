import React, { FC } from 'react';
import { Button, DrawerActionBar, DrawerCloseButton, DrawerFooter, Flex } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';
import { useDocumentReviewContext } from 'routes/DocumentReview/contexts/DocumentReviewContext';
import { navigate } from '@reach/router';

import { EscrowConversionPsaUpload } from './EscrowConversionPsaUpload';

interface EscrowConversionDrawerContentProps {
  setDrawerIsOpen: (value: boolean) => void;
  title?: string;
}

export const EscrowConversionDrawerContent: FC<EscrowConversionDrawerContentProps> = ({
  setDrawerIsOpen,
  title = 'Open Escrow',
}) => {
  const { values, handleReset, isSubmitting } = useFormikContext<OpenOrderDrawerForm>();
  const { documentId } = useDocumentReviewContext();
  const { clearUploads } = useUploadDocument();

  return (
    <>
      <DrawerActionBar>
        <Flex flexDirection="row-reverse" justifyContent="space-between" width="100%">
          <DrawerCloseButton
            color="carbon"
            data-test-id="open-new-transaction-drawer-x-button"
            label="Close open new transaction drawer"
            onClick={() => {
              handleReset();
              clearUploads();
              setDrawerIsOpen(false);
            }}
          />
        </Flex>
      </DrawerActionBar>

      <Flex flex={1} flexDirection="column" overflow="auto">
        <EscrowConversionPsaUpload title={title} />
      </Flex>
      <DrawerFooter>
        <Button
          data-test-id="open-new-transaction-drawer-close-button"
          mr="space30"
          variant="outline"
          onClick={() => {
            clearUploads();
            handleReset();
            setDrawerIsOpen(false);
          }}
        >
          Close
        </Button>
        <Button
          data-test-id="open-order-drawer-next-button"
          iconRight="ArrowChevronRight"
          isDisabled={isSubmitting || !documentId}
          isLoading={isSubmitting}
          onClick={async () => {
            await navigate(`/document-review?transactionId=${values.transactionId}`);
          }}
        >
          Next
        </Button>
      </DrawerFooter>
    </>
  );
};
