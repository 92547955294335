import React, { FC, useContext } from 'react';
import { Flex, Text, Box, IconButton, Divider, Icon } from '@endpoint/blockparty';
import { navigate } from '@reach/router';
import { Document } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { DocumentsContext } from 'routes/Transaction/Documents';
import { ErrorMessage } from 'components/ErrorMessage';

interface RightPanelHeaderProps {
  documentId: string;
  transactionIdentifier: string;
}

export const DocumentRightPanelHeader: FC<RightPanelHeaderProps> = ({ documentId, transactionIdentifier }) => {
  const { documents } = useContext(DocumentsContext);
  const documentIds = documents?.map((document: Document) => document.id);
  const documentPosition: number = documentIds?.indexOf(documentId) || 0;

  const navigateToDocument = (documentIndex: number): void => {
    if (documentIds) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(`/transaction/${transactionIdentifier}/documents/${documentIds[documentIndex % documentIds.length]}`);
    }
  };

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent={documents && documents?.length === 1 ? 'flex-end' : null}
        px="space60"
        py="space50"
      >
        {!documents && <ErrorMessage />}
        {documents && documentIds && documentIds.length > 1 && (
          <Flex alignItems="center" flexGrow={1}>
            <IconButton
              color="carbon500"
              data-test-id="navigation-left"
              isDisabled={documentPosition === 0}
              label="back"
              name="ArrowChevronLeft"
              variant="unstyled"
              variantColor="carbon"
              onClick={(): void => {
                navigateToDocument(documentPosition - 1);
              }}
            />
            <IconButton
              color="carbon500"
              data-test-id="navigation-right"
              isDisabled={documentPosition === documents.length - 1}
              label="forward"
              ml="space50"
              name="ArrowChevronRight"
              variant="unstyled"
              variantColor="carbon"
              onClick={(): void => {
                navigateToDocument(documentPosition + 1);
              }}
            />
            <Text color="carbon500" data-test-id="navigation-location" ml="space50">
              {documentIds && `${documentPosition + 1} of ${documentIds.length}`}
            </Text>
          </Flex>
        )}
        <IconButton
          color="carbon500"
          label="close"
          name="Close"
          variant="unstyled"
          variantColor="carbon"
          onClick={(): void => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate(`/transaction/${transactionIdentifier}/documents`);
          }}
        />
      </Flex>
      <Divider />
    </Box>
  );
};

DocumentRightPanelHeader.displayName = 'DocumentRightPanelHeader';
Icon.displayName = 'Icon';
