import React, { ChangeEvent, FC, useMemo } from 'react';
import { InputGroup, Flex, InputLabel, Input, Box, Select, InputErrorMessage } from '@endpoint/blockparty';
import { Field, useFormikContext } from 'formik';
import { useStateOptions } from 'hooks/useStateOptions';
import { OrganizationType } from 'consts/organizationConsts';
import { useEditTransactionBasicInfoContext } from 'hooks/useEditTransactionBasicInfoContext';

import { EditTransactionBasicInfoDrawer } from '../types/EditTransactionBasicInfoDrawer';
import { BasicInfoPropertyProps } from '.';

export const PropertyEditForm: FC<BasicInfoPropertyProps> = ({ transaction }) => {
  const { values, setFieldValue, errors } = useFormikContext<EditTransactionBasicInfoDrawer>();
  const { focusField } = useEditTransactionBasicInfoContext();
  const STATE_OPTIONS = useStateOptions(transaction.organizationId as OrganizationType);

  const isStateSelectDisabled: boolean = !!values?.state;

  return (
    <Flex data-test-id="transaction-basic-info-edit-form" flexDirection="row" mb="space50" ml="space50" mt="space30">
      <Flex flexDirection="column" mr="space50" width="50%">
        <InputGroup groupId="address" isInvalid={!!errors?.address}>
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel color={errors.address ? 'watermelon500' : 'carbon600'} htmlFor="address">
              Address *
            </InputLabel>
          </Flex>
          <Field
            as={Input}
            autoComplete="none"
            autoFocus={focusField === 'address'}
            name="address"
            value={values.address}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFieldValue('address', event.target.value);
            }}
          />
          {errors.address && <InputErrorMessage>{errors.address}</InputErrorMessage>}
        </InputGroup>

        <InputGroup groupId="address2 " pt="space50">
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel htmlFor="address2">Apt, unit, etc.</InputLabel>
          </Flex>
          <Field
            as={Input}
            autoComplete="none"
            data-test-id="address2"
            name="address2"
            value={values.address2}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFieldValue('address2', event.target.value);
            }}
          />
        </InputGroup>

        <InputGroup groupId="city" isInvalid={!!errors?.city} pt="space50">
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel color={errors.city ? 'watermelon500' : 'carbon600'}>City *</InputLabel>
          </Flex>
          <Field
            as={Input}
            autoComplete="none"
            name="city"
            value={values.city}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFieldValue('city', event.target.value);
            }}
          />
          {errors.city && <InputErrorMessage>{errors.city}</InputErrorMessage>}
        </InputGroup>

        <InputGroup
          data-test-id="open-order-drawer-state-selection"
          flex={1}
          groupId="state"
          isInvalid={!!errors.state}
          pt="space50"
        >
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel htmlFor="state">State *</InputLabel>
          </Flex>
          <Field
            as={Select}
            inputId="state"
            isDisabled={isStateSelectDisabled}
            name="state"
            options={STATE_OPTIONS}
            value={STATE_OPTIONS.find((option) => option.value === values.state)}
            onChange={(state: { value: '' }) => {
              setFieldValue('state', state.value);
            }}
          />
          {errors?.state && <InputErrorMessage>{errors?.state}</InputErrorMessage>}
        </InputGroup>

        <Box width={24} />
        <InputGroup flex={1} groupId="zip" isInvalid={!!errors.zip} marginRight="5px" pt="space50">
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel color={errors.zip ? 'watermelon500' : 'carbon600'} htmlFor="zip">
              Zip Code *
            </InputLabel>
          </Flex>
          <Field
            as={Input}
            autoComplete="none"
            name="zip"
            value={values.zip}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFieldValue('zip', event.target.value);
            }}
          />
          {errors?.zip && <InputErrorMessage>{errors?.zip}</InputErrorMessage>}
        </InputGroup>

        <InputGroup groupId="county" isInvalid={!!errors.county} pt="space50">
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel color={errors.county ? 'watermelon500' : 'carbon600'} htmlFor="county">
              County *
            </InputLabel>
          </Flex>
          <Field
            as={Input}
            autoComplete="none"
            name="county"
            value={values.county}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFieldValue('county', event.target.value);
            }}
          />
          {errors?.county && <InputErrorMessage>{errors?.county}</InputErrorMessage>}
        </InputGroup>
      </Flex>
      <Flex flexDirection="column" width="50%">
        <InputGroup groupId="parcelNumber" pb="space60">
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel htmlFor="parcelNumber">Parcel Number</InputLabel>
          </Flex>
          <Field
            as={Input}
            autoComplete="none"
            autoFocus={focusField === 'parcelNumber'}
            name="parcelNumber"
            value={values.parcelNumber}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFieldValue('parcelNumber', event.target.value);
            }}
          />
        </InputGroup>
      </Flex>
    </Flex>
  );
};
