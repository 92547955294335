import { gql } from '@apollo/client';
import { TodoAssignment } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const SET_TODO_STATUS = gql`
  mutation UpdateTodoAssignment($data: TodoAssignmentUpdateInput!, $where: TodoAssignmentWhereUniqueInput!) {
    updateTodoAssignment(data: $data, where: $where) {
      id
      status
      statusOptions
      recallReason
    }
  }
`;

export interface UpdateTodoAssignment {
  updateTodoAssignment: TodoAssignment;
}
