import { OrganizationType } from 'consts/organizationConsts';
import { sortBy, uniqBy } from 'lodash';
import { Market } from '@endpoint/opsware-bff-graphql-schema';
import useMarketsAndOrganizations from 'hooks/useMarketsAndOrganizations';
import { useMemo } from 'react';

export const useStateOptions = (organizationId: OrganizationType) => {
  const { markets } = useMarketsAndOrganizations();

  return useMemo(() => {
    const marketsFilterByOrganization: Market[] = uniqBy(
      markets?.filter((market) => market.organizationId === organizationId) || [],
      'state',
    );
    const mappedMarkets =
      marketsFilterByOrganization?.map((market) => {
        // Adding California option if exist in the response, due to this query repeats 'CA' state using different names: 'SoCal & NorCal'.

        if (market.state === 'CA') {
          return { value: 'CA', label: 'California' };
        }

        return { value: market.state, label: market.name };
      }) || [];

    return sortBy(mappedMarkets, ['label']);
  }, [markets, organizationId]);
};
