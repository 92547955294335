import React, { FC, useState } from 'react';
import { useField } from 'formik';
import { DatePicker, InputGroup, InputLabel } from '@endpoint/blockparty';
import { InputProps } from 'components/TextArea';

interface DatePickerProps extends InputProps {
  startDate: Date;
  onDataChange: (fieldName: string, data: Date) => void;
}

export const DatePickerField: FC<DatePickerProps> = ({ label, name, placeholder, startDate, onDataChange }) => {
  const [startDateInitial, setStartDate] = useState(startDate);
  const [field, meta, helpers] = useField({ name, placeholder });
  const { setValue } = helpers;

  return (
    <InputGroup>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <DatePicker
        dateFormat="eee, MMM dd"
        id={name}
        placeholderText={placeholder}
        selected={startDateInitial}
        {...meta}
        {...field}
        onChange={(date: Date): void => {
          setStartDate(date);
          setValue(date);
          onDataChange(name, date);
        }}
      />
    </InputGroup>
  );
};
