import React, { FC } from 'react';
import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { Box } from '@endpoint/blockparty';
import { useEditContactDetailsProvider } from 'hooks/useEditContactDetailsProvider';

import { Details } from './Details';
import { ContactInformation } from './ContactInformation';
import { SystemInformation } from './SystemInformation';
import { EditContactDetails } from '../../EditContactDetails/EditContactDetailsForm';

interface DetailsTabProps {
  participant: TransactionParticipant;
}

export const DetailsTab: FC<DetailsTabProps> = ({ participant }): React.ReactElement => {
  const { state } = useEditContactDetailsProvider();

  return (
    <Box data-test-id="contact-details-tab">
      {state.isEditing ? (
        <EditContactDetails participant={participant} />
      ) : (
        <>
          <Details participant={participant} />
          <ContactInformation participant={participant} />
          <SystemInformation participant={participant} />
        </>
      )}
    </Box>
  );
};
