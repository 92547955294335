import {
  Box,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Text,
} from '@endpoint/blockparty';
import { Market } from '@endpoint/opsware-bff-graphql-schema';
import { formatLikeTitle } from 'helpers/formatText';
import { getColumnSortIcon } from 'helpers/table';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useMemo } from 'react';
import { CellProps, Column, useFlexLayout, useSortBy, useTable, UseTableCellProps } from 'react-table';

import { SwitchButton } from '../TableComponents/SwitchButton';

export interface MarketsRowDataTypes {
  name: string;
  organizationLegalName: string;
  fundingType: string;
  acceptsPrelistings: boolean;
  active: boolean;
}
export interface MarketsTableProps {
  markets: Market[];
}

export type TableColumnCellProps<T extends object> = Pick<UseTableCellProps<T>, 'column'>;

export type TableColumnHeaderCellProps = Pick<CellProps<GenericObject>, 'cell'>;

export const columnHeaders = (): Column<MarketsRowDataTypes>[] => [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Organization',
    accessor: 'organizationLegalName',
  },
  {
    Header: 'Funding Type',
    accessor: 'fundingType',
    disableSortBy: true,
    Cell: ({ cell }: TableColumnHeaderCellProps): React.ReactElement => <Text>{formatLikeTitle(cell.value)}</Text>,
  },
  {
    Header: 'Accepts Prelisting',
    accessor: 'acceptsPrelistings',
    disableSortBy: true,
    Cell: ({ cell }: TableColumnHeaderCellProps): React.ReactElement => <Text>{cell.value ? 'Yes' : 'No'}</Text>,
  },
  {
    Header: 'Status',
    accessor: 'active',
    disableSortBy: true,
    Cell: ({ cell }: TableColumnHeaderCellProps): React.ReactElement => {
      const { canAdminActivateMarkets } = useFlags();

      if (!canAdminActivateMarkets) {
        return <Text data-test-id="active-status-text">{cell.value ? 'Activated' : 'Inactive'}</Text>;
      }

      return <SwitchButton market={cell.row.original as Market} />;
    },
  },
];

const MarketsTable: React.FC<MarketsTableProps> = ({ markets }) => {
  const columns = useMemo(columnHeaders, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: markets,
    },
    useFlexLayout,
    useSortBy,
  );

  return (
    <Box>
      <Table data-test-id="markets-table" label="Markets Table" {...getTableProps()}>
        <TableHeader zIndex={0}>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();

            return (
              <TableHeaderRow key={key} {...headerGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key: tableHeaderKey, ...tableHeaderProps } = column.getHeaderProps(
                    column.getSortByToggleProps(),
                  );

                  return (
                    <TableHeaderCell key={tableHeaderKey} justifyContent="space-between" {...tableHeaderProps}>
                      {column.render('Header')}
                      {column.canSort && (
                        <Icon
                          color="carbon200"
                          data-test-id={`${getColumnSortIcon(column.isSorted, column.isSortedDesc)}_icon`}
                          name={getColumnSortIcon(column.isSorted, column.isSortedDesc)}
                          size="large"
                        />
                      )}
                    </TableHeaderCell>
                  );
                })}
              </TableHeaderRow>
            );
          })}
        </TableHeader>
        <TableBody maxHeight={rows.length ? 'calc(100vh - 315px)' : '40px'} overflow="overlay" {...getTableBodyProps()}>
          {rows.length === 0 ? (
            <TableCell py="space30">No matching records found. Try adjusting your search.</TableCell>
          ) : (
            rows.map((row) => {
              prepareRow(row);
              const { key: rowKey, ...tableRowProps } = row.getRowProps();

              return (
                <TableRow key={rowKey} {...tableRowProps}>
                  {row.cells.map((cell, i) => {
                    const { key: cellsRowKey, ...tableCellProps } = cell.getCellProps();

                    return (
                      <TableCell key={cellsRowKey} py="space30" wordBreak="break-word" {...tableCellProps}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MarketsTable;
