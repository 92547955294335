import React, { FC } from 'react';
import { UploadDocumentError } from 'components/UploadDocumentProgress/UploadDocumentError';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { UploadDocumentProgress } from 'components/UploadDocumentProgress/UploadDocumentProgress';
import { DocumentUpload, DocumentUploadStatus } from 'consts/uploadDocumentsConsts';

interface FileUploadProgressProps {
  document: DocumentUpload;
  isRemovable?: boolean;
}

export const FileUploadProgress: FC<FileUploadProgressProps> = ({ document, isRemovable }) => {
  const { cancelUpload, clearUpload } = useUploadDocument();

  if (document.status === DocumentUploadStatus.ERROR) {
    return <UploadDocumentError document={document} onRemoveDocument={() => clearUpload(document.id)} />;
  }

  return (
    <UploadDocumentProgress
      document={document}
      isRemovable={isRemovable || false}
      onCancelUpload={() => cancelUpload(document.id)}
      onRemoveDocument={() => clearUpload(document.id)}
    />
  );
};
