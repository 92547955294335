import React, { FC, useState } from 'react';
import { Box, Button, Flex, Text } from '@endpoint/blockparty';
import { UpdateContactInput } from '@endpoint/opsware-bff-graphql-schema';
import { useFormikContext } from 'formik';
import { EditContactDetailsAction, useEditContactDetailsProvider } from 'hooks/useEditContactDetailsProvider';

import { DiscardUnsavedChangesModal } from '../DiscardUnsavedChangesModal';
import { UpdateAllOpenOrdersModal } from '../UpdateAllOpenOrdersModal';

interface EditContactDetailsActionBarProps {
  closeUpdateAllOpenOrdersModal: () => void;
  isLoadingUpdateAllOpenOrders: boolean;
  isUpdateAllOpenOrdersModalOpen: boolean;
  onSaveAllOpenOrders: (updateOption: string) => void;
}

export const EditContactDetailsActionBar: FC<EditContactDetailsActionBarProps> = ({
  closeUpdateAllOpenOrdersModal,
  isLoadingUpdateAllOpenOrders = false,
  isUpdateAllOpenOrdersModalOpen = false,
  onSaveAllOpenOrders,
}) => {
  const { state, dispatch } = useEditContactDetailsProvider();
  const { dirty, handleReset, handleSubmit, isSubmitting, isValid } = useFormikContext<UpdateContactInput>();
  const [discardChangesModalIsOpen, setDiscardChangesModalIsOpen] = useState<boolean>(false);

  const toggleIsEditing = () => {
    dispatch({
      type: EditContactDetailsAction.SET_IS_EDITING,
      payload: { ...state, isEditing: false },
    });
  };

  const toggleUnsavedChangesModal = () => {
    setDiscardChangesModalIsOpen(!discardChangesModalIsOpen);
  };

  return (
    <Box
      backgroundColor="mist0"
      opacity="90%"
      position="sticky"
      py="space50"
      style={{ borderTop: '1px solid #C9C9C9', bottom: -24 }}
    >
      <Flex justifyContent="flex-end">
        <Button
          isDisabled={isSubmitting}
          mr="space50"
          variant="outline"
          onClick={() => {
            if (!dirty) {
              toggleIsEditing();

              return;
            }

            setDiscardChangesModalIsOpen(true);
          }}
        >
          Cancel
        </Button>
        <Button
          data-test-id="edit-contact-save-button"
          isDisabled={!dirty || !isValid || isSubmitting}
          mr="space60"
          onClick={() => handleSubmit()}
        >
          <Text color="white" fontWeight="semi">
            Save
          </Text>
        </Button>
      </Flex>
      <DiscardUnsavedChangesModal
        closeModal={toggleUnsavedChangesModal}
        handleIsEditing={toggleIsEditing}
        isModalOpen={discardChangesModalIsOpen}
        resetUpdateForm={handleReset}
      />
      <UpdateAllOpenOrdersModal
        closeModal={closeUpdateAllOpenOrdersModal}
        isLoading={isLoadingUpdateAllOpenOrders}
        isModalOpen={isUpdateAllOpenOrdersModalOpen}
        onSave={onSaveAllOpenOrders}
      />
    </Box>
  );
};
