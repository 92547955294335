import {
  Box,
  Divider,
  DrawerSection,
  Flex,
  InputErrorMessage,
  InputGroup,
  InputLabel,
  Select,
  Text,
} from '@endpoint/blockparty';
import { ErrorMessage } from 'components/ErrorMessage';
import { OcrConfidenceScoreText } from 'components/OcrConfidenceScoreText';
import { setNestedObjectValues, useFormikContext } from 'formik';
import React, { FC, useEffect } from 'react';
import { OrganizationForm } from 'components/OpenOrderDrawer/OrganizationForm';
import { useQuery } from '@apollo/client';
import { OpswareUser } from 'components/Auth/helpers';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';
import { CREATE_TRANSACTION_MUTATION_FAILED } from 'components/OpenOrderDrawer';
import { OpenOrderDrawerForm, OpenOrderDrawerFormType } from 'helpers/openOrder/openOrderFormTypes';
import { PropertyAddressForm } from 'components/OpenOrderDrawer/Step1/PropertyAddressForm';
import { Dates } from 'components/OpenOrderDrawer/Step2/Dates';
import { Terms } from 'components/OpenOrderDrawer/Step2/Terms';
import { REVIEW_FORM_PRODUCT_TYPES } from 'consts/supportedProductTypes';
import { transactionTypeMap } from 'consts/transactionConsts';

export const OCR_RESULTS_RETRIEVAL_FAILED = 'OCR_RESULTS_RETRIEVAL_FAILED'; // TBD - use shared location

export const REVIEW_SELECT_PRODUCT_TYPES = REVIEW_FORM_PRODUCT_TYPES.map((productType) => ({
  value: productType,
  label: transactionTypeMap[productType],
}));

export const ReviewForm: FC = () => {
  const { values, errors, setFieldValue, status, setStatus, touched, validateForm, setTouched, initialValues } =
    useFormikContext<OpenOrderDrawerForm>();

  const { data } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);
  const hasMultiOrgAccess = data?.me.hasMultiOrganizationAccess;

  const isOrganizationSet = !!initialValues?.organization;

  const { error = '' } = status ?? {};

  useEffect(() => {
    async function runFormValidation() {
      const validationErrors = await validateForm();

      if (Object.keys(validationErrors || {}).length > 0) {
        await setTouched(setNestedObjectValues(validationErrors, true));
      }
    }

    if (values) {
      void runFormValidation();
    }
  }, [values, validateForm, setTouched, setFieldValue]);

  useEffect(() => {
    setFieldValue('ocrComplete', true, true);
    setFieldValue('formType', OpenOrderDrawerFormType.UPLOAD);
  }, [setFieldValue]);

  useEffect(() => {
    // If the current user has only one organization access, setFieldValue "organization" to the user's organization.
    if (!hasMultiOrgAccess) {
      const organizationId = data?.me.organization;

      setFieldValue('organization', Array.isArray(organizationId) ? organizationId[0] : organizationId);
    }
  }, [data, hasMultiOrgAccess, setFieldValue]);

  return (
    <DrawerSection id="order-review-drawer-section">
      <Text as="h1" fontWeight="semi" mt="24px" size="fontSize50">
        Review order details
      </Text>
      <Box pb={error ? 'space50' : 'space0'} pt="space30">
        <Text as="h2">Verify the following information matches what is listed in the contract.</Text>
      </Box>
      {hasMultiOrgAccess && !isOrganizationSet && (
        <Box mt="space70">
          <OrganizationForm />
          <Divider mb="space70" />
        </Box>
      )}
      {error === CREATE_TRANSACTION_MUTATION_FAILED && (
        <ErrorMessage
          closeAction={() => setStatus({ error: '' })}
          description="There was a problem saving your order"
          title="Try again or contact the Opsware product team for assistance."
        />
      )}
      {error === OCR_RESULTS_RETRIEVAL_FAILED && (
        <ErrorMessage
          closeAction={() => setStatus({ error: '' })}
          description="Information could not be imported. Please continue to complete the order details manually."
          title="There was a problem reading the document"
        />
      )}
      <Box mt="space70">
        <Text as="h2" fontWeight="semi" size="fontSize30">
          Property
        </Text>

        <PropertyAddressForm />
      </Box>
      <Divider mt="space60" />
      <Box mt="space70">
        <Text as="h2" fontWeight="semi" size="fontSize30">
          Terms
        </Text>
        <InputGroup
          data-test-id="open-order-drawer-product-type-select"
          flex={1}
          groupId="productType"
          isInvalid={touched?.terms?.productType && !!errors.terms?.productType}
          pb="space30"
          pt="space50"
        >
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel htmlFor="productType">Product Type *</InputLabel>
            {values?.terms?.productType && values.ocrConfidenceScores?.productType && (
              <OcrConfidenceScoreText
                currentInputText={values.terms.productType}
                originalInputText={initialValues.terms?.productType}
                value={values.ocrConfidenceScores?.productType}
              />
            )}
          </Flex>
          <Select
            defaultValue={REVIEW_SELECT_PRODUCT_TYPES.find((option) => option.value === values.terms.productType) || ''}
            name="productType"
            options={REVIEW_SELECT_PRODUCT_TYPES}
            onChange={(value: { value: string }) => setFieldValue('terms.productType', value.value)}
          />
          {touched?.terms?.productType && errors.terms?.productType && (
            <InputErrorMessage>{errors.terms?.productType}</InputErrorMessage>
          )}
        </InputGroup>

        <Terms />
      </Box>
      <Divider mt="space60" />
      <Dates />
    </DrawerSection>
  );
};
