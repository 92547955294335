import React, { FC } from 'react';
import { Box, Flex, Grid, Heading, Text } from '@endpoint/blockparty';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { getCacheMarketName, getCacheOrganizationName } from 'helpers/transaction';
import useMarketsAndOrganizations from 'hooks/useMarketsAndOrganizations';

export interface BasicInfoOwnershipProps {
  transaction: Transaction;
}

export const BasicInfoOwnership: FC<BasicInfoOwnershipProps> = ({ transaction }) => {
  const { markets, organizations } = useMarketsAndOrganizations();

  return (
    <Box data-test-id="basic-info-ownership">
      <Heading backgroundColor="slate0" p="space50" size="fontSize40">
        Ownership
      </Heading>
      <Grid
        columnGap="space60"
        pb="space50"
        pt="space30"
        px="space50"
        rowGap="space40"
        templateColumns="repeat(2, minmax(150px, 1fr))"
      >
        <Box>
          <Flex width="space90">
            <Text as="p" color="passive" mb="space30" size="fontSize10" width="100%">
              Organization
            </Text>
          </Flex>
          <Text as="p" data-testid="basic-info-property-organization">
            {getCacheOrganizationName(organizations, transaction?.organizationId) || '-'}
          </Text>
        </Box>
        <Box>
          <Flex>
            <Text as="p" color="passive" mb="space30" size="fontSize10" width="100%">
              Market
            </Text>
          </Flex>
          <Text as="p" data-testid="basic-info-property-market" mb="space40">
            {getCacheMarketName(markets, transaction.marketId) || '-'}
          </Text>
        </Box>
      </Grid>
    </Box>
  );
};

BasicInfoOwnership.displayName = 'BasicInfoOwnership';
