import { gql, ApolloClient, ApolloQueryResult } from '@apollo/client';
import { AddressDetails, SimpleAddress, VerificationStatus } from '@endpoint/opsware-bff-graphql-schema';

export const VERIFY_ADDRESS_QUERY = gql`
  query verifyAddress($input: AddressVerificationInput) {
    verifyAddress(input: $input) {
      status {
        valid
        modified
      }
      address {
        city
        fullStreet
        number
        streetName
        secondary
        streetPost
        state
        countyName
        streetSuffix
        zipCode
      }
    }
  }
`;

export const SEARCH_ADDRESS_QUERY = gql`
  query searchAddress($input: AddressSearchInput) {
    searchAddress(input: $input) {
      search
      options {
        maxResults
        includeOnlyStates
      }
      suggestions {
        street
        secondary
        state
        city
        zipCode
      }
    }
  }
`;
export interface SearchAddressWithInputData {
  searchAddress: {
    suggestions: SimpleAddress[];
  };
}

export function getSearchAddressResults(
  searchTerm: string,
  client: ApolloClient<object>,
  formattedStateOptions: string[],
): Promise<ApolloQueryResult<SearchAddressWithInputData>> {
  const resp: Promise<ApolloQueryResult<SearchAddressWithInputData>> = client.query({
    query: SEARCH_ADDRESS_QUERY,
    variables: {
      input: {
        search: searchTerm,
        options: { includeOnlyStates: formattedStateOptions },
      },
    },
    fetchPolicy: 'network-only',
  });

  return resp;
}

export interface VerifyAddressWithInputData {
  verifyAddress: {
    address: AddressDetails;
    status: VerificationStatus;
  };
}

export function verifyAddressResults(
  address: SimpleAddress,
  client: ApolloClient<object>,
): Promise<ApolloQueryResult<VerifyAddressWithInputData>> {
  const resp: Promise<ApolloQueryResult<VerifyAddressWithInputData>> = client.query({
    query: VERIFY_ADDRESS_QUERY,
    variables: {
      input: {
        address,
      },
    },
    fetchPolicy: 'network-only',
  });

  return resp;
}
