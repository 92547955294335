import React, { FC } from 'react';
import { Avatar, Flex, Text, Icon, Box, useToast } from '@endpoint/blockparty';
import { Note, NoteEntityType, TransactionNote } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { giveFirstWord } from 'helpers/formatText';
import { generateInternalNoteDate } from 'helpers/datetimeHelper';
import { useMutation } from '@apollo/client';
import { GET_TRANSACTION_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GET_OPS_TASK_NOTES } from 'routes/Transaction/TransactionRightPanel/queries';

import {
  TOGGLE_INTERNAL_PINNED_NOTE,
  ToggleInternalPinnedNote,
  RemoveInternalNote,
  REMOVE_INTERNAL_NOTE,
  TOGGLE_PINNED_NOTE,
  REMOVE_NOTE,
} from './queries';
import { TogglePinnedButton } from './TogglePinnedButton';
import { RemoveInternalNoteButton } from './RemoveInternalNote';

interface InternalNoteProps {
  entityType: NoteEntityType;
  isBasicInfo?: boolean;
  note: TransactionNote | Note;
  transactionId: string;
}

export const InternalNote: FC<InternalNoteProps> = ({ entityType, isBasicInfo, note, transactionId }) => {
  const { isOpswareNotesEnabled } = useFlags();

  let avatarName: string = '';
  let userName: string = '';

  if (entityType === NoteEntityType.TRANSACTION || !isOpswareNotesEnabled) {
    const transactionNote = note as TransactionNote;

    avatarName = `${giveFirstWord(transactionNote.author?.firstName)} ${giveFirstWord(
      transactionNote.author?.lastName,
    )}`;

    userName = `${transactionNote.author?.firstName} ${transactionNote.author?.lastName}`;
  } else {
    const taskNote = note as Note;

    avatarName = `${giveFirstWord(taskNote?.authorName)} ${giveFirstWord(taskNote?.authorName?.split(' ')[1] || '')}`;

    userName = taskNote.authorName;
  }

  const getNoteInfo = entityType === NoteEntityType.TRANSACTION ? GET_TRANSACTION_INFO : GET_OPS_TASK_NOTES;
  const createdAtFormatted: string = generateInternalNoteDate(note.createdAt);
  const toast = useToast();
  const togglePinMutation = isOpswareNotesEnabled ? TOGGLE_PINNED_NOTE : TOGGLE_INTERNAL_PINNED_NOTE;
  const removeNoteMutation = isOpswareNotesEnabled ? REMOVE_NOTE : REMOVE_INTERNAL_NOTE;
  const showTogglePinButton = entityType === NoteEntityType.TRANSACTION;
  const showPinTitle = note.isPinned && isBasicInfo && entityType === NoteEntityType.TRANSACTION;
  const showRemoveButton = isBasicInfo || entityType === NoteEntityType.TASK;

  const mutationHandler = {
    onError(): void {
      toast({
        description: 'Action was not successful',
        duration: 5000,
        iconColor: 'watermelon500',
        icon: 'ErrorCircle',
      });
    },
  };

  const [togglePinned, { loading: pinUnpinLoading }] = useMutation<ToggleInternalPinnedNote>(togglePinMutation, {
    ...mutationHandler,
    refetchQueries: [
      {
        query: GET_TRANSACTION_INFO,
        variables: {
          where: {
            id: transactionId,
          },
        },
      },
    ],
  });

  const [removeNote, { loading: loadingRemove }] = useMutation<RemoveInternalNote>(removeNoteMutation, {
    ...mutationHandler,
    refetchQueries: [
      {
        query: getNoteInfo,
        variables: {
          where: {
            id: transactionId,
          },
        },
      },
    ],
  });

  const handleTogglePin = (isPinned: boolean): void => {
    if (isOpswareNotesEnabled) {
      void togglePinned({
        variables: {
          where: {
            id: note.id,
          },
          data: {
            isPinned,
          },
        },
      });
    } else {
      void togglePinned({
        variables: {
          data: {
            id: note.id,
            isPinned,
          },
        },
      });
    }
  };

  const handleRemoveNote = (noteId: string): void => {
    if (isOpswareNotesEnabled) {
      void removeNote({
        variables: {
          where: {
            id: note.id,
          },
        },
      });
    } else {
      void removeNote({
        variables: {
          id: noteId,
        },
      });
    }
  };

  return (
    <Flex data-test-id="internal-note" flexDirection="column" mb="space60">
      {showPinTitle && (
        <Flex alignItems="center" data-test-id="pinned" mb="space20" pl="36px">
          <Icon color="marigold700" name="PushPin" size="medium" />
          <Text as="p" color="marigold700" size="fontSize10">
            Pinned Note
          </Text>
        </Flex>
      )}
      <Flex mb="space20">
        <Avatar data-test-id="note-avatar" mr="space40" name={avatarName} size={24} />
        <Text as="p" data-test-id="note-author" fontWeight="semi" size="fontSize20">
          {userName}
        </Text>
      </Flex>
      <Box mb="space20" pl="36px">
        <Text as="p" data-test-id="note-text" size="fontSize20">
          {note.text}
        </Text>
      </Box>
      <Flex alignItems="center" pl="36px">
        <Text color="carbon500" data-test-id="note-created-date" size="fontSize10">
          {createdAtFormatted}
        </Text>
        <Icon color="carbon500" name="Bullet" size="medium" />
        {showTogglePinButton && (
          <TogglePinnedButton
            isPinned={note.isPinned}
            loading={pinUnpinLoading || loadingRemove}
            noteId={note.id}
            onTogglePinned={handleTogglePin}
          />
        )}
        {showRemoveButton && (
          <>
            <Icon color="carbon500" name="Bullet" size="medium" />
            <RemoveInternalNoteButton
              loading={pinUnpinLoading || loadingRemove}
              noteId={note.id}
              onRemoveNote={handleRemoveNote}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

InternalNote.displayName = 'InternalNote';
