import React, { FC } from 'react';
import { Task, Milestone } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { Divider, Box, AccordionItem, AccordionHeader, AccordionPanel } from '@endpoint/blockparty';

import { MilestoneAccordionItem } from './MilestoneAccordionItem';
import { MilestoneAccordionHeader } from './MilestoneAccordionHeader';

interface MilestoneAccordionProps {
  milestone: Milestone;
}

interface MilestoneAccordionItemsProps {
  tasks: Task[];
}

export const MilestoneAccordion: FC<MilestoneAccordionProps> = ({ milestone }) => (
  <AccordionItem
    backgroundColor="carbon0"
    data-test-id={`milestone-card-${milestone.position}`}
    defaultIsOpen
    mb="space50"
    overflow="none"
    variant="shadow"
  >
    <AccordionHeader data-testid={`milestone-header-${milestone.position}`} iconLeft>
      <MilestoneAccordionHeader milestone={milestone} />
    </AccordionHeader>
    <AccordionPanel data-testid={`milestone-panel-${milestone.position}`} p="space0">
      <MilestoneAccordionItems tasks={milestone.tasks} />
    </AccordionPanel>
  </AccordionItem>
);

MilestoneAccordion.displayName = 'MilestoneAccordion';

export const MilestoneAccordionItems: FC<MilestoneAccordionItemsProps> = ({ tasks }) => (
  <>
    {tasks.map((value: Task) => (
      <Box key={value.id}>
        <MilestoneAccordionItem task={value} />
        <Divider />
      </Box>
    ))}
  </>
);
