import React from 'react';
import { Flex, Icon, Avatar, Text, Box } from '@endpoint/blockparty';
import { TransactionParticipant, TransactionRole } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { formatRolesLikeTitle, giveFirstWord } from 'helpers/formatText';
import { formatPhoneNumber } from 'helpers/formatPhone';
import { UseTableCellProps, CellProps, Column } from 'react-table';
import { getColumnSortIcon } from 'helpers/table';
import { AllContactTypes } from 'consts/contactDetails';
import { roleOptions } from 'consts/transactionParticipant';

// //////////////////////////////////////////////////////////////////////
type NameCell = {
  label: string;
  avatarName: string;
};

type RolesCell = {
  formattedRoles: string;
  isDirectingContact: boolean;
};
export interface ContactsRowDataTypes {
  name: NameCell;
  roles: RolesCell;
  phoneNumber?: string;
  email: string;
  contactId: string;
  participantId: string;
}

export type TableColumnCellProps<T extends object> = Pick<UseTableCellProps<T>, 'column'>;

export type TableColumnHeaderCellProps = Pick<CellProps<GenericObject>, 'cell'>;

// //////////////////////////////////////////////////////////////////////

/** **** Table helpers ***** */

export const columnHeaders = (): Column<ContactsRowDataTypes>[] => [
  {
    Header: 'Name',
    accessor: 'name',
    // eslint-disable-next-line react/display-name
    Cell: ({ cell }: TableColumnHeaderCellProps): React.ReactElement => (
      <Flex alignItems="center">
        <Box>
          <Avatar mr="space40" name={cell && cell.value.avatarName} size={32} />
        </Box>
        <Text>{cell && cell.value.label}</Text>
      </Flex>
    ),
    sortType: 'customNameSort',
  },
  {
    Header: 'Role',
    accessor: 'roles',
    Cell: ({ cell }: TableColumnHeaderCellProps): React.ReactElement => {
      return (
        <Flex>
          {cell.value.isDirectingContact && (
            <Box
              alignItems="center"
              backgroundColor="marigold100"
              border="1px solid transparent"
              borderRadius="10px"
              data-test-id="contact-director-tag"
              display="flex"
              height="20px"
              justifyContent="center"
              mr="space30"
              px="space40"
            >
              <Text color="clay900" size="fontSize10">
                Directing
              </Text>
            </Box>
          )}
          <Text>{cell.value.formattedRoles}</Text>
        </Flex>
      );
    },
    sortType: 'customRolesSort',
  },
  {
    Header: 'Phone',
    accessor: 'phoneNumber',
    disableSortBy: true,
    Cell: ({ cell }: TableColumnHeaderCellProps): React.ReactElement => (
      <Text>{cell.value ? formatPhoneNumber(cell.value) : '-'}</Text>
    ),
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableSortBy: true,
  },
];

export const contactsRowData = (
  participants: TransactionParticipant[],
  canViewClosingSpecialist?: boolean,
): ContactsRowDataTypes[] =>
  participants
    .map((participant): ContactsRowDataTypes => {
      let firstName;
      let lastName;
      let entityName;
      let isEntity = false;
      let { roles }: { roles: TransactionRole[] } = participant;
      const { id: participantId, isDirectingContact } = participant;
      const contact = participant.contact as AllContactTypes;

      if ('entityName' in contact) {
        ({ entityName } = contact);
        isEntity = true;
      }

      if ('firstName' in contact) {
        ({ firstName, lastName } = contact);
      }

      if (!canViewClosingSpecialist && roles.length > 1) {
        roles = roles.filter((role) => role !== TransactionRole.CLOSING_SPECIALIST);
      }

      const { phone, email } = contact;
      const contactId = contact.id as string;

      const rolesLikeTitle: string = formatRolesLikeTitle(roles.join(', '));
      const fullName = (isEntity ? entityName : `${firstName} ${lastName}`) as string;
      const avatarName = isEntity
        ? giveFirstWord(entityName)
        : `${giveFirstWord(firstName || '')} ${giveFirstWord(lastName || '')}`;

      return {
        name: { label: fullName, avatarName },
        roles: { formattedRoles: rolesLikeTitle, isDirectingContact },
        phoneNumber: phone,
        email: email || '',
        participantId,
        contactId,
      };
    })
    .filter((participant) => {
      if (!canViewClosingSpecialist) {
        return participant.roles.formattedRoles !== roleOptions[TransactionRole.CLOSING_SPECIALIST].label;
      }

      return participant;
    });

/** **** react-table--specific components ***** */

export function ColumnSortedHeader({ column }: TableColumnCellProps<ContactsRowDataTypes>): React.ReactElement {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      {column.render('Header')}
      {/* Sort direction indicator */}
      {column.canSort && (
        <Icon color="carbon200" name={getColumnSortIcon(column.isSorted, column.isSortedDesc)} size="large" />
      )}
    </Flex>
  );
}

export function ColumnHeader({ column }: TableColumnCellProps<ContactsRowDataTypes>): React.ReactElement {
  return <>{column.render('Header')}</>;
}
