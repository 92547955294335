import React, { FC } from 'react';
import {
  SkeletonBlock,
  SkeletonText,
  Table,
  TableHeaderRow,
  TableHeaderCell,
  TableHeader,
  TableBody,
} from '@endpoint/blockparty';
import { v4 } from 'uuid';

interface SkeletonTableProps {
  rows?: number;
  columns?: number;
}

export const SkeletonTable: FC<SkeletonTableProps> = ({ rows = 8, columns = 4 }): React.ReactElement => (
  <Table label="Loading Skeleton Table">
    <TableHeader>
      <TableHeaderRow>
        {[...Array(columns)].map((_column, i) => {
          const columKeyId = v4();

          return (
            <TableHeaderCell
              key={`header-cell-${columKeyId}`}
              data-test-id="table-header-cell"
              flex="150 0 auto"
              p="space50"
              width="150px"
            >
              <SkeletonBlock width="100%" />
            </TableHeaderCell>
          );
        })}
      </TableHeaderRow>
    </TableHeader>
    <TableBody>
      {[...Array(rows)].map((_row) => {
        const tableRowId = v4();

        return (
          <TableHeaderRow key={`bodyRow-${tableRowId}`} data-test-id="table-row">
            {[...Array(columns)].map((_column) => {
              const columKeyId = v4();

              return (
                <TableHeaderCell
                  key={`table-cell-${columKeyId}`}
                  data-test-id="table-cell"
                  flex="150 0 auto"
                  p="space50"
                  width="150px"
                >
                  <SkeletonText display="flex" flex={1} lines={1} />
                </TableHeaderCell>
              );
            })}
          </TableHeaderRow>
        );
      })}
    </TableBody>
  </Table>
);
