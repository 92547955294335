import React, { FC, useMemo } from 'react';
import { Table, TableBody, TableCell, TableRow, Card, TableFooter, Link } from '@endpoint/blockparty';
import { AllContactTypes } from 'consts/contactDetails';
import { RouteComponentProps, navigate, Link as ReachRouterLink } from '@reach/router';
import { useSortBy, useTable, useFlexLayout, ColumnInstance, Cell } from 'react-table';
import {
  AgentContact,
  Contact,
  EntityContact,
  IndividualContact,
  LenderContact,
} from '@endpoint/opsware-bff-graphql-schema';
import { Pagination } from 'components/Pagination';
import { ParamsType } from 'helpers/utils/queryParams';
import { useAddressBookFilters } from 'helpers/addressBook';
import GenericTableHeader from 'components/GenericTableHeader';
import GenericTableRow from 'components/GenericTableRow';

import {
  contactsRowData,
  columnHeaders,
  ColumnHeader,
  useGetInitialSortState,
  ContactsRowDataTypes,
} from '../TableComponents';

interface ContactsTableProps {
  contacts: (Contact | IndividualContact | LenderContact | AgentContact | EntityContact)[];
  currentParams: ParamsType;
  loading: boolean;
  totalItems: number;
}

const columnHeaderInstance = (column: ColumnInstance<ContactsRowDataTypes>) => <ColumnHeader column={column} />;

export const ContactsTable: FC<ContactsTableProps> = ({ contacts, currentParams, loading, totalItems }) => {
  const columns = useMemo(columnHeaders, []);
  const data = useMemo(() => contactsRowData(contacts as AllContactTypes[]), [contacts]);
  const { navigateWithParams } = useAddressBookFilters();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        sortBy: useGetInitialSortState(),
      },
    },
    useSortBy,
    useFlexLayout,
  );

  return (
    <Card variant="shadow">
      <Table data-test-id="contacts-table-wrapper" label="Address Book Table" {...getTableProps()}>
        <GenericTableHeader
          columnHeader={columnHeaderInstance}
          dataTestId="address-book-table-header"
          headerGroups={headerGroups}
        />
        <TableBody maxHeight={rows.length ? 'calc(100vh - 315px)' : '40px'} overflow="auto" {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row) => {
              prepareRow(row);

              const { key: rowKey, ...rowProps } = row.getRowProps();

              return (
                <GenericTableRow<ContactsRowDataTypes>
                  key={rowKey}
                  cursor="pointer"
                  dataTestId={`row-${row.original.contactId}`}
                  row={row}
                  onClick={(): RouteComponentProps => {
                    return navigate(`address-book/contacts/${row.original.contactId}`);
                  }}
                />
              );
            })
          ) : (
            <TableCell alignItems="center" color="carbon500" height="100%" justifyContent="center" py="space30">
              No matching records found. Try adjusting your search.
            </TableCell>
          )}
        </TableBody>
        {rows.length ? (
          <TableFooter borderTop="1px solid" borderTopColor="carbon100">
            <Pagination
              currentParams={currentParams}
              loading={loading}
              navigateWithParams={navigateWithParams}
              totalItems={totalItems}
            />
          </TableFooter>
        ) : null}
      </Table>
    </Card>
  );
};
