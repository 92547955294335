import { TransactionNote } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { getUnixTime, parseISO } from 'date-fns';

export const sortNotesByDate = (notes: TransactionNote[]) =>
  notes.sort((aNote: TransactionNote, bNote: TransactionNote) => {
    const noteA = getUnixTime(parseISO(aNote.createdAt));
    const noteB = getUnixTime(parseISO(bNote.createdAt));

    return noteB - noteA;
  });

export const sortInternalNotesByPinned = (notes: TransactionNote[]): TransactionNote[] => {
  const pinnedNotes = sortNotesByDate(notes.filter((note: TransactionNote) => note.isPinned));
  const nonPinnedNotes = sortNotesByDate(notes.filter((note: TransactionNote) => !note.isPinned));

  return [...pinnedNotes, ...nonPinnedNotes];
};

export const sortPinnedInternalNotesByDate = (notes: TransactionNote[]): TransactionNote[] =>
  sortNotesByDate(notes.filter((note: TransactionNote) => note.isPinned));
