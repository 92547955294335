import React, { FC } from 'react';
import { flatten } from 'lodash';
import { MultiSelect } from '@endpoint/blockparty';
import { TodoStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { todoStatusFilterMap } from 'consts/toDoConsts';

interface TodoStatusFilterOptions {
  value: string;
  label: string;
}

const filterOptions: TodoStatusFilterOptions[] = Object.keys(todoStatusFilterMap).map((key) => ({
  label: key,
  value: key,
}));

interface TodoStatusFilterProps {
  onChange(status: TodoStatus[]): void;
}

export const TodoStatusFilter: FC<TodoStatusFilterProps> = ({ onChange }) => (
  <MultiSelect
    controlText="To-do Status"
    options={filterOptions}
    onChange={(value): void => {
      const valueTyped = value as TodoStatusFilterOptions[];
      const newFilterOptions = flatten(valueTyped.map((valueType) => todoStatusFilterMap[valueType.value]));

      onChange(newFilterOptions);
    }}
  />
);
