import React, { FC } from 'react';
import { Tag } from '@endpoint/blockparty';
import { mapStatusText, mapStatusToColorVariant } from 'helpers/statusHelper';
import { toDoStatusMapping, toDoStatusColorMap } from 'consts/toDoConsts';
import { TodoStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { BoxProps } from '@endpoint/blockparty/dist/components/Box';

interface StatusTagProps extends BoxProps {
  status: TodoStatus;
  variantType?: 'solid' | 'outline';
}

export const StatusTag: FC<StatusTagProps> = ({ status, variantType = 'solid', ...rest }) => (
  <Tag
    data-test-id={`status-Tag-${status}`}
    minWidth="84px"
    role="status"
    variant={variantType}
    variantColor={mapStatusToColorVariant(status, toDoStatusColorMap)}
    {...rest}
  >
    {mapStatusText(status, toDoStatusMapping)}
  </Tag>
);

StatusTag.displayName = 'StatusTag';
