import React, { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  InputLabel,
  Modal,
  ModalContent,
  ModalFooter,
  ModalSection,
  Select,
  Text,
  useToast,
} from '@endpoint/blockparty';
import { getSelectOptionsByTransactionType } from 'consts/cancellationConsts';
import { CancellationReason, TransactionStatus, TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { useMutation } from '@apollo/client';
import { UPDATE_TRANSACTION_BASIC_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import { cache } from 'apollo/ApolloCache';

interface CancelTransactionModalProps {
  transactionId: string;
  transactionType: TransactionType;
  isOpen: boolean;
  onClose: () => void;
}

export const CancelTransactionModal: FC<CancelTransactionModalProps> = ({
  transactionId,
  transactionType,
  isOpen,
  onClose,
}) => {
  const toast = useToast();

  const [cancellationReason, setCancellationReason] = useState();
  const [cancellationNote, setCancellationNote] = useState('');

  const [cancelTransactionUpdate, { loading: isCancelTransactionUpdating }] = useMutation(
    UPDATE_TRANSACTION_BASIC_INFO,
    {
      async onCompleted(): Promise<void> {
        onClose();
        toast({
          description: 'Cancel Transaction Successful',
          duration: 5000,
          icon: 'CheckCircle',
        });

        await cache.reset();
      },
      onError(): void {
        toast({
          description: 'Action was not successful',
          duration: 5000,
          iconColor: 'watermelon500',
          icon: 'ErrorCircle',
        });
      },
    },
  );

  const cancelTransaction = (id: string, reason: CancellationReason | undefined, note?: string | undefined) => {
    void cancelTransactionUpdate({
      variables: {
        updateTransaction: {
          status: TransactionStatus.CANCELLED,
          cancellationReason: reason,
          cancellationNote: note,
        },
        where: { id },
      },
    });
  };

  const cancellationSelectOptions = getSelectOptionsByTransactionType(transactionType);

  const defaultValue = cancellationSelectOptions.find((option) => option.value === cancellationReason);

  const isCancellationReasonOther = cancellationReason === CancellationReason.OTHER;

  const isSaveButtonDisabled = !cancellationReason || (isCancellationReasonOther && !cancellationNote);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalSection>
          <Flex flexDirection="column">
            <Text as="h3" fontWeight="semi" paddingBottom="space60" size="fontSize30">
              Cancellation Reason
            </Text>
            <Text paddingBottom="space60">
              If &quot;Other&quot;, please leave an internal note describing the reason.
            </Text>
            <Select
              defaultValue={defaultValue}
              isLoading={isCancelTransactionUpdating}
              options={cancellationSelectOptions}
              onChange={(value) => {
                setCancellationReason(value.value);
              }}
            />
            {isCancellationReasonOther && (
              <Box color="carbon900" paddingTop="space60">
                <InputLabel color="carbon500" htmlFor="cancellation-reason-other" size="fontSize10">
                  Internal Note <Text color="red">*</Text>
                </InputLabel>
                <Input
                  id="cancellation-reason-other"
                  placeholder="Describe the reason for cancellation"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setCancellationNote(event.target.value);
                  }}
                />
              </Box>
            )}
          </Flex>
        </ModalSection>
        <ModalFooter>
          <Button mr="space30" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            dataTestId="cancellation-save-button"
            isDisabled={isSaveButtonDisabled}
            isLoading={isCancelTransactionUpdating}
            minWidth={105}
            onClick={async () => {
              cancelTransaction(transactionId, cancellationReason, cancellationNote);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
