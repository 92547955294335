import { AddressDetails, AddressVerificationResults, SimpleAddress } from '@endpoint/opsware-bff-graphql-schema';

export const emptyAddress: SimpleAddress = {
  street: '',
  secondary: '',
  city: '',
  state: '',
  zipCode: '',
};

export enum PropertyAddressTypes {
  STREET1 = 'property.address.street1',
  STREET2 = 'property.address.street2',
  CITY = 'property.address.city',
  STATE = 'property.address.state',
  ZIP = 'property.address.zip',
  COUNTY = 'property.address.county',
}

export const ADDRESS_SELECTION_TYPES = {
  SUGGESTED_ADDRESS: 'suggestedAddress',
  ENTERED_ADDRESS: 'enteredAddress',
};

export const mockEnteredAddressWithTypos = {
  address: {
    street1: '2101 E El Segundos Blvd',
    street2: 'Str 104',
    city: 'El Segundo',
    state: 'CA',
    county: 'Los Angels',
    zip: '90246',
  },
};

export const invalidTestAddress = {
  street1: '0000 TEST FILE',
  street2: '',
  city: 'Los Angeles',
  state: 'CA',
  county: 'Los Angeles',
  zip: '91411',
};

export const mockSuggestedAddress: AddressDetails = {
  city: 'El Segundo',
  countyName: 'Los Angeles',
  fullStreet: '2101 E El Segundo Blvd Ste 104',
  number: '2101',
  secondary: 'Ste 104',
  state: 'CA',
  streetName: 'El Segundo',
  streetPost: '',
  streetPre: '',
  streetSuffix: 'Blvd',
  zipCode: '90245',
  zip4: '',
};

export const mockTestEnteredAddress = {
  street1: '0000 TEST FILE',
  street2: '',
  city: 'Los Angeles',
  state: 'CA',
  zip: '91411',
  county: 'Los Angeles',
};

export const invalidAddressVerificationValues: SimpleAddress = {
  street: '0000 TEST FILE',
  secondary: '',
  city: 'Los Angeles',
  state: 'CA',
  zipCode: '91411',
};

export const mockAutoCorrectedResponse: AddressVerificationResults = {
  status: {
    valid: true,
    modified: true,
  },
  address: {
    city: 'El Segundo',
    fullStreet: '2101 E El Segundo Blvd Ste 104',
    number: '2101',
    streetName: 'El Segundo',
    secondary: 'Ste 104',
    streetPost: '',
    state: 'CA',
    countyName: 'Los Angeles',
    streetPre: '',
    streetSuffix: 'Blvd',
    zipCode: '90245',
  },
};

export const mockInvalidResponse: AddressVerificationResults = {
  status: {
    valid: false,
    modified: false,
  },
  address: {
    city: '0000 TEST FILE',
    fullStreet: '0000 TEST FILE',
    number: '2101',
    streetName: '',
    secondary: '',
    streetPost: '',
    state: 'CA',
    countyName: 'Los Angeles',
    streetPre: '',
    streetSuffix: '',
    zipCode: '91411',
  },
};

export const manualAddressSelection: SimpleAddress = {
  street: 'Enter Address Manually',
};
