import { Box, DrawerSection, Text } from '@endpoint/blockparty';
import React, { FC, useEffect, useState } from 'react';
import useOCRSupport from 'hooks/useOCRSupport';

import { OpenOrderPsaUploadWidget } from '../PsaUploadWidget';
import { ErrorMessage } from '../../ErrorMessage';
import { SkeletonOpenOrderPsaUploadWidget } from '../../SkeletonOpenOrderPsaUploadWidget';

export const UploadEntry: FC = () => {
  const [hasQueryError, setHasQueryError] = useState<boolean>(false);
  const { regions, loading } = useOCRSupport();

  useEffect(() => {
    if (!regions.length && !loading) setHasQueryError(true);
  }, [regions, loading]);

  return (
    <DrawerSection>
      <Text data-test-id="upload-psa-text" fontWeight="semi" size="fontSize30">
        Upload a purchase agreement
      </Text>
      <br />
      <Text as="p" fontWeight="normal" mt="space50" size="fontSize20">
        Select a market before uploading the purchase agreement. Maximum upload file size 50MB. PDF only.
      </Text>
      {hasQueryError && (
        <Box data-test-id="error-open-order-psa-query" mt="space70">
          <ErrorMessage
            closeAction={() => setHasQueryError(!hasQueryError)}
            description='Please select "Other" as the market to continue without the help of OCR.'
            title="There was a problem loading market options"
          />
        </Box>
      )}
      {loading ? <SkeletonOpenOrderPsaUploadWidget /> : <OpenOrderPsaUploadWidget />}
    </DrawerSection>
  );
};
