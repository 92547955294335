import { ApolloClient } from '@apollo/client';
import { FileUpload } from 'apollo/resolvers/UploadDocument/FileUpload';
import { maxUploadSize, FileRejections, DocumentUpload } from 'consts/uploadDocumentsConsts';
import { getUploadErrorMessage } from 'helpers/documents';
import { cache } from 'apollo/ApolloCache';
import { trimFileExtension } from 'helpers/formatText';
import { UnassignedDocumentCreateInput } from '@endpoint/opsware-bff-graphql-schema';
import { delay } from 'lodash';

import { createDocumentUpload } from './createDocumentUpload';
import {
  startUpload,
  uploadDocumentError,
  updateUploadFileUpload,
  uploadProgressDone,
  updateUploadProgress,
  createUnassignedDocument,
} from './apolloClientHelpers';

export interface UploadUnassignedDocumentInput {
  file: File;
  name: string;
  type: string;
  region: string;
  escrowNote?: string;
}

export const uploadUnassignedDocument = async (
  variables: UploadUnassignedDocumentInput,
  client: ApolloClient<object>,
): Promise<FileUpload> => {
  const file = variables.file as File;
  const documentType = variables.type;
  const validationError = file.size > maxUploadSize ? getUploadErrorMessage(FileRejections.FILE_TOO_LARGE) : '';

  let fileUpload = new FileUpload(variables.file, '');
  const documentUpload: DocumentUpload = createDocumentUpload(file.name, documentType, validationError, fileUpload);

  startUpload(cache, documentUpload);

  if (documentUpload.fileRejection) {
    uploadDocumentError(cache, documentUpload.id);
  } else {
    const unassignedDocumentInput: UnassignedDocumentCreateInput = {
      name: trimFileExtension(file.name),
      type: documentType,
      region: variables.region,
      escrowNote: variables.escrowNote,
    };

    try {
      const { data: document } = await createUnassignedDocument(client, unassignedDocumentInput);
      const url = document?.createUnassignedDocument.storage?.uploadUrl;
      const downloadUrl = document?.createUnassignedDocument.storage?.url;

      const documentId = document?.createUnassignedDocument.id;

      if (!url) {
        throw new Error('Unable to retreive DocumentUploadUrl in Open Order upload PSA');
      }

      fileUpload = new FileUpload(variables.file, url, documentId, downloadUrl);

      delay(() => {
        /**
         * Cache updates are synchronous and this code uses a reactive variable, see link for more information
         * https://github.com/apollographql/apollo-client/issues/6852
         *
         * Explanation about using delay over setTimeout
         * http://www.boduch.ca/2014/04/lodash-replacing-settimeout-with-delay.html
         **/
        updateUploadFileUpload(cache, documentUpload.id, fileUpload);
      }, 100);

      fileUpload.onComplete = () => {
        uploadProgressDone(cache, documentUpload.id);
      };

      fileUpload.onProgress = (progress) => {
        updateUploadProgress(cache, documentUpload.id, progress);
      };

      fileUpload.onError = () => {
        uploadDocumentError(cache, documentUpload.id);
      };

      fileUpload.start();
    } catch (ex) {
      uploadDocumentError(cache, documentUpload.id);
    }
  }

  return fileUpload;
};
