import React, { FC, useState } from 'react';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Text,
  useDisclosure,
  useDropdownMenuState,
} from '@endpoint/blockparty';
import { Transaction, TransactionStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MarkAsTestModal } from 'components/MarkAsTestModal';
import { useHasWriteAccess } from 'hooks/useHasWriteAccess';
import { CancelTransactionModal } from 'components/CancelTransactionModal';

import { AdditionalInfoDrawer } from './AdditionalInfoPanel';

interface TransactionOverviewProps {
  transaction: Transaction;
}

export const BasicInfoActionMenu: FC<TransactionOverviewProps> = ({ transaction }) => {
  const { id: transactionId } = transaction;
  const menu = useDropdownMenuState({ placement: 'bottom-end' });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCancelTransactionModalOpen, setIsCancelTransactionModalOpen] = useState(false);
  const { isMarkTransactionAsTestEnabled, isCancelTransactionsInOpswareEnabled } = useFlags();

  const hasWriteAccess = useHasWriteAccess();

  const hasMarkAsTestAccess = hasWriteAccess && isMarkTransactionAsTestEnabled;

  const isCancelTransactionEligible =
    transaction.status === TransactionStatus.PRELISTING || transaction.status === TransactionStatus.IN_ESCROW;

  const hasCancelTransactionAccess =
    hasWriteAccess && isCancelTransactionsInOpswareEnabled && isCancelTransactionEligible;

  const openAdditionalInfoDrawer = () => setIsDrawerOpen(true);
  const closeAdditionalInfoDrawer = () => setIsDrawerOpen(false);

  const openCancelTransactionModal = () => setIsCancelTransactionModalOpen(true);
  const closeCancelTransactionModal = () => setIsCancelTransactionModalOpen(false);
  const isTest = transaction.isTest;

  const {
    isOpen: isMarkAsTestModalOpen,
    onOpen: onMarkAsTestModalOpen,
    onClose: onMarkAsTestModalClose,
  } = useDisclosure();

  return (
    <>
      <DropdownMenuTrigger state={menu}>
        <IconButton
          aria-label="Open Basic Info Action Menu"
          color="carbon500"
          label="Open Basic Info Action Menu"
          marginLeft="auto"
          name="MoreHorizontal"
          size="small"
          variant="none"
          variantColor="carbon"
        />
      </DropdownMenuTrigger>
      <DropdownMenu py="space30" state={menu}>
        {hasCancelTransactionAccess && (
          <DropdownMenuItem borderRadius="radiusNone" px="space50" py="space30" onClick={openCancelTransactionModal}>
            <Flex>
              <Box>
                <Icon color="carbon500" mr="space30" name="Cancel" size="large" />
              </Box>
              <Text>Cancel Transaction</Text>
            </Flex>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem borderRadius="radiusNone" px="space50" py="space30" onClick={openAdditionalInfoDrawer}>
          <Flex alignItems="center">
            <Box>
              <Icon color="carbon500" mr="space30" name="Information" size="large" />
            </Box>
            <Text as="p" color="carbon900" size="fontSize20">
              Additional Info
            </Text>
          </Flex>
        </DropdownMenuItem>
        {hasMarkAsTestAccess && !isTest && (
          <DropdownMenuItem borderRadius="radiusNone" px="space50" py="space30" onClick={onMarkAsTestModalOpen}>
            <Flex alignItems="center">
              <Box>
                <Icon color="carbon500" mr="space30" name="Test" size="large" />
              </Box>
              <Text as="p" color="carbon900" size="fontSize20">
                Mark as Test
              </Text>
            </Flex>
          </DropdownMenuItem>
        )}
      </DropdownMenu>
      <AdditionalInfoDrawer isOpen={isDrawerOpen} transactionId={transactionId} onClose={closeAdditionalInfoDrawer} />
      <MarkAsTestModal isOpen={isMarkAsTestModalOpen} transactionId={transaction.id} onClose={onMarkAsTestModalClose} />
      <CancelTransactionModal
        isOpen={isCancelTransactionModalOpen}
        transactionId={transaction.id}
        transactionType={transaction.type!}
        onClose={closeCancelTransactionModal}
      />
    </>
  );
};
