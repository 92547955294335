import React, { FC, useState } from 'react';
import { AllContactTypes } from 'consts/contactDetails';
import { useIsSystemOrOpsAdmin } from 'hooks/useIsSystemOrOpsAdmin';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, useToast } from '@endpoint/blockparty';
import { EditContactDetailsAction, useEditContactDetailsProvider } from 'hooks/useEditContactDetailsProvider';
import { UpdateContactInput } from '@endpoint/opsware-bff-graphql-schema';
import {
  generateEditContactSubmitValues,
  generateEditTransactionParticipantsValues,
  generateInitialEditContactValues,
  generateUpdateContactValidation,
  getDisplayableError,
} from 'helpers/updateContact';
import { Formik } from 'formik';
import { SystemInformation } from 'routes/AddressBook/Tabs/DetailsTab/SystemInformation';
import { ErrorMessage } from 'components/ErrorMessage';
import { useMutation } from '@apollo/client';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { GlobalContactUpdateOptions } from 'consts/addressBook';
import { scrollElementIntoView } from 'helpers/scrollElementIntoView';

import { DetailsFields } from './DetailsFields';
import { ContactInformationFields } from './ContactInformationFields';
import { EditContactDetailsActionBar } from '../EditContactDetailsActionBar';
import {
  UpdateContactDetailsData,
  UpdateTransactionParticipantsData,
  UPDATE_CONTACT_DETAILS,
  UPDATE_TRANSACTION_PARTICIPANTS,
} from './queries';

interface EditContactDetailsProps {
  contact: AllContactTypes;
}

export const EditContactDetails: FC<EditContactDetailsProps> = ({ contact }) => {
  const { isAdmin } = useIsSystemOrOpsAdmin();
  const { canEditGlobalContactInAddressBook, canApplyContactEditsToAllOpenOrders } = useFlags();
  const userCanUpdatePhoneOrEmail = isAdmin && canEditGlobalContactInAddressBook;
  const toast = useToast();
  const { state, dispatch } = useEditContactDetailsProvider();
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const [displayUpdateAllOpenOrdersModal, setDisplayUpdateAllOpenOrdersModal] = useState<boolean>(false);
  const [errDescription, setErrDescription] = useState<string>('');
  const [updatedFields, setUpdatedFields] = useState<UpdateContactInput>({});
  const [editContactDetailsSegmentKeys, setEditContactDetailsSegmentKeys] = useState<string[]>([]);
  const initialValues: UpdateContactInput = generateInitialEditContactValues(contact);
  const validationSchema = generateUpdateContactValidation(contact);

  const handleHasError = () => {
    setHasSubmitError(false);
  };

  const [updateContactDetails, { loading }] = useMutation<UpdateContactDetailsData>(UPDATE_CONTACT_DETAILS, {
    onCompleted(): void {
      dispatch({
        type: EditContactDetailsAction.SET_IS_EDITING,
        payload: { ...state, isEditing: false },
      });

      toast({
        description: 'Edits saved',
        duration: 5000,
        icon: 'CheckCircle',
      });

      trackAnalytics('Edit Contact Saved', { fields: editContactDetailsSegmentKeys });
    },
    onError(error): void {
      const errMessage = getDisplayableError(error);

      if (errMessage) {
        setErrDescription(errMessage);
      } else {
        setErrDescription('');
      }

      setHasSubmitError(true);
    },
  });

  const [updateTransactionParticipants] = useMutation<UpdateTransactionParticipantsData>(
    UPDATE_TRANSACTION_PARTICIPANTS,
    {
      onCompleted(): void {
        void updateContactDetails({
          variables: {
            where: { id: contact.id },
            input: { ...updatedFields },
          },
        });
      },
      onError(error): void {
        // displays generic error message
        setErrDescription('');
        setHasSubmitError(true);
        setDisplayUpdateAllOpenOrdersModal(false);
        scrollElementIntoView('error-message-alert');
      },
    },
  );

  const handleSubmit = React.useCallback(
    async (values: UpdateContactInput) => {
      const submitValues = generateEditContactSubmitValues(initialValues, values);

      setEditContactDetailsSegmentKeys(Object.keys(submitValues));

      if (isOnlyEmailOrPhoneUpdated(submitValues) || !canApplyContactEditsToAllOpenOrders) {
        await updateContactDetails({
          variables: {
            where: { id: contact.id },
            input: { ...submitValues },
          },
        });
      } else {
        setUpdatedFields(submitValues);
        setDisplayUpdateAllOpenOrdersModal(true);
      }
    },
    [initialValues, canApplyContactEditsToAllOpenOrders, updateContactDetails, contact.id],
  );

  const isOnlyEmailOrPhoneUpdated = (updatedValues: UpdateContactInput) => {
    const numberOfUpdatedProps = Object.getOwnPropertyNames(updatedValues).length;

    return (
      (numberOfUpdatedProps === 1 && (!!updatedValues.email || !!updatedValues.phoneNumber)) ||
      (numberOfUpdatedProps === 2 && !!updatedValues.email && !!updatedValues.phoneNumber)
    );
  };

  const toggleModal = () => {
    setDisplayUpdateAllOpenOrdersModal(!displayUpdateAllOpenOrdersModal);
  };

  const onSave = React.useCallback(
    async (optionSelected: string) => {
      if (optionSelected === GlobalContactUpdateOptions.APPLY_TO_ALL) {
        const submitTransactionParticipantValues = generateEditTransactionParticipantsValues(updatedFields);

        await updateTransactionParticipants({
          variables: {
            where: { contactId: contact.id },
            input: { ...submitTransactionParticipantValues },
          },
        });
      }

      if (optionSelected === GlobalContactUpdateOptions.DO_NOT_APPLY) {
        await updateContactDetails({
          variables: {
            where: { id: contact.id },
            input: { ...updatedFields },
          },
        });
      }
    },
    [updatedFields, updateContactDetails, contact.id, updateTransactionParticipants],
  );

  return (
    <Formik
      initialValues={{ ...initialValues }}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Box>
        {hasSubmitError ? (
          <Box id="error-message-alert" pb="space50">
            <ErrorMessage closeAction={handleHasError} description={errDescription} title="Unable to save changes" />
          </Box>
        ) : null}
        <DetailsFields contact={contact} />
        <ContactInformationFields canEditOnboardedContactProps={userCanUpdatePhoneOrEmail} contact={contact} />
        <SystemInformation contact={contact} />
        <EditContactDetailsActionBar
          closeUpdateAllOpenOrdersModal={toggleModal}
          isLoadingUpdateAllOpenOrders={loading}
          isUpdateAllOpenOrdersModalOpen={displayUpdateAllOpenOrdersModal}
          onSaveAllOpenOrders={onSave}
        />
      </Box>
    </Formik>
  );
};
