import React, { FC } from 'react';
import { Text, Grid, Icon, Box } from '@endpoint/blockparty';
import { Contact, IndividualContact } from '@endpoint/opsware-bff-graphql-schema';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import { Color } from '@endpoint/blockparty/dist/components/Box/types';

interface TaskEntityContactProps {
  contact: Contact;
}

interface TaskEntityRowProps {
  label: string;
  value?: string;
}

export const TaskEntityRow: FC<TaskEntityRowProps> = ({ label, value }) => {
  if (!value) {
    return null;
  }

  return (
    <>
      <Text color="carbon500">{label}</Text>
      <Text>{value}</Text>
    </>
  );
};

export const TaskEntityContact: FC<TaskEntityContactProps> = ({ contact }) => {
  const individualContact = contact as IndividualContact;
  const fullName = `${individualContact.firstName} ${individualContact.lastName}`.trim();
  const onboardedValue = individualContact.isOnboarded ? 'Joined' : 'Not Onboarded';
  const onboardedIcon: { color: Color; name: IconNames } = individualContact.isOnboarded
    ? { color: 'aloe500', name: 'CheckCircle' }
    : { color: 'marigold500', name: 'Warning' };

  return (
    <Grid backgroundColor="slate0" gap="space10" templateColumns="1fr 3fr">
      <TaskEntityRow label="Name:" value={fullName} />
      <TaskEntityRow label="Email:" value={individualContact.email} />
      <TaskEntityRow label="Phone:" value={individualContact.phone} />
      {individualContact.isOnboarded !== undefined && (
        <>
          <Text color="carbon500">Status:</Text>
          <Box>
            <Icon mr="space30" {...onboardedIcon} />
            <Text>{onboardedValue}</Text>
          </Box>
        </>
      )}
    </Grid>
  );
};
