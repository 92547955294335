import { Document, TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { formatLikeTitle } from 'helpers/formatText';

export interface DocumentsRowData {
  added: string;
  deliveredTo: TransactionParticipant[];
  displayName: string;
  documentId: string;
  name: string;
  type: string;
  mimeType: string;
}

export const deriveMimeTypeText = (mimeType: string | undefined): string => {
  if (mimeType && mimeType.includes('pdf')) return '.pdf';
  if (mimeType && mimeType.includes('jpeg')) return '.jpg';

  return '';
};

export const getDocumentDisplayName = (documentName: string, mimeType: string): string => {
  if (mimeType && documentName.includes(mimeType)) return documentName;

  if (mimeType && documentName.includes('.jpeg')) {
    const trimmedJpeg = documentName.replace('.jpeg', '');

    return `${trimmedJpeg}${mimeType}`;
  }

  return `${documentName}${mimeType}`;
};

export const documentsRowData = (documents: Document[]): DocumentsRowData[] =>
  documents.map((document: Document) => {
    const type = document.type ? formatLikeTitle(document.type) : '-';
    const mimeType = deriveMimeTypeText(document.storage?.mimeType);
    const documentName = getDocumentDisplayName(document.name, mimeType);

    return {
      displayName: documentName,
      name: `${documentName}`.toLowerCase(),
      type,
      deliveredTo: document.participants,
      added: document.createdAt,
      documentId: document.id,
      mimeType,
    };
  });
