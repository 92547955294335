import React, { FC, useContext, useCallback } from 'react';
import { Alert, Button, AlertContent, AlertDescription, AlertTitle } from '@endpoint/blockparty';
import { navigate } from '@reach/router';
import { TransactionContext } from 'routes/Transaction';
import { getPsaDocData } from 'helpers/orderReview/getPsaDocData';
import { useDocumentReviewContext, DocumentReviewType } from 'routes/DocumentReview/contexts/DocumentReviewContext';
import { useQuery } from '@apollo/client';
import { GetTransactionType, GET_TRANSACTION_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';

export const OrderReviewPrompt: FC = () => {
  const { id: transactionId } = useContext(TransactionContext);
  const { setDocumentProperties } = useDocumentReviewContext();
  const { data } = useQuery<GetTransactionType>(GET_TRANSACTION_INFO, {
    variables: {
      where: { id: transactionId },
    },
  });
  const { transaction } = data ?? {};

  const goToReview = useCallback(async () => {
    if (transaction) {
      const { id, storageUrl } = getPsaDocData(transaction.documents);

      if (id && storageUrl) {
        setDocumentProperties({
          documentId: id,
          documentUrl: storageUrl,
          documentReviewType: DocumentReviewType.ORDER_REVIEW,
          documentRegion: transaction.property.address.state ?? '',
        });
      }

      await navigate(`/document-review?transactionId=${transactionId}`);
    }
  }, [transaction, transactionId, setDocumentProperties]);

  return (
    <Alert backgroundColor="marigold0" data-test-id="order-review-prompt" mb="space60" status="info">
      <AlertContent>
        <AlertTitle>Escrow ordered through the Endpoint App</AlertTitle>
        <AlertDescription>To open escrow, start by reviewing the purchase agreement.</AlertDescription>
      </AlertContent>
      <ButtonWriteRequired
        data-test-id="start-order-review-button"
        style={{
          color: '#474747', // carbon700
          outlineColor: '#474747', // carbon700
        }}
        variant="outline"
        // NOTE: could not get desired results with color="carbon700"
        variantColor="carbon"
        onClick={goToReview}
      >
        Start Review
      </ButtonWriteRequired>
    </Alert>
  );
};
