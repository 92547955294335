import { useQuery } from '@apollo/client';
import { OcrPsaRegionResult } from '@endpoint/opsware-bff-graphql-schema';
import { GET_OCR_PSA_REGIONS, GetOcrPsaRegionsData } from 'consts/getMarketsAndOrganizationsQuery';

export default function useOCRSupport(market?: string) {
  const { data, loading } = useQuery<GetOcrPsaRegionsData>(GET_OCR_PSA_REGIONS, {
    fetchPolicy: 'cache-first',
  });

  const regions = data?.ocrPsaRegions ?? [];

  const isValidMarket = market && regions.some((region: OcrPsaRegionResult) => region.value === market);

  return {
    regions,
    loading,
    isValidMarket,
  };
}
