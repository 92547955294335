import React, { FC, useCallback, useEffect, useRef } from 'react';
import { DrawerSection, Flex, Loading, Text } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { formatCurrencyStringToNumber } from 'helpers/formatText';
import { GET_OCR_PSA_RESULTS } from 'routes/TransactionsList/queries';
import { OCR_DOCUMENT_CONFIDENCE_SAFE_THESHOLD } from 'consts/ocrDocumentConfidenceSafeThreshold';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { isValidDate, toDateEndOfDay } from 'helpers/datetimeHelper';
import { convertOcrProductType } from 'helpers/openOrder/convertOcrProductType';
import { useDocumentReviewContext } from 'routes/DocumentReview/contexts/DocumentReviewContext';
import { OcrResultsStatus } from 'consts/ocrResultsStatus';
import { OpenOrderDrawerForm, OpenOrderDrawerFormType } from 'helpers/openOrder/openOrderFormTypes';

export const OCR_RESULTS_RETRIEVAL_FAILED = 'OCR_RESULTS_RETRIEVAL_FAILED';

interface OcrWaitingScreenProps {
  next(): void;
  setDefaultValue(path: string, value: unknown): void;
  prelimConversion?: boolean;
}

export const OcrWaitingScreen: FC<OcrWaitingScreenProps> = ({ prelimConversion, next, setDefaultValue }) => {
  const timeLeft = useRef(60);
  const { setStatus } = useFormikContext<OpenOrderDrawerForm>();
  const { clearUploads } = useUploadDocument();
  const { documentId } = useDocumentReviewContext();

  const { error, data, stopPolling } = useQuery(GET_OCR_PSA_RESULTS, {
    variables: { where: { documentId } },
    pollInterval: 500,
  });

  const gotToReview = useCallback(() => {
    stopPolling();
    clearUploads();
    next();
  }, [stopPolling, clearUploads, next]);

  // NOTE: 60 SECOND TIMER ON POLLING THEN REDIRECT TO REVIEW
  useEffect(() => {
    const pollingOCRInterval = setInterval(() => {
      timeLeft.current -= 1;

      if (timeLeft.current < 1) {
        setStatus({ error: OCR_RESULTS_RETRIEVAL_FAILED });
        gotToReview();
      }
    }, 1000);

    return () => {
      clearInterval(pollingOCRInterval);
    };
  }, [setStatus, gotToReview]);

  useEffect(() => {
    if (data?.ocrPsaResults?.status === OcrResultsStatus.SUCCEEDED) {
      const results = data.ocrPsaResults.result;

      if (!prelimConversion) {
        setDefaultValue('property.address.street1', results.propertyAddress?.value || '');
        setDefaultValue('property.address.street2', results.propertyUnit?.value || '');
        setDefaultValue('property.address.city', results.propertyCity?.value || '');
        setDefaultValue('property.address.zip', results.propertyZip?.value || '');
        setDefaultValue('property.parcelNumber', results.parcelNumber?.value || '');
        setDefaultValue('property.address.county', results.propertyCounty?.value || '');
        // since the UI in this case is forcing the user to select a state, set the state value to empty string ''
        // by doing this it also causes the form error-state to function properly for a PSA upload order
        setDefaultValue('property.address.state', '');
      }

      // TERMS
      setDefaultValue('terms.loanAmount', results.loanAmount?.value || '');
      setDefaultValue(
        'terms.salePrice',
        results.purchasePrice?.value ? formatCurrencyStringToNumber(results.purchasePrice?.value) : '',
      );

      setDefaultValue('terms.emdAmount', results.earnestMoneyDepositAmount?.value || '');
      setDefaultValue('terms.productType', convertOcrProductType(results.productType?.value as string | null));

      if (results.purchasePrice?.value && results.earnestMoneyDepositAmount?.value) {
        const currencyValueInteger = formatCurrencyStringToNumber(results.earnestMoneyDepositAmount.value);
        const salesPrice = formatCurrencyStringToNumber(results.purchasePrice.value);

        setDefaultValue('terms.emdPercent', ((100 * currencyValueInteger) / salesPrice).toFixed(2));
      }

      // FEES
      setDefaultValue('fees.sellerCreditsBuyer', results.sellerCreditsBuyerAmount?.value || '');

      // DATES
      if (results?.closingDate?.value && isValidDate(results.closingDate.value)) {
        // Setting the time on the date so the local time conversion on the client doesn't change it to previous day.
        setDefaultValue('dates.closingDate', toDateEndOfDay(results.closingDate.value));
      }

      // SET CONFIDENCE SCORES
      setDefaultValue('ocrConfidenceScores.street1', results.propertyAddress?.confidence || '');
      setDefaultValue('ocrConfidenceScores.street2', results.propertyUnit?.confidence || '');
      setDefaultValue('ocrConfidenceScores.city', results.propertyCity?.confidence || '');
      setDefaultValue('ocrConfidenceScores.state', results.propertyState?.confidence || '');
      setDefaultValue('ocrConfidenceScores.zip', results.propertyZip?.confidence || '');
      setDefaultValue('ocrConfidenceScores.county', results.propertyCounty?.confidence || '');
      setDefaultValue('ocrConfidenceScores.parcelNumber', results.parcelNumber?.confidence || '');
      setDefaultValue('ocrConfidenceScores.loanAmount', results.loanAmount?.confidence || '');
      setDefaultValue('ocrConfidenceScores.salePrice', results.purchasePrice?.confidence || '');
      setDefaultValue('ocrConfidenceScores.emdAmount', results.earnestMoneyDepositAmount?.confidence || '');
      setDefaultValue('ocrConfidenceScores.productType', results.productType?.confidence || '');
      setDefaultValue('ocrConfidenceScores.sellerCreditsBuyer', results.sellerCreditsBuyerAmount?.confidence || '');
      setDefaultValue('ocrConfidenceScores.closingDate', results.closingDate?.confidence || '');

      if (results.docTypeConfidence < OCR_DOCUMENT_CONFIDENCE_SAFE_THESHOLD) {
        setStatus({ error: OCR_RESULTS_RETRIEVAL_FAILED });
      }

      setDefaultValue('formType', OpenOrderDrawerFormType.UPLOAD);
      setDefaultValue('documentUploaded', true);
      gotToReview();
    }
  }, [data, setDefaultValue, setStatus, gotToReview, prelimConversion]);

  useEffect(() => {
    if (error) {
      setStatus({ error: OCR_RESULTS_RETRIEVAL_FAILED });
      gotToReview();
    }
  }, [error, setStatus, gotToReview]);

  return (
    <DrawerSection py="50%">
      <Flex alignItems="center" flexDirection="row" justifyContent="center">
        <Loading size="large" />
      </Flex>
      <Flex alignItems="center" flexDirection="row" justifyContent="center">
        <Text color="carbon500" mt="space60" size="fontSize20">
          Reading Document ...
        </Text>
      </Flex>
    </DrawerSection>
  );
};
