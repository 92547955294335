import { ApolloClient, FetchResult, gql, InMemoryCache } from '@apollo/client';
import {
  DocumentCreateInput,
  Document,
  UnassignedDocumentCreateInput,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { FileRejections, DocumentUpload, DocumentUploadStatus, UploadDocument } from 'consts/uploadDocumentsConsts';
import { UPLOAD_DOCUMENT_QUERY } from 'hooks/useDocumentUpload/queries';
import { uploadDocumentVar } from 'apollo/ApolloCache';
import { cloneDeep } from 'lodash';
import { FileUpload } from 'apollo/resolvers/UploadDocument/FileUpload';

export function startUpload(cache: InMemoryCache, documentUpload: DocumentUpload) {
  // always get fresh query data
  const data = cache.readQuery<UploadDocumentResponse>({ query: UPLOAD_DOCUMENT_QUERY });
  const returned = cloneDeep(data);
  const uploads: DocumentUpload[] = returned?.uploadDocument.uploads ?? [];
  const uploadDrawerOpen = returned?.uploadDocument.uploadDrawerOpen ?? true;
  const newData = {
    uploadDrawerOpen,
    uploads: [...uploads, { ...documentUpload, __typename: 'DocumentUpload' }],
  };

  uploadDocumentVar(newData);
}

export function cancelUploadInCache(cache: InMemoryCache, id: string) {
  const data = cache.readQuery<UploadDocumentResponse>({ query: UPLOAD_DOCUMENT_QUERY });

  if (data?.uploadDocument.uploads && data?.uploadDocument.uploads.length) {
    const uploads: DocumentUpload[] = cloneDeep(data.uploadDocument.uploads);
    const targetsIndex = uploads.findIndex((upload) => upload.id === id);

    uploads[targetsIndex].fileRejection = FileRejections.CANCELED;
    uploads[targetsIndex].status = DocumentUploadStatus.ERROR;
    uploads[targetsIndex].progress = 100;
    const newData = {
      uploadDrawerOpen: data.uploadDocument.uploadDrawerOpen,
      uploads,
    };

    uploadDocumentVar(newData);
  }
}

export function clearCachedUploads(cache: InMemoryCache) {
  const data = cache.readQuery<UploadDocumentResponse>({ query: UPLOAD_DOCUMENT_QUERY });

  if (data?.uploadDocument.uploads && data?.uploadDocument.uploads.length) {
    const uploadDrawerOpen = data?.uploadDocument.uploadDrawerOpen ?? true;

    const newData: UploadDocument = {
      uploadDrawerOpen,
      uploads: [] as DocumentUpload[],
    };

    uploadDocumentVar(newData);
  }
}

export function clearCachedUpload(cache: InMemoryCache, id: string) {
  const data = cache.readQuery<UploadDocumentResponse>({ query: UPLOAD_DOCUMENT_QUERY });

  if (data?.uploadDocument.uploads && data?.uploadDocument.uploads.length) {
    const uploadDrawerOpen = data?.uploadDocument.uploadDrawerOpen ?? true;
    const uploads: DocumentUpload[] = cloneDeep(data.uploadDocument.uploads);
    const targets = uploads.filter((upload) => upload.id !== id);
    const newData: UploadDocument = {
      uploadDrawerOpen,
      uploads: targets,
    };

    uploadDocumentVar(newData);
  }
}

export function updateUploadProgress(cache: InMemoryCache, id: string, progress: number) {
  const data = cache.readQuery<UploadDocumentResponse>({ query: UPLOAD_DOCUMENT_QUERY });

  if (data?.uploadDocument && data.uploadDocument.uploads.length) {
    const uploads: DocumentUpload[] = cloneDeep(data.uploadDocument.uploads);
    const targetsIndex = uploads.findIndex((upload) => upload.id === id);

    uploads[targetsIndex].progress = progress;
    const newData = {
      uploadDrawerOpen: data.uploadDocument.uploadDrawerOpen,
      uploads,
    };

    uploadDocumentVar(newData);
  }
}

export function updateUploadFileUpload(cache: InMemoryCache, id: string, fileUpload: FileUpload) {
  const data = cache.readQuery<UploadDocumentResponse>({ query: UPLOAD_DOCUMENT_QUERY });

  if (data?.uploadDocument && data.uploadDocument.uploads.length) {
    const uploads: DocumentUpload[] = cloneDeep(data.uploadDocument.uploads);
    const targetsIndex = uploads.findIndex((upload) => upload.id === id);

    uploads[targetsIndex].fileUpload = fileUpload;
    const newData = {
      uploadDrawerOpen: data.uploadDocument.uploadDrawerOpen,
      uploads,
    };

    uploadDocumentVar(newData);
  }
}

export function uploadProgressDone(cache: InMemoryCache, id: string) {
  const data = cache.readQuery<UploadDocumentResponse>({ query: UPLOAD_DOCUMENT_QUERY });

  if (data?.uploadDocument && data.uploadDocument.uploads.length) {
    const uploads: DocumentUpload[] = cloneDeep(data.uploadDocument.uploads);
    const targetsIndex = uploads.findIndex((upload) => upload.id === id);

    uploads[targetsIndex].progress = 100;
    uploads[targetsIndex].status = DocumentUploadStatus.COMPLETED;
    const newData = {
      uploadDrawerOpen: data.uploadDocument.uploadDrawerOpen,
      uploads,
    };

    uploadDocumentVar(newData);
  }
}

export function uploadDocumentError(cache: InMemoryCache, id: string) {
  const data = cache.readQuery<UploadDocumentResponse>({ query: UPLOAD_DOCUMENT_QUERY });

  if (data?.uploadDocument && data.uploadDocument.uploads.length) {
    const uploads: DocumentUpload[] = cloneDeep(data.uploadDocument.uploads);
    const targetsIndex = uploads.findIndex((upload) => upload.id === id);

    uploads[targetsIndex].progress = 100;
    uploads[targetsIndex].status = DocumentUploadStatus.ERROR;
    const newData = {
      uploadDrawerOpen: data.uploadDocument.uploadDrawerOpen,
      uploads,
    };

    uploadDocumentVar(newData);
  }
}

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateDocument($data: DocumentCreateInput!) {
    createDocument(data: $data) {
      id
      type
      storage {
        url
        uploadUrl
      }
    }
  }
`;

export function createDocument(
  client: ApolloClient<object>,
  data: DocumentCreateInput,
): Promise<FetchResult<CreateDocument | null | undefined>> {
  const response: Promise<FetchResult<CreateDocument | null | undefined>> = client.mutate<
    CreateDocument,
    { data: DocumentCreateInput }
  >({
    mutation: CREATE_DOCUMENT_MUTATION,
    variables: {
      data,
    },
  });

  return response;
}

export async function createUnassignedDocument(
  client: ApolloClient<object>,
  data: UnassignedDocumentCreateInput,
): Promise<FetchResult<CreateUnassignedDocument | null | undefined>> {
  const CREATE_UNASSIGNED_DOCUMENT_MUTATION = gql`
    mutation CreateUnassignedDocument($data: UnassignedDocumentCreateInput!) {
      createUnassignedDocument(data: $data) {
        id
        storage {
          url
          uploadUrl
        }
      }
    }
  `;

  const response = await client.mutate<CreateUnassignedDocument, { data: UnassignedDocumentCreateInput }>({
    mutation: CREATE_UNASSIGNED_DOCUMENT_MUTATION,
    variables: {
      data,
    },
  });

  return response;
}

interface UploadDocumentResponse {
  uploadDocument: UploadDocument;
}

export interface CreateDocument {
  createDocument: Document;
}

interface CreateUnassignedDocument {
  createUnassignedDocument: Document;
}
