import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Flex, IconButton, useToast } from '@endpoint/blockparty';
import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { giveFirstWord } from 'helpers/formatText';
import { TrackingEvent, DocumentAssignmentChange } from 'consts/segmentProtocols';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { REMOVE_DOCUMENT_PARTICIPANT, UpdatedDocument, GET_DOCUMENTS } from 'routes/Transaction/Documents/queries';
import { navigate } from '@reach/router';
import { buildQueryInput } from 'helpers/buildQueryInput';
import { AllContactTypes } from 'consts/contactDetails';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ParticipantItem } from '../ParticipantItem';

interface DeliveredToItemProps {
  documentId: string;
  documentName: string;
  transactionIdentifier: string;
  participant: TransactionParticipant;
  documentType?: string;
}

export const DeliveredToItem: FC<DeliveredToItemProps> = ({
  documentId,
  documentName,
  transactionIdentifier,
  participant,
  documentType,
}) => {
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const toast = useToast();
  const { isRemoveParticipantsFromPsaEnabled } = useFlags();

  const trackingEvent: TrackingEvent = 'Document Assignment Removed';
  const trackingData: DocumentAssignmentChange = {
    assignee: participant.id,
    fileNumber: transactionIdentifier,
    name: documentName,
    id: documentId,
  };

  const { firstName, lastName, entityName } = participant.contact as AllContactTypes;
  const avatarName = entityName
    ? giveFirstWord(entityName)
    : `${giveFirstWord(firstName || '')} ${giveFirstWord(lastName || '')}`;
  const fullName = entityName || `${firstName} ${lastName}`;
  const docType = documentType?.toLowerCase();

  const mutationHandler = {
    onError(): void {
      toast({
        description: 'Action was not successful',
        duration: 5000,
        iconColor: 'watermelon500',
        icon: 'ErrorCircle',
      });
    },
    onCompleted(): void {
      toast({
        description: 'Document recalled',
        duration: 5000,
        icon: 'CheckCircle',
      });

      trackAnalytics(trackingEvent, trackingData);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(`/transaction/${transactionIdentifier}/documents`);
    },
  };

  const querytInput = buildQueryInput(transactionIdentifier);
  const [removeParticipant] = useMutation<UpdatedDocument>(REMOVE_DOCUMENT_PARTICIPANT, {
    refetchQueries: [
      {
        query: GET_DOCUMENTS,
        variables: {
          where: querytInput,
        },
      },
    ],
    awaitRefetchQueries: true,
    ...mutationHandler,
  });

  const handleRemoveParticipant = (docId: string, participantId: string): void => {
    void removeParticipant({
      variables: {
        where: { id: docId },
        forParticipants: [{ id: participantId }],
      },
    });
  };

  const handleShowCloseIcon = () => {
    if (docType?.includes('purchase agreement') || docType === 'psa') {
      return isRemoveParticipantsFromPsaEnabled;
    }

    return true;
  };

  const isShowCloseIcon = handleShowCloseIcon();

  return (
    <Box pb="space30">
      <Flex
        _hover={{ backgroundColor: 'carbon0' }}
        alignItems="center"
        data-test-id={`assignee-box-${participant.id}`}
        justifyContent="space-between"
        onMouseEnter={(): void => {
          setShowCloseIcon(isShowCloseIcon);
        }}
        onMouseLeave={(): void => setShowCloseIcon(false)}
      >
        <ParticipantItem avatarName={avatarName} fullName={fullName} roles={participant.roles} />
        {showCloseIcon && (
          <IconButton
            data-test-id="remove-participant-button"
            label="Remove User"
            mr="space60"
            name="Close"
            variant="unstyled"
            variantColor="carbon"
            onClick={(): void => {
              handleRemoveParticipant(documentId, participant.id);
            }}
          />
        )}
      </Flex>
    </Box>
  );
};

DeliveredToItem.displayName = 'DeliveredToItem';
