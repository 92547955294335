import { Drawer, DrawerContent, Flex } from '@endpoint/blockparty';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema';
import { Formik } from 'formik';
import React, { FC } from 'react';
import { OpenOrderDrawerForm, OpenOrderDrawerFormType } from 'helpers/openOrder/openOrderFormTypes';
import { emptyOpenOrderDrawerForm } from 'helpers/openOrder/emptyOpenOrderDrawerForm';

import { EscrowConversionDrawerContent } from './EscrowConversionDrawerContent';

interface EscrowConversionDrawerProps {
  isOpen: boolean;
  setDrawerIsOpen: (value: boolean) => void;
  transaction: Transaction;
  title?: string;
}

export const initialEscrowConversionValues: OpenOrderDrawerForm = emptyOpenOrderDrawerForm({
  formType: OpenOrderDrawerFormType.UPLOAD,
});

export const EscrowConversionDrawer: FC<EscrowConversionDrawerProps> = ({
  isOpen,
  setDrawerIsOpen,
  transaction,
  title = 'Open Escrow',
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={() => setDrawerIsOpen(false)}>
      <DrawerContent>
        <Flex alignContent="center" flex={1} flexDirection="column">
          <Formik
            initialValues={{
              ...initialEscrowConversionValues,
              transactionId: transaction.id,
              organization: transaction.organizationId,
              isTest: transaction.isTest,
              property: { ...transaction.property },
              transactionStatus: transaction.status,
            }}
            onSubmit={() => {}}
          >
            <EscrowConversionDrawerContent setDrawerIsOpen={setDrawerIsOpen} title={title} />
          </Formik>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};
