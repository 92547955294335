import React, { FC } from 'react';
import { Box, Button, Tooltip } from '@endpoint/blockparty';
import { useHasWriteAccess } from 'hooks/useHasWriteAccess';
import { ButtonProps } from '@endpoint/blockparty/dist/components/Button';

/**
 * This component can be used as a button where WRITE ACCESS is required.
 * If the user is READ ONLY, it will render a disabled button and display a Tooltip message.
 * If the user has WRITE ACCESS it will render the normal button as expected.
 */

interface ButtonWriteRequiredProps extends ButtonProps {}

const message = 'The current user does not have write permissions.';
const carbon700 = '#474747';
const carbon500 = '#757575';
const carbon0 = '#f7f7f7';

export const ButtonWriteRequired: FC<ButtonWriteRequiredProps> = (props) => {
  const hasWriteAccess = useHasWriteAccess();

  const { isDisabled, children, ...rest } = props;

  return !hasWriteAccess ? (
    <Tooltip label={message}>
      <Box>
        <Box pointerEvents="none">
          <Button
            isDisabled
            {...rest}
            style={{
              ...rest.style,
              color: carbon0,
              outlineColor: carbon500,
              backgroundColor: carbon500,
            }}
          >
            {children}
          </Button>
        </Box>
      </Box>
    </Tooltip>
  ) : (
    <Button
      {...props}
      style={{
        ...rest.style,
        color: isDisabled ? carbon0 : carbon700,
        outlineColor: isDisabled ? carbon500 : carbon700,
        backgroundColor: isDisabled ? carbon500 : '',
      }}
    >
      {children}
    </Button>
  );
};
