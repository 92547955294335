import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema';
import { AllContactTypes } from 'consts/contactDetails';
import { isEntity } from 'helpers/contacts';

const makeUpperCase = (string: string): string => string.toUpperCase();
const makeLowerCase = (string: string): string => string.toLowerCase();

function removeNonWord(str: string): string {
  return str.replace(/[^0-9a-zA-Z\xC0-\xFF -]/g, '');
}

export const giveFirstWord = (string: string | undefined): string => {
  if (!string) return '';

  return string.replace(/ .*/, '');
};

export function formatRolesLikeTitle(string: string | null | undefined, isDisplayedAside: boolean = false): string {
  let returnValue = formatLikeTitle(string);

  const replaceMap: { [role: string]: string } = {
    'Proptech Buyer': 'Buyer',
    'Proptech Seller': 'Seller',
  };

  if (isDisplayedAside) {
    replaceMap['Agent Transaction Coordinator'] = 'TC';
  } else {
    replaceMap['Agent Transaction'] = 'Transaction';
  }

  Object.keys(replaceMap).forEach((searchRole) => {
    const regex = new RegExp(searchRole, 'g');

    returnValue = returnValue.replace(regex, replaceMap[searchRole]);
  });

  return returnValue;
}

export function formatLikeTitle(string: string | null | undefined): string {
  if (!string) return '';
  let returnValue = string
    .replace(/[-_]/g, ' ')
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    .replace(/^"|"$/g, '');

  returnValue = makeLowerCase(returnValue);

  return returnValue.replace(/^\w|\s\w/g, makeUpperCase);
}

export function formatNotificationType(string: string | null | undefined): string {
  if (!string) return '';

  return string.replace(/sms/i, makeUpperCase);
}

export function formatToCamelCase(string: string): string {
  const returnString = removeNonWord(string)
    .replace(/-/g, ' ') // convert all hyphens to spaces
    .replace(/\s[a-z]/g, makeUpperCase) // convert first char of each word to UPPERCASE
    .replace(/\s+/g, '') // remove spaces
    .replace(/^[A-Z]/g, makeLowerCase); // convert first char to lowercase

  return returnString;
}

export function formatMimeType(mimeType: string): string {
  return mimeType.split('/')[1];
}

export function formatCurrency(value: number = 0): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(value);
}

export function formatField(value: string | number): string {
  if (value) {
    if (typeof value === 'number') {
      return formatCurrency(value);
    }

    return formatLikeTitle(value);
  }

  return '-';
}

export function trimFileExtension(name: string) {
  return name.replace(/\.[^/.]+$/, '');
}

export function formatCurrencyStringToNumber(value: string | number): number {
  if (typeof value === 'number') {
    return Number.isNaN(value) ? 0 : value;
  }

  const formatValue = parseInt(value.replace(/,/g, ''), 10);

  return Number.isNaN(formatValue) ? 0 : formatValue;
}

export function getContactName({ contact, partial = true }: { contact: AllContactTypes; partial?: boolean }): string {
  // if partial is true, return only first name and last name
  const isContactEntity = 'entityName' in contact;
  const { firstName, middleName, lastName, entityName } = contact;

  let name: string;

  if (partial) {
    name = (isContactEntity ? entityName : `${firstName} ${lastName}`.trim()) || '';
  } else {
    name =
      (isContactEntity ? entityName : `${firstName} ${middleName || ''} ${lastName}`.replace(/  +/g, ' ').trim()) || '';
  }

  return name;
}

export function getParticipantName({
  participant,
  partial = true,
}: {
  participant: TransactionParticipant;
  partial?: boolean;
}): string {
  const { contactType, firstName, middleName, lastName, entityName } = participant;
  const isParticipantEntity = isEntity(contactType || '');

  let name: string;

  if (partial) {
    name = (isParticipantEntity ? entityName : `${firstName} ${lastName}`.trim()) || '';
  } else {
    name =
      (isParticipantEntity ? entityName : `${firstName} ${middleName || ''} ${lastName}`.replace(/  +/g, ' ').trim()) ||
      '';
  }

  return name;
}
