import { gql } from '@apollo/client';

export const UPLOAD_DOCUMENT_QUERY = gql`
  query GetUploadDocuments {
    uploadDocument @client {
      uploadDrawerOpen
      uploads {
        id
        status
        progress
        name
        fileRejection
        documentType
        fileUpload
      }
    }
  }
`;

export const UPLOAD_DOCUMENT_MUTATION = gql`
  mutation uploadDocument($file: JSON!, $transactionId: String!, $documentType: String!) {
    uploadDocument(file: $file, id: $id, transactionId: $transactionId, documentType: $documentType) @client {
      uploadDocument {
        uploads {
          id
          status
          progress
          name
          fileRejection
          documentType
          fileUpload
        }
      }
    }
  }
`;

export const UPLOAD_UNASSIGNED_DOCUMENT_MUTATION = gql`
  mutation uploadUnassignedDocument($file: JSON!, $documentType: String!, $region: String!, $escrowNote: String) {
    uploadUnassignedDocument(file: $file, documentType: $documentType, region: $region, escrowNote: $escrowNote)
      @client {
      uploadUnassignedDocument {
        uploads {
          id
          status
          progress
          name
          fileRejection
          documentType
          fileUpload
        }
      }
    }
  }
`;

export const CANCEL_UPLOAD_DOCUMENT_MUTATION = gql`
  mutation CancelUploadDocument($id: String!) {
    cancelUploadDocument(id: $id) @client
  }
`;

export const CLEAR_UPLOADS_MUTATION = gql`
  mutation ClearUploads($uploads: Array!) {
    clearUploads(uploads: $uploads) @client
  }
`;
