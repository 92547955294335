import { useQuery, useApolloClient } from '@apollo/client';
import { uploadDocumentVar, cache } from 'apollo/ApolloCache';
import { UploadDocument, DocumentUpload } from 'consts/uploadDocumentsConsts';

import { cancelUploadInCache, clearCachedUpload, clearCachedUploads } from './utils/apolloClientHelpers';
import { UPLOAD_DOCUMENT_QUERY } from './queries';
import { uploadDocumentToTransaction } from './utils/uploadDocumentToTransaction';
import { uploadUnassignedDocument } from './utils/uploadUnassignedDocument';

export interface UseUploadDocumentProps {
  data?: UploadDocument;
  toggleUploadDrawer: () => void;
  uploadDocument: (file: File, transactionId: string, documentType: string) => void;
  uploadUnassignedDocument: (uploadUnassignedDocumentInput: UploadUnassignedDocumentInput) => void;
  cancelUpload: (id: string) => void;
  clearUpload: (id: string) => void;
  clearUploads: () => void;
}

export function useUploadDocument(): UseUploadDocumentProps {
  const { data } = useQuery<{ uploadDocument: UploadDocument }>(UPLOAD_DOCUMENT_QUERY);
  const client = useApolloClient();

  const uploadDocument = data?.uploadDocument;
  const isUploadDrawerOpen = !!data?.uploadDocument.uploadDrawerOpen;

  return {
    data: uploadDocument,
    toggleUploadDrawer: () => {
      const uploadUpdateData: UploadDocument = {
        uploadDrawerOpen: !isUploadDrawerOpen,
        uploads: uploadDocument ? uploadDocument.uploads : ([] as DocumentUpload[]),
      };

      uploadDocumentVar(uploadUpdateData);
    },
    uploadDocument: (file: File, transactionId: string, documentType: string) => {
      void uploadDocumentToTransaction({ file, transactionId, documentType }, client);
    },
    uploadUnassignedDocument: (uploadUnassignedDocumentInput: UploadUnassignedDocumentInput) => {
      void uploadUnassignedDocument(uploadUnassignedDocumentInput, client);
    },
    cancelUpload: (id: string) => {
      const upload = uploadDocument?.uploads.find((documentUpload) => documentUpload.id === id);

      upload?.fileUpload?.cancel();

      cancelUploadInCache(cache, id);
    },
    clearUpload: (id: string) => {
      clearCachedUpload(cache, id);
    },
    clearUploads: () => {
      clearCachedUploads(cache);
    },
  };
}

export interface UploadUnassignedDocumentInput {
  file: File;
  name: string;
  type: string;
  region: string;
  escrowNote?: string;
}
