import { gql } from '@apollo/client';
import { IQuery, TransactionSearchResult, Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_OPEN_TRANSACTIONS = gql`
  query searchTransactions($search: TransactionsMultiMarketSearchInput) {
    searchTransactions(search: $search) {
      id
      createdAt
      fileNum
      status
      isOpsReviewComplete
      qualityControlStatus
      source
      property {
        address {
          standardFormat
          county
        }
      }
      dates {
        estimatedClosing
      }
      type
    }
  }
`;

export interface TransactionUpdateResult {
  updateTransaction: Transaction;
}
export interface TransactionSearchResults {
  searchTransactions: TransactionSearchResult[];
}

export interface SearchTransactions {
  transactions: IQuery['searchTransactions'];
}
