import { formatCurrencyStringToNumber } from 'helpers/formatText';

export function castToNumberHelper(value: string | number | undefined) {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    return formatCurrencyStringToNumber(value);
  }

  return undefined;
}
