import { Formik, FormikErrors, FormikState } from 'formik';
import React, { FC, useEffect } from 'react';
import { navigate } from '@reach/router';
import { ApolloClient, useQuery } from '@apollo/client';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { emptyOpenOrderDrawerForm } from 'helpers/openOrder/emptyOpenOrderDrawerForm';
import { OpswareUser } from 'components/Auth/helpers';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';
import { OrganizationType } from 'consts/organizationConsts';
import { OpenOrderDrawerForm, OpenOrderDrawerFormType } from 'helpers/openOrder/openOrderFormTypes';
import formValidationSchema from 'helpers/openOrder/formValidationSchema';

import { createTransaction } from './createTransactionMutation';
import { OpenOrderDrawerForms } from './OpenOrderDrawerForms';

export const CREATE_TRANSACTION_MUTATION_FAILED = 'CREATE TRANSACTION MUTATION FAILED';

export const initialError = {
  property: 'Required',
} as FormikErrors<OpenOrderDrawerForm>;

interface OpenOrderDrawerProps {
  isOpen: boolean;
  setDrawerIsOpen: (value?: boolean) => void;
  client: ApolloClient<object>;
}

export const formInitialValues: OpenOrderDrawerForm = emptyOpenOrderDrawerForm();

export const handleFormikOpenOrderSubmit = async (
  values: OpenOrderDrawerForm,
  {
    resetForm,
    setSubmitting,
    setStatus,
    client,
    clearUploads,
  }: {
    resetForm: (nextState?: Partial<FormikState<OpenOrderDrawerForm>> | undefined) => void;
    setSubmitting: (isSubmitting: boolean) => void;
    setStatus: (status?: { error: string }) => void;
    client: ApolloClient<object>;
    clearUploads: () => void;
  },
) => {
  const castValues = formValidationSchema.cast(values) as OpenOrderDrawerForm;
  const response = await createTransaction(castValues, client);

  if (!response?.data?.createTransaction.id) {
    setStatus({ error: CREATE_TRANSACTION_MUTATION_FAILED });
    setSubmitting(false);

    return;
  }

  trackAnalytics('New Order Submitted', {
    data: { ...castValues },
  });

  setSubmitting(false);

  clearUploads();
  resetForm();
  await navigate(`/transaction/id-${response.data.createTransaction.id}`);
};

export const OpenTransactionDrawer: FC<OpenOrderDrawerProps> = ({ isOpen, setDrawerIsOpen, client }) => {
  const { clearUploads } = useUploadDocument();
  const { data } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);

  const hasMultiOrgAccess = data?.me.hasMultiOrganizationAccess;

  useEffect(() => {
    formInitialValues.formType = OpenOrderDrawerFormType.UPLOAD;

    if (hasMultiOrgAccess === false) {
      formInitialValues.organization = data?.me.organization?.[0] || OrganizationType.ENDPOINT_CLOSING;
    }
  }, [data, hasMultiOrgAccess]);

  return (
    <Formik
      initialErrors={initialError}
      initialStatus={{ error: '' }}
      initialValues={formInitialValues}
      validateOnChange
      validationSchema={formValidationSchema}
      onReset={() => {
        setDrawerIsOpen(false);
      }}
      onSubmit={async (values, { resetForm, setSubmitting, setStatus }) =>
        handleFormikOpenOrderSubmit({ ...values }, { resetForm, setSubmitting, setStatus, client, clearUploads })
      }
    >
      <OpenOrderDrawerForms isOpen={isOpen} setDrawerIsOpen={setDrawerIsOpen} />
    </Formik>
  );
};
