import React, { FC } from 'react';
import { Button, Modal, ModalContent, Text, ModalFooter, ModalSection } from '@endpoint/blockparty';
import { navigate } from '@reach/router';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { useQueryParams } from 'helpers/utils/queryParams';

interface DiscardOrderModalType {
  onClose(): void;
  isOpen: boolean;
}

export const DiscardOrderModal: FC<DiscardOrderModalType> = ({ isOpen, onClose }) => {
  const { currentParams } = useQueryParams();
  const { transactionId } = currentParams;
  const { clearUploads } = useUploadDocument();

  return isOpen ? (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent zIndex={2}>
        <ModalSection>
          <Text as="h1" fontWeight="semi" size="fontSize30">
            Discard order?
          </Text>
          <Text as="p" pt="space50" size="fontSize20">
            Progress will not be saved
          </Text>
        </ModalSection>
        <ModalFooter>
          <Button data-test-id="document-review-modal-keep-editing" mr="space50" variant="outline" onClick={onClose}>
            Keep editing
          </Button>
          <Button
            data-test-id="document-review-modal-cancel"
            onClick={() => {
              clearUploads();

              if (transactionId) {
                void navigate(`/transaction/id-${transactionId}`);
              } else {
                void navigate('/transactions');
              }
            }}
          >
            Discard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : null;
};
