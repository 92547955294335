import React, { FC } from 'react';
import { Heading, Flex } from '@endpoint/blockparty';
import { InternalNote } from 'components/InternalNote';
import { NoteEntityType, TransactionNote } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { AddNote } from 'components/AddNote';
import { sortPinnedInternalNotesByDate } from 'helpers/internalNotes';

interface InternalNotesProps {
  notes: TransactionNote[];
  transactionId: string;
}

export const InternalNotes: FC<InternalNotesProps> = ({ notes, transactionId }) => {
  const sortedNotes: TransactionNote[] = sortPinnedInternalNotesByDate(notes || []);

  return (
    <Flex data-test-id="interal-notes" flexDirection="column">
      <Heading as="h5" mb="space60" size="fontSize40">
        Internal Notes
      </Heading>
      <AddNote entityType={NoteEntityType.TRANSACTION} isCalendarPage transactionId={transactionId} />
      <Flex flexDirection="column">
        {sortedNotes.length > 0 &&
          sortedNotes.map((note: TransactionNote) => (
            <InternalNote
              key={note.id}
              entityType={NoteEntityType.TRANSACTION}
              isBasicInfo={false}
              note={note}
              transactionId={transactionId}
            />
          ))}
      </Flex>
    </Flex>
  );
};
