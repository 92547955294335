import React from 'react';
import { Box, Flex, Icon, SkeletonBlock } from '@endpoint/blockparty';

export function InternalNoteLoader() {
  return (
    <Box mb="space40">
      <Flex alignItems="center" mb="space20">
        <SkeletonBlock borderRadius="50%" height={24} mr="space40" width={24} />
        <SkeletonBlock height={12} width="91px" />
      </Flex>
      <Flex flexDirection="column" pl="36px">
        <SkeletonBlock height={12} mb="space20" />
        <Flex alignItems="center">
          <SkeletonBlock height={12} width="48px" />
          <Icon color="carbon500" name="Bullet" size="medium" />
          <SkeletonBlock height={12} width="48px" />
        </Flex>
      </Flex>
    </Box>
  );
}
