import { gql } from '@apollo/client';
import { QualityControlFailureOption } from '@endpoint/opsware-bff-graphql-schema';

export const GET_QC_FAILURE_OPTIONS = gql`
  query qualityControlFailureOptions($input: QualityControlFailureOptionsInput) {
    qualityControlFailureOptions(input: $input) {
      value
      label
    }
  }
`;

export interface GetQCFailureOptions {
  qualityControlFailureOptions: QualityControlFailureOption[] | Promise<QualityControlFailureOption[]>;
}
