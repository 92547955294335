import React from 'react';
import { ColumnInstance } from 'react-table';
import { getColumnSortIcon } from 'helpers/table';
import { Flex, Icon } from '@endpoint/blockparty';

type DataItem = {};

export interface GenericColumnHeaderProps<T extends DataItem> {
  column: ColumnInstance<T>;
  onClick: () => void;
}

const GenericColumnHeader = <T extends DataItem>({ column, onClick }: GenericColumnHeaderProps<T>) => {
  const { canSort } = column;

  if (!canSort) {
    return <>{column.render('Header')}</>;
  }

  return (
    <Flex
      alignItems="center"
      data-test-id={`sort-column-header-${column.id}`}
      justifyContent="space-between"
      width="100%"
      onClick={onClick}
    >
      {column.render('Header')}
      {/* Sort direction indicator */}
      {column.canSort && (
        <Icon color="carbon400" name={getColumnSortIcon(column.isSorted, column.isSortedDesc)} size="large" />
      )}
    </Flex>
  );
};

export default GenericColumnHeader;
