import React, { FC } from 'react';
import { Checkbox, CheckboxGroup, DatePicker, LayoutLeftPanel, Text, Box } from '@endpoint/blockparty';
import { startOfDay, formatISO } from 'date-fns';
import './minicalendar-styles.scss';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { useCalendarFilters } from 'helpers/calendar';

interface CalendarLeftPanelParams {
  initialDate: Date;
}

export const CalendarLeftPanel: FC<CalendarLeftPanelParams> = ({ initialDate }) => {
  const { navigateWithParams, currentParams } = useCalendarFilters();

  return (
    <LayoutLeftPanel borderColor="carbon200" borderRight="1px solid" p="space60">
      <Box className="calendar-datepicker" mb="space60">
        <DatePicker
          inline
          placeholderText="Select Date"
          selected={initialDate}
          onChange={(newDate: Date) => {
            navigateWithParams({ date: formatISO(startOfDay(newDate)) });
            trackAnalytics('Mini Calendar Clicked', { event: 'Mini Calendar Clicked' });
          }}
        />
      </Box>
      <Box>
        <Text fontWeight="semi" size="fontSize20">
          Event Types
        </Text>
        <CheckboxGroup pt="space60">
          <Checkbox
            colorVariant="blue"
            data-test-id="checkbox-open-transactions"
            isChecked={currentParams.showOpenTransactions}
            value="Open Transactions"
            onChange={() =>
              navigateWithParams({
                showOpenTransactions: !currentParams.showOpenTransactions,
              })
            }
          >
            Open Transactions
          </Checkbox>
          <Checkbox
            colorVariant="carbon"
            data-test-id="checkbox-closed-transactions"
            isChecked={currentParams.showClosedTransactions}
            value="Closed Transactions"
            onChange={() =>
              navigateWithParams({
                showClosedTransactions: !currentParams.showClosedTransactions,
              })
            }
          >
            Closed Transactions
          </Checkbox>
          <Checkbox
            colorVariant="yellow"
            data-test-id="checkbox-signings"
            isChecked={currentParams.showSignings}
            value="Signings"
            onChange={() =>
              navigateWithParams({
                showSignings: !currentParams.showSignings,
              })
            }
          >
            Signings
          </Checkbox>
        </CheckboxGroup>
      </Box>
    </LayoutLeftPanel>
  );
};

DatePicker.displayName = 'DatePicker';
