import React, { FC } from 'react';
import { Avatar, Flex, Text, Box } from '@endpoint/blockparty';
import { formatRolesLikeTitle } from 'helpers/formatText';
import { TransactionRole } from '@endpoint/opsware-bff-graphql-schema/dist/types';

interface ParticipantItemProps {
  avatarName: string;
  fullName: string;
  roles: TransactionRole[];
  py?: string;
  px?: string;
}

export const ParticipantItem: FC<ParticipantItemProps> = ({ avatarName, fullName, roles, py, px }) => (
  <Flex
    alignItems="center"
    data-test-id={`participant-item-${avatarName}`}
    px={px || 'space50'}
    py={py || '6px'}
    width="100%"
  >
    <Box alignSelf="flex-start">
      <Avatar name={avatarName} size={24} src="" />
    </Box>
    <Flex flexDirection="column">
      <Text
        maxWidth="300px"
        ml="space40"
        overflow="hidden"
        size="fontSize20"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {fullName}
      </Text>
      <Text
        color="carbon500"
        maxWidth="350px"
        ml="space40"
        overflow="hidden"
        size="fontSize10"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {formatRolesLikeTitle(roles.join(', '), true)}
      </Text>
    </Flex>
  </Flex>
);

ParticipantItem.displayName = 'ParticipantItem';
