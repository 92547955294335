import { useQuery } from '@apollo/client';
import { Box, Card, Flex, Heading, LayoutContent, LayoutSubContent, Text } from '@endpoint/blockparty';
import { RouteComponentProps } from '@reach/router';
import { OrganizationSelect } from 'components/FilterOrganizationSelect';
import { filterMarkets } from 'helpers/adminMarkets';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, useMemo, useState } from 'react';
import { Dogs404 } from 'routes/404';
import { ErrorMessage } from 'components/ErrorMessage';
import { Layout } from 'components/Layout';
import { SearchInputForm } from 'components/SearchBar';
import { SkeletonTable } from 'components/SkeletonTable';
import { useQueryParams } from 'helpers/utils/queryParams';
import { useIsSystemOrOpsAdmin } from 'hooks/useIsSystemOrOpsAdmin';
import { Dogs403 } from 'routes/403';

import MarketsTable from './MarketsTable';
import { GetMarketsQueryResult, GET_MARKETS } from './queries';
import StatusSelect from './StatusSelect';

export interface MarketsProps extends RouteComponentProps {}

const Markets: FC<MarketsProps> = () => {
  const { canViewMarketsPage, canSearchMarketsTable, canFilterMarketsTable } = useFlags();
  const { isAdmin } = useIsSystemOrOpsAdmin();
  const { loading, data, error } = useQuery<GetMarketsQueryResult>(GET_MARKETS);
  const { currentParams, navigateWithParams } = useQueryParams(
    (params: { organizationIds: number[]; search: string; status: boolean[] }) => ({
      search: params.search ?? '',
      status: [params.status ?? []].flat(1),
      organizationIds: [params.organizationIds ?? []].flat(1).map(Number),
    }),
  );
  const { search, organizationIds } = currentParams;

  const [searchTerm, updateSearchTerm] = useState(search);
  const [filterOrganization, setFilterOrganization] = useState<number[]>(organizationIds);
  const [selectedStatusOption, setSelectedStatusOption] = useState<boolean[]>([]);

  const filteredMarkets = useMemo(() => {
    return filterMarkets(
      {
        searchTerm,
        selectedStatusOption,
        filterOrganization,
      },
      data?.markets,
    );
  }, [data?.markets, filterOrganization, searchTerm, selectedStatusOption]);

  if (!canViewMarketsPage) {
    return <Dogs404 />;
  }

  if (!isAdmin) {
    return <Dogs403 />;
  }

  return (
    <Layout>
      <LayoutContent width="100%">
        <LayoutSubContent minHeight="calc(100vh - 56px)" p="space60">
          <Flex alignItems="center" justifyContent="space-between">
            <Heading as="h4" color="carbon900" fontWeight="normal" pb="space60" size="fontSize50">
              <Text fontWeight="semi" size="fontSize50">
                Markets
              </Text>
            </Heading>
            {canFilterMarketsTable && (
              <Box ml="space50">
                <OrganizationSelect
                  defaultValue={organizationIds.map(String)}
                  onChange={(newOrganizationIds) => {
                    setFilterOrganization(newOrganizationIds);
                    navigateWithParams({ organizationIds: newOrganizationIds });
                  }}
                />
              </Box>
            )}
          </Flex>

          <Flex alignItems="center" minWidth="77%">
            <Flex alignItems="center" pb="space60" width="100%">
              {canSearchMarketsTable && (
                <SearchInputForm
                  disabled={loading}
                  handleSubmit={(): void => {
                    navigateWithParams({ search: searchTerm });
                  }}
                  placeholder="Search by name"
                  value={searchTerm}
                  onSearchTermChange={(term: string) => {
                    updateSearchTerm(term);
                  }}
                />
              )}
              {canFilterMarketsTable && (
                <Box data-test-id="status-select" ml="space40">
                  <StatusSelect onChange={setSelectedStatusOption} />
                </Box>
              )}
            </Flex>
          </Flex>
          <Card variant="shadow">
            {loading && <SkeletonTable />}
            {error && (
              <ErrorMessage
                description="Please refresh the page. Contact OpsWare product team for assistance if this issue persists."
                title="Unable to load the Markets"
              />
            )}
            {filteredMarkets && <MarketsTable markets={filteredMarkets} />}
          </Card>
        </LayoutSubContent>
      </LayoutContent>
    </Layout>
  );
};

export default Markets;
