import React from 'react';
import { Box, Card } from '@endpoint/blockparty';

import { CalendarDayLoader } from './CalendarDayLoader';

export function CalendarLoading() {
  return (
    <Card m="space60">
      <Box>
        <CalendarDayLoader />
        <CalendarDayLoader />
        <CalendarDayLoader />
      </Box>
    </Card>
  );
}
