import React, { FC } from 'react';
import { Todo } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { AccordionSection } from 'components/AccordionSection';

import { ToDoCard } from './ToDoCard/index';

interface TodoContentProps {
  transactionIdentifier: string;
  todo: Todo;
  taskId: string;
}

export const TodoContent: FC<TodoContentProps> = ({ taskId, transactionIdentifier, todo }) => (
  <AccordionSection dataTestId="content" hasDivider isOpen={false} title="To-Do Content">
    <ToDoCard data-test-id="todo-card" taskId={taskId} todo={todo} transactionIdentifier={transactionIdentifier} />
  </AccordionSection>
);

TodoContent.displayName = 'Content';
