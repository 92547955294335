import React, { FC } from 'react';
import { Box, MultiSelect } from '@endpoint/blockparty';
import { TransactionType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { transactionTypeMap } from 'consts/transactionConsts';
import { SelectStyleProps } from '@endpoint/blockparty/dist/components/Select/styles';
import { useGetActiveTransactionTypes } from 'hooks/useGetActiveTransactionTypes';

interface OfficeSelectProps {
  onChange(options: TransactionType[]): void;
  defaultValue: TransactionType[];
}

export const ProductTypeSelect: FC<OfficeSelectProps> = ({ onChange, defaultValue }) => {
  const multiSelectOptions = Object.values(useGetActiveTransactionTypes()).map((transactionType) => ({
    value: transactionType,
    label: transactionTypeMap[transactionType],
  }));

  const defaultValueOptions = multiSelectOptions.filter((option) => {
    return defaultValue.includes(option.value);
  });

  return (
    <Box minWidth={240}>
      <MultiSelect
        controlText="Product Type"
        customStyles={{
          menu: (base: SelectStyleProps) => ({
            ...base,
            minWidth: 180,
            right: 0,
          }),
        }}
        data-test-id="open-order-product-type-select"
        defaultValue={defaultValueOptions}
        options={multiSelectOptions}
        onChange={(values: { value: TransactionType }[]) => onChange(values.map((value) => value.value))}
      />
    </Box>
  );
};
