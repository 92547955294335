import React, { FC, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerActionBar,
  DrawerSection,
  Heading,
  Modal,
  ModalContent,
  ModalSection,
  ModalFooter,
  Select,
  Text,
  useDisclosure,
  Flex,
} from '@endpoint/blockparty';
import { useUploadDocument } from 'hooks/useDocumentUpload';
import { DocumentUploadStatus, DocumentUpload } from 'consts/uploadDocumentsConsts';

import { DocumentUploadWidget } from './DocumentUploadWidget';
import { FileUploadProgress } from './FileUploadProgress';

interface DocumentUploadDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  transactionId: string;
}

export const DocumentUploadDrawer: FC<DocumentUploadDrawerProps> = ({ isOpen, onClose, transactionId }) => {
  const { data, cancelUpload, clearUploads, toggleUploadDrawer } = useUploadDocument();
  const [uploads, setUploads] = useState(data?.uploads || []);
  const { isOpen: isModalOpen, onOpen: onCancelUploadModalOpen, onClose: onCancelUploadModalClose } = useDisclosure();

  useEffect(() => {
    if (data?.uploads) setUploads(data.uploads);
  }, [data]);

  const handleDrawerCloseBehavior = () => {
    // if any uploads are INPROGRESS, open cancel modal
    if (uploads.some((upload) => upload.status === DocumentUploadStatus.INPROGRESS)) onCancelUploadModalOpen();
    // otherwise trigger drawer closed and clear uploads
    else {
      toggleUploadDrawer();
      uploads.map((upload: DocumentUpload) => cancelUpload(upload.id));
      clearUploads();
      onClose();
      setUploads([]);
    }
  };

  const handleCancelUploads = (): void => {
    uploads.map((upload) => cancelUpload(upload.id));
    onCancelUploadModalClose();
    toggleUploadDrawer();
    clearUploads();
    onClose();
    setUploads([]);
  };

  return (
    <>
      <Drawer
        data-test-id="document-upload-drawer"
        id="document-upload-drawer"
        isOpen={isOpen}
        placement="right"
        onClose={handleDrawerCloseBehavior}
      >
        <DrawerContent>
          <DrawerActionBar>
            <DrawerCloseButton />
          </DrawerActionBar>
          <DrawerSection>
            <Heading as="h3" pb="space40" size="fontSize50">
              Upload Document
            </Heading>
            <Text as="p" size="fontSize20">
              Maximum upload file size is 50MB. Supports PDF, JPG, and JPEG only.
            </Text>
          </DrawerSection>
          <DrawerSection height="100%" overflow="auto">
            <Box data-test-id="progress-container">
              {uploads.length > 0
                ? uploads.map((upload: DocumentUpload) => (
                    <Box key={upload.id} pb="space70">
                      <Text color="carbon500" pb="space20" size="fontSize10">
                        Document Type *
                      </Text>
                      <Select isDisabled placeholder={upload.documentType} />
                      <FileUploadProgress document={upload} />
                    </Box>
                  ))
                : null}
            </Box>
            <DocumentUploadWidget transactionId={transactionId} />
          </DrawerSection>
        </DrawerContent>
      </Drawer>
      <Modal
        data-test-id="stop-all-uploads-confirmation"
        id="stop-all-uploads-modal"
        isOpen={isModalOpen}
        onClose={onCancelUploadModalClose}
      >
        <ModalContent>
          <ModalSection>
            <Flex flexDirection="column">
              <Text as="h2" fontWeight="semi" mb="space50" size="fontSize30">
                Cancel uploads?
              </Text>
              <Text as="p" size="fontSize20">
                Would you like to cancel all in-progress uploads?
              </Text>
            </Flex>
          </ModalSection>
          <ModalFooter>
            <Button data-test-id="cancel-stop-button" mr="space30" variant="outline" onClick={onCancelUploadModalClose}>
              Continue uploads
            </Button>
            <Button
              data-test-id="modal-stop-button"
              onClick={(): void => {
                handleCancelUploads();
              }}
            >
              Cancel uploads
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
