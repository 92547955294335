import React, { FC, useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { LayoutContent, LayoutSubContent } from '@endpoint/blockparty';
import { Layout } from 'components/Layout';
import { trackPage } from 'helpers/utils/segment/segmentAnalytics';
import { Page } from 'consts/segmentProtocols';

import { TransactionsListContent } from './TransactionsListContent';

interface TransactionListProps extends RouteComponentProps {}

export const TransactionsList: FC<TransactionListProps> = ({ location }) => {
  useEffect(() => {
    trackPage<Page>('Transaction List');
  }, []);

  // This hack forces a form reset when Transactions link is clicked in header nav. When there is no '?' in the URL
  //  the form is hidden and then reappears once redirected to a page containing a '?'. The remount causes a
  //  reinitialization of form components using default values.
  //
  // TODO: refactor form components to use `value` prop instead of `defaultValue` so
  //  form values keep in sync with url query param values.

  const hasSearch = location?.href.includes('?');

  useEffect(() => {
    if (!hasSearch) {
      void navigate('/transactions/?');
    }
  }, [hasSearch]);

  return (
    <Layout>
      <LayoutContent>
        <LayoutSubContent>{hasSearch ? <TransactionsListContent /> : null}</LayoutSubContent>
      </LayoutContent>
    </Layout>
  );
};

TransactionsList.displayName = 'TransactionsList';
