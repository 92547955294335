import React, { FC } from 'react';
import { Box } from '@endpoint/blockparty';
import { generateSchema } from 'helpers/dynamicForm/schema';
import { OpsTask } from '@endpoint/opsware-bff-graphql-schema';

import { TaskStepViewWrapper } from './TaskStepViewWrapper';

interface DynamicFormProps {
  task?: OpsTask;
}

export const DynamicForm: FC<DynamicFormProps> = ({ task }) => {
  if (!task) return null;

  const { title, stepDefinitions } = generateSchema(task.taskDefinitionSchema);
  const firstIncompleteStep = task.steps?.findIndex((taskStep) => !taskStep.isCompleted);

  return (
    <Box px="space60">
      {task.steps?.map((taskStep, index) => (
        <TaskStepViewWrapper
          key={taskStep.stepId}
          expanded={index === firstIncompleteStep}
          taskStep={taskStep}
          taskStepDefinition={stepDefinitions[taskStep.key]}
        />
      ))}
    </Box>
  );
};
