import React, { FC } from 'react';
import { Box, Flex, Grid, Heading, Text } from '@endpoint/blockparty';
import { ContactType, TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema';
import { formatPhoneNumber } from 'helpers/formatPhone';
import { EditFieldButton } from 'components/EditFieldButton';
import { useEditContactDetailsProvider, EditContactDetailsAction } from 'hooks/useEditContactDetailsProvider';
import { UpdateContactField } from 'consts/updateContact';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { isEmailOffline } from 'helpers/updateContact';
import { useIsSystemOrOpsAdmin } from 'hooks/useIsSystemOrOpsAdmin';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsClosingSpecialistRole } from 'hooks/useIsClosingSpecialistRole';

interface ContactInformationProps {
  participant: TransactionParticipant;
}

export const ContactInformationFields: FC<ContactInformationProps> = ({ participant }) => {
  const {
    contact: { phone, email, isOnboarded },
  } = participant;
  const { isAdmin } = useIsSystemOrOpsAdmin();
  const { canEditContactEmailAndPhone, disableEditContactPhoneAndEmailUnderTransaction } = useFlags();
  const userCanUpdatePhoneOrEmail = isAdmin && canEditContactEmailAndPhone;
  const isEditableContactType = ![ContactType.PROPTECH_PARTNER, ContactType.SETTLEMENT_COMPANY].includes(
    participant?.contact?.contactType as ContactType,
  );

  const canEditEmail =
    isEditableContactType &&
    (isEmailOffline(email!) || !isOnboarded || userCanUpdatePhoneOrEmail) &&
    !disableEditContactPhoneAndEmailUnderTransaction;
  const canEditPhone =
    isEditableContactType &&
    (!isOnboarded || userCanUpdatePhoneOrEmail) &&
    !disableEditContactPhoneAndEmailUnderTransaction;
  const { state, dispatch } = useEditContactDetailsProvider();
  const isClosingSpecialist = useIsClosingSpecialistRole(participant);

  const handleEditClick = (fieldToFocus: UpdateContactField) => {
    dispatch({
      type: EditContactDetailsAction.SET_SELECTED_FIELD,
      payload: { ...state, selectedField: fieldToFocus },
    });

    trackAnalytics('Edit Contact Clicked', { field: fieldToFocus });
  };

  return (
    <Grid
      columnGap="space60"
      px="space40"
      py="space30"
      rowGap="space30"
      templateColumns="repeat(2, minmax(150px, 1fr))"
    >
      <Box>
        <Flex justifyContent="space-between">
          <Text as="p" color="passive" mb="space20" size="fontSize10">
            Phone
          </Text>
          {canEditPhone && !isClosingSpecialist && (
            <EditFieldButton handleClick={() => handleEditClick(UpdateContactField.PHONE)} label="Phone" />
          )}
        </Flex>
        <Text as="p" data-test-id="contact-information-phone">
          {phone ? formatPhoneNumber(phone) : '-'}
        </Text>
      </Box>
      <Box>
        <Flex justifyContent="space-between">
          <Text as="p" color="passive" mb="space20" size="fontSize10">
            Email
          </Text>
          {canEditEmail && !isClosingSpecialist && (
            <EditFieldButton handleClick={() => handleEditClick(UpdateContactField.EMAIL)} label="Email" />
          )}
        </Flex>
        <Text as="p" data-test-id="contact-information-email" mb="space30">
          {email || '-'}
        </Text>
      </Box>
    </Grid>
  );
};

export const ContactInformation: FC<ContactInformationProps> = ({ participant }) => {
  return (
    <Box data-test-id="contact-information-section" width="100%">
      <Heading backgroundColor="slate0" p="space40" size="fontSize30">
        Contact Information
      </Heading>
      <ContactInformationFields participant={participant} />
    </Box>
  );
};
