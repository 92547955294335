import { gql } from '@apollo/client';
import { DocumentUpload } from 'consts/uploadDocumentsConsts';

export const typeDefs = gql`
  extend type Query {
    me: OpswareUser!
    breadCrumbLinks: [BreadCrumbLink!]!
    uploadDocument: [UploadDocument]
    uploadUnassignedDocument: [UploadUnassignedDocument]
  }

  type BreadCrumbLink {
    label: string!
    path: string!
  }

  type OpswareUser {
    fistName: String!
    lastName: String!
  }

  enum DocumentUploadStatus {
    ERROR
    INPROGRESS
    COMPLETED
  }

  enum FileRejections {
    FILE_INVALID_TYPE
    FILE_TOO_LARGE
    CANCELED
  }

  scalar FileUpload

  type DocumentUpload {
    id: ID!
    name: String!
    status: DocumentUploadStatus!
    progress: number
    documentType: String!
    fileRejection: FileRejections
    fileUpload: FileUpload!
  }

  type UploadDocument {
    uploadDrawerOpen: Boolean!
    uploads: [DocumentUpload]
  }

  type UploadUnassignedDocument {
    uploads: [DocumentUpload]
  }
`;

export interface BreadCrumbLink {
  label: string;
  path: string;
}

export interface UploadUnassignedDocument {
  uploads?: [DocumentUpload] | [];
}
