import { Button, Text, useDisclosure, Modal, ModalContent, ModalSection, ModalFooter } from '@endpoint/blockparty';
import React, { FC } from 'react';

interface RemoveInternalNoteButtonProps {
  loading: boolean;
  noteId: string;
  onRemoveNote: (id: string) => void;
}

export const RemoveInternalNoteButton: FC<RemoveInternalNoteButtonProps> = ({ loading, noteId, onRemoveNote }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        data-test-id={`remove-note-button-${noteId}`}
        isDisabled={loading}
        size="none"
        variant="link"
        onClick={onOpen}
      >
        <Text color="carbon500" size="fontSize10">
          Remove
        </Text>
      </Button>
      <Modal data-test-id="remove-note-confirmation" id="remove-pinned-note-modal" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalSection>
            <Text as="h2" fontWeight="semi" size="fontSize30">
              Are you sure you want to remove this note?
            </Text>
          </ModalSection>
          <ModalFooter>
            <Button data-test-id="cancel-remove-button" mr="space30" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              data-test-id="modal-remove-button"
              onClick={(): void => {
                onRemoveNote(noteId);
                onClose();
              }}
            >
              Yes, remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
