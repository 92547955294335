import React, { FC, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation, navigate } from '@reach/router';
import {
  RadioGroup,
  Radio,
  useToast,
  Text,
  Flex,
  Button,
  Modal,
  ModalContent,
  ModalSection,
  ModalFooter,
} from '@endpoint/blockparty';
import { Transaction, TransactionType, TransactionUpdateInput } from '@endpoint/opsware-bff-graphql-schema';
import { UPDATE_TRANSACTION_BASIC_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import { TransactionUpdateResult } from 'routes/Dashboard/OpenOrderList/queries';
import { networkFailureInfo } from 'consts/toastProps';
import { hasValidListingAgent, hasValidSellingAgent } from 'helpers/transactionParticipants';
import { ButtonMilestoneValidated } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/ButtonMilestoneValidated';

export interface ModalAgentsValidationProps {
  updateToQCReady(): void;
  transaction: Transaction;
  loading: boolean;
}

export const ModalAgentsValidation: FC<ModalAgentsValidationProps> = ({ transaction, updateToQCReady, loading }) => {
  const location = useLocation();
  const toast = useToast();
  const [updateRequireAgents] = useMutation<TransactionUpdateResult>(UPDATE_TRANSACTION_BASIC_INFO, {
    onCompleted(data): void {
      toast({
        description: 'Acknowledged',
        icon: 'CheckCircle',
      });

      if (hasValidListingAgent(data.updateTransaction) && hasValidSellingAgent(data.updateTransaction)) {
        void updateToQCReady();
      } else {
        void navigate(`${location.pathname}/contacts`);
      }

      setIsAgentValidationModalOpen(false);
    },
    onError(): void {
      toast(networkFailureInfo);
    },
  });

  const [isAgentValidationModalOpen, setIsAgentValidationModalOpen] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [listingAgentAnswer, setListingAgentAnswer] = useState('');
  const [sellingAgentAnswer, setSellingAgentAnswer] = useState('');

  useEffect(() => {
    const enforceSellingAgentRequirement = !hasValidSellingAgent(transaction) && !sellingAgentAnswer;
    const enforceListingAgentRequirement = !hasValidListingAgent(transaction) && !listingAgentAnswer;

    if (enforceSellingAgentRequirement || enforceListingAgentRequirement) {
      setIsSaveButtonDisabled(true);
    } else {
      setIsSaveButtonDisabled(false);
    }
  }, [transaction, sellingAgentAnswer, listingAgentAnswer]);

  const onCancel = () => {
    setIsAgentValidationModalOpen(false);
  };

  const onClick = () => {
    const isMissingListingAgent = !hasValidListingAgent(transaction);
    const isMissingSellingAgent = !hasValidSellingAgent(transaction);
    const isMissingAgent = isMissingListingAgent || isMissingSellingAgent;
    const isRefi = transaction.type === TransactionType.REFINANCE;

    if (isRefi || !isMissingAgent) {
      void updateToQCReady();
    } else if (isMissingAgent) {
      setIsAgentValidationModalOpen(true);
    }
  };

  const onSave = (): void => {
    const updateTransactionVariables = {} as Partial<TransactionUpdateInput>;

    if (!hasValidListingAgent(transaction) && listingAgentAnswer) {
      updateTransactionVariables.requireListingAgent = listingAgentAnswer === 'yesListingAgentRequired';
    }

    if (!hasValidSellingAgent(transaction) && sellingAgentAnswer) {
      updateTransactionVariables.requireSellingAgent = sellingAgentAnswer === 'yesSellingAgentRequired';
    }

    void updateRequireAgents({
      variables: {
        where: { id: transaction.id },
        updateTransaction: {
          ...updateTransactionVariables,
        },
      },
    });
  };

  return (
    <>
      <ButtonMilestoneValidated
        data-test-id="qc-preparing-order-prompt-cta"
        isDisabled={loading}
        transactionId={transaction.id}
        variant="outline"
        variantColor="carbon"
        onClick={onClick}
      >
        Ready for QC
      </ButtonMilestoneValidated>
      <Modal isOpen={isAgentValidationModalOpen} onClose={() => setIsAgentValidationModalOpen(false)}>
        <ModalContent>
          <ModalSection width="max-content">
            <Flex flexDirection="column">
              <Text as="h3" fontWeight="semi" mb="space20" size="fontSize30">
                Missing agent contacts
              </Text>
              {!hasValidListingAgent(transaction) && (
                <RadioGroup
                  aria-label="listingAgentRequiredChoices"
                  data-test-id="open-order-type"
                  defaultValue={listingAgentAnswer}
                  marginTop="20px"
                  name="listingAgentRequiredChoices"
                >
                  <Text>Is there a Listing Agent?</Text>
                  <Radio
                    key='agent-validation-yes-listing-agent-radio"'
                    data-test-id="agent-validation-yes-listing-agent-radio"
                    value="yesListingAgentRequired"
                    onChange={() => {
                      setListingAgentAnswer('yesListingAgentRequired');
                    }}
                  >
                    Yes, need to add Listing Agent to transaction
                  </Radio>
                  <Radio
                    key="agent-validation-no-listing-agent-radio"
                    data-test-id="agent-validation-no-listing-agent-radio"
                    value="noListingAgentRequired"
                    onChange={() => {
                      setListingAgentAnswer('noListingAgentRequired');
                    }}
                  >
                    No, there is no Listing Agent on transaction
                  </Radio>
                </RadioGroup>
              )}
              {!hasValidSellingAgent(transaction) && (
                <RadioGroup
                  aria-label="sellingAgentRequiredChoices"
                  data-test-id="selling-agent-required-choices"
                  defaultValue={sellingAgentAnswer}
                  marginTop="20px"
                  name="sellingAgentRequiredChoices"
                >
                  <Text>Is there a Selling Agent?</Text>
                  <Radio
                    key="agent-validation-yes-selling-agent-radio"
                    data-test-id="agent-validation-yes-selling-agent-radio"
                    value="yesSellingAgentRequired"
                    onChange={() => {
                      setSellingAgentAnswer('yesSellingAgentRequired');
                    }}
                  >
                    Yes, need to add Selling Agent to transaction
                  </Radio>
                  <Radio
                    key="agent-validation-no-selling-agent-radio"
                    data-test-id="agent-validation-no-selling-agent-radio"
                    value="noSellingAgentRequired"
                    onChange={() => {
                      setSellingAgentAnswer('noSellingAgentRequired');
                    }}
                  >
                    No, there is no Selling Agent on transaction
                  </Radio>
                </RadioGroup>
              )}
            </Flex>
          </ModalSection>
          <ModalFooter>
            <Button
              data-test-id="qc-preparing-order-agent-validation-modal-cancel-button"
              mr="space40"
              variant="outline"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              data-test-id="qc-preparing-order-agent-validation-modal-save-button"
              isDisabled={isSaveButtonDisabled}
              onClick={onSave}
            >
              <Text color="white" fontWeight="semi">
                Save
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
