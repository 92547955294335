import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Flex, LayoutRightPanel } from '@endpoint/blockparty';

import { TaskPanel } from './TaskPanel/TaskPanel';
import { RightPanelHeader } from './RightPanelHeader';

type TransactionRightPanelProps = RouteComponentProps<{
  transactionIdentifier: string;
  taskId: string;
}>;

export const TransactionRightPanel: FC<TransactionRightPanelProps> = ({
  transactionIdentifier = '0',
  taskId = '0',
}) => {
  return (
    <LayoutRightPanel display={['none', 'none', 'block']} width="504px">
      <Flex flexDirection="column" height="100%">
        <RightPanelHeader taskId={taskId} transactionIdentifier={transactionIdentifier} />
        <TaskPanel taskId={taskId} transactionIdentifier={transactionIdentifier} />
      </Flex>
    </LayoutRightPanel>
  );
};

TransactionRightPanel.displayName = 'TransactionRightPanel';
