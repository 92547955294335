import React, { FC, useState } from 'react';
import {
  InputGroup,
  InputLabel,
  Select,
  Checkbox,
  CheckboxGroup,
  InputErrorMessage,
  Box,
  Button,
  Flex,
  Text,
  Icon,
} from '@endpoint/blockparty';
import { ContactType, TransactionRole, TransactionType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NewContactSchema, NewContactStep } from 'consts/createNewContact';
import {
  handleRolesCheckboxUpdate,
  isEntity,
  isAgentOrTC,
  getContactRoleOptionsByTransactionType,
  setRolesByContactAndTransactionType,
  isSettlementCompanyByValues,
} from 'helpers/contacts';
import { useFormikContext } from 'formik';
import { BasicSelectOption } from 'consts/documentTypeOptions';
import {
  getActiveContactTypes,
  internalEmployeeOption,
  proptechPartnerOption,
  settlementCompanyOption,
} from 'helpers/contacts/getActiveContactTypes';
import { useNewContactProvider, ActionKind } from 'hooks/useContactProvider';
import { delay } from 'lodash';

interface SelectContactTypeAndRolesProps {
  transactionType: TransactionType;
}

export const SelectContactTypeAndRoles: FC<SelectContactTypeAndRolesProps> = ({ transactionType }) => {
  const { errors, setFieldValue, setFieldTouched, touched, values } = useFormikContext<NewContactSchema>();
  const { state, dispatch } = useNewContactProvider();
  const isAgentOrTCOnLoad =
    values.contactType === ContactType.TRANSACTION_COORDINATOR ||
    values.contactType === ContactType.AGENT ||
    state.selectedContactData.contactType === ContactType.AGENT ||
    state.selectedContactData.contactType === ContactType.TRANSACTION_COORDINATOR;
  const isPropTechPartner =
    values.contactType === ContactType.PROPTECH_PARTNER ||
    state.selectedContactData.contactType === ContactType.PROPTECH_PARTNER;

  const isSettlementCompany = isSettlementCompanyByValues(values, state);

  const isInternalEmployee =
    values.contactType === ContactType.INTERNAL_EMPLOYEE ||
    state.selectedContactData.contactType === ContactType.INTERNAL_EMPLOYEE;

  const [roles, setRoles] = useState<TransactionRole[]>([]);
  const [hasRolesError, setHasRolesError] = useState(false);
  const { canAddProptechPartnerToTransaction } = useFlags();
  const activeContactTypeOptions = getActiveContactTypes();
  const [showDualRoles, setShowDualRoles] = useState(false);
  const [showAddTransactionRoleButton, setShowAddTransactionRoleButton] = useState(isAgentOrTCOnLoad);

  // Use flag to disable roles if flag is turned off
  const isEnabledIfProptech = isPropTechPartner ? canAddProptechPartnerToTransaction : true;

  const setRolesToContext = (rolesToDispatch: TransactionRole[]) => {
    dispatch({
      type: ActionKind.SET_CONTACT,
      payload: {
        ...state,
        selectedContactData: {
          ...state.selectedContactData,
          roles: [...rolesToDispatch],
        },
      },
    });
  };

  const setContactTypeToContext = (contactType: ContactType) => {
    dispatch({
      type: ActionKind.SET_CONTACT,
      payload: {
        ...state,
        selectedContactData: {
          ...state.selectedContactData,
          contactType,
        },
      },
    });
  };

  const rolesOptions = getContactRoleOptionsByTransactionType(values.contactType, transactionType);

  const handleNameInputs = (
    isNonIndividual: boolean,
    firstNameCondition: string,
    lastNameCondition: string,
    entityCondition: string,
  ) => {
    if (isNonIndividual) {
      setFieldValue('entityName', entityCondition);
      setFieldValue('firstName', '');
      setFieldValue('lastName', '');
      setFieldTouched('entityName', false);
    } else {
      setFieldValue('firstName', firstNameCondition, false);
      setFieldValue('lastName', lastNameCondition, false);
      setFieldValue('entityName', '');
      setFieldTouched('firstName', false);
    }
  };

  const handleRemoveClick = () => {
    setShowDualRoles(!showDualRoles);
    setShowAddTransactionRoleButton(!showAddTransactionRoleButton);
    const filterBuyerSeller: TransactionRole[] = roles.filter(
      (role: TransactionRole) => role !== TransactionRole.BUYER && role !== TransactionRole.SELLER,
    );

    setRoles(filterBuyerSeller);
    setFieldValue('roles', [...filterBuyerSeller], true);
    setRolesToContext(filterBuyerSeller);
  };

  const handleSelectChange = (contactTypeOption: SelectOptionsObject<ContactType> | BasicSelectOption) => {
    const isNonIndividualTransactee = isEntity(contactTypeOption.value);
    const showAddTransacteeButton = isAgentOrTC(contactTypeOption.value);
    const rolesByTransactionType: TransactionRole[] = setRolesByContactAndTransactionType(
      contactTypeOption.value as ContactType,
      transactionType,
    );

    setRoles(rolesByTransactionType);
    if (hasRolesError) setHasRolesError(false);

    setShowAddTransactionRoleButton(showAddTransacteeButton);
    if (showDualRoles) setShowDualRoles(false);

    setRolesToContext(rolesByTransactionType);
    setFieldValue('roles', rolesByTransactionType);
    setContactTypeToContext(contactTypeOption.value as ContactType);

    // set name fields with data that returns from search
    if (state.newContactStep === NewContactStep.SEARCH_UPDATE_CONTACT) {
      handleNameInputs(
        isNonIndividualTransactee,
        state.selectedContactData.firstName || '',
        state.selectedContactData.lastName || '',
        state.selectedContactData.entityName || '',
      );
    } else {
      // set name fields for new contact
      handleNameInputs(isNonIndividualTransactee, '', '', '');
    }
  };

  const handleCheckboxChange = (roleFromChange: TransactionRole) => {
    const updatedRoles = handleRolesCheckboxUpdate(roleFromChange, roles);

    setRoles(updatedRoles);
    setFieldValue('roles', updatedRoles);
    setHasRolesError(!updatedRoles.length);
    setRolesToContext(updatedRoles);
  };

  const getContactTypeValue = () => {
    if (isPropTechPartner) {
      return proptechPartnerOption;
    }

    if (isSettlementCompany) {
      return settlementCompanyOption;
    }

    if (isInternalEmployee) {
      return internalEmployeeOption;
    }

    return activeContactTypeOptions.find((option) => option.value === values.contactType) || '';
  };

  const contactTypeValue = getContactTypeValue();

  return (
    <>
      <InputGroup groupId="contactType" isInvalid={touched.contactType && !!errors.contactType} pb="space60">
        <InputLabel color="carbon500" htmlFor="contactType">
          Type *
        </InputLabel>
        <Select
          data-test-id="contact-type-select"
          inputId="contactType"
          isDisabled={isPropTechPartner || isSettlementCompany || isInternalEmployee}
          isSearchable
          name="contactType"
          options={activeContactTypeOptions}
          placeholder="Select..."
          value={contactTypeValue}
          onChange={(option: SelectOptionsObject<ContactType> | null): void => {
            let changedOption: BasicSelectOption | SelectOptionsObject<ContactType>;

            if (!option) {
              changedOption = { label: '', value: '' };
            } else {
              changedOption = option;
            }

            setFieldValue('contactType', changedOption.value);

            /** adding a delay to the call of setFieldTouched ensures that validation is run onChange
             * fields that use ReactSelect or other Selects - see https://github.com/formium/formik/issues/2059
             */
            delay(setFieldTouched, 100, 'contactType', true);
            handleSelectChange(changedOption);
          }}
        />
        <InputErrorMessage>Type is a required field</InputErrorMessage>
      </InputGroup>
      {rolesOptions.length > 0 && isEnabledIfProptech && !isInternalEmployee && (
        <InputGroup groupId="select-role" isInvalid={hasRolesError} pb="space50">
          <InputLabel color="carbon500" htmlFor="select-role">
            Role *
          </InputLabel>
          {/*
          // @ts-ignore - revisit invalid inputId prop */}
          <CheckboxGroup inputId="select-role">
            <>
              {rolesOptions.map((option: SelectOptionsObject<TransactionRole>) => {
                const isOptionChecked = values.roles.includes(option.value);

                return (
                  <Checkbox
                    key={`${option.value}-checkbox`}
                    isChecked={isOptionChecked}
                    value={option.value}
                    onChange={() => handleCheckboxChange(option.value)}
                  >
                    {option.label}
                  </Checkbox>
                );
              })}
            </>
          </CheckboxGroup>
          <InputErrorMessage>Select at least one</InputErrorMessage>
        </InputGroup>
      )}
      <>
        {showAddTransactionRoleButton && (
          <Box pb="space60" pt="space50">
            <Button
              className="add-transaction-role-button"
              iconLeft="Add"
              size="none"
              variant="unstyled"
              onClick={() => {
                setShowDualRoles(!showDualRoles);
                setShowAddTransactionRoleButton(!showAddTransactionRoleButton);
              }}
            >
              Add individual role
            </Button>
          </Box>
        )}

        {showDualRoles ? (
          <>
            <Flex justifyContent="space-between" pb="space50" pt="space50">
              <Text as="p" fontWeight="semi" size="fontSize30">
                Individual role
              </Text>
              <Button size="small" variant="outline" onClick={handleRemoveClick}>
                <Icon mr="space30" name="Close" size="medium" />
                Remove
              </Button>
            </Flex>
            <InputGroup groupId="transactee-roles" isInvalid={hasRolesError} pb="space60">
              <InputLabel color="carbon500" htmlFor="transactee-role">
                Role *
              </InputLabel>
              {/* @ts-ignore - revisit invalid inputId prop */}
              <CheckboxGroup>
                <Checkbox
                  data-test-id="buyer-checkbox"
                  isChecked={roles.includes(TransactionRole.BUYER)}
                  value={TransactionRole.BUYER}
                  onChange={() => handleCheckboxChange(TransactionRole.BUYER)}
                >
                  Buyer
                </Checkbox>
                <Checkbox
                  data-test-id="seller-checkbox"
                  isChecked={roles.includes(TransactionRole.SELLER)}
                  value={TransactionRole.SELLER}
                  onChange={() => handleCheckboxChange(TransactionRole.SELLER)}
                >
                  Seller
                </Checkbox>
              </CheckboxGroup>
              <InputErrorMessage>Select at least one</InputErrorMessage>
            </InputGroup>
          </>
        ) : null}
      </>
    </>
  );
};
