import { ThemeShape } from '@endpoint/blockparty/dist/theme';
import { TaskStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export type TaskStatusColorMap = { [index in TaskStatus]: keyof ThemeShape['colors'] };

export type TaskStatusMap = { [index in TaskStatus]: string };

export const taskStatusMap: TaskStatusMap = {
  [TaskStatus.NOT_STARTED]: 'Not Started',
  [TaskStatus.IN_PROGRESS]: 'In Progress',
  [TaskStatus.COMPLETED]: 'Completed',
  [TaskStatus.WAIVED]: 'Waived',
};

export const taskStatusBgColorMap: TaskStatusColorMap = {
  [TaskStatus.NOT_STARTED]: 'carbon0',
  [TaskStatus.IN_PROGRESS]: 'marigold400',
  [TaskStatus.COMPLETED]: 'aloe500',
  [TaskStatus.WAIVED]: 'mist200',
};

export const taskStatusBorderColorMap: TaskStatusColorMap = {
  [TaskStatus.NOT_STARTED]: 'mist600',
  [TaskStatus.IN_PROGRESS]: 'marigold400',
  [TaskStatus.COMPLETED]: 'aloe500',
  [TaskStatus.WAIVED]: 'mist200',
};

export const taskStatusDisabledBgColorMap: TaskStatusColorMap = {
  [TaskStatus.NOT_STARTED]: 'carbon0',
  [TaskStatus.IN_PROGRESS]: 'marigold100',
  [TaskStatus.COMPLETED]: 'aloe100',
  [TaskStatus.WAIVED]: 'mist200',
};

export const taskStatusDisabledBorderColorMap: TaskStatusColorMap = {
  [TaskStatus.NOT_STARTED]: 'mist200',
  [TaskStatus.IN_PROGRESS]: 'marigold100',
  [TaskStatus.COMPLETED]: 'aloe100',
  [TaskStatus.WAIVED]: 'mist200',
};

export const taskStatusTextColorMap: TaskStatusColorMap = {
  [TaskStatus.NOT_STARTED]: 'mist700',
  [TaskStatus.IN_PROGRESS]: 'carbon900',
  [TaskStatus.COMPLETED]: 'carbon900',
  [TaskStatus.WAIVED]: 'mist800',
};
