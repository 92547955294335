import React, { FC } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerActionBar,
  DrawerCloseButton,
  DrawerContent,
  DrawerSection,
  Flex,
  Heading,
  Icon,
  Text,
  Divider,
} from '@endpoint/blockparty';
import { MilestoneType, TransactionStage, Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { formatISO } from 'date-fns';
import { navigate } from '@reach/router';
import { ApolloError } from '@apollo/client';
import { ErrorMessage } from 'components/ErrorMessage';
import { transactionTypeMap } from 'consts/transactionConsts';
import { dateFormatter } from 'helpers/datetimeHelper';
import { mapStatusText } from 'helpers/statusHelper';

import { SigningEventBody } from './CalendarEventBody/SigningEventBody';
import { TransactionEventBody } from './CalendarEventBody/TransactionEventBody';
import './CalendarEventDrawer.scss';
import { InternalNotes } from './InternalNotes';
import { CalendarEventDrawerLoader } from './CalendarEventDrawerLoader';

interface CalendarEventDrawerProps {
  eventType: TransactionStage | MilestoneType;
  headerText: string;
  isTransactionEvent: boolean;
  isOpen: boolean;
  onClose(): void;
  signingDate: Date | null;
  signingTimePlusThirty: Date | null;
  transaction: Transaction | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

export const CalendarEventDrawer: FC<CalendarEventDrawerProps> = ({
  eventType,
  headerText,
  isTransactionEvent,
  isOpen,
  onClose,
  signingDate,
  signingTimePlusThirty,
  transaction,
  loading,
  error,
}) => {
  const estimatedClosingISO =
    transaction && transaction.dates ? formatISO(new Date(transaction.dates.estimatedClosing)) : '';
  const estimatedClosingDate: string = dateFormatter(estimatedClosingISO, 'E, MMM d');
  const formattedSigningTime = `${dateFormatter(signingDate, 'h:mm')}-${dateFormatter(signingTimePlusThirty, 'h:mma')}`;

  return (
    <Drawer id="calendar-event-drawer" isOpen={isOpen} onClose={onClose}>
      <DrawerContent overflow="auto">
        <DrawerActionBar>
          <DrawerCloseButton />
        </DrawerActionBar>
        <Heading
          as="h3"
          id="transaction-event-drawer-header-text"
          pb="space20"
          pt="space60"
          px="space60"
          size="fontSize50"
        >
          {headerText}
        </Heading>
        <DrawerSection pt="space20">
          {loading && <CalendarEventDrawerLoader />}

          {error && (
            <Flex data-test-id="interal-notes-error" flexDirection="column">
              <Heading as="h5" mb="space60" size="fontSize40">
                {headerText}
              </Heading>
              <ErrorMessage />
            </Flex>
          )}
          {transaction && (
            <>
              <Flex flexDirection="column">
                <Box>
                  <Text data-test-id="event-date" size="fontSize20">
                    {isTransactionEvent ? estimatedClosingDate : dateFormatter(signingDate, 'E, MMM d')}
                  </Text>
                  <Icon color="carbon500" mx="space20" name="Bullet" size="16px" />
                  {isTransactionEvent ? (
                    <Text data-test-id="transaction-type" size="fontSize20">
                      {transaction.type ? mapStatusText(transaction.type, transactionTypeMap) : ''}
                    </Text>
                  ) : (
                    <Text data-test-id="signing-time">{formattedSigningTime}</Text>
                  )}
                </Box>
                <Text data-test-id="property-address" size="fontSize20">
                  {transaction.property.address.standardFormat || ''}
                </Text>
              </Flex>
              {isTransactionEvent ? (
                <TransactionEventBody eventType={eventType} transaction={transaction} />
              ) : (
                <SigningEventBody eventType={eventType} transaction={transaction} />
              )}
              <Button
                data-test-id={`view-transaction-button-${transaction.fileNum}`}
                variant="outline"
                variantColor="blue"
                onClick={() => navigate(`/transaction/${transaction.fileNum}`)}
              >
                View Transaction
              </Button>

              <Divider my="space60" />
              <InternalNotes notes={transaction.notes} transactionId={transaction.id} />
            </>
          )}
        </DrawerSection>
      </DrawerContent>
    </Drawer>
  );
};
