// this regex pattern matches what is in contact service's phone number format for cognito. See:
// github.com/endpointclosing/contact-service/blob/main/src/contact/helpers/formatCognitoPhoneNumber.ts
export const phoneNumberRegex = /^(1|)?(\d{3})(\d{3})(\d{4})$/;
/**
 * Removes all non-numeric characters in a given string
 * @param phone
 * @returns {string} non-formatted phone
 */
export const removeNonNumeric = (phone: string): string => phone.replace(/\D/g, '');

/**
 * Formats non-formatted string to US phone number
 * @param phone
 * @returns {string} formatted phone
 */
export const formatPhoneNumber = (phone: string | null): string | null => {
  const cleaned = `${phone}`.replace(/\D/g, '');

  const match = cleaned.match(phoneNumberRegex);

  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return null;
};
