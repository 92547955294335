import { gql } from '@apollo/client';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_SORTED_TASKS = gql`
  query getSortedTasks($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      fileNum
      id
      milestones {
        id
        tasks {
          id
        }
      }
    }
  }
`;

interface GetSortedTasksTransactionResult extends Transaction {
  sortedTasks: string[];
}

export interface GetSortedTasksResult {
  transaction: GetSortedTasksTransactionResult;
}
