import React, { FC } from 'react';
import { Box, NavBarItem, NavBar, Divider } from '@endpoint/blockparty';

export enum TsOptions {
  Search = 'Search',
  Liveboard = 'Liveboard',
  Visualization = 'Visualization',
}

interface SubNavProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<TsOptions>>;
}

interface TabItemProps {
  tabName: string;
  selected: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<TsOptions>>;
}

const TabItem: FC<TabItemProps> = ({ tabName, selected, setActiveTab }) => (
  <NavBarItem
    key={tabName}
    color={selected ? 'blue500' : 'carbon900'}
    data-test-id={`nav-bar-item-${tabName}`}
    fontWeight={selected ? 'heading' : 'normal'}
    mx="space50"
    selected={selected}
    onClick={() => setActiveTab(tabName as TsOptions)}
  >
    {tabName}
  </NavBarItem>
);

export const SubNav: FC<SubNavProps> = ({ activeTab, setActiveTab }) => {
  return (
    <Box>
      <Divider />
      <NavBar data-test-id="analytics-navigation" mx="space50">
        {Object.keys(TsOptions).map((tabName) => (
          <TabItem key={tabName} selected={activeTab === tabName} setActiveTab={setActiveTab} tabName={tabName} />
        ))}
      </NavBar>
      <Divider m="space0" />
    </Box>
  );
};
