import React, { FC } from 'react';
import { Button, Modal, ModalContent, ModalFooter, ModalSection, Text } from '@endpoint/blockparty';

interface UpdateDirectingContactModalProps {
  onClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
  onConfirm: () => void;
}

export const UpdateDirectingContactModal: FC<UpdateDirectingContactModalProps> = ({
  isLoading,
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent zIndex={2}>
        <ModalSection>
          <Text as="h1" fontWeight="semi" size="fontSize30">
            Reassign directing contact?
          </Text>
        </ModalSection>
        <ModalFooter>
          <Button isDisabled={isLoading} mr="space50" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            data-test-id="confirm-directing-contact"
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
