import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Box, Button, Flex, Input, Text } from '@endpoint/blockparty';
import React, { FC, useCallback, useState } from 'react';
import { giveFirstWord } from 'helpers/formatText';
import { NoteEntityType, OpswareUser } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { ErrorMessage } from 'components/ErrorMessage';
import { useHasWriteAccess } from 'hooks/useHasWriteAccess';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GET_TRANSACTION_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import { GET_OPS_TASK_NOTES } from 'routes/Transaction/TransactionRightPanel/queries';

import { CreateNote, CREATE_NOTE, CREATE_TRANSACTION_NOTE, GET_CURRENT_USER } from './queries';

interface AddNoteProps {
  entityType: NoteEntityType;
  isCalendarPage?: boolean;
  transactionId: string;
}

export const AddNote: FC<AddNoteProps> = ({ entityType, isCalendarPage, transactionId }) => {
  const { isOpswareNotesEnabled } = useFlags();
  const [text, setText] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [hasMutationError, setHasMutationError] = useState<boolean>(false);
  const { data } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);
  const fullName = data ? `${giveFirstWord(data.me.firstName)} ${giveFirstWord(data.me.lastName)}` : '';
  const hasWriteAccess = useHasWriteAccess();
  const createNoteMutation = isOpswareNotesEnabled ? CREATE_NOTE : CREATE_TRANSACTION_NOTE;
  const getNotesInfo = entityType === NoteEntityType.TRANSACTION ? GET_TRANSACTION_INFO : GET_OPS_TASK_NOTES;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event?.target.value);
  };

  const handleCancel = () => {
    setText('');
  };

  const handleErrorCloseAction = () => {
    setHasMutationError(!hasMutationError);
  };

  const mutationHandler = {
    onError(): void {
      setHasMutationError(true);
    },
  };

  const [createTransactionNote] = useMutation<CreateNote>(createNoteMutation, {
    ...mutationHandler,
    refetchQueries: [
      {
        query: getNotesInfo,
        variables: {
          where: {
            id: transactionId,
          },
        },
      },
    ],
  });

  const handleFormSubmt = useCallback(() => {
    setSubmitting(true);
    void createTransactionNote({
      variables: {
        data: isOpswareNotesEnabled
          ? {
              entityType,
              entityId: transactionId,
              text,
              isPinned: !!isCalendarPage,
            }
          : {
              transactionId,
              text,
              isPinned: !!isCalendarPage,
            },
      },
    });

    setSubmitting(false);
    setText('');
  }, [createTransactionNote, entityType, isCalendarPage, isOpswareNotesEnabled, text, transactionId]);

  return (
    <Box mb="space60">
      <Flex>
        <Avatar data-test-id="note-avatar" mr="space40" name={fullName} size={24} />
        <Box data-test-id="add-transaction-note" width="100%">
          <Input
            autoComplete="off"
            data-test-id="add-transaction-note-input"
            disabled={submitting || !hasWriteAccess}
            id="note"
            placeholder="Add note"
            value={text}
            onChange={handleInputChange}
          />
        </Box>
      </Flex>
      <Box>
        {hasMutationError && (
          <Flex mt="space50">
            {' '}
            <ErrorMessage closeAction={handleErrorCloseAction} title="Unable to save note" />
          </Flex>
        )}
        {text.length > 0 && (
          <Flex justifyContent="flex-end" mt="space50">
            <Button isDisabled={submitting} mr="space50" variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button data-test-id="add-transaction-note-submit" isDisabled={submitting} onClick={handleFormSubmt}>
              <Text color="white" fontWeight="semi">
                Save
              </Text>
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};
