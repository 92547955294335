export interface BasicSelectOption {
  value: string;
  label: string;
}

export const documentTypeOptions: BasicSelectOption[] = [
  { value: 'Addendum', label: 'Addendum' },
  { value: 'Commission Disbursement', label: 'Commission Disbursement' },
  { value: 'Earnest Money Check Copy', label: 'Earnest Money Check Copy' },
  { value: 'Entity Document', label: 'Entity Document' },
  { value: 'Exhibit A', label: 'Exhibit A' },
  { value: 'Final Utility', label: 'Final Utility' },
  { value: 'HOA Document', label: 'HOA Document' },
  { value: 'Home Warranty Document', label: 'Home Warranty Document' },
  { value: 'KCSC Form', label: 'KCSC Form' },
  { value: 'Lender CD', label: 'Lender CD' },
  { value: 'Loan Document – Unsigned', label: 'Loan Document – Unsigned' },
  { value: 'Other', label: 'Other' },
  { value: 'Payoff #1', label: 'Payoff #1' },
  { value: 'Payoff #2', label: 'Payoff #2' },
  { value: 'Power of Attorney', label: 'Power of Attorney' },
  { value: 'Prelim Order Received', label: 'Prelim Order Received' },
  { value: 'Purchase Agreement', label: 'Purchase Agreement' },
  { value: 'Tax Statement', label: 'Tax Statement' },
  { value: 'Tax Certificate', label: 'Tax Certificate' },
  { value: 'TitleFlex Result - deed', label: 'TitleFlex Result - deed' },
  { value: 'TitleFlex Result - no deed', label: 'TitleFlex Result - no deed' },
  { value: 'Title Order (Title Commitment)*', label: 'Title Order (Title Commitment)*' },
  { value: 'Trust Certification – Signed', label: 'Trust Certification – Signed' },
  { value: 'Shipping Label', label: 'Shipping Label' },
  { value: 'Utilities', label: 'Utilities' },
];
