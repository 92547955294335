import React, { FC, useState } from 'react';
import { Text, Box, Divider, Icon, Flex, Modal, ModalContent, Preview } from '@endpoint/blockparty';
import { TodoAssignmentStepAnswerField, Document } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { convertObjectToKeySortedList } from 'helpers/todo';
import { IconMap, imageIcon } from 'helpers/documents';
import { deriveMimeTypeText } from 'routes/Transaction/Documents/DocumentsContent/DocumentsTable/helpers';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import { v4 } from 'uuid';

type CustomerStatusCardQuestionAnswerProps = Partial<TodoAssignmentStepAnswerField>;

const multipleAnswerSortOrder = ['fullName', 'emailAddress', 'phoneNumber'];

const QuestionAnswerBoolean: FC<{ answer: boolean }> = ({ answer }) => <Box>{answer ? 'Yes' : 'No'}</Box>;
const QuestionAnswerString: FC<{ answer: string }> = ({ answer }) => <Box>{answer || '-'}</Box>;

const QuestionAnswerObject: FC<{ answer: object; fieldType?: string }> = ({ answer, fieldType }) => {
  const shouldIncludeLabel = fieldType === 'fixed_sum';

  return (
    <>
      {convertObjectToKeySortedList(answer, multipleAnswerSortOrder).map((field) => {
        const [fieldLabel, fieldValue] = field;

        return (
          <Box key={fieldLabel}>
            {shouldIncludeLabel && `${fieldLabel}: `}
            {fieldValue}
          </Box>
        );
      })}
    </>
  );
};

const QuestionAnswerMultiple: FC<{ answer: object[]; fieldType?: string }> = ({ answer, fieldType }) => (
  <>
    {answer.map((answerObject) =>
      answerObject ? <QuestionAnswerObject key={v4()} answer={answerObject} fieldType={fieldType} /> : '-',
    )}
  </>
);

const QuestionAnswerDocument: FC<{ document: Document }> = ({ document }) => {
  const mimeType = deriveMimeTypeText(document?.storage?.mimeType || '');
  const iconInfo: IconMap = imageIcon(mimeType);
  const url = document?.storage?.url;
  const [previewPdf, setPreviewPdf] = useState(false);

  return (
    <>
      <Flex
        alignItems="center"
        border="1px solid #C9C9C9"
        borderRadius="3px"
        cursor="pointer"
        data-test-id={`document-trigger-${document.name}`}
        padding="15px"
        onClick={() => {
          setPreviewPdf(true);
        }}
      >
        <Icon color={iconInfo.color} name={iconInfo.name as IconNames} size="18px" />
        <Text marginLeft="12px">{document.name}</Text>
      </Flex>
      {url && (
        <Modal
          data-test-id={`document-id-${document.name}`}
          id={`document-id-${document.name}`}
          isOpen={previewPdf}
          onClose={() => setPreviewPdf(false)}
        >
          <ModalContent height="100vh" maxWidth="100vh">
            <Preview overflow="auto" src={url} type={mimeType} />
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

const isEmptyObject = (item: unknown) =>
  !!(typeof item === 'object' && item?.constructor === Object && !Object.keys(item).length);

const mapAnswerTypeToComponent = (answer: unknown, documentObj?: Document, fieldType?: string) => {
  if (answer == null || isEmptyObject(answer)) {
    return '-';
  }

  if (documentObj) {
    return <QuestionAnswerDocument document={documentObj} />;
  }

  if (Array.isArray(answer)) {
    return <QuestionAnswerMultiple answer={answer} fieldType={fieldType} />;
  }

  if (typeof answer === 'object') {
    return <QuestionAnswerObject answer={answer} fieldType={fieldType} />;
  }

  if (typeof answer === 'boolean') {
    return <QuestionAnswerBoolean answer={answer} />;
  }

  if (typeof answer === 'string') {
    return <QuestionAnswerString answer={answer} />;
  }

  return '-';
};

export const CustomerStatusCardQuestionAnswer: FC<CustomerStatusCardQuestionAnswerProps> = ({
  answer,
  label,
  document,
  fieldType,
}) => {
  const AnswerComponent = mapAnswerTypeToComponent(answer, document, fieldType);

  return (
    <Box mt="space40">
      <Divider />
      {label && (
        <Box display="flex" mt="space40">
          <Text color="carbon900" fontWeight="semi" size="fontSize20">
            Q:
          </Text>
          <Text color="carbon900" flexGrow="1" marginLeft="space50" ml="space40">
            {label}
          </Text>
        </Box>
      )}
      <Box display="flex" mt="space40">
        {label && (
          <Text color="carbon900" fontWeight="semi" size="fontSize20">
            A:
          </Text>
        )}
        <Text color="carbon900" flexGrow="1" marginLeft="space50" ml="space40">
          {AnswerComponent}
        </Text>
      </Box>
    </Box>
  );
};
