import { IconButtonWriteRequired } from 'components/IconButtonWriteRequired';
import React, { FC } from 'react';

interface EditTransactionBasicInfoProps {
  toggleEditing: () => void;
  setFocusField: (value: string) => void;
  fieldName?: string;
}

export const EditTransactionBasicInfoEditButton: FC<EditTransactionBasicInfoProps> = ({
  toggleEditing,
  fieldName,
  setFocusField,
}) => {
  return (
    <IconButtonWriteRequired
      color="carbon200"
      data-test-id="edit-transaction-icon-button"
      iconSize="medium"
      name="Edit"
      size="medium"
      style={{
        marginRight: '12px',
      }}
      variant="unstyled"
      onClick={() => {
        if (fieldName) {
          setFocusField(fieldName);
        }

        toggleEditing();
      }}
    />
  );
};
