import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';
import { Center, Flex, IconButton, Input, InputErrorMessage, InputGroup, Select, Text } from '@endpoint/blockparty';
import { defaultItemsPerPageOptions } from 'consts/paginiation';
import { ParamsType } from 'helpers/utils/queryParams';
import { getFirstItemInPages, getLastItemInPages } from 'helpers/pagination';

interface PaginationProps {
  currentParams: ParamsType;
  loading: boolean;
  itemsPerPageOptions?: SelectOptionsObject<number>[];
  totalItems: number;
  navigateWithParams: (params: ParamsType) => void;
}

export const Pagination: FC<PaginationProps> = ({
  currentParams,
  loading,
  itemsPerPageOptions = defaultItemsPerPageOptions,
  navigateWithParams,
  totalItems,
}) => {
  const defaultItemsPerPageValue = itemsPerPageOptions.filter((item: SelectOptionsObject<number>) => {
    if (typeof currentParams.itemsPerPage === 'string') {
      return item.value === parseInt(currentParams.itemsPerPage, 10);
    }

    return item.value === currentParams.itemsPerPage;
  });
  const pageIndexParsed = parseInt(currentParams.pageIndex as string, 10);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPageValue[0]);
  const [pageIndexValue, setPageIndexValue] = useState<number>(pageIndexParsed);
  let totalPages = Math.ceil(totalItems / itemsPerPage.value);
  const isFirstPage = pageIndexParsed === 1;
  const isLastPage = pageIndexParsed === totalPages;
  const minOfItemsRange = getFirstItemInPages(pageIndexParsed, defaultItemsPerPageValue[0].value);
  const maxOfItemsRange = getLastItemInPages(pageIndexParsed, itemsPerPage.value, totalItems!);
  const isPageIndexValueInvalid = !pageIndexValue || pageIndexValue > totalPages || pageIndexValue < 1;

  useEffect(() => {
    setPageIndexValue(pageIndexParsed);
  }, [pageIndexParsed]);

  const handleItemsPerPageChange = (selected: SelectOptionsObject<number>): void => {
    setItemsPerPage(selected);
    totalPages = Math.ceil(totalItems / selected.value);

    const boundedPageIndexValue = Math.max(1, Math.min(totalPages, pageIndexValue));

    setPageIndexValue(boundedPageIndexValue);
    void navigateWithParams({ ...currentParams, itemsPerPage: selected.value, pageIndex: boundedPageIndexValue });
  };

  const handlePageInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const parsedValue = parseInt(event.target.value, 10);

    setPageIndexValue(parsedValue);
  };

  const handlePageEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isPageIndexValueInvalid) {
      void navigateWithParams({ ...currentParams, pageIndex: pageIndexValue });
    }
  };

  const handlePrevPageClick = () => {
    const pageIndexMinusOne = pageIndexValue - 1;

    setPageIndexValue(pageIndexMinusOne);
    void navigateWithParams({ ...currentParams, pageIndex: pageIndexMinusOne });
  };

  const handleNextPageClick = () => {
    const pageIndexPlusOne = pageIndexValue + 1;

    setPageIndexValue(pageIndexPlusOne);
    void navigateWithParams({ ...currentParams, pageIndex: pageIndexPlusOne });
  };

  return (
    <Flex justifyContent="space-between" px="space60" py="space30">
      <Center>
        <Text as="p" pr="space30">
          Items per page:
        </Text>
        <Select
          aria-label="Items per page"
          isDisabled={loading}
          menuPlacement="top"
          options={itemsPerPageOptions}
          value={itemsPerPage}
          onChange={(option: SelectOptionsObject<number>) => handleItemsPerPageChange(option)}
        />
        <Text as="p" pl="space60" pr="space30">
          Showing {minOfItemsRange} - {maxOfItemsRange} of {totalItems}
        </Text>
      </Center>
      <Center>
        <InputGroup groupId="page-index-input" isDisabled={loading} isInvalid={isPageIndexValueInvalid}>
          <Input
            aria-label="Page Index"
            mr="space30"
            paddingX="space30"
            textAlign="right"
            textOverflow="ellipsis"
            type="text"
            value={pageIndexValue || ''}
            width="67px"
            onChange={handlePageInputChange}
            onKeyPress={handlePageEnter}
          />
          <InputErrorMessage>Invalid number</InputErrorMessage>
        </InputGroup>
        <Text as="p"> of {totalPages} pages</Text>
        <IconButton
          isDisabled={loading || isFirstPage}
          label="Previous Page"
          ml="space60"
          name="ArrowChevronLeft"
          variant="unstyled"
          variantColor="carbon"
          onClick={handlePrevPageClick}
        />
        <IconButton
          isDisabled={loading || isLastPage}
          label="Next Page"
          ml="space70"
          name="ArrowChevronRight"
          variant="unstyled"
          variantColor="carbon"
          onClick={handleNextPageClick}
        />
      </Center>
    </Flex>
  );
};
