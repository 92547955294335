import React, { FC, useMemo } from 'react';
import { RouteComponentProps, Link as ReachRouterLink, useLocation } from '@reach/router';
import { useTable, Column, useSortBy, ColumnInstance, useFlexLayout, Cell } from 'react-table';
import { Table, TableBody, TableCell, Link } from '@endpoint/blockparty';
import {
  TransactionSearchResult,
  TransactionsSortProperties,
  SortDirection,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TableItemClicked, TrackingEvent } from 'consts/segmentProtocols';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { calculateDateDifferenceTimeElapsed } from 'helpers/datetimeHelper';
import { transactionRowData, TransactionsListRowData, useOpenOrderFilters } from 'helpers/openOrderList';
import { parse } from 'query-string';
import { useFlags } from 'launchdarkly-react-client-sdk';
import GenericTableHeader from 'components/GenericTableHeader';
import GenericColumnHeader from 'components/GenericColumnHeader';
import GenericTableRow from 'components/GenericTableRow';

const trackData: TableItemClicked = {
  table: 'Open Order List Table',
};

const trackingEvent: TrackingEvent = 'Table Item Clicked';

interface TransactionsListTableProps extends RouteComponentProps {
  transactions: TransactionSearchResult[];
}

export const formatCreatedDate = (date: Date): string => {
  return calculateDateDifferenceTimeElapsed(new Date(), date);
};

export const columnHeaders = (
  isQcStatusEnabled?: boolean,
  isQcSourceEnabled?: boolean,
): Column<TransactionsListRowData>[] => {
  const headers: Column<TransactionsListRowData>[] = [
    {
      Header: 'File #',
      accessor: TransactionsSortProperties.FILE_NUMBER,
      minWidth: 150,
    },
    {
      Header: 'Address',
      accessor: TransactionsSortProperties.ADDRESS,
      disableSortBy: true,
      minWidth: 500,
    },
    {
      Header: 'Product Type',
      accessor: TransactionsSortProperties.ESCROW_PRODUCT_TYPE,
    },
    isQcStatusEnabled
      ? {
          Header: 'Order Status',
          accessor: TransactionsSortProperties.QUALITY_CONTROL_STATUS,
        }
      : {
          Header: 'Needs Review',
          accessor: TransactionsSortProperties.NEEDS_REVIEW,
        },
    {
      Header: 'Created',
      id: TransactionsSortProperties.CREATED_ON,
      accessor: (row) => formatCreatedDate(row.CREATED_ON),
      sortType: (first, second) => {
        const transactionA = new Date(first.original.CREATED_ON).getTime();
        const transactionB = new Date(second.original.CREATED_ON).getTime();

        return transactionB - transactionA;
      },
    },
  ];

  if (isQcSourceEnabled) {
    headers.push({
      Header: 'Source',
      accessor: TransactionsSortProperties.SOURCE,
      disableSortBy: true,
    });
  }

  return headers;
};

const useGetInitialSortState = () => {
  const location = useLocation();
  const searchParams = parse(location.search, { arrayFormat: 'comma' });
  const sortQuery = searchParams.sort;

  if (sortQuery) {
    if (sortQuery[1] === 'DESC') {
      return [
        {
          id: sortQuery[0],
          desc: true,
        },
      ];
    }

    return [
      {
        id: sortQuery[0],
        desc: false,
      },
    ];
  }

  return [];
};

const getSortQuery = (column: ColumnInstance<TransactionsListRowData>) => {
  let query: [TransactionsSortProperties, SortDirection] | [] = [
    column.id as TransactionsSortProperties,
    SortDirection.ASC,
  ];

  if (column.isSorted) {
    query = column.isSortedDesc ? [] : [column.id as TransactionsSortProperties, SortDirection.DESC];
  }

  return query;
};

function ColumnHeader({ column }: { column: ColumnInstance<TransactionsListRowData> }): React.ReactElement {
  const { navigateWithParams } = useOpenOrderFilters();

  return (
    <GenericColumnHeader<TransactionsListRowData>
      column={column}
      onClick={() => {
        navigateWithParams({ sort: getSortQuery(column) });
      }}
    />
  );
}

const columnHeaderInstance = (column: ColumnInstance<TransactionsListRowData>) => <ColumnHeader column={column} />;

const tableCellInstance = (cell: Cell<TransactionsListRowData>, transactionIdentifier: string) => (
  <Link
    _hover={{ textDecoration: 'none' }}
    as={ReachRouterLink}
    to={`/transaction/${transactionIdentifier}`}
    onClick={() => {
      trackAnalytics(trackingEvent, trackData);
    }}
  >
    {cell.render('Cell')}
  </Link>
);

export const OpenOrderListTable: FC<TransactionsListTableProps> = ({ transactions }) => {
  const { isQcStatusEnabled, isQcSourceEnabled } = useFlags();
  const columns = useMemo(
    () => columnHeaders(isQcStatusEnabled, isQcSourceEnabled),
    [isQcStatusEnabled, isQcSourceEnabled],
  );
  const data = useMemo(
    () => transactionRowData(transactions, isQcStatusEnabled, isQcSourceEnabled),
    [transactions, isQcStatusEnabled, isQcSourceEnabled],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        sortBy: useGetInitialSortState(),
      },
    },
    useSortBy,
    useFlexLayout,
  );

  return (
    <Table label="Open Order List Table" {...getTableProps()}>
      <GenericTableHeader
        columnHeader={columnHeaderInstance}
        dataTestId="transaction-list-table-header"
        headerGroups={headerGroups}
      />
      <TableBody {...getTableBodyProps()}>
        {rows.length ? (
          rows.map((row) => {
            prepareRow(row);
            const { key: rowKey } = row.getRowProps();
            // NOTE: when routing if fileNumber is "-" - prefixed with "id" for query logic in /src/routes/Transaction/index.tsx
            const transactionIdentifier =
              row.original.FILE_NUMBER !== '-' ? row.original.FILE_NUMBER : `id-${row.original.transactionId}`;

            return (
              <GenericTableRow<TransactionsListRowData>
                key={rowKey}
                dataTestId={`row-${row.original.FILE_NUMBER}`}
                row={row}
                tableCell={(cell) => tableCellInstance(cell, transactionIdentifier)}
              />
            );
          })
        ) : (
          <TableCell py="space30">No matching records found.</TableCell>
        )}
      </TableBody>
    </Table>
  );
};
