import { Transaction } from '@endpoint/opsware-bff-graphql-schema';

export interface EditTransactionBasicInfoFormInitialValue {
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  county?: string;
  parcelNumber?: string;
  productType?: string;
  loanAmount?: string | number;
  emdAmount?: number;
  emdPercent?: number;
  salePrice?: number;
  loanNumber?: string;
  estimatedClosing?: string;
  emdDueDate?: string;
  escrowOpenedDate?: string;
  contractExecutionDate?: string;
  createdDate: string;
}

export function buildEditTransactionBasicInfoFormInitialValues(
  transaction: Transaction,
): EditTransactionBasicInfoFormInitialValue {
  return {
    address: transaction.property.address.street1 || '',
    address2: transaction.property.address.street2 || '',
    city: transaction.property.address.city || '',
    state: transaction.property.address.state || '',
    zip: transaction.property.address.zip || '',
    county: transaction.property.address.county || '',
    parcelNumber: transaction.property.parcelNumber || '',
    productType: transaction.type || '',
    loanAmount: transaction.loanAmount || '',
    emdAmount: transaction.emd?.amount || 0,
    emdPercent: transaction.emd?.amount && transaction.price ? (transaction.emd.amount / transaction.price) * 100 : 0,
    salePrice: transaction.price || 0,
    loanNumber: transaction.loanNumber || '',
    estimatedClosing: transaction.dates.estimatedClosing || '',
    emdDueDate: transaction.dates.emdContractDate || '',
    escrowOpenedDate: transaction.dates.escrowOpen || '',
    contractExecutionDate: transaction.dates.contractExecutedDate || '',
    createdDate: transaction.createdAt,
  };
}
