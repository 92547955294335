import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Layout } from 'components/Layout';
import { NotFound } from 'components/NotFound';

export const Dogs404: FC<RouteComponentProps> = () => (
  <Layout>
    <NotFound />
  </Layout>
);
