import React, { FC, useMemo } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import { RouteComponentProps, Link as ReachRouterLink } from '@reach/router';
import { Table, TableBody, TableCell, TableRow, Link } from '@endpoint/blockparty';
import { TransactionSearchResult } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TableItemClicked, TrackingEvent } from 'consts/segmentProtocols';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import GenericTableHeader from 'components/GenericTableHeader';
import GenericTableRow from 'components/GenericTableRow';

import { transactionRowData, TransactionsListRowData } from './helpers';

const trackData: TableItemClicked = {
  table: 'Transactions Table',
};
const trackingEvent: TrackingEvent = 'Table Item Clicked';

interface TransactionsListTableProps extends RouteComponentProps {
  transactions: TransactionSearchResult[];
}

export const columnHeaders = (): Column<TransactionsListRowData>[] => [
  {
    Header: 'File #',
    accessor: 'fileNumber',
    minWidth: 150,
    width: 300,
  },
  {
    Header: 'Address',
    accessor: 'address',
    minWidth: 500,
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Product Type',
    accessor: 'productType',
  },
  {
    Header: 'Closing Date',
    accessor: 'closingDate',
  },
];

const tableCellInstance = (cell: Cell<TransactionsListRowData>, transactionIdentifier: string) => (
  <Link
    _hover={{ textDecoration: 'none' }}
    as={ReachRouterLink}
    to={`/transaction/${transactionIdentifier}`}
    onClick={() => {
      trackAnalytics(trackingEvent, trackData);
    }}
  >
    {cell.render('Cell')}
  </Link>
);

export const TransactionsListTable: FC<TransactionsListTableProps> = ({ transactions }) => {
  const columns = useMemo(columnHeaders, []);
  const data = useMemo(() => transactionRowData(transactions), [transactions]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
  );

  return (
    <Table label="Transactions List Table" {...getTableProps()}>
      <GenericTableHeader dataTestId="transaction-list-table-header" headerGroups={headerGroups} />
      <TableBody data-test-id="transaction-list-table-body" {...getTableBodyProps()}>
        {rows.length ? (
          rows.map((row) => {
            prepareRow(row);
            const { key: rowKey } = row.getRowProps();
            // NOTE: when routing if fileNumber is "-" - prefixed with "id" for query logic in /src/routes/Transaction/index.tsx
            const transactionIdentifier =
              row.original.fileNumber !== '-' ? row.original.fileNumber : `id-${row.original.transactionId}`;

            return (
              <GenericTableRow<TransactionsListRowData>
                key={rowKey}
                dataTestId={`row-${row.original.fileNumber}`}
                row={row}
                tableCell={(cell) => tableCellInstance(cell, transactionIdentifier)}
              />
            );
          })
        ) : (
          <TableCell py="space30">No matching records found.</TableCell>
        )}
      </TableBody>
    </Table>
  );
};

TransactionsListTable.displayName = 'TransactionListTable';
TableRow.displayName = 'TableRow';
