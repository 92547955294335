import { gql } from '@apollo/client';
import { IQuery, TransactionSearchResult } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_TRANSACTIONS = gql`
  query searchTransactions($search: TransactionsMultiMarketSearchInput) {
    searchTransactions(search: $search) {
      id
      createdAt
      fileNum
      status
      isTest
      displayStatus
      property {
        address {
          standardFormat
          county
        }
      }
      dates {
        estimatedClosing
      }
      type
      isPending
    }
  }
`;
export const GET_OCR_PSA_RESULTS = gql`
  query ocrPsaResults($where: OcrResultsWhereInput!) {
    ocrPsaResults(where: $where) {
      status
      result {
        docTypeConfidence
        propertyAddress {
          value
          confidence
        }
        propertyUnit {
          value
          confidence
        }
        propertyCity {
          value
          confidence
        }
        propertyState {
          value
          confidence
        }
        propertyZip {
          value
          confidence
        }
        propertyCounty {
          value
          confidence
        }
        parcelNumber {
          value
          confidence
        }
        productType {
          value
          confidence
        }
        purchasePrice {
          value
          confidence
        }
        loanAmount {
          value
          confidence
        }
        earnestMoneyDepositAmount {
          value
          confidence
        }
        sellerCreditsBuyerPercent {
          value
          confidence
        }
        sellerCreditsBuyerAmount {
          value
          confidence
        }
        closingDate {
          value
          confidence
        }
      }
      errorMessage
    }
  }
`;
export interface TransactionsData {
  searchTransactions: TransactionSearchResult[];
}
export interface GetTransactionsResult {
  transactions: IQuery['transactions'];
}
