import { gql } from '@apollo/client';
import { Market, Organization } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { OcrPsaRegionResult } from '@endpoint/opsware-bff-graphql-schema';

export const GET_MARKETS_AND_ORGANIZATIONS = gql`
  query GetMarketsAndOrganizations {
    organizations {
      id
      domain
      name
      brandName
      shortName
      createdAt
      updatedAt
    }
    markets {
      id
      name
      state
      active
      acceptsPrelistings
      organizationId
      organizationLegalName
      createdAt
      updatedAt
      fundingType
    }
  }
`;
export interface MarketsAndOrganizations {
  markets: Market[];
  organizations: Organization[];
}
export const GET_OCR_PSA_REGIONS = gql`
  query GetOcrPsaRegions {
    ocrPsaRegions {
      value
      label
    }
  }
`;
export interface GetOcrPsaRegionsData {
  ocrPsaRegions: OcrPsaRegionResult[];
}
