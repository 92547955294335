import React, { FC, useState } from 'react';
import { Button, Modal, ModalContent, ModalFooter, ModalSection, Radio, RadioGroup, Text } from '@endpoint/blockparty';
import { RecallReason, TodoStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { todoRecallReasonMap } from 'consts/toDoConsts';

interface ConfirmRecallReasonModalProps {
  onSubmit: (status: TodoStatus, recallReason: RecallReason) => void;
  isModalOpen: boolean;
  onClose: () => void;
  assigneeId: string;
  updateLocation: string;
}

export const ConfirmRecallReasonModal: FC<ConfirmRecallReasonModalProps> = ({
  assigneeId,
  isModalOpen,
  onClose,
  onSubmit,
  updateLocation,
}) => {
  const [recallReason, setRecallReason] = useState<RecallReason>(RecallReason.ADD_TO_SIGNING);

  const handleRadioChange = (selected: RecallReason) => {
    setRecallReason(selected);
  };

  const location = updateLocation.replace(/ /g, '-');

  return (
    <Modal
      data-test-id="recall-reason-modal"
      id={`recall-reason-${assigneeId}-${location}`}
      isOpen={isModalOpen}
      onClose={onClose}
    >
      <ModalContent>
        <ModalSection>
          <Text as="p" fontWeight="semi" pb="space60">
            Reason for Recall
          </Text>
          <RadioGroup defaultValue={recallReason} name={`recall-reason-${assigneeId}`}>
            <>
              {Object.entries(todoRecallReasonMap).map(([reason, label]) => (
                <Radio
                  key={reason}
                  label={label}
                  value={reason}
                  onChange={() => handleRadioChange(reason as RecallReason)}
                >
                  {label}
                </Radio>
              ))}
            </>
          </RadioGroup>
        </ModalSection>
        <ModalFooter>
          <Button data-test-id="cancel-todo-recall-reason" mr="space30" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            data-test-id="recall-todo"
            onClick={(): void => {
              onSubmit(TodoStatus.RECALLED, recallReason);
              onClose();
            }}
          >
            Recall To-do
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
