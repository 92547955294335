import React, { FC, useMemo, useContext } from 'react';
import { useTable, useSortBy, HeaderGroup, ColumnInstance, useFlexLayout } from 'react-table';
import {
  Table,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from '@endpoint/blockparty';
import { Document, TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { TableItemClicked, TrackingEvent } from 'consts/segmentProtocols';
import { navigate, RouteComponentProps } from '@reach/router';

import { documentsRowData } from './helpers/index';
import { ColumnSortedHeader, ColumnHeader, docColumnHeaders } from './DocumentsTableComponents';
import { DocumentsContext } from '../..';

interface DocumentsTableProps {
  documents: Document[];
}

export interface DocumentRowDataTypes {
  displayName: string;
  name: string;
  type?: string;
  deliveredTo: TransactionParticipant[];
  added: string;
  documentId: string;
  mimeType: string;
}

export const DocumentsTable: FC<DocumentsTableProps> = ({ documents }) => {
  const { fileNumber, transactionId } = useContext(DocumentsContext);
  const columns = useMemo(docColumnHeaders, []);
  const data: DocumentRowDataTypes[] = useMemo(() => documentsRowData(documents), [documents]);
  const trackData: TableItemClicked = {
    table: 'Documents Table',
  };
  const trackingEvent: TrackingEvent = 'Table Item Clicked';
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useFlexLayout,
  );

  return (
    <Table data-test-id="documents-table" label="Documents Table" {...getTableProps()}>
      <TableHeader zIndex={0}>
        {headerGroups.map((headerGroup: HeaderGroup<DocumentRowDataTypes>) => {
          const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();

          return (
            <TableHeaderRow key={key} {...headerGroupProps}>
              {headerGroup.headers.map((column: ColumnInstance<DocumentRowDataTypes>) => {
                const { key: tableHeaderKey, ...tableHeaderProps } = column.getHeaderProps(
                  column.getSortByToggleProps(),
                );

                return (
                  <TableHeaderCell key={tableHeaderKey} {...tableHeaderProps}>
                    {column.canSort ? <ColumnSortedHeader column={column} /> : <ColumnHeader column={column} />}
                  </TableHeaderCell>
                );
              })}
            </TableHeaderRow>
          );
        })}
      </TableHeader>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const { key: rowKey, ...tableRowProps } = row.getRowProps();

          return (
            <TableRow
              key={rowKey}
              cursor="pointer"
              {...tableRowProps}
              onClick={(): RouteComponentProps => {
                trackAnalytics(trackingEvent, trackData);

                return navigate(
                  `/transaction/${fileNumber || `id-${transactionId}`}/documents/${row.original.documentId}`,
                );
              }}
            >
              {row.cells.map((cell, i) => {
                const { key: cellsRowKey, ...tableCellProps } = cell.getCellProps();

                return (
                  <TableCell key={cellsRowKey} py="space30" wordBreak="break-word" {...tableCellProps}>
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

DocumentsTable.displayName = 'DocumentsTable';
