import { TransactionStage } from '@endpoint/opsware-bff-graphql-schema/dist/types';

type TransactionStageLookup = {
  [key in TransactionStage]: string;
};

export const transactionStageToIcon: TransactionStageLookup = {
  [TransactionStage.ALL_SIGNINGS_SCHEDULED]: 'PinPairBoth',
  [TransactionStage.BUYER_SIGNING_SCHEDULED]: 'PinPairSecond',
  [TransactionStage.NO_SIGNINGS_SCHEDULED]: 'PinPairOutline',
  [TransactionStage.RECORDING_COMPLETE]: 'PaperCheck',
  [TransactionStage.RELEASED_TO_RECORD]: 'PaperOutline',
  [TransactionStage.SELLER_SIGNING_SCHEDULED]: 'PinPairFirst',
  [TransactionStage.TRANSACTION_DISBURSED]: 'DollarSignCircle',
};
