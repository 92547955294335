import React, { FC } from 'react';
import { Box, Heading } from '@endpoint/blockparty';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema';
import { useEditTransactionBasicInfoContext } from 'hooks/useEditTransactionBasicInfoContext';

import { PropertyEditForm } from './PropertyEditForm';
import { Property } from './Property';

export interface BasicInfoPropertyProps {
  transaction: Transaction;
}

export const BasicInfoProperty: FC<BasicInfoPropertyProps> = ({ transaction }) => {
  const { isBeingEdited } = useEditTransactionBasicInfoContext();

  return (
    <Box data-test-id="basic-info-property">
      <Heading backgroundColor="slate0" p="space40" size="fontSize30">
        Property
      </Heading>
      {isBeingEdited ? <PropertyEditForm transaction={transaction} /> : <Property transaction={transaction} />}
    </Box>
  );
};
