import React from 'react';
import { Box, Flex, SkeletonBlock } from '@endpoint/blockparty';

export function CalendarDayLoader() {
  return (
    <Box aria-label="calendar-day-loader">
      <Box backgroundColor="slate0" height={38} />
      <Flex px="space60" py="space40">
        <SkeletonBlock height={12} mr="74px" width={58} />
        <SkeletonBlock height={12} mr="74px" width={184} />
        <SkeletonBlock height={12} maxWidth="auto" width={329} />
      </Flex>
      <Flex px="space60" py="space40">
        <SkeletonBlock height={12} mr="74px" width={58} />
        <SkeletonBlock height={12} mr="74px" width={184} />
        <SkeletonBlock height={12} maxWidth="auto" width={329} />
      </Flex>
      <Flex px="space60" py="space40">
        <SkeletonBlock height={12} mr="74px" width={58} />
        <SkeletonBlock height={12} mr="74px" width={184} />
        <SkeletonBlock height={12} maxWidth="auto" width={329} />
      </Flex>
      <Flex px="space60" py="space40">
        <SkeletonBlock height={12} mr="74px" width={58} />
        <SkeletonBlock height={12} mr="74px" width={184} />
        <SkeletonBlock height={12} maxWidth="auto" width={329} />
      </Flex>
      <Flex px="space60" py="space40">
        <SkeletonBlock height={12} mr="74px" width={58} />
        <SkeletonBlock height={12} mr="74px" width={184} />
        <SkeletonBlock height={12} maxWidth="auto" width={329} />
      </Flex>
      <Flex px="space60" py="space40">
        <SkeletonBlock height={12} mr="74px" width={58} />
        <SkeletonBlock height={12} mr="74px" width={184} />
        <SkeletonBlock height={12} maxWidth="auto" width={329} />
      </Flex>
      <Flex px="space60" py="space40">
        <SkeletonBlock height={12} mr="74px" width={58} />
        <SkeletonBlock height={12} mr="74px" width={184} />
        <SkeletonBlock height={12} maxWidth="auto" width={329} />
      </Flex>
      <Flex px="space60" py="space40">
        <SkeletonBlock height={12} mr="74px" width={58} />
        <SkeletonBlock height={12} mr="74px" width={184} />
        <SkeletonBlock height={12} maxWidth="auto" width={329} />
      </Flex>
    </Box>
  );
}
