import * as yup from 'yup';
import { TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { OpenOrderDrawerFormStep, OpenOrderDrawerFormType } from 'helpers/openOrder/openOrderFormTypes';

interface OpenOrderDrawerTerms {
  productType: TransactionType;
}

const formValidationSchema = yup.object().shape({
  property: yup.object().when(['formType', 'currentStep'], {
    is: (formType: string, currentStep: string) =>
      formType === OpenOrderDrawerFormType.MANUAL && currentStep === OpenOrderDrawerFormStep.STEP1,
    then: yup.object().shape({
      address: yup.object().shape({
        street1: yup.string().required('Required').trim(),
        street2: yup.string().trim(),
        city: yup.string().required('Required').trim(),
        state: yup.string().required('Required').trim(),
        zip: yup.string().required('Required').trim(),
        county: yup.string().required('Required').trim(),
      }),
      parcelNumber: yup.string().trim(),
    }),
  }),
  market: yup.string(),
  documentUploaded: yup.boolean().when('formType', {
    is: (formType: string) => formType === OpenOrderDrawerFormType.UPLOAD,
    then: yup
      .boolean()
      .required('Required')
      .test('isUploaded', 'isNotUploaded', (value) => value === true),
  }),
  terms: yup
    .object()
    .when(['formType', 'currentStep'], {
      is: (formType: string, currentStep: string) =>
        formType === OpenOrderDrawerFormType.MANUAL && currentStep === OpenOrderDrawerFormStep.STEP1,
      then: yup.object().shape({
        productType: yup.string().required('Required').trim(),
      }),
    })
    .when(['formType', 'currentStep', '.'], {
      is: (formType: string, currentStep: string, terms: OpenOrderDrawerTerms) => {
        const formStepValidation =
          formType === OpenOrderDrawerFormType.MANUAL && currentStep === OpenOrderDrawerFormStep.STEP2;

        const productTypeValidation =
          terms?.productType !== TransactionType.REFINANCE && terms?.productType !== TransactionType.EQUITY;

        return formStepValidation && productTypeValidation;
      },
      then: yup.object().shape({
        salePrice: yup.number().required('Required').min(0, 'Sale Price must be greater than or equal to 0'),
        loanNumber: yup.string().trim(),
      }),
      otherwise: yup.object().shape({
        loanNumber: yup.string().trim(),
      }),
    }),
  organization: yup.number().when(['formType', 'currentStep'], {
    is: (formType: string, currentStep: string) =>
      formType === OpenOrderDrawerFormType.MANUAL && currentStep === OpenOrderDrawerFormStep.STEP1,
    then: yup.number().required('Required'),
  }),
});

export default formValidationSchema;
