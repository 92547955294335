export enum UpdateContactField {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ENTITY_NAME = 'entityName',
  MIDDLE_NAME = 'middleName',
  LICENSE_NUMBER = 'licenseNumber',
  NMLS_ID = 'nmlsId',
  MLS_ID = 'mlsId',
  STATE_OF_OPERATION = 'stateOfOperation',
  ROLE = 'role',
  CONTACT_TYPE = 'contactType',
  PHONE = 'phoneNumber',
  EMAIL = 'email',
}

export const editContactDisplayableErrors = {
  phoneError: 'Updating phone is not allowed at the moment',
  endpointAccountError: 'Cannot edit phone/email of users with an Endpoint account',
  emailExistsError: 'The provided email already exists.',
};
