import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { RelatedContactsProvider } from 'hooks/useRelatedContactsProvider';

interface ContactsProps extends RouteComponentProps {
  participantId?: string;
}

export const Contacts: FC<ContactsProps> = ({ children }) => (
  <RelatedContactsProvider>{children}</RelatedContactsProvider>
);
