import React, { FC } from 'react';
import {
  Tag,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Icon,
  LayoutSubHeader,
  Stack,
  Text,
  Tooltip,
} from '@endpoint/blockparty';
import { Link, RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';
import { Transaction, TransactionStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { mapStatusText, mapStatusToColorVariant } from 'helpers/statusHelper';
import { prettyDate, formatDateDifference } from 'helpers/datetimeHelper';
import { transactionStatusBgColorMap, transactionStatusMap, transactionTypeMap } from 'consts/transactionConsts';
import { ErrorMessage } from 'components/ErrorMessage';
import { buildQueryInput } from 'helpers/buildQueryInput';
import { v4 } from 'uuid';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { GET_TRANSACTION_HEADER_DATA, GetTransactionHeader } from './queries';
import { TransactionHeaderUsers } from './TransactionHeaderUsers';

interface TransactionProps extends RouteComponentProps {
  transactionIdentifier: string;
}

export const SubHeader: FC<TransactionProps> = ({ transactionIdentifier }) => {
  const { isMarkTransactionAsTestEnabled } = useFlags();

  const queryInput = buildQueryInput(transactionIdentifier);
  const { data, error } = useQuery<GetTransactionHeader>(GET_TRANSACTION_HEADER_DATA, {
    variables: {
      where: queryInput,
    },
  });

  const breadcrumbs = data?.breadCrumbLinks;
  const escrowPending = data?.transaction.escrowPending && data?.transaction.status !== TransactionStatus.CANCELLED;

  const isTest = data?.transaction.isTest;

  return (
    <LayoutSubHeader flexDirection="column" p="space60">
      {error && <ErrorMessage />}
      {data?.transaction && (
        <>
          <Flex justifyContent="space-between" mb="space50">
            <Flex>
              <Tag
                data-test-id="tasks-header-status-Tag"
                mr="space30"
                variantColor={
                  escrowPending
                    ? 'marigold500'
                    : mapStatusToColorVariant(data.transaction.status, transactionStatusBgColorMap)
                }
              >
                {escrowPending ? 'Order Processing' : mapStatusText(data.transaction.status, transactionStatusMap)}
              </Tag>
              {isTest && isMarkTransactionAsTestEnabled && (
                <Box>
                  <Text color="textAlert" size="fontSize10">
                    Test
                  </Text>
                  <Icon color="carbon200" mx="space10" name="Bullet" />
                </Box>
              )}
              <Text data-test-id="tasks-header-transaction-type" size="fontSize10">
                {data?.transaction.type && mapStatusText(data.transaction.type, transactionTypeMap)}
              </Text>
              <Icon color="carbon200" mt="space10" mx="space10" name="Bullet" />
              <Icon color="carbon500" mr="space20" name="Pin" size="medium" />
              <Text data-test-id="tasks-header-address" size="fontSize10">
                {data.transaction.property.address.standardFormat || ''}
              </Text>
            </Flex>
            <Box>
              {data.transaction.status !== TransactionStatus.PRELISTING &&
                data.transaction.status !== TransactionStatus.CANCELLED && (
                  <TransactionClosingDate transaction={data.transaction} />
                )}
            </Box>
          </Flex>
          <Flex justifyContent="space-between">
            <Box>
              <Breadcrumb size="fontSize50">
                <BreadcrumbItem>
                  {data.transaction.fileNum ? (
                    <BreadcrumbLink as={Link} to="./">
                      File #{data.transaction.fileNum}
                    </BreadcrumbLink>
                  ) : (
                    <BreadcrumbLink as={Link} size="fontSize60" to="./">
                      <Tooltip label="Generating File Number" state={{ placement: 'bottom' }}>
                        <Text size="fontSize50" tabIndex={0}>
                          File # --------
                        </Text>
                      </Tooltip>
                    </BreadcrumbLink>
                  )}
                </BreadcrumbItem>
                {breadcrumbs?.map((breadcrumb, idx) => {
                  const isCurrent = idx === breadcrumbs.length - 1;
                  const breadcrumbKey = v4();

                  return (
                    <BreadcrumbItem key={`${breadcrumb.label}-${breadcrumbKey}`} isCurrentPage={isCurrent}>
                      {breadcrumb.path && !isCurrent ? (
                        <BreadcrumbLink as={Link} to={breadcrumb.path}>
                          {breadcrumb.label}
                        </BreadcrumbLink>
                      ) : (
                        <BreadcrumbLink>{breadcrumb.label}</BreadcrumbLink>
                      )}
                    </BreadcrumbItem>
                  );
                })}
              </Breadcrumb>
            </Box>
            <Box>
              {data?.transaction && (
                <TransactionHeaderUsers
                  fileNumber={data.transaction.fileNum}
                  transactionParticipants={data.transaction.participants}
                />
              )}
            </Box>
          </Flex>
        </>
      )}
    </LayoutSubHeader>
  );
};

interface TransactionAddressProps {
  transaction: Transaction;
}

export const TransactionClosingDate: FC<TransactionAddressProps> = ({ transaction }) => (
  <Stack alignItems="center" direction="row" spacing="space30">
    <Icon color="carbon500" name="CalendarDateRange" size="medium" />
    <Text data-test-id="transaction-subheader-closing-date" size="fontSize10">
      {transaction.dates.estimatedClosing &&
        `${prettyDate(transaction.dates.estimatedClosing)} (${formatDateDifference(
          new Date(transaction.dates.estimatedClosing),
          new Date(),
        )})`}
    </Text>
  </Stack>
);
