import * as React from 'react';
import { createContext, useContext, useState, useCallback, useMemo, FC } from 'react';

export enum DocumentReviewType {
  OPEN_ORDER = 'OPEN_ORDER',
  ORDER_REVIEW = 'ORDER_REVIEW',
  PRELIM_CONVERSION = 'PRELIM_CONVERSION',
}

export type DocumentReviewContextType = {
  documentId: string;
  documentUrl: string;
  documentRegion: string;
  documentReviewType: DocumentReviewType;
  setDocumentProperties: (props: DocumentReviewInputProps) => void;
  clearDocumentProperties: () => void;
};

interface DocumentReviewInputProps {
  documentId: string;
  documentUrl: string;
  documentRegion: string;
  documentReviewType: DocumentReviewType;
}

const initialState: DocumentReviewInputProps = {
  documentId: '',
  documentUrl: '',
  documentRegion: '',
  documentReviewType: DocumentReviewType.ORDER_REVIEW,
};

const DocumentReviewContext = createContext<DocumentReviewContextType>({
  ...initialState,
  setDocumentProperties: () => {},
  clearDocumentProperties: () => {},
});

export function useDocumentReviewContext() {
  return useContext(DocumentReviewContext);
}

/**
 * @DocumentReviewProvider
 ** - PDF/JPG Document Rendering
 ** - Routing logic
 */
export const DocumentReviewProvider: FC = ({ children }) => {
  const [docReviewData, setDocumentProperties] = useState<DocumentReviewInputProps>({ ...initialState });

  const clearDocumentProperties = useCallback(() => {
    setDocumentProperties({
      ...initialState,
    });
  }, []);

  const values: DocumentReviewContextType = useMemo(
    () => ({
      setDocumentProperties,
      clearDocumentProperties,
      ...docReviewData,
    }),
    [docReviewData, clearDocumentProperties, setDocumentProperties],
  );

  return <DocumentReviewContext.Provider value={values}>{children}</DocumentReviewContext.Provider>;
};
