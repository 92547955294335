import React, { FC, ReactNode, useCallback, useState } from 'react';
import {
  Flex,
  Popover,
  Text,
  usePopoverState,
  Box,
  IconButton,
  PopoverTrigger,
  Button,
  Divider,
  ListChoice,
  useToast,
  InputGroup,
  Search,
  Avatar,
} from '@endpoint/blockparty';
import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TrackingEvent, DocumentAssignmentChange } from 'consts/segmentProtocols';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { useMutation } from '@apollo/client';
import {
  UpdatedDocument,
  ADD_DOCUMENT_PARTICIPANT,
  GET_DOCUMENTS,
  REMOVE_DOCUMENT_PARTICIPANT,
} from 'routes/Transaction/Documents/queries';
import { giveFirstWord } from 'helpers/formatText';
import { ErrorMessage } from 'components/ErrorMessage';
import { getContactSelectOptions, ContactOption } from 'helpers/transactionParticipants';
import { AllContactTypes } from 'consts/contactDetails';
import { ButtonWriteRequired } from 'components/ButtonWriteRequired';

import { filterParticipants } from './helpers';
import { ParticipantItem } from '../ParticipantItem';

interface AddCustomerProps {
  documentId: string;
  documentName: string;
  documentUsers: TransactionParticipant[];
  transactionIdentifier: string;
  transactionParticipants: TransactionParticipant[] | [];
}

interface Context {
  context: string;
}

const addCustomerTrackingEvent: TrackingEvent = 'Document Assignment Added';
const removeCustomerTrackingEvent: TrackingEvent = 'Document Assignment Removed';

export const AddCustomerPopover: FC<AddCustomerProps> = React.memo(
  ({ documentId, documentName, transactionIdentifier, documentUsers = [], transactionParticipants = [] }) => {
    const [hasError, setHasError] = useState(false);
    const popover = usePopoverState({ placement: 'top-start' });
    const participants = filterParticipants(transactionParticipants, documentUsers);
    const contactSelectOptions = getContactSelectOptions(participants);

    const handlePopoverClose = useCallback(() => popover.hide(), [popover]);

    const [currentParticipantId, setCurrentParticipantId] = useState<string>('');

    const toast = useToast();

    const trackingData: DocumentAssignmentChange = React.useMemo(
      () => ({
        assignee: currentParticipantId,
        fileNumber: transactionIdentifier,
        name: documentName,
        id: documentId,
      }),
      [currentParticipantId, documentId, documentName, transactionIdentifier],
    );

    const removeCustomerMutationHandler = React.useMemo(
      () => ({
        onError(): void {
          toast({
            description: 'Action was not successful',
            duration: 5000,
            iconColor: 'watermelon500',
            icon: 'ErrorCircle',
          });
        },
        onCompleted(): void {
          toast({
            description: 'Document recalled',
            duration: 5000,
            icon: 'CheckCircle',
          });

          trackAnalytics(removeCustomerTrackingEvent, trackingData);
        },
      }),
      [toast, trackingData],
    );

    const [addParticipant, { loading: isAddingParticipant }] = useMutation<UpdatedDocument>(ADD_DOCUMENT_PARTICIPANT, {
      refetchQueries: [
        {
          query: GET_DOCUMENTS,
          variables: {
            where: { fileNum: transactionIdentifier },
          },
        },
      ],
    });

    const [removeParticipant, { loading: isRemovingParticipant }] = useMutation<UpdatedDocument>(
      REMOVE_DOCUMENT_PARTICIPANT,
      {
        refetchQueries: [
          {
            query: GET_DOCUMENTS,
            variables: {
              where: { fileNum: transactionIdentifier },
            },
          },
        ],
        ...removeCustomerMutationHandler,
      },
    );

    const handleRemoveParticipant = React.useCallback(
      (docId: string, participantId: string): void => {
        void removeParticipant({
          variables: {
            where: { id: docId },
            forParticipants: [{ id: participantId }],
          },
        });
      },
      [removeParticipant],
    );

    const handleAddParticipant = React.useCallback(
      (docId: string, participantId: string): void => {
        void addParticipant({
          variables: {
            where: { id: docId },
            forParticipants: [{ id: participantId }],
          },
          onError(): void {
            setHasError(true);
          },
          onCompleted(): void {
            toast({
              description: 'Document delivered',
              duration: 5000,
              icon: 'CheckCircle',
              onUndo: () => {
                handleRemoveParticipant(documentId, participantId);
              },
            });

            trackAnalytics(addCustomerTrackingEvent, trackingData);
          },
        });
      },
      [addParticipant, documentId, handleRemoveParticipant, toast, trackingData],
    );

    const isAddCustomerDisabled = React.useMemo(
      () => isAddingParticipant || isRemovingParticipant,
      [isAddingParticipant, isRemovingParticipant],
    );

    const formatOptionLabel = React.useCallback((data: unknown, { context }: Context): ReactNode => {
      const { fullName, formattedRoles, src, value, label } = data as Partial<ContactOption>;

      if (context === 'value') {
        return label;
      }

      return (
        <Flex alignItems="center">
          <Avatar alignSelf="flex-start" mr="space40" name={value || ''} size={24} src={src} />
          <Flex flexDirection="column">
            <Text maxWidth="280px" overflow="hidden" size="fontSize20" textOverflow="ellipsis" whiteSpace="nowrap">
              {fullName}
            </Text>
            <Text
              color="carbon500"
              maxWidth="280px"
              overflow="hidden"
              size="fontSize10"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {formattedRoles}
            </Text>
          </Flex>
        </Flex>
      );
    }, []);

    return (
      <>
        {hasError && <ErrorMessage />}
        <PopoverTrigger {...popover} pt="space50">
          <ButtonWriteRequired
            data-test-id="add-customer-button"
            isDisabled={isAddCustomerDisabled}
            mr="space40"
            variant="outline"
            variantColor="blue"
          >
            Add Customer
          </ButtonWriteRequired>
        </PopoverTrigger>
        <Popover
          data-test-id="popover-add-customer"
          px="space0"
          width={400}
          {...popover}
          aria-label="popover-add-customer"
        >
          <Flex alignItems="center" justifyContent="space-between" pb="space60" px="space50">
            <Text fontWeight="semi">Add Customers</Text>
            <IconButton
              data-test-id="popover-add-customer-close-button"
              label="Close Popover"
              name="Close"
              variant="unstyled"
              variantColor="carbon"
              onClick={handlePopoverClose}
            />
          </Flex>
          <Divider />
          <Box pt="space50" px="space50">
            <InputGroup data-test-id="add-assignee-input-group">
              <Search
                aria-labelledby="add-customer-search-panel"
                classNamePrefix="list"
                data-test-id="add-customer-search-pannel"
                formatOptionLabel={formatOptionLabel}
                options={contactSelectOptions}
                placeholder="Search by name or role"
                value={null}
                onChange={(option: ContactOption): void => {
                  if (option) {
                    handlePopoverClose();
                    setCurrentParticipantId(option.id);
                    handleAddParticipant(documentId, option.id);
                  }
                }}
              />
            </InputGroup>
          </Box>
          <Box data-test-id="popover-add-customer-content" maxHeight={456} minWidth={400} overflow="auto" pt="space50">
            {!participants.length ? (
              <Text px="space50">All contacts have access to this document.</Text>
            ) : (
              participants.map((participant: TransactionParticipant): ReactNode => {
                const { firstName, lastName, entityName } = participant.contact as AllContactTypes;
                const avatarName = entityName
                  ? giveFirstWord(entityName)
                  : `${giveFirstWord(firstName || '')} ${giveFirstWord(lastName || '')}`;
                const fullName = entityName || `${firstName} ${lastName}`;

                return (
                  <ListChoice
                    key={`add-user-item-${participant.id}`}
                    data-test-id={`list-choice-add-customer-${participant.id}`}
                    px="space50"
                    py="space20"
                    onClick={() => {
                      handlePopoverClose();
                      setCurrentParticipantId(participant.id);
                      handleAddParticipant(documentId, participant.id);
                    }}
                  >
                    <ParticipantItem
                      avatarName={avatarName}
                      fullName={fullName}
                      px="space0"
                      py="space30"
                      roles={participant.roles}
                    />
                  </ListChoice>
                );
              })
            )}
          </Box>
        </Popover>
      </>
    );
  },
);

AddCustomerPopover.displayName = 'AddCustomerPopover';
ListChoice.displayName = 'ListChoice';
