import { OpsTaskStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';

export const mapStatusToLabel: { [key in OpsTaskStatus]: string } = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  WAIVED: 'Waived',
  PARTIAL: 'Partial',
  WAITING: 'Waiting',
};

export const mapStatusToIcon: { [key in OpsTaskStatus]: { color: string; icon: IconNames } } = {
  NOT_STARTED: { color: 'marigold500', icon: 'CircleOutline' },
  IN_PROGRESS: { color: 'marigold500', icon: 'CircleSolid' },
  COMPLETED: { color: 'aloe500', icon: 'CircleSolid' },
  WAIVED: { color: 'mist200', icon: 'CircleSolid' },
  PARTIAL: { color: 'turquoise500', icon: 'CircleOutline' },
  WAITING: { color: 'mist500', icon: 'CircleOutline' },
};
