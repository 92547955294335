import {
  differenceInDays,
  format,
  add,
  formatISO,
  parseISO,
  isThisYear,
  isToday,
  isValid,
  differenceInMinutes,
  differenceInHours,
  differenceInCalendarDays,
  differenceInMonths,
  differenceInCalendarWeeks,
  startOfDay,
} from 'date-fns';

export const formatDateDifference = (baseDate: Date, dateToCompare: Date): string => {
  const dayDifference = differenceInCalendarDays(dateToCompare, baseDate);

  if (Number.isNaN(dayDifference)) {
    return '';
  }

  if (dayDifference === 0) {
    return 'today';
  }

  if (dayDifference < 0) {
    if (dayDifference === -1) {
      return 'tomorrow';
    }

    return `in ${Math.abs(dayDifference)} days`;
  }

  if (dayDifference === 1) {
    return 'yesterday';
  }

  return `${dayDifference} days overdue`;
};

export const dateFormatter = (date: Date | string | null, formatType: string): string => {
  if (!date) {
    return format(new Date(), formatType);
  }

  return format(new Date(date), formatType);
};

export const isDateAlert = (date: Date): boolean => differenceInDays(new Date(), date) >= 0;

export const prettyDate = (date: Date | string): string => format(new Date(date), 'MMM d, yyyy');

export function todoWithDueDate(todoDueDate: Date | string | undefined | null): Date {
  if (!todoDueDate) {
    return new Date();
  }

  return new Date(todoDueDate);
}

export const datePlusDays = (startTime: Date, days: number): string => {
  const addDays: Date = add(new Date(startTime), { days });

  return formatISO(addDays);
};

export const generateInternalNoteDate = (date: string): string => {
  const isWithinThisYear = isThisYear(parseISO(date));
  const isWithinToday = isToday(parseISO(date));

  if (isWithinThisYear && !isWithinToday) return dateFormatter(parseISO(date), 'MMM d');
  if (isWithinToday) return dateFormatter(parseISO(date), 'h:mm a');

  return dateFormatter(parseISO(date), 'MMM d, yyy');
};

interface CalculateDateDifferenceMap {
  [key: string]: (leftDate: Date, rightDate: Date) => string;
}

const calculateDateDifferenceMap = new Map<number, (leftDate: Date, rightDate: Date) => string>();

calculateDateDifferenceMap.set(-364, (_, __) => '1 year ago');
calculateDateDifferenceMap.set(
  -90,
  (left, right) => `${differenceInMonths(startOfDay(left), startOfDay(right))} months ago`,
);

calculateDateDifferenceMap.set(-13, (left, right) => `${differenceInCalendarWeeks(left, right)} weeks ago`);
calculateDateDifferenceMap.set(-6, (left, right) => `${differenceInCalendarWeeks(left, right)} week ago`);
calculateDateDifferenceMap.set(-1, (left, right) => `${differenceInCalendarDays(left, right)} days ago`);
calculateDateDifferenceMap.set(0, (_, __) => 'Yesterday');
calculateDateDifferenceMap.set(1, (_, __) => 'Today');
calculateDateDifferenceMap.set(2, (_, __) => 'Tomorrow');
calculateDateDifferenceMap.set(7, (left, right) => `in ${differenceInCalendarDays(right, left)} days`);
calculateDateDifferenceMap.set(14, (left, right) => `in ${differenceInCalendarWeeks(right, left)} week`);
calculateDateDifferenceMap.set(91, (left, right) => `in ${differenceInCalendarWeeks(right, left)} weeks`);
calculateDateDifferenceMap.set(
  365,
  (left, right) => `in ${differenceInMonths(startOfDay(right), startOfDay(left))} months`,
);

calculateDateDifferenceMap.set(Number.MAX_SAFE_INTEGER, (left, right) => 'in 1 year');

const mapAsArray = Array.from(calculateDateDifferenceMap, ([key, value]) => ({
  key,
  value,
}));

export function calculateDateDifference(baseDate: Date, compareDate: Date): string {
  const numberOfDays = differenceInCalendarDays(compareDate, baseDate);

  for (const { key, value } of mapAsArray) {
    if (numberOfDays < key) {
      return value(baseDate, compareDate);
    }
  }

  return '';
}

export function calculateDateDifferenceTimeElapsed(baseDate: Date, compareDate: Date): string {
  const numberOfMinutes = Math.abs(differenceInMinutes(compareDate, baseDate));
  const numberOfHours = Math.abs(differenceInHours(compareDate, baseDate));
  const numberOfDays = Math.abs(differenceInCalendarDays(compareDate, baseDate));

  if (numberOfMinutes === 1) {
    return `${numberOfMinutes} min ago`;
  }

  if (numberOfMinutes <= 119) {
    return `${numberOfMinutes} mins ago`;
  }

  if (numberOfHours <= 47) {
    return `${numberOfHours} hrs ago`;
  }

  if (numberOfDays <= 13) {
    return `${numberOfDays} days ago`;
  }

  for (const { key, value } of mapAsArray) {
    if (numberOfDays > Math.abs(key)) {
      return value(baseDate, compareDate);
    }
  }

  return '';
}

export function isValidDate(date?: Date): boolean {
  if (!date) return false;
  const validateDate = new Date(date);

  return isValid(validateDate);
}

/**
 * Applies an end of day timestamp to ISO date string (2020-01-01) before generating the Date object.
 * This helps prevent local timezone conversions from change the date to the previous day.
 */
export function toDateEndOfDay(isoDateString: string): Date {
  return new Date(`${isoDateString}T23:00:00`);
}

/**
 * @param date The date selected in the client
 * @returns The selected date but the time set to noon "local" time for the client
 * @summary The underlying issue has been that when we change the date, the time/time-zone info of the previous value was maintained. This would cause off by 1 date issues when a user in the east coast would set the original date but then a user in the west coast would modify the selected date.
 */
export function toLocalIsoDate(date: Date): Date {
  // Noon "local" time will be in the same date across the United States (Hawaii to Puerto Rico)
  return new Date(date.setHours(12, 0, 0, 0));
}
