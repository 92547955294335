import { gql } from '@apollo/client';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_TRANSACTION_INFO = gql`
  query getTransactionInfo($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      id
      createdAt
      fileNum
      status
      isTest
      qualityControlStatus
      loanAmount
      loanNumber
      price
      isOpsReviewComplete
      marketId
      organizationId
      qualityControlFailureReasons
      requireSellingAgent
      requireListingAgent
      source
      emd {
        amount
      }
      buyerSigning {
        date
      }
      sellerSigning {
        date
      }
      property {
        parcelNumber
        propertyImage
        address {
          standardFormat
          street1
          street2
          city
          state
          zip
          county
        }
      }
      documents {
        id
        type
        createdAt
        storage {
          url
        }
      }
      milestones {
        id
        type
        name
        description
        position
        status
        pinnedItems {
          data
        }
      }
      type
      dates {
        estimatedClosing
        escrowOpen
        cancellationDate
        titleProductDelivered
        prelistingOpened
        contractExecutedDate
        emdContractDate
      }
      notes {
        id
        text
        author {
          id
          firstName
          lastName
          imageUrl
        }
        createdAt
        isPinned
      }
      participants {
        id
        roles
        isDirectingContact
        contact {
          id
          contactType
          isOnboarded
          email
          phone
          ... on AgentContact {
            licenseNumber
            stateOfOperation
            firstName
            lastName
            middleName
          }
          ... on LenderContact {
            nmlsId
            firstName
            lastName
            middleName
          }
          ... on IndividualContact {
            firstName
            lastName
            middleName
          }
          ... on EntityContact {
            entityName
          }
        }
      }
    }
  }
`;

export const UPDATE_TRANSACTION_BASIC_INFO = gql`
  mutation updateTransaction($where: UpdateTransactionWhereUniqueInput!, $updateTransaction: TransactionUpdateInput!) {
    updateTransaction(where: $where, data: $updateTransaction) {
      id
      fileNum
      status
      loanAmount
      loanNumber
      price
      qualityControlStatus
      isTest
      isOpsReviewComplete
      marketId
      qualityControlFailureReasons
      cancellationReason
      cancellationNote
      requireSellingAgent
      requireListingAgent
      participants {
        id
        roles
      }
      emd {
        amount
      }
      property {
        parcelNumber
        propertyImage
        address {
          standardFormat
          street1
          street2
          city
          state
          zip
          county
        }
      }
      type
      dates {
        estimatedClosing
        escrowOpen
        cancellationDate
        titleProductDelivered
        prelistingOpened
        contractExecutedDate
        emdContractDate
      }
    }
  }
`;

export interface GetTransactionType {
  transaction: Transaction;
}
