import { ApolloClient, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { cache } from './apollo/ApolloCache';
import { typeDefs } from './apollo/typeDefs';
import { getAuthToken, signOut } from './components/Auth/helpers';
import pckg from '../package.json';

export const createClient = (uri: string, buildVersion: string) => {
  const httpLink = createHttpLink({
    uri,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAuthToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  // https://www.apollographql.com/docs/link/links/error/
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err?.extensions?.code) {
          case 'UNAUTHENTICATED':
            // error code is set to UNAUTHENTICATED
            // when AuthenticationError thrown in resolver
            // eslint-disable-next-line no-void
            void signOut();
            break;
          default:
            break;
        }
      }
    }

    if (typeof networkError === 'string' && networkError === 'No current user') {
      void signOut();
    }

    return undefined;
  });

  // link: from([]) array order is important, 'from' method concatenates
  // in order of error, auth, http
  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache,
    typeDefs,
    name: pckg.name,
    version: buildVersion,
  });
};
