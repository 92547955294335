import React, { FC } from 'react';
import { Text } from '@endpoint/blockparty';
import { v4 } from 'uuid';

interface MultiLineTextProps {
  text: string;
}

export const MultiLineText: FC<MultiLineTextProps> = ({ text }) => {
  const linesOfText = text
    .trim()
    .split('\n')
    .map((line) => line.trim());

  return (
    <>
      {linesOfText.map((line, index) => (
        <Text key={v4()}>{line}</Text>
      ))}
    </>
  );
};
