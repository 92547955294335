import { find } from 'lodash';
import { Todo, TodoStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { OperationVariables } from '@apollo/client';

import { FormValues } from '..';

export type MutationData = {
  mutationType: 'updateTodoAssignment' | 'createTodoAssignment' | 'updateTodo';
  mutationData: OperationVariables;
};

export function setEditTodoMutationData(originalData: Todo, formData: FormValues): MutationData[] {
  const dataToMutate: MutationData[] = [];
  const formDataDueISO = new Date(formData.due).toISOString();
  const originalDataDueISO = new Date(originalData.due).toISOString();

  const updateTodoMutation: MutationData = {
    mutationType: 'updateTodo',
    mutationData: {
      variables: {
        data: {},
        where: {
          id: originalData.id,
        },
      },
    },
  };

  formData.todoAssignments.forEach((formDataAssignment) => {
    const originalAssignment = find(originalData.assignments, {
      id: formDataAssignment.id,
    });

    const isOriginalUnchanged = originalAssignment && formDataAssignment.status === originalAssignment.status;

    // indicates that someone removed them, then added them back
    const isOriginalDelivered =
      originalAssignment &&
      originalAssignment.status !== TodoStatus.RECALLED &&
      originalAssignment.status !== TodoStatus.DELIVERED &&
      formDataAssignment.status === TodoStatus.DELIVERED;

    const isOriginalChangedToRecalled =
      originalAssignment &&
      originalAssignment.status !== TodoStatus.RECALLED &&
      formDataAssignment.status === TodoStatus.RECALLED;

    // if original assignment status was RECALLED then changed to DELIVERED
    const isOriginalRecalledAndStatusChangedDelivered =
      originalAssignment &&
      originalAssignment.status === TodoStatus.RECALLED &&
      formDataAssignment.status === TodoStatus.DELIVERED;

    const isNewAssignee = !originalAssignment && formDataAssignment.status !== TodoStatus.RECALLED;

    if (isOriginalUnchanged || isOriginalDelivered) {
      return;
    }

    if (isNewAssignee || isOriginalRecalledAndStatusChangedDelivered) {
      dataToMutate.push({
        mutationType: 'createTodoAssignment',
        mutationData: {
          variables: {
            data: {
              status: TodoStatus.DELIVERED,
              todo: {
                connect: {
                  id: originalData.id,
                },
              },
              assignee: {
                connect: {
                  id: formDataAssignment.todoAssigneeId,
                },
              },
            },
          },
        },
      });
    }

    if (originalAssignment && isOriginalChangedToRecalled) {
      const updateTodoAssignmentMutation: MutationData = {
        mutationType: 'updateTodoAssignment',
        mutationData: {
          variables: {
            data: {
              status: TodoStatus.RECALLED,
              recallReason: formDataAssignment.recallReason,
            },
            where: {
              id: originalAssignment.id,
            },
          },
        },
      };

      dataToMutate.push(updateTodoAssignmentMutation);
    }
  });

  if (formDataDueISO !== originalDataDueISO) {
    updateTodoMutation.mutationData.variables.data.due = formDataDueISO;
  }

  if (formData.escrowNote !== originalData.escrowNote) {
    if (formData.escrowNote) {
      updateTodoMutation.mutationData.variables.data.escrowNote = formData.escrowNote;
    }
  }

  if (Object.keys(updateTodoMutation.mutationData.variables.data).length > 0) {
    dataToMutate.push(updateTodoMutation);
  }

  return dataToMutate;
}
