import React from 'react';
import { Box, Flex, SkeletonBlock, SkeletonText } from '@endpoint/blockparty';

export function SkeletonOpenOrderPsaUploadWidget() {
  return (
    <Box data-test-id="skeleton-open-order-psa-upload-widget">
      <Flex flexDirection="column" mt="space60">
        <SkeletonText lines={2} py="space30" width="80%" />
        <SkeletonText lines={1} py="space30" width="20%" />
        <SkeletonBlock height={12} maxWidth="auto" my="space30" />
        <SkeletonBlock height={24} maxWidth="auto" my="space30" />
      </Flex>
    </Box>
  );
}
