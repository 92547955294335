import React, { FC, useContext } from 'react';
import {
  Authenticator,
  SignIn,
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  VerifyContact,
} from 'aws-amplify-react';
import { UsernameAttributes } from 'aws-amplify-react/lib-esm/Auth/common/types';
import { IAuthenticatorProps } from 'aws-amplify-react/lib-esm/Auth/Authenticator';
import * as FullStory from '@fullstory/browser';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ConfigContext } from 'App';
import * as Sentry from '@sentry/react';
import { meVar } from 'apollo/ApolloCache';
import { UserAuthWrapper } from 'components/Auth/UserAuthWrapper';

import { getCurrentUser, OpswareUser } from './helpers';

const AuthenticatedContent: FC<IAuthenticatorProps> = ({ authState, children, ...props }) => {
  const config = useContext(ConfigContext);
  const ldClient = useLDClient();

  let currentUser;

  if (authState === 'signedIn') {
    try {
      const currentUserResponse = getCurrentUser();

      currentUser = currentUserResponse;
    } catch (error) {
      console.error(`The following error occurred: ${error}`);
    }

    if (currentUser) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      currentUser.then((user: OpswareUser) => {
        meVar(user);
      });

      // if the user is authenticated and we're in produciton
      // we want to add their info to Fullstory
      if (config.FULLSTORY_ORG) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        currentUser.then((user: OpswareUser) => {
          FullStory.identify(user.id, user);
        });
      }

      // if env has SEGMENT_WRITE_KEY add user info to Segment
      if (config.SEGMENT_WRITE_KEY) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        currentUser.then((user: OpswareUser) => {
          window.analytics.identify(user.email, {
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            cognitoId: user.id,
            organization: user.organization,
          });
        });
      }

      // if env has LAUNCH_DARKLY_CLIENT_ID add user info to LaunchDarkly
      if (config.LAUNCH_DARKLY_CLIENT_ID) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        currentUser.then((user: OpswareUser) => {
          const loggedInUser = {
            key: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          };

          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          ldClient?.identify(loggedInUser);
        });
      }

      if (config.SENTRY_DSN) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        currentUser.then((user: OpswareUser) => {
          Sentry.setUser({ ID: user.id });
        });
      }
    }
  }

  if (authState !== 'signedIn') {
    return null;
  }

  return <UserAuthWrapper>{children}</UserAuthWrapper>;
};

/*
https://aws-amplify.github.io/docs/js/react
There's a confusing hide prop on the provided Authenitcator component that doesn't
work when their hideDefault prop is set to true. So if anything needs to be
hidden with a feature flag, take it out of the component's return, set it to a
constant, do your ternary check there, then include that constant child component in the Authenticator component.
*/
export const Auth: FC = ({ children }) => (
  <Authenticator hideDefault theme={{ container: { height: '100%' } }} usernameAttributes={UsernameAttributes.EMAIL}>
    <SignIn />
    <ConfirmSignIn />
    <ForgotPassword />
    <RequireNewPassword />
    <VerifyContact />
    <AuthenticatedContent>{children}</AuthenticatedContent>
  </Authenticator>
);
