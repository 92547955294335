import React from 'react';
import { TableHeader, TableHeaderRow, TableHeaderCell } from '@endpoint/blockparty';
import { ColumnInstance, HeaderGroup } from 'react-table';

type DataItem = {};

export interface GenericTableHeaderProps<T extends DataItem> {
  headerGroups: HeaderGroup<T>[];
  dataTestId: string;
  columnHeader?: (column: ColumnInstance<T>) => JSX.Element;
}

const GenericTableHeader = <T extends DataItem>({
  headerGroups,
  dataTestId,
  columnHeader,
}: GenericTableHeaderProps<T>) => (
  <TableHeader data-test-id={dataTestId} zIndex={0}>
    {headerGroups.map((headerGroup) => {
      const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();

      return (
        <TableHeaderRow key={key} {...headerGroupProps}>
          {headerGroup.headers.map((column) => {
            const { key: headerKey, ...headerProps } = column.getHeaderProps(
              column.getSortByToggleProps && column.getSortByToggleProps(),
            );

            return (
              <TableHeaderCell key={headerKey} {...headerProps}>
                {(columnHeader && columnHeader(column)) || <>{column.render('Header')}</>}
              </TableHeaderCell>
            );
          })}
        </TableHeaderRow>
      );
    })}
  </TableHeader>
);

export default GenericTableHeader;
