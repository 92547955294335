import {
  Contact,
  AgentContact,
  EntityContact,
  IndividualContact,
  LenderContact,
} from '@endpoint/opsware-bff-graphql-schema';
import { gql, ApolloClient, ApolloQueryResult } from '@apollo/client';

export const SEARCH_CONTACTS = gql`
  query searchContacts($input: String!) {
    searchContacts(input: $input) {
      id
      email
      contactType
      phone
      ... on AgentContact {
        licenseNumber
        firstName
        lastName
        stateOfOperation
        middleName
      }
      ... on LenderContact {
        nmlsId
        firstName
        lastName
        middleName
      }
      ... on IndividualContact {
        firstName
        lastName
        middleName
      }
      ... on EntityContact {
        entityName
      }
    }
  }
`;

export const SEARCH_CONTACTS_WITH_INPUT = gql`
  query searchContactsWithInput($input: SearchContactInput!) {
    searchContactsWithInput(input: $input) {
      id
      email
      contactType
      phone
      ... on AgentContact {
        licenseNumber
        firstName
        lastName
        stateOfOperation
        middleName
      }
      ... on LenderContact {
        nmlsId
        firstName
        lastName
        middleName
      }
      ... on IndividualContact {
        firstName
        lastName
        middleName
      }
      ... on EntityContact {
        entityName
      }
    }
  }
`;

export interface SearchContactsWithInputData {
  searchContactsWithInput: Contact[] | AgentContact[] | EntityContact[] | IndividualContact[] | LenderContact[];
}

export function getSearchContactsResults(
  searchTerm: string,
  client: ApolloClient<object>,
  organizationId?: number,
): Promise<ApolloQueryResult<SearchContactsWithInputData>> {
  const resp: Promise<ApolloQueryResult<SearchContactsWithInputData>> = client.query({
    query: SEARCH_CONTACTS_WITH_INPUT,
    variables: {
      input: {
        term: searchTerm,
        organizationId,
      },
    },
    fetchPolicy: 'network-only',
  });

  return resp;
}
