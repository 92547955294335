import { MilestoneType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { useQuery } from '@apollo/client';
import {
  GET_MILESTONE_VALIDATION_ERRORS,
  GetMilestoneValidationErrors,
} from 'hooks/useMilestoneValidationErrors/queries';

export const useMilestoneValidationErrors = (transactionId: string, milestoneType: MilestoneType) => {
  return useQuery<GetMilestoneValidationErrors>(GET_MILESTONE_VALIDATION_ERRORS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        milestoneType,
        transactionId,
      },
    },
  });
};
