import React, { FC, useContext, ReactNode } from 'react';
import { find } from 'lodash';
import { RouteComponentProps } from '@reach/router';
import { LayoutRightPanel, Flex, Box } from '@endpoint/blockparty';
import { Document } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { generateLoadingContentSkeltons } from 'components/LoadingContentSkeleton';
import { TransactionContext } from 'routes/Transaction';

import { DocumentsContext } from '../..';
import { DocumentRightPanelHeader } from './RightPanelHeader';
import { DocumentDetails } from './Details';
import { DocumentDetailsSection } from './DocumentDetailsSection';
import { DocumentDeliveredToSection } from './DocumentDeliveredTo';

type DocumentRightPanelProps = RouteComponentProps<{
  documentId: string;
  transactionIdentifier: string;
}>;

export interface DocumentDetailsProps {
  document: Document | undefined;
  transactionIdentifier?: string;
}

const loader = (): ReactNode => (
  <Box data-test-id="loader" m="space60">
    {generateLoadingContentSkeltons(2, 6, 'docs-right-panel')}
  </Box>
);

export const DocumentRightPanel: FC<DocumentRightPanelProps> = ({ documentId = '0' }) => {
  const { documents } = useContext(DocumentsContext);
  const { id, fileNumber } = useContext(TransactionContext);

  const transactionIdentifier = fileNumber || `id-${id}`;

  const document = documentId !== '0' ? find(documents, { id: documentId }) : null;

  return (
    <LayoutRightPanel display={['none', 'none', 'block']} maxWidth="504px">
      {!document && loader()}

      {document && (
        <Flex flexDirection="column" height="100%">
          <DocumentRightPanelHeader documentId={documentId} transactionIdentifier={transactionIdentifier} />
          <Box overflow="auto">
            <DocumentDetails document={document} />
            <DocumentDetailsSection document={document} />
            <DocumentDeliveredToSection document={document} transactionIdentifier={transactionIdentifier} />
          </Box>
        </Flex>
      )}
    </LayoutRightPanel>
  );
};

DocumentRightPanel.displayName = 'DocumentRightPanel';
