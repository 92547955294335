import React, { FC } from 'react';
import { Flex, Preview, DrawerContent } from '@endpoint/blockparty';
import { RouteComponentProps } from '@reach/router';
import { Layout } from 'components/Layout';
import { useQueryParams } from 'helpers/utils/queryParams';
import { GetTransactionType, GET_TRANSACTION_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import { useQuery } from '@apollo/client';
import { Dogs404 } from 'routes/404';

import { useDocumentReviewContext } from './contexts/DocumentReviewContext';
import { OpenOrderReview } from './OpenOrderReview';

interface DocumentReviewProps extends RouteComponentProps {}

export const DocumentReview: FC<DocumentReviewProps> = () => {
  const { documentId, documentUrl, documentReviewType } = useDocumentReviewContext();
  const { currentParams } = useQueryParams();
  const { transactionId } = currentParams;
  const { data, loading } = useQuery<GetTransactionType>(GET_TRANSACTION_INFO, {
    variables: {
      where: { id: transactionId },
    },
  });
  const { transaction } = data ?? {};
  const showDocPreview = documentUrl && documentId && documentReviewType;

  if (loading && transactionId) {
    return null;
  }

  return documentId ? (
    <Layout>
      <Flex flexDirection="row" height="100%" justifyContent="flex-end" overflow="auto" zIndex={1}>
        {showDocPreview && <Preview src={documentUrl} type=".pdf" />}
        <Flex>
          <DrawerContent width="504px">
            <OpenOrderReview transaction={transaction} />
          </DrawerContent>
        </Flex>
      </Flex>
    </Layout>
  ) : (
    <Dogs404 />
  );
};
