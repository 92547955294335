import { gql } from '@apollo/client';
import {
  IndividualContact,
  Contact,
  AgentContact,
  EntityContact,
  LenderContact,
  TransactionParticipant,
} from '@endpoint/opsware-bff-graphql-schema';

export const FIND_CONTACTS = gql`
  query findContacts($input: ContactFindOptions) {
    findContacts(input: $input) {
      id
      email
      contactType
      phone
      ... on AgentContact {
        licenseNumber
        stateOfOperation
        firstName
        lastName
        middleName
      }
      ... on LenderContact {
        nmlsId
        firstName
        lastName
        middleName
      }
      ... on IndividualContact {
        firstName
        lastName
        middleName
      }
      ... on EntityContact {
        entityName
      }
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact($input: CreateContactInput!) {
    createContact(input: $input) {
      id
    }
  }
`;

export const CREATE_TRANSACTION_PARTICIPANT = gql`
  mutation createTransactionParticipant($input: CreateTransactionParticipantInput!) {
    createTransactionParticipant(input: $input) {
      id
      contact {
        id
        ... on AgentContact {
          firstName
          lastName
          middleName
        }
        ... on LenderContact {
          firstName
          lastName
          middleName
        }
        ... on IndividualContact {
          firstName
          lastName
          middleName
        }
        ... on EntityContact {
          entityName
        }
      }
      autoAddErrors
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation updateContact($where: ContactWhereUniqueInput!, $input: UpdateContactInput!) {
    updateContact(where: $where, input: $input) {
      id
    }
  }
`;

export interface ContactsData {
  findContacts: Contact[] | AgentContact[] | EntityContact[] | IndividualContact[] | LenderContact[];
}

export interface ContactData {
  createContact: Contact;
}

export interface UpdateContactData {
  updateContact: Contact;
}

export interface CreateTransactionParticipantData {
  createTransactionParticipant: TransactionParticipant;
}
