import React, { Dispatch, FC, SetStateAction } from 'react';
import { Button, Heading, Flex, Text } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';
import { EditTransactionBasicInfoDrawer } from 'routes/Transaction/TransactionBasicInfo/types/EditTransactionBasicInfoDrawer';

export type EditTransactionInfoActionBannerProps = {
  setDiscardChangesModalIsOpen: Dispatch<SetStateAction<boolean>>;
  setFormEditing: Dispatch<SetStateAction<boolean>>;
};

export const EditTransactionInfoActionBanner: FC<EditTransactionInfoActionBannerProps> = ({
  setDiscardChangesModalIsOpen,
  setFormEditing,
}) => {
  const { handleSubmit, isSubmitting, isValid, dirty } = useFormikContext<EditTransactionBasicInfoDrawer>();

  return (
    <Heading backgroundColor="mist0" data-test-id="edit-transaction-action-banner" p="space50" size="fontSize40">
      <Flex justifyContent="flex-end">
        <Button
          isDisabled={isSubmitting}
          mr="space50"
          variant="outline"
          onClick={() => {
            if (dirty || !isValid) {
              setDiscardChangesModalIsOpen(true);
            } else {
              setFormEditing(false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          data-test-id="edit-transaction-save-button"
          isDisabled={isSubmitting || !isValid || !dirty}
          isLoading={isSubmitting}
          mr="space60"
          onClick={() => handleSubmit()}
        >
          <Text color="white" fontWeight="semi">
            Save
          </Text>
        </Button>
      </Flex>
    </Heading>
  );
};
