import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Flex, Heading, Image, Text } from '@endpoint/blockparty';
import DogsImg from 'static/img/403Dogs.svg';
import { OpswareUser, signOut } from 'components/Auth/helpers';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';

export const NotAuthorized: FC<RouteComponentProps> = () => {
  const [currentUserEmail, setCurrentUserEmail] = useState<string>();
  const ldClient = useLDClient();

  const { data } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER);

  const handleSignout = async () => {
    await signOut();
    await ldClient?.identify({ key: '', firstName: '', lastName: '', email: '' });
  };

  useEffect(() => {
    if (data?.me.email) {
      setCurrentUserEmail(data?.me.email);
    }
  }, [data]);

  return (
    <Flex background="slate0" flexBasis="" flexDirection="row" height="100%" justifyContent="space-evenly" width="100%">
      <Flex direction="column" justifyContent="flex-start" marginLeft="10%" marginTop="5%" textAlign="left" width="50%">
        <Heading marginBottom="space60" marginTop="space70" size="fontSize70">
          You&apos;ve reached a restricted page
        </Heading>
        <Text size="fontSize50">Sorry, you don&apos;t have permission to access this page.</Text>
        <Text marginBottom="space60" size="fontSize50">
          {' '}
          If you feel that this is a mistake, please contact your admin.
        </Text>
        <Text fontWeight="semi" marginBottom="space60" size="fontSize20">
          Error Code: 403
        </Text>
        <Text color="carbon500" size="fontSize20">
          {`You're signed in as ${currentUserEmail}`}
          {'  '}
          <Text color="blue500" textDecoration="underline" onClick={() => handleSignout()}>
            Sign Out
          </Text>
        </Text>
      </Flex>
      <Flex alignContent="center" justifyContent="center" marginRight="10%" width="50%">
        <Image
          alt="dogsImg-403-image"
          height="50%"
          maxWidth="448px"
          // @ts-ignore - fix svg reference */
          src={DogsImg}
        />
      </Flex>
    </Flex>
  );
};
