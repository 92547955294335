import React, { FC } from 'react';
import { Radio, RadioGroup, Text } from '@endpoint/blockparty';
import { useField } from 'formik';
import { v4 } from 'uuid';
import { FormikItemValue, TaskStepOption } from 'helpers/dynamicForm/types';

interface TaskStepItemRadioGroupProps {
  name: string;
  title?: string;
  options?: TaskStepOption[];
}

export const TaskStepItemRadioGroup: FC<TaskStepItemRadioGroupProps> = ({ name, title, options }) => {
  const [field, meta, helpers] = useField<FormikItemValue>({ name });
  const value: string | undefined = field.value?.value as string | undefined;

  const handleOnChange = (optionValue: string) => {
    helpers.setValue({ value: optionValue });
  };

  return (
    <>
      <Text size="fontSize20">{title}</Text>
      <RadioGroup defaultValue={value} marginLeft="space20" marginTop="space40" name={field.name}>
        {options?.map((option) => {
          return (
            <Radio key={v4()} value={option.value} onChange={() => handleOnChange(option.value)}>
              {option.label}
            </Radio>
          );
        })}
      </RadioGroup>
    </>
  );
};
