import React, { FC, ReactNode, useState, memo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FetchResult } from 'apollo-link';
import { isUndefined } from 'lodash';
import {
  Drawer,
  Text,
  DrawerContent,
  DrawerCloseButton,
  DrawerActionBar,
  Flex,
  DrawerHeader,
  DrawerSection,
  Box,
  useToast,
} from '@endpoint/blockparty';
import { Todo, TodoAssignment } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { TodoAssignmentToUpdate } from 'consts/toDoConsts';
import { ErrorMessage } from 'components/ErrorMessage';
import { TodoCreated } from 'consts/segmentProtocols';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { buildQueryInput } from 'helpers/buildQueryInput';

import { EditToDoForm } from './EditToDoFormBody';
import { setEditTodoMutationData, MutationData } from './setMutationDataHelper';
import {
  UPDATE_TODO,
  UPDATE_TODO_ASSIGNMENT,
  CREATE_TODO_ASSIGNMENT,
  GET_TRANSACTION_PARTICIPANTS,
  CreateTodoAssignment,
  GetTransactionParticipants,
} from './queries';
import { GET_TODO_DETAILS } from '../queries';

interface EditTodoDrawerProps {
  transactionIdentifier: string;
  todo: Todo;
  isOpen: boolean;
  onClose(): void;
  taskId: string;
}

export interface FormValues {
  id: string;
  escrowNote?: string;
  due: Date;
  todoAssignments: TodoAssignmentToUpdate[];
}

type MutationErrorCount = number;

const responseHasUpdateTodoAssignment = (data: GenericObject): data is { updateTodoAssignment: TodoAssignment } =>
  data.updateTodoAssignment;

export const EditTodoDrawer: FC<EditTodoDrawerProps> = ({ transactionIdentifier, isOpen, onClose, todo, taskId }) => {
  const createTodoTrackData: TodoCreated = {
    assignee: '',
    fileNum: transactionIdentifier,
    location: 'Add Assignee',
    name: '',
  };
  const toast = useToast();
  const [mutationErrorCount, updateMutationErrorCount] = useState<MutationErrorCount>(0);
  const mutationHandler = {
    onError(): void {
      updateMutationErrorCount(mutationErrorCount + 1);
    },
    onCompleted(data: GenericObject) {
      if (responseHasUpdateTodoAssignment(data)) {
        const todoAssignmentId = data.updateTodoAssignment.id;
        const originalValue = todo.assignments?.find((value) => value.id === todoAssignmentId)?.status;

        if (!todoAssignmentId || !originalValue) {
          return;
        }

        trackAnalytics('Todo Modified', {
          location: 'Edit Todo',
          modification: 'Status',
          todoId: todoAssignmentId,
          originalValue,
          newValue: data.updateTodoAssignment.status,
          reason: data.updateTodoAssignment.recallReason,
        });
      }

      if (data.createTodoAssignment) {
        trackAnalytics('Todo Created', {
          ...createTodoTrackData,
          assignee: data.createTodoAssignment.assignee.contact.id,
          name: data.createTodoAssignment.todo.name,
        });
      }
    },
  };

  const [updateTodo, { error: updateTodoError }] = useMutation(UPDATE_TODO, mutationHandler);

  const [updateTodoAssignment, { error: updateTodoAssignmentError }] = useMutation(
    UPDATE_TODO_ASSIGNMENT,
    mutationHandler,
  );

  const [createTodoAssignment, { error: createTodoAssignmentError }] = useMutation<CreateTodoAssignment>(
    CREATE_TODO_ASSIGNMENT,
    {
      refetchQueries: [
        {
          query: GET_TODO_DETAILS,
          variables: {
            where: {
              id: taskId,
            },
          },
        },
      ],
      ...mutationHandler,
    },
  );

  const queryInput = buildQueryInput(transactionIdentifier);

  const { data } = useQuery<GetTransactionParticipants>(GET_TRANSACTION_PARTICIPANTS, {
    variables: {
      where: queryInput,
    },
  });

  function setMutation(mutationData: MutationData[]): Promise<FetchResult[]> {
    const mutations = {
      createTodoAssignment,
      updateTodoAssignment,
      updateTodo,
    };
    const mutationPromises: Promise<FetchResult>[] = [];

    mutationData.forEach((mutationObj) => {
      mutationPromises.push(mutations[mutationObj.mutationType]({ ...mutationObj.mutationData }));
    });

    return Promise.all(mutationPromises);
  }

  const handleSubmit = (initialValues: Todo, updatedValues: FormValues): Promise<FetchResult[]> => {
    const mutationData: MutationData[] = setEditTodoMutationData(initialValues, updatedValues);

    // set mutationErrorCount to zero so that previous mutationErrorCount does not
    // continue to increment after a retry resolves
    updateMutationErrorCount(0);
    const mutationPromises = setMutation(mutationData);
    const updateTodoDue = mutationData.filter(
      (mutationDatum) => mutationDatum.mutationType === 'updateTodo' && mutationDatum.mutationData.variables.data.due,
    );
    const updateTodoNote = mutationData.filter(
      (mutationDatum) =>
        mutationDatum.mutationType === 'updateTodo' && mutationDatum.mutationData.variables.data.escrowNote,
    );

    function trackToDoUpdateData(): void {
      if (updateTodoDue.length || updateTodoNote.length) {
        const trackingEvent = 'Todo Modified';

        if (updateTodoDue.length) {
          trackAnalytics(trackingEvent, {
            todoId: todo.id,
            location: 'Edit Todo',
            modification: 'Date',
            originalValue: initialValues.due,
            newValue: updatedValues.due,
          });
        }

        if (updateTodoNote.length && updatedValues.escrowNote) {
          trackAnalytics(trackingEvent, {
            todoId: todo.id,
            location: 'Edit Todo',
            modification: 'Note',
            originalValue: initialValues.escrowNote || '',
            newValue: updatedValues.escrowNote,
          });
        }
      }
    }

    void mutationPromises.then((responses: FetchResult[]): void => {
      const isThereAFailedMutation: boolean = responses.some((index: FetchResult): boolean => isUndefined(index));

      if (!isThereAFailedMutation) {
        toast({
          description: 'Todo saved',
          duration: 5000,
          icon: 'CheckCircle',
        });

        trackToDoUpdateData();
        onClose();
      }
    });

    return mutationPromises;
  };

  const ErrorMessages: FC = memo(() => {
    const errorMessageArray: ReactNode[] = [];

    if (updateTodoError) {
      errorMessageArray.push(
        <Box key="err1" mb="space0">
          <Text>Unable to update Date or Escrow Note.</Text>
        </Box>,
      );
    }

    if (updateTodoAssignmentError) {
      errorMessageArray.push(
        <Box key="err2" mb="space0">
          <Text>Unable to remove a Todo Assignee.</Text>
        </Box>,
      );
    }

    if (createTodoAssignmentError) {
      errorMessageArray.push(
        <Box key="err3" mb="space0">
          <Text>Unable to update assignees.</Text>
        </Box>,
      );
    }

    return <Flex flexDirection="column">{errorMessageArray}</Flex>;
  });

  return (
    <Drawer id={`edit-todo-${todo.id}`} isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerContent overflow="auto">
        <DrawerActionBar>
          <DrawerCloseButton />
        </DrawerActionBar>
        <Flex flex={1} flexDirection="column">
          <DrawerHeader>
            <Text as="h4" data-test-id="edit-todo-drawer-header" fontWeight="semi" size="fontSize50">
              Edit Todo
            </Text>
            <Text as="p" data-test-id="edit-todo-drawer-name" pt="space30">
              {todo.name}
            </Text>
          </DrawerHeader>
          <DrawerSection>
            <Text as="h4" data-test-id="define-to-do-header" fontWeight="semi" size="fontSize50">
              Define To-Do
            </Text>
          </DrawerSection>
          {mutationErrorCount > 0 && (
            <DrawerSection>
              <ErrorMessage>
                <ErrorMessages />
              </ErrorMessage>
            </DrawerSection>
          )}
          <EditToDoForm
            cancelAction={(): void => {
              updateMutationErrorCount(0);
              onClose();
            }}
            todo={todo}
            toDoUpdateAction={handleSubmit}
            transaction={data ? data.transaction : null}
          />
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};

EditTodoDrawer.displayName = 'EditTodoDrawer';
DrawerCloseButton.displayName = 'DrawerCloseButton';
