import React from 'react';
import { Flex, Icon } from '@endpoint/blockparty';

export function PlatformIcon() {
  return (
    <Flex alignItems="center" bg="blue500" borderRadius="radiusDefault" justifyContent="center" mr="10px" size={32}>
      <Icon color="carbon0" name="Add" size="large" />
    </Flex>
  );
}
