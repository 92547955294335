import React from 'react';
import { Flex, Text } from '@endpoint/blockparty';

import { PlatformIcon } from './PlatformIcon';
import { ValidPlatform } from './types';

interface PlatformLogoProps {
  platformName: ValidPlatform;
}

const PlatformLogo: React.FC<PlatformLogoProps> = ({ platformName }) => {
  return (
    <Flex
      alignItems="center" // hard coded width value
      height="100%"
      minWidth="216px"
      px="space60"
    >
      <PlatformIcon />
      <Flex flexDirection="column">
        <Text color="carbon0" size="fontSize10">
          Opsware
        </Text>
        <Text color="carbon0" data-test-id="platform-name" fontWeight="semi" size="fontSize30">
          {platformName}
        </Text>
      </Flex>
    </Flex>
  );
};

export default PlatformLogo;
