import React, { FC } from 'react';
import { Box, Flex, Icon, Text } from '@endpoint/blockparty';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import {
  MilestoneStatus,
  MilestoneType,
  Transaction,
  TransactionRole,
  TransactionStage,
  TransactionType,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { getParticipantsNamesByRole } from 'helpers/transactionParticipants';
import { mapStatusText } from 'helpers/statusHelper';
import { transactionStageToIcon } from 'consts/calendar';
import { formatLikeTitle } from 'helpers/formatText';

import { SigningApppointmentInfo } from './SigningAppointmentInfo';

interface TransactionEventBodyProps {
  eventType: TransactionStage | MilestoneType;
  transaction: Transaction;
}

export const TransactionEventBody: FC<TransactionEventBodyProps> = ({ eventType, transaction }) => {
  const borrowers = transaction.participants.length
    ? getParticipantsNamesByRole(transaction, TransactionRole.BORROWER)
    : [];
  const sellers = transaction.participants.length
    ? getParticipantsNamesByRole(transaction, TransactionRole.SELLER)
    : [];
  const buyers = transaction.participants.length ? getParticipantsNamesByRole(transaction, TransactionRole.BUYER) : [];

  const hideSigningAppointmentInfo: boolean =
    eventType === TransactionStage.RELEASED_TO_RECORD ||
    eventType === TransactionStage.RECORDING_COMPLETE ||
    eventType === TransactionStage.TRANSACTION_DISBURSED;

  const iconName: string = mapStatusText(eventType, transactionStageToIcon);
  const iconColor = iconName === transactionStageToIcon.TRANSACTION_DISBURSED ? 'mist500' : 'blue500';

  const sellerSigning = transaction.sellerSigning?.date;
  const buyerSigning = transaction.buyerSigning?.date;

  const sellerCompleted = !!transaction.milestones.find(
    (milestone) =>
      milestone.status === MilestoneStatus.COMPLETE && milestone.type === MilestoneType.SELLER_SIGNING_SCHEDULED,
  );

  const buyerCompleted = !!transaction.milestones.find(
    (milestone) =>
      milestone.status === MilestoneStatus.COMPLETE && milestone.type === MilestoneType.BUYER_SIGNING_SCHEDULED,
  );

  return hideSigningAppointmentInfo ? (
    <Flex alignItems="center" data-test-id={`closing-event-milestone-${transaction.fileNum}`} pb="space60" pt="space50">
      <Box aria-label={iconName}>
        <Icon
          color={iconColor || 'blue500'}
          data-test-id="stage-icon"
          mr="space40"
          name={iconName as IconNames}
          size="large"
        />
      </Box>
      <Text>{formatLikeTitle(eventType)}</Text>
    </Flex>
  ) : (
    <Flex data-test-id="closing-event-signing-appointments" flexDirection="column" py="space60">
      {transaction.type && transaction.type === TransactionType.REFINANCE ? (
        <SigningApppointmentInfo
          completed={sellerCompleted}
          date={sellerSigning}
          participants={borrowers}
          side="Borrower"
        />
      ) : (
        <>
          <Box pb="space50">
            <SigningApppointmentInfo
              completed={sellerCompleted}
              date={sellerSigning}
              participants={sellers}
              side="Seller"
            />
          </Box>
          <SigningApppointmentInfo completed={buyerCompleted} date={buyerSigning} participants={buyers} side="Buyer" />
        </>
      )}
    </Flex>
  );
};
