import { FileUpload } from 'apollo/resolvers/UploadDocument/FileUpload';

export const maxUploadSize: number = 50000000;

export const mimeTypes: string[] = ['application/pdf', 'image/jpeg', 'image/jpg'];

export enum FileRejections {
  FILE_INVALID_TYPE = 'file-invalid-type',
  FILE_TOO_LARGE = 'file-too-large',
  CANCELED = 'Upload cancelled',
}

export enum DocumentTypes {
  INVOICE = 'Invoice',
  ADDENDUM = 'Addendum',
  LOAN_DOCUMENT = 'Loan Document',
  INSPECTION = 'Inspection',
  OTHER = 'Other',
}

export enum DocumentUploadStatus {
  ERROR = 'ERROR',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface DocumentUpload {
  id: string;
  name: string;
  status: DocumentUploadStatus;
  progress?: number;
  documentType: string;
  fileRejection?: FileRejections | string;
  fileUpload: FileUpload;
}

export interface UploadDocument {
  uploadDrawerOpen: boolean;
  uploads: DocumentUpload[];
}
