import { useQuery } from '@apollo/client';
import { MarketsAndOrganizations, GET_MARKETS_AND_ORGANIZATIONS } from 'consts/getMarketsAndOrganizationsQuery';
import React from 'react';

export const useIsAccessPrelistingEnabled = (state: string): boolean => {
  const { data } = useQuery<MarketsAndOrganizations>(GET_MARKETS_AND_ORGANIZATIONS, {
    fetchPolicy: 'cache-only',
  });

  const prelistingAccessibleMarkets: string[] = [];

  const allMarkets = data?.markets;

  const prelimMarkets = allMarkets?.filter((market) => {
    return market.acceptsPrelistings;
  });

  prelimMarkets?.forEach((market) => {
    if (prelistingAccessibleMarkets.indexOf(market.state) === -1) {
      prelistingAccessibleMarkets.push(market.state);
    }
  });

  return prelistingAccessibleMarkets.includes(state);
};
