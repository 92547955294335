import React, { useState } from 'react';
import { Select, Box, Flex, Icon } from '@endpoint/blockparty';
import { OpsTask, OpsTaskStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { SelectProps } from '@endpoint/blockparty/dist/components/Select';
import { mapStatusToLabel, mapStatusToIcon } from 'consts/opsTasks';

interface TaskStatusButtonProps extends Partial<SelectProps> {
  task: OpsTask;
  onChange(task: OpsTaskStatus): void;
}

interface Option {
  readonly value: string;
  readonly label: string;
}

export const FormatOptionLabel = (data: unknown): React.ReactNode => {
  const { label, value } = data as Option;
  const { color, icon } = mapStatusToIcon[value as OpsTaskStatus];

  return (
    <Flex alignItems="center">
      <Icon color={color} name={icon} size="medium" />
      <Box ml="space30">{label}</Box>
    </Flex>
  );
};

export const TaskStatusButton: React.FC<TaskStatusButtonProps> = ({ task, onChange, ...rest }) => {
  const selectOptions = Object.keys(OpsTaskStatus).map((status) => ({
    label: mapStatusToLabel[status as OpsTaskStatus],
    value: status as OpsTaskStatus,
  }));
  const taskStatusOption = selectOptions.find((option) => option.value === task.status);
  const [selectedOption, setSelectedOption] = useState(taskStatusOption);

  return (
    <Select
      data-test-id="task-status-button"
      formatOptionLabel={FormatOptionLabel}
      options={selectOptions}
      value={selectedOption}
      {...rest}
      onChange={(option): void => {
        setSelectedOption(option);
        onChange(option.value);
      }}
    />
  );
};
