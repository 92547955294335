import { TransactionRole } from '@endpoint/opsware-bff-graphql-schema';

export type TransactionParticipantRoleMap = Record<TransactionRole, SelectOptionsObject<TransactionRole>>;

export const roleOptions: TransactionParticipantRoleMap = {
  [TransactionRole.SELLING_AGENT]: {
    label: 'Selling Agent',
    value: TransactionRole.SELLING_AGENT,
  },
  [TransactionRole.LISTING_AGENT]: {
    label: 'Listing Agent',
    value: TransactionRole.LISTING_AGENT,
  },
  [TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR]: {
    label: 'Selling TC',
    value: TransactionRole.SELLING_AGENT_TRANSACTION_COORDINATOR,
  },
  [TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR]: {
    label: 'Listing TC',
    value: TransactionRole.LISTING_AGENT_TRANSACTION_COORDINATOR,
  },
  [TransactionRole.BUYER]: {
    label: 'Buyer',
    value: TransactionRole.BUYER,
  },
  [TransactionRole.BORROWER]: {
    label: 'Borrower',
    value: TransactionRole.BORROWER,
  },
  [TransactionRole.SELLER]: {
    label: 'Seller',
    value: TransactionRole.SELLER,
  },
  [TransactionRole.LOAN_OFFICER]: {
    label: 'Loan Officer',
    value: TransactionRole.LOAN_OFFICER,
  },
  [TransactionRole.LOAN_PROCESSOR]: {
    label: 'Loan Processor',
    value: TransactionRole.LOAN_PROCESSOR,
  },
  [TransactionRole.MORTGAGE_BROKER]: {
    label: 'Mortgage Broker',
    value: TransactionRole.MORTGAGE_BROKER,
  },
  [TransactionRole.ESCROW_AGENT]: {
    label: 'Escrow Agent',
    value: TransactionRole.ESCROW_AGENT,
  },
  [TransactionRole.PROPTECH_PARTNER]: {
    label: 'Proptech Partner',
    value: TransactionRole.PROPTECH_PARTNER,
  },
  [TransactionRole.PROPTECH_BUYER]: {
    label: 'Buyer',
    value: TransactionRole.PROPTECH_BUYER,
  },
  [TransactionRole.PROPTECH_SELLER]: {
    label: 'Seller',
    value: TransactionRole.PROPTECH_SELLER,
  },
  [TransactionRole.CLOSING_SPECIALIST]: {
    label: 'Closing Specialist',
    value: TransactionRole.CLOSING_SPECIALIST,
  },
  [TransactionRole.SETTLEMENT_COMPANY]: {
    label: 'Settlement Company',
    value: TransactionRole.SETTLEMENT_COMPANY,
  },
  [TransactionRole.BUYER_API]: {
    label: 'Buyer - API',
    value: TransactionRole.BUYER_API,
  },
  [TransactionRole.SELLER_API]: {
    label: 'Seller - API',
    value: TransactionRole.SELLER_API,
  },
};
