import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Auth } from 'aws-amplify';
import { Box } from '@endpoint/blockparty';
import PostMessage from '@endpoint/post-message';
import { TransactionContext } from 'routes/Transaction';
import { generateLoadingContentSkeltons } from 'components/LoadingContentSkeleton';
import { ConfigContext } from 'App';
import { updateBreadcrumbs } from 'helpers/updateBreadcrumbs';

interface SigningsProps extends RouteComponentProps {}

const postMessage = new PostMessage();

export const Signings: FC<SigningsProps> = () => {
  const config = useContext(ConfigContext);
  const { id: transactionId } = useContext(TransactionContext);
  const iframeRef = useRef(null);
  const [isIframeRegistered, setIframeRegister] = useState<boolean>(false);
  const [isSolexReady, setSolexReady] = useState<boolean>(false);

  const getCurrentSession = async () => {
    const currentSession = await Auth.currentSession();

    return {
      awsRefreshToken: currentSession.getRefreshToken(),
      awsAccessToken: currentSession.getAccessToken(),
      awsIdToken: currentSession.getIdToken(),
    };
  };

  const handleIframeRegister = async (): Promise<void> => {
    const response = await postMessage.registerIframe(iframeRef.current);

    setIframeRegister(response);
  };

  const handleSolexReady = () => setSolexReady(true);

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Signings', path: '' }]);
  }, []);

  useEffect(() => {
    if (!config.SOLEX_EO_CLIENT_URL) {
      return;
    }

    postMessage.setTrustedOrigins(config.SOLEX_EO_CLIENT_URL);
    postMessage.setTargetOrigin(config.SOLEX_EO_CLIENT_URL);
  }, [config.SOLEX_EO_CLIENT_URL]);

  useEffect(() => {
    if (!iframeRef) {
      return;
    }

    handleIframeRegister().catch(() => {
      setIframeRegister(false);
    });
  }, [iframeRef]);

  useEffect(() => {
    postMessage.on('solexReady', handleSolexReady);
  });

  useEffect(() => {
    if (!isIframeRegistered || !transactionId || !isSolexReady) {
      return;
    }

    const defaultConfig = {
      transactionId,
      awsWebClientId: config.AWS_COGNITO_WEB_CLIENT_ID,
      awsRegion: config.AWS_REGION,
      awsUserPoolId: config.AWS_COGNITO_USER_POOL_ID,
    };

    getCurrentSession()
      .then((tokens) => {
        postMessage.sendToIframe('auth', {
          ...defaultConfig,
          awsAccesToken: tokens.awsAccessToken?.getJwtToken(),
          awsIdToken: tokens.awsIdToken?.getJwtToken(),
          awsRefreshToken: tokens.awsRefreshToken?.getToken(),
        });
      })
      .catch(() => {
        postMessage.sendToIframe('auth', {
          ...defaultConfig,
          awsAccesToken: null,
          awsIdToken: null,
          awsRefreshToken: null,
        });
      });
  });

  return (
    <>
      {!isIframeRegistered && (
        <Box data-testid="skeleton-signings" mt="space60" px="space60">
          {generateLoadingContentSkeltons(3, 3, 'signings')}
        </Box>
      )}

      <Box style={{ height: '100%', overflow: 'auto', display: isIframeRegistered ? 'block' : 'none' }}>
        <iframe
          ref={iframeRef}
          frameBorder="no"
          src={config.SOLEX_EO_CLIENT_URL}
          style={{ height: '100%', width: '100%' }}
          title="solex"
        />
      </Box>
    </>
  );
};
