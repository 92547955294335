import React, { createContext, FC, ReactNode, useMemo } from 'react';
import { OperationVariables, QueryResult, useQuery } from '@apollo/client';
import { Box, Text } from '@endpoint/blockparty';
import { generateLoadingContentSkeltons } from 'components/LoadingContentSkeleton';
import { ErrorMessage } from 'components/ErrorMessage';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { DynamicForm } from '../DynamicForm';
import { OpsTaskResponse, GET_OPS_TASK, GET_OPS_TASK_NOTES } from '../queries';
import { TabbedContent } from '../TabbedContent';
import { TaskFilters } from '../TaskFilters';
import { TaskPanel } from './TaskPanel';

interface TaskPanelV2Props {
  taskId: string;
  transactionIdentifier: string;
}

export const TaskDrawerContext = createContext<OpsTaskResponse | undefined>(undefined);

const loader = (): ReactNode => <Box m="space60">{generateLoadingContentSkeltons(2, 6, 'right-panel')}</Box>;

export const TaskPanelV2: FC<TaskPanelV2Props> = ({ transactionIdentifier, taskId }) => {
  const { canViewTaskNotes } = useFlags();

  const getOpsTaskQuery = canViewTaskNotes ? GET_OPS_TASK_NOTES : GET_OPS_TASK;

  const { data, loading, error } = useQuery<OpsTaskResponse>(getOpsTaskQuery, {
    variables: { where: { id: taskId } },
    fetchPolicy: 'network-only',
  });

  const task = data?.opsTask;

  const taskDrawerProviderValue = useMemo(() => data, [data]);
  const showLegacyExperience = !task?.taskDefinitionSchema || !Object.keys(task?.taskDefinitionSchema).length;

  // Default to existing experience if taskDefinitionSchema is undefined on query
  if (showLegacyExperience) {
    return <TaskPanel taskId={taskId} transactionIdentifier={transactionIdentifier} />;
  }

  return (
    <>
      {loading && loader()}
      {error && <ErrorMessage />}
      {!loading && task && (
        <TaskDrawerContext.Provider value={taskDrawerProviderValue}>
          <Box key={taskId} overflow="auto" py="space60">
            <Text data-test-id="task-details-title" fontWeight="semi" px="space60" size="fontSize50">
              {task.taskTitle}
            </Text>
            <TaskFilters task={task} />
            <TabbedContent />
            <DynamicForm task={task} />
          </Box>
        </TaskDrawerContext.Provider>
      )}
    </>
  );
};
