import React, { FC } from 'react';
import { Card, Flex, Box, Heading, Image, Text, Divider } from '@endpoint/blockparty';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { fallbackPropertyImg } from 'consts/urlConsts';

import { BasicInfoActionMenu } from '../BasicInfoActionMenu';
import { TransactionOverviewAgents } from './TransactionOverviewAgents';

interface TransactionOverviewProps {
  transaction: Transaction;
}

export const TransactionOverview: FC<TransactionOverviewProps> = ({ transaction }) => (
  <Card mb="space60" p="space60">
    <Flex justifyContent="space-between">
      <Flex>
        <Box width="150px">
          <Image
            alt="property image"
            data-test-id="transaction-overview-image"
            fallbackSrc={fallbackPropertyImg}
            src={transaction.property.propertyImage || ''}
          />
        </Box>
        <Box ml="space60">
          <Heading data-test-id="transaction-overview-address" size="fontSize40">
            {transaction.property.address?.standardFormat || '-'}
          </Heading>
          <Flex mt="space30">
            {transaction.property.address?.county && (
              <Text data-test-id="transaction-overview-county">{`${transaction.property.address?.county} County`}</Text>
            )}
            {transaction.property.address?.county && transaction.property?.parcelNumber && (
              <Divider mx="space30" orientation="vertical" />
            )}
            {transaction.property?.parcelNumber && (
              <Text data-test-id="transaction-overview-parcel-number">{`Parcel # ${transaction.property?.parcelNumber}`}</Text>
            )}
          </Flex>
          <TransactionOverviewAgents transactionParticipants={transaction.participants} />
        </Box>
      </Flex>
      <Box>
        <BasicInfoActionMenu transaction={transaction} />
      </Box>
    </Flex>
  </Card>
);

TransactionOverview.displayName = 'TransactionOverview';
Image.displayName = 'Image';
