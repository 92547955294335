import React, { FC } from 'react';
import { Grid, Box, Flex, Text } from '@endpoint/blockparty';
import { EditTransactionBasicInfoEditButton } from 'components/EditTransactionBasicInfo/EditTransactionBasicInfoEditButton';
import { useBasicInfoShowEditButton } from 'hooks/useBasicInfoShowEditButton';
import { useEditTransactionBasicInfoContext } from 'hooks/useEditTransactionBasicInfoContext';

import { BasicInfoPropertyProps } from '.';

export const Property: FC<BasicInfoPropertyProps> = ({ transaction }) => {
  const { toggleIsBeingEdited, setFocusField } = useEditTransactionBasicInfoContext();
  const propertyAddress = `${transaction.property.address.street1} ${transaction.property.address.street2}`;
  const showEditButton = useBasicInfoShowEditButton(transaction);

  return (
    <Grid
      columnGap="space60"
      pb="space50"
      pt="space30"
      px="space50"
      rowGap="space40"
      templateColumns="repeat(2, minmax(150px, 1fr))"
    >
      <Box key="transaction.property.address" data-test-id="basic-info-property">
        <Flex width="space90">
          <Text as="p" color="passive" mb="space30" size="fontSize10" width="100%">
            Property Address
          </Text>
          {showEditButton && (
            <EditTransactionBasicInfoEditButton
              fieldName="address"
              setFocusField={setFocusField}
              toggleEditing={toggleIsBeingEdited}
            />
          )}
        </Flex>

        <Text as="p" data-test-id="basic-info-property-street1">
          {propertyAddress.trim()}
        </Text>
        <Text as="p" data-test-id="basic-info-property-city-state-zip">
          {transaction.property.address.city}, {transaction.property.address.state} {transaction.property.address.zip}
        </Text>
        <Text as="p" data-test-id="basic-info-property-county">
          {transaction.property.address.county || ''}
        </Text>
      </Box>
      <Box>
        <Flex>
          <Text as="p" color="passive" mb="space30" size="fontSize10" width="100%">
            Parcel Number
          </Text>
          {showEditButton && (
            <EditTransactionBasicInfoEditButton
              fieldName="parcelNumber"
              setFocusField={setFocusField}
              toggleEditing={toggleIsBeingEdited}
            />
          )}
        </Flex>
        <Text as="p" data-test-id="basic-info-property-parcelNumber" mb="space40">
          {transaction.property.parcelNumber || '-'}
        </Text>
      </Box>
    </Grid>
  );
};
