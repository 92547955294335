import React, { FC, ReactNode } from 'react';
import { generateLoadingContentSkeltons } from 'components/LoadingContentSkeleton';
import { Task } from '@endpoint/opsware-bff-graphql-schema';
import { Box } from '@endpoint/blockparty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from '@apollo/client';
import { ErrorMessage } from 'components/ErrorMessage';

import { CustomerStatus } from '../CustomerStatus';
import { Details } from '../Details';
import { TodoContent } from '../TodoContent';
import { GetToDoDetails, GET_TODO_DETAILS, GET_TODO_DETAILS_WITH_STEPANSWERS } from '../queries';

interface TaskPanelProps {
  taskId: string;
  transactionIdentifier: string;
}

const loader = (): ReactNode => <Box m="space60">{generateLoadingContentSkeltons(2, 6, 'right-panel')}</Box>;

export const TaskPanel: FC<TaskPanelProps> = ({ transactionIdentifier = '0', taskId = '0' }) => {
  const { isTodoQuestionsAnswersEnabled } = useFlags();

  let todoDetailsQuery = GET_TODO_DETAILS;

  if (isTodoQuestionsAnswersEnabled) {
    todoDetailsQuery = GET_TODO_DETAILS_WITH_STEPANSWERS;
  }

  const {
    data: toDoDetails,
    loading,
    error,
  } = useQuery<GetToDoDetails>(todoDetailsQuery, {
    variables: { where: { id: taskId } },
  });

  const task: Task | undefined = toDoDetails?.task;

  const showError = !loading && error;

  return (
    <>
      {loading && loader()}
      {showError && <ErrorMessage />}
      {!loading && task && (
        <Box key={taskId} overflow="auto">
          <Details task={task} />
          {task.todos.length > 0 &&
            task.todos.map((todo) => (
              <React.Fragment key={todo.id}>
                <Box>
                  <TodoContent taskId={taskId} todo={todo} transactionIdentifier={transactionIdentifier} />
                </Box>
                <Box>
                  <CustomerStatus assignments={todo.assignments || []} />
                </Box>
              </React.Fragment>
            ))}
        </Box>
      )}
    </>
  );
};
