import { add, startOfDay, sub, formatISO } from 'date-fns';
import React, { FC, useState } from 'react';
import { Box, Button, Flex, IconButton, Text, useDisclosure } from '@endpoint/blockparty';
import { MarketSelect } from 'components/FilterMarketSelect';
import { OrganizationSelect } from 'components/FilterOrganizationSelect';
import useMarketsAndOrganizations from 'hooks/useMarketsAndOrganizations';
import { dateFormatter } from 'helpers/datetimeHelper';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { IconHelpDrawer } from 'routes/Calendar/CalendarSubHeader/IconHelpDrawer';
import { useCalendarFilters } from 'helpers/calendar';

interface CalendarSubHeaderProps {
  endDate: string;
  startDate: Date;
  totalClosings: number;
}

export const CalendarSubHeader: FC<CalendarSubHeaderProps> = ({ endDate, startDate, totalClosings }) => {
  const { navigateWithParams } = useCalendarFilters();
  const { showOrganizationDropdown, showMarketDropdown } = useMarketsAndOrganizations();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const oneWeekPast = sub(startDate, { weeks: 1 });
  const oneWeekFuture = add(startDate, { weeks: 1 });
  const today = startOfDay(new Date());
  const [organizationId, setOrganizationId] = useState<number[]>([]);

  return (
    <Flex justifyContent="space-between" pt="space60" px="space60">
      <Flex alignItems="center">
        <Button
          data-test-id="today-button"
          mr="space60"
          variant="outline"
          variantColor="blue"
          onClick={() => {
            trackAnalytics('Calendar Today', { event: 'Calendar Today Clicked' });
            navigateWithParams({ date: formatISO(today) });
          }}
        >
          Today
        </Button>
        <IconButton
          color="carbon500"
          data-test-id="previous-one-week-button"
          label="Calendar back button"
          mr="space30"
          name="ArrowChevronLeft"
          variant="unstyled"
          variantColor="carbon"
          onClick={() => {
            trackAnalytics('Calendar Back', { event: 'Calendar Back Clicked' });
            navigateWithParams({ date: formatISO(oneWeekPast) });
          }}
        />
        <IconButton
          color="carbon500"
          data-test-id="next-one-week-button"
          label="Calendar next button"
          mr="space60"
          name="ArrowChevronRight"
          variant="unstyled"
          variantColor="carbon"
          onClick={() => {
            trackAnalytics('Calendar Next', { event: 'Calendar Next Clicked' });
            navigateWithParams({ date: formatISO(oneWeekFuture) });
          }}
        />
        <Text data-test-id="selected-date" fontWeight="semi" size="fontSize50">
          {`${dateFormatter(startDate, 'MMM d')}-${dateFormatter(endDate, 'MMM d, yyy')}`}
        </Text>
      </Flex>
      <Flex alignItems="center">
        <Text data-test-id="total-closings">{totalClosings} closings</Text>
        <IconButton
          color="carbon500"
          data-test-id="calendar-help-drawer-trigger"
          label="Calendar tip button"
          ml="space50"
          name="Help"
          variant="unstyled"
          variantColor="carbon"
          onClick={() => {
            onOpen();
            trackAnalytics('Calendar Tip', { event: 'Calendar Tip Clicked' });
          }}
        />
        <IconHelpDrawer isOpen={isOpen} onClose={onClose} />
        {showOrganizationDropdown && (
          <Box ml="space50">
            <OrganizationSelect
              onChange={(organizationIds) => {
                navigateWithParams({
                  organizationIds,
                });

                setOrganizationId(organizationIds);
              }}
            />
          </Box>
        )}
        {showMarketDropdown && (
          <Box ml="space50">
            <MarketSelect
              organizationValue={organizationId}
              onChange={(marketIds) => {
                navigateWithParams({
                  marketIds,
                });
              }}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

CalendarSubHeader.displayName = 'CalendarSubHeader';
