import {
  DateTime,
  TransactionDates,
  TransactionTermsUpdateInput,
  TransactionType,
  TransactionUpdateInput,
} from '@endpoint/opsware-bff-graphql-schema';

import { castToNumberHelper } from './castToNumberHelper';

export interface UpdateTransactionBasicInfoMutationInput {
  parcelNumber?: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  productType: string;
  salePrice?: number;
  loanAmount?: string | number;
  loanNumber?: string;
  emdAmount?: number;
  closingDate?: DateTime;
  emdDueDate?: DateTime;
  contractExecutionDate?: DateTime;
  estimatedClosing?: DateTime;
  escrowOpenedDate?: DateTime;
}

export function buildBasicInfoTransactionUpdate(data: UpdateTransactionBasicInfoMutationInput): TransactionUpdateInput {
  const input: TransactionUpdateInput = {
    address: {
      street1: data.address,
      street2: data.address2 || '',
      city: data.city,
      state: data.state,
      county: data.county,
      zip: data.zip,
    },
  };

  const terms: TransactionTermsUpdateInput = {};
  const dates: TransactionDates = {};

  dates.contractExecutedDate = data.contractExecutionDate || '';

  dates.emdContractDate = data.emdDueDate || '';

  dates.estimatedClosing = data.estimatedClosing || '';

  dates.escrowOpen = data.escrowOpenedDate || '';

  // Conditional check to see if the transaction is a REFINANCE, if so remove salePrice from return object.
  if (data.productType !== TransactionType.REFINANCE) {
    terms.salePrice = castToNumberHelper(data.salePrice);
  }

  terms.loanAmount = castToNumberHelper(data.loanAmount);

  terms.loanNumber = data.loanNumber;

  terms.emdAmount = castToNumberHelper(data.emdAmount);

  input.parcelNumber = data.parcelNumber;

  terms.productType = data.productType as TransactionType;

  return { ...input, terms, dates };
}
