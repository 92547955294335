import React, { FC } from 'react';
import { Card, Flex, Heading, Text } from '@endpoint/blockparty';
import { CalendarDate } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { sum } from 'lodash';

import { DayCard } from '../DayCard';

interface WeekListProps {
  calendarDates: CalendarDate[];
}

export const WeekList: FC<WeekListProps> = ({ calendarDates }) => {
  const closings: number = calendarDates
    ? sum(calendarDates.map((week: CalendarDate): number => week.transactionCalendarEvents.length))
    : 0;
  const signings: number = calendarDates
    ? sum(calendarDates.map((week: CalendarDate): number => week.signingCalendarEvents.length))
    : 0;

  return closings > 0 || signings > 0 ? (
    <Card
      // @ts-ignore - to be fixed in BP
      css={`
        & > div {
          width: 100%;
        }
      `}
      height="100%"
      m="space60"
      overflow="auto"
    >
      {calendarDates.map((day) => (
        <DayCard key={day.date} calendarDate={day} />
      ))}
    </Card>
  ) : (
    <Flex alignItems="center" data-test-id="no-days" flexDirection="column" height={224} justifyContent="center">
      <Heading as="h5" data-test-id="heading-no-documents" mb="space50" size="fontSize40">
        No calendar events found.
      </Heading>
      <Text>There are no calendar events within this time frame. Try adjusting your filters.</Text>
    </Flex>
  );
};
