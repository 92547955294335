import React, { FC, useState, useEffect, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import {
  Button,
  Drawer,
  DrawerActionBar,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerSection,
  Heading,
  Flex,
  Text,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Alert,
  AlertContent,
} from '@endpoint/blockparty';
import {
  NewContactSchema,
  NewContactStep,
  ContactGQLErrorType,
  contactIsParticipantError,
  newContactDefault,
} from 'consts/createNewContact';
import { ActionKind, useNewContactProvider } from 'hooks/useContactProvider';
import { setNewContactDrawerTitle, isEntity, getContactErrorMessageText } from 'helpers/contacts';
import { ErrorMessage } from 'components/ErrorMessage';
import { cleanseUpdateContactInput } from 'helpers/updateContact';
import { TransactionParticipant, TransactionType } from '@endpoint/opsware-bff-graphql-schema';
import { TransactionContext } from 'routes/Transaction';

import { ContactDetailsForm } from './ContactDetailsForm';
import { SelectDuplicateOrNewContact } from './SelectDuplicatOrNewContact';
import {
  CREATE_CONTACT,
  FIND_CONTACTS,
  UPDATE_CONTACT,
  ContactsData,
  ContactData,
  UpdateContactData,
} from '../queries';

interface AddNewContactDrawerProps {
  handleCancel: () => void;
  isDrawerOpen: boolean;
  propertyAddress: string;
  toggleDrawer: (isOpen: boolean) => void;
  transactionParticipants: TransactionParticipant[];
  transactionType: TransactionType;
}

export const AddNewContactDrawer: FC<AddNewContactDrawerProps> = ({
  handleCancel,
  isDrawerOpen,
  propertyAddress,
  toggleDrawer,
  transactionParticipants,
  transactionType,
}) => {
  const { dirty, handleReset, handleSubmit, isSubmitting, isValid, values } = useFormikContext<NewContactSchema>();
  const { state, dispatch } = useNewContactProvider();
  const [showGqlErrorMessage, setShowGqlErrorMessage] = useState(false);
  const [showExistingContactErrorMessage, setShowExistingContactErrorMessage] = useState(false);
  const [showEditingAlert, setShowEditingAlert] = useState(false);
  const { organizationId } = useContext(TransactionContext);
  const isNewContactEntity = isEntity(values.contactType);
  const isAlreadyAddedContact = transactionParticipants
    .map((participant) => participant.contact.email)
    .includes(values.email);

  useEffect(() => {
    if (!state.contactGQLError) setShowGqlErrorMessage(false);
    else setShowGqlErrorMessage(true);
  }, [state]);

  useEffect(() => {
    if (isAlreadyAddedContact) {
      setShowExistingContactErrorMessage(true);
    } else {
      setShowExistingContactErrorMessage(false);
    }
  }, [state, values, transactionParticipants, isAlreadyAddedContact]);

  useEffect(() => {
    if (state.newContactStep === NewContactStep.SEARCH_UPDATE_CONTACT && !isAlreadyAddedContact)
      setShowEditingAlert(true);
    else setShowEditingAlert(false);
  }, [state, isAlreadyAddedContact]);

  const [findContacts, { data: findContactsData, loading: findLoading }] = useLazyQuery<ContactsData>(FIND_CONTACTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      handleClearErrorAlert();

      if (data && data.findContacts && data.findContacts.length) {
        dispatch({
          type: ActionKind.SET_CONTACT_STEP,
          payload: { ...state, newContactStep: NewContactStep.SELECT_DUPLICATE },
        });
      } else {
        void createContact();
      }
    },
    onError: () => {
      dispatch({
        type: ActionKind.SET_CONTACT_GQL_ERROR,
        payload: { ...state, contactGQLError: ContactGQLErrorType.FIND_ERROR },
      });
    },
  });

  const [createContact, { loading: createContactLoading }] = useMutation<ContactData>(CREATE_CONTACT, {
    variables: {
      input: {
        firstName: isNewContactEntity ? undefined : values.firstName,
        lastName: isNewContactEntity ? undefined : values.lastName,
        middleName: isNewContactEntity ? undefined : values.middleName || undefined,
        entityName: isNewContactEntity ? values.entityName : undefined,
        email: values.email || undefined,
        type: values.contactType,
        phoneNumber: values.phone || undefined,
        stateOfOperation: values.stateOfOperation || undefined,
        licenseNumber: values.licenseNumber || undefined,
        nmlsId: values.nmlsId || undefined,
        organizationId: organizationId || undefined,
      },
    },
    onCompleted: (data) => {
      handleClearErrorAlert();

      dispatch({
        type: ActionKind.SET_CONTACT,
        payload: {
          ...state,
          selectedContactData: {
            ...state.selectedContactData,
            id: data.createContact.id!,
            roles: [...values.roles],
          },
        },
      });

      handleSubmit();
    },
    onError: () => {
      dispatch({
        type: ActionKind.SET_CONTACT_GQL_ERROR,
        payload: { ...state, contactGQLError: ContactGQLErrorType.CREATE_ERROR },
      });
    },
  });

  const [updateContact, { loading: updateContactLoading }] = useMutation<UpdateContactData>(UPDATE_CONTACT, {
    onCompleted: () => {
      handleClearErrorAlert();
      handleSubmit();
    },
    onError: () => {
      dispatch({
        type: ActionKind.SET_CONTACT_GQL_ERROR,
        payload: { ...state, contactGQLError: ContactGQLErrorType.CREATE_ERROR },
      });
    },
  });

  const createContactStep: NewContactStep = NewContactStep.SEARCH_CONTACT;

  const isAddContact = state.newContactStep === NewContactStep.ADD_CONTACT;
  const isDuplicates = state.newContactStep === NewContactStep.SELECT_DUPLICATE;
  const isSearchAndUpdate = state.newContactStep === NewContactStep.SEARCH_UPDATE_CONTACT;
  const isSearch =
    state.newContactStep === NewContactStep.SEARCH_CONTACT ||
    state.newContactStep === NewContactStep.SEARCH_UPDATE_CONTACT;
  const isSelectButtonDisabled =
    isSubmitting || !dirty || !isValid || isSearch || !state.isDuplicateOrNewContactSelected;

  const handleDrawerClose = () => {
    toggleDrawer(false);
    dispatch({ type: ActionKind.SET_CONTACT_STEP, payload: { ...state, newContactStep: createContactStep } });
    dispatch({ type: ActionKind.SET_CONTACT, payload: { ...state, selectedContactData: newContactDefault } });
    handleClearErrorAlert();
    dispatch({
      type: ActionKind.SET_IS_DUPLICATE_OR_NEW_CONTACT_SELECTED,
      payload: { ...state, isDuplicateOrNewContactSelected: false },
    });

    handleReset();
  };

  const handleBackClick = (): void => {
    handleClearErrorAlert();

    if (state.newContactStep === NewContactStep.ADD_CONTACT || isSearchAndUpdate) {
      dispatch({
        type: ActionKind.SET_CONTACT_STEP,
        payload: { ...state, newContactStep: NewContactStep.SEARCH_CONTACT },
      });

      dispatch({ type: ActionKind.SET_CONTACT, payload: { ...state, selectedContactData: newContactDefault } });
      handleReset();
    }

    if (state.newContactStep === NewContactStep.SELECT_DUPLICATE) {
      dispatch({
        type: ActionKind.SET_CONTACT_STEP,
        payload: { ...state, newContactStep: NewContactStep.ADD_CONTACT },
      });

      dispatch({
        type: ActionKind.SET_IS_DUPLICATE_OR_NEW_CONTACT_SELECTED,
        payload: { ...state, isDuplicateOrNewContactSelected: false },
      });
    }
  };

  const handleSaveDuringAddClick = () => {
    void findContacts({
      variables: {
        input: {
          firstName: isNewContactEntity ? undefined : values.firstName || undefined,
          lastName: isNewContactEntity ? undefined : values.lastName || undefined,
          licenseNumber: values.licenseNumber || undefined,
          nmlsId: values.nmlsId || undefined,
          email: values.email || undefined,
          entityName: isNewContactEntity ? values.entityName || undefined : undefined,
          organizationId: organizationId || undefined,
        },
      },
    });
  };

  const handleSelectClick = () => {
    if (values.id === state.selectedContactData.id) {
      void createContact();
    } else {
      handleSubmit();
    }
  };

  const handleSaveDuringSearchClick = () => {
    const cleanChanges = cleanseUpdateContactInput(state.selectedContactData, values);

    const hasNoContactTypeChange = state.selectedContactData.contactType === cleanChanges.contactType;

    // skip to handleSubmit if there are no changes or the contactType doesn't change
    if (isEmpty(cleanChanges) || hasNoContactTypeChange) {
      handleSubmit();
    } else {
      void updateContact({
        variables: {
          where: {
            id: state.selectedContactData.id,
          },
          input: {
            // do not update contact type if contact has one already - per OM-2057
            type: state.selectedContactData.contactType ? undefined : cleanChanges.contactType,
            firstName: cleanChanges.firstName,
            middleName: cleanChanges.middleName,
            lastName: cleanChanges.lastName,
            licenseNumber: cleanChanges.licenseNumber,
            stateOfOperation: cleanChanges.stateOfOperation,
            nmlsId: cleanChanges.nmlsId,
            entityName: cleanChanges.entityName,
          },
        },
      });
    }
  };

  const handleClearErrorAlert = () => {
    dispatch({
      type: ActionKind.SET_CONTACT_GQL_ERROR,
      payload: { ...state, contactGQLError: null },
    });
  };

  const drawerTitle: string = setNewContactDrawerTitle(state.newContactStep);
  const gqlErrorMessageText = state.contactGQLError ? getContactErrorMessageText(state.contactGQLError) : null;

  return (
    <Drawer
      data-test-id="add-contact-drawer"
      id="add-contact-drawer"
      isOpen={isDrawerOpen}
      placement="right"
      onClose={() => {
        handleDrawerClose();
      }}
    >
      <DrawerContent>
        <DrawerActionBar>
          <Flex
            alignItems="center"
            justifyContent={isSearchAndUpdate || isAddContact || isDuplicates ? 'space-between' : 'flex-end'}
            width="100%"
          >
            {isSearchAndUpdate || isAddContact || isDuplicates ? (
              <Button iconLeft="ArrowChevronLeft" size="none" variant="unstyled" onClick={handleBackClick}>
                Back
              </Button>
            ) : null}
            <DrawerCloseButton />
          </Flex>
        </DrawerActionBar>
        <Flex flex={1} flexDirection="column" overflow="auto">
          {!isDuplicates ? (
            <DrawerSection pb="space40">
              <Heading as="h3" pb="space40" size="fontSize50">
                {drawerTitle}
              </Heading>
              <Text as="p" size="fontSize20">
                {propertyAddress}
              </Text>
            </DrawerSection>
          ) : null}
          {showGqlErrorMessage ? (
            <DrawerSection>
              <ErrorMessage
                closeAction={handleClearErrorAlert}
                description={gqlErrorMessageText?.description}
                isCloseButtonShown={false}
                title={gqlErrorMessageText?.label}
              />
            </DrawerSection>
          ) : null}
          {showExistingContactErrorMessage ? (
            <DrawerSection pb="0">
              <ErrorMessage
                closeAction={() => {
                  setShowExistingContactErrorMessage(false);
                }}
                description={contactIsParticipantError?.description}
                title={contactIsParticipantError?.label}
              />
            </DrawerSection>
          ) : null}
          {showEditingAlert ? (
            <DrawerSection pb="0">
              <Alert status="info">
                <AlertIcon />
                <AlertContent>
                  <AlertTitle as="p">Updating existing contact</AlertTitle>
                  <AlertDescription as="p">Any edits you make will be saved to the contact profile.</AlertDescription>
                </AlertContent>
              </Alert>
            </DrawerSection>
          ) : null}

          {state.newContactStep === NewContactStep.SEARCH_CONTACT ||
          state.newContactStep === NewContactStep.SEARCH_UPDATE_CONTACT ||
          state.newContactStep === NewContactStep.ADD_CONTACT ? (
            <ContactDetailsForm transactionType={transactionType} />
          ) : (
            <SelectDuplicateOrNewContact isDuplicateAndNewCheckStep options={findContactsData?.findContacts || []} />
          )}
        </Flex>
        <DrawerFooter>
          <Button
            isDisabled={isSubmitting}
            mr="space50"
            variant="outline"
            onClick={() => {
              handleReset();
              dispatch({
                type: ActionKind.SET_CONTACT_STEP,
                payload: { ...state, newContactStep: createContactStep },
              });

              handleCancel();
            }}
          >
            Cancel
          </Button>
          {state.newContactStep === NewContactStep.ADD_CONTACT && (
            <Button
              data-test-id="save-contact-button"
              isDisabled={
                isSubmitting ||
                !dirty ||
                !isValid ||
                findLoading ||
                createContactLoading ||
                isSearch ||
                isAlreadyAddedContact
              }
              isLoading={isSubmitting || findLoading || createContactLoading}
              type="button"
              onClick={handleSaveDuringAddClick}
            >
              <Text color="white" fontWeight="semi">
                {findLoading || createContactLoading || isSubmitting ? 'Saving...' : 'Save'}
              </Text>
            </Button>
          )}
          {state.newContactStep === NewContactStep.SELECT_DUPLICATE && (
            <Button
              data-test-id="select-contact-button"
              isDisabled={isSelectButtonDisabled}
              isLoading={isSubmitting}
              type="submit"
              onClick={handleSelectClick}
            >
              {isSubmitting ? 'Adding to File...' : 'Select'}
            </Button>
          )}
          {(state.newContactStep === NewContactStep.SEARCH_CONTACT ||
            state.newContactStep === NewContactStep.SEARCH_UPDATE_CONTACT) && (
            <Button
              data-test-id="select-contact-button"
              isDisabled={isSubmitting || !dirty || !isValid || updateContactLoading || isAlreadyAddedContact}
              isLoading={isSubmitting || updateContactLoading}
              type="submit"
              onClick={handleSaveDuringSearchClick}
            >
              {isSubmitting ? 'Adding to File...' : 'Save'}
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
