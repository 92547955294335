import React, { FC, ReactElement } from 'react';
import { Flex, Text, Box } from '@endpoint/blockparty';
import { formatLikeTitle } from 'helpers/formatText';

export interface CustomerStatusDates {
  toDoDelivered?: string;
  toDoStarted?: string;
  submitted?: string;
  reset?: string;
}
interface CardBodyProp {
  data: CustomerStatusDates;
}

export const CustomerStatusCardBody: FC<CardBodyProp> = ({ data }) => (
  <Box>
    {Object.entries(data).map((key): ReactElement | null => {
      if (key[1] !== undefined && !Array.isArray(key[1])) {
        return (
          <Box key={key[0]} pt="space20">
            <Flex key={`row-${key[0]}`} alignItems="center" data-test-id={key[0]}>
              <Text as="p" color="carbon900" data-test-id={`${key[0]}-label`} mr="space30" size="fontSize20">
                {formatLikeTitle(key[0])}
              </Text>
              <Text as="p" color="carbon500" data-test-id={`${key[0]}-value`} size="fontSize10">
                {key[1]}
              </Text>
            </Flex>
          </Box>
        );
      }

      return null;
    })}
  </Box>
);
