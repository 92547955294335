import React, { FC } from 'react';
import { TransactionRole } from '@endpoint/opsware-bff-graphql-schema';
import { Button, Modal, ModalContent, ModalSection, ModalFooter, Text, useToast } from '@endpoint/blockparty';
import { useMutation } from '@apollo/client';
import { REMOVE_PARTICIPANT_ROLES } from 'routes/Transaction/ContactDetails/queries';
import { trackAnalytics } from 'helpers/utils/segment/segmentAnalytics';
import { navigate } from '@reach/router';
import { GET_CONTACTS } from 'routes/Transaction/TransactionContacts/queries';
import { GET_DOCUMENTS } from 'routes/Transaction/Documents/queries';
import { GET_TASKS } from 'routes/Transaction/TaskList/queries';
import { buildQueryInput } from 'helpers/buildQueryInput';

interface RemoveTransactionParticipantModalProps {
  isRemoveTransactionParticipantModalOpen: boolean;
  participantId: string;
  roles: TransactionRole[];
  toggleModal: () => void;
  transactionIdInput: string;
}

export const RemoveTransactionParticipantModal: FC<RemoveTransactionParticipantModalProps> = ({
  isRemoveTransactionParticipantModalOpen,
  participantId,
  roles,
  toggleModal,
  transactionIdInput,
}) => {
  const toast = useToast();
  const queryInput = buildQueryInput(transactionIdInput);
  const contactPagePath = `/transaction/${transactionIdInput}/contacts`;
  const [removeTransactionParticipantRoles, { loading }] = useMutation(REMOVE_PARTICIPANT_ROLES, {
    onCompleted(): void {
      toast({
        description: 'Contact removed from transaction',
        duration: 5000,
        icon: 'CheckCircle',
      });

      trackAnalytics('Remove Contact Saved', {});
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(contactPagePath);
    },
    onError(): void {
      toast({
        description: 'Unable to remove contact',
        duration: 5000,
        icon: 'ErrorCircle',
        iconColor: 'watermelon500',
      });
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CONTACTS,
        variables: {
          where: queryInput,
        },
      },
      {
        query: GET_DOCUMENTS,
        variables: {
          where: queryInput,
        },
      },
      {
        query: GET_TASKS,
        variables: {
          where: queryInput,
        },
      },
    ],
  });

  const handleConfirm = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    removeTransactionParticipantRoles({ variables: { where: { id: participantId }, roles } });
    toggleModal();
  };

  return (
    <Modal isOpen={isRemoveTransactionParticipantModalOpen} onClose={toggleModal}>
      <ModalContent zIndex={2}>
        <ModalSection>
          <Text as="h1" fontWeight="semi" pb="space40" size="fontSize30">
            Remove contact from this transaction?
          </Text>
          <Text as="p">
            If a contact is removed in error, they will need to be added back to the transaction from scratch.
          </Text>
        </ModalSection>
        <ModalFooter>
          <Button mr="space50" variant="outline" onClick={() => toggleModal()}>
            Cancel
          </Button>
          <Button
            data-test-id="remove-from-transaction-button"
            isDisabled={loading}
            isLoading={loading}
            onClick={handleConfirm}
          >
            Yes, remove
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
