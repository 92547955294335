export class FileUpload {
  reader: FileReader;
  xhr: XMLHttpRequest;
  file: File;
  url: string;
  documentId?: string;
  downloadUrl?: string;

  onProgress?: (progress: number) => void;
  onComplete?: () => void;
  onError?: () => void;

  constructor(file: File, url: string, documentId?: string, downloadUrl?: string) {
    this.file = file;
    this.url = url;
    this.documentId = documentId;
    this.downloadUrl = downloadUrl;
    this.reader = new FileReader();
    this.xhr = new XMLHttpRequest();

    this.onProgressEventListener = this.onProgressEventListener.bind(this);
    this.onLoadEventListener = this.onLoadEventListener.bind(this);
    this.onReaderOnLoad = this.onReaderOnLoad.bind(this);
    this.onErrorEventListener = this.onErrorEventListener.bind(this);

    this.xhr.upload.addEventListener('progress', this.onProgressEventListener, false);
    this.xhr.addEventListener('load', this.onLoadEventListener, false);
    this.xhr.addEventListener('error', this.onErrorEventListener, false);
    this.reader.onload = this.onReaderOnLoad;
  }

  public cancel() {
    this.xhr.abort();
  }

  private onProgressEventListener(event: ProgressEvent) {
    if (event.lengthComputable) {
      const percentage = Math.round((event.loaded * 100) / event.total);

      if (this.onProgress) {
        this.onProgress(percentage);
      }
    }
  }

  private onLoadEventListener(event: ProgressEvent) {
    if (this.xhr.status === 200 && this.onComplete) {
      this.onComplete();
    } else if (this.xhr.status !== 200 && this.onError) {
      this.onError();
    }
  }

  private onReaderOnLoad(event: ProgressEvent<FileReader>) {
    if (event.target && typeof event.target.result === 'string') {
      // s3 accepts binary encoded blob - convert the target blob string to a Buffer with latin1 (binary) encoding, per the documentation https://nodejs.org/api/buffer.html
      const fileToBuffer = Buffer.from(event.target.result, 'latin1');

      this.xhr.open('PUT', this.url, true);
      this.xhr.setRequestHeader('Content-Type', this.file.type);
      this.xhr.send(fileToBuffer);
    } else {
      this.onErrorEventListener(event);
    }
  }

  private onErrorEventListener(event: ProgressEvent) {
    if (this.onError) {
      this.onError();
    }
  }

  public start() {
    this.reader.readAsBinaryString(this.file);
  }
}
