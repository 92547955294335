import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Layout } from 'components/Layout';
import { NotAuthorized } from 'components/NotAuthorized';

export const Dogs403: FC<RouteComponentProps> = () => (
  <Layout>
    <NotAuthorized />
  </Layout>
);
