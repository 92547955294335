import { gql } from '@apollo/client';
import { Transaction } from '@endpoint/opsware-bff-graphql-schema/dist/types';

export const GET_TASKS = gql`
  query getTasks($where: TransactionWhereUniqueInput!) {
    transaction(where: $where) {
      fileNum
      id
      type
      isOpsReviewComplete
      status
      milestones {
        id
        type
        name
        position
        pinnedItems {
          roles
          data
        }
        status
        statusOptions
        description
        type
        tasks {
          id
          name
          status
          statusOptions
          todos {
            id
            due
            assignments {
              id
              status
            }
          }
        }
      }
      participants {
        id
        roles
        contact {
          id
          phone
          email
          ... on AgentContact {
            firstName
            lastName
          }
          ... on LenderContact {
            firstName
            lastName
          }
          ... on IndividualContact {
            firstName
            lastName
          }
          ... on EntityContact {
            entityName
          }
        }
      }
    }
  }
`;

export interface GetTasksType {
  transaction: Transaction;
}
