import { OpsTasksSortProperties } from '@endpoint/opsware-bff-graphql-schema';

export enum QueueSortTableAccessor {
  submittedDate = 'submittedDate',
  closingDate = 'closingDate',
}

export const toOpsTasksSortPropertiesMap: Record<QueueSortTableAccessor, OpsTasksSortProperties> = {
  submittedDate: OpsTasksSortProperties.SUBMITTED_DATE,
  closingDate: OpsTasksSortProperties.CLOSING_DATE,
};

export const toOpsTasksTableAccessorMap: Record<OpsTasksSortProperties, QueueSortTableAccessor> = {
  [OpsTasksSortProperties.SUBMITTED_DATE]: QueueSortTableAccessor.submittedDate,
  [OpsTasksSortProperties.CLOSING_DATE]: QueueSortTableAccessor.closingDate,
};
