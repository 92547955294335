import React, { FC, ReactElement, ReactNode } from 'react';
import { FieldArray, ArrayHelpers } from 'formik';
import { findIndex, cloneDeep } from 'lodash';
import { Avatar, Flex, InputGroup, Text, Search, InputLabel } from '@endpoint/blockparty';
import { TodoStatus, TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { InputProps } from 'components/TextArea';
import { TodoAssignmentToUpdate } from 'consts/toDoConsts';
import { getParticipantSelectOptions, UserOption } from 'helpers/transactionParticipants';

interface AddAssigneeProps extends InputProps {
  transactionParticipants: Partial<TransactionParticipant>[];
  values: TodoAssignmentToUpdate[];
}

interface Context {
  context: string;
}

export const addAssigneeOnChange = (
  option: UserOption,
  values: TodoAssignmentToUpdate[],
  helpers: Pick<ArrayHelpers, 'push' | 'replace'>,
): void => {
  const optionIdInListIndex = findIndex(values, (value) => value.todoAssigneeId === option.id);
  let newEntry: TodoAssignmentToUpdate;

  if (optionIdInListIndex < 0) {
    newEntry = {
      id: '',
      status: TodoStatus.DELIVERED,
      todoAssigneeId: option.id,
      assigneeRoles: option.roles || [],
      assigneeFirstName: option.firstName,
      assigneeLastName: option.lastName,
    };

    helpers.push(newEntry);
  } else {
    newEntry = {
      ...values[optionIdInListIndex],
      status: TodoStatus.DELIVERED,
    };

    helpers.replace(optionIdInListIndex, newEntry);
  }
};

export const AddAssigneeField: FC<AddAssigneeProps> = ({ transactionParticipants, values, label, name }) => {
  const participantSelectOptions = getParticipantSelectOptions(transactionParticipants);

  const formatOptionLabel = (data: unknown, { context }: Context): ReactNode => {
    const { label: optionLabel, src, value, fullName, formattedRoles } = data as Partial<UserOption>;

    if (context === 'value') {
      return optionLabel;
    }

    return (
      <Flex alignItems="center">
        <Avatar alignSelf="flex-start" mr="space40" name={value || ''} size={24} src={src} />
        <Flex flexDirection="column">
          <Text maxWidth="280px" overflow="hidden" size="fontSize20" textOverflow="ellipsis" whiteSpace="nowrap">
            {fullName}
          </Text>
          <Text
            color="carbon500"
            maxWidth="280px"
            overflow="hidden"
            size="fontSize10"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {formattedRoles}
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <InputGroup data-test-id="add-assignee-input-group">
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <FieldArray
        name={name}
        render={(arrayHelpers: ArrayHelpers): ReactElement => (
          <Search
            classNamePrefix="list"
            formatOptionLabel={formatOptionLabel}
            options={participantSelectOptions}
            placeholder="Search by name or role"
            value={null}
            onChange={(option: UserOption): void => {
              if (option) {
                const clonedValues = cloneDeep(values);

                addAssigneeOnChange(option, clonedValues, arrayHelpers);
              }
            }}
          />
        )}
      />
    </InputGroup>
  );
};

AddAssigneeField.displayName = 'AddAssigneeField';
