import React, { FC, ReactNode } from 'react';
import { TransactionParticipant } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { Box, Flex, Text, Divider } from '@endpoint/blockparty';
import { OpswareAvatar } from 'components/Avatar';
import { formatRolesLikeTitle, formatToCamelCase, giveFirstWord } from 'helpers/formatText';
import { AllContactTypes } from 'consts/contactDetails';
import { getContactData } from 'helpers/transactionParticipants';

interface CardBodyRowProps {
  label: string;
  value: string;
}

interface AssigneesRowProps {
  label?: string;
  values: TransactionParticipant[] | undefined;
  location?: string;
}

interface AssigneesProp {
  assignees: TransactionParticipant[] | undefined;
  location?: string;
}

export const CardBodyRow: FC<CardBodyRowProps> = ({ label, value }) => (
  <Flex flexDirection="column" pt="space40">
    <Text as="p" color="carbon500" data-test-id={`${label}-label`} mb="space30" size="fontSize10">
      {label}
    </Text>
    <Text as="p" color="carbon900" data-test-id={`${label}-value`} mb="space40" size="fontSize20">
      {value}
    </Text>
    <Divider />
  </Flex>
);

export const AssigneesRow: FC<AssigneesRowProps> = ({ location, label, values }) => (
  <Flex flexDirection="column" pt="space40">
    {label && (
      <Text as="p" color="carbon500" data-test-id="assignees-label" size="fontSize10">
        Assignees
      </Text>
    )}
    <AssigneesList assignees={values} location={location} />
  </Flex>
);

export const AssigneesList: FC<AssigneesProp> = ({ assignees, location }) => {
  if (!assignees) {
    return null;
  }

  return (
    <Box data-test-id={`${location}-participants-row`}>
      {assignees.map((assignee: TransactionParticipant): ReactNode => {
        const contact = assignee.contact as AllContactTypes;
        const { fullName, firstName, lastName } = getContactData(contact);
        const roles = assignee.roles.length > 0 ? `${formatRolesLikeTitle(assignee.roles.join(', '), true)}` : '';
        const avatarName = `${giveFirstWord(firstName)} ${giveFirstWord(lastName)}`.trim();

        return (
          <Box key={`assignee-${assignee.id}`} data-test-id="assignee-box" display="flex" pt="space40">
            <OpswareAvatar name={avatarName || ''} size={24} src="" />

            <Flex data-test-id={`assignee-name-${formatToCamelCase(fullName || '')}`} flexDirection="column">
              <Text
                maxWidth="235px"
                ml="space40"
                overflow="hidden"
                size="fontSize20"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {fullName}
              </Text>
              <Text
                color="carbon500"
                maxWidth="235px"
                ml="space40"
                overflow="hidden"
                size="fontSize10"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {roles}
              </Text>
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};

AssigneesRow.displayName = 'AssigneesRow';
