import React, { FC, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useToast, Flex, Switch, Box, Text, useDisclosure } from '@endpoint/blockparty';
import { Market } from '@endpoint/opsware-bff-graphql-schema';
import { ConfirmMarketStatusModal } from 'routes/Admin/Markets/TableComponents/ConfirmMarketStatusModal';

import { UpdateMarketsResult, UPDATE_MARKET } from '../../queries';

interface StatusButtonProps {
  market: Market;
}

export const SwitchButton: FC<StatusButtonProps> = ({ market }) => {
  const [toggleMarket, setToggleMarket] = useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setToggleMarket(market.active);
  }, [market.active]);

  const toast = useToast();
  const [updateMarket, { loading }] = useMutation<UpdateMarketsResult>(UPDATE_MARKET, {
    onCompleted(): void {
      toast({
        description: `${market.name} ${toggleMarket ? 'deactivated' : 'activated'}`,
        duration: 5000,
        icon: 'CheckCircle',
      });

      setToggleMarket(!toggleMarket);
    },
    onError(): void {
      toast({
        description: 'Action was not successful',
        duration: 5000,
        iconColor: 'watermelon500',
        icon: 'ErrorCircle',
      });
    },
  });

  const changeMarketStatus = () => {
    onClose();
    void updateMarket({
      variables: {
        where: { id: market.id },
        input: { active: !toggleMarket },
      },
    });
  };

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Switch
        data-test-id="admin-market-switch-button"
        id="status-toggle-button"
        isChecked={toggleMarket}
        isDisabled={loading}
        onClick={onOpen}
      />
      <Box as="label" htmlFor="status-toggle-button" pl="space40">
        <Text as="p">{toggleMarket ? 'Activated' : 'Activate'}</Text>
      </Box>
      <ConfirmMarketStatusModal
        isOpen={isOpen}
        title={`Are you sure you wish to ${!toggleMarket ? 'activate' : 'deactivate'} this market?`}
        onClose={onClose}
        onConfirm={changeMarketStatus}
      />
    </Flex>
  );
};
