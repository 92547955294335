import React, { FC } from 'react';
import { v4 } from 'uuid';
import { Box, Button, Stack } from '@endpoint/blockparty';
import { TaskStepItemDefinition } from 'helpers/dynamicForm/types';

import { TaskStepItemView } from '../TaskStepItemView';

interface TaskStepViewProps {
  items: TaskStepItemDefinition[];
  isLoading: boolean;
}

export const TaskStepView: FC<TaskStepViewProps> = ({ items, isLoading }) => {
  const ctaId = v4();

  return (
    <Stack gap="space10" paddingTop="space10">
      {items.map((item) => (
        <TaskStepItemView key={item.key} ctaId={ctaId} item={item} />
      ))}
      <Box>
        <Button id={ctaId} isLoading={isLoading} type="submit" width="20%">
          Save
        </Button>
      </Box>
    </Stack>
  );
};
