import React, { FC } from 'react';
import { Box, Stack, Text } from '@endpoint/blockparty';
import { useFormikContext } from 'formik';
import { CompletedView, FormikItems, TaskStepItemDefinition } from 'helpers/dynamicForm/types';
import { v4 } from 'uuid';

interface TaskStepCompletedViewProps {
  items: TaskStepItemDefinition[];
  completedView: CompletedView;
}

export const TaskStepCompletedView: FC<TaskStepCompletedViewProps> = ({ items, completedView }) => {
  const { values } = useFormikContext<FormikItems>();
  const { showResponse, message } = completedView;

  const flattenValues = (formikItems?: FormikItems, itemDefinitions?: TaskStepItemDefinition[]): string[] => {
    let flattenedValues: string[] = [];

    if (!formikItems || !itemDefinitions?.length) return flattenedValues;

    for (const [formikKey, formikValue] of Object.entries(formikItems)) {
      const itemDefinition = itemDefinitions.find((definition) => definition.key === formikKey);
      let formikValues: string[] = [];

      formikValues = formikValues.concat(formikValue.value);

      for (const value of formikValues) {
        const label = itemDefinition?.options?.find((option) => option.value === value)?.label;

        flattenedValues = flattenedValues.concat(label ?? value);

        const flattenedFollowUpValues = flattenValues(formikValue.followUp, itemDefinition?.followUp?.[value]);

        if (flattenedFollowUpValues?.length) {
          flattenedValues = flattenedValues.concat(flattenedFollowUpValues);
        }
      }
    }

    return flattenedValues;
  };

  if (!message && !showResponse) {
    return null;
  }

  return (
    <Stack paddingTop="space10">
      {message && (
        <Box>
          <Text data-test-id="task-step-completed-view-message">{completedView.message}</Text>
        </Box>
      )}
      {showResponse && (
        <Box>
          {flattenValues(values, items).map((value) => (
            <Box key={v4()}>
              <Text>{value}</Text>
            </Box>
          ))}
        </Box>
      )}
    </Stack>
  );
};
