import React, { FC } from 'react';
import { Flex, Icon, Tooltip } from '@endpoint/blockparty';
import { OpsTaskStatus } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { IconNames } from '@endpoint/blockparty/dist/components/Icon/iconList';
import { mapStatusToLabel, mapStatusToIcon } from 'consts/opsTasks';

interface QueueTableCellStatusProps {
  status: OpsTaskStatus;
  isOverdue: boolean;
}

const overdueIcon: { color: string; icon: IconNames } = {
  color: 'watermelon500',
  icon: 'CircleOutline',
};

export const QueueTableCellStatus: FC<QueueTableCellStatusProps> = ({ status, isOverdue }) => {
  const statusLabel = isOverdue ? 'Overdue' : mapStatusToLabel[status];
  const { color, icon } = isOverdue ? overdueIcon : mapStatusToIcon[status];

  return (
    <Tooltip label={statusLabel}>
      <Flex aria-label={statusLabel} data-test-id="queue-table-cell-status" flex="1" justifyContent="center">
        <Icon color={color} name={icon} size="medium" />
      </Flex>
    </Tooltip>
  );
};
