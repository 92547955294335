import React, { FC } from 'react';
import { useToast } from '@endpoint/blockparty';
import { WorkflowPrompt } from 'routes/Transaction/TransactionBasicInfo/WorkflowPrompts/WorkflowPrompt';
import { useMutation } from '@apollo/client';
import {
  MilestoneType,
  QualityControlStatus,
  Transaction,
  TransactionStatus,
} from '@endpoint/opsware-bff-graphql-schema/';
import { TransactionUpdateResult } from 'routes/Dashboard/OpenOrderList/queries';
import { UPDATE_TRANSACTION_BASIC_INFO } from 'routes/Transaction/TransactionBasicInfo/queries';
import { updateQcStatusTransactionPayload } from 'helpers/updateTransaction';
import { networkFailureInfo, qcReadyInfo } from 'consts/toastProps';
import { useMilestoneValidationErrors } from 'hooks/useMilestoneValidationErrors';

import { ModalAgentsValidation } from './ModalAgentsValidation';

interface WorkflowPromptQCPreparingOrderProps {
  transaction: Transaction;
}

export const WorkflowPromptQCPreparingOrder: FC<WorkflowPromptQCPreparingOrderProps> = ({ transaction }) => {
  const toast = useToast();
  const { data: milestoneValidationErrors } = useMilestoneValidationErrors(transaction.id, MilestoneType.ESCROW_OPENED);
  const [updateTransaction, { loading }] = useMutation<TransactionUpdateResult>(UPDATE_TRANSACTION_BASIC_INFO, {
    onCompleted(): void {
      toast(qcReadyInfo);
    },
    onError(): void {
      toast(networkFailureInfo);
    },
  });

  const shouldShowWorkflowPrompt =
    transaction.status === TransactionStatus.IN_ESCROW &&
    transaction.qualityControlStatus === QualityControlStatus.PREPARING_ORDER;

  const updateToQCReady = () => {
    void updateTransaction(updateQcStatusTransactionPayload(transaction.id, QualityControlStatus.QC_READY));
  };

  if (!shouldShowWorkflowPrompt) {
    return null;
  }

  const dataTestId = 'qc-preparing-order-prompt';

  return (
    <WorkflowPrompt
      dataTestId={dataTestId}
      description="When the order is ready, mark transaction as Ready for QC."
      listValues={milestoneValidationErrors?.milestoneValidationErrors}
      status="warning"
      title="Preparing Order"
    >
      <ModalAgentsValidation loading={loading} transaction={transaction} updateToQCReady={updateToQCReady} />
    </WorkflowPrompt>
  );
};
