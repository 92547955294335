import React, { FC, useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTable, Column, useSortBy, useFlexLayout, Row, ColumnInstance } from 'react-table';
import { Box, Flex, Icon, Table, TableBody, TableCell, Avatar, TableFooter } from '@endpoint/blockparty';
import { OpsTask, SearchOpsTaskByParamsResult } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { QueueTableCellStatus } from 'routes/Queue/QueueTable/QueueTableCellStatus';
import { Pagination } from 'components/Pagination';
import { useQueueFilters, getSortQuery, useGetInitialSortState } from 'helpers/queue';
import GenericTableRow from 'components/GenericTableRow';
import GenericTableHeader from 'components/GenericTableHeader';
import GenericColumnHeader from 'components/GenericColumnHeader';

export const columnHeaders: Column<OpsTask>[] = [
  {
    Header: () => (
      <Flex flex="1" justifyContent="center">
        Status
      </Flex>
    ),
    accessor: 'status',
    disableSortBy: true,
    maxWidth: 40,
    Cell: ({ cell: { value, row } }) => <QueueTableCellStatus isOverdue={row.original.isOverdue} status={value} />,
  },
  {
    Header: 'File #',
    accessor: (row) => row.transaction.fileNum,
    disableSortBy: true,
    Cell: ({ cell: { value, row } }: { cell: { value: string; row: Row<OpsTask> } }) => (
      <Box color={row.original.assigneeName ? 'carbon500' : 'carbon900'}>{value}</Box>
    ),
  },
  {
    Header: 'Task Name',
    accessor: 'taskTitle',
    disableSortBy: true,
    Cell: ({ cell: { value, row } }) => <Box color={row.original.assigneeName ? 'carbon500' : 'blue500'}>{value}</Box>,
  },
  {
    Header: 'Submitted Date',
    id: 'submittedDate',
    accessor: (row) => new Date(row.createdOn).toLocaleDateString(),
    disableSortBy: false,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      const textColor = 'carbon900';

      return <Box color={textColor}>{value}</Box>;
    },
  },
  {
    Header: 'Closing Date',
    id: 'closingDate',
    accessor: (row) => new Date(row.dueDateTime).toLocaleDateString(),
    disableSortBy: false,
    Cell: ({ cell: { value, row } }: { cell: { value: string; row: Row<OpsTask> } }) => {
      let textColor: string;

      if (row.original.isOverdue) {
        textColor = 'watermelon500';
      } else if (row.original.assigneeName) {
        textColor = 'carbon500';
      } else {
        textColor = 'carbon900';
      }

      return <Box color={textColor}>{value}</Box>;
    },
  },
  {
    Header: 'Ops User',
    accessor: 'assigneeName',
    disableSortBy: true,
    Cell: ({ cell: { value } }) =>
      value && (
        <Flex alignItems="center" color="carbon500">
          <Avatar color="carbon500" name={value} size={30} />
          <Box pl="space30">{value}</Box>
        </Flex>
      ),
  },
];

function ColumnHeader({ column }: { column: ColumnInstance<OpsTask> }): React.ReactElement {
  const { navigateWithParams } = useQueueFilters();

  return (
    <GenericColumnHeader<OpsTask>
      column={column}
      onClick={() => {
        navigateWithParams({ sort: getSortQuery(column), pageIndex: '1' });
      }}
    />
  );
}

const columnHeaderInstance = (column: ColumnInstance<OpsTask>) => <ColumnHeader column={column} />;

interface QueueTableFooterProps {
  fetchingOpsTasks: boolean;
  totalItems: number;
}

export const QueueTableFooter: FC<QueueTableFooterProps> = ({ fetchingOpsTasks, totalItems }) => {
  const { currentParams, navigateWithParams } = useQueueFilters();

  return (
    <TableFooter borderTop="1px solid" borderTopColor="carbon100">
      <Pagination
        currentParams={currentParams}
        loading={fetchingOpsTasks}
        navigateWithParams={navigateWithParams}
        totalItems={totalItems}
      />
    </TableFooter>
  );
};

interface QueueTableProps extends SearchOpsTaskByParamsResult, RouteComponentProps {
  fetchingOpsTasks: boolean;
  onClick(opsTask?: OpsTask): void;
}

const QueueTable: FC<QueueTableProps> = ({ items: opsTasks, fetchingOpsTasks, onClick, totalItems }) => {
  const columns = useMemo(() => columnHeaders, []);
  const opsTasksData = useMemo(() => opsTasks || [], [opsTasks]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: opsTasksData,
      manualSortBy: true,
      initialState: {
        sortBy: useGetInitialSortState(),
      },
    },
    useSortBy,
    useFlexLayout,
  );

  return (
    <Table label="Queue Table" maxHeight="calc(100vh - 235px)" {...getTableProps()}>
      <GenericTableHeader
        columnHeader={columnHeaderInstance}
        dataTestId="queue-table-header"
        headerGroups={headerGroups}
      />
      <TableBody _hover={{ cursor: 'pointer' }} {...getTableBodyProps()}>
        {rows.length ? (
          rows.map((row) => {
            prepareRow(row);
            const { key } = row.getRowProps();

            return (
              <GenericTableRow<OpsTask> key={key} dataTestId={`row-${row?.original?.id}`} row={row} onClick={onClick} />
            );
          })
        ) : (
          <TableCell alignItems="center" color="carbon500" height="100%" justifyContent="center" py="space30">
            No matching records found. Try adjusting your search.
          </TableCell>
        )}
      </TableBody>
      {rows.length ? <QueueTableFooter fetchingOpsTasks={fetchingOpsTasks} totalItems={totalItems} /> : null}
    </Table>
  );
};

export default QueueTable;
