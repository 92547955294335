import {
  Flex,
  Icon,
  Input,
  InputElemGroup,
  InputErrorMessage,
  InputGroup,
  InputLabel,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@endpoint/blockparty';
import { TransactionType } from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { useFormikContext } from 'formik';
import React, { FC, ChangeEvent, useCallback } from 'react';
import Cleave from 'cleave.js/react';
import { formatCurrencyStringToNumber } from 'helpers/formatText';
import { OcrConfidenceScoreText } from 'components/OcrConfidenceScoreText';
import { currencyInputRegex } from 'consts/currencyInputRegex';
import { formatInputValue } from 'helpers/openOrder/formatInput';
import { delay } from 'lodash';
import { preventMinus, preventNegativeNumberCopyPaste } from 'helpers/openOrder/preventMinusInput';
import { OpenOrderDrawerForm } from 'helpers/openOrder/openOrderFormTypes';

export const Terms: FC = () => {
  const { errors, values, setFieldValue, handleChange, setFieldTouched, touched, initialValues } =
    useFormikContext<OpenOrderDrawerForm>();

  const isRefinance = values.terms?.productType === TransactionType.REFINANCE;
  const isSaleMortgage = values.terms?.productType === TransactionType.SALE_MORTGAGE;
  const isEquity = values.terms?.productType === TransactionType.EQUITY;

  const termsErrors = errors.terms;

  const salePriceInvalid = touched?.terms?.salePrice && !!termsErrors?.salePrice;

  const showLoanNumber = isSaleMortgage || isRefinance || isEquity;

  const showEmdAmount = !isRefinance && !isEquity;

  const showLoanAmount = isSaleMortgage || isRefinance || isEquity;

  const showSalePrice = !isRefinance && !isEquity;

  const handleSalesPriceUpdate = useCallback(
    (value?: number | string) => {
      if (!value) {
        setFieldValue('terms.salePrice', '');
        setFieldTouched('terms.salePrice');
        setFieldValue('terms.emdPercent', '');

        /** adding a delay to the call of setFieldTouched ensures that any validation is run onChange */
        delay(setFieldTouched, 100, 'terms.emdPercent', true);

        return;
      }

      // Cast string (e.g. 1,000,000.00) to a number for emd amount and % calculation
      const formattedSalePrice = formatCurrencyStringToNumber(value);

      setFieldValue('terms.salePrice', formattedSalePrice);

      delay(setFieldTouched, 100, 'terms.salePrice', true);

      // If EMD Amount is unknown set EMD % as null ELSE use the formattedSalePrice and EMD Amount to calculate the EMD %
      if (!values.terms.emdAmount) {
        setFieldValue('terms.emdPercent', null);
        /** adding a delay to the call of setFieldTouched ensures that any validation is run onChange */
        delay(setFieldTouched, 100, 'terms.emdPercent', true);
      } else if (values.terms.emdAmount) {
        const emdAmount = formatInputValue(values.terms.emdAmount);

        setFieldValue('terms.emdPercent', ((100 * emdAmount) / formattedSalePrice).toFixed(2));
        /** adding a delay to the call of setFieldTouched ensures that any validation is run onChange */
        delay(setFieldTouched, 100, 'terms.emdPercent', true);
      }
    },
    [setFieldTouched, setFieldValue, values.terms.emdAmount],
  );

  return (
    <>
      {showSalePrice && (
        <InputGroup
          data-test-id="open-order-sale-price"
          groupId="salePrice"
          isInvalid={salePriceInvalid}
          pb="space30"
          pt="space50"
        >
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel color={salePriceInvalid ? 'watermelon500' : 'carbon600'} htmlFor="salePrice">
              Sale Price *
            </InputLabel>
            {values?.terms?.salePrice && values.ocrConfidenceScores?.salePrice && (
              <OcrConfidenceScoreText
                currentInputText={values.terms.salePrice}
                originalInputText={initialValues.terms?.salePrice}
                value={values.ocrConfidenceScores?.salePrice}
              />
            )}
          </Flex>
          <InputElemGroup>
            <InputLeftElement>
              <Icon name="DollarSign" />
            </InputLeftElement>

            <Input
              as={Cleave}
              autoComplete="off"
              name="terms.salePrice"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                rawValueTrimPrefix: true,
              }}
              value={values.terms.salePrice ?? ''}
              onBlur={() => handleSalesPriceUpdate(values?.terms?.salePrice)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleSalesPriceUpdate(event.target.value)}
            />
          </InputElemGroup>
          {salePriceInvalid && <InputErrorMessage>{termsErrors?.salePrice}</InputErrorMessage>}
        </InputGroup>
      )}

      {showLoanAmount ? (
        <InputGroup groupId="loanAmount" isInvalid={!!termsErrors?.loanAmount} pb="space30" pt="space50">
          <Flex flexDirection="row" justifyContent="space-between">
            <InputLabel>Loan Amount</InputLabel>
            {values?.terms?.loanAmount && values.ocrConfidenceScores?.loanAmount && (
              <OcrConfidenceScoreText
                currentInputText={values.terms.loanAmount}
                originalInputText={initialValues.terms?.loanAmount}
                value={values.ocrConfidenceScores?.loanAmount}
              />
            )}
          </Flex>
          <InputElemGroup>
            <InputLeftElement>
              <Icon name="DollarSign" />
            </InputLeftElement>

            <Input
              as={Cleave}
              autoComplete="off"
              name="terms.loanAmount"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                rawValueTrimPrefix: true,
              }}
              value={values.terms.loanAmount ?? ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const loanValue = preventNegativeNumberCopyPaste(event.target.value);

                if (!currencyInputRegex.test(loanValue)) return;
                setFieldValue('terms.loanAmount', loanValue);
              }}
              onKeyPress={preventMinus}
            />
          </InputElemGroup>
          {termsErrors?.loanAmount && <InputErrorMessage>{termsErrors?.loanAmount}</InputErrorMessage>}
        </InputGroup>
      ) : null}

      {showLoanNumber ? (
        <InputGroup
          data-test-id="loan-number-input-id"
          groupId="loanNumber"
          isInvalid={!!termsErrors?.loanAmount}
          pb="space30"
          pt="space50"
        >
          <InputLabel>Loan Number</InputLabel>
          <Input
            autoComplete="off"
            name="terms.loanNumber"
            value={values.terms.loanNumber ?? ''}
            onChange={handleChange}
          />
          {termsErrors?.loanNumber && <InputErrorMessage>{termsErrors?.loanNumber}</InputErrorMessage>}
        </InputGroup>
      ) : null}

      {showEmdAmount && (
        <InputGroup groupId="emdAmount" isInvalid={!!termsErrors?.loanAmount} pb="space30" pt="space50">
          <Flex flexDirection="row" width="100%">
            <Flex flexDirection="row" justifyContent="space-between" width="45%">
              <InputLabel>EMD Amount</InputLabel>
              {!!values?.terms?.emdAmount && values.ocrConfidenceScores?.emdAmount && (
                <OcrConfidenceScoreText
                  currentInputText={values.terms.emdAmount}
                  originalInputText={initialValues.terms?.emdAmount}
                  value={values.ocrConfidenceScores?.emdAmount}
                />
              )}
            </Flex>
          </Flex>
          <Flex alignItems="center">
            <InputElemGroup data-test-id="open-order-emd-dollarSign" flex={1} iconRightSpacing>
              <InputLeftElement>
                <Icon name="DollarSign" />
              </InputLeftElement>
              <Input
                as={Cleave}
                autoComplete="off"
                flex={1}
                name="terms.emdAmount"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                  rawValueTrimPrefix: true,
                }}
                value={values.terms.emdAmount || ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  // If EMD Amount value is deleted set EMD % to null
                  if (!event.target.value) {
                    setFieldValue('terms.emdAmount', null);
                    setFieldValue('terms.emdPercent', null);
                  }

                  const emdValue = preventNegativeNumberCopyPaste(event.target.value);

                  // format "10.00" --> 10
                  const emdAmount = formatCurrencyStringToNumber(emdValue);

                  setFieldValue('terms.emdAmount', emdAmount);

                  delay(setFieldTouched, 100, 'terms.emdAmount', true);

                  // If SalePrice and emdAmount are valid calculate the EMD % - ELSE Only set the emd amount value
                  if (values.terms.salePrice && emdAmount) {
                    const salesPrice = formatInputValue(values.terms.salePrice);

                    setFieldValue('terms.emdAmount', emdAmount);
                    setFieldValue('terms.emdPercent', ((100 * emdAmount) / salesPrice).toFixed(2));
                  } else if (emdAmount && !values.terms.salePrice) {
                    setFieldValue('terms.emdAmount', emdAmount);
                  }
                }}
                onKeyPress={preventMinus}
              />
            </InputElemGroup>
            <Text px="space50">=</Text>
            <InputElemGroup data-test-id="open-order-emd-percentage" flex={1} iconRightSpacing>
              <Input
                autoComplete="off"
                name="terms.emdPercent"
                type="number"
                value={values.terms.emdPercent ?? ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  // IF value is deleted set field to null and return
                  if (!event.target.value) {
                    setFieldValue('terms.emdPercent', null);

                    return;
                  }

                  if (!currencyInputRegex.test(event.target.value)) return;

                  /* 
                    IF Sale Price AND emd % - calculate the emd amount value
                    ELSE set Amount and % values as null
                  */
                  if (values.terms.salePrice && event.target.value) {
                    // cast percent and salePrice to number for calculation
                    const percentage = formatInputValue(event.target.value);
                    const salePrice = formatInputValue(values.terms.salePrice);

                    const emdAmount = (salePrice * percentage) / 100;

                    if (emdAmount > 0) {
                      setFieldValue('terms.emdAmount', emdAmount);
                    } else {
                      setFieldValue('terms.emdAmount', null);
                    }

                    setFieldValue('terms.emdPercent', percentage);
                  } else {
                    setFieldValue('terms.emdPercent', null);
                    setFieldValue('terms.emdAmount', null);
                  }
                }}
              />
              <InputRightElement>%</InputRightElement>
            </InputElemGroup>
          </Flex>
        </InputGroup>
      )}
    </>
  );
};
