import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { Box, Card, LayoutContent, LayoutSubContent } from '@endpoint/blockparty';
import { Layout } from 'components/Layout';
import { Page, TrackingEvent } from 'consts/segmentProtocols';
import { trackAnalytics, trackPage } from 'helpers/utils/segment/segmentAnalytics';
import { updateOpsTaskPayload } from 'helpers/opsTask';
import { GET_OPS_TASKS } from 'routes/Queue/queries';
import { QueueDrawer } from 'routes/Queue/QueueDrawer/QueueDrawer';
import QueueHeader from 'routes/Queue/QueueHeader';
import QueueTable from 'routes/Queue/QueueTable';
import {
  OpsSquad,
  OpsTask,
  OpsTasksFilter,
  OpsTasksSortProperties,
  SearchOpsTaskByParamsResult,
  SortDirection,
} from '@endpoint/opsware-bff-graphql-schema/dist/types';
import { SkeletonTable } from 'components/SkeletonTable';
import { ErrorMessage } from 'components/ErrorMessage';
import { buildIsOverdue, buildStatuses, generateQueueQueryFilters, useQueueFilters } from 'helpers/queue';
import QueueTableFilters from 'routes/Queue/QueueTableFilters';
import { Dogs403 } from 'routes/403';
import { Dogs404 } from 'routes/404';
import { SkeletonQueue } from 'routes/Queue/SkeletonQueue';
import { UPDATE_OPS_TASK } from 'routes/Transaction/TransactionRightPanel/queries';
import { GET_CURRENT_USER } from 'consts/currentUserQuery';
import { OpswareUser } from 'components/Auth/helpers';
import { defaultSquads } from 'consts/supportedTaskSquad';
import { defaultOptions, defaultStatuses, SupportedOptions } from 'consts/supportedTaskOptions';

interface QueueProps extends RouteComponentProps {}

const Queue: React.FC<QueueProps> = () => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>();
  const [showSquadsSelect, setShowSquadsSelect] = useState<boolean>();
  const { isQueuePageEnabled } = useFlags();
  const trackingEvent: TrackingEvent = 'Table Searched';
  const { currentParams, navigateWithParams } = useQueueFilters();
  const [updateOpsTask] = useMutation(UPDATE_OPS_TASK);

  const queueQueryFilters = generateQueueQueryFilters(currentParams);
  const { squads, query, statuses, marketIds, pageSize, pageNumber } = queueQueryFilters;
  const [squadsFilter, setSquadsFilter] = useState(squads ?? defaultSquads);
  const [statusesFilter, setStatusesFilter] = useState(statuses?.length ? statuses : defaultStatuses);
  const [isOverdueFilter, setIsOverdueFilter] = useState<boolean | undefined>(true);
  const [searchBarFilter, setSearchBarFilter] = useState(query);
  let trackingData;

  const {
    data: currentUserData,
    loading: opswareUserLoading,
    error: opswareUserLoadingError,
  } = useQuery<{ me: OpswareUser }>(GET_CURRENT_USER, {
    fetchPolicy: 'cache-only',
    variables: {
      includeSquad: true,
    },
  });
  const {
    data,
    loading: fetchingOpsTasks,
    error,
  } = useQuery<{ opsTasks: SearchOpsTaskByParamsResult }>(GET_OPS_TASKS, {
    variables: {
      filter: {
        squads: isEmpty(squadsFilter) ? defaultSquads : squadsFilter,
        statuses: statusesFilter,
        isOverdue: isOverdueFilter || undefined,
        query,
        marketIds,
        pageSize,
        pageNumber,
        sort: queueQueryFilters.sort || {
          propertyName: OpsTasksSortProperties.SUBMITTED_DATE,
          direction: SortDirection.ASC,
        },
      } as OpsTasksFilter,
    },
    onCompleted: () => {
      trackingData = {
        search: query,
        table: 'Queue Table',
      };

      trackAnalytics(trackingEvent, trackingData);
    },
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedOpsTask, setSelectedOpsTask] = useState<OpsTask>();
  const hasCurrentUserResolved: boolean = !opswareUserLoading && !!currentUserData && !!currentUserData.me.firstName;

  useEffect(() => {
    if (hasCurrentUserResolved) {
      const hasSquads: boolean = !!currentUserData?.me?.squad;

      setIsAuthorized(hasSquads);
      setShowSquadsSelect(!!currentUserData?.me?.hasMultiSquadAccess);
    }
  }, [currentUserData, hasCurrentUserResolved]);

  useEffect(() => {
    if (hasCurrentUserResolved) {
      setShowSquadsSelect(currentUserData?.me?.hasMultiSquadAccess);
    }
  }, [currentUserData, hasCurrentUserResolved]);

  useEffect(() => {
    trackPage<Page>('Queue');
  }, []);

  if (!isQueuePageEnabled) {
    return <Dogs404 />;
  }

  if (!hasCurrentUserResolved) {
    return <SkeletonQueue />;
  }

  if (isAuthorized === false || opswareUserLoadingError) {
    return <Dogs403 />;
  }

  const handleSearchBarSubmit = (): void => {
    trackAnalytics(trackingEvent, {
      search: searchBarFilter,
      table: 'Queue Table',
    });

    navigateWithParams({ search: searchBarFilter, pageIndex: '1' });
  };

  const handleSearchBarChange = (term: string): void => setSearchBarFilter(term);

  const handleSquadsChange = (options: OpsSquad[]) => {
    navigateWithParams({ squads: options, pageIndex: '1' });
    setSquadsFilter(options);
  };

  const handleStatusesChange = (options: SupportedOptions[]) => {
    navigateWithParams({ statuses: options, pageIndex: '1' });
    setStatusesFilter(buildStatuses(options));
    setIsOverdueFilter(buildIsOverdue(options));
  };

  const assignOpsTaskToCurrentUser = (opsTask: OpsTask) => {
    const fullName = `${currentUserData?.me.firstName} ${currentUserData?.me.lastName}`.trim();

    return updateOpsTask(
      updateOpsTaskPayload(opsTask.id, {
        assigneeName: fullName,
        assigneeId: currentUserData?.me.id,
      }),
    );
  };

  const unassignOpsTask = (opsTask: OpsTask) => {
    return updateOpsTask(
      updateOpsTaskPayload(opsTask.id, {
        assigneeName: '',
        assigneeId: '',
      }),
    );
  };

  const handleToggleDrawer = async (opsTask?: OpsTask) => {
    let updatedOpsTaskProperties;

    if (opsTask && !opsTask.assigneeId && opsTask.autoAssignTask) {
      updatedOpsTaskProperties = await assignOpsTaskToCurrentUser(opsTask);
    } else if (selectedOpsTask && selectedOpsTask.assigneeId === currentUserData?.me.id) {
      updatedOpsTaskProperties = await unassignOpsTask(selectedOpsTask);
    }

    setSelectedOpsTask({
      ...opsTask,
      ...updatedOpsTaskProperties?.data?.updateOpsTask,
    } as OpsTask);

    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Layout>
      <LayoutContent>
        <LayoutSubContent>
          <Box p="space60">
            <QueueHeader />
            <QueueTableFilters
              defaultSquads={squadsFilter}
              defaultStatuses={currentParams?.statuses?.length ? currentParams.statuses : defaultOptions}
              fetchingOpsTasks={fetchingOpsTasks}
              handleSearchBarChange={handleSearchBarChange}
              handleSearchBarSubmit={handleSearchBarSubmit}
              handleSquadsChange={handleSquadsChange}
              handleStatusesChange={handleStatusesChange}
              searchBarFilter={searchBarFilter}
              showSquadsSelect={showSquadsSelect}
            />
            <Card variant="shadow">
              {fetchingOpsTasks && <SkeletonTable columns={5} rows={16} />}
              {error && <ErrorMessage />}
              {data?.opsTasks && (
                <QueueTable fetchingOpsTasks={fetchingOpsTasks} onClick={handleToggleDrawer} {...data.opsTasks} />
              )}
            </Card>
          </Box>
        </LayoutSubContent>
      </LayoutContent>
      <QueueDrawer
        isOpen={isDrawerOpen}
        taskId={selectedOpsTask?.id || '0'}
        transactionIdentifier={selectedOpsTask?.transaction?.fileNum || '0'}
        onClose={handleToggleDrawer}
      />
    </Layout>
  );
};

export default Queue;
