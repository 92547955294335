import { ContactType } from '@endpoint/opsware-bff-graphql-schema';
import * as yup from 'yup';

import { isEntity } from '.';

export interface AddContactValidationItems {
  firstName?: string;
  middleName?: string | null;
  lastName?: string;
  entityName?: string;
  nmlsId?: string | null;
  licenseNumber?: string | null;
  email?: string;
  phoneNumber?: string | null;
  stateOfOperation?: string | null;
}

export const generateAddContactValidation = (
  contactType: ContactType,
): yup.ObjectSchema<yup.Shape<object | undefined, AddContactValidationItems>, object> => {
  const isProptechPartner = contactType === ContactType.PROPTECH_PARTNER;

  if (isEntity(contactType) || isProptechPartner) {
    return yup.object().shape({
      entityName: yup.string().required('Name is a required field'),
      email: yup.string().email('Invalid email address'),
      phoneNumber: yup.string().nullable(),
      roles: yup.array().of(yup.string()).required('Select at least one'),
    });
  }

  return yup.object().shape({
    firstName: yup.string().required('First Name is a required field'),
    middleName: yup.string().nullable(),
    lastName: yup.string().required('Last Name is a required field'),
    nmlsId: yup
      .string()
      .matches(/^[0-9]*$/, 'NMLS ID can only contain numbers')
      .nullable(),
    email: yup.string().email('Invalid email address'),
    phoneNumber: yup.string().nullable(),
    licenseNumber: yup
      .string()
      .matches(/^[a-zA-Z0-9]*$/, 'License Number can only be alphanumeric')
      .nullable(),
    roles: yup.array().of(yup.string()).required('Select at least one'),
  });
};
